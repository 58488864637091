import { createAction, props } from '@ngrx/store';
import { SourceAssessmentDto } from '@api/dictionaries';

export const loadSourceAssessments = createAction('[Dictionaries/API] Load Source Assessments');
export const loadSourceAssessmentsSuccess = createAction(
  '[Dictionaries/API] Load Source Assessments Success',
  props<{ sourceAssessments: SourceAssessmentDto[] }>(),
);
export const loadSourceAssessmentsFailure = createAction(
  '[Dictionaries/API] Load Source Assessments Failure',
  props<{ error: unknown }>(),
);
