import { createAction, props } from '@ngrx/store';
import { AssessmentTypeDto } from '@api/dictionaries';

export const loadAssessmentTypes = createAction('[Dictionaries/API] Load Assessment Types');
export const loadAssessmentTypesSuccess = createAction(
  '[Dictionaries/API] Load Assessment Types Success',
  props<{ assessmentTypes: AssessmentTypeDto[] }>(),
);
export const loadAssessmentTypesFailure = createAction(
  '[Dictionaries/API] Load Assessment Types Failure',
  props<{ error: unknown }>(),
);
