/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CertificateCodeDto } from '../models/CertificateCodeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CertificateCodeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all certificate codes
     * Returns a list of all certificate codes.
     * @returns CertificateCodeDto List of certificate codes returned
     * @throws ApiError
     */
    public getApiCertificateCodes(): Observable<Array<CertificateCodeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/certificate-codes',
        });
    }

    /**
     * Update certificate code
     * Updates an existing certificate code based on the provided data.
     * @returns void
     * @throws ApiError
     */
    public putApiCertificateCodes({
        requestBody,
    }: {
        /**
         * Certificate code object that needs to be updated.
         */
        requestBody: CertificateCodeDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/certificate-codes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create new certificate code
     * Creates a new certificate code based on the provided data.
     * @returns CertificateCodeDto Certificate code created successfully
     * @throws ApiError
     */
    public postApiCertificateCodes({
        requestBody,
    }: {
        /**
         * Certificate code object that needs to be added.
         */
        requestBody: CertificateCodeDto,
    }): Observable<CertificateCodeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/certificate-codes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find certificate codes by parameters
     * Returns a list of certificate codes matching the given parameters.
     * @returns CertificateCodeDto List of matching certificate codes returned.
     * @throws ApiError
     */
    public getApiCertificateCodesByParams({
        assetType,
        employeeLogin,
        endDate,
    }: {
        /**
         * Asset type to filter by.
         */
        assetType?: string,
        /**
         * Employee login to filter by.
         */
        employeeLogin?: string,
        /**
         * End date to filter by.
         */
        endDate?: string,
    }): Observable<Array<CertificateCodeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/certificate-codes/by-params',
            query: {
                'asset-type': assetType,
                'employee-login': employeeLogin,
                'end-date': endDate,
            },
        });
    }

    /**
     * Find certificate code by ID
     * Returns a certificate code with the given ID.
     * @returns CertificateCodeDto Certificate code found and returned
     * @throws ApiError
     */
    public getApiCertificateCodes1({
        id,
    }: {
        /**
         * ID of the certificate code to be found. Cannot be empty.
         */
        id: string,
    }): Observable<CertificateCodeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/certificate-codes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Certificate code not found`,
            },
        });
    }

    /**
     * Delete certificate code
     * Deletes the certificate code with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiCertificateCodes({
        id,
    }: {
        /**
         * ID of the certificate code to be deleted. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/certificate-codes/{id}',
            path: {
                'id': id,
            },
        });
    }

}
