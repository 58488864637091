/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CheckListFormingResultDto } from '../models/CheckListFormingResultDto';
import type { CheckListItemResultDto } from '../models/CheckListItemResultDto';
import type { CheckListResponseDto } from '../models/CheckListResponseDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CheckListService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get checklist for a specific process instance
     * Returns actual checklist for the requested process instance
     * @returns CheckListResponseDto Checklist for requested process instance is returned
     * @throws ApiError
     */
    public getProcessesChecklist({
        processInstanceId,
    }: {
        /**
         * Process instance id for getting a checklist
         */
        processInstanceId: string,
    }): Observable<CheckListResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/processes/{processInstanceId}/checklist',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `Checklist for requested process instance is empty or does not exist`,
                500: `Server error`,
            },
        });
    }

    /**
     * Create/Update checklist
     * Returns response with status and data from checklist creating/updating
     * @returns CheckListFormingResultDto checklist was successfully created/update
     * @throws ApiError
     */
    public postProcessesChecklist({
        processInstanceId,
    }: {
        /**
         * Process instance id for creating/updating checklist
         */
        processInstanceId: string,
    }): Observable<CheckListFormingResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/processes/{processInstanceId}/checklist',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Change result for a specific checklist item
     * Returns actual checklist for the process instance
     * @returns CheckListResponseDto Checklist item result was successfully changed
     * @throws ApiError
     */
    public patchProcessesChecklist({
        checkupId,
        processInstanceId,
        requestBody,
    }: {
        /**
         * Checkup id for a specific process checklist item
         */
        checkupId: string,
        /**
         * Process instance id for the result change of checklist item
         */
        processInstanceId: string,
        requestBody?: CheckListItemResultDto,
    }): Observable<CheckListResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/processes/{processInstanceId}/checklist/{checkupId}',
            path: {
                'checkupId': checkupId,
                'processInstanceId': processInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Checklist item result is unable to change due to its auto check result`,
                404: `Checklist not found`,
                500: `Server error`,
            },
        });
    }

}
