<mat-card formGroupName="requisitesContract">
  <mat-card-header>
    <mat-card-title>Реквізити</mat-card-title>
  </mat-card-header>

  <div class="row">
    <mat-card-content>
      <mat-form-field>
        <mat-label>{{ requisitesContractConfig.addressLetterTitle }}</mat-label>

        <input
          matInput
          type="text"
          [placeholder]="requisitesContractConfig.addressLetterTitle"
          formControlName="addressLetter"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ requisitesContractConfig.addressLocationTitle }}</mat-label>

        <input
          matInput
          type="text"
          [placeholder]="requisitesContractConfig.addressLocationTitle"
          formControlName="addressLocation"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ requisitesContractConfig.phoneFaxTitle }}</mat-label>

        <input matInput type="text" [placeholder]="requisitesContractConfig.phoneFaxTitle" formControlName="phoneFax" />
      </mat-form-field>
      <mat-form-field *ngIf="requisitesContractConfig.isShowPasspDoc">
        <mat-label>{{ requisitesContractConfig.passpDocTitle }}</mat-label>

        <input matInput type="text" [placeholder]="requisitesContractConfig.passpDocTitle" formControlName="passpDoc" />
      </mat-form-field>

      <mat-form-field *ngIf="requisitesContractConfig.isShowEmail">
        <mat-label>{{ requisitesContractConfig.emailTitle }}</mat-label>

        <input
          matInput
          type="email"
          [placeholder]="requisitesContractConfig.emailTitle"
          [pattern]="EMAIL_REGEX"
          formControlName="email"
        />
      </mat-form-field>

      <button
        mat-stroked-button
        color="primary"
        (click)="refreshPartnerRequisites()"
        type="button"
        [disabled]="requisitesContract.disabled"
      >
        Оновити реквізити з САП
      </button>
    </mat-card-content>

    <mat-card-content>
      <mat-form-field>
        <mat-label>{{ requisitesContractConfig.addressLetterBankTitle }}</mat-label>
        <input
          matInput
          type="text"
          [placeholder]="requisitesContractConfig.addressLetterBankTitle"
          formControlName="addressLetterBank"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ requisitesContractConfig.phoneFaxBankTitle }}</mat-label>
        <input
          matInput
          type="text"
          [placeholder]="requisitesContractConfig.phoneFaxBankTitle"
          formControlName="phoneFaxBank"
        />
      </mat-form-field>

      <mat-form-field *ngIf="requisitesContractConfig.isShowEmailBank">
        <mat-label>{{ requisitesContractConfig.emailTitleBank }}</mat-label>

        <input
          matInput
          type="email"
          [placeholder]="requisitesContractConfig.emailTitleBank"
          [pattern]="EMAIL_REGEX"
          formControlName="emailBank"
        />
      </mat-form-field>

      <button
        mat-stroked-button
        color="primary"
        (click)="refreshBankRequisites()"
        type="button"
        [disabled]="requisitesContract.disabled"
      >
        Оновити реквізити з САП
      </button>
    </mat-card-content>
  </div>
</mat-card>
