/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DictionaryQueryDTO } from '../models/DictionaryQueryDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DictionaryEditorService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Say hello
     * Say hello
     * @returns string String response with greeting
     * @throws ApiError
     */
    public getApiDictionaryEditor(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor',
        });
    }

    /**
     * Get dictionary query data by id
     * Get dictionary query data by id
     * @returns DictionaryQueryDTO Dictionary query data
     * @throws ApiError
     */
    public getApiDictionaryEditor1({
        id,
    }: {
        /**
         * ID of the dictionary query to be obtained. Cannot be empty.
         */
        id: number,
    }): Observable<DictionaryQueryDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get dictionary all detail queries data by id and master id
     * Get dictionary all detail queries data by id and master id
     * @returns any Dictionary all detail queries data
     * @throws ApiError
     */
    public getApiDictionaryEditorDetails({
        id,
        masterId,
    }: {
        /**
         * ID of the dictionary detail to be obtained. Cannot be empty.
         */
        id: number,
        /**
         * MasterId of the dictionary detail to be obtained. Cannot be empty.
         */
        masterId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor/{id}/details/{masterId}',
            path: {
                'id': id,
                'masterId': masterId,
            },
            errors: {
                404: `Dictionary not found`,
            },
        });
    }

    /**
     * Get dictionary detail data by params.
     * Get dictionary detail data by id, master id and detail name
     * @returns any Dictionary detail data
     * @throws ApiError
     */
    public getApiDictionaryEditorDetails1({
        detailName,
        id,
        masterId,
    }: {
        /**
         * Detail name of the dictionary detail to be obtained. Cannot be empty.
         */
        detailName: string,
        /**
         * ID of the dictionary detail to be obtained. Cannot be empty.
         */
        id: number,
        /**
         * MasterId of the dictionary detail to be obtained. Cannot be empty.
         */
        masterId: string,
    }): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor/{id}/details/{masterId}/{detailName}',
            path: {
                'detailName': detailName,
                'id': id,
                'masterId': masterId,
            },
            errors: {
                404: `Dictionary not found`,
            },
        });
    }

}
