import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetDeal } from '@api/loan-approval';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CustomCurrencyPipeModule } from '@sib/shared/util';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMatContextMenuTriggerModule } from '@w3soto/ngx-mat-context-menu-trigger';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { TermsSecuringEditTitleDialogComponent } from '../terms-securing-edit-title-dialog/terms-securing-edit-title-dialog.component';

@Component({
  selector: 'sib-terms-securing-item',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    CustomCurrencyPipeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    NgxMatContextMenuTriggerModule,
    MatTooltipModule,
  ],
  templateUrl: './terms-securing-item.component.html',
  styleUrls: ['./terms-securing-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsSecuringItemComponent {
  @Input() set assetDeals(assetDeals: AssetDeal[]) {
    if (assetDeals?.length) {
      this.dataSource.data = assetDeals;
    }
  }

  @Input() title = '';
  @Input() disabled = false;
  @Output() updateItem = new EventEmitter<AssetDeal>();
  @Output() generateNewTitle = new EventEmitter<AssetDeal>();
  @Output() deleteItem = new EventEmitter<AssetDeal>();
  @Output() addNewItem = new EventEmitter<string>();

  public dataSource = new MatTableDataSource<AssetDeal>();
  public displayedColumns = ['index', 'title', 'menu'];

  constructor(private dialog: MatDialog) {}

  edit(row: AssetDeal) {
    this.dialog
      .open(TermsSecuringEditTitleDialogComponent, {
        width: '640px',
        data: {
          title: row.title,
        },
      })
      .afterClosed()
      .subscribe((v) => {
        if (!v) return;
        this.updateItem.emit({ ...row, title: v.title });
      });
  }

  generate(row: AssetDeal) {
    this.generateNewTitle.emit(row);
  }

  addNew(categoryId: string) {
    this.addNewItem.emit(categoryId);
  }

  delete(row: AssetDeal) {
    this.deleteItem.emit(row);
  }
}
