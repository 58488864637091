import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isBefore } from 'date-fns';

export class CustomValidators {
  static checkWhitespace(control: FormControl): ValidationErrors | null {
    return (control: FormControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  static checkEmpty(key: string): ValidationErrors | null {
    return (formControl: FormControl): ValidationErrors | null => {
      const isValid = formControl.value.some((item: any) => !!item[key]);
      return isValid ? null : { isEmpty: true };
    };
  }

  static dateLessThan(from: string, to: string): ValidationErrors | null {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const f = formGroup.controls[from];
      const t = formGroup.controls[to];

      return isBefore(new Date(f.value), new Date(t.value)) ? null : { invalidDate: true };
    };
  }
}
