/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ObligationTypeDTO } from '../models/ObligationTypeDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ObligationTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all obligation types
     * Returns all obligation types.
     * @returns ObligationTypeDTO all obligation types
     * @throws ApiError
     */
    public getApiObligationTypes(): Observable<Array<ObligationTypeDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/obligation-types',
        });
    }

    /**
     * Create obligation type
     * Method for creating obligation type.
     * @returns ObligationTypeDTO obligation type created
     * @throws ApiError
     */
    public postApiObligationTypes({
        requestBody,
    }: {
        /**
         * Obligation type object that needs to be created.
         */
        requestBody: ObligationTypeDTO,
    }): Observable<ObligationTypeDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/obligation-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `obligation type already exist`,
                500: `server error`,
            },
        });
    }

    /**
     * Get obligation type
     * Get obligation type by ID.
     * @returns any obligation type found
     * @throws ApiError
     */
    public getApiObligationTypes1({
        id,
    }: {
        /**
         * Obligation type ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        obligationTypeName?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/obligation-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `obligation type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update obligation type
     * Updates the obligation type with the given ID.
     * @returns any obligation type updated
     * @throws ApiError
     */
    public putApiObligationTypes({
        id,
        requestBody,
    }: {
        /**
         * Obligation type ID. Cannot be empty.
         */
        id: number,
        /**
         * Obligation type object that needs to be updated.
         */
        requestBody: ObligationTypeDTO,
    }): Observable<{
        id: number;
        obligationTypeName?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/obligation-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete obligation type
     * Deletes the obligation type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiObligationTypes({
        id,
    }: {
        /**
         * Obligation type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/obligation-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
