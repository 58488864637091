import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllCurrentCredits,
  selectCurrentCreditsEntities,
} from '../../+state/current-credits/current-credits.selectors';

@Injectable({ providedIn: 'root' })
export class CurrentCreditsFacade {
  private readonly store = inject(Store);

  selectAllCurrentCredits$ = this.store.select(selectAllCurrentCredits);
  selectCurrentCreditsEntities$ = this.store.select(selectCurrentCreditsEntities);
}
