import { createAction, props } from '@ngrx/store';
import { RequestTypeDto } from '@api/dictionaries';

export const loadRequestTypes = createAction('[Dictionaries/API] Load Request Types');
export const loadRequestTypesSuccess = createAction(
  '[Dictionaries/API] Load Request Types Success',
  props<{ requestTypes: RequestTypeDto[] }>(),
);
export const loadRequestTypesFailure = createAction(
  '[Dictionaries/API] Load Request Types Failure',
  props<{ error: unknown }>(),
);
