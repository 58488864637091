import { createAction, props } from '@ngrx/store';
import { ErrorDto, ProcessErrorDto } from '@api/dictionaries';

export const loadErrors = createAction('[Dictionaries/API] Load Errors');
export const loadErrorsSuccess = createAction(
  '[Dictionaries/API] Load Errors Success',
  props<{ errors: ErrorDto[] }>(),
);
export const loadErrorsFailure = createAction('[Dictionaries/API] Load Errors Failure', props<{ error: unknown }>());

export const loadErrorsProcess = createAction('[Dictionaries/API] Load Errors Process');
export const loadErrorsProcessSuccess = createAction(
  '[Dictionaries/API] Load Errors Process Success',
  props<{ errorsProcess: ProcessErrorDto[] }>(),
);
export const loadErrorsProcessFailure = createAction(
  '[Dictionaries/API] Load Errors Process Failure',
  props<{ error: unknown }>(),
);
