import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as AssetTypesActions from './asset-types.actions';
import { AssetTypeDto } from '@api/dictionaries';

export const assetTypesFeatureKey = 'asset-types';

export interface State extends EntityState<AssetTypeDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<AssetTypeDto> = createEntityAdapter<AssetTypeDto>({
  selectId: (item) => item.assetTypeCode,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(AssetTypesActions.loadAssetTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AssetTypesActions.loadAssetTypesSuccess, (state, { assetTypes }) => ({
    ...adapter.setAll(assetTypes, state),
    isLoading: false,
  })),
  on(AssetTypesActions.loadAssetTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
