/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ParticipantDTO } from '../models/ParticipantDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ParticipantService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all participants
     * Returns all participants.
     * @returns ParticipantDTO all participants
     * @throws ApiError
     */
    public getApiParticipants(): Observable<Array<ParticipantDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/participants',
        });
    }

    /**
     * Create participant
     * Method for creating participant.
     * @returns ParticipantDTO participant created
     * @throws ApiError
     */
    public postApiParticipants({
        requestBody,
    }: {
        /**
         * Participant object that needs to be created.
         */
        requestBody: ParticipantDTO,
    }): Observable<ParticipantDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get participants
     * Get participants by proposal type ID.
     * @returns ParticipantDTO participants found
     * @throws ApiError
     */
    public getApiParticipantsProposalTypes({
        proposalTypeId,
    }: {
        /**
         * Proposal type ID. Cannot be empty.
         */
        proposalTypeId: number,
    }): Observable<Array<ParticipantDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/participants/proposal-types/{proposalTypeId}',
            path: {
                'proposalTypeId': proposalTypeId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get participant
     * Get participant by ID.
     * @returns any participant found
     * @throws ApiError
     */
    public getApiParticipants1({
        id,
    }: {
        /**
         * Participant ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        roleId?: string;
        proposalTypeId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/participants/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `participant not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update participant
     * Updates the participant with the given ID.
     * @returns any participant updated
     * @throws ApiError
     */
    public putApiParticipants({
        id,
        requestBody,
    }: {
        /**
         * Participant ID. Cannot be empty.
         */
        id: number,
        /**
         * Participant object that needs to be updated.
         */
        requestBody: ParticipantDTO,
    }): Observable<{
        id: number;
        roleId?: string;
        proposalTypeId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/participants/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Participant DTO has wrong ID`,
                404: `Participant not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete participant
     * Deletes the participant with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiParticipants({
        id,
    }: {
        /**
         * Participant ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/participants/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
