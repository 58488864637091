/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssetTypeDto } from '../models/AssetTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssetTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all asset types
     * Returns all asset types.
     * @returns AssetTypeDto List of asset types or empty list
     * @throws ApiError
     */
    public getApiAssetsAssetTypes(): Observable<Array<AssetTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assets/asset-types',
        });
    }

    /**
     * Create an asset type
     * Method for creating an asset type.
     * @returns AssetTypeDto asset type created
     * @throws ApiError
     */
    public postApiAssetsAssetTypes({
        requestBody,
    }: {
        /**
         * Asset type object that needs to be created.
         */
        requestBody: AssetTypeDto,
    }): Observable<AssetTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/assets/asset-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Update an asset type
     * Updates the asset type with the given ID.
     * @returns any asset type updated
     * @throws ApiError
     */
    public putApiAssetsAssetTypes({
        assetTypeCode,
        requestBody,
    }: {
        /**
         * Asset type code.  Cannot be empty.
         */
        assetTypeCode: string,
        /**
         * Asset type object that needs to be updated.
         */
        requestBody: AssetTypeDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/assets/asset-types/{assetTypeCode}',
            path: {
                'assetTypeCode': assetTypeCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete asset type
     * Deletes the asset type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiAssetsAssetTypes({
        assetTypeCode,
    }: {
        /**
         * Asset type code.  Cannot be empty.
         */
        assetTypeCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/assets/asset-types/{assetTypeCode}',
            path: {
                'assetTypeCode': assetTypeCode,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get asset type
     * Get asset type by asset type.
     * @returns any Asset type exist
     * @throws ApiError
     */
    public getApiAssetsAssetTypes1({
        assetType,
    }: {
        /**
         * Asset type. Cannot be empty.
         */
        assetType: string,
    }): Observable<{
        assetTypeCode: string;
        assetTypeName?: string;
        sapCodeMain?: string;
        sapCodeAdd?: string;
        assetTypeAddData?: string;
        estFlg?: boolean;
        actFlg?: boolean;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assets/asset-types/{assetType}',
            path: {
                'assetType': assetType,
            },
            errors: {
                404: `Asset type not found`,
            },
        });
    }

}
