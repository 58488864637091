import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as ObligationTypesActions from './obligation-types.actions';
import { ObligationTypeDTO } from '@api/dictionaries';

export const obligationTypesFeatureKey = 'obligation-types';

export interface State extends EntityState<ObligationTypeDTO> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<ObligationTypeDTO>({
  selectId: (obligationType) => obligationType.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ObligationTypesActions.loadObligationTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ObligationTypesActions.loadObligationTypesSuccess, (state, { obligationTypes }) => ({
    ...adapter.setAll(obligationTypes, state),
    isLoading: false,
  })),

  on(ObligationTypesActions.loadObligationTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
