import { createReducer, on } from '@ngrx/store';
import * as UseTypes from './use-types.actions';
import { UseTypeDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const useTypesFeatureKey = 'use-types';

export interface State extends EntityState<UseTypeDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<UseTypeDto>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(UseTypes.loadUseTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(UseTypes.loadUseTypesSuccess, (state, { useTypes }) => ({
    ...adapter.setAll(useTypes, state),
    isLoading: false,
  })),
  on(UseTypes.loadUseTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectUseTypesState = selectAll;
