import { createFeature, createReducer, on } from '@ngrx/store';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import {
  AssetAgreementRelation,
  AutoCheck,
  Controller,
  DeferredConditions,
  GuarantorAgreementRelation,
  LawCases,
  ProcessParticipant,
  PropertyOwner,
  ProtocolCC,
  VotingComment,
  VotingResults,
} from '@api/loan-approval';

export const variablesFeatureKey = 'variables';

export interface VariablesState {
  applicationType: string;
  assessmentType: string;
  applicationStartDate: string;
  ruCode: number | null;
  variablesInRootProcess: boolean;
  assignmentRole: string;
  initiatorFullName: string;
  requestNumber: string;
  rdCode: number | null;
  unitCode: number | null;
  serviceUnitCode: number | null;
  bpCode: number | null;
  orgUnitTitle: string;
  initiatorId: string;
  ruTitle: string;
  bpClientName: string;
  applicationGroupName: string | null;
  rdTitle: string;
  regionCode: number;
  estimateNumber: string | null;
  isFileStoreEnable: boolean;
  rateApproval: string;
  controlOptions: Record<string, string | undefined>;
  tabsAccessRestriction: Record<string, string> | null;
  filterParams: Record<'agreements' | 'asset', string[]> | null;
  autoChecks: AutoCheck[];
  assetAgreementRelation: AssetAgreementRelation[];
  guarantorAgreementRelation: GuarantorAgreementRelation[];
  controllers: Controller[];
  lawCases: LawCases[];
  resolvableErrors: Array<string>;
  processParticipants: ProcessParticipant[];
  deferredConditions: DeferredConditions[];
  votingComments: VotingComment[];
  votingResults: VotingResults[];
  propertyOwner: PropertyOwner[];
  protocolCC: ProtocolCC;
}

export const initialState: VariablesState = {
  applicationType: '',
  assessmentType: '',
  applicationStartDate: '',
  ruCode: null,
  variablesInRootProcess: true,
  assignmentRole: '',
  initiatorFullName: '',
  requestNumber: '',
  rdCode: null,
  unitCode: null,
  serviceUnitCode: null,
  bpCode: null,
  orgUnitTitle: '',
  initiatorId: '',
  ruTitle: '',
  bpClientName: '',
  applicationGroupName: '',
  rdTitle: '',
  regionCode: 0,
  rateApproval: '',
  tabsAccessRestriction: null,
  estimateNumber: '',
  isFileStoreEnable: false,
  filterParams: null,
  controlOptions: {},
  autoChecks: [],
  assetAgreementRelation: [],
  guarantorAgreementRelation: [],
  controllers: [],
  lawCases: [],
  resolvableErrors: [],
  processParticipants: [],
  deferredConditions: [],
  votingComments: [],
  votingResults: [],
  propertyOwner: [],
  protocolCC: {},
};

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => ({
    ...state,
    ...variables,
    processParticipants: variables.processParticipants || [],
    guarantorAgreementRelation: variables.guarantorAgreementRelation || [],
    assetAgreementRelation: variables.assetAgreementRelation || [],
    propertyOwner: variables.propertyOwner || [],
    protocolCC: variables.protocolCC || {},
    controllers: variables.controllers || [],
    lawCases: variables.lawCases || [],
    autoChecks: variables.autoChecks || [],
    deferredConditions: variables.deferredConditions || [],
    votingComments: variables.votingComments || [],
    votingResults: variables.votingResults || [],
  })),
);

export const variablesFeature = createFeature({
  name: variablesFeatureKey,
  reducer,
});
