import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Agreement, CashFlowZKR } from '@api/loan-approval';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cashFlowZkrValidator } from '../../value-accessors/cash-flow-zkrs-table/cash-flow-zkrs-table.validators';

@UntilDestroy()
@Injectable()
export class ObligationsBorrowerFormService extends FormGroup<
  ReturnType<typeof ObligationsBorrowerFormService.formGroup>
> {
  private static readonly formGroup = () => ({
    penaltySum: new FormControl<number>(5000, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    cashFlows: new FormControl<Agreement['cashFlows']>({ value: undefined, disabled: true }, { nonNullable: true }),
    isCashFlow: new FormControl<boolean>(false, { nonNullable: true }),
    sumFlows: new FormControl<number>(0, { nonNullable: true }),
    quarterFlows: new FormControl<CashFlowZKR[]>([], {
      validators: [cashFlowZkrValidator('FLOW_UAH')],
      nonNullable: true,
    }),
  });

  get penaltySum() {
    return this.controls.penaltySum;
  }

  get cashFlows() {
    return this.controls.cashFlows;
  }

  get isCashFlow() {
    return this.controls.isCashFlow;
  }

  get sumFlows() {
    return this.controls.sumFlows;
  }

  get quarterFlows() {
    return this.controls.quarterFlows;
  }

  private get minValidator() {
    return Validators.min(0.01);
  }

  constructor() {
    super(ObligationsBorrowerFormService.formGroup());

    this.cashFlows.valueChanges.pipe(untilDestroyed(this)).subscribe((cashFlows) => {
      if (this.cashFlows.status === 'DISABLED') return;
      switch (cashFlows) {
        case 'monthFlows':
          this.sumFlows.addValidators([Validators.required, this.minValidator]);
          this.sumFlows.enable();
          this.quarterFlows.disable();
          break;
        case 'quarterFlows':
          this.sumFlows.removeValidators([Validators.required, this.minValidator]);
          this.sumFlows.disable();
          this.quarterFlows.enable();
          break;
      }

      this.sumFlows.updateValueAndValidity();
    });

    this.isCashFlow.valueChanges.pipe(untilDestroyed(this)).subscribe((isCashFlow) => {
      if (this.isCashFlow.status === 'DISABLED') return;
      if (isCashFlow) {
        this.cashFlows.enable();
        this.sumFlows.enable();
        this.quarterFlows.enable();
      } else {
        this.cashFlows.reset();
        this.quarterFlows.disable();
        this.cashFlows.disable();
        this.sumFlows.disable();
      }
    });
  }
}
