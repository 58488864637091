/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ProcessTaskDto } from '../models/ProcessTaskDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ProcessTaskService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all processes tasks
     * Returns all processes tasks.
     * @returns ProcessTaskDto all processes tasks
     * @throws ApiError
     */
    public getApiProcessesTasks(): Observable<Array<ProcessTaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/processes-tasks',
        });
    }

    /**
     * Create processes task
     * Creates the processes task.
     * @returns any Processes task updated
     * @throws ApiError
     */
    public postApiProcessesTasks({
        requestBody,
    }: {
        /**
         * Processes task object that needs to be create.
         */
        requestBody: ProcessTaskDto,
    }): Observable<{
        id?: number;
        processDefinitionKey?: string;
        taskDefinitionKey?: string;
        taskName?: string;
        roleId?: string;
        roleName?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/processes-tasks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Processes task not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get processes task
     * Get processes task by ID.
     * @returns any participant found
     * @throws ApiError
     */
    public getApiProcessesTasks1({
        id,
    }: {
        /**
         * Processes task ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id?: number;
        processDefinitionKey?: string;
        taskDefinitionKey?: string;
        taskName?: string;
        roleId?: string;
        roleName?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/processes-tasks/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Processes task not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update processes task
     * Method for creating processes task.
     * @returns ProcessTaskDto participant created
     * @throws ApiError
     */
    public putApiProcessesTasks({
        id,
        requestBody,
    }: {
        id: number,
        /**
         * Processes task object that needs to be created.
         */
        requestBody: ProcessTaskDto,
    }): Observable<ProcessTaskDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/processes-tasks/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete processes task
     * Deletes the processes task with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiProcessesTasks({
        id,
    }: {
        /**
         * Processes task ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/processes-tasks/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
