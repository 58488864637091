import { createAction, props } from '@ngrx/store';
import { EmployeeDto } from '@api/loan-org-structure';

export const loadEmployees = createAction('[Dictionaries/API] Load Employees');
export const loadEmployeesSuccess = createAction(
  '[Dictionaries/API] Load Employees Success',
  props<{ employees: EmployeeDto[] }>(),
);
export const loadEmployeesFailure = createAction(
  '[Dictionaries/API] Load Employees Failure',
  props<{ error: unknown }>(),
);
