import { createSelector } from '@ngrx/store';
import * as MeasurementUnits from './measurement-units.reducers';
import { selectMeasurementUnitsState } from '../+shell/reducers.index';

export const selectMeasurementUnits = createSelector(selectMeasurementUnitsState, MeasurementUnits.selectAll);

export const selectMeasurementUnitsEntities = createSelector(
  selectMeasurementUnitsState,
  MeasurementUnits.selectEntities,
);
