import { ErrorStateMatcher } from '@angular/material/core';
import { inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  form = inject(FormGroupDirective, { skipSelf: true });

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (this.form?.submitted || control.touched));
  }
}
