<mat-card formGroupName="scopeContract">
  <mat-card-header>
    <mat-card-title>Предмет договору</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="row">
      <mat-form-field>
        <mat-label>Ліміт договору</mat-label>

        <input matInput type="text" placeholder="Ліміт договору" formControlName="agreementSum" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Валюта</mat-label>

        <input matInput type="text" placeholder="Валюта" formControlName="currencyCode" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field (click)="datepicker.open()">
        <mat-label>Строк дії Ліміту (до)</mat-label>
        <input matInput formControlName="endDate" [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Вид Ліміту</mat-label>
        <mat-select placeholder="Вид Ліміту" formControlName="guaranteeCat">
          <mat-option *ngFor="let category of guaranteeCategory" [value]="category.type"
            >{{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Рахунок покриття</mat-label>

        <input matInput type="text" placeholder="Рахунок покриття" formControlName="coverageAccount" />
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Поточний рахунок</mat-label>
      <mat-select placeholder="Виберіть поточний рахунок" formControlName="curAccount">
        <mat-option *ngFor="let item of account$ | async" [value]="item.accNum"> {{ item.accNum }} </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>
