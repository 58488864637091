import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class LoanGrantingProcedureGroupService extends FormGroup<
  ReturnType<typeof LoanGrantingProcedureGroupService.formGroup>
> {
  private static readonly formGroup = () => ({
    agreementSum: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    agreementSumUAH: new FormControl<number>({ value: 0, disabled: true }, { nonNullable: true }),
    rateDate: new FormControl<string | Date>({ value: '', disabled: true }, { nonNullable: true }),
    currencyCode: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    lendingPurpose: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    curAccount: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    komPayFlag: new FormControl<boolean>(false, { nonNullable: true }),
  });

  constructor() {
    super(LoanGrantingProcedureGroupService.formGroup());
  }
}
