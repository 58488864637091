/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CurrentApplicationsStatusReportParameters } from '../models/CurrentApplicationsStatusReportParameters';
import type { CurrentApplicationStatusReportData } from '../models/CurrentApplicationStatusReportData';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ReportService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all current applications status report data
     * Returns a list of all current applications status report data.
     * @returns CurrentApplicationStatusReportData current applications status report data found
     * @throws ApiError
     */
    public postReportsCurrentApplicationsStatus({
        requestBody,
    }: {
        requestBody: CurrentApplicationsStatusReportParameters,
    }): Observable<Array<CurrentApplicationStatusReportData>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/reports/current-applications-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

}
