import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as UiTabsAction from './ui-tabs.actions';
import * as UiTabsSelectors from './ui-tabs.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UiTabsEffects {
  loadUiTabs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiTabsAction.loadUiTabs),
      concatLatestFrom((action) => [this.store$.select(UiTabsSelectors.selectAllUiTabs)]),
      switchMap(([action, uiTabs]) =>
        (uiTabs.length ? of(uiTabs) : this.dictionariesService.loadUiTabs()).pipe(
          map((response) =>
            UiTabsAction.loadUiTabsSuccess({
              uiTabs: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(UiTabsAction.loadUiTabsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
