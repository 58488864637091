/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssetCategoryDto } from '../models/AssetCategoryDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssetCategoryService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all asset categories
     * Returns a list of all asset categories.
     * @returns AssetCategoryDto Successful operation
     * @throws ApiError
     */
    public getApiAssetCategories(): Observable<Array<AssetCategoryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/asset-categories',
        });
    }

    /**
     * Create an asset category
     * Creates a new asset category.
     * @returns AssetCategoryDto Asset category created successfully
     * @throws ApiError
     */
    public postApiAssetCategories({
        requestBody,
    }: {
        /**
         * Asset category object that needs to be added.
         */
        requestBody: AssetCategoryDto,
    }): Observable<AssetCategoryDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/asset-categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update an asset category
     * Updates an existing asset category with the specified ID.
     * @returns void
     * @throws ApiError
     */
    public putApiAssetCategories({
        catId,
        requestBody,
    }: {
        catId: string,
        /**
         * Asset category object that needs to be updated.
         */
        requestBody: AssetCategoryDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/asset-categories/{catId}',
            path: {
                'catId': catId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find an asset category by ID
     * Returns an asset category with the specified ID.
     * @returns AssetCategoryDto Successful operation
     * @throws ApiError
     */
    public getApiAssetCategories1({
        id,
    }: {
        /**
         * ID of the asset category to be obtained. Cannot be empty.
         */
        id: string,
    }): Observable<AssetCategoryDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/asset-categories/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Delete an asset category
     * Deletes an asset category with the specified ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiAssetCategories({
        id,
    }: {
        /**
         * ID of the asset category to be deleted. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/asset-categories/{id}',
            path: {
                'id': id,
            },
        });
    }

}
