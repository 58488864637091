import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { markForCheck, SapGeneralError } from '@sib/shared/util';
import { PledgerContractGroupService } from './pledger-contract-group.service';
import { MatButtonModule } from '@angular/material/button';
import { UntilDestroy } from '@ngneat/until-destroy';
import { catchError } from 'rxjs/operators';
import { filter, of, throwError } from 'rxjs';
import { PLEDGER_CONTRACT_CONFIG, PledgerContractConfig } from './token';
import { ApiError, GuarantorService } from '@api/loan-approval';

@UntilDestroy()
@Component({
  selector: 'sib-pledger-contract-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatListModule,
    MatButtonModule,
  ],
  templateUrl: './pledger-contract-form.component.html',
  styleUrls: ['./pledger-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class PledgerContractFormComponent {
  constructor(
    private guarantorService: GuarantorService,
    public pledgerContractGroupService: PledgerContractGroupService,
    private cdr: ChangeDetectorRef,
    @Inject(PLEDGER_CONTRACT_CONFIG) public pledgerContractConfig: PledgerContractConfig,
  ) {}

  public getGuarantor() {
    if (this.pledgerContractGroupService.bpNumber.value) {
      this.guarantorService
        .getSapGuarantor({
          bpCode: this.pledgerContractGroupService.bpNumber.value,
        })
        .pipe(
          catchError((error: ApiError) => {
            if (error.body instanceof SapGeneralError) {
              this.pledgerContractGroupService.bpNumber.setErrors({ verifyGuarantor: error.body.errorMessage });
              return of(undefined);
            }
            return throwError(() => error);
          }),
          markForCheck(this.cdr),
          filter(Boolean),
        )
        .subscribe({
          next: (result) => {
            this.pledgerContractGroupService.patchValue({ partnerNameSign: result.orgName });
          },
        });
    }
  }
}
