import { inject, Injectable } from '@angular/core';
import {
  AgreementService,
  BailAgreementRequestDto,
  BailContract,
  Guarantor,
  PartnerSpouseData,
} from '@api/loan-approval';
import { combineLatest, filter, first, forkJoin, switchMap } from 'rxjs';
import { createBailContract } from './models.factory';
import { map } from 'rxjs/operators';
import {
  AgreementsFacadeService,
  deleteTaskV2ProcessVariable,
  DocumentEntryListFacadeService,
  GuarantorsFacadeService,
  setTaskV2ProcessVariable,
  VariablesFacadeService,
} from '@sib/task/shared/store';
import { RequisitesService } from './requisites.service';
import { AbstractStoreService } from './abstract-store.service';
import { DocumentsService } from './documents.service';

@Injectable({
  providedIn: 'root',
})
export class GuarantorsService extends AbstractStoreService {
  private readonly requisitesService = inject(RequisitesService);
  private readonly guarantorsFacadeService = inject(GuarantorsFacadeService);
  private readonly variablesFacadeService = inject(VariablesFacadeService);
  private readonly agreementsFacadeService = inject(AgreementsFacadeService);
  private readonly agreementService = inject(AgreementService);
  private readonly documentEntryListFacadeService = inject(DocumentEntryListFacadeService);
  private readonly documentsService = inject(DocumentsService);

  public isCanCreateWithoutBinding() {
    return combineLatest([
      this.variablesFacadeService.selectGuarantorAgreementRelation$.pipe(filter(Boolean)),
      this.guarantorsFacadeService.selectBailContracts$,
    ]).pipe(
      first(),
      map(
        ([guarantorAgreementRelation, bailContracts]) =>
          guarantorAgreementRelation.length === 1 &&
          !bailContracts.some((bailContract) => bailContract.bpCode === guarantorAgreementRelation[0].bpCode),
      ),
    );
  }

  public createDeal(bpCode: string) {
    return this.guarantorsFacadeService.selectGuarantorByBpCode$(bpCode).pipe(
      first(Boolean),
      switchMap((guarantor) => this.createBailContract(guarantor.bpCode, guarantor.orgName)),
    );
  }

  private createBailContract(bpCode: string, orgName: string) {
    return this.variablesFacadeService.selectOrgUnitId$.pipe(
      first(Boolean),
      switchMap((unitCode) =>
        forkJoin([
          this.requisitesService.getPartnerPasspDoc(String(bpCode)),
          this.requisitesService.getOrgUnitAddress(String(unitCode)),
          this.agreementsFacadeService.selectActiveAgreement$.pipe(first(Boolean)),
          this.requisitesService.getSapGetPartnerSpouseData(String(bpCode)),
        ]),
      ),
      switchMap(([partnerPasspDoc, orgUnit, agreement, partnerSpouseData]) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              bailContracts: [
                createBailContract({
                  dealNumber: agreement.dealNumber,
                  partnerName: orgName,
                  bpCode: bpCode,
                  passpDoc: partnerPasspDoc?.passpDoc || '',
                  addressLetter: partnerPasspDoc?.mailingAddr || '',
                  addressLocation: partnerPasspDoc?.locationAddr || '',
                  gtCity: orgUnit.city || '',
                  addressLetterBank: orgUnit.address || '',
                  spouses: partnerSpouseData.map((i: PartnerSpouseData) => ({
                    partner: i.spouseBpNum || '',
                    partnerName: i.spouseName || '',
                    agreement: 'false',
                    idNumber: i.spouseTaxId || '',
                    passP: i.spousePassp || '',
                    passpDate: i.spousePasspDate || '',
                    passpPlace: i.spousePasspPlace || '',
                  })),
                }),
              ],
              guarantorAgreementRelation: [
                {
                  bpCode: bpCode,
                  dealNumber: agreement.dealNumber,
                },
              ],
            },
          }),
        );

        return this.waitForResponse(undefined);
      }),
    );
  }

  createGuaranteeContract(guaranteeContractInfo: BailAgreementRequestDto) {
    return this.agreementService
      .postSapBailAgreementSet({
        requestBody: { ...guaranteeContractInfo },
      })
      .pipe(map((response) => ({ ...guaranteeContractInfo, ...response })));
  }

  updateComment(guarantor: Guarantor) {
    this.store.dispatch(
      setTaskV2ProcessVariable({
        requestBody: {
          guarantor: [guarantor],
        },
      }),
    );
  }

  deleteBailContracts(ids: string[]) {
    return this.guarantorsFacadeService.selectAllBailContracts$.pipe(first()).pipe(
      switchMap((bailContracts) => {
        this.store.dispatch(
          deleteTaskV2ProcessVariable({
            requestBody: {
              bailContracts: bailContracts.filter((bailContract) => ids.some((id) => bailContract.id === id)),
            },
          }),
        );

        return this.waitForResponse(ids);
      }),
    );
  }

  deleteGuarantor(guarantor: Guarantor) {
    return combineLatest([
      this.guarantorsFacadeService.selectAllBailContracts$,
      this.guarantorsFacadeService.selectGuarantorAgreementRelation$,
    ]).pipe(
      first(),
      switchMap(([bailContracts, guarantorAgreementRelation]) => {
        this.store.dispatch(
          deleteTaskV2ProcessVariable({
            requestBody: {
              guarantor: [guarantor],
              guarantorAgreementRelation: guarantorAgreementRelation?.filter((i) => i.bpCode === guarantor.bpCode),
              bailContracts: bailContracts?.filter((i: BailContract) => i.bpCode === guarantor.bpCode),
            },
          }),
        );

        return this.waitForResponse(guarantor);
      }),
    );
  }

  deleteGuarantorWithRelatedFiles(guarantor: Guarantor) {
    return this.documentsService.updateBindingByBindingCode(guarantor.bpCode, '').pipe(
      first(),
      switchMap((updatedDocument) => this.documentsService.deleteFiles(updatedDocument.map((item) => item.documentId))),
      switchMap(() => this.deleteGuarantor(guarantor)),
    );
  }

  deleteGuarantorAndLink(guarantor: Guarantor) {
    return this.documentsService
      .updateBindingByBindingCode(guarantor.bpCode, '')
      .pipe(switchMap(() => this.deleteGuarantor(guarantor)));
  }

  deleteGuarantorWithNewDocumentLink(guarantor: Guarantor, bindingCode: string) {
    return this.documentsService
      .updateBindingByBindingCode(guarantor.bpCode, bindingCode)
      .pipe(switchMap(() => this.deleteGuarantor(guarantor)));
  }
}
