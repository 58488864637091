import { createAction, props } from '@ngrx/store';

export const checkAuth = createAction('[Auth] Check Auth');
export const checkAuthSuccess = createAction('[Auth] Check Auth Success', props<{ isAuthenticated: boolean }>());
export const checkAuthFailure = createAction('[Auth] Check Auth Failure', props<{ isAuthenticated: boolean }>());

export const logout = createAction('[Auth/API] Logout');
export const logoutSuccess = createAction('[Auth/API] Logout Success');
export const logoutFailure = createAction('[Auth/API] Logout Failure', props<{ error: any }>());

// current-user
export const loadCurrentUser = createAction('[Auth/API] Load Current User');
export const loadCurrentUserSuccess = createAction('[Auth/API] Load Current User Success', props<{ user: any }>());
export const loadCurrentUserFailure = createAction('[Auth/API] Load Current User Failure', props<{ error: any }>());

export const updateCurrentUser = createAction('[Auth/API] Update Current User', props<{ userProfile: any }>());
export const updateCurrentUserSuccess = createAction('[Auth/API] Update Current User Success', props<{ user: any }>());
export const updateCurrentUserFailure = createAction('[Auth/API] Update Current User Failure', props<{ error: any }>());

export const createUserSetting = createAction('[Auth/API] Create User Setting', props<{ userSettings: any }>());
export const createUserSettingSuccess = createAction('[Auth/API] Create User Setting Success', props<{ user: any }>());
export const createUserSettingFailure = createAction('[Auth/API] Create User Setting Failure', props<{ error: any }>());

export const updateUserSetting = createAction('[Auth/API] Update User Setting', props<{ userSettings: any }>());
export const updateUserSettingSuccess = createAction('[Auth/API] Update User Setting Success', props<{ user: any }>());
export const updateUserSettingFailure = createAction('[Auth/API] Update User Setting Failure', props<{ error: any }>());

export const upsertUserSetting = createAction('[Auth] Upsert User Setting', props<{ userSettings: any }>());

export const removeUserSetting = createAction('[Auth/API] Remove User Setting', props<{ userProfile: any }>());
export const removeUserSettingSuccess = createAction('[Auth/API] Remove User Setting Success', props<{ user: any }>());
export const removeUserSettingFailure = createAction('[Auth/API] Remove User Setting Failure', props<{ error: any }>());
