import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@sib/shared/da';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authServ: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authServ.isLoggedIn) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
