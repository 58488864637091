/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConditionDto } from '../models/ConditionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ConditionService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all conditions
     * Returns all conditions.
     * @returns ConditionDto all conditions
     * @throws ApiError
     */
    public getApiConditions(): Observable<Array<ConditionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/conditions',
        });
    }

    /**
     * Update condition
     * Updates the condition.
     * @returns any condition updated
     * @throws ApiError
     */
    public putApiConditions({
        requestBody,
    }: {
        /**
         * Condition object that needs to be updated.
         */
        requestBody: ConditionDto,
    }): Observable<{
        id: number;
        sId?: number;
        condId?: number;
        itemLevel?: number;
        startDate?: string;
        endDate?: string;
        rate?: number;
        summ?: number;
        period?: number;
        sumMin?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/conditions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Create condition
     * Method for creating condition.
     * @returns ConditionDto Condition created
     * @throws ApiError
     */
    public postApiConditions({
        requestBody,
    }: {
        /**
         * Condition object that needs to be created.
         */
        requestBody: ConditionDto,
    }): Observable<ConditionDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/conditions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get conditions
     * Get conditions by group ID.
     * @returns ConditionDto conditions found
     * @throws ApiError
     */
    public getApiConditionsConditionGroups({
        id,
    }: {
        /**
         * Condition group ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<ConditionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/conditions/condition-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get conditions
     * Get conditions by type and group IDs.
     * @returns ConditionDto conditions found
     * @throws ApiError
     */
    public getApiConditionsConditionTypesConditionGroups({
        condId,
        groupId,
    }: {
        /**
         * Condition type ID. Cannot be empty.
         */
        condId: number,
        /**
         * Condition group ID. Cannot be empty.
         */
        groupId: number,
    }): Observable<Array<ConditionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/conditions/condition-types/{condId}/condition-groups/{groupId}',
            path: {
                'condId': condId,
                'groupId': groupId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get conditions
     * Get conditions by type ID.
     * @returns ConditionDto conditions found
     * @throws ApiError
     */
    public getApiConditionsConditionTypes({
        id,
    }: {
        /**
         * Condition type ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<ConditionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/conditions/condition-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get condition
     * Get condition by ID.
     * @returns any condition found
     * @throws ApiError
     */
    public getApiConditions1({
        id,
    }: {
        /**
         * Condition ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        sId?: number;
        condId?: number;
        itemLevel?: number;
        startDate?: string;
        endDate?: string;
        rate?: number;
        summ?: number;
        period?: number;
        sumMin?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/conditions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `condition not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete condition
     * Deletes the condition with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiConditions({
        id,
    }: {
        /**
         * condition ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/conditions/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
