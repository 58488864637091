import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as Assessment from './asset-subtypes.actions';
import * as Selectors from './asset-subtypes.selectors';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class AssetSubTypesEffects {
  assetSubTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Assessment.loadAssetSubTypes),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectAssetSubTypes)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadAssetSubTypes()).pipe(
          map((response) =>
            Assessment.loadAssetSubTypesSuccess({
              assetSubTypes: response,
            }),
          ),
          catchError((error) => of(Assessment.loadAssetSubTypesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
