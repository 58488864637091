/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ScopeDto } from '../models/ScopeDto';
import type { TaskEventTypeDTO } from '../models/TaskEventTypeDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScopeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all scopes
     * Returns all scopes.
     * @returns ScopeDto all scopes
     * @throws ApiError
     */
    public getApiScopes(): Observable<Array<ScopeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scopes',
        });
    }

    /**
     * Create scope
     * Method for creating scope.
     * @returns ScopeDto scope created
     * @throws ApiError
     */
    public postApiScopes({
        requestBody,
    }: {
        /**
         * Scope object that needs to be created.
         */
        requestBody: ScopeDto,
    }): Observable<ScopeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scopes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Scope with such scope code already exists`,
                500: `server error`,
            },
        });
    }

    /**
     * Get scope
     * Get scope by code.
     * @returns any scope found
     * @throws ApiError
     */
    public getApiScopes1({
        scopeCode,
    }: {
        /**
         * Scope code. Cannot be empty.
         */
        scopeCode: string,
    }): Observable<{
        scopeCode: string;
        ruScopeCode?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scopes/{scopeCode}',
            path: {
                'scopeCode': scopeCode,
            },
            errors: {
                404: `scope not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update scope
     * Updates the scope with the given code.
     * @returns any scope updated
     * @throws ApiError
     */
    public putApiScopes({
        scopeCode,
        requestBody,
    }: {
        /**
         * Scope code. Cannot be empty.
         */
        scopeCode: string,
        /**
         * Scope object that needs to be updated.
         */
        requestBody: ScopeDto,
    }): Observable<{
        scopeCode: string;
        ruScopeCode?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/scopes/{scopeCode}',
            path: {
                'scopeCode': scopeCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete scope
     * Deletes the scope with the given code.
     * @returns void
     * @throws ApiError
     */
    public deleteApiScopes({
        scopeCode,
    }: {
        /**
         * Scope code. Cannot be empty.
         */
        scopeCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scopes/{scopeCode}',
            path: {
                'scopeCode': scopeCode,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getApiTaskEventTypes(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/task-event-types',
        });
    }

    /**
     * Create an Task Event Type
     * Method for creating an Task Event Type.
     * @returns TaskEventTypeDTO Task Event Type created
     * @throws ApiError
     */
    public postApiTaskEventTypes({
        requestBody,
    }: {
        /**
         * Task Event Type object that needs to be created.
         */
        requestBody: TaskEventTypeDTO,
    }): Observable<TaskEventTypeDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/task-event-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                500: `server error`,
            },
        });
    }

    /**
     * Get Task Event Type
     * Get Task Event Type by ID.
     * @returns TaskEventTypeDTO Task Event Type found
     * @throws ApiError
     */
    public getApiTaskEventTypes1({
        id,
    }: {
        /**
         * Task Event Type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<TaskEventTypeDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/task-event-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Task Event Type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update an Task Event Type
     * Method for updating an Task Event Type.
     * @returns TaskEventTypeDTO Task Event Type updated
     * @throws ApiError
     */
    public putApiTaskEventTypes({
        id,
        requestBody,
    }: {
        /**
         * Task Event Type ID.  Cannot be empty.
         */
        id: number,
        /**
         * Task Event Type object that needs to be updated.
         */
        requestBody: TaskEventTypeDTO,
    }): Observable<TaskEventTypeDTO> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/task-event-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete Task Event Type
     * Deletes the Task Event Type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiTaskEventTypes({
        id,
    }: {
        /**
         * Task Event Type ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/task-event-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Task Event Type not found`,
                500: `server error`,
            },
        });
    }

}
