import { Injectable } from '@angular/core';
import { UsersService } from '@sib/shared/da';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthEffects {
  // checkAuth$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.checkAuth),
  //     switchMap(() => {
  //       return this.authService.isAuthenticated$().pipe(
  //         switchMap((isAuthenticated: boolean) => {
  //           if (isAuthenticated) {
  //             return [AuthActions.checkAuthSuccess({ isAuthenticated }), AuthActions.loadCurrentUser()];
  //           } else {
  //             return [AuthActions.checkAuthFailure({ isAuthenticated })];
  //           }
  //         }),
  //       );
  //     }),
  //   ),
  // );
  //   loadCurrentUser$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.loadCurrentUser),
  //     switchMap(() => {
  //       return this.usersService.loadUserByLogin().pipe(
  //         switchMap((userId: string) => {
  //           return this.myProfileService.loadUserProfile(+userId).pipe(
  //             map((response) => {
  //               return AuthActions.loadCurrentUserSuccess({ user: response.data });
  //             }),
  //             catchError((error) => {
  //               this.toastService.error(error.message);
  //               return of(AuthActions.loadCurrentUserFailure({ error }));
  //             }),
  //           );
  //         }),
  //       );
  //     }),
  //   ),
  // );

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadCurrentUser),
      switchMap(() =>
        this.usersService.loadUserFullInfo().pipe(
          map((response) => AuthActions.loadCurrentUserSuccess({ user: response })),
          catchError((error) => {
            this.toastService.error(error.message);
            return of(AuthActions.loadCurrentUserFailure({ error }));
          }),
        ),
      ),
    ),
  );

  createUserSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.createUserSetting),
      concatLatestFrom((action) => [this.store.select(AuthSelectors.selectCurrentUser)]),
      switchMap(([{ userSettings }, currentUser]) =>
        this.usersService.createUserSetting(currentUser!, userSettings).pipe(
          map((response) => AuthActions.createUserSettingSuccess({ user: response })),
          catchError((error) => {
            this.toastService.error(error.message);
            return of(AuthActions.createUserSettingFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateUserSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateUserSetting),
      concatLatestFrom((action) => [this.store.select(AuthSelectors.selectCurrentUser)]),
      switchMap(([{ userSettings }, currentUser]) =>
        this.usersService.updateUserSetting(currentUser!, userSettings).pipe(
          map((response) => AuthActions.updateUserSettingSuccess({ user: response })),
          catchError((error) => {
            this.toastService.error(error.message);
            return of(AuthActions.updateUserSettingFailure({ error }));
          }),
        ),
      ),
    ),
  );

  upsertUserSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.upsertUserSetting),
      concatLatestFrom((action) => [this.store.select(AuthSelectors.selectCurrentUser).pipe(filter(Boolean))]),
      map(([{ userSettings }, currentUser]) => {
        const isEmptySettings = Object.keys(currentUser.json).length === 0;
        return isEmptySettings
          ? AuthActions.createUserSetting({ userSettings })
          : AuthActions.updateUserSetting({ userSettings });
      }),
    ),
  );

  removeUserSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.removeUserSetting),
      concatLatestFrom((action) => [this.store.select(AuthSelectors.selectCurrentUser).pipe(filter(Boolean))]),
      switchMap(([{ userProfile }, currentUser]) =>
        this.usersService.removeUserSettings(currentUser.id).pipe(
          map((response) => AuthActions.removeUserSettingSuccess({ user: response })),
          catchError((error) => {
            this.toastService.error(error.message);
            return of(AuthActions.removeUserSettingFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private usersService: UsersService,
    private toastService: ToastrService, // private dialogService: DialogService,
  ) {}
}
