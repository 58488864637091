import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { TermsGrantingTrancheFormService } from './terms-granting-tranche-form.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CurrencyMaskDirective } from 'ngx-currency';

@Component({
  selector: 'sib-terms-granting-tranche-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatListModule,

    DigitOnlyModule,
    CurrencyMaskDirective,
  ],
  templateUrl: './terms-granting-tranche-form.component.html',
  styleUrls: ['./terms-granting-tranche-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline',
      },
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TermsGrantingTrancheFormComponent {
  constructor(public formGroup: TermsGrantingTrancheFormService) {}
}
