import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaskSettingsState } from './task-settings.reducer';

export const taskSettingsActions = createActionGroup({
  source: '[TaskV2/API] Task Settings',
  events: {
    updateSettings: props<{ taskSettings: Partial<TaskSettingsState> }>(),
    resetSettings: emptyProps(),
  },
});
