import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Icon } from './assets/output';

@Injectable({ providedIn: 'root' })
export class MatIconsRegistry {
  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public add(...icons: Icon[]) {
    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIconLiteral(icon.name, this.sanitizer.bypassSecurityTrustHtml(icon.data));
    });
  }
}
