import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAssetSubTypes, selectAssetSubTypes } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class AssetSubtypesFacadeService {
  public assetSubTypes$ = this.store.select(selectAssetSubTypes);
  constructor(private store: Store) {}

  public loadAssetSubTypes() {
    this.store.dispatch(loadAssetSubTypes());
  }
}
