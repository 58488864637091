import { inject, Injectable } from '@angular/core';
import { FormInfoDialogModel, InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PromptDialogComponent, PromptDialogData } from './prompt-dialog/prompt-dialog.component';
import { InfoDialogModel, InfoTableDialogComponent } from './info-table-dialog/info-table-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SharedDialogsService {
  private dialog = inject(MatDialog);

  openInfoDialog(data: FormInfoDialogModel, extraOptions?: MatDialogConfig) {
    return this.dialog
      .open<InfoDialogComponent, FormInfoDialogModel, boolean>(InfoDialogComponent, {
        data,
        maxWidth: '600px',
        ...extraOptions,
      })
      .afterClosed();
  }

  openInfoTableDialog<T extends Record<string, unknown>>(data: InfoDialogModel<T>, extraOptions?: MatDialogConfig) {
    return this.dialog.open<InfoTableDialogComponent, InfoDialogModel<T>>(InfoTableDialogComponent, {
      data,
      ...extraOptions,
    });
  }

  openPromptDialog(data: Partial<PromptDialogData>, extraOptions?: MatDialogConfig) {
    const initialData: Partial<PromptDialogData> = {
      title: `${data.comment ? 'Редагувати' : 'Додати'} коментар`,
      message: '',
      closeLabel: 'Закрити',
      confirmLabel: 'Зберегти',
      fieldLabel: 'Коментар',
      placeholder: 'Введіть коментар',
      ...data,
    };
    return this.dialog
      .open<PromptDialogComponent, PromptDialogData, { comment: string }>(PromptDialogComponent, {
        data: initialData,
        maxWidth: '900px',
        ...extraOptions,
      })
      .afterClosed();
  }
}
