import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryDialogComponent } from '@sib/task/shared/feature';
import { DialogService } from '@ngneat/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'sib-history-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './history-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryButtonComponent {
  private readonly dialogService = inject(DialogService);

  @Input()
  public processInstanceId = '';

  history(): void {
    this.dialogService.open(HistoryDialogComponent, {
      data: { processId: this.processInstanceId },
    });
  }
}
