import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadCurrentUser } from './auth.actions';
import { isLoading } from './auth.selectors';
import { first } from 'rxjs';

export const authResolver = () => {
  const store = inject(Store);
  store.dispatch(loadCurrentUser());
  return store.select(isLoading).pipe(first(Boolean));
};
