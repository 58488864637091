import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';
import { PartnerAccount, SapIntegrationService } from '@api/loan-approval';

@Component({
  selector: 'sib-scope-contract-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './scope-contract-formcomponent.html',
  styleUrls: ['./scope-contract-formcomponent.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ScopeContractFormComponent {
  public sapIntegrationService = inject(SapIntegrationService);

  @Input()
  public bpCode!: number | null;

  public guaranteeCategory = [
    {
      type: 'COVERED_GUARANTEE',
      name: 'Покритий',
    },
    {
      type: 'DEPOSIT_GUARANTEE',
      name: 'Гарантія під депозит',
    },
    {
      type: 'RISK_GUARANTEE',
      name: 'Ризикова гарантія',
    },
  ];

  public account$!: Observable<PartnerAccount[]>;

  public ngOnInit() {
    this.account$ = this.sapIntegrationService.getSapGetPartnerAccount({
      bpCode: String(this.bpCode),
    });
  }
}
