/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DocumentProcessDto } from '../models/DocumentProcessDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DocumentProcessService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all document processes
     * Returns all document processes.
     * @returns DocumentProcessDto all document processes
     * @throws ApiError
     */
    public getApiDocumentsDocProc(): Observable<Array<DocumentProcessDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-proc',
        });
    }

    /**
     * Create document process
     * Method for creating document process.
     * @returns DocumentProcessDto document process created
     * @throws ApiError
     */
    public postApiDocumentsDocProc({
        requestBody,
    }: {
        /**
         * Document process object that needs to be created.
         */
        requestBody: DocumentProcessDto,
    }): Observable<DocumentProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/documents/doc-proc',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get document process
     * Get document process by process ID.
     * @returns any document process found
     * @throws ApiError
     */
    public getApiDocumentsDocProcGetByProcId({
        procId,
    }: {
        /**
         * Process ID. Cannot be empty.
         */
        procId: number,
    }): Observable<{
        id: number;
        processId?: number;
        documentGroupId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-proc/get-by-proc-id/{procId}',
            path: {
                'procId': procId,
            },
            errors: {
                404: `document process not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get document process
     * Get document process by ID.
     * @returns any document process found
     * @throws ApiError
     */
    public getApiDocumentsDocProc1({
        id,
    }: {
        /**
         * Document process ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        processId?: number;
        documentGroupId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-proc/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `document process not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update document process
     * Updates the document process with the given ID.
     * @returns any document process updated
     * @throws ApiError
     */
    public putApiDocumentsDocProc({
        id,
        requestBody,
    }: {
        /**
         * Document process ID. Cannot be empty.
         */
        id: number,
        /**
         * Document process object that needs to be updated.
         */
        requestBody: DocumentProcessDto,
    }): Observable<{
        id: number;
        processId?: number;
        documentGroupId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/documents/doc-proc/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete document process
     * Deletes the document process with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDocumentsDocProc({
        id,
    }: {
        /**
         * Document process ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/documents/doc-proc/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
