import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadProductCategories, selectProductCategoriesIsLoading } from '@sib/shared/store';
import { filter } from 'rxjs';

export const productCategoriesResolver = () => {
  const store = inject(Store);
  store.dispatch(loadProductCategories());
  return store.select(selectProductCategoriesIsLoading).pipe(filter((isLoading) => !isLoading));
};
