import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAssetAgreementRelation } from '../../+state/variables/variables.selectors';
import { selectAssetAgreementRelationByActiveAgreement } from './asset-agreement-relation.selectors';

@Injectable({
  providedIn: 'root',
})
export class AssetAgreementRelationFacadeService {
  private store = inject(Store);

  getAllAssetAgreementRelation$ = this.store.select(selectAssetAgreementRelation);
  selectAssetAgreementRelationByActiveAgreement$ = this.store.select(selectAssetAgreementRelationByActiveAgreement);
}
