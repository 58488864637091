import { createReducer, on } from '@ngrx/store';
import * as CarBrands from './car-brands.actions';
import { CarBrandDto } from '@api/dictionaries';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const carBrandsFeatureKey = 'car-brands';
export const adapter: EntityAdapter<CarBrandDto> = createEntityAdapter<CarBrandDto>({
  selectId: (carBrand: CarBrandDto) => carBrand.id,
  sortComparer: false,
});

export interface State extends EntityState<CarBrandDto> {
  isLoading: boolean;
  error: unknown;
}

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(CarBrands.loadCarBrands, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CarBrands.loadCarBrandsSuccess, (state, { carBrands }) => ({
    ...adapter.setAll(carBrands, state),
    isLoading: false,
    carBrands,
  })),
  on(CarBrands.loadCarBrandsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
