import { createReducer, on } from '@ngrx/store';
import * as DictionaryGroupsActions from './dictionary-groups.actions';
import { DictionaryGroupDTO } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const dictionaryGroupsFeatureKey = 'dictionary-groups';

export interface State extends EntityState<DictionaryGroupDTO> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<DictionaryGroupDTO>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(DictionaryGroupsActions.loadDictionaryGroups, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DictionaryGroupsActions.loadDictionaryGroupsSuccess, (state, { dictionaryGroups }) => ({
    ...adapter.setAll(dictionaryGroups, state),
    isLoading: false,
  })),
  on(DictionaryGroupsActions.loadDictionaryGroupsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDictionaryGroups = selectAll;
export const selectIsLoading = (state: State) => state.isLoading;
