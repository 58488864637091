import { createSelector } from '@ngrx/store';
import * as fromConsLevel from './consideration-level.reducer';
import { selectConsiderationLevelState } from '../+shell/reducers.index';

export const selectConsiderationLevelListIsLoading = createSelector(
  selectConsiderationLevelState,
  fromConsLevel.selectIsLoading,
);

export const selectConsiderationLevelById = (id: number) =>
  createSelector(selectConsiderationLevelListEntities, (considerationLevelEntities) => considerationLevelEntities[id]);

export const selectConsiderationLevelListEntities = createSelector(
  selectConsiderationLevelState,
  fromConsLevel.selectEntities,
);

export const selectAllConsiderationLevelList = createSelector(selectConsiderationLevelState, fromConsLevel.selectAll);
export const selectIsAllConsiderationLevelListLoading = createSelector(
  selectConsiderationLevelState,
  (state) => state.isLoading,
);
