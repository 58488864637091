/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EffectiveRoleDto } from '../models/EffectiveRoleDto';
import type { MainAndEffectiveRolesIdsDto } from '../models/MainAndEffectiveRolesIdsDto';
import type { RoleDto } from '../models/RoleDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RoleService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Roles by IDs
     * Returns the list of Roles for the specified IDs.
     * @returns RoleDto List of Roles or empty list
     * @throws ApiError
     */
    public getApiRoles({
        roleId,
    }: {
        /**
         * The list of Role IDs.
         */
        roleId: Array<string>,
    }): Observable<Array<RoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles',
            query: {
                'role-id': roleId,
            },
        });
    }

    /**
     * Add role
     * Creates a new role object.
     * @returns RoleDto Role created successfully
     * @throws ApiError
     */
    public postApiRoles({
        requestBody,
    }: {
        /**
         * Role data.
         */
        requestBody: RoleDto,
    }): Observable<RoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Role with such id already exists`,
            },
        });
    }

    /**
     * Find Main and Effective Roles by login
     * Returns the list of Main and Effective Roles for the specified login.
     * @returns MainAndEffectiveRolesIdsDto Main and effective roles
     * @throws ApiError
     */
    public getApiRolesFindMainAndEffectiveRolesIds({
        login,
    }: {
        /**
         * The employee login. Cannot be empty.
         */
        login: string,
    }): Observable<MainAndEffectiveRolesIdsDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles/find-main-and-effective-roles-ids/{login}',
            path: {
                'login': login,
            },
        });
    }

    /**
     * Get groups by parameters
     * Returns the list of groups for the specified parameters.
     * @returns any List of group or empty list
     * @throws ApiError
     */
    public getApiRolesGroupByParam({
        direction,
        id,
        ids,
        login,
        name,
        nameLike,
        orderedProperty,
        propertyName,
        tenantId,
        type,
    }: {
        direction?: string,
        id?: string,
        ids?: string,
        login?: string,
        name?: string,
        nameLike?: string,
        orderedProperty?: boolean,
        propertyName?: string,
        tenantId?: string,
        type?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles/group-by-param',
            query: {
                'direction': direction,
                'id': id,
                'ids': ids,
                'login': login,
                'name': name,
                'nameLike': nameLike,
                'orderedProperty': orderedProperty,
                'propertyName': propertyName,
                'tenantId': tenantId,
                'type': type,
            },
        });
    }

    /**
     * Get Role by name
     * Returns the Role object with the specified name.
     * @returns RoleDto Role with such name exist
     * @throws ApiError
     */
    public getApiRolesName({
        roleName,
    }: {
        /**
         * The role name. Cannot be empty.
         */
        roleName: string,
    }): Observable<RoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles/name/{roleName}',
            path: {
                'roleName': roleName,
            },
            errors: {
                404: `Role with such name not found`,
            },
        });
    }

    /**
     * Get Role by ID
     * Returns the Role object with the specified ID.
     * @returns RoleDto Role exist
     * @throws ApiError
     */
    public getApiRoles1({
        id,
    }: {
        /**
         * The role ID. Cannot be empty.
         */
        id: string,
    }): Observable<RoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Role with such id not found`,
            },
        });
    }

    /**
     * Update role
     * Updates the role with the specified ID.
     * @returns RoleDto Role updated
     * @throws ApiError
     */
    public putApiRoles({
        id,
        requestBody,
    }: {
        /**
         * The role ID. Cannot be empty.
         */
        id: string,
        /**
         * Role data.
         */
        requestBody: RoleDto,
    }): Observable<RoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Role DTO has wrong ID`,
                404: `Role not found`,
            },
        });
    }

    /**
     * Delete role by ID.
     * Deletes the role object with the specified ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRoles({
        id,
    }: {
        /**
         * The role ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/roles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Find effective Role by ID and Unit Code
     * Returns the effective Role for the specified ID and Unit Code.
     * @returns EffectiveRoleDto EffectiveRoleDto with effective role value
     * @throws ApiError
     */
    public getApiRolesFindEffectiveRole({
        roleId,
        unitCode,
    }: {
        /**
         * The role ID. Cannot be empty.
         */
        roleId: string,
        /**
         * The unit code. Cannot be empty.
         */
        unitCode: number,
    }): Observable<EffectiveRoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles/{role-id}/find-effective-role/{unit-code}',
            path: {
                'role-id': roleId,
                'unit-code': unitCode,
            },
        });
    }

}
