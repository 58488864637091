import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Vehicle } from '@api/loan-approval';

const vehiclesFeatureKey = 'vehicles';

export type VehiclesState = EntityState<Vehicle>;

export const adapter = createEntityAdapter<Vehicle>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.vehicles || [], state)),
);

export const vehicleFeature = createFeature({
  name: vehiclesFeatureKey,
  reducer,
  extraSelectors: ({ selectVehiclesState }) => ({
    ...adapter.getSelectors(selectVehiclesState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleFeature;
