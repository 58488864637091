import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TermDocumentaryOperationGroupService } from './term-documentary-operation-group.service';

@Component({
  selector: 'sib-term-documentary-operation-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './term-documentary-operation-form.component.html',
  styleUrls: ['./term-documentary-operation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TermDocumentaryOperationFormComponent {
  public terms = [
    { id: 'DAYS', name: 'Дні' },
    { id: 'MONTHS', name: 'Місяці' },
  ];

  constructor(public form: TermDocumentaryOperationGroupService) {}
}
