/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AutoCheckRunSetRequestDto } from '../models/AutoCheckRunSetRequestDto';
import type { AutoCheckRunSetResponseDto } from '../models/AutoCheckRunSetResponseDto';
import type { AutoCheckSetForProductDto } from '../models/AutoCheckSetForProductDto';
import type { AutoCheckSetResultDto } from '../models/AutoCheckSetResultDto';
import type { AutoCheckSetResultLogDto } from '../models/AutoCheckSetResultLogDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SapAutoCheckService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Run auto check set
     * Run auto check set.
     * @returns AutoCheckRunSetResponseDto details of the executed set including set result id
     * @throws ApiError
     */
    public postSapAutoCheckRunSet({
        requestBody,
    }: {
        /**
         * Auto check run set request
         */
        requestBody: AutoCheckRunSetRequestDto,
    }): Observable<AutoCheckRunSetResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/auto-check/run-set',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                461: `set not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get auto check set for product
     * Get auto check set for product by product code or user role.
     * @returns AutoCheckSetForProductDto sets for product found
     * @throws ApiError
     */
    public getSapAutoCheckSetForProduct({
        productCode,
        userRole,
    }: {
        /**
         * Product code
         */
        productCode: string,
        /**
         * User role
         */
        userRole: string,
    }): Observable<Array<AutoCheckSetForProductDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/auto-check/set-for-product',
            query: {
                'product-code': productCode,
                'user-role': userRole,
            },
            errors: {
                400: `bad request`,
                461: `sets for product not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get auto check set result
     * Get auto check set result by result ID.
     * @returns AutoCheckSetResultDto set result found
     * @throws ApiError
     */
    public getSapAutoCheckSetResult({
        resultId,
    }: {
        /**
         * Result ID
         */
        resultId: string,
    }): Observable<Array<AutoCheckSetResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/auto-check/set-result',
            query: {
                'result-id': resultId,
            },
            errors: {
                400: `bad request`,
                461: `result for set not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get auto check set result log
     * Get auto check set result log by result ID and check ID.
     * @returns AutoCheckSetResultLogDto set result found
     * @throws ApiError
     */
    public getSapAutoCheckSetResultLog({
        checkId,
        resultId,
    }: {
        /**
         * Check ID
         */
        checkId: string,
        /**
         * Result ID
         */
        resultId: string,
    }): Observable<Array<AutoCheckSetResultLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/auto-check/set-result-log',
            query: {
                'check-id': checkId,
                'result-id': resultId,
            },
            errors: {
                400: `bad request`,
                461: `result log for set not found`,
                500: `server error`,
            },
        });
    }

}
