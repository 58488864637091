import { createSelector } from '@ngrx/store';
import { selectRouterParam } from '@sib/shared/store';
import { selectAllAgreementGuaranties } from '../../+state/agreement-guaranty/agreement-guaranty.selectors';
import { selectAllAgreements } from '../../+state/agreements/agreement.selectors';
import { selectAllAssets } from '../../+state/asset/asset.selectors';
import { selectPropertyOwnerEntities } from '../../+state/property-owner/property-owner.selectors';
import { selectAssetAgreementRelation } from '../../+state/variables/variables.selectors';

export const selectActiveContractId = selectRouterParam('contractId');

export const selectActiveAgreementGuaranty = createSelector(
  selectActiveContractId,
  selectAllAgreementGuaranties,
  (providingId: string, agreementGuarantyList) => agreementGuarantyList.find((i) => i.cagId === providingId),
);

export const selectAgreementGuaranties = createSelector(
  selectAllAgreementGuaranties,
  selectAllAgreements,
  selectAllAssets,
  selectPropertyOwnerEntities,
  selectAssetAgreementRelation,
  (agreementGuaranties, agreements, assets, propertyOwners, assetAgreementRelation) => {
    if (assets.length) {
      const propertyOwner = propertyOwners[assets[0].id];

      return agreementGuaranties.map((agreementGuaranty) => ({
        ...agreementGuaranty,
        asset: assets?.filter((item) => item.cagId === agreementGuaranty.cagId),
        bpNumber: propertyOwner?.partnerBpCode || '',
        partnerName: propertyOwner?.partnerName || '',
      }));
    }

    return [];
  },
);
