/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SyncService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Sync all plugins
     * Synchronizes all plugins.
     * @returns any Successful operation
     * @throws ApiError
     */
    public postApiSync(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/sync',
        });
    }

    /**
     * Get plugin names
     * Returns a list of plugin names.
     * @returns string Successful operation
     * @throws ApiError
     */
    public getApiSyncPluginNames(): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/sync/plugin-names',
        });
    }

    /**
     * Synchronize group plugins
     * Synchronize plugins for a specific group.
     * @returns any Synchronization completed successfully.
     * @throws ApiError
     */
    public postApiSyncSyncGroup({
        id,
    }: {
        /**
         * The ID of the group to synchronize plugins for.
         */
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/sync/sync-group/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Sync plugin
     * Synchronizes a specific plugin.
     * @returns any Successful operation
     * @throws ApiError
     */
    public postApiSyncSyncPlug({
        pluginCode,
    }: {
        /**
         * The code of the plugin to synchronize.
         */
        pluginCode: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/sync/sync-plug/{pluginCode}',
            path: {
                'pluginCode': pluginCode,
            },
            errors: {
                400: `Bad request`,
            },
        });
    }

}
