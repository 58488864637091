<h3 mat-dialog-title>{{ data.titleHidden ? '' : data.title }}</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <div class="description" [innerHtml]="data.description"></div>
</div>

<div mat-dialog-actions>
  <button data-e2e="infoNo" type="reset" *ngIf="!data.buttonNoHidden" mat-stroked-button [mat-dialog-close]="false">
    {{ data.buttonTitleNo }}
  </button>

  <button
    data-e2e="infoYes"
    type="submit"
    *ngIf="!data.buttonYesHidden"
    mat-raised-button
    (click)="dialogRef.close(true)"
    color="primary"
  >
    {{ data.buttonTitleYes }}
  </button>
</div>
