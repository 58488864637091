import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as CurrentLoansActions from '../../+state/current-credits/current-credits.actions';
import { ToastrService } from 'ngx-toastr';
import { SapIntegrationService } from '@api/loan-approval';
import { setTaskV2ProcessVariable } from '../../+state/task/task.actions';
import { BorrowerGroupFacade } from '../borrower-groups/borrower-group.facade';
import { CurrentCreditsFacade } from '../current-credits/current-credits.facade';

@Injectable()
export class CurrentCreditsEffects {
  loadActualClientLoans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentLoansActions.loadActualClientLoans),
      concatLatestFrom((action) => [
        this.currentCreditsFacade.selectAllCurrentCredits$,
        this.borrowerGroupFacade.selectAllBorrowerGroups$,
      ]),
      switchMap(([action, currentLoans, borrowerGroup]) => {
        if (currentLoans.length) {
          return of(currentLoans).pipe(
            map((response) => setTaskV2ProcessVariable({ requestBody: { currentCredits: response } })),
            catchError((error) => {
              this.toasrtService.error(error?.error?.message);
              return of(error);
            }),
          );
        } else {
          const reqArray = borrowerGroup.map((i) =>
            this.sapIntegrationService.getSapActualClientLoans({ bpCode: i.buPartner! }).pipe(
              map((res) => res),
              catchError((error) => of([])),
            ),
          );
          return forkJoin(reqArray).pipe(
            switchMap((response) => {
              const responseArray = response.flatMap((item) => item);
              return [setTaskV2ProcessVariable({ requestBody: { currentCredits: responseArray } })];
            }),
            catchError((error) => {
              this.toasrtService.error(error?.error?.message);
              return of(error);
            }),
          );
        }
      }),
    ),
  );

  loadSapActualClientLoans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentLoansActions.loadSapActualClientLoans),
      concatLatestFrom((action) => [
        this.borrowerGroupFacade.selectAllBorrowerGroups$,
        this.currentCreditsFacade.selectCurrentCreditsEntities$,
      ]),
      switchMap(([action, allBorrowerGroups, loansEntities]) => {
        const reqArray = allBorrowerGroups.map((i) =>
          this.sapIntegrationService.getSapActualClientLoans({ bpCode: i.buPartner! }).pipe(
            map((res) => res),
            catchError(() => of([])),
          ),
        );
        return forkJoin(reqArray).pipe(
          switchMap((response) => {
            const responseArray: any[] = [];
            response.forEach((i) => responseArray.push(...i));
            const currentLoans = responseArray.map((loan) => ({
              ...loan,
              comment: loansEntities[loan.ranl]?.comment || '',
            }));
            return [setTaskV2ProcessVariable({ requestBody: { currentCredits: currentLoans } })];
          }),
          catchError((error) => {
            this.toasrtService.error(error?.error?.message);
            return of(error);
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private toasrtService: ToastrService,
    private sapIntegrationService: SapIntegrationService,
    private borrowerGroupFacade: BorrowerGroupFacade,
    private currentCreditsFacade: CurrentCreditsFacade,
  ) {}
}
