import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadConditionGroups } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class ConditionGroupsFacadeService {
  constructor(private store: Store) {}

  public loadConditionGroups() {
    this.store.dispatch(loadConditionGroups());
  }
}
