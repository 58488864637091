import { createAction, props } from '@ngrx/store';
import { OrgUnitDto } from '@api/loan-org-structure';

export const loadOrgUnits = createAction('[Dictionaries/API] Load Org Units');
export const loadOrgUnitsSuccess = createAction(
  '[Dictionaries/API] Load Org Units Success',
  props<{ orgUnits: OrgUnitDto[] }>(),
);
export const loadOrgUnitsFailure = createAction(
  '[Dictionaries/API] Load Org Units Failure',
  props<{ error: unknown }>(),
);
