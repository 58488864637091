import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CheckupsActions from './checkups.actions';
import * as CheckupsSelectors from './checkups.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CheckupsEffects {
  loadAssetTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckupsActions.loadCheckups),
      concatLatestFrom((action) => [this.store$.select(CheckupsSelectors.selectCheckups)]),
      switchMap(([action, allCheckups]) =>
        allCheckups.length ? of(allCheckups) : this.dictionariesService.loadCheckups(),
      ),
      switchMap((response) => [
        CheckupsActions.loadCheckupsSuccess({
          checkups: response,
        }),
      ]),
      catchError((error) => {
        this.toastrService.error(error?.error?.message);
        return of(CheckupsActions.loadCheckupsFailure({ error }));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
