import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ObligationsBorrowerFormService } from './obligations-borrower-form.service';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CashFlowZKRSTableComponent } from '../../value-accessors';
import { CurrencyInputViewComponent } from '@sib/shared/ui';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CurrencyMaskDirective } from 'ngx-currency';

@Component({
  selector: 'sib-obligations-borrower-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    DigitOnlyModule,

    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTableModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    CurrencyInputViewComponent,
    CashFlowZKRSTableComponent,
    CurrencyMaskDirective,
  ],
  templateUrl: './obligations-borrower-form.component.html',
  styleUrls: ['./obligations-borrower-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline',
      },
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ObligationsBorrowerFormComponent {
  constructor(public formGroup: ObligationsBorrowerFormService) {}
}
