/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { TaskChecksErrorEntity } from '../models/TaskChecksErrorEntity';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TaskChecksService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Executes checks on process human task by event
     * @returns TaskChecksErrorEntity Check is successful if array will be empty
     * @throws ApiError
     */
    public postTaskChecksEvent({
        taskEvent,
        taskId,
    }: {
        /**
         * The name of the event for which you need to check the task
         */
        taskEvent: 'ASSIGN' | 'COMPLETE',
        /**
         * The ID of the task to get variables for.
         */
        taskId: string,
    }): Observable<Array<TaskChecksErrorEntity>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task-checks/{taskId}/event/{taskEvent}',
            path: {
                'taskEvent': taskEvent,
                'taskId': taskId,
            },
        });
    }

}
