import { ConsiderationLevelEffects } from '../consideration-level/consideration-level.effects';
import { LawCaseTypesEffects } from '../law-case-types/law-case-types.effects';
import { LoanClassesEffects } from '../loan-classes/loan-classes.effects';
import { LoanTypesEffects } from '../loan-types/loan-types.effects';
import { ProductEffects } from '../product/product.effects';
import { BpRolesEffects } from '../bp-roles/bp-roles.effects';
import { BpRoleCourtCasesEffects } from '../bp-role-court-cases/bp-role-court-cases.effects';
import { ProposalsTypesEffects } from '../proposals-types/proposals-types.effects';
import { CurrenciesEffects } from '../currencies/currencies.effects';
import { AssetTypesEffects } from '../asset-types/asset-types.effects';
import { AssetCategoryEffects } from '../asset-category/asset-category.effects';
import { ConditionGroupsEffects } from '../condition-groups/condition-groups.effects';
import { UiTabsEffects } from '../ui-tabs/ui-tabs.effects';
import { ProtocolConditionTypesEffects } from '../protocol-condition-types/protocol-condition-types.effects';
import { EmployeesEffects } from '../employees/employees.effects';
import { MessageTypeEffects } from '../message-type/message-type.effects';
import { ErrorsEffects } from '../errors/errors.effects';
import { DocumentsEffects } from '../documents/documents.effects';
import { OrgUnitsEffects } from '../org-units/org-units.effects';
import { ParticipantsEffects } from '../participants/participants.effects';
import { RolesEffects } from '../roles/roles.effects';
import { VotingOptionsEffects } from '../voting-options/voting-options.effects';
import { ReportsEffects } from '../reports/reports.effects';
import { DictionariesEffects } from '../dictionaries/dictionaries.effects';
import { BankServicesEffects } from '../bank-services/bank-services.effects';
import { DictionaryGroupsEffects } from '../dictionary-groups/dictionary-groups.effects';
import { ProductCategoriesEffects } from '../product-categories/product-categories.effects';
import { AssessmentTypesEffects } from '../assessment-types/assessment-types.effects';
import { SODEffects } from '../sods/sods.effects';
import { RequestTypesEffects } from '../request-types/request-types.effects';
import { SourceAssessmentsEffects } from '../source-assessments/source-assessments.effects';
import { AssessmentApproachesEffects } from '../assessment-approaches/assessment-approaches.effects';
import { MonitoringResultsEffects } from '../monitoring-results/monitoring-results.effects';
import { BelongingObjectsEffects } from '../belonging-objects/belonging-objects.effects';
import { RegionsEffects } from '../regions/regions.effects';
import { CarBrandsEffects } from '../car-brands/car-brands.effects';
import { UseTypesEffects } from '../use-types/use-types.effects';
import { TypeDocUseLandsEffects } from '../type-doc-use-lands/type-doc-use-lands.effects';
import { TypeDocsOwnershipEffects } from '../type-docs-ownership/type-docs-ownership.effects';
import { EquipmentTypesEffects } from '../equipment-types/equipment-types.effects';
import { MeasurementUnitsEffects } from '../measurement-units/measurement-units.effects';
import { AssessmentKindsEffects } from '../assessment-kinds/assessment-kinds.effects';
import { AssetSubTypesEffects } from '../asset-subtypes/asset-subtypes.effects';
import { CheckupsEffects } from '../checkups/checkups.effects';
import { ControlGroupsEffects } from '../control-groups/control-groups.effects';
import { DecisionsEffects } from '../decisions/decisions.effects';
import { RiskSegmentsEffects } from '../risk-segments/risk-segments.effects';
import { ObligationTypesEffects } from '../obligation-types/obligation-types.effects';
import { AgreementsCountEffects } from '../agreements-count/agreements-count.effects';
import { ConditionTypesEffects } from '../condition-types/condition-types.effects';
import { ProductCategoryTypesEffects } from '../product-category-types/product-category-types.effects';
import { PromotionEffects } from '../promotion/promotion.effects';

export const effects = [
  ConsiderationLevelEffects,
  LoanTypesEffects,
  LoanClassesEffects,
  ProductEffects,
  LawCaseTypesEffects,
  BpRolesEffects,
  BpRoleCourtCasesEffects,
  ProposalsTypesEffects,
  CurrenciesEffects,
  AssetTypesEffects,
  ConditionGroupsEffects,
  UiTabsEffects,
  ProtocolConditionTypesEffects,
  EmployeesEffects,
  MessageTypeEffects,
  ErrorsEffects,
  OrgUnitsEffects,
  DocumentsEffects,
  ParticipantsEffects,
  RolesEffects,
  VotingOptionsEffects,
  ReportsEffects,
  DictionariesEffects,
  AssetCategoryEffects,
  BankServicesEffects,
  DictionaryGroupsEffects,
  ProductCategoriesEffects,
  BankServicesEffects,
  AssetCategoryEffects,
  AssessmentTypesEffects,
  SODEffects,
  RequestTypesEffects,
  SourceAssessmentsEffects,
  AssessmentApproachesEffects,
  MonitoringResultsEffects,
  BelongingObjectsEffects,
  RegionsEffects,
  CarBrandsEffects,
  UseTypesEffects,
  TypeDocUseLandsEffects,
  TypeDocsOwnershipEffects,
  EquipmentTypesEffects,
  MeasurementUnitsEffects,
  AssessmentKindsEffects,
  AssetSubTypesEffects,
  BankServicesEffects,
  CheckupsEffects,
  ControlGroupsEffects,
  DecisionsEffects,
  RiskSegmentsEffects,
  ObligationTypesEffects,
  AgreementsCountEffects,
  ConditionTypesEffects,
  ProductCategoryTypesEffects,
  PromotionEffects,
];
