import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as ReportsActions from './reports.actions';
import { ReportDto } from '@api/dictionaries';

export const reportsFeatureKey = 'reports';

export interface State extends EntityState<ReportDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<ReportDto>({
  selectId: (report) => report.reportId,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ReportsActions.loadReports, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ReportsActions.loadReportsSuccess, (state, { reports }) => ({
    ...adapter.setAll(reports, state),
    isLoading: false,
  })),

  on(ReportsActions.loadReportsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
