import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { CurrenciesFacadeService } from '@sib/shared/store';
import { CREDIT_AGREEMENT_CONTRACT_CONFIG, CreditAgreementContractConfig } from './token';
import { CurrencyMaskDirective } from 'ngx-currency';

@Component({
  selector: 'sib-credit-agreement-contract-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    CurrencyMaskDirective,
  ],
  templateUrl: './credit-agreement-contract-form.component.html',
  styleUrls: ['./credit-agreement-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CreditAgreementContractFormComponent {
  constructor(
    public currenciesFacadeService: CurrenciesFacadeService,
    @Inject(CREDIT_AGREEMENT_CONTRACT_CONFIG) public creditAgreementContractConfig: CreditAgreementContractConfig,
  ) {}
}
