/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { JwtDto } from '../models/JwtDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TokenService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Retrieve JWT token for authenticated user
     * @returns JwtDto JWT token successfully retrieved
     * @throws ApiError
     */
    public getToken(): Observable<JwtDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/token',
        });
    }

}
