import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import * as EquipmentTypes from './equipment-types.actions';
import * as Selectors from './equipment-types.selectors';

@Injectable()
export class EquipmentTypesEffects {
  equipmentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentTypes.loadEquipmentTypes),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectEquipmentTypes)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadEquipmentTypes()).pipe(
          map((response) =>
            EquipmentTypes.loadEquipmentTypesSuccess({
              equipmentTypes: response,
            }),
          ),
          catchError((error) => of(EquipmentTypes.loadEquipmentTypesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
