import { createReducer, on } from '@ngrx/store';
import * as DictionariesActions from './dictionaries.actions';
import { DictionaryDTO } from '@api/loan-org-structure';

export const dictionariesFeatureKey = 'dictionaries';

export interface State {
  isLoading: boolean;
  dictionaries: any[];
  dictionariesOrgstructure: DictionaryDTO[];
}

export const initialState: State = {
  isLoading: false,
  dictionaries: [],
  dictionariesOrgstructure: [],
};

export const reducer = createReducer(
  initialState,

  on(DictionariesActions.loadDictionaries, DictionariesActions.reloadDictionaries, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(
    DictionariesActions.loadDictionariesSuccess,
    DictionariesActions.reloadDictionariesSuccess,
    (state, { dictionaries }) => ({
      ...state,
      dictionaries,
      isLoading: false,
    }),
  ),

  on(
    DictionariesActions.loadDictionariesFailure,
    DictionariesActions.reloadDictionariesFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),

  on(DictionariesActions.loadDictionariesOrgstructure, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DictionariesActions.loadDictionariesOrgstructureSuccess, (state, { dictionariesOrgstructure }) => ({
    ...state,
    isLoading: false,
    dictionariesOrgstructure,
  })),
  on(DictionariesActions.loadDictionariesOrgstructureFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const selectDictionaries = (state: State) => state.dictionaries;
export const selectDictionariesOrgstructure = (state: State) => state.dictionariesOrgstructure;
export const selectIsLoading = (state: State) => state.isLoading;
