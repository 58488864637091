import { ChangeDetectorRef, Directive, ElementRef, HostBinding, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StickyControllerService } from './sticky-controller.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { markForCheck, RectResizeObserver } from '@sib/shared/util';
import { v4 } from 'uuid';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[sibStickyBar]',
  standalone: true,
})
export class StickyBarDirective implements OnInit, OnDestroy {
  private readonly id = v4();
  private readonly cdr = inject(ChangeDetectorRef);

  @HostBinding('style.top')
  private top = '0';

  @HostBinding('style')
  public styles = {
    position: 'sticky',
    zIndex: 10,
    boxSizing: 'content-box',
  };

  @Input()
  @HostBinding('class.mat-elevation-z2')
  public elevation = false;

  constructor(
    private stickyControllerService: StickyControllerService,
    private elementRef: ElementRef,
    private rectResizeObserver: RectResizeObserver,
  ) {}

  public ngOnInit() {
    this.rectResizeObserver
      .resize$(this.elementRef.nativeElement)
      .pipe(
        map((resizeEntry) => resizeEntry.contentRect.height),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((height) => {
        this.stickyControllerService.upsertStickyItem(this.id, height);
      });

    this.stickyControllerService.stickyItems$
      .pipe(
        map((items) => Object.entries(items)),
        markForCheck(this.cdr),
        untilDestroyed(this),
      )
      .subscribe((items) => {
        const currentIndex = items.findIndex(([id]) => id === this.id);
        this.top = items.reduce((acc, [id, height], index) => (index < currentIndex ? acc + height : acc), 0) + 'px';
      });
  }

  public ngOnDestroy() {
    this.stickyControllerService.deleteStickyItem(this.id);
  }
}
