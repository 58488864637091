import { createFeature, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { UserModel } from '@sib/shared/da';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: UserModel | null;
  error: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export const initialState: AuthState = {
  user: null,
  error: null,
  isAuthenticated: false,
  isLoading: false,
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.checkAuthSuccess, AuthActions.checkAuthFailure, (state, { isAuthenticated }) => ({
    ...state,
    isAuthenticated,
  })),

  on(AuthActions.logout, (state) => initialState),

  // !current user
  on(AuthActions.loadCurrentUser, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(AuthActions.loadCurrentUserSuccess, (state, { user }) => ({
    ...state,
    user,
    error: null,
    isLoading: false,
  })),
  on(AuthActions.loadCurrentUserFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(AuthActions.updateCurrentUser, (state, { userProfile }) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(AuthActions.updateCurrentUserSuccess, (state, { user }) => ({
    ...state,
    user,
    error: null,
    isLoading: false,
  })),
  on(AuthActions.updateCurrentUserFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(AuthActions.createUserSetting, (state, { userSettings }) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(AuthActions.createUserSettingSuccess, (state, { user }) => ({
    ...state,
    user,
    error: null,
    isLoading: false,
  })),
  on(AuthActions.createUserSettingFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(AuthActions.updateUserSetting, (state, { userSettings }) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(AuthActions.updateUserSettingSuccess, (state, { user }) => ({
    ...state,
    user,
    error: null,
    isLoading: false,
  })),
  on(AuthActions.updateUserSettingFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(AuthActions.removeUserSetting, (state, { userProfile }) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(AuthActions.removeUserSettingSuccess, (state, { user }) => ({
    ...state,
    user,
    error: null,
    isLoading: false,
  })),
  on(AuthActions.removeUserSettingFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer: authReducer,
});
