import { Asset, Deposit, Equipment, Good, Land, PropertyOwner, Realty, Vehicle } from '@api/loan-approval';

export type DepositDto = Asset & PropertyOwner & Deposit & { assetCategory: 'deposit' };
export type VehicleDto = Asset & PropertyOwner & Vehicle & { assetCategory: 'vehicles' };
export type RealtyDto = Asset & PropertyOwner & Realty & { assetCategory: 'realty'; owners: PropertyOwner[] };
export type LandDto = Asset & PropertyOwner & Land & { assetCategory: 'land' };
export type GoodDto = Asset & PropertyOwner & Good & { assetCategory: 'goods' };
export type EquipmentDto = Asset & PropertyOwner & Equipment & { assetCategory: 'equipment' };

export type FullProvidingInfo = DepositDto | VehicleDto | RealtyDto | LandDto | GoodDto | EquipmentDto;
