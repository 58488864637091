import { createReducer, on } from '@ngrx/store';
import * as BelongingObjects from './belonging-objects.actions';
import { BelongingObjectDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const belongingObjectsFeatureKey = 'belonging-objects';

export interface State extends EntityState<BelongingObjectDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<BelongingObjectDto>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(BelongingObjects.loadBelongingObjects, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(BelongingObjects.loadBelongingObjectsSuccess, (state, { belongingObjects }) => ({
    ...adapter.setAll(belongingObjects, state),
    isLoading: false,
  })),
  on(BelongingObjects.loadBelongingObjectsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectBelongingObjectsState = selectAll;
