<mat-card formGroupName="assetDeals">
  <mat-card-header>
    <mat-card-title>{{ termsSecuringLoanConfig.title }}</mat-card-title>

    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="addNewItem()"
      [disabled]="termsSecuringLoanGroupService.disabled"
    >
      <mat-icon>assignment_add</mat-icon>
      Додати запис
    </button>
  </mat-card-header>

  <mat-card-content>
    <ng-container *ngFor="let item of termsSecuring | keyvalue">
      <sib-terms-securing-item
        [title]="item.value.title"
        [assetDeals]="item.value.array"
        (addNewItem)="addNewItem($event)"
        (updateItem)="updateItem($event)"
        (generateNewTitle)="generateNewTitle($event)"
        (deleteItem)="deleteItem($event)"
        [disabled]="termsSecuringLoanGroupService.disabled"
        *ngIf="item.value.array.length"
      ></sib-terms-securing-item>
    </ng-container>

    <mat-form-field *ngIf="termsSecuringLoanConfig.showAssetComment">
      <mat-label> Коментар для забезпечення/поруки</mat-label>
      <textarea
        matInput
        type="text"
        placeholder="Введіть коментар для забезпечення/поруки"
        [(ngModel)]="assetComment"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="termsSecuringLoanGroupService.disabled"
        (change)="change()"
      ></textarea>
    </mat-form-field>
  </mat-card-content>
</mat-card>
