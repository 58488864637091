import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadUseTypes, selectUseTypeById, selectUseTypes, selectUseTypesEntities } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class UseTypesFacadeService {
  public useTypes$ = this.store.select(selectUseTypes);
  public useTypesEntities$ = this.store.select(selectUseTypesEntities);
  public selectUseTypeByTypeId$ = (id: string) => this.store.select(selectUseTypeById(id));

  constructor(private store: Store) {}

  public loadUseTypes() {
    this.store.dispatch(loadUseTypes());
  }
}
