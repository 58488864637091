import { Inject, Injectable, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { bankAgreementContractToken } from './bank-agreement-contract.token';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class BankAgreementContractGroupService extends FormGroup<
  ReturnType<typeof BankAgreementContractGroupService.formGroup>
> {
  private static readonly formGroup = () => ({
    assetId: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    dealTitle: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    startDate: new FormControl<Date | null>(null, { validators: [Validators.required], nonNullable: true }),
    dealNumber: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
  });

  constructor(
    @Optional()
    @Inject(bankAgreementContractToken)
    private bankAgreementContractConfig = new BehaviorSubject({ isActive: true, isValidatorsRequired: true }),
  ) {
    super(BankAgreementContractGroupService.formGroup());

    bankAgreementContractConfig.pipe(untilDestroyed(this)).subscribe(({ isValidatorsRequired }) => {
      if (isValidatorsRequired) {
        this.enable();
      } else {
        this.disable();
      }
    });
  }

  public override getRawValue(): any {
    return this.bankAgreementContractConfig.value.isActive ? super.getRawValue() : undefined;
  }
}
