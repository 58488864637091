/* 🤖 this file was generated by svg-to-ts */

import { Icon } from './ts/icon.model';

export type IconNameSubset<T extends Readonly<Icon[]>> = T[number]['name'];

export * from './ts/icon-account-group.icon';
export * from './ts/icon-account-multiple-check.icon';
export * from './ts/icon-account-switch.icon';
export * from './ts/icon-add-note-plus.icon';
export * from './ts/icon-animation-outline.icon';
export * from './ts/icon-arrow-bottom-right-bold-box-outline.icon';
export * from './ts/icon-arrow-top-right.icon';
export * from './ts/icon-arrow-up-circle-outline.icon';
export * from './ts/icon-auto-fix.icon';
export * from './ts/icon-back-arrow.icon';
export * from './ts/icon-backspace-reverse-outline.icon';
export * from './ts/icon-calendar-cursor.icon';
export * from './ts/icon-calendar-today.icon';
export * from './ts/icon-change.icon';
export * from './ts/icon-check-decagram.icon';
export * from './ts/icon-checkbox-marked.icon';
export * from './ts/icon-checkbox-outline.icon';
export * from './ts/icon-close-primary.icon';
export * from './ts/icon-close-secondary.icon';
export * from './ts/icon-close.icon';
export * from './ts/icon-cog-outline.icon';
export * from './ts/icon-content-copy.icon';
export * from './ts/icon-content-save.icon';
export * from './ts/icon-delete.icon';
export * from './ts/icon-desktop-mac.icon';
export * from './ts/icon-dots-horizontal-circle-outline.icon';
export * from './ts/icon-edit-pencil.icon';
export * from './ts/icon-eye.icon';
export * from './ts/icon-file-document-outline.icon';
export * from './ts/icon-file-documents.icon';
export * from './ts/icon-filter.icon';
export * from './ts/icon-graph.icon';
export * from './ts/icon-help.icon';
export * from './ts/icon-hire.icon';
export * from './ts/icon-history.icon';
export * from './ts/icon-lock.icon';
export * from './ts/icon-message-reply-text.icon';
export * from './ts/icon-minus.icon';
export * from './ts/icon-package-down.icon';
export * from './ts/icon-refresh.icon';
export * from './ts/icon-search.icon';
export * from './ts/icon-slash-eye.icon';
export * from './ts/icon-sort-bool-ascending.icon';
export * from './ts/icon-tacks.icon';
export * from './ts/icon-text-box-plus-outline.icon';
export * from './ts/icon-tune-variant.icon';
export * from './ts/icon-view.icon';
export * from './ts/icon.model';
