import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { DndDirective } from '../../directives/dnd.directive';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export interface FileDrop {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  base64: string;
}

@Component({
  selector: 'sib-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxFileDropModule, NgIf, DndDirective, MatButtonModule, MatIconModule],
})
export class FileDropComponent {
  @Input() isCustomDndActive = false;
  @Input() directory = false;
  @Input() multiple = false;
  @Input() acceptFiles = ['.jpeg', '.jpg', '.png', '.pdf'];
  @Input() maxFileSize = 20; // Mb
  @Output() fileDropped = new EventEmitter<FileDrop>();
  public files: NgxFileDropEntry[] = [];
  public maxCountFiles = 1;
  public isDragActive = false;

  get acceptFilesJoin(): string {
    return this.acceptFiles.join(', ');
  }

  get description(): string {
    return `Файли ${this.acceptFiles} розміром до ${this.maxFileSize} MB`;
  }

  constructor(private toastrService: ToastrService) {}

  onFileDrop(files: NgxFileDropEntry[]): void {
    this.files = files;
    if (files.length > this.maxCountFiles) {
      this.toastrService.error(`Не може додати більше ${this.maxCountFiles} файлів за один раз`);
    } else {
      for (const droppedFile of files) {
        this.checkIsItFile(droppedFile);
      }
    }
  }

  private checkIsItFile(droppedFile: NgxFileDropEntry): void {
    if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => this.checkFileSizeAllowed(file));
    } else {
      this.toastrService.error(`Приймаються лише файли у форматі ${this.acceptFilesJoin}`);
    }
  }

  private isFileAllowed(fileName: string): boolean {
    const isFileAllowed = true;
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    const [extensionFormat]: any = extension;

    // if (extension) {
    //   for (const acceptFile of this.acceptFiles) {
    //     if (acceptFile.includes(extensionFormat.toLocaleLowerCase())) {
    //       isFileAllowed = true;
    //     }
    //   }
    // }
    return isFileAllowed;
  }

  private isFileSizeAllowed(file: File): boolean {
    const isMaxFileSizeAllow = file.size <= this.maxFileSize * Math.pow(1024, 2);
    const isMinFileSizeDisallowed = file.size <= 0;

    if (!isMaxFileSizeAllow) {
      this.toastrService.error(
        `Єдині файли у форматі ${this.maxFileSize}mb, приймаються. Максимальний розмір дозволеного файлу ${this.maxFileSize}mb.`,
      );
    }

    if (isMinFileSizeDisallowed) {
      this.toastrService.error('Файл не може бути пустим.');
    }

    return isMaxFileSizeAllow && !isMinFileSizeDisallowed;
  }

  private checkFileSizeAllowed(file: File): void {
    if (this.isFileSizeAllowed(file)) {
      this.createFileToBase64(file);
    }
  }

  private createFileToBase64(file: File): void {
    let mimeType = '';
    if (file.name.indexOf('.docx') !== -1) {
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    if (file.name.indexOf('.doc') !== -1 && file.name.indexOf('.docx') === -1) {
      mimeType = 'application/msword';
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (result) => {
      const fileObj = {
        name: file.name,
        size: file.size,
        type: file.type ? file.type : mimeType,
        lastModified: file.lastModified,
        base64: reader.result as string,
      };
      this.fileDropped.emit(fileObj);
    };
  }

  // Create drag n drop by directive
  onFileDropped(files: any): void {
    this.files = files;

    if (files.length > this.maxCountFiles) {
      this.toastrService.error(`Не може додати більше ${this.maxCountFiles} файлів за один раз`);
    } else {
      for (const droppedFile of files) {
        if (this.isFileAllowed(droppedFile.name)) {
          this.checkFileSizeAllowed(droppedFile);
        } else {
          this.toastrService.error(`Приймаються лише файли у форматі ${this.acceptFilesJoin}`);
        }
      }
    }
  }

  onDragOver() {
    this.isDragActive = true;
  }

  onDragLeave() {
    this.isDragActive = false;
  }
}
