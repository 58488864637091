import { createReducer, on } from '@ngrx/store';
import * as TasksAction from '../actions/tasks.actions';
import type { TaskCountDto } from '@api/loan-approval';

export const tasksFeatureKey = 'tasks-info';

export interface State {
  isLoading: boolean;
  error: any;
  tasksCount: TaskCountDto;
  tasksAssessmentCount: TaskCountDto;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  tasksCount: {
    assigned: 0,
    candidate: 0,
  },
  tasksAssessmentCount: {
    assigned: 0,
    candidate: 0,
  },
};

export const reducer = createReducer(
  initialState,

  on(TasksAction.loadTasksCount, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TasksAction.loadTasksCountSuccess, (state, { tasksCount, tasksAssessment }) => ({
    ...state,
    isLoading: false,
    tasksCount,
    tasksAssessment,
  })),
  on(TasksAction.loadTasksCountFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(TasksAction.resetTasksState, (state) => initialState),
);

export const selectIsLoading = (state: State) => state.isLoading;
export const selectError = (state: State) => state.error;
export const selectTasksCount = (state: State) => state.tasksCount;
export const selectTasksAssessmentCount = (state: State) => state.tasksAssessmentCount;
