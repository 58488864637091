import { CashFlowZKR } from '@api/loan-approval';
import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { CashFlowZKRSTableComponent } from './cash-flow-zkrs-table.component';
import { differenceInMonths } from 'date-fns';

export const cashFlowZkrValidator =
  (flowType: CashFlowZKR['flowType']): ValidatorFn =>
  (control) => {
    const formArray = new FormArray<ReturnType<typeof CashFlowZKRSTableComponent.defineCashFlowZKRS>>([]);

    control.value?.forEach((value: never) => {
      const form = CashFlowZKRSTableComponent.defineCashFlowZKRS(flowType);

      form.patchValue(value);
      formArray.push(form);
    });

    return formArray.status === 'INVALID' ? { invalid: true } : null;
  };

export const cashFlowZkrDateValidation: ValidatorFn = (
  group: AbstractControl<ReturnType<typeof CashFlowZKRSTableComponent.defineCashFlowZKRS>>,
) => {
  const { year1, month1, year2, month2 } = group.getRawValue();

  if (year1 && month1 && year2 && month2) {
    const startDate = new Date(year1, month1 - 1);
    const endDate = new Date(year2, month2 - 1);

    if (differenceInMonths(startDate, endDate) > 0) {
      return { dateDiff: true };
    }
  }

  return null;
};
