import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from './auth.guard';
import { ArchiveComponent } from './pages/archive/archive.component';
import { HomeComponent } from './pages/home/home.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { inject } from '@angular/core';
import { authResolver, UserTabRolesFacadeService } from '@sib/shared/store';
import { productCategoriesResolver } from '../../libs/shared/store/src/lib/shared-state/product-categories/router-setup';

export const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    resolve: {
      loadRoles: () => inject(UserTabRolesFacadeService).loadRoles(),
      authResolver: authResolver,
      productCategoriesResolver: productCategoriesResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'dictionaries',
        loadChildren: () => import('@app/pages/directories/directories.module').then((m) => m.DirectoriesModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          breadcrumb: 'Довідники',
          permissions: {
            only: ['dictionary_admin'],
          },
        },
      },
      {
        path: 'proposals',
        loadChildren: () => import('@app/pages/proposals/proposals.module').then((m) => m.ProposalsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['request_list_access'],
          },
        },
      },
      {
        path: 'tasks',
        loadChildren: () => import('@app/pages/tasks/tasks.module').then((m) => m.TasksModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['request_list_tasks'],
          },
        },
      },
      {
        path: 'archive',
        component: ArchiveComponent,
        data: { breadcrumb: { alias: 'Archive' } },
      },
      {
        path: 'reports',
        data: { breadcrumb: 'Звіти' },
        loadChildren: () => import('@app/pages/reports/report-routes').then((m) => m.reportRoutes),
      },
      {
        path: 'property-assessment',
        loadChildren: () =>
          import('@app/pages/property-assessment/property-assessment.module').then((m) => m.PropertyAssessmentModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['valuation_list_access'],
          },
        },
      },
      {
        path: 'tasks-assessment',
        loadChildren: () =>
          import('@app/pages/tasks-assessment/tasks-assessment.module').then((m) => m.TasksAssessmentModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['valuation_list_tasks'],
          },
        },
      },
    ],
    data: { breadcrumb: { alias: 'Home' } },
  },

  { path: 'login', component: LoginComponent },

  { path: '**', redirectTo: '' },
];
