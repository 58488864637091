/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { TypeDocUseLandDto } from '../models/TypeDocUseLandDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TypeDocUseLandService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all type doc use land
     * Returns all type doc use land.
     * @returns TypeDocUseLandDto all type doc use land
     * @throws ApiError
     */
    public getApiTypeDocUseLands(): Observable<Array<TypeDocUseLandDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/type-doc-use-lands',
        });
    }

    /**
     * Create type doc use land
     * Method for creating type doc use land.
     * @returns TypeDocUseLandDto type doc use land created
     * @throws ApiError
     */
    public postApiTypeDocUseLands({
        requestBody,
    }: {
        /**
         * Type doc use land object that needs to be created.
         */
        requestBody: TypeDocUseLandDto,
    }): Observable<TypeDocUseLandDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/type-doc-use-lands',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get type doc use land
     * Get type doc use land by description.
     * @returns any type doc use land found
     * @throws ApiError
     */
    public getApiTypeDocUseLandsDescription({
        descr,
    }: {
        /**
         * Type doc use land description. Cannot be empty.
         */
        descr: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/type-doc-use-lands/description/{descr}',
            path: {
                'descr': descr,
            },
            errors: {
                404: `type doc use land not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get type doc use land
     * Get type doc use land by ID.
     * @returns any type doc use land found
     * @throws ApiError
     */
    public getApiTypeDocUseLands1({
        id,
    }: {
        /**
         * Type doc use land ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/type-doc-use-lands/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `type doc use land not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update type doc use land
     * Updates the type doc use land with the given ID.
     * @returns any type doc use land updated
     * @throws ApiError
     */
    public putApiTypeDocUseLands({
        id,
        requestBody,
    }: {
        /**
         * Type doc use land ID. Cannot be empty.
         */
        id: string,
        /**
         * Type doc use land object that needs to be created.
         */
        requestBody: TypeDocUseLandDto,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/type-doc-use-lands/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete type doc use land
     * Deletes the type doc use land with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiTypeDocUseLands({
        id,
    }: {
        /**
         * Type doc use land ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/type-doc-use-lands/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
