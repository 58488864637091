import type { Tab } from '../denormalized/task-tabs/types';
import { RoleAccessDto } from '@api/dictionaries';

export const tabs: Tab[] = [
  { name: 'Угоди по заявці', type: 'terms-agreement', route: 'terms-agreement' },
  { name: 'Нові кредитні ліміти', type: 'new-limits', route: 'credit-limits' },
  { name: 'Група позичальника', type: 'borrower-group', route: 'borrower-group' },
  { name: 'Діючі кредитні угоди', type: 'current-loans', route: 'current-loans' },
  { name: 'Закриті кредитні угоди (УКРСИББАНК)', type: 'credit-history', route: 'credit-history' },
  { name: 'Перевірка KYC', type: 'check_KYC', route: 'kyc-check' },
  { name: 'Судові справи', type: 'legal-cases', route: 'legal-cases' },
  { name: 'Забезпечення', type: 'providing', route: 'providing' },
  { name: 'Поручителі', type: 'guarantors', route: 'guarantors' },
  { name: 'Повідомлення', type: 'notifications', route: 'notifications' },
  { name: 'Документи', type: 'documents', route: 'documents' },
  { name: 'Автоперевірки', type: 'auto-check', route: 'auto-check' },
  { name: 'Check-list', type: 'check-list', route: 'check-list' },
];

export const isAllow = (
  tabType: string,
  userRoles: string[],
  userTabsRoles: RoleAccessDto[],
  tabsAccessRestriction: Record<string, string> | null,
) => {
  const tabs = userTabsRoles.filter(
    (i) => userRoles.some((userRole) => i.roleId === userRole) && i.tabCode === tabType,
  );

  return !(
    (tabs?.length > 0 && tabs.some((tab) => tab.tabAccess === 'Denied')) ||
    tabs?.length === 0 ||
    tabsAccessRestriction?.[tabType] === 'denied'
  );
};
