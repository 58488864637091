/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BelongingObjectDto } from '../models/BelongingObjectDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BelongingObjectService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all belonging objects
     * Returns all belonging objects.
     * @returns BelongingObjectDto all belonging objects
     * @throws ApiError
     */
    public getApiBelongingObjects(): Observable<Array<BelongingObjectDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/belonging-objects',
        });
    }

    /**
     * Create a belonging object
     * Method for creating a belonging object.
     * @returns BelongingObjectDto belonging object created
     * @throws ApiError
     */
    public postApiBelongingObjects({
        requestBody,
    }: {
        /**
         * Belonging object object that needs to be created.
         */
        requestBody: BelongingObjectDto,
    }): Observable<BelongingObjectDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/belonging-objects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get belonging object
     * Get belonging object by description.
     * @returns any belonging object found
     * @throws ApiError
     */
    public getApiBelongingObjectsDescription({
        descr,
    }: {
        /**
         * Belonging object description. Cannot be empty.
         */
        descr: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/belonging-objects/description/{descr}',
            path: {
                'descr': descr,
            },
            errors: {
                404: `belonging object not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get belonging object
     * Get belonging object by ID.
     * @returns any belonging object found
     * @throws ApiError
     */
    public getApiBelongingObjects1({
        id,
    }: {
        /**
         * Belonging object ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/belonging-objects/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `belonging object not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update a belonging object
     * Updates the belonging object with the given ID.
     * @returns BelongingObjectDto belonging object updated
     * @throws ApiError
     */
    public putApiBelongingObjects({
        id,
        requestBody,
    }: {
        /**
         * Belonging object ID. Cannot be empty.
         */
        id: string,
        /**
         * Belonging object object that needs to be updated.
         */
        requestBody: BelongingObjectDto,
    }): Observable<BelongingObjectDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/belonging-objects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete belonging object
     * Deletes the belonging object with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiBelongingObjects({
        id,
    }: {
        /**
         * Belonging object ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/belonging-objects/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
