import { createAction, props } from '@ngrx/store';
import { AssetTypeDto } from '@api/dictionaries';

export const loadAssetTypes = createAction('[Dictionaries/API] Load Asset Types');
export const loadAssetTypesSuccess = createAction(
  '[Dictionaries/API] Load Asset Types Success',
  props<{ assetTypes: AssetTypeDto[] }>(),
);
export const loadAssetTypesFailure = createAction(
  '[Dictionaries/API] Load Asset Types Failure',
  props<{ error: any }>(),
);
