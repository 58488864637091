import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { FeeForLoanGroupService } from './fee-for-loan-group.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CashFlowZKRSTableComponent } from '../../value-accessors';
import { CurrencyInputViewComponent } from '@sib/shared/ui';
import { CurrencyMaskDirective } from 'ngx-currency';

@Component({
  selector: 'sib-fee-for-loan-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    DigitOnlyModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,

    MatCheckboxModule,
    MatDatepickerModule,
    CashFlowZKRSTableComponent,
    CurrencyInputViewComponent,
    CurrencyMaskDirective,
  ],
  templateUrl: './fee-for-loan-form.component.html',
  styleUrls: ['./fee-for-loan-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline',
      },
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FeeForLoanFormComponent {
  constructor(public formGroup: FeeForLoanGroupService) {}
}
