import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as UiTabsAction from './ui-tabs.actions';
import { UITabDto } from '@api/dictionaries';

export const uiTabsFeatureKey = 'ui-tabs';

export interface State extends EntityState<UITabDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<UITabDto>({
  selectId: (uiTabs) => uiTabs.tabCode!,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(UiTabsAction.loadUiTabs, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(UiTabsAction.loadUiTabsSuccess, (state, { uiTabs }) => ({
    ...adapter.setAll(uiTabs, state),
    isLoading: false,
  })),
  on(UiTabsAction.loadUiTabsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
