import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value?: string | number,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string {
    return (this.currencyPipe.transform(value, currencyCode, display, locale) || '').replace(/,/g, ' ');
  }
}

@NgModule({
  imports: [CustomCurrencyPipe],
  exports: [CustomCurrencyPipe],
  providers: [CurrencyPipe, CustomCurrencyPipe],
})
export class CustomCurrencyPipeModule {}
