import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAssessmentApproaches, selectAssessmentApproaches } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class AssessmentApproachesFacadeService {
  public assessmentApproaches$ = this.store.select(selectAssessmentApproaches);
  constructor(private store: Store) {}

  public loadAssessmentApproaches() {
    this.store.dispatch(loadAssessmentApproaches());
  }
}
