import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Spouse } from '@api/loan-approval';

@Injectable()
export class ConsentHusbandWifeGroupService extends FormGroup<
  ReturnType<typeof ConsentHusbandWifeGroupService.formGroup>
> {
  private static readonly formGroup = () => ({
    consentHusbandWife: new FormControl<Spouse[]>([], { nonNullable: true }),
  });

  constructor() {
    super(ConsentHusbandWifeGroupService.formGroup());
  }
}
