import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllBorrowerGroups } from '../../+state/borrower-group/borrower-group.selectors';
import { borrowerGroupActions } from './borrower-group.actions';

@Injectable({ providedIn: 'root' })
export class BorrowerGroupFacade {
  private readonly store = inject(Store);

  selectAllBorrowerGroups$ = this.store.select(selectAllBorrowerGroups);

  loadSapBorrowerGroups() {
    this.store.dispatch(borrowerGroupActions.loadSapBorrowerGroups());
  }
}
