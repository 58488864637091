<h3 mat-dialog-title>Редагувати опис</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <div class="content" [formGroup]="titleForm">
    <mat-form-field>
      <textarea matInput placeholder="Опис" autofocus formControlName="title"></textarea>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button type="reset" mat-stroked-button [mat-dialog-close]="">Вийти</button>

  <button type="submit" mat-raised-button (click)="onSubmitDialog()" color="primary">Зберегти опис</button>
</div>
