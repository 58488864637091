import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllProductList,
  selectIsAllProductListLoading,
  selectLoanClassId,
  selectProductById,
  selectProductListEntities,
} from './product.selectors';
import { loadProductList } from './product.actions';

@Injectable({
  providedIn: 'root',
})
export class ProductFacadeService {
  public selectAllProductList$ = this.store.select(selectAllProductList);
  public selectProductListEntities$ = this.store.select(selectProductListEntities);

  public getProductById$ = (id: number) => this.store.select(selectProductById(id));

  public selectIsLoading$ = this.store.select(selectIsAllProductListLoading);
  public getLoanClassId$ = (id: number | null) => this.store.select(selectLoanClassId(id));

  constructor(private store: Store) {}

  public loadAllProductList() {
    this.store.dispatch(loadProductList());
  }
}
