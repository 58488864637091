/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConditionGroupDto } from '../models/ConditionGroupDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ConditionGroupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all condition groups
     * Returns all condition groups.
     * @returns ConditionGroupDto all condition groups
     * @throws ApiError
     */
    public getApiConditionGroups(): Observable<Array<ConditionGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-groups',
        });
    }

    /**
     * Update condition group
     * Updates the condition group.
     * @returns any condition group updated
     * @throws ApiError
     */
    public putApiConditionGroups({
        requestBody,
    }: {
        /**
         * Condition group object that needs to be updated.
         */
        requestBody: ConditionGroupDto,
    }): Observable<{
        id: number;
        prodId?: number;
        startDate?: string;
        endDate?: string;
        val?: string;
        stilgartId?: number;
        descr?: string;
        rate?: number;
        rateAdd?: number;
        incRate?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/condition-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Create condition group
     * Method for creating condition group.
     * @returns ConditionGroupDto condition group created
     * @throws ApiError
     */
    public postApiConditionGroups({
        requestBody,
    }: {
        /**
         * Condition group object that needs to be created.
         */
        requestBody: ConditionGroupDto,
    }): Observable<ConditionGroupDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/condition-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get condition groups
     * Get condition groups by currency code.
     * @returns ConditionGroupDto condition group found
     * @throws ApiError
     */
    public getApiConditionGroupsCurrencies({
        curCode,
    }: {
        /**
         * Currency code. Cannot be empty.
         */
        curCode: string,
    }): Observable<Array<ConditionGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-groups/currencies/{curCode}',
            path: {
                'curCode': curCode,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get condition groups
     * Get condition groups by product ID.
     * @returns ConditionGroupDto condition group found
     * @throws ApiError
     */
    public getApiConditionGroupsProducts({
        id,
    }: {
        /**
         * Product ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<ConditionGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-groups/products/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get condition groups
     * Get condition groups by repayment type ID.
     * @returns ConditionGroupDto condition group found
     * @throws ApiError
     */
    public getApiConditionGroupsRepaymentTypes({
        id,
    }: {
        /**
         * Repayment type ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<ConditionGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-groups/repayment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get condition group
     * Get condition group by ID.
     * @returns any condition group found
     * @throws ApiError
     */
    public getApiConditionGroups1({
        id,
    }: {
        /**
         * Condition group ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        prodId?: number;
        startDate?: string;
        endDate?: string;
        val?: string;
        stilgartId?: number;
        descr?: string;
        rate?: number;
        rateAdd?: number;
        incRate?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `condition group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete condition group
     * Deletes the condition group with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiConditionGroups({
        id,
    }: {
        /**
         * Condition group ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/condition-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
