/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { UseTypeDto } from '../models/UseTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UseTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all use types
     * Returns all use types.
     * @returns UseTypeDto all use types
     * @throws ApiError
     */
    public getApiUseTypes(): Observable<Array<UseTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/use-types',
        });
    }

    /**
     * Create use type
     * Method for creating use type.
     * @returns any use type created
     * @throws ApiError
     */
    public postApiUseTypes({
        requestBody,
    }: {
        /**
         * Use type object that needs to be created.
         */
        requestBody: UseTypeDto,
    }): Observable<{
        id: string;
        description?: string;
        sapCode?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/use-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get use type
     * Get use type by ID.
     * @returns any use type found
     * @throws ApiError
     */
    public getApiUseTypes1({
        id,
    }: {
        /**
         * Use type ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        description?: string;
        sapCode?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/use-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `use type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update use type
     * Updates the use type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public putApiUseTypes({
        id,
        requestBody,
    }: {
        /**
         * Use type ID. Cannot be empty.
         */
        id: string,
        /**
         * Use type object that needs to be updated.
         */
        requestBody: UseTypeDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/use-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete use type
     * Deletes the use type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiUseTypes({
        id,
    }: {
        /**
         * Use type ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/use-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
