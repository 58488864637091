import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import type { Tab } from '@sib/task/shared/store';
import { TaskSettingsFacadeService, TaskTabsFacadeService } from '@sib/task/shared/store';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthFacadeService } from '@sib/shared/store';
import { ComponentStore } from '@ngrx/component-store';
import { tap } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';
import { StickyBarDirective } from '@sib/shared/ui';

@Component({
  selector: 'sib-task-tabs',
  standalone: true,
  imports: [CommonModule, CdkDropList, RouterLink, CdkDrag, RouterLinkActive, RouterOutlet, StickyBarDirective],
  templateUrl: './task-tabs.component.html',
  styleUrls: ['./task-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTabsComponent extends ComponentStore<object> {
  private readonly taskTabsFacadeService = inject(TaskTabsFacadeService);
  private readonly authFacadeService = inject(AuthFacadeService);
  private readonly taskSettingsFacadeService = inject(TaskSettingsFacadeService);

  public getTaskTabs$ = this.taskTabsFacadeService.getTaskTabs$;

  public drop = this.effect<CdkDragDrop<Tab[]>>((trigger$) =>
    trigger$.pipe(
      concatLatestFrom(() => this.taskSettingsFacadeService.selectUserTabsSettingName$),
      tap(([event, userTabsSettingName = 'task-tabs']) => {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        const userSettings = {
          [userTabsSettingName]: event.container.data.map((item) => ({ type: item.type })),
        };
        this.authFacadeService.upsertUserSetting(userSettings);
      }),
    ),
  );

  trackByTypeFn(index: number, data: { type: string }) {
    return data.type;
  }
}
