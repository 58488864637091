import { createAction, props } from '@ngrx/store';
import { AssetSubtypeDto } from '@api/loan-org-structure';

export const loadAssetSubTypes = createAction('[Dictionaries/API] Load Asset Sub Types');
export const loadAssetSubTypesSuccess = createAction(
  '[Dictionaries/API] Load Asset Sub Types Success',
  props<{ assetSubTypes: AssetSubtypeDto[] }>(),
);
export const loadAssetSubTypesFailure = createAction(
  '[Dictionaries/API] Load Asset Sub Types Failure',
  props<{ error: unknown }>(),
);
