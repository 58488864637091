import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectTasksAssessmentCount, selectTasksCount } from './selectors';
import { loadTasksCount } from '@app/core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class TasksFacadeService {
  public selectTasksCount$ = this.store.select(selectTasksCount);
  public selectTasksAssessmentCount$ = this.store.select(selectTasksAssessmentCount);

  constructor(private store: Store) {}

  public loadTasksCount() {
    this.store.dispatch(loadTasksCount());
  }
}
