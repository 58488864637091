import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as AssetCategoryActions from './asset-category.actions';
import { AssetCategoryDto } from '@api/loan-org-structure';

export const assetCategoryFeatureKey = 'asset-category';

export interface State extends EntityState<AssetCategoryDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<AssetCategoryDto> = createEntityAdapter<AssetCategoryDto>({
  selectId: (assetCategory: AssetCategoryDto) => assetCategory.name,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(AssetCategoryActions.loadAssetCategory, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AssetCategoryActions.loadAssetCategorySuccess, (state, { assetCategory }) => ({
    ...adapter.setAll(assetCategory, state),
    isLoading: false,
  })),
  on(AssetCategoryActions.loadAssetCategoryFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
