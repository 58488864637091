/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ProductCategoryDto } from '../models/ProductCategoryDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ProductCategoryService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all product categories
     * Returns all product categories.
     * @returns ProductCategoryDto all product categories
     * @throws ApiError
     */
    public getApiProductCategories(): Observable<Array<ProductCategoryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/product-categories',
        });
    }

    /**
     * Create product category
     * Method for creating product category.
     * @returns ProductCategoryDto product category created
     * @throws ApiError
     */
    public postApiProductCategories({
        requestBody,
    }: {
        /**
         * Product category object that needs to be created.
         */
        requestBody: ProductCategoryDto,
    }): Observable<ProductCategoryDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/product-categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get product categories
     * Get product categories by ids.
     * @returns ProductCategoryDto product categories found
     * @throws ApiError
     */
    public getApiProductCategoriesListIds({
        ids,
    }: {
        /**
         * Product categories IDs. Cannot be empty.
         */
        ids: Array<number>,
    }): Observable<Array<ProductCategoryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/product-categories/list/ids',
            query: {
                'ids': ids,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get product category
     * Get product category by ID.
     * @returns any product category found
     * @throws ApiError
     */
    public getApiProductCategories1({
        id,
    }: {
        /**
         * Product category ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title?: string;
        sapProductId?: number;
        active?: boolean;
        prio?: number;
        products?: Array<number>;
        checkups?: Array<number>;
        categoryType: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/product-categories/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `product category not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update product category
     * Updates the product category with the given ID.
     * @returns any product category updated
     * @throws ApiError
     */
    public putApiProductCategories({
        id,
        requestBody,
    }: {
        /**
         * Product category ID. Cannot be empty.
         */
        id: number,
        /**
         * Product category object that needs to be updated.
         */
        requestBody: ProductCategoryDto,
    }): Observable<{
        id: number;
        title?: string;
        sapProductId?: number;
        active?: boolean;
        prio?: number;
        products?: Array<number>;
        checkups?: Array<number>;
        categoryType: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/product-categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Product category id doesn't match Product category id from body`,
                404: `Product category with such id not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete product category
     * Deletes the product category with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiProductCategories({
        id,
    }: {
        /**
         * Product category ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/product-categories/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
