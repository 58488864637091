import { createSelector } from '@ngrx/store';
import { exhaustiveCheck, formatDateWithPoint, typedFilter } from '@sib/shared/util';
import {
  getInfoDictionaryByType,
  getInfoType,
  infoDictionaryMapper,
  selectAgreementsTabsMapper,
} from '../../helpers/info-table.helper';
import { selectAllAgreements } from '../../+state/agreements/agreement.selectors';
import {
  selectAssetAgreementRelation,
  selectGuarantorAgreementRelation,
  selectPropertyOwner,
} from '../../+state/variables/variables.selectors';
import { selectProductCategories, selectProductCategoryTypesEntities, selectRouterParam } from '@sib/shared/store';
import { Agreement, AssetAgreementRelation, Guarantor } from '@api/loan-approval';
import { selectProvidingList } from '../providing/providing.selectors';
import { selectDepositEntities } from '../../+state/deposit/deposit.selectors';
import { selectVehiclesEntities } from '../../+state/vehicles/vehicles.selectors';
import { selectRealtyEntities } from '../../+state/realty/realty.selectors';
import { selectLandsEntities } from '../../+state/land/land.selectors';
import { selectGoodsEntities } from '../../+state/goods/goods.selectors';
import { selectEquipmentsEntities } from '../../+state/equipment/equipment.selectors';
import { FullProvidingInfo } from '../../models/providing.model';
import { selectAllGuarantors } from '../../+state/guarantors/guarantors.selectors';
import { CategoryTypeDto, ProductCategoryDto } from '@api/dictionaries';
import { Dictionary } from '@ngrx/entity/src/models';

export const getAgreementIdByRouteParam = selectRouterParam('agreementId');
export const buildAgreementInfoTable = createSelector(selectAllAgreements, (agreements) =>
  agreements
    .map((agreement) => ({
      ...agreement,
      total: agreement.agreementSum,
      term: agreement.agreementTerm ? `${agreement.agreementTerm} ${agreement.agreementTermMeasure}` : undefined,
      termType: agreement.agreementTermMeasure,
      purposeLending: agreement.lendingPurpose,
      currency: agreement.currencyCode,
      rate: agreement.interestRate,
      startDate: agreement.startDate ? formatDateWithPoint(new Date(agreement.startDate)) : undefined,
      endDate: agreement.endDate ? formatDateWithPoint(new Date(agreement.endDate)) : undefined,
    }))
    .map((agreement) => ({
      id: agreement.id,
      datasource: infoDictionaryMapper(getInfoDictionaryByType(getInfoType(agreement)), agreement),
    })),
);

export const selectAgreementsTabs = createSelector(selectAllAgreements, (agreements) =>
  selectAgreementsTabsMapper(agreements),
);

export const selectAgreementsByGuarantors = createSelector(
  selectAllAgreements,
  selectGuarantorAgreementRelation,
  (agreements, relations) =>
    agreements.filter((agreement) => relations.some((relation) => relation.dealNumber === agreement.dealNumber)),
);

export const selectActiveAgreementId = createSelector(
  getAgreementIdByRouteParam,
  selectAllAgreements,
  // on some pages can be 'main' that means do not filter by agreements
  (agreementId, agreements) => (!agreementId || agreementId === 'main' ? agreements[0]?.id : agreementId),
);

export const selectActiveAgreement = createSelector(
  selectActiveAgreementId,
  selectAllAgreements,
  (id, agreements: Agreement[]) => agreements.find((agreement: Agreement) => agreement.id === id),
);

export const selectAgreementsByAssets = createSelector(
  selectAllAgreements,
  selectAssetAgreementRelation,
  (agreements, relations) =>
    agreements.filter((agreement) => relations.some((relation) => relation.dealNumber === agreement.dealNumber)),
);

export const selectProvidingInfoList = createSelector(
  selectProvidingList,
  selectDepositEntities,
  selectVehiclesEntities,
  selectRealtyEntities,
  selectLandsEntities,
  selectGoodsEntities,
  selectEquipmentsEntities,
  selectPropertyOwner,
  (providingInfo, deposit, vehicle, realty, land, goods, equipment, owners) =>
    providingInfo
      .map((providingItem): FullProvidingInfo => {
        switch (providingItem.assetCategory) {
          case 'deposit':
            return { ...providingItem, ...deposit[providingItem.id], assetCategory: 'deposit' };
          case 'vehicles':
            return { ...providingItem, ...vehicle[providingItem.id], assetCategory: 'vehicles' };
          case 'realty':
            return {
              ...providingItem,
              ...realty[providingItem.id],
              assetCategory: 'realty',
              owners: owners.filter((owner) => owner.assetId === providingItem.id),
            };
          case 'land':
            return { ...providingItem, ...land[providingItem.id], assetCategory: 'land' };
          case 'goods':
            return { ...providingItem, ...goods[providingItem.id], assetCategory: 'goods' };
          case 'equipment':
            return { ...providingItem, ...equipment[providingItem.id], assetCategory: 'equipment' };
          default:
            return exhaustiveCheck();
        }
      })
      .filter(Boolean),
);

export const selectProvidingByDealNumber = (dealNumber: string) =>
  createSelector(
    selectAssetAgreementRelation,
    selectProvidingInfoList,
    (assetAgreementRelation: AssetAgreementRelation[], providingInfoList) =>
      typedFilter(providingInfoList, (list) =>
        assetAgreementRelation?.some((item) => (item.dealNumber === dealNumber ? item.astId : '') === list.id),
      ),
  );

export const selectGuarantorsByDealNumber = (dealNumber: string) =>
  createSelector(selectGuarantorAgreementRelation, selectAllGuarantors, (guarantorAgreementRelation, guarantors) =>
    guarantors.reduce(
      (acc, curr) =>
        guarantorAgreementRelation.find((i) => i.dealNumber === dealNumber && curr.bpCode === i.bpCode)
          ? [...acc, curr]
          : acc,
      [] as Guarantor[],
    ),
  );

export const selectIsRiskLoan = (id: string) =>
  createSelector(
    selectProductCategories,
    selectAllAgreements,
    selectProductCategoryTypesEntities,
    (
      productCategories: ProductCategoryDto[],
      agreements: Agreement[],
      productCategoryTypes: Dictionary<CategoryTypeDto>,
    ): boolean => {
      const product = productCategories.find(
        (item) => agreements.find((agreement) => agreement.id === id)?.productCategoryId === item.id,
      );
      return product ? productCategoryTypes[product?.categoryType]?.code === 'RISK_LOAN' : false;
    },
  );
