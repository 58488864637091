import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectKycBpCodes } from './kyc-check.selectors';

@Injectable({
  providedIn: 'root',
})
export class KycCheckFacadeService {
  private readonly store = inject(Store);

  getKycBpCodes$ = this.store.select(selectKycBpCodes);
}
