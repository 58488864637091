import { createReducer, on } from '@ngrx/store';
import * as ProductCategoriesActions from './product-categories.actions';
import { ProductCategoryDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const productCategoriesKey = 'product-categories';

export interface State extends EntityState<ProductCategoryDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<ProductCategoryDto>({
  selectId: (currency) => currency.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ProductCategoriesActions.loadProductCategories, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProductCategoriesActions.loadProductCategoriesSuccess, (state, { productCategories }) => ({
    ...adapter.setAll(productCategories, state),
    isLoading: false,
  })),
  on(ProductCategoriesActions.loadProductCategoriesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProductCategoriesState = selectAll;

export const selectIsLoading = (state: State) => state.isLoading;
