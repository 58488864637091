import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAssessmentKinds, selectAssessmentKinds } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class AssessmentKindsFacadeService {
  public assessmentKinds$ = this.store.select(selectAssessmentKinds);

  constructor(private store: Store) {}

  public loadAssessmentKinds() {
    this.store.dispatch(loadAssessmentKinds());
  }
}
