import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { loadConditionTypesFailure, loadConditionTypesSuccess, selectConditionTypes } from '../index';
import { ConditionTypeService } from '@api/dictionaries';
import { loadConditionTypes } from '../index';

@Injectable()
export class ConditionTypesEffects {
  loadConditionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConditionTypes),
      concatLatestFrom((action) => [this.store.select(selectConditionTypes)]),
      switchMap(([action, allParticipants]) =>
        (allParticipants.length ? of(allParticipants) : this.conditionTypeService.getApiConditionTypes()).pipe(
          map((response) =>
            loadConditionTypesSuccess({
              conditionTypes: response,
            }),
          ),
          catchError((error) => of(loadConditionTypesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store: Store, private conditionTypeService: ConditionTypeService) {}
}
