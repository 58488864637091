import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as RiskSegmentsActions from './risk-segments.actions';
import * as RiskSegmentsSelectors from './risk-segments.selectors';
import { Store } from '@ngrx/store';
import { RiskSegmentService } from '@api/dictionaries';

@Injectable()
export class RiskSegmentsEffects {
  loadRiskSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskSegmentsActions.loadRiskSegments),
      concatLatestFrom((action) => [this.store$.select(RiskSegmentsSelectors.selectRiskSegments)]),
      switchMap(([action, riskSegments]) =>
        riskSegments.length ? of(riskSegments) : this.riskSegmentService.getApiRiskSegments(),
      ),
      switchMap((response) => [
        RiskSegmentsActions.loadRiskSegmentsSuccess({
          riskSegments: response,
        }),
      ]),
      catchError((error) => of(RiskSegmentsActions.loadRiskSegmentsFailure({ error }))),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private riskSegmentService: RiskSegmentService) {}
}
