/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EquipmentTypeDto } from '../models/EquipmentTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class EquipmentTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all equipment types
     * Returns all equipment types.
     * @returns EquipmentTypeDto all equipment types
     * @throws ApiError
     */
    public getApiEquipmentTypes(): Observable<Array<EquipmentTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/equipment-types',
        });
    }

    /**
     * Create equipment type
     * Method for creating an equipment type.
     * @returns EquipmentTypeDto equipment type created
     * @throws ApiError
     */
    public postApiEquipmentTypes({
        requestBody,
    }: {
        /**
         * Equipment type object that needs to be created.
         */
        requestBody: EquipmentTypeDto,
    }): Observable<EquipmentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/equipment-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get equipment type
     * Get equipment type by ID.
     * @returns any equipment type found
     * @throws ApiError
     */
    public getApiEquipmentTypes1({
        id,
    }: {
        /**
         * Equipment type ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/equipment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `equipment type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update equipment type
     * Updates equipment type.
     * @returns any equipment type updated
     * @throws ApiError
     */
    public putApiEquipmentTypes({
        id,
        requestBody,
    }: {
        /**
         * Equipment type ID.  Cannot be empty.
         */
        id: string,
        /**
         * Equipment type object that needs to be updated.
         */
        requestBody: EquipmentTypeDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/equipment-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete equipment type
     * Deletes the equipment type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiEquipmentTypes({
        id,
    }: {
        /**
         * Equipment type ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/equipment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
