import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as RolesActions from './roles.actions';
import { RoleDto } from '@api/dictionaries';

export const rolesFeatureKey = 'roles';

export interface State extends EntityState<RoleDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<RoleDto>({
  selectId: (role) => role.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(RolesActions.loadRoles, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(RolesActions.loadRolesSuccess, (state, { roles }) => ({
    ...adapter.setAll(roles, state),
    isLoading: false,
  })),

  on(RolesActions.loadRolesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
