/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { GroupLimitDto } from '../models/GroupLimitDto';
import type { IncludeToDealsLimitsCalculationDto } from '../models/IncludeToDealsLimitsCalculationDto';
import type { LimitCalculationDto } from '../models/LimitCalculationDto';
import type { ProtDateDto } from '../models/ProtDateDto';
import type { UpdateGrrDto } from '../models/UpdateGrrDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class GroupLimitService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get group limit
     * Returns response with status and group limit data
     * @returns GroupLimitDto Group limit data was successfully returned
     * @throws ApiError
     */
    public getProcessesGroupLimit({
        processInstanceId,
    }: {
        /**
         * Process instance id for the forming of group limit
         */
        processInstanceId: string,
    }): Observable<GroupLimitDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/processes/{processInstanceId}/group-limit',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `Specified process instance not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Calculate group limit
     * Returns response with status and data from group limit calculation
     * @returns GroupLimitDto Group limit was successfully calculated
     * @throws ApiError
     */
    public postProcessesGroupLimit({
        processInstanceId,
    }: {
        /**
         * Process instance id for the calculating of group limit
         */
        processInstanceId: string,
    }): Observable<GroupLimitDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/processes/{processInstanceId}/group-limit',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Checks if limit calculation is blocked
     * Returns 'isLimitCalculationBlocked' property which depends on votingResults variable
     * @returns LimitCalculationDto 'isLimitCalculationBlocked' property is returned
     * @throws ApiError
     */
    public getProcessesGroupLimitIsLimitCalculationBlocked({
        processInstanceId,
    }: {
        /**
         * Process instance id
         */
        processInstanceId: string,
    }): Observable<LimitCalculationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/processes/{processInstanceId}/group-limit/is-limit-calculation-blocked',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                500: `Server error`,
            },
        });
    }

    /**
     * Include or exclude deal from group limit calculation
     * @returns GroupLimitDto Specified deal was successfully included/excluded from group limit calculation
     * @throws ApiError
     */
    public patchProcessesGroupLimitItem({
        agreementId,
        processInstanceId,
        requestBody,
    }: {
        /**
         * Agreement/deal which will be included/excluded from group limit calculation
         */
        agreementId: string,
        /**
         * Process instance id for the group limit
         */
        processInstanceId: string,
        requestBody?: IncludeToDealsLimitsCalculationDto,
    }): Observable<GroupLimitDto> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/processes/{processInstanceId}/group-limit/item/{agreementId}',
            path: {
                'agreementId': agreementId,
                'processInstanceId': processInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Specified process instance not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * Change grr value for the specified agreement
     * @returns void
     * @throws ApiError
     */
    public patchProcessesGroupLimitItemGrr({
        agreementId,
        processInstanceId,
        requestBody,
    }: {
        /**
         * Agreement which will be updated
         */
        agreementId: string,
        /**
         * Process instance id for the group limit
         */
        processInstanceId: string,
        requestBody?: UpdateGrrDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/processes/{processInstanceId}/group-limit/item/{agreementId}/grr',
            path: {
                'agreementId': agreementId,
                'processInstanceId': processInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Specified process instance not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * Update protocol date
     * @returns GroupLimitDto Protocol date was successfully updated
     * @throws ApiError
     */
    public patchProcessesGroupLimitProtocolDate({
        processInstanceId,
        requestBody,
    }: {
        /**
         * Process instance id for the protocol update
         */
        processInstanceId: string,
        requestBody?: ProtDateDto,
    }): Observable<GroupLimitDto> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/processes/{processInstanceId}/group-limit/protocol-date',
            path: {
                'processInstanceId': processInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Specified process instance not found`,
                500: `Server error`,
            },
        });
    }

}
