import { createSelector } from '@ngrx/store';
import { selectLogin, selectRolesAccess, selectUserRoles } from '@sib/shared/store';
import { selectTabsAccessRestriction } from '../../+state/variables/variables.selectors';
import { selectTaskAssignee } from '../../+state/task-details/task-details.selectors';
import { PageType } from '../task-tabs/types';
import { taskSettingsFeature } from '../../+state/task-settings/task-settings.reducer';
import { isAllow } from '../../helpers/task-tabs.helper';

export const selectUserTabsRoleAccessByPageType = (pageType: PageType) =>
  createSelector(selectUserRoles, selectRolesAccess, (userRoles, rolesAccess) =>
    rolesAccess.filter((i) => userRoles.some((userRole) => i.roleId === userRole) && i.tabCode === pageType),
  );

export const selectIsPageViewOnly = (pageType: PageType) =>
  createSelector(
    selectUserTabsRoleAccessByPageType(pageType),
    selectTabsAccessRestriction,
    (userTabRoles, tabsAccessRestriction) => {
      const editState = userTabRoles.some((tab) => tab.tabAccess === 'Edit');

      return (
        (userTabRoles?.length > 0 && userTabRoles.some((tab) => tab.tabAccess === 'ViewOnly') && !editState) ||
        (userTabRoles?.length === 0 && !editState) ||
        tabsAccessRestriction?.[pageType] === 'viewOnly'
      );
    },
  );

export const selectIsAssignee = createSelector(
  selectTaskAssignee,
  selectLogin,
  (assignee, login) => assignee === login,
);

export const selectIsAccessLimited = (pageType: PageType) =>
  createSelector(
    selectIsPageViewOnly(pageType),
    selectIsAssignee,
    taskSettingsFeature.selectIsReadonly,
    (isPageViewOnly, isAssignee, isReadonly) => isReadonly || !isAssignee || isPageViewOnly,
  );

export const selectIsPageAccessible = (pageType: string) =>
  createSelector(
    selectTabsAccessRestriction,
    selectRolesAccess,
    selectUserRoles,
    (tabsAccessRestriction, rolesAccess, userRoles) => isAllow(pageType, userRoles, rolesAccess, tabsAccessRestriction),
  );
