import { getUniqueUuid } from '@sib/shared/util';
import { BailContract, DocumentaryOperation, Spouse } from '@api/loan-approval';
import { getSignatories } from '@sib/task/shared/util';

export const createBailContract = ({
  dealNumber,
  partnerName,
  bpCode,
  passpDoc,
  addressLetter,
  addressLocation,
  gtCity,
  addressLetterBank,
  spouses,
}: {
  dealNumber: string;
  partnerName: string;
  bpCode: string;
  passpDoc: string;
  addressLetter: string;
  addressLocation: string;
  gtCity: string;
  addressLetterBank: string;
  spouses: Spouse[];
}): BailContract => ({
  id: getUniqueUuid(), // ID договору поруки в Системі (UUID)
  bpCode, // Код БП - поручителя (Guarantor)
  dealNumber, // ID угоди/ген.угоди, з якою пов'язаний договір поруки
  gtId: '', // № Договору поруки в SAP
  gtCity, // Місто
  gtDate: '', // Дата договору
  gtSum: undefined, // Сума відповідальності поручителя
  gtCurr: '', // Валюта
  gtEkv: undefined, // Еквівалент в грн
  gtTerm: undefined, // Строк поруки
  gtPasp: '', // Паспортні дані поручителя
  signatories: getSignatories({
    pledgerContract: { partnerName: partnerName, ownerBpCode: bpCode },
    requisitesContract: {
      addressLetterBank: addressLetterBank,
      passpDoc: passpDoc,
      addressLetter: addressLetter,
      addressLocation: addressLocation,
    },
  }),
  spouses: spouses,
});

export const createDocumentaryOperation = ({ agreementId }: { agreementId: string }): DocumentaryOperation => ({
  notLaterLastDay: false,
  limitDeadlineTerm: undefined,
  limitDeadlineTermMeasure: undefined,
  numberMonthsTerm: undefined,
  daysBeforeEndDepositTerm: undefined,
  guaranteeCat: undefined,
  coverageAccount: undefined,
  agreementFormDigital: undefined,
  agreementId: agreementId,
});
