<div class="sidebar" [class.small]="sidebarType === 'small'">
  <div class="sidebar-controller">
    <div class="icon">
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="changeSidebarState()"
      >
        <path
          d="M6.59819 10.6667L1.97819 6.04671L6.59819 1.42671L7.42069 2.25505L4.21236 5.46338H12.4782V6.63005H4.21236L7.42652 9.83838L6.59819 10.6667ZM1.97819 6.04671V0.213379H0.811523V11.88H1.97819V6.04671Z"
          fill="#828282"
        />
      </svg>
    </div>
  </div>

  <nav class="nav">
    <div class="nav-section" [class.active]="homeRla.isActive">
      <a class="headline" [routerLink]="['/home']" routerLinkActive="active" #homeRla="routerLinkActive">
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.74349 3.66665L9.74349 8.69582L8.25599 7.20832L5.49349 10.0417L3.51016 8.05832L6.27266 5.15415L4.71432 3.66665L9.74349 3.66665ZM13.2852 11.4583L13.2852 1.54165C13.2852 0.762486 12.6477 0.124985 11.8685 0.124985L1.95182 0.124985C1.17266 0.124985 0.535156 0.762486 0.535156 1.54165L0.535156 11.4583C0.535156 12.2375 1.17266 12.875 1.95182 12.875L11.8685 12.875C12.6477 12.875 13.2852 12.2375 13.2852 11.4583ZM1.95182 11.4583L1.95182 1.54165L11.8685 1.54165L11.8685 11.4583L1.95182 11.4583Z"
            fill="#468FD3"
          />
        </svg>
        <span>Головна</span>
      </a>
    </div>

    <div
      class="accordion accordion-item-container active"
      *ngxPermissionsOnly="['valuation_list_access', 'valuation_list_tasks']"
    >
      <div class="accordion-title active" (click)="triggerAccordion($event)">
        <div class="accordion-title-text">Оцінка майна</div>
        <svg class="accordion-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path d="M3 9.4l5-5 5 5-1.4 1.4-3.6-3.6-3.6 3.6z"></path>
          <path
            d="M15 8c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7 7 3.1 7 7zM16 8c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8 8-3.6 8-8v0z"
          ></path>
        </svg>
      </div>
      <div class="accordion-content active">
        <!-- !Оцінка майна -->
        <div
          class="nav-section nav-subsection"
          [class.active]="propertyAssessmentRla.isActive"
          *ngxPermissionsOnly="'valuation_list_access'"
        >
          <a
            hidden
            [routerLink]="['/property-assessment']"
            #propertyAssessmentRla="routerLinkActive"
            routerLinkActive="active"
          ></a>
          <a class="headline" [routerLink]="['/property-assessment']" routerLinkActive="active">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91014 0.356934C3.96772 0.356934 0.76709 3.55756 0.76709 7.49998C0.76709 11.4424 3.96772 14.643 7.91014 14.643C11.8526 14.643 15.0532 11.4424 15.0532 7.49998C15.0532 3.55756 11.8526 0.356934 7.91014 0.356934ZM7.91014 1.30934C11.3268 1.30934 14.1008 4.08329 14.1008 7.49998C14.1008 10.9167 11.3268 13.6906 7.91014 13.6906C4.49345 13.6906 1.7195 10.9167 1.7195 7.49998C1.7195 4.08329 4.49345 1.30934 7.91014 1.30934ZM7.23559 8.75497L10.3159 5.67462C10.5016 5.48871 10.8033 5.48871 10.9893 5.67462C11.1752 5.86032 11.1752 6.16231 10.9893 6.348L7.57236 9.76496C7.38645 9.95087 7.085 9.95087 6.89898 9.76496L4.70015 7.56634C4.51445 7.38065 4.51445 7.07866 4.70015 6.89296C4.88606 6.70706 5.18783 6.70706 5.37374 6.89296L7.23559 8.75497Z"
                fill="#468FD3"
                stroke="#468FD3"
                stroke-width="0.5"
              />
            </svg>
            <span>Запити <span class="assessment">Оцінка</span></span>
          </a>
          <div class="nav-section-inner">
            <a class="nav-item" [routerLink]="['/property-assessment/my']" routerLinkActive="active">
              <div class="icon"></div>
              <span>мої запити</span>
            </a>
            <a class="nav-item" [routerLink]="['/property-assessment/all']" routerLinkActive="active">
              <div class="icon"></div>
              <span>всі запити</span>
            </a>
            <a class="nav-item" [routerLink]="['/property-assessment/completed']" routerLinkActive="active">
              <div class="icon"></div>
              <span>оброблені запити</span>
            </a>
          </div>
        </div>
        <!-- !Оцінка майна  завдання-->
        <div
          class="nav-section nav-subsection"
          [class.active]="tasksAssessmentRla.isActive"
          *ngxPermissionsOnly="'valuation_list_tasks'"
        >
          <a
            hidden
            [routerLink]="['/tasks-assessment']"
            #tasksAssessmentRla="routerLinkActive"
            routerLinkActive="active"
          ></a>
          <a class="headline" [routerLink]="['/tasks-assessment']" routerLinkActive="active">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91014 0.356934C3.96772 0.356934 0.76709 3.55756 0.76709 7.49998C0.76709 11.4424 3.96772 14.643 7.91014 14.643C11.8526 14.643 15.0532 11.4424 15.0532 7.49998C15.0532 3.55756 11.8526 0.356934 7.91014 0.356934ZM7.91014 1.30934C11.3268 1.30934 14.1008 4.08329 14.1008 7.49998C14.1008 10.9167 11.3268 13.6906 7.91014 13.6906C4.49345 13.6906 1.7195 10.9167 1.7195 7.49998C1.7195 4.08329 4.49345 1.30934 7.91014 1.30934ZM7.23559 8.75497L10.3159 5.67462C10.5016 5.48871 10.8033 5.48871 10.9893 5.67462C11.1752 5.86032 11.1752 6.16231 10.9893 6.348L7.57236 9.76496C7.38645 9.95087 7.085 9.95087 6.89898 9.76496L4.70015 7.56634C4.51445 7.38065 4.51445 7.07866 4.70015 6.89296C4.88606 6.70706 5.18783 6.70706 5.37374 6.89296L7.23559 8.75497Z"
                fill="#468FD3"
                stroke="#468FD3"
                stroke-width="0.5"
              />
            </svg>
            <span>Завдання <span class="assessment">Оцінка</span></span>
          </a>
          <div class="nav-section-inner" *ngIf="tasksFacadeService.selectTasksAssessmentCount$ | async as tasksCount">
            <a class="nav-item" [routerLink]="['/tasks-assessment/new']" routerLinkActive="active">
              <div class="icon"></div>
              <span>нові ({{ tasksCount.candidate }})</span>
            </a>
            <a class="nav-item" [routerLink]="['/tasks-assessment/in-progress']" routerLinkActive="active">
              <div class="icon"></div>
              <span>у роботі ({{ tasksCount.assigned }})</span>
            </a>
            <a class="nav-item" [routerLink]="['/tasks-assessment/department']" routerLinkActive="active">
              <div class="icon"></div>
              <span>підрозділу</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="accordion accordion-item-container active"
      *ngxPermissionsOnly="['request_list_tasks', 'request_list_access']"
    >
      <div class="accordion-title active" (click)="triggerAccordion($event)">
        <div class="accordion-title-text">ЗАЯВКИ ТА ІН.</div>
        <svg class="accordion-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path d="M3 9.4l5-5 5 5-1.4 1.4-3.6-3.6-3.6 3.6z"></path>
          <path
            d="M15 8c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7 7 3.1 7 7zM16 8c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8 8-3.6 8-8v0z"
          ></path>
        </svg>
      </div>
      <div class="accordion-content active">
        <!-- !Заявки -->
        <div
          class="nav-section nav-subsection"
          [class.active]="proposalsRla.isActive"
          *ngxPermissionsOnly="'request_list_access'"
        >
          <a hidden [routerLink]="['/proposals']" #proposalsRla="routerLinkActive" routerLinkActive="active"></a>
          <a class="headline" [routerLink]="['/proposals']" routerLinkActive="active">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91014 0.356934C3.96772 0.356934 0.76709 3.55756 0.76709 7.49998C0.76709 11.4424 3.96772 14.643 7.91014 14.643C11.8526 14.643 15.0532 11.4424 15.0532 7.49998C15.0532 3.55756 11.8526 0.356934 7.91014 0.356934ZM7.91014 1.30934C11.3268 1.30934 14.1008 4.08329 14.1008 7.49998C14.1008 10.9167 11.3268 13.6906 7.91014 13.6906C4.49345 13.6906 1.7195 10.9167 1.7195 7.49998C1.7195 4.08329 4.49345 1.30934 7.91014 1.30934ZM7.23559 8.75497L10.3159 5.67462C10.5016 5.48871 10.8033 5.48871 10.9893 5.67462C11.1752 5.86032 11.1752 6.16231 10.9893 6.348L7.57236 9.76496C7.38645 9.95087 7.085 9.95087 6.89898 9.76496L4.70015 7.56634C4.51445 7.38065 4.51445 7.07866 4.70015 6.89296C4.88606 6.70706 5.18783 6.70706 5.37374 6.89296L7.23559 8.75497Z"
                fill="#468FD3"
                stroke="#468FD3"
                stroke-width="0.5"
              />
            </svg>
            <span>Заявки</span>
          </a>
          <div class="nav-section-inner">
            <a class="nav-item" [routerLink]="['/proposals/my-proposals']" routerLinkActive="active">
              <div class="icon"></div>
              <span>мої заявки</span>
            </a>
            <a class="nav-item" [routerLink]="['/proposals/all-proposals']" routerLinkActive="active">
              <div class="icon"></div>
              <span>всі активні</span>
            </a>
            <a class="nav-item" [routerLink]="['/proposals/completed-proposals']" routerLinkActive="active">
              <div class="icon"></div>
              <span>всі завершені</span>
            </a>
          </div>
        </div>

        <div
          class="nav-section nav-subsection"
          *ngxPermissionsOnly="'request_list_tasks'"
          [class.active]="tasksRla.isActive"
        >
          <a hidden [routerLink]="['/tasks']" #tasksRla="routerLinkActive" routerLinkActive="active"></a>
          <a class="headline" [routerLink]="['/tasks']" routerLinkActive="active">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91014 0.356934C3.96772 0.356934 0.76709 3.55756 0.76709 7.49998C0.76709 11.4424 3.96772 14.643 7.91014 14.643C11.8526 14.643 15.0532 11.4424 15.0532 7.49998C15.0532 3.55756 11.8526 0.356934 7.91014 0.356934ZM7.91014 1.30934C11.3268 1.30934 14.1008 4.08329 14.1008 7.49998C14.1008 10.9167 11.3268 13.6906 7.91014 13.6906C4.49345 13.6906 1.7195 10.9167 1.7195 7.49998C1.7195 4.08329 4.49345 1.30934 7.91014 1.30934ZM7.23559 8.75497L10.3159 5.67462C10.5016 5.48871 10.8033 5.48871 10.9893 5.67462C11.1752 5.86032 11.1752 6.16231 10.9893 6.348L7.57236 9.76496C7.38645 9.95087 7.085 9.95087 6.89898 9.76496L4.70015 7.56634C4.51445 7.38065 4.51445 7.07866 4.70015 6.89296C4.88606 6.70706 5.18783 6.70706 5.37374 6.89296L7.23559 8.75497Z"
                fill="#468FD3"
                stroke="#468FD3"
                stroke-width="0.5"
              />
            </svg>
            <span>Завдання</span>
          </a>
          <div class="nav-section-inner" *ngIf="tasksFacadeService.selectTasksCount$ | async as tasksCount">
            <a data-e2e="taskNew" class="nav-item" [routerLink]="['/tasks/new']" routerLinkActive="active">
              <div class="icon"></div>
              <span>нові ({{ tasksCount.candidate }})</span>
            </a>
            <a
              data-e2e="taskInProgress"
              class="nav-item"
              [routerLink]="['/tasks/in-progress']"
              routerLinkActive="active"
            >
              <div class="icon"></div>
              <span>у роботі ({{ tasksCount.assigned }})</span>
            </a>
            <a
              data-e2e="taskDepartment"
              class="nav-item"
              [routerLink]="['/tasks/department']"
              routerLinkActive="active"
            >
              <div class="icon"></div>
              <span>підрозділу</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion accordion-item-container active">
      <div class="accordion-title active" (click)="triggerAccordion($event)">
        <div class="accordion-title-text">Звіти</div>
        <svg class="accordion-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path d="M3 9.4l5-5 5 5-1.4 1.4-3.6-3.6-3.6 3.6z"></path>
          <path
            d="M15 8c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7 7 3.1 7 7zM16 8c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8 8-3.6 8-8v0z"
          ></path>
        </svg>
      </div>
      <div class="accordion-content active">
        <!-- !Звіти -->
        <div class="nav-section reports" [class.active]="reportsRla.isActive">
          <a hidden [routerLink]="['/reports']" #reportsRla="routerLinkActive" routerLinkActive="active"></a>
          <a class="headline">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91014 0.356934C3.96772 0.356934 0.76709 3.55756 0.76709 7.49998C0.76709 11.4424 3.96772 14.643 7.91014 14.643C11.8526 14.643 15.0532 11.4424 15.0532 7.49998C15.0532 3.55756 11.8526 0.356934 7.91014 0.356934ZM7.91014 1.30934C11.3268 1.30934 14.1008 4.08329 14.1008 7.49998C14.1008 10.9167 11.3268 13.6906 7.91014 13.6906C4.49345 13.6906 1.7195 10.9167 1.7195 7.49998C1.7195 4.08329 4.49345 1.30934 7.91014 1.30934ZM7.23559 8.75497L10.3159 5.67462C10.5016 5.48871 10.8033 5.48871 10.9893 5.67462C11.1752 5.86032 11.1752 6.16231 10.9893 6.348L7.57236 9.76496C7.38645 9.95087 7.085 9.95087 6.89898 9.76496L4.70015 7.56634C4.51445 7.38065 4.51445 7.07866 4.70015 6.89296C4.88606 6.70706 5.18783 6.70706 5.37374 6.89296L7.23559 8.75497Z"
                fill="#468FD3"
                stroke="#468FD3"
                stroke-width="0.5"
              />
            </svg>
            <span>Звіти</span>
          </a>
          <div class="nav-section-inner">
            <div *ngFor="let item of reportsList$ | async">
              <a
                *ngIf="checkIfUserCanViewReport(item.reportRoles)"
                class="nav-item"
                [routerLink]="['/reports/' + item.nameId?.toLowerCase()]"
                routerLinkActive="active"
              >
                <div class="icon"></div>
                <span>{{ item.title }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion accordion-item-container active" *ngxPermissionsOnly="'dictionary_admin'">
      <div class="accordion-title active" (click)="triggerAccordion($event)">
        <div class="accordion-title-text">Довідники</div>
        <svg class="accordion-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path d="M3 9.4l5-5 5 5-1.4 1.4-3.6-3.6-3.6 3.6z"></path>
          <path
            d="M15 8c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7 7 3.1 7 7zM16 8c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8 8-3.6 8-8v0z"
          ></path>
        </svg>
      </div>
      <div class="accordion-content active">
        <!-- !Довідники -->
        <div class="nav-section nav-subsection" [class.active]="dictionariesRla.isActive">
          <a hidden [routerLink]="['/dictionaries']" #dictionariesRla="routerLinkActive" routerLinkActive="active"></a>
          <a class="headline">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.91014 0.356934C3.96772 0.356934 0.76709 3.55756 0.76709 7.49998C0.76709 11.4424 3.96772 14.643 7.91014 14.643C11.8526 14.643 15.0532 11.4424 15.0532 7.49998C15.0532 3.55756 11.8526 0.356934 7.91014 0.356934ZM7.91014 1.30934C11.3268 1.30934 14.1008 4.08329 14.1008 7.49998C14.1008 10.9167 11.3268 13.6906 7.91014 13.6906C4.49345 13.6906 1.7195 10.9167 1.7195 7.49998C1.7195 4.08329 4.49345 1.30934 7.91014 1.30934ZM7.23559 8.75497L10.3159 5.67462C10.5016 5.48871 10.8033 5.48871 10.9893 5.67462C11.1752 5.86032 11.1752 6.16231 10.9893 6.348L7.57236 9.76496C7.38645 9.95087 7.085 9.95087 6.89898 9.76496L4.70015 7.56634C4.51445 7.38065 4.51445 7.07866 4.70015 6.89296C4.88606 6.70706 5.18783 6.70706 5.37374 6.89296L7.23559 8.75497Z"
                fill="#468FD3"
                stroke="#468FD3"
                stroke-width="0.5"
              />
            </svg>
            <span>Довідники</span>
          </a>
          <div class="nav-section-inner">
            <a class="nav-item" [routerLink]="['/dictionaries/orgstructure']" routerLinkActive="active">
              <div class="icon"></div>
              <span>Організаційна структура</span>
            </a>

            <a class="nav-item" [routerLink]="['/dictionaries/groups']" routerLinkActive="active">
              <div class="icon"></div>
              <span>Групи довідників</span>
            </a>

            <a class="nav-item" [routerLink]="['/dictionaries/document-templates']" routerLinkActive="active">
              <div class="icon"></div>
              <span>шаблони документів</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- !Архів -->
    <div class="nav-section archive" [class.active]="archiveRla.isActive" *ngIf="false">
      <a class="headline" [routerLink]="['/archive']" routerLinkActive="active" #archiveRla="routerLinkActive">
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.3636 14.7365H2.45699C2.01453 14.7352 1.59044 14.5588 1.27757 14.246C0.96469 13.9331 0.788372 13.509 0.787004 13.0665V9.72655C0.787128 9.68145 0.79272 9.63659 0.803654 9.59298L2.81884 1.52709C2.90942 1.16599 3.11804 0.845539 3.41154 0.61667C3.70491 0.387669 4.0665 0.263428 4.43876 0.263428H9.38193C9.7542 0.263428 10.1158 0.387681 10.4092 0.61667C10.7026 0.845548 10.9113 1.16602 11.0019 1.52709L13.017 9.59298C13.028 9.63659 13.0336 9.68145 13.0337 9.72655V13.0665C13.0323 13.509 12.856 13.9331 12.5431 14.246C12.2302 14.5588 11.8062 14.7352 11.3637 14.7365H11.3636ZM9.92188 1.79967C9.89206 1.67901 9.8226 1.57178 9.72481 1.49512C9.6269 1.41833 9.50625 1.3767 9.38188 1.37658H4.43871C4.31433 1.3767 4.19368 1.41833 4.09577 1.49512C3.99798 1.57178 3.92852 1.67901 3.8987 1.79967L2.05611 9.16989H5.24023C5.38784 9.16989 5.5295 9.22854 5.63387 9.33291C5.73824 9.43728 5.79689 9.57894 5.79689 9.72655V10.2164C5.79031 10.4879 5.87927 10.753 6.04814 10.9656C6.217 11.1782 6.45507 11.3248 6.72097 11.3799C7.0444 11.4357 7.37605 11.3458 7.62728 11.1343C7.8784 10.923 8.0234 10.6115 8.02353 10.2832V9.72653C8.02353 9.57891 8.08218 9.43726 8.18655 9.33289C8.29092 9.22851 8.43258 9.16986 8.58019 9.16986H11.7643L9.92188 1.79967Z"
            fill="#828282"
          />
          <path
            d="M4.12722 7.4999C4.12722 7.35229 4.18587 7.21063 4.29024 7.10626C4.39461 7.00189 4.53627 6.94324 4.68388 6.94324H9.13719C9.33612 6.94324 9.51989 7.04935 9.61929 7.22157C9.7187 7.39379 9.7187 7.60602 9.61929 7.77823C9.51989 7.95045 9.33612 8.05656 9.13719 8.05656H4.68388C4.53627 8.05656 4.39461 7.99791 4.29024 7.89354C4.18587 7.78917 4.12722 7.64752 4.12722 7.4999Z"
            fill="#828282"
          />
          <path
            d="M8.58006 5.82987H5.24008C5.04115 5.82987 4.85738 5.72376 4.75798 5.55154C4.65857 5.37933 4.65857 5.1671 4.75798 4.99488C4.85738 4.82267 5.04115 4.71655 5.24008 4.71655H8.58006C8.779 4.71655 8.96276 4.82266 9.06217 4.99488C9.16157 5.1671 9.16157 5.37933 9.06217 5.55154C8.96276 5.72376 8.77899 5.82987 8.58006 5.82987Z"
            fill="#828282"
          />
          <path
            d="M8.02342 3.60332H5.79677C5.59784 3.60332 5.41407 3.4972 5.31466 3.32499C5.21526 3.15277 5.21526 2.94054 5.31466 2.76832C5.41407 2.59611 5.59784 2.48999 5.79677 2.48999H8.02342C8.22235 2.48999 8.40612 2.5961 8.50552 2.76832C8.60493 2.94054 8.60493 3.15277 8.50552 3.32499C8.40612 3.4972 8.22235 3.60332 8.02342 3.60332Z"
            fill="#828282"
          />
        </svg>
        <span>Архів</span>
      </a>
    </div>
  </nav>

  <button
    mat-stroked-button
    #popoverTrigger="mtxPopoverTrigger"
    [mtxPopoverTriggerFor]="popover"
    mtxPopoverTriggerOn="hover"
    class="version"
  >
    VERSION
  </button>

  <mtx-popover #popover="mtxPopover" [elevation]="2">
    <p>
      <b>Frontend: </b> <u>{{ version }}</u>
    </p>

    <br />
    <b>Backend: </b>
    <ul>
      <li *ngFor="let item of uiFacadeService.version$ | async">
        <p>
          <u>{{ item }}</u>
        </p>
      </li>
    </ul>

    <p>Time info: {{ timeInfo | json }}</p>
  </mtx-popover>
</div>
