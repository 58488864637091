import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as ProductCategoryTypesAction from './product-category-types.actions';
import { CategoryTypeDto } from '@api/dictionaries';

export const productCategoryTypesFeatureKey = 'product-category-types';

export interface State extends EntityState<CategoryTypeDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<CategoryTypeDto>({
  selectId: (productCategoryType) => productCategoryType.id!,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ProductCategoryTypesAction.loadProductCategoryTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProductCategoryTypesAction.loadProductCategoryTypesSuccess, (state, { productCategoryTypes }) => ({
    ...adapter.setAll(productCategoryTypes, state),
    isLoading: false,
  })),
  on(ProductCategoryTypesAction.loadProductCategoryTypesFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
