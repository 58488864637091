import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ControlGroupsActions from './control-groups.actions';
import * as ControlGroupsSelectors from './control-groups.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ControlGroupsEffects {
  controlGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ControlGroupsActions.loadControlGroups),
      concatLatestFrom((action) => [this.store$.select(ControlGroupsSelectors.selectControlGroups)]),
      switchMap(([action, allCheckups]) =>
        allCheckups.length ? of(allCheckups) : this.dictionariesService.loadControlGroups(),
      ),
      switchMap((response) => [
        ControlGroupsActions.loadControlGroupsSuccess({
          controlGroups: response,
        }),
      ]),
      catchError((error) => {
        this.toastrService.error(error?.error?.message);
        return of(ControlGroupsActions.loadControlGroupsFailure({ error }));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
