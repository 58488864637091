/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LoanTypeDto } from '../models/LoanTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LoanTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all loan types
     * Returns all loan types by title.
     * @returns LoanTypeDto all loan types
     * @throws ApiError
     */
    public getApiLoanTypes({
        title,
    }: {
        /**
         * Loan type ID. Cannot be empty.
         */
        title?: string,
    }): Observable<Array<LoanTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/loan-types',
            query: {
                'title': title,
            },
        });
    }

    /**
     * Create loan type
     * Method for creating loan type.
     * @returns LoanTypeDto loan type created
     * @throws ApiError
     */
    public postApiLoanTypes({
        requestBody,
    }: {
        /**
         * Loan type object that needs to be created.
         */
        requestBody: LoanTypeDto,
    }): Observable<LoanTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/loan-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get loan type
     * Get loan type by ID.
     * @returns any loan type found
     * @throws ApiError
     */
    public getApiLoanTypes1({
        id,
    }: {
        /**
         * Loan type ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        typeTitle?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/loan-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `loan type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update loan type
     * Updates the loan type with the given ID.
     * @returns any loan type updated
     * @throws ApiError
     */
    public putApiLoanTypes({
        id,
        requestBody,
    }: {
        /**
         * Loan type ID. Cannot be empty.
         */
        id: string,
        /**
         * Loan type object that needs to be updated.
         */
        requestBody: LoanTypeDto,
    }): Observable<{
        id: string;
        typeTitle?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/loan-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete loan type
     * Deletes the loan type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiLoanTypes({
        id,
    }: {
        /**
         * Loan type ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/loan-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
