import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as ConditionGroupsActions from './condition-groups.actions';
import { ConditionGroupDto } from '@api/dictionaries';

export const conditionGroupsFeatureKey = 'condition-groups';

export interface State extends EntityState<ConditionGroupDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ConditionGroupDto> = createEntityAdapter<ConditionGroupDto>({
  selectId: (conditionGroup: ConditionGroupDto) => conditionGroup.id!,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ConditionGroupsActions.loadConditionGroups, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ConditionGroupsActions.loadConditionGroupsSuccess, (state, { conditionGroups }) => ({
    ...adapter.setAll(conditionGroups, state),
    isLoading: false,
  })),

  on(ConditionGroupsActions.loadConditionGroupsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
