import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadLoanClasses } from './loan-classes.actions';
import { selectAllLoanClassesList } from './loan-classes.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoanClassesFacadeService {
  public getLoanClassesList$ = this.store.select(selectAllLoanClassesList);

  constructor(private store: Store) {}

  loadLoanClasses() {
    this.store.dispatch(loadLoanClasses());
  }
}
