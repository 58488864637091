/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RegionDto } from '../models/RegionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RegionService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all regions
     * Returns a list of all regions.
     * @returns RegionDto List of regions or empty list
     * @throws ApiError
     */
    public getApiRegions(): Observable<Array<RegionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/regions',
        });
    }

    /**
     * Add region
     * Creates a new region object.
     * @returns RegionDto Region created successfully
     * @throws ApiError
     */
    public postApiRegions({
        requestBody,
    }: {
        /**
         * Region data.
         */
        requestBody: RegionDto,
    }): Observable<RegionDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/regions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update region
     * Update region.
     * @returns void
     * @throws ApiError
     */
    public patchApiRegions({
        requestBody,
    }: {
        /**
         * Region data.
         */
        requestBody: RegionDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/regions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get region by ID
     * Returns the region object with the specified ID.
     * @returns RegionDto Region found
     * @throws ApiError
     */
    public getApiRegions1({
        id,
    }: {
        /**
         * The region ID. Cannot be empty.
         */
        id: number,
    }): Observable<RegionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/regions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Delete region by ID.
     * Deletes the region object with the specified ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRegions({
        id,
    }: {
        /**
         * The region ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/regions/{id}',
            path: {
                'id': id,
            },
        });
    }

}
