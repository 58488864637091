/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SchedulerSyncDto } from '../models/SchedulerSyncDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SchedulerService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Create a new scheduler
     * Create a new scheduler based on the provided request body
     * @returns SchedulerSyncDto Scheduler resource created
     * @throws ApiError
     */
    public postApiSchedulers({
        requestBody,
    }: {
        /**
         * Scheduler object that needs to be added.
         */
        requestBody: SchedulerSyncDto,
    }): Observable<SchedulerSyncDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/schedulers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Find a scheduler by its title
     * @returns SchedulerSyncDto The scheduler was found and returned in the response body
     * @throws ApiError
     */
    public getApiSchedulers({
        title,
    }: {
        /**
         * Title of the scheduler to be obtained. Cannot be empty.
         */
        title: string,
    }): Observable<SchedulerSyncDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/schedulers/{title}',
            path: {
                'title': title,
            },
            errors: {
                404: `The scheduler with the specified title could not be found`,
            },
        });
    }

    /**
     * Update a scheduler by title
     * Update an existing scheduler based on the title
     * @returns SchedulerSyncDto Scheduler updated successfully
     * @throws ApiError
     */
    public putApiSchedulers({
        title,
        requestBody,
    }: {
        title: string,
        /**
         * New scheduler data to replace the existing one
         */
        requestBody: SchedulerSyncDto,
    }): Observable<SchedulerSyncDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/schedulers/{title}',
            path: {
                'title': title,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Delete scheduler by title
     * @returns void
     * @throws ApiError
     */
    public deleteApiSchedulers({
        title,
    }: {
        /**
         * Title of the scheduler to delete
         */
        title: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/schedulers/{title}',
            path: {
                'title': title,
            },
        });
    }

}
