import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyBarDirective } from '../../directives';

@Component({
  selector: 'sib-form-page',
  standalone: true,
  imports: [CommonModule, StickyBarDirective],
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPageComponent {}
