import { createAction, props } from '@ngrx/store';
import { BelongingObjectDto } from '@api/dictionaries';

export const loadBelongingObjects = createAction('[Dictionaries/API] Load Belonging Objects');
export const loadBelongingObjectsSuccess = createAction(
  '[Dictionaries/API] Load Belonging Objects Success',
  props<{ belongingObjects: BelongingObjectDto[] }>(),
);
export const loadBelongingObjectsFailure = createAction(
  '[Dictionaries/API] Load Belonging Objects Failure',
  props<{ error: unknown }>(),
);
