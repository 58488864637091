import { InjectionToken } from '@angular/core';

export const PLEDGER_CONTRACT_CONFIG = new InjectionToken<PledgerContractConfig>('pledger_contract_config');

export interface PledgerContractConfig {
  title: string;
  isFop: boolean;
  ownSignTitle: string;
  agentTitle: string;
  fopTitle: string;
}
