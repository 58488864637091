import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, JwtService } from '@sib/shared/da';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { iconEye, iconSlashEye, MatIconsRegistry } from '@sib/shared/ui-icons';
import { LogoComponent } from '@sib/shared/ui';
import { CommonModule } from '@angular/common';
import { LoginService, OpenAPI as OpenAPIAuth } from '@api/auth';

@Component({
  selector: 'sib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    LogoComponent,
    CommonModule,
  ],
})
export class LoginComponent {
  public passwordType = 'password';
  public submitted = false;
  public loginForm = new FormGroup({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    private router: Router,
    private https: HttpClient,
    private authServ: AuthService,
    private loginService: LoginService,
    private matIconsRegistry: MatIconsRegistry,
    private jwtService: JwtService,
  ) {
    this.matIconsRegistry.add(iconEye, iconSlashEye);
  }

  changeInputType() {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }

  login() {
    this.jwtService.clearToken();
    if (this.loginForm.status === 'INVALID') {
      return;
    }

    OpenAPIAuth.HEADERS = {
      'No-Auth-Challenge': 'true',
      Authorization: `Basic ${btoa(
        unescape(
          encodeURIComponent(
            `${this.loginForm.controls['username'].value.trim()}:${this.loginForm.controls['password'].value}`,
          ),
        ),
      )}`,
    };

    this.loginService.postLogin().subscribe({
      next: ({ token }) => {
        this.jwtService.setToken(token!);
        this.authServ.setPermissions();
        this.router.navigate(['']);

        // Should be deleted once login will be refactored on request with body
        // @ts-ignore
        delete OpenAPIAuth.HEADERS['No-Auth-Challenge'];
        // @ts-ignore
        delete OpenAPIAuth.HEADERS['Authorization'];
      },
      error: (error) => {
        switch (error.status) {
          case HttpStatusCode.Unauthorized:
            this.loginForm.controls.password.setErrors({
              formError: 'Відмовлено у доступі. Логін або пароль вказані некоректно.',
            });
            break;

          case HttpStatusCode.Forbidden:
            this.loginForm.controls.username.setErrors({
              formError: 'Відмовлено у доступі. У системі користувачу не призначено жодної ролі.',
            });
            break;
          default:
            this.loginForm.controls.password.setErrors({
              formError: 'Невизначена помилка. Зверніться до адміністратора.',
            });
            break;
        }
      },
    });
  }
}
