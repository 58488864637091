import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as BpRolesAction from './bp-roles.actions';
import { BPRoleDto } from '@api/dictionaries';

export const bpRolesFeatureKey = 'bp-roles';

export interface State extends EntityState<BPRoleDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<BPRoleDto>({
  selectId: (bpRoles) => bpRoles.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(BpRolesAction.loadBpRoles, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(BpRolesAction.loadBpRolesSuccess, (state, { bpRoles }) => ({
    ...adapter.setAll(bpRoles, state),
    isLoading: false,
  })),
  on(BpRolesAction.loadBpRolesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
