import { createSelector } from '@ngrx/store';
import * as fromEmployees from './employees.reducer';
import { convertArrayToObjectTyped } from '@sib/shared/util';
import { selectEmployeesState } from '../+shell/reducers.index';

export const selectEmployeesIsLoading = createSelector(selectEmployeesState, fromEmployees.selectIsLoading);

export const selectEmployeesEntities = createSelector(selectEmployeesState, fromEmployees.selectEntities);

export const selectAllEmployees = createSelector(selectEmployeesState, fromEmployees.selectAll);

export const selectEmployeeById = (employeeId: number) =>
  createSelector(selectEmployeesEntities, (employeesEntities) => employeesEntities[employeeId]);

export const selectEmployeesLoginEntities = createSelector(selectAllEmployees, (allEmployees) =>
  convertArrayToObjectTyped(allEmployees, 'login'),
);

export const selectEmployeeByLogin = (employeeLogin: string) =>
  createSelector(selectEmployeesLoginEntities, (employeesLoginEntities) => employeesLoginEntities[employeeLogin]);
