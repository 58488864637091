import { ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sib-currency-input',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './currency-input.view.component.html',
  styleUrls: ['./currency-input.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyInputViewComponent {
  @ContentChild('[currency]')
  public currency: HTMLElement | undefined;
}
