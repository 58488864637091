import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LoanTypesActions from './loan-types.actions';
import * as LoanTypesSelectors from './loan-types.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LoanTypesEffects {
  loadLoanTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoanTypesActions.loadLoanTypes),
      concatLatestFrom((action) => [this.store$.select(LoanTypesSelectors.selectAllLoanTypesList)]),
      switchMap(([action, allLoanTypesList]) =>
        (allLoanTypesList.length ? of(allLoanTypesList) : this.dictionariesService.loadLoanTypes()).pipe(
          map((response) =>
            LoanTypesActions.loadLoanTypesSuccess({
              loanTypesList: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(LoanTypesActions.loadLoanTypesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
