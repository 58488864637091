/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DictionaryGroupDTO } from '../models/DictionaryGroupDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DictionaryGroupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all dictionary groups
     * @returns DictionaryGroupDTO list of DictionaryGroups or empty list
     * @throws ApiError
     */
    public getApiDictionaryGroups(): Observable<Array<DictionaryGroupDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-groups',
        });
    }

    /**
     * Create a new dictionary group
     * @returns DictionaryGroupDTO DictionaryGroup created
     * @throws ApiError
     */
    public postApiDictionaryGroups({
        requestBody,
    }: {
        /**
         * Dictionary group object to be created
         */
        requestBody: DictionaryGroupDTO,
    }): Observable<DictionaryGroupDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/dictionary-groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get dictionary group by ID
     * @returns DictionaryGroupDTO DictionaryGroup exist
     * @throws ApiError
     */
    public getApiDictionaryGroups1({
        id,
    }: {
        /**
         * ID of the dictionary group to be obtained. Cannot be empty.
         */
        id: number,
    }): Observable<DictionaryGroupDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `DictionaryGroup not found`,
            },
        });
    }

    /**
     * Update dictionary group by ID
     * @returns DictionaryGroupDTO DictionaryGroup updated
     * @throws ApiError
     */
    public putApiDictionaryGroups({
        id,
        requestBody,
    }: {
        /**
         * Dictionary group ID
         */
        id: number,
        /**
         * Dictionary group object to be updated
         */
        requestBody: DictionaryGroupDTO,
    }): Observable<DictionaryGroupDTO> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/dictionary-groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `DictionaryGroup DTO has wrong ID`,
                404: `DictionaryGroup not found`,
            },
        });
    }

    /**
     * Delete dictionary group by ID
     * @returns void
     * @throws ApiError
     */
    public deleteApiDictionaryGroups({
        id,
    }: {
        /**
         * Dictionary group ID
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/dictionary-groups/{id}',
            path: {
                'id': id,
            },
        });
    }

}
