import { createSelector } from '@ngrx/store';
import * as fromCurrencies from './currencies.reducer';
import { selectCurrenciesState } from '../+shell/reducers.index';

export const selectCurrenciesIsLoading = createSelector(selectCurrenciesState, fromCurrencies.selectIsLoading);

export const selectCurrenciesEntities = createSelector(selectCurrenciesState, fromCurrencies.selectEntities);

export const selectCurrencyById = (code: string) =>
  createSelector(selectCurrenciesEntities, (currenciesEntities) => currenciesEntities[code]);

export const selectAllCurrencies = createSelector(selectCurrenciesState, fromCurrencies.selectAll);
