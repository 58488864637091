import { createReducer, on } from '@ngrx/store';
import * as BankServicesActions from './bank-services.action';
import { BankServiceDTO } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const bankServicesFeatureKey = 'bank-services';

export interface State extends EntityState<BankServiceDTO> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<BankServiceDTO>({
  selectId: (item) => item.serviceCode,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(BankServicesActions.loadBankServices, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(BankServicesActions.loadBankServicesSuccess, (state, { bankServices }) => ({
    ...adapter.setAll(bankServices, state),
    isLoading: false,
  })),
  on(BankServicesActions.loadBankServicesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectBankServicesState = selectAll;
