import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectProcessDefinitionKey,
  selectProcessInstanceId,
  selectRootProcessInstanceId,
  selectTaskAssignee,
  selectTaskDefinitionKey,
  selectTaskDetails,
  selectTaskId,
  selectTaskName,
} from './task-details.selectors';
import { DefaultService } from '@api/camunda';
import { tap } from 'rxjs';
import { taskDetailsActions } from './task-details.actions';
import { TaskDetailsState } from './task-details.reducer';

@Injectable({ providedIn: 'root' })
export class TaskDetailsFacadeService {
  private readonly store = inject(Store);
  private readonly camundaService = inject(DefaultService);

  public selectTaskId$ = this.store.select(selectTaskId);
  public selectTaskAssignee$ = this.store.select(selectTaskAssignee);
  public selectTaskName$ = this.store.select(selectTaskName);
  public selectProcessInstanceId$ = this.store.select(selectProcessInstanceId);
  public selectRootProcessInstanceId$ = this.store.select(selectRootProcessInstanceId);
  public selectProcessDefinitionKey$ = this.store.select(selectProcessDefinitionKey);
  public selectTaskDetails$ = this.store.select(selectTaskDetails);
  public selectTaskDefinitionKey$ = this.store.select(selectTaskDefinitionKey);

  public getTaskDetails(id: string) {
    return this.camundaService.getHistoryTask({ taskId: id }).pipe(tap((result) => this.setTaskDetails(result[0])));
  }

  public getHistoricTaskDetails(id: string) {
    return this.camundaService
      .getHistoryTask({ processInstanceId: id, maxResults: 1 })
      .pipe(tap((result) => this.setTaskDetails({ ...result[0], processInstanceId: id })));
  }

  public setTaskDetails(taskDetails: Partial<TaskDetailsState>) {
    this.store.dispatch(taskDetailsActions.loadSuccess({ taskDetails }));
  }
}
