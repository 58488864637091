import { createAction, props } from '@ngrx/store';
import { ConsiderationLevelDto } from '@api/dictionaries';

export const loadConsiderationLevel = createAction('[Dictionaries/API] Load Consideration Level');
export const loadConsiderationLevelSuccess = createAction(
  '[Dictionaries/API] Load Consideration Level Success',
  props<{ considerationLevelList: ConsiderationLevelDto[] }>(),
);
export const loadConsiderationLevelFailure = createAction(
  '[Dictionaries/API] Load Consideration Level Failure',
  props<{ error: unknown }>(),
);
