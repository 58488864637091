import { createAction, props } from '@ngrx/store';
import { ProposalTypeDto } from '@api/dictionaries';

export const loadProposalsTypes = createAction('[Dictionaries/API] Load Proposals Types');
export const loadProposalsTypesSuccess = createAction(
  '[Dictionaries/API] Load Proposals Types Success',
  props<{ proposalsTypesList: ProposalTypeDto[] }>(),
);
export const loadProposalsTypesFailure = createAction(
  '[Dictionaries/API] Load Proposals Types Failure',
  props<{ error: any }>(),
);
