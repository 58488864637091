import { createAction, props } from '@ngrx/store';
import { ExpandedSOD } from './sods.reducers';

export const loadSOD = createAction('[Dictionaries/API] Load SOD');
export const loadSODSuccess = createAction('[Dictionaries/API] Load SOD Success', props<{ SOD: ExpandedSOD[] }>());
export const loadSODNameSuccess = createAction(
  '[Dictionaries/API] Load SOD Name Success',
  props<{ SOD: ExpandedSOD[] }>(),
);
export const loadSODFailure = createAction('[Dictionaries/API] Load SOD Failure', props<{ error: unknown }>());
