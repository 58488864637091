import { createReducer, on } from '@ngrx/store';
import * as Checkups from './checkups.actions';
import { CheckupDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const checkupsFeatureKey = 'checkups';

export interface State extends EntityState<CheckupDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<CheckupDto>({
  selectId: (item) => item.id!,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(Checkups.loadCheckups, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(Checkups.loadCheckupsSuccess, (state, { checkups = [] }) => ({
    ...adapter.setAll(checkups, state),
    isLoading: false,
  })),
  on(Checkups.loadCheckupsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(Checkups.loadCheckupsReset, () => initialState),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCheckupsState = selectAll;
