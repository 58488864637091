import { Address, Guarantor, PropertyOwner } from '@api/loan-approval';
import { Dictionary } from '@ngrx/entity/src/models';
import { RegionDto } from '@api/loan-org-structure';
import { AssetTypeDto, CarBrandDto, EquipmentTypeDto, MeasurementUnitDto } from '@api/dictionaries';
import { formatDateWithPoint } from '@sib/shared/util';
import {
  DepositDto,
  EquipmentDto,
  FullProvidingInfo,
  GoodDto,
  LandDto,
  RealtyDto,
  VehicleDto,
} from '@sib/task/shared/store';

export function generateTermsSecuringTitle({ item }: { item: DepositDto }): string;
export function generateTermsSecuringTitle({
  item,
  carBrandsEntities,
}: {
  item: VehicleDto;
  carBrandsEntities?: Dictionary<CarBrandDto>;
}): string;
export function generateTermsSecuringTitle({
  item,
  regionsEntities,
  assetTypesEntities,
}: {
  item: RealtyDto;
  regionsEntities?: Dictionary<RegionDto>;
  assetTypesEntities?: Dictionary<AssetTypeDto>;
}): string;
export function generateTermsSecuringTitle({
  item,
  regionsEntities,
  assetTypesEntities,
}: {
  item: LandDto;
  regionsEntities?: Dictionary<RegionDto>;
  assetTypesEntities?: Dictionary<AssetTypeDto>;
}): string;
export function generateTermsSecuringTitle({
  item,
  regionsEntities,
  measurementUtilsEntities,
}: {
  item: GoodDto;
  regionsEntities?: Dictionary<RegionDto>;
  measurementUtilsEntities?: Dictionary<MeasurementUnitDto>;
}): string;
export function generateTermsSecuringTitle({
  item,
  regionsEntities,
  equipmentTypesEntities,
}: {
  item: EquipmentDto;
  regionsEntities?: Dictionary<RegionDto>;
  equipmentTypesEntities?: Dictionary<EquipmentTypeDto>;
}): string;
export function generateTermsSecuringTitle({
  item,
  regionsEntities,
  measurementUtilsEntities,
  assetTypesEntities,
  equipmentTypesEntities,
  carBrandsEntities,
}: {
  item: FullProvidingInfo;
  regionsEntities?: Dictionary<RegionDto>;
  measurementUtilsEntities?: Dictionary<MeasurementUnitDto>;
  assetTypesEntities?: Dictionary<AssetTypeDto>;
  equipmentTypesEntities?: Dictionary<EquipmentTypeDto>;
  carBrandsEntities?: Dictionary<CarBrandDto>;
}): string {
  switch (item.assetCategory) {
    case 'deposit':
      return `Права вимоги грошових коштів за сумою вкладу ${item.value || '-'} ${
        item.currencyCode || '-'
      } та всією сумою нарахованих процентів, відповідно до умов договору ${item.dealNumber} від ${
        item.startDate ? formatDateWithPoint(new Date(item.startDate)) : '-'
      } року, укладеному між Банком та ${item.partnerName}, код за ДРФО/ЄДРПОУ ${item.idNumber}`;
    case 'realty':
      return `${assetTypesEntities?.[item.assetType]!.assetTypeName}, що складається з ____, загальною площею ${
        item.totalArea || '-'
      } м² що знаходиться за адресою: ${
        item.address ? generateAddressString(item.address, regionsEntities!) : '-'
      }, та є власністю ${generateOwners(item.owners)}. ${generateMarketCostSentence(item)}`;
    case 'land':
      return `${assetTypesEntities?.[item.assetType]!.assetTypeName} загальною площею ${
        item.totalArea || '-'
      } м², кадастровий номер ${
        item.landDocument?.cadastreNumber || '-'
      }, з цільовим призначенням ____, що знаходиться за адресою: ${
        item.address ? generateAddressString(item.address, regionsEntities!) : '-'
      }, та є власністю ${item.partnerName}, код за ДРФО/ЄДРПОУ ${item.idNumber}. ${generateMarketCostSentence(item)}`;
    case 'goods':
      return `${item.title || '-'}, кількістю/масою ${item.amount || '-'} ${
        item.unit ? measurementUtilsEntities?.[item.unit]!.description : '-'
      }, що є власністю ${item.partnerName}, код за ДРФО/ЄДРПОУ ${item.idNumber}, та зберігається за адресою ${
        item.address ? generateAddressString(item.address!, regionsEntities!) : '-'
      }. ${generateMarketCostSentence(item)}`;
    case 'equipment':
      return `${item.type ? equipmentTypesEntities?.[item.type]!.description : '-'}, ${
        item.year
      } року випуску, заводський номер ${item.numberFac || '-'}, що є власністю ${
        item.partnerName
      }, код за ДРФО/ЄДРПОУ ${item.idNumber} та зберігається за адресою ${
        item.address ? generateAddressString(item.address!, regionsEntities!) : '-'
      }. ${generateMarketCostSentence(item)}`;
    case 'vehicles':
      return `${item.brand ? carBrandsEntities?.[item.brand]?.brand : '-'} ${item.model || '-'}, ${
        item.year || '-'
      } року випуску, об'єм двигуна _____, № кузову ${item.numberChassis || '-'}, що є власністю ${
        item.partnerName
      }, код за ДРФО/ЄДРПОУ ${item.idNumber}, відповідно до свідоцтва про реєстрацію транспортного засобу № ${
        item.docNumber
      }, від ${item.docDate ? formatDateWithPoint(new Date(item.docDate)) : '-'} року. ${generateMarketCostSentence(
        item,
      )}`;
    default:
      return '';
  }
}

export function generateTermsSecuringTitleGuarantor(item: Guarantor): string {
  return `Порука ${item.orgName}, код за ДРФО/ЄДРПОУ ${item.idNumber}.`;
}

export function generateAddressString(address: Address[], regionsEntities: Dictionary<RegionDto>): string {
  return address.reduce(
    (acc, item) =>
      (acc += `${item.country} обл. ${regionsEntities[item.region!]?.title}, ${
        item.area ? 'р-н ' + item.area + ',' : ''
      } ${item.settlement}, ${item.street}, ${item.house}. `),
    '',
  );
}

export function generateOwners(owners: PropertyOwner[]): string {
  return owners.reduce(
    (acc, owner) => (acc += ` ${owner.partnerName}, код за ДРФО/ЄДРПОУ ${owner.idNumber ? owner.idNumber : ''};`),
    '',
  );
}

export const generateMarketCostSentence = (asset: FullProvidingInfo) =>
  `Ринкова вартість ${asset.value} ${asset.currencyCode}`;
