import { inject, Injectable } from '@angular/core';
import { AbstractStoreService } from './abstract-store.service';
import { combineLatest, switchMap, throwError } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  AgreementGuarantyFacade,
  AssetsFacadeService,
  deleteTaskV2ProcessVariable,
  setTaskV2ProcessVariable,
} from '@sib/task/shared/store';

@Injectable({ providedIn: 'root' })
export class AgreementGuarantiesService extends AbstractStoreService {
  private readonly agreementGuarantyFacade = inject(AgreementGuarantyFacade);
  private readonly assetsFacadeService = inject(AssetsFacadeService);

  deleteAgreementGuaranty(cagIds: string[]) {
    return combineLatest([
      this.agreementGuarantyFacade.selectAllAgreementGuaranties$.pipe(
        map((agreementGuarantyAll) => agreementGuarantyAll.filter((item) => cagIds.includes(item.cagId || ''))),
      ),
      this.assetsFacadeService.selectAllAssets$,
    ]).pipe(
      first(),
      switchMap(([agreementGuaranties, assets]) => {
        if (agreementGuaranties.some((item) => item.cagmtId)) {
          return throwError(() => ({
            extraData: {
              agreementGuaranties,
            },
          }));
        }

        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              asset: assets.map((asset) => ({
                ...asset,
                cagId: agreementGuaranties.some((agreementGuaranty) => agreementGuaranty.cagId === asset.cagId)
                  ? ''
                  : asset.cagId,
              })),
            },
          }),
        );
        return this.waitForResponse(undefined).pipe(
          switchMap(() => {
            this.store.dispatch(
              deleteTaskV2ProcessVariable({
                requestBody: {
                  agreementGuaranty: agreementGuaranties,
                },
              }),
            );
            return this.waitForResponse(undefined);
          }),
        );
      }),
    );
  }
}
