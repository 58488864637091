import { createSelector } from '@ngrx/store';
import * as fromLoanClasses from './loan-classes.reducer';
import { selectLoanClassesState } from '../+shell/reducers.index';

export const selectLoanClassesListIsLoading = createSelector(selectLoanClassesState, fromLoanClasses.selectIsLoading);

export const selectLoanClassesListEntities = createSelector(selectLoanClassesState, fromLoanClasses.selectEntities);

export const selectLoanClassById = (id: number) =>
  createSelector(selectLoanClassesListEntities, (loanClassesListEntities) => loanClassesListEntities[id]);

export const selectAllLoanClassesList = createSelector(selectLoanClassesState, fromLoanClasses.selectAll);
