import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCarBrands, selectCarBrandsEntities } from './car-brands.selectors';
import { loadCarBrands } from './car-brands.actions';

@Injectable({
  providedIn: 'root',
})
export class CarBrandsFacadeService {
  public carBrands$ = this.store.select(selectCarBrands);
  public carBrandsEntities$ = this.store.select(selectCarBrandsEntities);

  constructor(private store: Store) {}

  public loadCarBrands() {
    this.store.dispatch(loadCarBrands());
  }
}
