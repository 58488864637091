import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

export interface InfoDialogModel<T extends Record<string, unknown> = Record<string, never>> {
  title?: string;
  prefix?: string;
  postfix?: string;
  buttonTitleYes?: string;
  buttonTitleNo?: string;
  buttonNoHidden?: boolean;
  buttonYesHidden?: boolean;
  dataSource: T[];
  columns: { name: keyof T; value: string }[];
}

@Component({
  selector: 'sib-info-table-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, MatTableModule],
  templateUrl: './info-table-dialog.component.html',
  styleUrls: ['./info-table-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTableDialogComponent implements OnInit {
  public dataSource = new MatTableDataSource<unknown>();
  public columns: { name: string; value: string }[] = [];
  public columnsDef: string[] = [];

  public defaultConfig: Omit<InfoDialogModel, 'dataSource' | 'columns'> = {
    title: 'Помилка',
    prefix: '',
    postfix: '',
    buttonTitleYes: 'Так',
    buttonTitleNo: 'Назад',
    buttonNoHidden: false,
    buttonYesHidden: true,
  };

  constructor(
    public dialogRef: MatDialogRef<InfoTableDialogComponent, InfoDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogModel,
  ) {}

  ngOnInit(): void {
    this.data = { ...this.defaultConfig, ...this.data };
    this.dataSource.data = this.data.dataSource;
    this.columns = this.data.columns;
    this.columnsDef = this.columns.map((column) => column.name);
  }
}
