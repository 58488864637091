/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Guarantor } from '../models/Guarantor';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class GuarantorService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get guarantor
     * Get guarantor by bp code.
     * @returns Guarantor Client Partner Set data found
     * @throws ApiError
     */
    public getSapGuarantor({
        bpCode,
    }: {
        bpCode?: string,
    }): Observable<Guarantor> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/guarantor',
            query: {
                'bp-code': bpCode,
            },
        });
    }

}
