/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AcdResponse } from '../models/AcdResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AksIntegrationService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Upload request documents
     * Uploads documents for a given process instance ID and returns the response from ACD.
     * @returns AcdResponse Successful operation
     * @throws ApiError
     */
    public postAksDocuments({
        processInstanceId,
    }: {
        /**
         * The ID of the process instance.
         */
        processInstanceId: string,
    }): Observable<Array<AcdResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/aks/documents/{processInstanceId}',
            path: {
                'processInstanceId': processInstanceId,
            },
        });
    }

}
