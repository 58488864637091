import { ErrMessage } from '../generated';

export const errorMessageCamundaVariable = (notifications: ErrMessage[]) => ({
  type: 'Object',
  value: JSON.stringify(notifications),
  valueInfo: {
    objectTypeName: 'java.util.ArrayList<ua.usb.bpm.process.model.ErrMessage>',
    serializationDataFormat: 'application/json',
  },
});

export const filterParamsCamundaVariable = (filterParams: { asset: string[] }) => ({
  type: 'Object',
  value: JSON.stringify(filterParams),
  valueInfo: {
    objectTypeName: 'java.util.HashMap<java.lang.Object,java.lang.Object>',
    serializationDataFormat: 'application/json',
  },
});
