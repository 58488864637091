import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllConsiderationLevelList,
  selectIsAllConsiderationLevelListLoading,
} from './consideration-level.selectors';
import { loadConsiderationLevel } from './consideration-level.actions';

@Injectable({
  providedIn: 'root',
})
export class ConsiderationLevelFacadeService {
  public selectAllConsiderationLevelList$ = this.store.select(selectAllConsiderationLevelList);
  public selectIsLoading$ = this.store.select(selectIsAllConsiderationLevelListLoading);

  constructor(private store: Store) {}

  public loadConsiderationLevel() {
    this.store.dispatch(loadConsiderationLevel());
  }
}
