import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class ResponsibilityContractGroupService extends FormGroup<typeof ResponsibilityContractGroupService.formGroup> {
  private static readonly formGroup = {
    gtSum: new FormControl<number | null>({ value: null, disabled: true }),
    gtCurr: new FormControl<string>({ value: '', disabled: true }),
    gtEkv: new FormControl<number | null>({ value: null, disabled: true }),
    gtTerm: new FormControl<number | null>({ value: null, disabled: true }),
  };

  constructor() {
    super(ResponsibilityContractGroupService.formGroup);
  }

  get gtSum() {
    return this.controls.gtSum;
  }
}
