import { createSelector } from '@ngrx/store';
import { taskFeatureSelector } from '../task/task.reducer';
import { taskDetailsFeature } from './task-details.reducer';

export const selectTaskDetails = createSelector(taskFeatureSelector, taskDetailsFeature.selectTaskDetailsState);
export const selectTaskId = createSelector(taskFeatureSelector, taskDetailsFeature.selectId);
export const selectTaskAssignee = createSelector(taskFeatureSelector, taskDetailsFeature.selectAssignee);
export const selectTaskName = createSelector(taskFeatureSelector, taskDetailsFeature.selectName);
export const selectProcessInstanceId = createSelector(taskFeatureSelector, taskDetailsFeature.selectProcessInstanceId);
export const selectRootProcessInstanceId = createSelector(
  taskFeatureSelector,
  taskDetailsFeature.selectRootProcessInstanceId,
);
export const selectProcessDefinitionKey = createSelector(
  taskFeatureSelector,
  taskDetailsFeature.selectProcessDefinitionKey,
);
export const selectTaskDefinitionKey = createSelector(taskFeatureSelector, taskDetailsFeature.selectTaskDefinitionKey);
