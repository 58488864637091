import { createReducer, on } from '@ngrx/store';
import * as MeasurementUnits from './measurement-units.actions';
import { MeasurementUnitDto } from '@api/dictionaries';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const measurementUnitsFeatureKey = 'measurement-units';

export const adapter: EntityAdapter<MeasurementUnitDto> = createEntityAdapter<MeasurementUnitDto>({
  selectId: (measurement: MeasurementUnitDto) => measurement.id,
  sortComparer: false,
});

export interface State extends EntityState<MeasurementUnitDto> {
  isLoading: boolean;
  error: any;
}

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(MeasurementUnits.loadMeasurementUnits, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(MeasurementUnits.loadMeasurementUnitsSuccess, (state, { measurementUnits }) => ({
    ...adapter.setAll(measurementUnits, state),
    isLoading: false,
  })),
  on(MeasurementUnits.loadMeasurementUnitsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
