/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SodDto } from '../models/SodDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SodService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all sods
     * Returns all sods.
     * @returns SodDto all sods
     * @throws ApiError
     */
    public getApiSods(): Observable<Array<SodDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/sods',
        });
    }

    /**
     * Update sod
     * Updates the sod with the given ID.
     * @returns void
     * @throws ApiError
     */
    public putApiSods({
        requestBody,
    }: {
        /**
         * Sod object that needs to be updated.
         */
        requestBody: SodDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/sods',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Create sod
     * Method for creating sod.
     * @returns SodDto sod created
     * @throws ApiError
     */
    public postApiSods({
        requestBody,
    }: {
        /**
         * Sod object that needs to be created.
         */
        requestBody: SodDto,
    }): Observable<SodDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/sods',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get sod
     * Get sod by ID.
     * @returns any sod found
     * @throws ApiError
     */
    public getApiSods1({
        id,
    }: {
        /**
         * Sod ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id?: string;
        accreditSod?: boolean;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/sods/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `sod not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update sod
     * Updates the sod with the given ID.
     * @returns any sod updated
     * @throws ApiError
     */
    public putApiSods1({
        id,
        requestBody,
    }: {
        /**
         * Sod ID. Cannot be empty.
         */
        id: string,
        /**
         * Sod object that needs to be updated.
         */
        requestBody: SodDto,
    }): Observable<{
        id: string;
        accreditSod: boolean;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/sods/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete sod
     * Deletes the sod with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiSods({
        id,
    }: {
        /**
         * Sod ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/sods/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
