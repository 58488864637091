import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllEmployees, selectEmployeeById, selectEmployeeByLogin } from './employees.selectors';
import { loadEmployees } from './employees.actions';

@Injectable({
  providedIn: 'root',
})
export class EmployeesFacadeService {
  public initiator$ = (initiatorId: string) => this.store.select(selectEmployeeByLogin(initiatorId));
  public selectAllEmployees$ = this.store.select(selectAllEmployees);
  public selectEmployeeById$ = (employeeId: string) => this.store.select(selectEmployeeById(Number(employeeId)));
  constructor(private store: Store) {}

  loadEmployees() {
    this.store.dispatch(loadEmployees());
  }
}
