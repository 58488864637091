import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProductActions from './product.actions';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';
import { ProductFacadeService } from './product.facade.service';
import { ProductDto } from '@api/dictionaries';

@Injectable()
export class ProductEffects {
  loadProductList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProductList),
      switchMap(() => this.productFacade.selectAllProductList$.pipe(take(1))),
      switchMap((allProductList: ProductDto[]) =>
        allProductList.length ? of(allProductList) : this.dictionariesService.loadProductList(),
      ),
      map((response) =>
        ProductActions.loadProductListSuccess({
          productList: response,
        }),
      ),
      catchError((error) => {
        this.toastrService.error(error?.error?.message);
        return of(ProductActions.loadProductListFailure({ error }));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private dictionariesService: DictionariesService,
    private productFacade: ProductFacadeService,
    private toastrService: ToastrService,
  ) {}
}
