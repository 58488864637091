/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { KeyDto } from '../models/KeyDto';
import type { UITabDto } from '../models/UITabDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UiTabService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all ui tabs
     * Returns all ui tabs.
     * @returns UITabDto all ui tabs
     * @throws ApiError
     */
    public getApiTabAccessUiTab(): Observable<Array<UITabDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/tab-access/ui-tab',
        });
    }

    /**
     * Create ui tab
     * Method for creating ui tab.
     * @returns UITabDto ui tab created
     * @throws ApiError
     */
    public postApiTabAccessUiTab({
        requestBody,
    }: {
        /**
         * UI tab object that needs to be created.
         */
        requestBody: UITabDto,
    }): Observable<UITabDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/tab-access/ui-tab',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get ui tab
     * Get ui tab by tab code.
     * @returns any ui tab found
     * @throws ApiError
     */
    public getApiTabAccessUiTab1({
        tabCode,
    }: {
        /**
         * Tab code. Cannot be empty.
         */
        tabCode: string,
    }): Observable<{
        tabCode: string;
        tabName?: string;
        docTemp?: Array<KeyDto>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/tab-access/ui-tab/{tabCode}',
            path: {
                'tabCode': tabCode,
            },
            errors: {
                404: `ui tab not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update ui tab
     * Updates the ui tab with the given tb code.
     * @returns any ui tab updated
     * @throws ApiError
     */
    public putApiTabAccessUiTab({
        tabCode,
        requestBody,
    }: {
        /**
         * Tab code. Cannot be empty.
         */
        tabCode: string,
        /**
         * UI tab object that needs to be updated.
         */
        requestBody: UITabDto,
    }): Observable<{
        tabCode: string;
        tabName?: string;
        docTemp?: Array<KeyDto>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/tab-access/ui-tab/{tabCode}',
            path: {
                'tabCode': tabCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete ui tab
     * Deletes the ui tab with the given tab code.
     * @returns void
     * @throws ApiError
     */
    public deleteApiTabAccessUiTab({
        tabCode,
    }: {
        /**
         * Tab code. Cannot be empty.
         */
        tabCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/tab-access/ui-tab/{tabCode}',
            path: {
                'tabCode': tabCode,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
