import { createAction, props } from '@ngrx/store';

export const loadConditionGroups = createAction('[Dictionaries/API] Load Condition Groups');
export const loadConditionGroupsSuccess = createAction(
  '[Dictionaries/API] Load Condition Groups Success',
  props<{ conditionGroups: any[] }>(),
);
export const loadConditionGroupsFailure = createAction(
  '[Dictionaries/API] Load Condition Groups Failure',
  props<{ error: any }>(),
);
