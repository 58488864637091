import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { ApiError, SapIntegrationService, Spouse } from '@api/loan-approval';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectionModel } from '@angular/cdk/collections';
import { ConsentHusbandWifeGroupService } from './consent-husband-wife-group.service';
import { catchError } from 'rxjs/operators';
import { SapGeneralError } from '@sib/shared/util';
import { of, throwError } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sib-consent-husband-wife-contract-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  templateUrl: './consent-husband-wife-contract-form.component.html',
  styleUrls: ['./consent-husband-wife-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ConsentHusbandWifeContractFormComponent {
  public displayedColumns = ['partner', 'partnerName', 'agreement', 'passP', 'passpDate', 'passpPlace'];
  public dataSource = new MatTableDataSource<Spouse>();
  public selection = new SelectionModel<Spouse>();

  @Input()
  public bpCode!: number | string | null;

  @Input() set spouses(spouses: Spouse[] | undefined) {
    if (spouses?.length) {
      this.dataSource.data = spouses;
      this.consentHusbandWifeGroupService.patchValue({ consentHusbandWife: spouses });
    }
  }

  constructor(
    private sapIntegrationService: SapIntegrationService,
    public consentHusbandWifeGroupService: ConsentHusbandWifeGroupService,
  ) {
    this.selection.changed.subscribe(() => {
      const spouses = this.consentHusbandWifeGroupService.getRawValue().consentHusbandWife || [];
      this.dataSource.data = spouses.map((item) =>
        item.passP === this.selection.selected[0].passP
          ? { ...item, agreement: item.agreement === 'true' ? 'false' : 'true' }
          : item,
      );
      this.consentHusbandWifeGroupService.patchValue({
        consentHusbandWife: this.dataSource.data,
      });
    });
  }

  loadInfo() {
    this.sapIntegrationService
      .getSapGetPartnerSpouseData({ bpCode: String(this.bpCode!) })
      .pipe(catchError((error: ApiError) => (error.body instanceof SapGeneralError ? of([]) : throwError(() => error))))
      .subscribe((partnerSpouseData) => {
        this.dataSource.data = partnerSpouseData.map((i) => ({
          partner: i.spouseBpNum || '',
          partnerName: i.spouseName || '',
          agreement: 'false',
          idNumber: i.spouseTaxId || '',
          passP: i.spousePassp || '',
          passpDate: i.spousePasspDate || '',
          passpPlace: i.spousePasspPlace || '',
        }));

        this.consentHusbandWifeGroupService.patchValue({
          consentHusbandWife: this.dataSource.data,
        });
      });
  }
}
