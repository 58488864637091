<mat-card formGroupName="bankAgreement">
  <mat-card-header>
    <mat-card-title>Договір банківського вкладу</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-form-field>
      <mat-label>Повна назва договору</mat-label>

      <input matInput type="text" placeholder="Введіть повну назву договору" formControlName="dealTitle" />
    </mat-form-field>

    <div class="row">
      <mat-form-field>
        <mat-label>Номер договору</mat-label>
        <input matInput type="text" placeholder="Номер договору" formControlName="dealNumber" />
      </mat-form-field>

      <mat-form-field (click)="datepicker.open()">
        <mat-label>Дата</mat-label>
        <input matInput formControlName="startDate" [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
