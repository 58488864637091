import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sib-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LoaderComponent {}
