import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Agreement } from '@api/loan-approval';

const TERM_MAX_VALUE = 90;
const maxValidator = (control: AbstractControl) =>
  control.value && control.value > TERM_MAX_VALUE
    ? { max: `Макс значення не може бути більше ніж ${TERM_MAX_VALUE} днів` }
    : null;

@UntilDestroy()
@Injectable()
export class TermsGrantingTrancheFormService extends FormGroup<
  ReturnType<typeof TermsGrantingTrancheFormService.form>
> {
  static readonly form = () => ({
    conditionTerm: new FormControl<Agreement['conditionTerm'] | undefined>('conditionTermMiss', {
      nonNullable: true,
    }),
    termMin: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    termMax: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
    conditionSumMin: new FormControl<boolean>(false, { nonNullable: true }),
    sumMin: new FormControl<number | undefined>(undefined, { nonNullable: true }),
  });

  constructor() {
    super(TermsGrantingTrancheFormService.form());

    this.termMax.addValidators(this.minMaxValidator.bind(this));
    this.termMin.addValidators(this.minMaxValidator.bind(this));

    this.conditionTermType.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.termMin.setValue(undefined);
      this.termMin.markAsUntouched();

      this.termMax.setValue(undefined);
      this.termMax.markAsUntouched();

      switch (value) {
        case 'conditionTermMinMax':
          this.termMin.addValidators(Validators.required);
          this.termMax.addValidators([Validators.required, maxValidator]);
          break;
        case 'conditionTermMin':
          this.termMin.addValidators(Validators.required);
          this.termMax.removeValidators([Validators.required, maxValidator]);
          break;
        case 'conditionTermMax':
          this.termMin.removeValidators(Validators.required);
          this.termMax.addValidators([Validators.required, maxValidator]);
          break;
        case 'conditionTermMiss':
          this.termMin.removeValidators(Validators.required);
          this.termMax.removeValidators([Validators.required, maxValidator]);
          break;
      }
      this.termMax.updateValueAndValidity();
      this.termMin.updateValueAndValidity();
    });

    this.conditionSumMin.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.sumMin.addValidators(Validators.required);
      } else {
        this.sumMin.removeValidators(Validators.required);
      }
      this.sumMin.updateValueAndValidity();
    });
  }

  get conditionTermType() {
    return this.controls.conditionTerm;
  }

  get termMin() {
    return this.controls.termMin;
  }

  get termMax() {
    return this.controls.termMax;
  }

  get conditionSumMin() {
    return this.controls.conditionSumMin;
  }

  get sumMin() {
    return this.controls.sumMin;
  }

  minMaxValidator() {
    return this.termMax.value && this.termMin.value && this.termMin.value > this.termMax.value
      ? { minMax: true }
      : null;
  }
}
