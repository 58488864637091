import { createSelector } from '@ngrx/store';
import { getInfoDictionaryByType, infoDictionaryMapper } from '../../helpers/info-table.helper';
import {
  selectAssessmentTypesEntities,
  selectEmployeesLoginEntities,
  selectProposalsTypesListEntities,
  selectRegionsEntities,
} from '@sib/shared/store';
import {
  selectApplicationType,
  selectAssessmentType,
  selectInitiatorId,
  selectRegionCode,
  selectServiceUnitCode,
  selectUnitCode,
  selectVariables,
} from '../../+state/variables/variables.selectors';

export const selectMainInfoTable = createSelector(
  selectVariables,
  selectAssessmentTypesEntities,
  selectRegionsEntities,
  selectProposalsTypesListEntities,
  selectApplicationType,
  selectAssessmentType,
  selectRegionCode,
  (variables, assessmentTypes, regions, proposalTypesList, appType, assessmentType, regionCode) =>
    infoDictionaryMapper(getInfoDictionaryByType('MAIN_INFO'), {
      ...variables,
      assessmentType: assessmentTypes[assessmentType]?.title,
      regionCode: regions[regionCode]?.title,
      typeTask: proposalTypesList[appType]?.title,
    }),
);

export const selectInitiator = createSelector(
  selectInitiatorId,
  selectEmployeesLoginEntities,
  (initiatorId, employees) => employees[initiatorId],
);

export const selectOrgUnitId = createSelector(
  selectUnitCode,
  selectServiceUnitCode,
  (unitCode, serviceUnitCode) => serviceUnitCode || unitCode,
);
