/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DocumentGroupDto } from '../models/DocumentGroupDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DocumentGroupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all document groups
     * Returns all document groups.
     * @returns DocumentGroupDto all document groups
     * @throws ApiError
     */
    public getApiDocumentsDocGroups(): Observable<Array<DocumentGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-groups',
        });
    }

    /**
     * Create document group
     * Method for creating document group.
     * @returns DocumentGroupDto document group created
     * @throws ApiError
     */
    public postApiDocumentsDocGroups({
        requestBody,
    }: {
        /**
         * Document group object that needs to be created.
         */
        requestBody: DocumentGroupDto,
    }): Observable<DocumentGroupDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/documents/doc-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get document group
     * Get document group by code.
     * @returns any document group found
     * @throws ApiError
     */
    public getApiDocumentsDocGroupsGetByCode({
        code,
    }: {
        /**
         * Document group code. Cannot be empty.
         */
        code: string,
    }): Observable<{
        id: number;
        code?: string;
        description?: string;
        documentTypeIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-groups/get-by-code/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `document group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get document group
     * Get document group by ID.
     * @returns any document group found
     * @throws ApiError
     */
    public getApiDocumentsDocGroups1({
        id,
    }: {
        /**
         * Document group ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        code?: string;
        description?: string;
        documentTypeIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `document group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update document group
     * Updates the document group with the given ID.
     * @returns any document group updated
     * @throws ApiError
     */
    public putApiDocumentsDocGroups({
        id,
        requestBody,
    }: {
        /**
         * Document group ID. Cannot be empty.
         */
        id: number,
        /**
         * Document group object that needs to be updated.
         */
        requestBody: DocumentGroupDto,
    }): Observable<{
        id: number;
        code?: string;
        description?: string;
        documentTypeIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/documents/doc-groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete document group
     * Deletes the document group with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDocumentsDocGroups({
        id,
    }: {
        /**
         * Document group ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/documents/doc-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
