import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import * as Assessment from './decisions.actions';
import * as Selectors from './decisions.selectors';
import { DecisionService } from '@api/dictionaries';

@Injectable()
export class DecisionsEffects {
  assessmentKinds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Assessment.loadDecisions),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectDecisions)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.decisionService.getApiDecisions()).pipe(
          map((response) =>
            Assessment.loadDecisionsSuccess({
              decisions: response,
            }),
          ),
          catchError((error) => of(Assessment.loadDecisionsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private decisionService: DecisionService,
  ) {}
}
