import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OpenAPI } from './generated/core/OpenAPI';

// has to be removed in LEWF-1621
@Injectable({
  providedIn: 'root',
})
export class PrintedForm {
  constructor(private https: HttpClient) {}

  getPrintedFormsProcessInstanceBailContract({
    processInstanceId,
    bailContractId,
  }: {
    processInstanceId: string;
    bailContractId: string;
  }) {
    return this.https.get(
      OpenAPI.BASE + `/printed-forms/process-instance/${processInstanceId}/bail-contract/${bailContractId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getPrintedFormsProcessInstanceLoanContract({
    processInstanceId,
    agreementId,
  }: {
    processInstanceId: string;
    agreementId: string;
  }) {
    return this.https.get(
      OpenAPI.BASE + `/printed-forms/process-instance/${processInstanceId}/loan-contract/${agreementId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getPrintedFormsProcessInstanceDocumentaryOperationsContract({
    processInstanceId,
    agreementId,
  }: {
    processInstanceId: string;
    agreementId: string;
  }) {
    return this.https.get(
      OpenAPI.BASE + `/printed-forms/process-instance/${processInstanceId}/documentary-operations/${agreementId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getPrintedFormsProcessInstanceCheckList({ processInstanceId }: { processInstanceId: string }) {
    return this.https.get(OpenAPI.BASE + `/printed-forms/process-instance/${processInstanceId}/checkList`, {
      responseType: 'blob',
    });
  }

  getPrintedFormsProcessInstanceProtocolCc({ processInstanceId }: { processInstanceId: string }) {
    return this.https.get(OpenAPI.BASE + `/printed-forms/process-instance/${processInstanceId}/protocol-cc`, {
      responseType: 'blob',
    });
  }
}
