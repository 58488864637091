import { createReducer, on } from '@ngrx/store';
import * as TypeDocsOwnership from './type-docs-ownership.actions';
import { TypeDocOwnershipDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const typeDocsOwnershipFeatureKey = 'type-docs-ownership';

export interface State extends EntityState<TypeDocOwnershipDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<TypeDocOwnershipDto>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(TypeDocsOwnership.loadTypeDocsOwnership, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TypeDocsOwnership.loadTypeDocsOwnershipSuccess, (state, { typeDocsOwnership }) => ({
    ...adapter.setAll(typeDocsOwnership, state),
    isLoading: false,
  })),
  on(TypeDocsOwnership.loadTypeDocsOwnershipFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectTypeDocsOwnershipState = selectAll;
