import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadTypeDocUseLands, selectTypeDocUseLands } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class TypeDocUseLandsFacadeService {
  public typeDocUseLands$ = this.store.select(selectTypeDocUseLands);
  constructor(private store: Store) {}

  public loadTypeDocUseLands() {
    this.store.dispatch(loadTypeDocUseLands());
  }
}
