import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeesActions from './employees.actions';
import * as EmployeesSelectors from './employees.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class EmployeesEffects {
  loadEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeesActions.loadEmployees),
      concatLatestFrom((action) => [this.store$.select(EmployeesSelectors.selectAllEmployees)]),
      switchMap(([action, allEmployees]) =>
        (allEmployees.length ? of(allEmployees) : this.dictionariesService.loadEmployees()).pipe(
          map((response) =>
            EmployeesActions.loadEmployeesSuccess({
              employees: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(EmployeesActions.loadEmployeesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
