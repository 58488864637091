import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LawCaseTypesActions from './law-case-types.actions';
import * as LawCaseTypesSelectors from './law-case-types.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LawCaseTypesEffects {
  loadLawCaseTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LawCaseTypesActions.loadLawCaseTypes),
      concatLatestFrom((action) => [this.store$.select(LawCaseTypesSelectors.selectAllLawCaseTypes)]),
      switchMap(([action, allLawCaseTypes]) =>
        (allLawCaseTypes.length ? of(allLawCaseTypes) : this.dictionariesService.loadLawCaseTypes()).pipe(
          map((response) =>
            LawCaseTypesActions.loadLawCaseTypesSuccess({
              lawCaseTypes: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(LawCaseTypesActions.loadLawCaseTypesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
