/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssessmentTypeDto } from '../models/AssessmentTypeDto';
import type { CurrencyDto } from '../models/CurrencyDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CurrencyService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all currencies
     * Returns all currencies.
     * @returns CurrencyDto all currencies
     * @throws ApiError
     */
    public getApiCurrencies(): Observable<Array<CurrencyDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/currencies',
        });
    }

    /**
     * Create currency
     * Method for creating currency.
     * @returns CurrencyDto currency created
     * @throws ApiError
     */
    public postApiCurrencies({
        requestBody,
    }: {
        /**
         * Currency object that needs to be created.
         */
        requestBody: CurrencyDto,
    }): Observable<CurrencyDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/currencies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get currency
     * Get currency by code.
     * @returns any currency found
     * @throws ApiError
     */
    public getApiCurrencies1({
        code,
    }: {
        /**
         * Currency code. Cannot be empty.
         */
        code: string,
    }): Observable<{
        code: string;
        expireDate?: string;
        definition?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/currencies/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `currency not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete currency
     * Deletes the currency with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiCurrencies({
        code,
    }: {
        /**
         * Currency code. Cannot be empty.
         */
        code: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/currencies/{code}',
            path: {
                'code': code,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Update currency
     * Updates the currency with the given code.
     * @returns any currency updated
     * @throws ApiError
     */
    public putApiCurrencies({
        id,
        requestBody,
    }: {
        /**
         * Currency code. Cannot be empty.
         */
        id: string,
        /**
         * Currency object that needs to be updated.
         */
        requestBody: AssessmentTypeDto,
    }): Observable<{
        code: string;
        expireDate?: string;
        definition?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/currencies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

}
