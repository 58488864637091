import { createSelector } from '@ngrx/store';
import * as fromProtocolConditionTypes from './protocol-condition-types.reducer';
import { selectProtocolConditionTypesState } from '../+shell/reducers.index';

export const selectProtocolConditionTypesIsLoading = createSelector(
  selectProtocolConditionTypesState,
  fromProtocolConditionTypes.selectIsLoading,
);

export const selectProtocolConditionTypesEntities = createSelector(
  selectProtocolConditionTypesState,
  fromProtocolConditionTypes.selectEntities,
);

export const selectProtocolConditionTypeById = (protocolConditionType: string) =>
  createSelector(
    selectProtocolConditionTypesEntities,
    (protocolConditionTypesEntities) => protocolConditionTypesEntities[protocolConditionType],
  );

export const selectAllProtocolConditionTypes = createSelector(
  selectProtocolConditionTypesState,
  fromProtocolConditionTypes.selectAll,
);
