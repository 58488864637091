import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectControllersByAgreementId } from './controllers.selectors';

@Injectable({
  providedIn: 'root',
})
export class ControllersFacadeServices {
  private store = inject(Store);

  getControllersByAgreementId$ = (agreementId: string) =>
    this.store.select(selectControllersByAgreementId(agreementId));
}
