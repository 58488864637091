import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadMessageType, selectAllMessageType } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class MessageTypeFacadeService {
  public messageTypes$ = this.store.select(selectAllMessageType);

  constructor(private store: Store) {}

  public loadMessageTypes() {
    this.store.dispatch(loadMessageType());
  }
}
