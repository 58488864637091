import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromShared from '@sib/shared/store';
import { Store } from '@ngrx/store';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { CustomCurrencyPipe, markForCheck } from '@sib/shared/util';
import { combineLatest, filter, forkJoin, Observable, startWith, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CurrencyRatesService } from '@sib/shared/feature';
import { ApplicationInfoDto, ClientCredit, DocumentEntry } from '@api/loan-approval';

export interface GeneralInformationTable extends ClientCredit {
  agreementSum?: string;
  currencyCode?: string;
}

interface Total {
  currencyCode: string;
  sum: string;
  sumLim?: string;
  sumEquivalent: string;
  sumEquivalentUAH: string;
}

@Component({
  selector: 'sib-general-information-table',
  standalone: true,
  templateUrl: './general-information-table.component.html',
  styleUrls: ['./general-information-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatTableModule, MatSelectModule, CustomCurrencyPipe, ReactiveFormsModule],
})
export class GeneralInformationTableComponent implements OnInit {
  @Input() dataTable!: Observable<any>;
  @Input() columnName!: { currencies: string; sum: string; sumEquivalent: string };

  private readonly destroyRef = inject(DestroyRef);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly currencyRatesService = inject(CurrencyRatesService);
  private readonly store = inject(Store);

  public currencies$ = this.store.select(fromShared.selectAllCurrencies);

  public dataSource = new MatTableDataSource<Total>();
  public displayedColumns = ['currencyCode', 'sum', 'sumEquivalent'];
  public todayDate = new Date();
  public currencyCodeControl = new FormControl('EUR', [Validators.required]);
  public totals: any = [];
  public rate: {
    rateDate?: string;
    currCode?: string;
    currRate?: number;
  }[] = [];

  ngOnInit() {
    this.currencies$
      .pipe(
        filter((v) => !!v.length),
        switchMap((currenciesDictionaries) =>
          forkJoin(
            currenciesDictionaries
              .filter((item) => item.code !== 'UAH')
              .map((item) => this.currencyRatesService.loadLoanApprovalCurrencyRates(item.code, '')),
          ),
        ),
        tap((result) => (this.rate = result)),
        switchMap(() =>
          combineLatest([this.currencyCodeControl.valueChanges.pipe(startWith('EUR')), this.dataTable]).pipe(
            markForCheck(this.cdr),
            takeUntilDestroyed(this.destroyRef),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([currCode, dataTable]) => {
        this.getTotals(dataTable);
      });
  }

  getTotals(dataTable: GeneralInformationTable[]) {
    this.totals = [];
    dataTable.forEach((item: GeneralInformationTable, index: number) => {
      const findUniqueCurrencyIndex = this.totals.findIndex(
        (v: any) => v.currencyCode === item.currCode || v.currencyCode === item.currencyCode,
      );

      if (findUniqueCurrencyIndex === -1 && (item.currCode || item.currencyCode)) {
        const newItem = {
          currencyCode: item.currCode || item.currencyCode,
          sum: item.sum || Number(item.agreementSum),
          sumLim: item.sumLim,
          sumEquivalent: this.getConverted(item, findUniqueCurrencyIndex, item.sum ? 'sum' : 'agreementSum'),
        };
        this.totals.push(newItem);
        this.dataSource.data = this.totals;
      } else {
        if (item.currCode || item.currencyCode) {
          const sum =
            parseFloat(this.totals[findUniqueCurrencyIndex].sum) +
            parseFloat(dataTable[index].agreementSum ? dataTable[index].agreementSum! : dataTable[index].sum!);

          this.totals[findUniqueCurrencyIndex].sumLim =
            parseFloat(this.totals[findUniqueCurrencyIndex].sumLim) + parseFloat(dataTable[index].sumLim!);
          this.totals[findUniqueCurrencyIndex].sum = sum;
          this.totals[findUniqueCurrencyIndex].sumEquivalent = this.getConverted(
            this.totals[findUniqueCurrencyIndex],
            findUniqueCurrencyIndex,
            'sum',
          );
        }
        this.dataSource.data = this.totals;
      }
    });
  }

  getConverted(item: GeneralInformationTable, currentIndex: number, type: string) {
    let currentCode,
      selectedCurrencyRate = 1,
      currentCurrencyRate = 1;
    const selectedCurrency = this.rate.find((rate) => rate.currCode === this.currencyCodeControl.value);
    const currentCurrency = this.rate.find(
      (rate) => rate.currCode === item.currCode || rate.currCode === item.currencyCode,
    );

    if (currentIndex !== -1) {
      currentCode = this.totals[currentIndex].currencyCode || this.totals[currentIndex].currCode;
    } else {
      currentCode = item.currencyCode || item.currCode;
    }

    if (selectedCurrency) {
      selectedCurrencyRate = selectedCurrency.currRate!;
    }

    if (currentCurrency) {
      currentCurrencyRate = currentCurrency.currRate!;
    }

    if (currentCode === this.currencyCodeControl.value) {
      if (type === 'agreementSum' || type === 'sum') {
        return item.agreementSum || item.sum;
      } else {
        return item.sumLim || item.agreementSum;
      }
    } else {
      if (!currentCurrencyRate) {
        if (type === 'agreementSum' || type === 'sum') {
          return ((Number(item.agreementSum!) || Number(item.sum!)) * currentCurrencyRate).toFixed(2);
        } else {
          return ((Number(item.agreementSum!) || Number(item.sumLim!)) * currentCurrencyRate).toFixed(2);
        }
      } else {
        if (type === 'agreementSum' || type === 'sum') {
          return (
            ((Number(item.agreementSum!) || Number(item.sum!)) * currentCurrencyRate) /
            selectedCurrencyRate
          ).toFixed(2);
        } else {
          return (
            ((Number(item.agreementSum!) || Number(item.sum!)) * currentCurrencyRate) /
            selectedCurrencyRate
          ).toFixed(2);
        }
      }
    }
  }

  sumTotal() {
    return this.totals.reduce((sum: number, item: any) => sum + Number(item.sumEquivalent), 0).toFixed(2);
  }
}
