import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { Agreement, CashFlowZKR } from '@api/loan-approval';
import { cashFlowZkrValidator } from '../../value-accessors';

@UntilDestroy()
@Injectable()
export class FeeForLoanGroupService extends FormGroup<ReturnType<typeof FeeForLoanGroupService.formGroup>> {
  private static readonly formGroup = () => ({
    condSalary: new FormControl<boolean>({ value: false, disabled: true }, { nonNullable: true }),
    otherDate: new FormControl<Date | undefined>(
      { value: undefined, disabled: true },
      { validators: Validators.required, nonNullable: true },
    ),
    otherRate: new FormControl<number>(
      { value: 0, disabled: true },
      { validators: [Validators.required, Validators.min(0)], nonNullable: true },
    ),
    condFlowRate: new FormControl<number>(0, { validators: [Validators.min(0)], nonNullable: true }),
    condSalaryRate: new FormControl<number>(0, { validators: [Validators.min(0)], nonNullable: true }),
    monthSalarySum: new FormControl<number>(
      { value: 0, disabled: true },
      {
        validators: [Validators.required, Validators.min(0.01)],
        nonNullable: true,
      },
    ),
    condFlow: new FormControl<boolean>({ value: false, disabled: true }, { nonNullable: true }),
    rateType: new FormControl<Agreement['rateType']>('singleSizeRate', { nonNullable: true }),
    salaryType: new FormControl<Agreement['salaryType']>('monthSalary', {
      validators: Validators.required,
      nonNullable: true,
    }),
    cashFlowZKRS: new FormControl<CashFlowZKR[]>([], {
      validators: [cashFlowZkrValidator('FLOW_UAH')],
      nonNullable: true,
    }),
    payRules: new FormControl<boolean>(true, { nonNullable: true }),
    repaymentPerDay: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required, Validators.min(1), Validators.max(31)],
      nonNullable: true,
    }),
    isPenaltyUPS: new FormControl<boolean>(false, { nonNullable: true }),
  });

  get salaryType() {
    return this.controls.salaryType;
  }

  get cashFlowZKRS() {
    return this.controls.cashFlowZKRS;
  }

  get rateType() {
    return this.controls.rateType;
  }

  get otherRate() {
    return this.controls.otherRate;
  }

  get otherDate() {
    return this.controls.otherDate;
  }

  get condSalary() {
    return this.controls.condSalary;
  }

  get condSalaryRate() {
    return this.controls.condSalaryRate;
  }

  get condFlow() {
    return this.controls.condFlow;
  }

  get condFlowRate() {
    return this.controls.condFlowRate;
  }

  get payRules() {
    return this.controls.payRules;
  }

  get repaymentPerDay() {
    return this.controls.repaymentPerDay;
  }

  get monthSalarySum() {
    return this.controls.monthSalarySum;
  }

  get isPenaltyUPS() {
    return this.controls.isPenaltyUPS;
  }

  constructor() {
    super(FeeForLoanGroupService.formGroup());
    this.rateType.valueChanges.pipe(untilDestroyed(this)).subscribe((rateType) => {
      if (this.rateType.status === 'DISABLED') return;
      switch (rateType) {
        case 'singleSizeRate':
          this.cashFlowZKRS.disable();
          this.otherRate.disable();
          this.otherDate.disable();
          this.condFlowRate.removeValidators([Validators.required]);
          this.condSalaryRate.removeValidators([Validators.required]);
          this.condSalary.disable();
          this.condFlow.disable();
          this.salaryType.disable();
          this.monthSalarySum.disable();
          break;
        case 'otherSizeRate':
          this.cashFlowZKRS.disable();
          this.otherRate.enable();
          this.otherDate.enable();
          this.condFlowRate.removeValidators([Validators.required]);
          this.condSalaryRate.removeValidators([Validators.required]);
          this.condSalary.disable();
          this.condFlow.disable();
          this.salaryType.disable();
          this.monthSalarySum.disable();
          break;
        case 'increSizeRate1':
          this.cashFlowZKRS.enable();
          this.otherRate.disable();
          this.otherDate.disable();
          this.condFlowRate.addValidators([Validators.required]);
          this.condSalaryRate.addValidators([Validators.required]);
          this.condSalary.enable();
          this.condFlow.enable();
          this.salaryType.enable();
          this.monthSalarySum.enable();
          if (this.condSalary.value && this.condFlow.value) {
            this.condFlow.addValidators([Validators.requiredTrue]);
            this.condSalary.addValidators([Validators.requiredTrue]);
          }

          if (!this.condSalary.value) {
            this.salaryType.disable();
          }
          break;
        case 'increSizeRate2':
          break;
      }
      this.condFlowRate.updateValueAndValidity();
    });

    this.salaryType.valueChanges.pipe(untilDestroyed(this)).subscribe((salaryType) => {
      if (this.salaryType.status === 'DISABLED') return;
      switch (salaryType) {
        case 'monthSalary':
          this.cashFlowZKRS.disable();
          this.monthSalarySum.enable();
          break;
        case 'quarterSalary':
          this.cashFlowZKRS.enable();
          this.monthSalarySum.disable();
          break;
      }
    });

    this.payRules.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.repaymentPerDay.removeValidators(Validators.required);
      } else {
        this.repaymentPerDay.addValidators(Validators.required);
      }
      this.repaymentPerDay.updateValueAndValidity();
    });

    this.condFlow.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.condFlow.status === 'DISABLED') return;
      if (value) {
        this.condFlowRate.enable();
        if (this.condSalary.hasValidator(Validators.requiredTrue)) {
          this.condSalary.removeValidators([Validators.requiredTrue]);
          this.condSalary.updateValueAndValidity();
        }
      } else {
        this.condFlowRate.disable();
        if (!this.condSalary.hasValidator(Validators.requiredTrue)) {
          this.condSalary.addValidators([Validators.requiredTrue]);
          this.condSalary.updateValueAndValidity();
        }
      }
    });

    this.condSalary.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.condSalary.status === 'DISABLED') return;
      if (value) {
        this.condSalaryRate.enable();
        this.salaryType.enable();
        if (this.condFlow.hasValidator(Validators.requiredTrue)) {
          this.condFlow.removeValidators([Validators.requiredTrue]);
          this.condFlow.updateValueAndValidity();
        }
      } else {
        this.condSalaryRate.disable();
        this.salaryType.disable();

        if (!this.condFlow.hasValidator(Validators.requiredTrue)) {
          this.condFlow.addValidators([Validators.requiredTrue]);
          this.condFlow.updateValueAndValidity();
        }
      }
    });
  }
}
