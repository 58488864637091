import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';
import { NgFor } from '@angular/common';

@Component({
  selector: 'sib-documents-akd-assets-dialog',
  templateUrl: './documents-akd-assets-dialog.component.html',
  styleUrls: ['./documents-akd-assets-dialog.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgFor],
})
export class DocumentsAkdAssetsDialogComponent implements OnInit {
  public providingList = this.dialogRef?.data.providingList;
  public providingFrom = new FormGroup({
    id: new FormControl(null, [Validators.required]),
  });

  get f() {
    return this.providingFrom.controls;
  }

  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {
    if (this.dialogRef?.data.bindingCode) {
      this.f.id.patchValue(this.dialogRef?.data.bindingCode);
    }
  }

  onSubmitDialog() {
    if (this.providingFrom.valid) {
      this.dialogRef.close({ bindingCode: this.f.id.value });
    }
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
}
