import { createReducer, on } from '@ngrx/store';
import * as AssessmentApproaches from './assessment-approaches.actions';
import { AssessmentApproachDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const assessmentApproachesFeatureKey = 'assessment-approaches';

export interface State extends EntityState<AssessmentApproachDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<AssessmentApproachDto>({
  selectId: (item: AssessmentApproachDto) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(AssessmentApproaches.loadAssessmentApproaches, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AssessmentApproaches.loadAssessmentApproachesSuccess, (state, { assessmentApproaches }) => ({
    ...adapter.setAll(assessmentApproaches, state),
    isLoading: false,
  })),
  on(AssessmentApproaches.loadAssessmentApproachesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
