import { createAction, props } from '@ngrx/store';
import { AssessmentKindDto } from '@api/dictionaries';

export const loadAssessmentKinds = createAction('[Dictionaries/API] Load Assessment Kinds');
export const loadAssessmentKindsSuccess = createAction(
  '[Dictionaries/API] Load Assessment Kinds Success',
  props<{ assessmentKinds: AssessmentKindDto[] }>(),
);
export const loadAssessmentKindsFailure = createAction(
  '[Dictionaries/API] Load Assessment Kinds Failure',
  props<{ error: unknown }>(),
);
