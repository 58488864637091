import { createAction, props } from '@ngrx/store';
import { EquipmentTypeDto } from '@api/dictionaries';

export const loadEquipmentTypes = createAction('[Dictionaries/API] Load Equipment Types');
export const loadEquipmentTypesSuccess = createAction(
  '[Dictionaries/API] Load Equipment Types Success',
  props<{ equipmentTypes: EquipmentTypeDto[] }>(),
);
export const loadEquipmentTypesFailure = createAction(
  '[Dictionaries/API] Load Equipment Types Failure',
  props<{ error: unknown }>(),
);
