import { createAction, props } from '@ngrx/store';
import { DictionaryGroupDTO } from '@api/dictionaries';

export const loadDictionaryGroups = createAction('[Dictionaries/API] Load Dictionary Groups');

export const loadDictionaryGroupsSuccess = createAction(
  '[Dictionaries/API] Load Dictionary Groups Success',
  props<{ dictionaryGroups: DictionaryGroupDTO[] }>(),
);

export const loadDictionaryGroupsFailure = createAction(
  '[Dictionaries/API] Load Dictionary Groups Failure',
  props<{ error: unknown }>(),
);
