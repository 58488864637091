import { createAction, props } from '@ngrx/store';
import { BpRoleCourtCasesDto } from '@api/dictionaries';

export const loadBpRoleCourtCases = createAction('[Dictionaries/API] Load Bp Role Court Cases');
export const loadBpRoleCourtCasesSuccess = createAction(
  '[Dictionaries/API] Load Bp Role Court Cases Success',
  props<{ bpRoleCourtCases: BpRoleCourtCasesDto[] }>(),
);
export const loadBpRoleCourtCasesFailure = createAction(
  '[Dictionaries/API] Load Bp Role Court Cases Failure',
  props<{ error: unknown }>(),
);
