import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService, JwtService } from '@sib/shared/da';
import { TasksService } from '@app/core/services/tasks.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromShared from '../../../../libs/shared/store/src/lib/shared-state';
import { MatButtonModule } from '@angular/material/button';
import { LogoComponent, StickyBarDirective } from '@sib/shared/ui';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { iconCogOutline, iconHelp, MatIconsRegistry } from '@sib/shared/ui-icons';
import { EmployeeDto } from '@api/loan-org-structure';
import { environment } from '@sib/shared/util';

@Component({
  selector: 'sib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  hostDirectives: [StickyBarDirective],
  imports: [MatButtonModule, LogoComponent, RouterModule, CommonModule, MatIconModule, StickyBarDirective],
})
export class HeaderComponent implements OnInit {
  public employee$: Observable<EmployeeDto> | null = null;
  public env = environment;

  @Output() logout = new EventEmitter<void>();

  constructor(
    private router: Router,
    public auth: AuthService,
    private tasksServ: TasksService,
    private store: Store,
    private matIconsRegistry: MatIconsRegistry,
    private jwtService: JwtService,
  ) {
    this.matIconsRegistry.add(iconHelp, iconCogOutline);
  }

  async ngOnInit() {
    const login = this.jwtService.getUserLogin();
    if (login) {
      this.employee$ = this.store.select(fromShared.selectEmployeeByLogin(login));
    }
  }

  onLogout(): void {
    this.jwtService.clearToken();
    this.tasksServ.resetTasks();
    this.logout.emit();
    this.router.navigate(['/login']);
  }
}
