import { createSelector } from '@ngrx/store';
import * as fromProduct from './product.reducer';
import { selectProductListState } from '../+shell/reducers.index';

export const selectProductListIsLoading = createSelector(selectProductListState, fromProduct.selectIsLoading);

export const selectProductListEntities = createSelector(selectProductListState, fromProduct.selectEntities);

export const selectProductById = (id: number) =>
  createSelector(selectProductListEntities, (productListEntities) => productListEntities[id]);

export const selectLoanClassId = (id: number | null) =>
  createSelector(selectProductListEntities, (productListEntities) => productListEntities[id!]?.loanClassId);

export const selectAllProductList = createSelector(selectProductListState, fromProduct.selectAll);
export const selectIsAllProductListLoading = createSelector(selectProductListState, (state) => state.isLoading);
