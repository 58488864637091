import { createAction, props } from '@ngrx/store';
import { TypeDocUseLandDto } from '@api/dictionaries';

export const loadTypeDocUseLands = createAction('[Dictionaries/API] Load Type Doc Use Lands');
export const loadTypeDocUseLandsSuccess = createAction(
  '[Dictionaries/API] Load Type Doc Use Lands Success',
  props<{ typeDocUseLands: TypeDocUseLandDto[] }>(),
);
export const loadTypeDocUseLandsFailure = createAction(
  '[Dictionaries/API] Load Type Doc Use Lands Failure',
  props<{ error: unknown }>(),
);
