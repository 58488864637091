import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ErrorType, TaskChecksErrorEntity } from '@api/loan-approval';
import * as fromRoot from '@app/core/store';
import { DefaultService } from '@api/camunda';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Merge } from 'ts-toolbelt/out/Object/Merge';

@Component({
  selector: 'sib-task-controls',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './task-controls-dialog.component.html',
  styleUrls: ['./task-controls-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskControlsDialogComponent {
  private dialogRef: MatDialogRef<TaskControlsDialogComponent, boolean> = inject(MatDialogRef);
  private camundaService = inject(DefaultService);
  private router = inject(Router);
  private store = inject(Store);

  public actionsComplete = {
    CERTIFICATE_ERROR: 'Повернути в чергу',
    CHECK_LIST_ERROR: 'Перейти до CheckList',
    DOCUMENT_ERROR: 'Додати документи',
    CHECK_AGREEMENT_GUARANTY_ERROR: "Перейти до об'єктів забезпечення",
    CHECK_BAIL_CONTRACTS_ERROR: 'Перейти до поручителів',
    AGREEMENT_PARAMS_ERROR: 'Перейти до параметрів кредитного договору',
    CHECK_AGREEMENT_GUARANTY_PARAMS_ERROR: 'Перейти до параметрів договору застави',
    CHECK_BAIL_CONTRACTS_PARAMS_ERROR: 'Перейти до параметрів договору порук',
    CHECK_DEPOSIT_PARAMS_ERROR: "Перейти до параметрів об'єкту забезпечення",
  };

  public actionsAssign: Record<string, string> = {};

  @Inject(MAT_DIALOG_DATA) public data: {
    taskEvent: 'ASSIGN' | 'COMPLETE';
    taskId: string;
    requestNumber: string;
    errors: Merge<TaskChecksErrorEntity, { extraParam?: string }>[];
    processDefinitionKey?: 'subprocess_property_assessment';
  } = inject(MAT_DIALOG_DATA);

  complete(
    errorType:
      | ErrorType
      | 'CHECK_AGREEMENT_GUARANTY_ERROR'
      | 'CHECK_BAIL_CONTRACTS_ERROR'
      | 'CHECK_BAIL_CONTRACTS_PARAMS_ERROR'
      | 'CHECK_DEPOSIT_PARAMS_ERROR'
      | 'CHECK_AGREEMENT_GUARANTY_PARAMS_ERROR',
    id?: string,
  ) {
    switch (errorType) {
      case 'CERTIFICATE_ERROR': {
        this.camundaService.postTaskUnclaim({ id: this.data.taskId }).subscribe(() => {
          this.store.dispatch(fromRoot.loadTasksCount());
          this.router.navigate([this.taskCoreRoute, 'new']);
          this.dialogRef.close();
        });
        break;
      }

      case 'CHECK_LIST_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'check-list']);
        this.dialogRef.close();
        break;
      }
      case 'DOCUMENT_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'documents']);
        this.dialogRef.close();
        break;
      }
      case 'CHECK_AGREEMENT_GUARANTY_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'providing/assets/main']);
        this.dialogRef.close();
        break;
      }
      case 'CHECK_BAIL_CONTRACTS_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'guarantors/guarantor-tab/main']);
        this.dialogRef.close();
        break;
      }
      case 'AGREEMENT_PARAMS_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'terms-agreement', id, 'contract']);
        this.dialogRef.close();
        break;
      }
      case 'CHECK_AGREEMENT_GUARANTY_PARAMS_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'providing/deals', id]);
        this.dialogRef.close();
        break;
      }
      case 'CHECK_BAIL_CONTRACTS_PARAMS_ERROR': {
        this.router.navigate([this.taskCoreRoute, 'details', this.data.taskId, 'guarantors/deals', id]);
        this.dialogRef.close();
        break;
      }
      case 'CHECK_DEPOSIT_PARAMS_ERROR': {
        this.router.navigate([
          this.taskCoreRoute,
          'details',
          this.data.taskId,
          'providing/assets/main',
          id,
          'assetParams',
        ]);
        this.dialogRef.close();
        break;
      }
    }
  }

  assign(errorType: ErrorType) {
    // task control on assign
  }

  private get taskCoreRoute() {
    return this.isAssessment ? 'tasks-assessment' : 'tasks';
  }

  private get isAssessment() {
    return this.data.processDefinitionKey === 'subprocess_property_assessment';
  }
}
