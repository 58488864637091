import { createAction, props } from '@ngrx/store';
import { MonitoringResultDto } from '@api/dictionaries';

export const loadMonitoringResults = createAction('[Dictionaries/API] Load Monitoring Results');
export const loadMonitoringResultsSuccess = createAction(
  '[Dictionaries/API] Load Monitoring Results Success',
  props<{ monitoringResults: MonitoringResultDto[] }>(),
);
export const loadMonitoringResultsFailure = createAction(
  '[Dictionaries/API] Load Monitoring Results Failure',
  props<{ error: unknown }>(),
);
