import { MemoizedSelector } from '@ngrx/store';

export type FromObjectWithArraysToObjectWithRecord<T> = {
  [K in keyof T]: T[K] extends Array<infer U> | null | undefined ? Record<string, U> | null : T[K];
};

// eslint-disable-next-line
export type SelectorReturnType<T> = T extends (...P: any[]) => MemoizedSelector<infer R, infer U, infer K>
  ? U
  : T extends MemoizedSelector<infer R, infer U, infer K>
  ? U
  : never;
