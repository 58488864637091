import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Agreement } from '@api/loan-approval';

@Injectable()
export class LoanRepaymentProcedureGroupService extends FormGroup<
  ReturnType<typeof LoanRepaymentProcedureGroupService.form>
> {
  static readonly form = () => ({
    returnType: new FormControl<Agreement['returnType'] | undefined>(undefined, { nonNullable: true }),
    dealDateEnd: new FormControl<Date | undefined>({ value: undefined, disabled: true }, { nonNullable: true }),
    repaymentAmount: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    repaymentDay: new FormControl<string | undefined>(undefined, {
      validators: [Validators.min(1), Validators.max(31), Validators.required],
      nonNullable: true,
    }),
  });

  constructor() {
    super(LoanRepaymentProcedureGroupService.form());
  }

  get repaymentDay() {
    return this.controls.repaymentDay;
  }
}
