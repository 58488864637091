import {
  differenceInYears,
  endOfWeek,
  formatISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
  toDate,
} from 'date-fns';
import { format, parse } from 'date-fns/fp';
import { exhaustiveCheck } from '../utils';

export const FULL_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const UTC_FULL_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ss`;
export const UTC_SHORT = 'yyyy-MM-dd';
export const SAP_DATE_FORMAT = 'yyyyMMdd';

export const startOfWeekLocale = (date: number | Date) => startOfWeek(date, { weekStartsOn: 1 });
export const endOfWeekLocale = (date: number | Date) => endOfWeek(date, { weekStartsOn: 1 });

export const fullDateFormat = format(FULL_DATE_FORMAT);
export const formatDateUTCShort = format(UTC_SHORT);
export const formatDateUTC = format(UTC_FULL_DATE_FORMAT);
export const formatDateWithPoint = format('dd.MM.yyyy');
export const formatDateWithHyphen = format('dd-MM-yyyy');

// format Tue Apr 18 2023 13:44:44 GMT+0300 instead of moment().format()
export const formatDateISO = (date: Date) => formatISO(date, { format: 'extended', representation: 'complete' });

// format 2023-04-18T13:44:44+03:00 instead of moment().toDate()
export const formatToDate = (date: Date) => toDate(date);

export const parseDate = (date: string, format = FULL_DATE_FORMAT) => parse(new Date(), format)(date);
export const parseUTCDate = parse(new Date(), UTC_SHORT);

export const yearsDifference = (endDate: Date, startDate: Date) => differenceInYears(endDate, startDate);

export type Period =
  | 'currentWeek'
  | 'currentYear'
  | 'currentMonth'
  | 'currentQuarter'
  | 'lastWeek'
  | 'lastYear'
  | 'lastMonth'
  | 'lastQuarter'
  | 'lastHalfYear'
  | 'nothing';

export const getRangeByPeriod = (period: Period, date = new Date()) => {
  switch (period) {
    case 'currentWeek':
      return { from: startOfWeekLocale(date), to: date };
    case 'currentMonth':
      return { from: startOfMonth(date), to: date };
    case 'currentYear':
      return { from: startOfYear(date), to: date };
    case 'currentQuarter':
      return { from: startOfQuarter(date), to: date };
    case 'lastWeek':
      return { from: subWeeks(date, 1), to: date };
    case 'lastMonth':
      return { from: subMonths(date, 1), to: date };
    case 'lastYear':
      return { from: subYears(date, 1), to: date };
    case 'lastQuarter':
      return { from: subQuarters(date, 1), to: date };
    case 'lastHalfYear':
      return { from: subMonths(date, 6), to: date };
    case 'nothing':
      return { from: new Date(), to: new Date() };
    default:
      exhaustiveCheck(period);
  }
};
