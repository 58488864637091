import { createSelector } from '@ngrx/store';
import * as fromProductCategoryTypes from './product-category-types.reducers';
import { selectProductCategoryTypesState } from '../+shell/reducers.index';
import { selectProductCategoryById } from '../index';
import { Dictionary } from '@ngrx/entity/src/models';
import { CategoryTypeDto, ProductCategoryDto } from '@api/dictionaries';

export const selectProductCategoryTypesLoading = createSelector(
  selectProductCategoryTypesState,
  fromProductCategoryTypes.selectIsLoading,
);

export const selectProductCategoryTypesEntities = createSelector(
  selectProductCategoryTypesState,
  fromProductCategoryTypes.selectEntities,
);

export const selectAllProductCategoryTypes = createSelector(
  selectProductCategoryTypesState,
  fromProductCategoryTypes.selectAll,
);

export const selectCategoryTypeByProductId = (productId: number) =>
  createSelector(
    selectProductCategoryTypesEntities,
    selectProductCategoryById(productId),
    (categoryType: Dictionary<CategoryTypeDto>, productCategory: ProductCategoryDto | undefined) =>
      productCategory ? categoryType[productCategory?.categoryType]?.code : '',
  );
