import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Asset } from '@api/loan-approval';

const assetFeatureKey = 'asset';

export type AssetState = EntityState<Asset>;

export const adapter = createEntityAdapter<Asset>();

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.asset || [], state)),
);

export const assetFeature = createFeature({
  name: assetFeatureKey,
  reducer,
  extraSelectors: ({ selectAssetState }) => ({
    ...adapter.getSelectors(selectAssetState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = assetFeature;
