import { Agreement } from '@api/loan-approval';

export const PRINTING_CONTRACT_LIST = [
  {
    id: 'singleSizeRate',
    name: 'Встановлення єдиного розміру %-ої ставки',
    percAddCond: 1,
  },
  {
    id: 'otherSizeRate',
    name: 'Відмінний від основного розміру %-ї ставки',
    percAddCond: 2,
  },
  {
    id: 'increSizeRate1',
    name: 'Підвищений розмір %-ї ставки за не переведення потоків + ЗКП',
    percAddCond: 3,
  },
  {
    id: 'increSizeRate2',
    name: 'Підвищений розмір %-ї ставки за не переведення потоків + прострочка',
    percAddCond: 4,
  },
  {
    id: 'rateTranche',
    name: 'Встановлення %-ї ставки в угоді про надання траншів',
    percAddCond: 5,
  },
];

export const PRINTING_CONTRACT = new Map<Agreement['rateType'], number>([
  ['singleSizeRate', 1],
  ['otherSizeRate', 2],
  ['increSizeRate1', 3],
  ['increSizeRate2', 4],
]);
