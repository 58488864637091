import { createSelector } from '@ngrx/store';
import { taskFeatureSelector } from '../task/task.reducer';
import { variablesFeature } from './variables.reducer';

export const selectVariables = createSelector(taskFeatureSelector, variablesFeature.selectVariablesState);
export const selectApplicationType = createSelector(taskFeatureSelector, variablesFeature.selectApplicationType);
export const selectAssessmentType = createSelector(taskFeatureSelector, variablesFeature.selectAssessmentType);
export const selectRegionCode = createSelector(taskFeatureSelector, variablesFeature.selectRegionCode);
export const selectBpCode = createSelector(taskFeatureSelector, variablesFeature.selectBpCode);
export const selectUnitCode = createSelector(taskFeatureSelector, variablesFeature.selectUnitCode);
export const selectServiceUnitCode = createSelector(taskFeatureSelector, variablesFeature.selectServiceUnitCode);
export const selectApplicationGroupName = createSelector(
  taskFeatureSelector,
  variablesFeature.selectApplicationGroupName,
);
export const selectApplicationStartDate = createSelector(
  taskFeatureSelector,
  variablesFeature.selectApplicationStartDate,
);
export const selectRequestNumber = createSelector(taskFeatureSelector, variablesFeature.selectRequestNumber);
export const selectEstimateNumber = createSelector(taskFeatureSelector, variablesFeature.selectEstimateNumber);
export const selectProcessParticipant = createSelector(taskFeatureSelector, variablesFeature.selectProcessParticipants);
export const selectGuarantorAgreementRelation = createSelector(taskFeatureSelector, (state) =>
  variablesFeature.selectGuarantorAgreementRelation(state),
);
export const selectAssetAgreementRelation = createSelector(taskFeatureSelector, (state) =>
  variablesFeature.selectAssetAgreementRelation(state),
);
export const selectTabsAccessRestriction = createSelector(
  taskFeatureSelector,
  variablesFeature.selectTabsAccessRestriction,
);
export const selectResolvableErrors = createSelector(taskFeatureSelector, variablesFeature.selectResolvableErrors);
export const selectVariablesInRootProcess = createSelector(
  taskFeatureSelector,
  variablesFeature.selectVariablesInRootProcess,
  () => true,
);
export const selectIsFileStoreEnable = createSelector(taskFeatureSelector, variablesFeature.selectIsFileStoreEnable);
export const selectInitiatorFullName = createSelector(taskFeatureSelector, variablesFeature.selectInitiatorFullName);

export const selectControllers = createSelector(taskFeatureSelector, (state) =>
  variablesFeature.selectControllers(state),
);
export const selectProtocolCC = createSelector(taskFeatureSelector, variablesFeature.selectProtocolCC);
export const selectLawCases = createSelector(taskFeatureSelector, variablesFeature.selectLawCases);
export const selectAutoChecks = createSelector(taskFeatureSelector, variablesFeature.selectAutoChecks);
export const selectInitiatorId = createSelector(taskFeatureSelector, variablesFeature.selectInitiatorId);
export const selectBpClientName = createSelector(taskFeatureSelector, variablesFeature.selectBpClientName);
export const selectFilterParams = createSelector(taskFeatureSelector, variablesFeature.selectFilterParams);
export const selectDeferredConditions = createSelector(taskFeatureSelector, variablesFeature.selectDeferredConditions);
export const selectVotingComments = createSelector(taskFeatureSelector, variablesFeature.selectVotingComments);
export const selectVotingResults = createSelector(taskFeatureSelector, variablesFeature.selectVotingResults);
export const selectPropertyOwner = createSelector(taskFeatureSelector, variablesFeature.selectPropertyOwner);
export const selectRateApproval = createSelector(taskFeatureSelector, variablesFeature.selectRateApproval);
export const selectControlOptions = createSelector(taskFeatureSelector, variablesFeature.selectControlOptions);
