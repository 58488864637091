<div class="header" sibStickyBar>
  <div class="back-button">
    <ng-content select="[backButton]"></ng-content>
  </div>

  <div class="actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>

<div class="body">
  <ng-content select="[body]"></ng-content>
</div>
