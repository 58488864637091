import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyMaskDirective } from 'ngx-currency';
import { DigitsOnlyDirective } from '@sib/shared/ui';
import { ControlContainer, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ConditionGroupsFacadeService, CurrenciesFacadeService, PromotionFacadeService } from '@sib/shared/store';
import { first, Observable } from 'rxjs';
import { FinancingConditionsGuaranteeService } from './financing-conditions-guarantee.service';

@Component({
  selector: 'sib-financing-conditions-guarantee',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CurrencyMaskDirective,
    DigitsOnlyDirective,
    FormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
  ],
  templateUrl: './financing-conditions-guarantee.component.html',
  styleUrls: ['./financing-conditions-guarantee.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FinancingConditionsGuaranteeComponent {
  public currenciesFacadeService = inject(CurrenciesFacadeService);
  public financingConditionsGuaranteeService = inject(FinancingConditionsGuaranteeService);
  private promotionFacadeService = inject(PromotionFacadeService);
  private conditionGroupsFacadeService = inject(ConditionGroupsFacadeService);

  @Input() dictionariesCustomProducts!: Observable<any[]>;

  public promotion$ = this.promotionFacadeService.getPromotion$;
  public minDate = new Date();

  constructor() {
    this.conditionGroupsFacadeService.loadConditionGroups();
  }

  onSelectChangeProduct({ value }: { value: number }) {
    this.dictionariesCustomProducts.pipe(first()).subscribe((dictionaries) => {
      const item = dictionaries.find((item) => value === item.groupId);
      this.financingConditionsGuaranteeService.patchValue({
        productId: item.id,
        currencyCode: item.val,
        productName: item.prodName,
        dealTitle: 'Генеральний договір про здійснення документарних операцій',
      });
    });
  }
}
