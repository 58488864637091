<h3 mat-dialog-title>{{ data.title }}</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <div class="content" [formGroup]="form">
    <p>{{ data.message }}</p>
    <mat-form-field>
      <mat-label> {{ data.fieldLabel }} </mat-label>
      <textarea matInput formControlName="comment" autofocus [placeholder]="data.placeholder"></textarea>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button type="reset" mat-stroked-button [mat-dialog-close]="">
    {{ data.closeLabel }}
  </button>

  <button type="submit" mat-raised-button (click)="onSubmitDialog()" color="primary">
    <mat-icon>save</mat-icon>
    {{ data.confirmLabel }}
  </button>
</div>
