/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssociatedDocumentsDto } from '../models/AssociatedDocumentsDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssociatedDocumentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get associated document
     * Get associated document
     * @returns AssociatedDocumentsDto associated document found
     * @throws ApiError
     */
    public getAssociatedDocsProcessId({
        processId,
    }: {
        /**
         * Process ID. Cannot be empty.
         */
        processId: string,
    }): Observable<Array<AssociatedDocumentsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/associated-docs/processId/{processId}',
            path: {
                'processId': processId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
