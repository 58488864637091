import { createSelector } from '@ngrx/store';
import { selectAssetAgreementRelation } from '../../+state/variables/variables.selectors';
import { selectActiveAgreement } from '../agreements/agreements.selectors';

export const selectAssetAgreementRelationByDealNumber = (dealNumber: string) =>
  createSelector(selectAssetAgreementRelation, (assetAgreementRelation) =>
    assetAgreementRelation.filter((i) => i.dealNumber === dealNumber),
  );

export const selectAssetAgreementRelationByActiveAgreement = createSelector(
  selectActiveAgreement,
  selectAssetAgreementRelation,
  (activeAgreement, assetAgreementRelation) =>
    assetAgreementRelation.filter((i) => i.dealNumber === activeAgreement?.dealNumber),
);
