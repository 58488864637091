/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RoleDto } from '../models/RoleDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RoleService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all roles
     * Returns all roles.
     * @returns RoleDto all roles
     * @throws ApiError
     */
    public getApiRoles(): Observable<Array<RoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles',
        });
    }

    /**
     * Create role
     * Method for creating role.
     * @returns RoleDto Role created
     * @throws ApiError
     */
    public postApiRoles({
        requestBody,
    }: {
        /**
         * Role object that needs to be created.
         */
        requestBody: RoleDto,
    }): Observable<RoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Role with such id already exists`,
                500: `server error`,
            },
        });
    }

    /**
     * Get role
     * Get role by ID.
     * @returns any role found
     * @throws ApiError
     */
    public getApiRoles1({
        id,
    }: {
        /**
         * Role ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        name?: string;
        scopeCode?: string;
        roleEmail?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `role not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update role
     * Updates the role with the given ID.
     * @returns any role updated
     * @throws ApiError
     */
    public putApiRoles({
        id,
        requestBody,
    }: {
        /**
         * Role ID. Cannot be empty.
         */
        id: string,
        /**
         * Role object that needs to be updated.
         */
        requestBody: RoleDto,
    }): Observable<{
        id: string;
        name?: string;
        scopeCode?: string;
        roleEmail?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                404: `Role not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete role
     * Deletes the role with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRoles({
        id,
    }: {
        /**
         * role ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
