import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as LoanClassesActions from './loan-classes.actions';
import { LoanClassDTO } from '@api/dictionaries';

export const loanClassesFeatureKey = 'loan-classes';

export interface State extends EntityState<LoanClassDTO> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<LoanClassDTO> = createEntityAdapter<LoanClassDTO>({
  selectId: (loanClassesItem: LoanClassDTO) => loanClassesItem.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(LoanClassesActions.loadLoanClasses, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(LoanClassesActions.loadLoanClassesSuccess, (state, { loanClassesList }) => ({
    ...adapter.setAll(loanClassesList, state),
    isLoading: false,
  })),

  on(LoanClassesActions.loadLoanClassesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
