<div class="history-dialog dialog">
  <header class="history-dialog__header dialog__header">
    <h3 class="history-dialog__title dialog__title">Історія процесу</h3>
    <button class="history-dialog__header-button dialog__header-button" (click)="onCloseDialog()">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.2832 4.29785L14.3866 14.4013" stroke="#828282" stroke-width="1.5" stroke-linecap="round" />
        <path d="M4.2832 14.4014L14.3866 4.29794" stroke="#828282" stroke-width="1.5" stroke-linecap="round" />
      </svg>
      Закрити
    </button>
  </header>
  <div class="history-dialog__body dialog__body">
    <ng-container *ngIf="!isLoading">
      <sib-loader></sib-loader>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <ng-container *ngIf="historyList?.length! > 0; else empty">
        <div class="main-table history-table">
          <table>
            <tr>
              <th>Назва підпроцесу</th>
              <th>Назва задачі</th>
              <th>Дата та час створення</th>
              <th>Дата та час взяття в роботу</th>
              <th>Дата та час завершення</th>
              <th>ПІБ виконавця</th>
              <th [matTooltip]="'дні: години: хвилини'">Час у роботі</th>
            </tr>

            <tr class="primary-table__tbody-tr" *ngFor="let row of historyList">
              <td class="primary-table__tbody-td">
                {{ row.procName }}
              </td>
              <td class="primary-table__tbody-td">
                {{ row.taskName }}
              </td>
              <td class="primary-table__tbody-td">
                {{ row.startTimeTask || '' | date : 'dd.MM.yyyy HH:mm:ss' }}
              </td>
              <td class="primary-table__tbody-td">
                <ng-container *ngIf="row.timeAssigne; else emptyrow">
                  {{ row.timeAssigne | date : 'dd.MM.yyyy HH:mm:ss' }}
                </ng-container>
              </td>
              <td class="primary-table__tbody-td">
                <ng-container *ngIf="row.timeComplete; else emptyrow">
                  {{ row.timeComplete | date : 'dd.MM.yyyy HH:mm:ss' }}
                </ng-container>
              </td>
              <td class="primary-table__tbody-td">
                <ng-container *ngIf="row.fullName; else emptyrow">
                  {{ row.fullName }}
                </ng-container>
              </td>
              <td class="primary-table__tbody-td" [matTooltip]="'дні: години: хвилини'">
                {{ getDuration(row) }}
              </td>
            </tr>
          </table>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #empty> Для цього процесу ще немає історії</ng-template>
    <ng-template #emptyrow> -</ng-template>
  </div>
</div>
