<ng-container [formGroup]="form">
  <div class="actions">
    <button type="button" mat-raised-button color="primary" (click)="addCashFlowZKRS()" [disabled]="isDisabled">Додати</button>
    <button type="button" mat-stroked-button color="warn" (click)="removeAllCashFlowZKRS()" [disabled]="isDisabled">Видалити все</button>
  </div>
  <mat-table [dataSource]="dataSource" formArrayName="cashFlowZKR">
    <ng-container [matColumnDef]="'flowType'" sticky>
      <mat-header-cell *matHeaderCellDef>Тип потоків</mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <div>{{ element.controls.flowType.value }}</div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'month1'">
      <mat-header-cell *matHeaderCellDef>Місяць початку періоду</mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <mat-form-field floatLabel="always">
          <mat-label>місяць</mat-label>
          <input type="text" matInput digitOnly formControlName="month1" />

          <mat-error>
            <ng-container
              *ngTemplateOutlet="minMaxValidator; context: { control: element.controls.month1 }"
            ></ng-container>
          </mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'year1'">
      <mat-header-cell *matHeaderCellDef>Рік початку періоду</mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <mat-form-field floatLabel="always">
          <mat-label>рік</mat-label>
          <input type="text" matInput digitOnly formControlName="year1" />
          <mat-error>
            <ng-container
              *ngTemplateOutlet="minMaxValidator; context: { control: element.controls.year1 }"
            ></ng-container>
          </mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'month2'">
      <mat-header-cell *matHeaderCellDef>Місяць завершення періоду</mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <mat-form-field floatLabel="always">
          <mat-label>місяць</mat-label>
          <input type="text" matInput digitOnly formControlName="month2" />
          <mat-error>
            <ng-container
              *ngTemplateOutlet="minMaxValidator; context: { control: element.controls.month2 }"
            ></ng-container>
          </mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'year2'">
      <mat-header-cell *matHeaderCellDef>Рік завершення періоду</mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <mat-form-field floatLabel="always">
          <mat-label>рік</mat-label>
          <input type="text" matInput digitOnly formControlName="year2" />
          <mat-error>
            <ng-container
              *ngTemplateOutlet="minMaxValidator; context: { control: element.controls.year2 }"
            ></ng-container>
          </mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'kvrtSum'">
      <mat-header-cell *matHeaderCellDef>Сума потоків до переводу</mat-header-cell>
      <mat-cell *matCellDef="let element" [formGroup]="element">
        <mat-form-field floatLabel="always">
          <mat-label>сума</mat-label>
          <input type="text" matInput currencyMask formControlName="kvrtSum" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'settings'">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element; let index = index">
        <button type="button" mat-icon-button color="primary" (click)="removeByIndexCashFlowZKRS(index)" [disabled]="isDisabled">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="'error'">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element; let index = index">
        <mat-error *ngIf="element.hasError('dateDiff') && element.touched">
          Дата початку періоду має бути меньшою ніж дата кінця періоду
        </mat-error>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsDef"></mat-header-row>
    <mat-row *matRowDef="let row; let index = index; columns: columnsDef"></mat-row>
  </mat-table>
</ng-container>

<ng-template #minMaxValidator let-control="control">
  <span *ngIf="control.getError('min') as error"> Мінімальне допустиме значення {{ error.min }} </span>
  <span *ngIf="control.getError('max') as error"> Максимальне допустиме значення {{ error.max }} </span>
</ng-template>
