import { Action, ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import * as fromUi from './ui.reducer';
import * as fromTasks from './tasks.reducer';
import { logout } from '@sib/shared/store';

export interface State {
  [fromUi.uiFeatureKey]: fromUi.State;
  [fromTasks.tasksFeatureKey]: fromTasks.State;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    [fromUi.uiFeatureKey]: fromUi.reducer,
    [fromTasks.tasksFeatureKey]: fromTasks.reducer,
  }),
});

export function resetRoot(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => reducer(action.type === logout.type ? undefined : state, action);
}

export const metaReducers: MetaReducer<State>[] = [resetRoot];

export const selectUiState = createFeatureSelector<fromUi.State>(fromUi.uiFeatureKey);
export const selectTasksState = createFeatureSelector<fromTasks.State>(fromTasks.tasksFeatureKey);
