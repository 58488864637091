/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { MessageTemplateDTO } from '../models/MessageTemplateDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MessageTemplateService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns MessageTemplateDTO all message templates
     * @throws ApiError
     */
    public getApiMessageTemplates(): Observable<Array<MessageTemplateDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/message-templates',
        });
    }

    /**
     * Create message template
     * Method for creating message template.
     * @returns MessageTemplateDTO message template created
     * @throws ApiError
     */
    public postApiMessageTemplates({
        requestBody,
    }: {
        /**
         * Message template object that needs to be created.
         */
        requestBody: MessageTemplateDTO,
    }): Observable<MessageTemplateDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/message-templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get message template
     * Get message template by ID.
     * @returns any message template found
     * @throws ApiError
     */
    public getApiMessageTemplates1({
        id,
    }: {
        /**
         * Message template ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        name?: string;
        subject?: string;
        text?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/message-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `message template not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update message template
     * Updates the message template with the given ID.
     * @returns any message template updated
     * @throws ApiError
     */
    public putApiMessageTemplates({
        id,
        requestBody,
    }: {
        /**
         * Message template ID. Cannot be empty.
         */
        id: number,
        /**
         * Message template object that needs to be updated.
         */
        requestBody: MessageTemplateDTO,
    }): Observable<{
        id: number;
        name?: string;
        subject?: string;
        text?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/message-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `MessageTemplate DTO has wrong ID`,
                404: `MessageTemplate not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete message template
     * Deletes the message template with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiMessageTemplates({
        id,
    }: {
        /**
         * Message template ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/message-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
