import { createReducer, on } from '@ngrx/store';
import * as UiActions from '../actions/ui.actions';

export const uiFeatureKey = 'ui';

export interface State {
  isSidenavActive: boolean;
  version: string[];
}

const initialState: State = {
  isSidenavActive: false,
  version: [],
};

export const reducer = createReducer(
  initialState,
  on(UiActions.closeSidenav, (state) => ({ ...state, isSidenavActive: false })),
  on(UiActions.openSidenav, (state) => ({ ...state, isSidenavActive: true })),
  on(UiActions.saveVersion, (state, { version }) => ({ ...state, version })),
);

export const selectIsSidenavActive = (state: State) => state.isSidenavActive;
export const selectVersion = (state: State) => state.version;
