import { createSelector } from '@ngrx/store';
import * as fromAssetTypes from './asset-types.reducer';
import { selectAssetTypesState } from '../+shell/reducers.index';

export const selectAssetTypesIsLoading = createSelector(selectAssetTypesState, fromAssetTypes.selectIsLoading);

export const selectAssetTypesEntities = createSelector(selectAssetTypesState, fromAssetTypes.selectEntities);
export const selectAssetTypesAll = createSelector(selectAssetTypesState, fromAssetTypes.selectAll);

export const selectAssetTypeById = (assetTypeCode: string) =>
  createSelector(selectAssetTypesEntities, (assetTypesEntities) => assetTypesEntities[assetTypeCode]);

export const selectActiveAssetTypes = createSelector(selectAssetTypesAll, (assetTypes) =>
  assetTypes.filter((assetType) => assetType.actFlg),
);
