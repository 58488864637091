import { createReducer, on } from '@ngrx/store';
import * as Assessment from './assessment-kinds.actions';
import { AssessmentKindDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const assessmentKindsFeatureKey = 'assessment-kinds';

export interface State extends EntityState<AssessmentKindDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<AssessmentKindDto>({
  selectId: (item: AssessmentKindDto) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(Assessment.loadAssessmentKinds, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(Assessment.loadAssessmentKindsSuccess, (state, { assessmentKinds }) => ({
    ...adapter.setAll(assessmentKinds, state),
    isLoading: false,
  })),
  on(Assessment.loadAssessmentKindsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAssessmentKindsState = selectAll;
