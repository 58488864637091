import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadTypeDocsOwnership, selectTypeDocsOwnership } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class TypeDocsOwnershipFacadeService {
  public typeDocsOwnership$ = this.store.select(selectTypeDocsOwnership);
  constructor(private store: Store) {}

  public loadTypeDocsOwnership() {
    this.store.dispatch(loadTypeDocsOwnership());
  }
}
