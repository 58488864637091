<h3 mat-dialog-title>{{ data.title }}</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <div [innerHtml]="data.prefix"></div>
  <mat-table *ngIf="dataSource.filteredData.length; else empty" [dataSource]="dataSource">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
      <mat-header-cell *matHeaderCellDef>{{ column.value }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element[column.name] }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsDef"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsDef"></mat-row>
  </mat-table>
  <div [innerHtml]="data.postfix"></div>
</div>

<div mat-dialog-actions>
  <button type="reset" *ngIf="!data.buttonNoHidden" mat-stroked-button [mat-dialog-close]="false">
    {{ data.buttonTitleNo }}
  </button>

  <button type="submit" *ngIf="!data.buttonYesHidden" mat-raised-button [mat-dialog-close]="true" color="primary">
    {{ data.buttonTitleYes }}
  </button>
</div>

<ng-template #empty> Записа відсутні</ng-template>
