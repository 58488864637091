import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadSourceAssessments, selectSourceAssessments } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class SourceAssessmentsFacadeService {
  public sourceAssessments$ = this.store.select(selectSourceAssessments);
  constructor(private store: Store) {}

  public loadSourceAssessments() {
    this.store.dispatch(loadSourceAssessments());
  }
}
