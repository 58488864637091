import { createSelector } from '@ngrx/store';
import * as fromRoles from './roles.reducer';
import { convertArrayToObjectTyped } from '@sib/shared/util';
import { selectRolesState } from '../+shell/reducers.index';

export const selectRolesIsLoading = createSelector(selectRolesState, fromRoles.selectIsLoading);

export const selectAllRoles = createSelector(selectRolesState, fromRoles.selectAll);

export const selectRolesEntities = createSelector(selectAllRoles, (allRoles) =>
  convertArrayToObjectTyped(allRoles, 'id'),
);

export const selectRoleById = (roleId: string) =>
  createSelector(selectRolesEntities, (rolesEntities) => rolesEntities[roleId]);
