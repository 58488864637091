/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LoanClassDTO } from '../models/LoanClassDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LoanClassService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all loan classes
     * Returns all loan classes.
     * @returns LoanClassDTO all loan classes
     * @throws ApiError
     */
    public getApiLoanClasses(): Observable<Array<LoanClassDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/loan-classes',
        });
    }

    /**
     * Create loan class
     * Method for creating loan class.
     * @returns LoanClassDTO loan class created
     * @throws ApiError
     */
    public postApiLoanClasses({
        requestBody,
    }: {
        /**
         * Loan class object that needs to be created.
         */
        requestBody: LoanClassDTO,
    }): Observable<LoanClassDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/loan-classes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get loan class
     * Get loan class by ID.
     * @returns any loan class found
     * @throws ApiError
     */
    public getApiLoanClasses1({
        id,
    }: {
        /**
         * Loan class ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        loanClassName?: string;
        obligationTypeId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/loan-classes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `loan class not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update loan class
     * Updates the loan class with the given ID.
     * @returns any assessment type updated
     * @throws ApiError
     */
    public putApiLoanClasses({
        id,
        requestBody,
    }: {
        /**
         * Loan class ID. Cannot be empty.
         */
        id: number,
        /**
         * Loan class object that needs to be updated.
         */
        requestBody: LoanClassDTO,
    }): Observable<{
        id: number;
        loanClassName?: string;
        obligationTypeId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/loan-classes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete loan class
     * Deletes the loan class with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiLoanClasses({
        id,
    }: {
        /**
         * Loan class ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/loan-classes/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
