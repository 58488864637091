import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, forkJoin, of, take } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as TasksActions from '../actions/tasks.actions';
import { JwtService } from '@sib/shared/da';
import { DefaultService } from '@api/camunda';
import { PROCESS_DEFINITION_KEYS } from '@sib/shared/util';
import { type TaskCountDto, TaskService } from '@api/loan-approval';
import { AuthFacadeService } from '@sib/shared/store';

@Injectable()
export class TasksEffects {
  private taskService = inject(TaskService);
  private authFacadeService = inject(AuthFacadeService);

  loadTasksCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TasksActions.loadTasksCount),
      switchMap(() =>
        forkJoin([
          this.defaultService.postTaskCount({
            requestBody: {
              assignee: this.jwtService.getUserLogin()!,
              assigned: true,
              processDefinitionKeyIn: PROCESS_DEFINITION_KEYS,
            },
          }),
          this.defaultService.postTaskCount({
            requestBody: {
              candidateUser: this.jwtService.getUserLogin()!,
              unassigned: true,
              processDefinitionKeyIn: PROCESS_DEFINITION_KEYS,
            },
          }),
          this.authFacadeService.checkUserPermission$('request_list_tasks').pipe(
            take(1),
            switchMap((isAllow) =>
              defer(() =>
                isAllow ? this.taskService.getTaskAssessmentCount() : of({ candidate: 0, assigned: 0 } as TaskCountDto),
              ),
            ),
          ),
          this.authFacadeService.checkUserPermission$('valuation_list_tasks').pipe(
            take(1),
            switchMap((isAllow) =>
              defer(() =>
                isAllow ? this.taskService.getTaskAssessmentCount() : of({ candidate: 0, assigned: 0 } as TaskCountDto),
              ),
            ),
          ),
        ]).pipe(
          map(([assignedTasks, unassignedTasks, tasksCount, tasksAssessment]) =>
            TasksActions.loadTasksCountSuccess({
              tasksCount: {
                assigned: assignedTasks.count,
                candidate: unassignedTasks.count,
              },
              tasksAssessment,
            }),
          ),
          catchError((error) =>
            // this.toastrService.error(error.error?.message);
            of(TasksActions.loadTasksCountFailure({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private defaultService: DefaultService, private jwtService: JwtService) {}
}
