/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RepaymentTypeDto } from '../models/RepaymentTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RepaymentTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all repayment types
     * Returns all repayment types.
     * @returns RepaymentTypeDto all repayment types
     * @throws ApiError
     */
    public getApiRepaymentTypes(): Observable<Array<RepaymentTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/repayment-types',
        });
    }

    /**
     * Create repayment type
     * Method for creating repayment type.
     * @returns RepaymentTypeDto repayment type created
     * @throws ApiError
     */
    public postApiRepaymentTypes({
        requestBody,
    }: {
        /**
         * Repayment type object that needs to be created.
         */
        requestBody: RepaymentTypeDto,
    }): Observable<RepaymentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/repayment-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get repayment type
     * Get repayment type by ID.
     * @returns any repayment type found
     * @throws ApiError
     */
    public getApiRepaymentTypes1({
        id,
    }: {
        /**
         * Repayment type ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        idTitle?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/repayment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `repayment type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update repayment type
     * Updates the repayment type with the given ID.
     * @returns any repayment type updated
     * @throws ApiError
     */
    public putApiRepaymentTypes({
        id,
        requestBody,
    }: {
        /**
         * Repayment type ID. Cannot be empty.
         */
        id: number,
        /**
         * Repayment type object that needs to be updated.
         */
        requestBody: RepaymentTypeDto,
    }): Observable<{
        id: number;
        idTitle?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/repayment-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete repayment type
     * Deletes the repayment type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRepaymentTypes({
        id,
    }: {
        /**
         * Repayment type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/repayment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
