import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AssessmentApproaches from './assessment-approaches.actions';
import * as Selectors from './assessment-approaches.selectors';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class AssessmentApproachesEffects {
  assessmentApproaches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssessmentApproaches.loadAssessmentApproaches),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectAssessmentApproaches)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadAssessmentApproaches()).pipe(
          map((response) =>
            AssessmentApproaches.loadAssessmentApproachesSuccess({
              assessmentApproaches: response,
            }),
          ),
          catchError((error) => of(AssessmentApproaches.loadAssessmentApproachesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
