import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRouterParam, selectRouterParamData, selectRouterQueryParam } from './router.selectors';

@Injectable({
  providedIn: 'root',
})
export class RouterStateFacadeService {
  private readonly store = inject(Store);

  public getParams = (param: string) => this.store.select(selectRouterParam(param));
  public selectRouterQueryParam = (param: string) => this.store.select(selectRouterQueryParam(param));
  public selectRouterParamData = (param: string) => this.store.select(selectRouterParamData(param));
}
