/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AbstractQueryDtoObject } from './models/AbstractQueryDtoObject';
export type { ActivityInstanceDto } from './models/ActivityInstanceDto';
export type { ActivityInstanceIncidentDto } from './models/ActivityInstanceIncidentDto';
export type { AnnotationDto } from './models/AnnotationDto';
export type { ApplicationServerDto } from './models/ApplicationServerDto';
export type { AtomLink } from './models/AtomLink';
export type { AttachmentDto } from './models/AttachmentDto';
export type { AuthenticationResult } from './models/AuthenticationResult';
export type { AuthorizationCheckResultDto } from './models/AuthorizationCheckResultDto';
export type { AuthorizationCreateDto } from './models/AuthorizationCreateDto';
export type { AuthorizationDto } from './models/AuthorizationDto';
export type { BasicUserCredentialsDto } from './models/BasicUserCredentialsDto';
export type { BatchDto } from './models/BatchDto';
export type { BatchStatisticsDto } from './models/BatchStatisticsDto';
export type { CalledProcessDefinitionDto } from './models/CalledProcessDefinitionDto';
export type { CaseDefinitionDiagramDto } from './models/CaseDefinitionDiagramDto';
export type { CaseDefinitionDto } from './models/CaseDefinitionDto';
export type { CaseExecutionDto } from './models/CaseExecutionDto';
export type { CaseExecutionQueryDto } from './models/CaseExecutionQueryDto';
export type { CaseExecutionTriggerDto } from './models/CaseExecutionTriggerDto';
export type { CaseInstanceDto } from './models/CaseInstanceDto';
export type { CaseInstanceQueryDto } from './models/CaseInstanceQueryDto';
export type { CleanableHistoricBatchReportResultDto } from './models/CleanableHistoricBatchReportResultDto';
export type { CleanableHistoricCaseInstanceReportResultDto } from './models/CleanableHistoricCaseInstanceReportResultDto';
export type { CleanableHistoricDecisionInstanceReportResultDto } from './models/CleanableHistoricDecisionInstanceReportResultDto';
export type { CleanableHistoricProcessInstanceReportResultDto } from './models/CleanableHistoricProcessInstanceReportResultDto';
export type { CommandDto } from './models/CommandDto';
export type { CommentDto } from './models/CommentDto';
export type { CompleteExternalTaskDto } from './models/CompleteExternalTaskDto';
export type { CompleteTaskDto } from './models/CompleteTaskDto';
export type { ConditionQueryParameterDto } from './models/ConditionQueryParameterDto';
export type { CorrelationMessageAsyncDto } from './models/CorrelationMessageAsyncDto';
export type { CorrelationMessageDto } from './models/CorrelationMessageDto';
export type { CountResultDto } from './models/CountResultDto';
export type { CreateCaseInstanceDto } from './models/CreateCaseInstanceDto';
export type { CreateIncidentDto } from './models/CreateIncidentDto';
export type { DatabaseDto } from './models/DatabaseDto';
export type { DecisionDefinitionDiagramDto } from './models/DecisionDefinitionDiagramDto';
export type { DecisionDefinitionDto } from './models/DecisionDefinitionDto';
export type { DecisionRequirementsDefinitionDto } from './models/DecisionRequirementsDefinitionDto';
export type { DecisionRequirementsDefinitionXmlDto } from './models/DecisionRequirementsDefinitionXmlDto';
export type { DeleteHistoricDecisionInstancesDto } from './models/DeleteHistoricDecisionInstancesDto';
export type { DeleteHistoricProcessInstancesDto } from './models/DeleteHistoricProcessInstancesDto';
export type { DeleteProcessInstancesDto } from './models/DeleteProcessInstancesDto';
export type { DeploymentDto } from './models/DeploymentDto';
export type { DeploymentResourceDto } from './models/DeploymentResourceDto';
export type { EvaluateDecisionDto } from './models/EvaluateDecisionDto';
export type { EvaluationConditionDto } from './models/EvaluationConditionDto';
export type { EventSubscriptionDto } from './models/EventSubscriptionDto';
export type { ExecutionDto } from './models/ExecutionDto';
export type { ExecutionQueryDto } from './models/ExecutionQueryDto';
export type { ExecutionTriggerDto } from './models/ExecutionTriggerDto';
export type { ExtendLockOnExternalTaskDto } from './models/ExtendLockOnExternalTaskDto';
export type { ExternalTaskBpmnError } from './models/ExternalTaskBpmnError';
export type { ExternalTaskDto } from './models/ExternalTaskDto';
export type { ExternalTaskFailureDto } from './models/ExternalTaskFailureDto';
export type { ExternalTaskQueryDto } from './models/ExternalTaskQueryDto';
export type { FetchExternalTasksDto } from './models/FetchExternalTasksDto';
export type { FetchExternalTasksExtendedDto } from './models/FetchExternalTasksExtendedDto';
export type { FetchExternalTaskTopicDto } from './models/FetchExternalTaskTopicDto';
export type { FilterDto } from './models/FilterDto';
export type { FormDto } from './models/FormDto';
export type { FormPart } from './models/FormPart';
export type { GroupDto } from './models/GroupDto';
export type { GroupDto1 } from './models/GroupDto1';
export type { GroupInfoDto } from './models/GroupInfoDto';
export type { GroupQueryDto } from './models/GroupQueryDto';
export type { HistoricActivityInstanceDto } from './models/HistoricActivityInstanceDto';
export type { HistoricActivityInstanceQueryDto } from './models/HistoricActivityInstanceQueryDto';
export type { HistoricActivityStatisticsDto } from './models/HistoricActivityStatisticsDto';
export type { HistoricBatchDto } from './models/HistoricBatchDto';
export type { HistoricBatchQueryDto } from './models/HistoricBatchQueryDto';
export type { HistoricCaseActivityInstanceDto } from './models/HistoricCaseActivityInstanceDto';
export type { HistoricCaseActivityStatisticsDto } from './models/HistoricCaseActivityStatisticsDto';
export type { HistoricCaseInstanceDto } from './models/HistoricCaseInstanceDto';
export type { HistoricCaseInstanceQueryDto } from './models/HistoricCaseInstanceQueryDto';
export type { HistoricDecisionInputInstanceDto } from './models/HistoricDecisionInputInstanceDto';
export type { HistoricDecisionInstanceDto } from './models/HistoricDecisionInstanceDto';
export type { HistoricDecisionInstanceQueryDto } from './models/HistoricDecisionInstanceQueryDto';
export type { HistoricDecisionInstanceStatisticsDto } from './models/HistoricDecisionInstanceStatisticsDto';
export type { HistoricDecisionOutputInstanceDto } from './models/HistoricDecisionOutputInstanceDto';
export type { HistoricDetailDto } from './models/HistoricDetailDto';
export type { HistoricDetailQueryDto } from './models/HistoricDetailQueryDto';
export type { HistoricExternalTaskLogDto } from './models/HistoricExternalTaskLogDto';
export type { HistoricExternalTaskLogQueryDto } from './models/HistoricExternalTaskLogQueryDto';
export type { HistoricIdentityLinkLogDto } from './models/HistoricIdentityLinkLogDto';
export type { HistoricIncidentDto } from './models/HistoricIncidentDto';
export type { HistoricJobLogDto } from './models/HistoricJobLogDto';
export type { HistoricJobLogQueryDto } from './models/HistoricJobLogQueryDto';
export type { HistoricProcessInstanceDto } from './models/HistoricProcessInstanceDto';
export type { HistoricProcessInstanceQueryDto } from './models/HistoricProcessInstanceQueryDto';
export type { HistoricTaskInstanceDto } from './models/HistoricTaskInstanceDto';
export type { HistoricTaskInstanceQueryDto } from './models/HistoricTaskInstanceQueryDto';
export type { HistoricVariableInstanceDto } from './models/HistoricVariableInstanceDto';
export type { HistoricVariableInstanceQueryDto } from './models/HistoricVariableInstanceQueryDto';
export type { HistoryCleanupConfigurationDto } from './models/HistoryCleanupConfigurationDto';
export type { HistoryTimeToLiveDto } from './models/HistoryTimeToLiveDto';
export type { IdentityLinkDto } from './models/IdentityLinkDto';
export type { IncidentDto } from './models/IncidentDto';
export type { IncidentStatisticsResultDto } from './models/IncidentStatisticsResultDto';
export type { InternalsDto } from './models/InternalsDto';
export type { JdkDto } from './models/JdkDto';
export type { JobDefinitionDto } from './models/JobDefinitionDto';
export type { JobDefinitionPriorityDto } from './models/JobDefinitionPriorityDto';
export type { JobDefinitionQueryDto } from './models/JobDefinitionQueryDto';
export type { JobDefinitionSuspensionStateDto } from './models/JobDefinitionSuspensionStateDto';
export type { JobDto } from './models/JobDto';
export type { JobDuedateDto } from './models/JobDuedateDto';
export type { JobQueryDto } from './models/JobQueryDto';
export type { JobSuspensionStateDto } from './models/JobSuspensionStateDto';
export type { LicenseKeyDataDto } from './models/LicenseKeyDataDto';
export type { LockedExternalTaskDto } from './models/LockedExternalTaskDto';
export type { LockExternalTaskDto } from './models/LockExternalTaskDto';
export type { MetricDto } from './models/MetricDto';
export type { MetricsIntervalResultDto } from './models/MetricsIntervalResultDto';
export type { MetricsResultDto } from './models/MetricsResultDto';
export type { MigrationExecutionDto } from './models/MigrationExecutionDto';
export type { MigrationInstructionDto } from './models/MigrationInstructionDto';
export type { MigrationInstructionValidationReportDto } from './models/MigrationInstructionValidationReportDto';
export type { MigrationPlanDto } from './models/MigrationPlanDto';
export type { MigrationPlanGenerationDto } from './models/MigrationPlanGenerationDto';
export type { MigrationPlanReportDto } from './models/MigrationPlanReportDto';
export type { MigrationVariableValidationReportDto } from './models/MigrationVariableValidationReportDto';
export type { ModificationDto } from './models/ModificationDto';
export type { MultipartFormData } from './models/MultipartFormData';
export type { OptimizeHistoricActivityInstanceDto } from './models/OptimizeHistoricActivityInstanceDto';
export type { OptimizeHistoricIdentityLinkLogDto } from './models/OptimizeHistoricIdentityLinkLogDto';
export type { OptimizeHistoricVariableUpdateDto } from './models/OptimizeHistoricVariableUpdateDto';
export type { PasswordPolicyRequestDto } from './models/PasswordPolicyRequestDto';
export type { PatchVariablesDto } from './models/PatchVariablesDto';
export type { PriorityDto } from './models/PriorityDto';
export type { ProcessDefinitionDiagramDto } from './models/ProcessDefinitionDiagramDto';
export type { ProcessDefinitionDto } from './models/ProcessDefinitionDto';
export type { ProcessDefinitionSuspensionStateDto } from './models/ProcessDefinitionSuspensionStateDto';
export type { ProcessEngineDto } from './models/ProcessEngineDto';
export type { ProcessInstanceDto } from './models/ProcessInstanceDto';
export type { ProcessInstanceModificationDto } from './models/ProcessInstanceModificationDto';
export type { ProcessInstanceModificationInstructionDto } from './models/ProcessInstanceModificationInstructionDto';
export type { ProcessInstanceQueryDto } from './models/ProcessInstanceQueryDto';
export type { ProcessInstanceSuspensionStateAsyncDto } from './models/ProcessInstanceSuspensionStateAsyncDto';
export type { ProcessInstanceSuspensionStateDto } from './models/ProcessInstanceSuspensionStateDto';
export type { ProductDto } from './models/ProductDto';
export type { ResourceOptionsDto } from './models/ResourceOptionsDto';
export type { RestartProcessInstanceDto } from './models/RestartProcessInstanceDto';
export type { RetriesDto } from './models/RetriesDto';
export type { SchemaLogEntryDto } from './models/SchemaLogEntryDto';
export type { SchemaLogQueryDto } from './models/SchemaLogQueryDto';
export type { SetJobRetriesByProcessDto } from './models/SetJobRetriesByProcessDto';
export type { SetJobRetriesDto } from './models/SetJobRetriesDto';
export type { SetRemovalTimeToHistoricBatchesDto } from './models/SetRemovalTimeToHistoricBatchesDto';
export type { SetRemovalTimeToHistoricDecisionInstancesDto } from './models/SetRemovalTimeToHistoricDecisionInstancesDto';
export type { SetRemovalTimeToHistoricProcessInstancesDto } from './models/SetRemovalTimeToHistoricProcessInstancesDto';
export type { SetRetriesForExternalTasksDto } from './models/SetRetriesForExternalTasksDto';
export type { SetVariablesAsyncDto } from './models/SetVariablesAsyncDto';
export type { SignalDto } from './models/SignalDto';
export type { SortingDto } from './models/SortingDto';
export type { StartProcessInstanceDto } from './models/StartProcessInstanceDto';
export type { StatisticsResultDto } from './models/StatisticsResultDto';
export type { SuspensionStateDto } from './models/SuspensionStateDto';
export type { TaskBpmnErrorDto } from './models/TaskBpmnErrorDto';
export type { TaskDto } from './models/TaskDto';
export type { TaskEscalationDto } from './models/TaskEscalationDto';
export type { TaskQueryDto } from './models/TaskQueryDto';
export type { TelemetryConfigurationDto } from './models/TelemetryConfigurationDto';
export type { TelemetryDataDto } from './models/TelemetryDataDto';
export type { TenantDto } from './models/TenantDto';
export type { TransitionInstanceDto } from './models/TransitionInstanceDto';
export type { TriggerVariableValueDto } from './models/TriggerVariableValueDto';
export type { UserCredentialsDto } from './models/UserCredentialsDto';
export type { UserDto } from './models/UserDto';
export type { UserDto1 } from './models/UserDto1';
export type { UserIdDto } from './models/UserIdDto';
export type { UserOperationLogEntryDto } from './models/UserOperationLogEntryDto';
export type { UserProfileDto } from './models/UserProfileDto';
export type { VariableInstanceDto } from './models/VariableInstanceDto';
export type { VariableInstanceQueryDto } from './models/VariableInstanceQueryDto';
export type { VariableNameDto } from './models/VariableNameDto';
export type { VariableQueryParameterDto } from './models/VariableQueryParameterDto';
export type { VariableValueDto } from './models/VariableValueDto';
export type { VersionDto } from './models/VersionDto';

export { DefaultService } from './services/DefaultService';
