<mat-card formGroupName="consentHusbandWife">
  <mat-card-header>
    <mat-card-title>Згода чоловіка/дружини</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="operations">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        (click)="loadInfo()"
        [disabled]="consentHusbandWifeGroupService.disabled"
      >
        <mat-icon>add_circle</mat-icon>
        Отримати дані
      </button>
    </div>
    <div class="row">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="partner">
          <mat-header-cell *matHeaderCellDef> Код БП чоловіка/дружини</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.partner }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="partnerName">
          <mat-header-cell *matHeaderCellDef> ПІБ чоловіка/дружини</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.partnerName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="agreement">
          <mat-header-cell *matHeaderCellDef> Наявність згоди</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="element.agreement === 'true'"
              [disabled]="consentHusbandWifeGroupService.disabled"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="passP">
          <mat-header-cell *matHeaderCellDef> Паспорт (серія/номер)</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.passP }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="passpDate">
          <mat-header-cell *matHeaderCellDef> Дата видачі</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.passpDate }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="passpPlace">
          <mat-header-cell *matHeaderCellDef> Ким виданий</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.passpPlace }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="!consentHusbandWifeGroupService.disabled && selection.toggle(row)"
        ></mat-row>

        <div *matNoDataRow>Записи відсутні</div>
      </mat-table>
    </div>
  </mat-card-content>
</mat-card>
