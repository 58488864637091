<mat-card formGroupName="responsibilityContract">
  <mat-card-header>
    <mat-card-title>Відповідальність поручителя перед кредитором</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <p>Відповідальність поручителя перед кредитором обмежується сумою</p>
    <div class="row">
      <mat-form-field>
        <mat-label>Сума поруки</mat-label>

        <input matInput type="text" placeholder="Сума поруки" formControlName="gtSum" currencyMask />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Валюта</mat-label>
        <mat-select placeholder="Виберіть тип валюти" formControlName="gtCurr">
          <mat-option *ngFor="let item of currenciesFacadeService.currencies$ | async" [value]="item.code">
            {{ item.definition }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Еквівалент у гривнях за курсом НБУ</mat-label>

        <input
          matInput
          type="text"
          placeholder="Еквівалент у гривнях за курсом НБУ"
          formControlName="gtEkv"
          currencyMask
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Строк поруки з дня укладення цього договору становить (роки)</mat-label>

        <input
          matInput
          type="number"
          digitOnly
          placeholder="Строк поруки з дня укладення цього договору становить (роки)"
          formControlName="gtTerm"
        />
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
