import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  buildAgreementInfoTable,
  getAgreementIdByRouteParam,
  selectActiveAgreement,
  selectActiveAgreementId,
  selectAgreementsByAssets,
  selectAgreementsByGuarantors,
  selectAgreementsTabs,
  selectGuarantorsByDealNumber,
  selectIsRiskLoan,
  selectProvidingByDealNumber,
} from './agreements.selectors';
import {
  selectAgreementById,
  selectAgreementEntities,
  selectAllAgreements,
} from '../../+state/agreements/agreement.selectors';

@Injectable({
  providedIn: 'root',
})
export class AgreementsFacadeService {
  private store = inject(Store);

  selectAgreementsInfoTable$ = this.store.select(buildAgreementInfoTable);
  selectAgreementsTabs$ = this.store.select(selectAgreementsTabs);
  selectAllAgreements$ = this.store.select(selectAllAgreements);
  selectAgreementsEntities$ = this.store.select(selectAgreementEntities);
  selectAgreementsByGuarantors$ = this.store.select(selectAgreementsByGuarantors);
  selectActiveAgreementId$ = this.store.select(selectActiveAgreementId);
  selectAgreementIdByRouteParam$ = this.store.select(getAgreementIdByRouteParam);
  selectActiveAgreement$ = this.store.select(selectActiveAgreement);
  selectAgreementsByAssets$ = this.store.select(selectAgreementsByAssets);
  selectAgreementById$ = (id: string) => this.store.select(selectAgreementById(id));

  selectProvidingByDealNumber$ = (dealNumber: string) => this.store.select(selectProvidingByDealNumber(dealNumber));
  selectGuarantorsByDealNumber$ = (dealNumber: string) => this.store.select(selectGuarantorsByDealNumber(dealNumber));
  selectIsRiskLoan$ = (id: string) => this.store.select(selectIsRiskLoan(id));
}
