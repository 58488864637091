/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AgreementGuarantyAsset } from '../models/AgreementGuarantyAsset';
import type { AgreementParametersDto } from '../models/AgreementParametersDto';
import type { AgreementRequestDto } from '../models/AgreementRequestDto';
import type { AssetsAndAgreementGuarantyDto } from '../models/AssetsAndAgreementGuarantyDto';
import type { BailAgreementRequestDto } from '../models/BailAgreementRequestDto';
import type { LoanAccountRequestDto } from '../models/LoanAccountRequestDto';
import type { LoanCommissionRequestDto } from '../models/LoanCommissionRequestDto';
import type { LoanDeferConditionRequestDto } from '../models/LoanDeferConditionRequestDto';
import type { LoanFlowScheduleRequestDto } from '../models/LoanFlowScheduleRequestDto';
import type { PaymentScheduleEntryRequestDto } from '../models/PaymentScheduleEntryRequestDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AgreementService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Create agreement
     * Create agreement in sap.
     * @returns any Agreement in sap created
     * @throws ApiError
     */
    public postSapAgreement({
        requestBody,
    }: {
        /**
         * Agreement request
         */
        requestBody: AgreementRequestDto,
    }): Observable<{
        dealNumberSAP?: string;
        apprID?: string;
        prodID?: string;
        debtor?: string;
        sdtyp?: string;
        shome?: string;
        orgUnitID?: string;
        apprDate?: string;
        protocolNum?: string;
        protDate?: string;
        startDate?: string;
        endDate?: string;
        loanSum?: string;
        loanCurr?: string;
        loanCond?: string;
        paymentDay?: string;
        graphType?: string;
        percAddCond?: string;
        signerDebtBP?: string;
        signerBankBP?: string;
        manager?: string;
        sumFlowsMonth?: string;
        dateKK?: string;
        rateKK?: string;
        monthPayment?: string;
        grr?: string;
        genDealNumSAP?: string;
        pledgeType?: string;
        loanPaymScheduleSap?: Array<PaymentScheduleEntryRequestDto>;
        loanCommissionSap?: Array<LoanCommissionRequestDto>;
        loanFlowScheduleSap?: Array<LoanFlowScheduleRequestDto>;
        loanDeferCond?: Array<LoanDeferConditionRequestDto>;
        loanAccounts?: Array<LoanAccountRequestDto>;
        loanCondAdd?: string;
        condFlowRate?: string;
        condSalaryRate?: string;
        isPenaltyUPS?: boolean;
        promotionID?: string;
        beneficiaryBP?: string;
        guaranteeCategory?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/agreement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create agreement
     * Create agreement in sap.
     * @returns any Agreement in sap created
     * @throws ApiError
     */
    public postSapAgreements({
        requestBody,
    }: {
        /**
         * Agreement parameters
         */
        requestBody: AgreementParametersDto,
    }): Observable<{
        dealNumberSAP?: string;
        apprID?: string;
        prodID?: string;
        debtor?: string;
        sdtyp?: string;
        shome?: string;
        orgUnitID?: string;
        apprDate?: string;
        protocolNum?: string;
        protDate?: string;
        startDate?: string;
        endDate?: string;
        loanSum?: string;
        loanCurr?: string;
        loanCond?: string;
        paymentDay?: string;
        graphType?: string;
        percAddCond?: string;
        signerDebtBP?: string;
        signerBankBP?: string;
        manager?: string;
        sumFlowsMonth?: string;
        dateKK?: string;
        rateKK?: string;
        monthPayment?: string;
        grr?: string;
        genDealNumSAP?: string;
        pledgeType?: string;
        loanPaymScheduleSap?: Array<PaymentScheduleEntryRequestDto>;
        loanCommissionSap?: Array<LoanCommissionRequestDto>;
        loanFlowScheduleSap?: Array<LoanFlowScheduleRequestDto>;
        loanDeferCond?: Array<LoanDeferConditionRequestDto>;
        loanAccounts?: Array<LoanAccountRequestDto>;
        loanCondAdd?: string;
        condFlowRate?: string;
        condSalaryRate?: string;
        isPenaltyUPS?: boolean;
        promotionID?: string;
        beneficiaryBP?: string;
        guaranteeCategory?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/agreements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create assets and agreement guaranty
     * Create assets and agreement guaranty in sap.
     * @returns any Successfully created assets and agreement guaranty.
     * @throws ApiError
     */
    public postSapAssetsAndAgreementGuaranty({
        requestBody,
    }: {
        /**
         * Assets and agreement guaranty request
         */
        requestBody: AssetsAndAgreementGuarantyDto,
    }): Observable<{
        collateralGuid?: string;
        collateralId?: string;
        collateralType?: string;
        collateralValue?: string;
        collateralCurr?: string;
        startDate?: string;
        endDate?: string;
        orgUnitId?: string;
        dealNumberSap?: string;
        owner?: string;
        debtor?: string;
        signerClientBp?: string;
        signerBankBp?: string;
        manager?: string;
        collatAsset?: Array<AgreementGuarantyAsset>;
        cagmtCity?: string;
        cagmtEnc?: string;
        dealNumber?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/assets-and-agreement-guaranty',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                460: `Invalid assets parameters`,
                461: `Agreement guaranty creation error`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get bail agreement
     * Get bail agreement by params.
     * @returns any Bail agreement created
     * @throws ApiError
     */
    public postSapBailAgreementSet({
        requestBody,
    }: {
        /**
         * Bail agreement request
         */
        requestBody: BailAgreementRequestDto,
    }): Observable<{
        bailGUID?: string;
        bailID?: string;
        error?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/bail-agreement-set',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

}
