import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { loadTaskV2VariablesSuccess } from '@sib/task/shared/store';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class AbstractStoreService {
  protected readonly store = inject(Store);
  protected readonly actions$ = inject(Actions);

  protected waitForResponse<T>(body: T) {
    return this.actions$.pipe(
      ofType(loadTaskV2VariablesSuccess),
      first(),
      map(() => body),
    );
  }
}
