<mat-card>
  <mat-card-header>
    <mat-card-title>Плата за кредит</mat-card-title>
  </mat-card-header>

  <mat-card-content formGroupName="feeForLoan">
    <p>Проценти та комісії:</p>

    <div class="flow-type">
      <mat-radio-group class="mat-radio-group-global-custom" formControlName="rateType">
        <mat-radio-button value="singleSizeRate"> Встановлення єдиного розміру %-ої ставки</mat-radio-button>

        <div class="form-group">
          <mat-radio-button value="otherSizeRate">Відмінний від основного розміру %-ї ставки</mat-radio-button>

          <div class="row" *ngIf="formGroup.rateType.value === 'otherSizeRate'">
            <mat-form-field (click)="datepicker.open()">
              <mat-label>Дата</mat-label>
              <input matInput formControlName="otherDate" [matDatepicker]="datepicker" />
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Ставка</mat-label>
              <input type="number" min="0" matInput formControlName="otherRate" />
              <span matTextSuffix>%</span>

              <mat-error>Мінімальне значення 0</mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-radio-button value="increSizeRate1">
          Підвищений розмір %-ї ставки за не переведення потоків + ЗКП
        </mat-radio-button>

        <div class="form-sub-group" *ngIf="formGroup.rateType.value === 'increSizeRate1'">
          <div class="form-group">
            <mat-checkbox formControlName="condFlow">
              Порушення умов щодо забезпечення грошових надходжень
            </mat-checkbox>
            <mat-form-field *ngIf="formGroup.condFlow.value">
              <input type="number" min="0" matInput formControlName="condFlowRate" />
              <span matTextSuffix>%</span>

              <mat-error>Мінімальне значення 0</mat-error>
            </mat-form-field>
          </div>

          <div>
            <div class="form-group">
              <mat-checkbox formControlName="condSalary">
                Здійснення виплати заробітної плати загальною сумою не менше ніж
              </mat-checkbox>
              <mat-form-field *ngIf="formGroup.condSalary.value">
                <input type="number" min="0" matInput formControlName="condSalaryRate" />
                <span matTextSuffix>%</span>

                <mat-error>Мінімальне значення 0</mat-error>
              </mat-form-field>
            </div>

            <mat-radio-group
              class="mat-radio-group-global-custom form-sub-group"
              formControlName="salaryType"
              *ngIf="formGroup.condSalary.value"
            >
              <div class="form-group">
                <mat-radio-button value="monthSalary"> Щомісячно</mat-radio-button>
                <sib-currency-input *ngIf="formGroup.salaryType.value === 'monthSalary'">
                  <mat-form-field>
                    <mat-label>грн</mat-label>
                    <input type="text" matInput currencyMask formControlName="monthSalarySum" />

                    <mat-error>Мінімальне значення 0</mat-error>
                  </mat-form-field>
                </sib-currency-input>
              </div>
              <mat-radio-button value="quarterSalary"> Щоквартально</mat-radio-button>
              <div class="cash-flow" *ngIf="formGroup.salaryType.value === 'quarterSalary'">
                <sib-cash-flow-zkrs-table
                  [flowType]="'FOT_UAH'"
                  formControlName="cashFlowZKRS"
                ></sib-cash-flow-zkrs-table>
              </div>
            </mat-radio-group>
          </div>
        </div>
      </mat-radio-group>

      <mat-checkbox formControlName="payRules">
        Проценти нараховуються та сплачуються у порядку, передбаченому правилами
      </mat-checkbox>

      <mat-form-field *ngIf="!formGroup.payRules.value" style="width: 130px; margin-left: 10px">
        <mat-label>День сплати %</mat-label>
        <input matInput type="number" placeholder="Введіть день" formControlName="repaymentPerDay" />

        <mat-error> Значення має бути в проміжку від 1 до 31 </mat-error>
      </mat-form-field>

      <div>
        <mat-checkbox formControlName="isPenaltyUPS"> Штрафні санкції за прострочку (УПС) </mat-checkbox>
      </div>
    </div>
  </mat-card-content>
</mat-card>
