/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { MeasurementUnitDto } from '../models/MeasurementUnitDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MeasurementUnitService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all measurement units
     * Returns all measurement units.
     * @returns MeasurementUnitDto all measurement units
     * @throws ApiError
     */
    public getApiMeasurementUnits(): Observable<Array<MeasurementUnitDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/measurement-units',
        });
    }

    /**
     * Create measurement unit
     * Method for creating measurement unit.
     * @returns MeasurementUnitDto measurement unit created
     * @throws ApiError
     */
    public postApiMeasurementUnits({
        requestBody,
    }: {
        /**
         * Measurement unit object that needs to be created.
         */
        requestBody: MeasurementUnitDto,
    }): Observable<MeasurementUnitDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/measurement-units',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get measurement unit
     * Get measurement unit by ID.
     * @returns any measurement unit found
     * @throws ApiError
     */
    public getApiMeasurementUnits1({
        id,
    }: {
        /**
         * Measurement unit ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/measurement-units/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `measurement unit not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update measurement unit
     * Updates the measurement unit with the given ID.
     * @returns any measurement unit updated
     * @throws ApiError
     */
    public putApiMeasurementUnits({
        id,
        requestBody,
    }: {
        /**
         * Measurement unit ID. Cannot be empty.
         */
        id: string,
        /**
         * Measurement unit object that needs to be updated.
         */
        requestBody: MeasurementUnitDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/measurement-units/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete measurement unit
     * Deletes the measurement unit with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiMeasurementUnits({
        id,
    }: {
        /**
         * Measurement unit ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/measurement-units/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
