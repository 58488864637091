<mat-table [dataSource]="matTableDataSource" [trackBy]="trackByName">
  <ng-container matColumnDef="name">
    <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="value">
    <mat-cell *matCellDef="let element">{{ element.value }}</mat-cell>
  </ng-container>

  <mat-row *matRowDef="let row; columns: ['name', 'value']"></mat-row>
</mat-table>
