import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Land } from '@api/loan-approval';

const landFeatureKey = 'land';

export type LandState = EntityState<Land>;

export const adapter = createEntityAdapter<Land>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.land || [], state)),
);

export const landFeature = createFeature({
  name: landFeatureKey,
  reducer,
  extraSelectors: ({ selectLandState }) => ({
    ...adapter.getSelectors(selectLandState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = landFeature;
