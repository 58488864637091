/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RiskSegmentDto } from '../models/RiskSegmentDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RiskSegmentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all risk segments
     * Returns all risk segments.
     * @returns RiskSegmentDto all risk segments
     * @throws ApiError
     */
    public getApiRiskSegments(): Observable<Array<RiskSegmentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/risk-segments',
        });
    }

    /**
     * Create risk segment
     * Method for creating risk segment.
     * @returns RiskSegmentDto risk segments created
     * @throws ApiError
     */
    public postApiRiskSegments({
        requestBody,
    }: {
        /**
         * Risk segment object that needs to be created.
         */
        requestBody: RiskSegmentDto,
    }): Observable<RiskSegmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/risk-segments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get risk segment
     * Get risk segment by ID.
     * @returns any risk segment found
     * @throws ApiError
     */
    public getApiRiskSegments1({
        id,
    }: {
        /**
         * Risk segment ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/risk-segments/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `risk segment not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update risk segment
     * Updates the risk segment with the given ID.
     * @returns any risk segment updated
     * @throws ApiError
     */
    public putApiRiskSegments({
        id,
        requestBody,
    }: {
        /**
         * Risk segment ID. Cannot be empty.
         */
        id: number,
        /**
         * Risk segment object that needs to be updated.
         */
        requestBody: RiskSegmentDto,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/risk-segments/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete risk segment
     * Deletes the risk segment with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRiskSegments({
        id,
    }: {
        /**
         * Risk segment ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/risk-segments/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
