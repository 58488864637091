/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssessmentKindDto } from '../models/AssessmentKindDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssessmentKindService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all assessment kinds
     * Returns all assessment kinds.
     * @returns AssessmentKindDto all assessment kinds
     * @throws ApiError
     */
    public getApiAssessmentKinds(): Observable<Array<AssessmentKindDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assessment-kinds',
        });
    }

    /**
     * Create an assessment kind
     * Method for creating an assessment kind.
     * @returns AssessmentKindDto Assessment kind created
     * @throws ApiError
     */
    public postApiAssessmentKinds({
        requestBody,
    }: {
        /**
         * Assessment kind object that needs to be created.
         */
        requestBody: AssessmentKindDto,
    }): Observable<AssessmentKindDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/assessment-kinds',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get assessment kind
     * Get assessment kind by description.
     * @returns any assessment kind found
     * @throws ApiError
     */
    public getApiAssessmentKindsDescription({
        descr,
    }: {
        /**
         * Assessment kind description.  Cannot be empty.
         */
        descr: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assessment-kinds/description/{descr}',
            path: {
                'descr': descr,
            },
            errors: {
                404: `assessment kind not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get assessment kind
     * Get assessment kind by ID.
     * @returns any assessment kind found
     * @throws ApiError
     */
    public getApiAssessmentKinds1({
        id,
    }: {
        /**
         * Assessment kind ID.  Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assessment-kinds/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `assessment kind not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update an assessment kind
     * Updates the assessment kind with the given ID.
     * @returns any assessment kind updated
     * @throws ApiError
     */
    public putApiAssessmentKinds({
        id,
        requestBody,
    }: {
        /**
         * Assessment kind ID.  Cannot be empty.
         */
        id: string,
        /**
         * Assessment kind object that needs to be updated.
         */
        requestBody: AssessmentKindDto,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/assessment-kinds/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete assessment kind
     * Deletes the assessment kind with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiAssessmentKinds({
        id,
    }: {
        /**
         * Assessment kind ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/assessment-kinds/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
