/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CheckupDto } from '../models/CheckupDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CheckupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all checkups or by list of ids
     * Returns all checkups or by list of ids.
     * @returns CheckupDto all checkups
     * @throws ApiError
     */
    public getApiCheckups({
        ids,
    }: {
        /**
         * List of ids
         */
        ids?: any[],
    }): Observable<Array<CheckupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/checkups',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * Create a checkup
     * Method for creating a checkup.
     * @returns any checkup created
     * @throws ApiError
     */
    public postApiCheckups({
        requestBody,
    }: {
        /**
         * Checkup object that needs to be created.
         */
        requestBody: CheckupDto,
    }): Observable<{
        id?: string;
        title?: string;
        result?: string;
        checkId?: string;
        blockCheck?: boolean;
        controlGroup?: number;
        resultGroup?: string;
        productCategories?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/checkups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get checkup
     * Get checkup by ID.
     * @returns any checkup found
     * @throws ApiError
     */
    public getApiCheckups1({
        id,
    }: {
        /**
         * Checkup ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id?: string;
        title?: string;
        result?: string;
        checkId?: string;
        blockCheck?: boolean;
        controlGroup?: number;
        resultGroup?: string;
        productCategories?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/checkups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `checkup not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update a checkup
     * Updates the checkup.
     * @returns any checkup updated
     * @throws ApiError
     */
    public putApiCheckups({
        id,
        requestBody,
    }: {
        /**
         * Checkup ID. Cannot be empty.
         */
        id: string,
        /**
         * Checkup object that needs to be updated.
         */
        requestBody: CheckupDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/checkups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete checkup
     * Deletes the checkup with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiCheckups({
        id,
    }: {
        /**
         * Checkup ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/checkups/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Update the product categories of a checkup
     * Method for updating the product categories of a checkup.
     * @returns void
     * @throws ApiError
     */
    public postApiCheckupsProductCategories({
        catId,
        id,
    }: {
        /**
         * Product category ID. Cannot be empty.
         */
        catId: number,
        /**
         * Checkup ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/checkups/{id}/product-categories/{catId}',
            path: {
                'catId': catId,
                'id': id,
            },
        });
    }

    /**
     * Delete a product category from a checkup
     * Method for deleting a product category from a checkup.
     * @returns void
     * @throws ApiError
     */
    public deleteApiCheckupsProductCategories({
        catId,
        id,
    }: {
        /**
         * Product category ID. Cannot be empty.
         */
        catId: number,
        /**
         * Checkup ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/checkups/{id}/product-categories/{catId}',
            path: {
                'catId': catId,
                'id': id,
            },
        });
    }

}
