import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/core/store';
import { AuthService } from '@sib/shared/da';
import { MatDialog } from '@angular/material/dialog';
import { getErrorByStatus, Unauthorized } from '@sib/shared/util';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store, private auth: AuthService, private dialog: MatDialog) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const body = getErrorByStatus(error);
        if (body instanceof Unauthorized) {
          this.store.dispatch(fromRoot.logoutSuccess());
          this.auth.logout();
        }

        return throwError({
          ...(error.error ? error.error : { error: error }),
          body: body,
          error: body,
          status: error.status,
          statusText: error.statusText,
        });
      }),
    );
  }
}
