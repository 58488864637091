<div class="dialog">
  <header class="dialog__header">
    <h3 class="dialog__title blue">Поручителі</h3>
    <button class="dialog__header-button" (click)="onCloseDialog()">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.2832 4.29785L14.3866 14.4013" stroke="#828282" stroke-width="1.5" stroke-linecap="round" />
        <path d="M4.2832 14.4014L14.3866 4.29794" stroke="#828282" stroke-width="1.5" stroke-linecap="round" />
      </svg>
      Закрити
    </button>
  </header>
  <div class="dialog__body">
    <form class="form" [formGroup]="guarantorFrom">
      <div class="form-box">
        <table class="primary-table">
          <thead class="primary-table__thead">
            <tr class="primary-table__thead-tr">
              <td class="primary-table__thead-td"></td>
              <td class="primary-table__thead-td">Код БП</td>
              <td class="primary-table__thead-td">Назва БП</td>
            </tr>
          </thead>
          <tbody class="primary-table__tbody">
            <tr class="primary-table__tbody-tr" *ngFor="let row of guarantors">
              <td class="primary-table__tbody-td dot">
                <input
                  type="radio"
                  id="guarantor-{{ row.id }}"
                  name="bpCode"
                  [value]="row.bpCode"
                  formControlName="bpCode"
                />
              </td>
              <td class="primary-table__tbody-td">
                {{ row.bpCode }}
              </td>
              <td class="primary-table__tbody-td">
                {{ row.orgName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form__action">
        <button class="form__button button-outline" (click)="onCloseDialog()">
          <svg class="button-outline__icon">
            <use xlink:href="/assets/sprites/sprite.svg#back-arrow"></use>
          </svg>
          Назад
        </button>
        <button
          class="form__button button-primary button-success"
          type="button"
          [disabled]="!guarantorFrom.valid"
          (click)="onSubmitDialog()"
        >
          <svg class="button-primary__icon">
            <use xlink:href="/assets/sprites/sprite.svg#checkbox-outline"></use>
          </svg>
          Прив'язати
        </button>
      </div>
    </form>
  </div>
</div>
