import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { DocumentaryOperation } from '@api/loan-approval';

const documentaryOperationsFeatureKey = 'documentaryOperations';

export type DocumentaryOperationsFeatureKeyState = EntityState<DocumentaryOperation>;

export const adapter = createEntityAdapter<DocumentaryOperation>({ selectId: (item) => item.agreementId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) =>
    adapter.setAll(variables.documentaryOperations || [], state),
  ),
);

export const documentaryOperationsFeature = createFeature({
  name: documentaryOperationsFeatureKey,
  reducer,
  extraSelectors: ({ selectDocumentaryOperationsState }) => ({
    ...adapter.getSelectors(selectDocumentaryOperationsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = documentaryOperationsFeature;
