import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as RolesActions from './roles.actions';
import * as RolesSelectors from './roles.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class RolesEffects {
  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.loadRoles),
      concatLatestFrom((action) => [this.store$.select(RolesSelectors.selectAllRoles)]),
      switchMap(([action, allRoles]) =>
        (allRoles.length ? of(allRoles) : this.dictionariesService.loadRoles()).pipe(
          map((response) =>
            RolesActions.loadRolesSuccess({
              roles: response,
            }),
          ),
          catchError((error) => of(RolesActions.loadRolesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
