import { Injectable } from '@angular/core';
import { setTaskV2ProcessVariable } from '@sib/task/shared/store';
import { AutoCheck } from '@api/loan-approval';
import { AbstractStoreService } from './abstract-store.service';

@Injectable({ providedIn: 'root' })
export class VariableService extends AbstractStoreService {
  updateApplicationGroupName(applicationGroupName: string) {
    this.store.dispatch(
      setTaskV2ProcessVariable({
        requestBody: {
          applicationGroupName,
        },
      }),
    );

    return this.waitForResponse(applicationGroupName);
  }

  updateAutoChecks(autoChecks: AutoCheck[]) {
    this.store.dispatch(
      setTaskV2ProcessVariable({
        requestBody: {
          autoChecks,
        },
      }),
    );

    return this.waitForResponse(autoChecks);
  }
}
