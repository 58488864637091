import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRegions, selectRegionsEntities } from './regions.selectors';
import { loadRegions } from './regions.actions';

@Injectable({
  providedIn: 'root',
})
export class RegionsFacadeService {
  public regions$ = this.store.select(selectRegions);
  public regionsEntities$ = this.store.select(selectRegionsEntities);

  constructor(private store: Store) {}

  public loadRegions() {
    this.store.dispatch(loadRegions());
  }
}
