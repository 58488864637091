import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[sibDebounceClick]',
  standalone: true,
})
export class DebounceClickDirective implements OnInit {
  @Output() debounceClick = new EventEmitter<any>();
  private clicks = new Subject();

  ngOnInit() {
    this.clicks.pipe(debounceTime(300), untilDestroyed(this)).subscribe((event: any) => this.debounceClick.emit(event));
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
