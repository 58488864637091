import { DestroyRef, Directive, ElementRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[sibDebouncedInputValue]',
  standalone: true,
})
export class DebouncedInputValueDirective implements OnInit {
  @Output('sibDebouncedInputValue') valueChange = new EventEmitter<string>();
  destroyRef = inject(DestroyRef);

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        debounceTime(1000),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((event: any) => event.target.value.trim()),
        distinctUntilChanged(),
        filter((inputValue: string) => !!inputValue),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((inputValue: string) => this.valueChange.emit(inputValue));
  }
}
