import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectGuarantorAgreementRelation } from '../../+state/variables/variables.selectors';

@Injectable({
  providedIn: 'root',
})
export class GuarantorAgreementRelationFacadeService {
  private store = inject(Store);

  getAllGuarantorAgreementRelation$ = this.store.select(selectGuarantorAgreementRelation);
}
