<h3 mat-dialog-title>
  <ng-content select="[title]"></ng-content>
</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <ng-content></ng-content>
</div>

<div mat-dialog-actions>
  <ng-content select="[actions]"></ng-content>
</div>
