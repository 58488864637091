import { createSelector } from '@ngrx/store';
import { authFeature } from './auth.reducer';

export const TECH_ROLES = [
  'task_reassignment',
  'system_user',
  'application_cancellation',
  'change_responsible',
  'dictionary_admin',
  'request_list_access',
  'doc_block',
  'request_list_tasks',
  'valuation_list_tasks',
  'valuation_list_tasks',
  'agro_accountable',
];

export const selectCurrentUser = authFeature.selectUser;
export const isLoading = authFeature.selectIsLoading;

export const selectUserGroups = createSelector(selectCurrentUser, (user) => user?.groups || []);

export const checkUserPermission = (permission: string) =>
  createSelector(selectUserGroups, (groups: string[]) => groups.includes(permission));

export const selectJson = createSelector(selectCurrentUser, (user) => user?.json);

export const selectLogin = createSelector(selectCurrentUser, (user) => user?.login);

export const selectId = createSelector(selectCurrentUser, (user) => user?.id);

export const selectUserRoles = createSelector(selectUserGroups, (userGroups) =>
  userGroups.filter((i) => TECH_ROLES.every((item) => item !== i)),
);
