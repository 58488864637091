import { createAction, props } from '@ngrx/store';
import { MeasurementUnitDto } from '@api/dictionaries';

export const loadMeasurementUnits = createAction('[Dictionaries/API] Load Measurement Units');
export const loadMeasurementUnitsSuccess = createAction(
  '[Dictionaries/API] Load Measurement Units Success',
  props<{ measurementUnits: MeasurementUnitDto[] }>(),
);
export const loadMeasurementUnitsFailure = createAction(
  '[Dictionaries/API] Load Measurement Units Failure',
  props<{ error: unknown }>(),
);
