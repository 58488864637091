import { createReducer, on } from '@ngrx/store';
import * as MonitoringResults from './monitoring-results.actions';
import { MonitoringResultDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const monitoringResultsFeatureKey = 'monitoring-results';

export interface State extends EntityState<MonitoringResultDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<MonitoringResultDto>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(MonitoringResults.loadMonitoringResults, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(MonitoringResults.loadMonitoringResultsSuccess, (state, { monitoringResults }) => ({
    ...adapter.setAll(monitoringResults, state),
    isLoading: false,
  })),
  on(MonitoringResults.loadMonitoringResultsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectMonitoringResultsState = selectAll;
