import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectActiveAssetTypes, selectAssetTypesAll, selectAssetTypesEntities } from './asset-types.selectors';
import { loadAssetTypes } from './asset-types.actions';

@Injectable({
  providedIn: 'root',
})
export class AssetTypesFacadeService {
  public selectAllAssetTypes$ = this.store.select(selectAssetTypesAll);
  public assetTypesEntities$ = this.store.select(selectAssetTypesEntities);
  public assetActiveTypes$ = this.store.select(selectActiveAssetTypes);

  constructor(private store: Store) {}

  public loadAssetTypes() {
    this.store.dispatch(loadAssetTypes());
  }
}
