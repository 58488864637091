/* 🤖 this file was generated by svg-to-ts */

export type icon =
  | 'account_group'
  | 'account_multiple_check'
  | 'account_switch'
  | 'add_note_plus'
  | 'animation_outline'
  | 'arrow_bottom_right_bold_box_outline'
  | 'arrow_top_right'
  | 'arrow_up_circle_outline'
  | 'auto_fix'
  | 'back_arrow'
  | 'backspace_reverse_outline'
  | 'calendar_cursor'
  | 'calendar_today'
  | 'change'
  | 'check_decagram'
  | 'checkbox_marked'
  | 'checkbox_outline'
  | 'close_primary'
  | 'close_secondary'
  | 'close'
  | 'cog_outline'
  | 'content_copy'
  | 'content_save'
  | 'delete'
  | 'desktop_mac'
  | 'dots_horizontal_circle_outline'
  | 'edit_pencil'
  | 'eye'
  | 'file_document_outline'
  | 'file_documents'
  | 'filter'
  | 'graph'
  | 'help'
  | 'hire'
  | 'history'
  | 'lock'
  | 'message_reply_text'
  | 'minus'
  | 'package_down'
  | 'refresh'
  | 'search'
  | 'slash_eye'
  | 'sort_bool_ascending'
  | 'tacks'
  | 'text_box_plus_outline'
  | 'tune_variant'
  | 'view';
export interface Icon {
  name: icon;
  data: string;
}
