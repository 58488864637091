/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AnnulmentTypeDto } from '../models/AnnulmentTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AnnulmentTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all annulment types
     * Returns all annulment types.
     * @returns AnnulmentTypeDto all annulment types.
     * @throws ApiError
     */
    public getApiAnnulmentsAnnulmentTypes(): Observable<Array<AnnulmentTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/annulments/annulment-types',
        });
    }

    /**
     * Create an annulment type
     * Method for creating an annulment type.
     * @returns AnnulmentTypeDto annulment type created
     * @throws ApiError
     */
    public postApiAnnulmentsAnnulmentTypes({
        requestBody,
    }: {
        /**
         * Annulment type object that needs to be created.
         */
        requestBody: AnnulmentTypeDto,
    }): Observable<AnnulmentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/annulments/annulment-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get annulment types
     * Get annulment type by title.
     * @returns AnnulmentTypeDto annulment type found
     * @throws ApiError
     */
    public getApiAnnulmentsAnnulmentTypesGetByTitle({
        title,
    }: {
        /**
         * Title. Cannot be empty.
         */
        title: string,
    }): Observable<AnnulmentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/annulments/annulment-types/get-by-title/{title}',
            path: {
                'title': title,
            },
            errors: {
                404: `annulment type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get annulment types
     * Get annulment type by reason ID.
     * @returns AnnulmentTypeDto annulment type found
     * @throws ApiError
     */
    public getApiAnnulmentsAnnulmentTypesGetByReasonId({
        reasonId,
    }: {
        /**
         * Reason ID.  Cannot be empty.
         */
        reasonId: string,
    }): Observable<Array<AnnulmentTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/annulments/annulment-types/getByReasonId',
            query: {
                'reasonId': reasonId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get annulment type
     * Get annulment type by ID.
     * @returns AnnulmentTypeDto annulment type found
     * @throws ApiError
     */
    public getApiAnnulmentsAnnulmentTypes1({
        id,
    }: {
        /**
         * Annulment type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<AnnulmentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/annulments/annulment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `annulment type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update an annulment type
     * Updates the annulment type with the given ID.
     * @returns AnnulmentTypeDto annulment type updated
     * @throws ApiError
     */
    public putApiAnnulmentsAnnulmentTypes({
        id,
        requestBody,
    }: {
        /**
         * Annulment type ID.  Cannot be empty.
         */
        id: number,
        /**
         * Annulment type object that needs to be updated.
         */
        requestBody: AnnulmentTypeDto,
    }): Observable<AnnulmentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/annulments/annulment-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete annulment type
     * Deletes the annulment type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiAnnulmentsAnnulmentTypes({
        id,
    }: {
        /**
         * Annulment type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/annulments/annulment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
