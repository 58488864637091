export const getSignatories = (data: {
  pledgerContract?: {
    partnerName?: string;
    ownerBpCode?: string;
    fop?: boolean;
    ownSign?: boolean;
    bpNumber?: string;
    partnerNameSign?: string;
    agent?: boolean;
    position?: string;
    doc?: string;
  };
  requisitesContract?: {
    addressLetter?: string;
    addressLocation?: string;
    phoneFax?: string;
    passpDoc?: string;
    addressLetterBank?: string;
    phoneFaxBank?: string;
    email?: string;
    emailBank?: string;
  };
  bpHolders?: {
    bpNumber?: string;
    partnerName?: string;
    position?: string;
    signatory?: {
      roleTitle?: string;
      partner?: string;
      partnerType?: string;
      partnerName?: string;
      partnerSign?: string;
      partnerNameSign?: string;
      ownSign?: boolean;
      agent?: boolean;
      position?: string;
      doc?: string;
      addressLocation?: string;
      addressLetter?: string;
      phoneFax?: string;
      id?: string;
      num?: string;
      date?: string;
      notaryCirc?: string;
      notaryName?: string;
      passportData?: string;
      email?: string;
      swiftCode?: string;
    } | null;
  };
}) => [
  {
    roleTitle: 'CLIENT',
    ownSign: data.pledgerContract?.ownSign || false,
    partner: data.pledgerContract?.ownerBpCode,
    partnerSign: data.pledgerContract?.bpNumber,
    partnerName: data.pledgerContract?.partnerName,
    partnerNameSign: data.pledgerContract?.partnerNameSign,
    agent: data.pledgerContract?.agent || false,
    position: data.pledgerContract?.position,
    doc: data.pledgerContract?.doc,
    addressLetter: data.requisitesContract?.addressLetter,
    addressLocation: data.requisitesContract?.addressLocation,
    phoneFax: data.requisitesContract?.phoneFax,
    passportData: data.requisitesContract?.passpDoc,
    email: data.requisitesContract?.email,
  },
  {
    roleTitle: 'BANK',
    partner: data.bpHolders?.bpNumber,
    partnerNameSign: data.bpHolders?.partnerName,
    partnerName: data.bpHolders?.partnerName,
    position: data.bpHolders?.position,
    num: data.bpHolders?.signatory?.num,
    date: data.bpHolders?.signatory?.date,
    notaryCirc: data.bpHolders?.signatory?.notaryCirc,
    notaryName: data.bpHolders?.signatory?.notaryName,
    addressLetter: data.requisitesContract?.addressLetterBank,
    phoneFax: data.requisitesContract?.phoneFaxBank,
    partnerSign: data.bpHolders?.bpNumber,
    agent: data.bpHolders?.signatory?.agent || false,
    ownSign: data.bpHolders?.signatory?.ownSign || false,
  },
];
