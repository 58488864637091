import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectDeferredConditions,
  selectProtocolCC,
  selectRateApproval,
  selectVotingComments,
} from '../../+state/variables/variables.selectors';
import { selectProtocolDate, selectVotingCommentsView, selectVotingList } from './protocol-cc.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProtocolCCFacadeService {
  private store = inject(Store);

  selectProtocolCC$ = this.store.select(selectProtocolCC);
  selectDeferredConditions$ = this.store.select(selectDeferredConditions);
  selectVotingComments$ = this.store.select(selectVotingComments);
  selectVotingCommentsView$ = this.store.select(selectVotingCommentsView);
  selectVotingList$ = this.store.select(selectVotingList);
  selectProtocolDate$ = this.store.select(selectProtocolDate);
  selectRateApproval$ = this.store.select(selectRateApproval);
}
