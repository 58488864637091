import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as LawCaseTypesActions from './law-case-types.actions';
import { CourtCasesTypeDto } from '@api/dictionaries';

export const lawCaseTypesFeatureKey = 'law-case-types';

export interface State extends EntityState<CourtCasesTypeDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<CourtCasesTypeDto> = createEntityAdapter<CourtCasesTypeDto>({
  selectId: (lawCaseType: CourtCasesTypeDto) => lawCaseType.casesType,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(LawCaseTypesActions.loadLawCaseTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(LawCaseTypesActions.loadLawCaseTypesSuccess, (state, { lawCaseTypes }) => ({
    ...adapter.setAll(lawCaseTypes, state),
    isLoading: false,
  })),

  on(LawCaseTypesActions.loadLawCaseTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
