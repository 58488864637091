/* Підключення service-layer використання бібліотеки "ІІТ Користувач ЦСК Підпис (web)" */
import { EuswSL } from './euswsl';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '@sib/shared/ui';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '@sib/shared/util';

// enum from js library eusw.js
/* eslint-disable-next-line */
declare let EndUserLibraryLoader: any;

EuswSL.isDebug = true;
EuswSL.isUiMode = true;

// EuswSL.EU_LIBRARY_TYPE = EndUserLibraryLoader.LIBRARY_TYPE_DEFAULT;

@Injectable({ providedIn: 'root' })
export class EcpService {
  constructor(private matDialog: MatDialog) {}

  getSign(data: string | ArrayBuffer) {
    return this.isEcpLoaded().pipe(
      switchMap(
        () =>
          new Observable((observer) => {
            EuswSL.getSign(
              data,
              (result: string) => observer.next(result),
              (error: string) => observer.error(error),
            );
          }),
      ),
      catchError((error) => {
        this.showErrorNotification(error);
        return of(undefined);
      }),
    );
  }

  verifySign(ecp: string, documentBlob: string) {
    return this.isEcpLoaded().pipe(
      switchMap(
        () =>
          new Observable((observer) => {
            EuswSL.verifySign(
              ecp,
              documentBlob,
              (result: string) => observer.next(result),
              (error: string) => observer.error(error),
            );
          }),
      ),
      catchError((error) => {
        this.showErrorNotification(error);
        return of(undefined);
      }),
    );
  }

  initEcp() {
    return this.isEcpLoaded();
  }

  private loadEcp() {
    return environment.envType !== 'local'
      ? new Promise((resolve, reject) => {
          EuswSL.load(resolve, reject);
        }).catch((err) => {
          this.showErrorNotification(err);
        })
      : Promise.resolve();
  }

  private isEcpLoaded() {
    return of(EuswSL.isLoaded).pipe(switchMap((isLoaded) => (isLoaded ? of(true) : this.loadEcp())));
  }

  private showErrorNotification(err: string) {
    if (environment.envType !== 'local') {
      this.matDialog.open(InfoDialogComponent, {
        data: {
          description: err,
        },
      });
    }
  }
}
