import { createSelector } from '@ngrx/store';
import * as fromConditionTypes from './condition-types.reducer';
import { selectConditionTypesState } from '../+shell/reducers.index';

export const selectConditionTypesIsLoading = createSelector(
  selectConditionTypesState,
  fromConditionTypes.selectIsLoading,
);

export const selectConditionTypes = createSelector(selectConditionTypesState, fromConditionTypes.selectAll);
export const selectConditionTypesEntities = createSelector(
  selectConditionTypesState,
  fromConditionTypes.selectEntities,
);
