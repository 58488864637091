import { createAction, props } from '@ngrx/store';
import { VariablesDto } from '@api/loan-approval';

export const setTaskV2ProcessVariable = createAction(
  '[TaskV2/API] Set Process Variable',
  props<{ requestBody: VariablesDto }>(),
);

export const loadTaskV2VariablesSuccess = createAction(
  '[TaskV2/API] Load Task Success',
  props<{ variables: VariablesDto }>(),
);

export const deleteTaskV2ProcessVariable = createAction(
  '[TaskV2/API] Delete Process Variable',
  props<{
    requestBody: VariablesDto;
  }>(),
);

export const resetVariables = createAction('[TaskV2/API] Reset Variables');
