/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ReportRoleDto } from '../models/ReportRoleDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ReportRoleService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all report roles
     * Returns all report roles.
     * @returns ReportRoleDto all report roles
     * @throws ApiError
     */
    public getApiReportRoles(): Observable<Array<ReportRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/report-roles',
        });
    }

    /**
     * Create report role
     * Method for creating report role.
     * @returns ReportRoleDto report role created
     * @throws ApiError
     */
    public postApiReportRoles({
        requestBody,
    }: {
        /**
         * Report role object that needs to be created.
         */
        requestBody: ReportRoleDto,
    }): Observable<ReportRoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/report-roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get report role
     * Get report role by ID.
     * @returns any report role found
     * @throws ApiError
     */
    public getApiReportRolesReportIdRoleId({
        reportId,
        roleId,
    }: {
        /**
         * Report ID. Cannot be empty.
         */
        reportId: string,
        /**
         * Role ID. Cannot be empty.
         */
        roleId: string,
    }): Observable<{
        reportId: string;
        roleId: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/report-roles/reportId-roleId/{reportId}/{roleId}',
            path: {
                'reportId': reportId,
                'roleId': roleId,
            },
            errors: {
                404: `report role not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete report role
     * Deletes the report role with the given report ID and role ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiReportRolesReportIdRoleId({
        reportId,
        roleId,
    }: {
        /**
         * Report ID. Cannot be empty.
         */
        reportId: string,
        /**
         * Role ID. Cannot be empty.
         */
        roleId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/report-roles/reportId-roleId/{reportId}/{roleId}',
            path: {
                'reportId': reportId,
                'roleId': roleId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get all report role
     * Get all report role by report ID.
     * @returns ReportRoleDto report role found
     * @throws ApiError
     */
    public getApiReportRolesReports({
        reportId,
    }: {
        /**
         * Report ID. Cannot be empty.
         */
        reportId: string,
    }): Observable<Array<ReportRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/report-roles/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get all report role
     * Get all report role by role ID.
     * @returns ReportRoleDto report role found
     * @throws ApiError
     */
    public getApiReportRolesRoles({
        roleId,
    }: {
        /**
         * Role ID. Cannot be empty.
         */
        roleId: string,
    }): Observable<Array<ReportRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/report-roles/roles/{roleId}',
            path: {
                'roleId': roleId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
