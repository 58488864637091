import { createAction, props } from '@ngrx/store';
import { CategoryTypeDto } from '@api/dictionaries';

export const loadProductCategoryTypes = createAction('[Dictionaries/API] Load Product Category Types');
export const loadProductCategoryTypesSuccess = createAction(
  '[Dictionaries/API] Load Product Category Types Success',
  props<{ productCategoryTypes: CategoryTypeDto[] }>(),
);
export const loadProductCategoryTypesFailure = createAction(
  '[Dictionaries/API] Load Product Category Types Failure',
  props<{ error: unknown }>(),
);
