/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RequestTypeDto } from '../models/RequestTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RequestTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all request types
     * Returns all request types.
     * @returns RequestTypeDto all request types
     * @throws ApiError
     */
    public getApiRequestTypes(): Observable<Array<RequestTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/request-types',
        });
    }

    /**
     * Create request type
     * Method for creating request type.
     * @returns RequestTypeDto request type created
     * @throws ApiError
     */
    public postApiRequestTypes({
        requestBody,
    }: {
        /**
         * Request type object that needs to be created.
         */
        requestBody: RequestTypeDto,
    }): Observable<RequestTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/request-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get request type
     * Get request type by ID.
     * @returns any request type found
     * @throws ApiError
     */
    public getApiRequestTypes1({
        id,
    }: {
        /**
         * Request type ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id?: string;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/request-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `request type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update request type
     * Updates the request type with the given ID.
     * @returns any request type updated
     * @throws ApiError
     */
    public putApiRequestTypes({
        id,
        requestBody,
    }: {
        /**
         * Request type ID. Cannot be empty.
         */
        id: string,
        /**
         * Request type object that needs to be updated.
         */
        requestBody: RequestTypeDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/request-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete request type
     * Deletes the request type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRequestTypes({
        id,
    }: {
        /**
         * Request type ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/request-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
