import { createSelector } from '@ngrx/store';
import * as fromObligationTypes from './obligation-types.reducer';
import { convertArrayToObjectTyped } from '@sib/shared/util';
import { selectObligationTypesState } from '../+shell/reducers.index';

export const selectIsLoadingObligationTypes = createSelector(
  selectObligationTypesState,
  fromObligationTypes.selectIsLoading,
);

export const selectObligationTypes = createSelector(selectObligationTypesState, fromObligationTypes.selectAll);

export const selectObligationTypesEntities = createSelector(selectObligationTypes, (obligationTypes) =>
  convertArrayToObjectTyped(obligationTypes, 'id'),
);

export const selectObligationTypeById = (obligationTypeId: string) =>
  createSelector(selectObligationTypesEntities, (obligationTypesEntities) => obligationTypesEntities[obligationTypeId]);
