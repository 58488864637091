import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Signator } from '@api/loan-approval';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BpHoldersGroupService extends FormGroup<ReturnType<typeof BpHoldersGroupService.formGroup>> {
  private static readonly formGroup = () => ({
    bpNumber: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }), // код бп (підписант)
    partnerName: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ), // назва-піб (називний відмінок)
    position: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }), // посада
    signatory: new FormControl<Signator | null>(null, { validators: [Validators.required], nonNullable: true }),
  });

  private setSignatorySource = new BehaviorSubject<Signator | undefined>(undefined);
  public setSignatory$ = this.setSignatorySource.asObservable();

  constructor() {
    super(BpHoldersGroupService.formGroup());
  }

  public setSignatory(signatory: Signator) {
    this.setSignatorySource.next(signatory);
  }

  public get bpNumber() {
    return this.controls.bpNumber;
  }
}
