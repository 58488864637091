import { createAction, props } from '@ngrx/store';
import { RiskSegmentDto } from '@api/dictionaries';

export const loadRiskSegments = createAction('[Dictionaries/API] Load RiskSegments');
export const loadRiskSegmentsSuccess = createAction(
  '[Dictionaries/API] Load RiskSegments Success',
  props<{ riskSegments: RiskSegmentDto[] }>(),
);
export const loadRiskSegmentsFailure = createAction(
  '[Dictionaries/API] Load RiskSegments Failure',
  props<{ error: unknown }>(),
);
