import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[sibScrollToErrorControl]',
  standalone: true,
})
export class ScrollToErrorControlDirective {
  constructor(private elementRef: ElementRef, private formGroupDir: FormGroupDirective) {}

  @HostListener('ngSubmit') onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector('.ng-invalid');

    firstInvalidControl &&
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });

    firstInvalidControl &&
      fromEvent(window, 'scroll')
        .pipe(debounceTime(100), take(1), untilDestroyed(this))
        .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
