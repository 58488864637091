/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LocationAkdDto } from '../models/LocationAkdDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LocationAkdService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all locations akd
     * Returns all locations akd.
     * @returns LocationAkdDto all locations akd
     * @throws ApiError
     */
    public getApiDocumentsLocationAkd(): Observable<Array<LocationAkdDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/location-akd',
        });
    }

    /**
     * Create location akd
     * Method for creating an location akd.
     * @returns LocationAkdDto location akd created
     * @throws ApiError
     */
    public postApiDocumentsLocationAkd({
        requestBody,
    }: {
        /**
         * Location akd object that needs to be created.
         */
        requestBody: LocationAkdDto,
    }): Observable<LocationAkdDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/documents/location-akd',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get location akd
     * Get location akd by code.
     * @returns any location akd found
     * @throws ApiError
     */
    public getApiDocumentsLocationAkdGetByCode({
        code,
    }: {
        /**
         * Location akd code. Cannot be empty.
         */
        code: string,
    }): Observable<{
        id: number;
        codeFolder?: string;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/location-akd/get-by-code/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `location akd not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get location akd
     * Get location akd by ID.
     * @returns any location akd found
     * @throws ApiError
     */
    public getApiDocumentsLocationAkd1({
        id,
    }: {
        /**
         * Location akd ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        codeFolder?: string;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/location-akd/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `location akd not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update location akd
     * Updates the location akd with the given ID.
     * @returns any location akd updated
     * @throws ApiError
     */
    public putApiDocumentsLocationAkd({
        id,
        requestBody,
    }: {
        /**
         * Location akd ID. Cannot be empty.
         */
        id: number,
        /**
         * Location akd object that needs to be updated.
         */
        requestBody: LocationAkdDto,
    }): Observable<{
        id: number;
        codeFolder?: string;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/documents/location-akd/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete location akd
     * Deletes the location akd with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDocumentsLocationAkd({
        id,
    }: {
        /**
         * Location akd ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/documents/location-akd/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
