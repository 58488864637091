/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ActivityInstanceDto } from '../models/ActivityInstanceDto';
import type { AnnotationDto } from '../models/AnnotationDto';
import type { AttachmentDto } from '../models/AttachmentDto';
import type { AuthenticationResult } from '../models/AuthenticationResult';
import type { AuthorizationCheckResultDto } from '../models/AuthorizationCheckResultDto';
import type { AuthorizationCreateDto } from '../models/AuthorizationCreateDto';
import type { AuthorizationDto } from '../models/AuthorizationDto';
import type { BasicUserCredentialsDto } from '../models/BasicUserCredentialsDto';
import type { BatchStatisticsDto } from '../models/BatchStatisticsDto';
import type { CalledProcessDefinitionDto } from '../models/CalledProcessDefinitionDto';
import type { CaseDefinitionDiagramDto } from '../models/CaseDefinitionDiagramDto';
import type { CaseDefinitionDto } from '../models/CaseDefinitionDto';
import type { CaseExecutionDto } from '../models/CaseExecutionDto';
import type { CaseExecutionQueryDto } from '../models/CaseExecutionQueryDto';
import type { CaseExecutionTriggerDto } from '../models/CaseExecutionTriggerDto';
import type { CleanableHistoricBatchReportResultDto } from '../models/CleanableHistoricBatchReportResultDto';
import type { CleanableHistoricCaseInstanceReportResultDto } from '../models/CleanableHistoricCaseInstanceReportResultDto';
import type { CleanableHistoricDecisionInstanceReportResultDto } from '../models/CleanableHistoricDecisionInstanceReportResultDto';
import type { CleanableHistoricProcessInstanceReportResultDto } from '../models/CleanableHistoricProcessInstanceReportResultDto';
import type { CommentDto } from '../models/CommentDto';
import type { CompleteExternalTaskDto } from '../models/CompleteExternalTaskDto';
import type { CompleteTaskDto } from '../models/CompleteTaskDto';
import type { CorrelationMessageAsyncDto } from '../models/CorrelationMessageAsyncDto';
import type { CorrelationMessageDto } from '../models/CorrelationMessageDto';
import type { CountResultDto } from '../models/CountResultDto';
import type { CreateCaseInstanceDto } from '../models/CreateCaseInstanceDto';
import type { CreateIncidentDto } from '../models/CreateIncidentDto';
import type { DecisionDefinitionDiagramDto } from '../models/DecisionDefinitionDiagramDto';
import type { DecisionDefinitionDto } from '../models/DecisionDefinitionDto';
import type { DecisionRequirementsDefinitionDto } from '../models/DecisionRequirementsDefinitionDto';
import type { DecisionRequirementsDefinitionXmlDto } from '../models/DecisionRequirementsDefinitionXmlDto';
import type { DeleteHistoricDecisionInstancesDto } from '../models/DeleteHistoricDecisionInstancesDto';
import type { DeleteHistoricProcessInstancesDto } from '../models/DeleteHistoricProcessInstancesDto';
import type { DeploymentDto } from '../models/DeploymentDto';
import type { DeploymentResourceDto } from '../models/DeploymentResourceDto';
import type { EvaluateDecisionDto } from '../models/EvaluateDecisionDto';
import type { EvaluationConditionDto } from '../models/EvaluationConditionDto';
import type { EventSubscriptionDto } from '../models/EventSubscriptionDto';
import type { ExecutionDto } from '../models/ExecutionDto';
import type { ExecutionQueryDto } from '../models/ExecutionQueryDto';
import type { ExecutionTriggerDto } from '../models/ExecutionTriggerDto';
import type { ExtendLockOnExternalTaskDto } from '../models/ExtendLockOnExternalTaskDto';
import type { ExternalTaskBpmnError } from '../models/ExternalTaskBpmnError';
import type { ExternalTaskDto } from '../models/ExternalTaskDto';
import type { ExternalTaskFailureDto } from '../models/ExternalTaskFailureDto';
import type { ExternalTaskQueryDto } from '../models/ExternalTaskQueryDto';
import type { FetchExternalTasksDto } from '../models/FetchExternalTasksDto';
import type { FilterDto } from '../models/FilterDto';
import type { FormDto } from '../models/FormDto';
import type { GroupDto } from '../models/GroupDto';
import type { GroupInfoDto } from '../models/GroupInfoDto';
import type { GroupQueryDto } from '../models/GroupQueryDto';
import type { HistoricActivityInstanceDto } from '../models/HistoricActivityInstanceDto';
import type { HistoricActivityInstanceQueryDto } from '../models/HistoricActivityInstanceQueryDto';
import type { HistoricActivityStatisticsDto } from '../models/HistoricActivityStatisticsDto';
import type { HistoricBatchDto } from '../models/HistoricBatchDto';
import type { HistoricCaseActivityInstanceDto } from '../models/HistoricCaseActivityInstanceDto';
import type { HistoricCaseActivityStatisticsDto } from '../models/HistoricCaseActivityStatisticsDto';
import type { HistoricCaseInstanceDto } from '../models/HistoricCaseInstanceDto';
import type { HistoricCaseInstanceQueryDto } from '../models/HistoricCaseInstanceQueryDto';
import type { HistoricDecisionInstanceDto } from '../models/HistoricDecisionInstanceDto';
import type { HistoricDecisionInstanceStatisticsDto } from '../models/HistoricDecisionInstanceStatisticsDto';
import type { HistoricDetailDto } from '../models/HistoricDetailDto';
import type { HistoricDetailQueryDto } from '../models/HistoricDetailQueryDto';
import type { HistoricExternalTaskLogDto } from '../models/HistoricExternalTaskLogDto';
import type { HistoricExternalTaskLogQueryDto } from '../models/HistoricExternalTaskLogQueryDto';
import type { HistoricIdentityLinkLogDto } from '../models/HistoricIdentityLinkLogDto';
import type { HistoricIncidentDto } from '../models/HistoricIncidentDto';
import type { HistoricJobLogDto } from '../models/HistoricJobLogDto';
import type { HistoricJobLogQueryDto } from '../models/HistoricJobLogQueryDto';
import type { HistoricProcessInstanceDto } from '../models/HistoricProcessInstanceDto';
import type { HistoricProcessInstanceQueryDto } from '../models/HistoricProcessInstanceQueryDto';
import type { HistoricTaskInstanceDto } from '../models/HistoricTaskInstanceDto';
import type { HistoricTaskInstanceQueryDto } from '../models/HistoricTaskInstanceQueryDto';
import type { HistoricVariableInstanceDto } from '../models/HistoricVariableInstanceDto';
import type { HistoricVariableInstanceQueryDto } from '../models/HistoricVariableInstanceQueryDto';
import type { HistoryCleanupConfigurationDto } from '../models/HistoryCleanupConfigurationDto';
import type { HistoryTimeToLiveDto } from '../models/HistoryTimeToLiveDto';
import type { IdentityLinkDto } from '../models/IdentityLinkDto';
import type { JobDefinitionDto } from '../models/JobDefinitionDto';
import type { JobDefinitionPriorityDto } from '../models/JobDefinitionPriorityDto';
import type { JobDefinitionQueryDto } from '../models/JobDefinitionQueryDto';
import type { JobDefinitionSuspensionStateDto } from '../models/JobDefinitionSuspensionStateDto';
import type { JobDto } from '../models/JobDto';
import type { JobDuedateDto } from '../models/JobDuedateDto';
import type { JobQueryDto } from '../models/JobQueryDto';
import type { JobSuspensionStateDto } from '../models/JobSuspensionStateDto';
import type { LockedExternalTaskDto } from '../models/LockedExternalTaskDto';
import type { LockExternalTaskDto } from '../models/LockExternalTaskDto';
import type { MetricsIntervalResultDto } from '../models/MetricsIntervalResultDto';
import type { MetricsResultDto } from '../models/MetricsResultDto';
import type { MigrationExecutionDto } from '../models/MigrationExecutionDto';
import type { MigrationPlanDto } from '../models/MigrationPlanDto';
import type { MigrationPlanGenerationDto } from '../models/MigrationPlanGenerationDto';
import type { MigrationPlanReportDto } from '../models/MigrationPlanReportDto';
import type { ModificationDto } from '../models/ModificationDto';
import type { MultipartFormData } from '../models/MultipartFormData';
import type { OptimizeHistoricActivityInstanceDto } from '../models/OptimizeHistoricActivityInstanceDto';
import type { OptimizeHistoricIdentityLinkLogDto } from '../models/OptimizeHistoricIdentityLinkLogDto';
import type { OptimizeHistoricVariableUpdateDto } from '../models/OptimizeHistoricVariableUpdateDto';
import type { PasswordPolicyRequestDto } from '../models/PasswordPolicyRequestDto';
import type { PatchVariablesDto } from '../models/PatchVariablesDto';
import type { PriorityDto } from '../models/PriorityDto';
import type { ProcessDefinitionDiagramDto } from '../models/ProcessDefinitionDiagramDto';
import type { ProcessDefinitionDto } from '../models/ProcessDefinitionDto';
import type { ProcessDefinitionSuspensionStateDto } from '../models/ProcessDefinitionSuspensionStateDto';
import type { ProcessEngineDto } from '../models/ProcessEngineDto';
import type { ProcessInstanceDto } from '../models/ProcessInstanceDto';
import type { ProcessInstanceModificationDto } from '../models/ProcessInstanceModificationDto';
import type { ProcessInstanceSuspensionStateAsyncDto } from '../models/ProcessInstanceSuspensionStateAsyncDto';
import type { ProcessInstanceSuspensionStateDto } from '../models/ProcessInstanceSuspensionStateDto';
import type { ResourceOptionsDto } from '../models/ResourceOptionsDto';
import type { RestartProcessInstanceDto } from '../models/RestartProcessInstanceDto';
import type { RetriesDto } from '../models/RetriesDto';
import type { SchemaLogEntryDto } from '../models/SchemaLogEntryDto';
import type { SchemaLogQueryDto } from '../models/SchemaLogQueryDto';
import type { SetJobRetriesByProcessDto } from '../models/SetJobRetriesByProcessDto';
import type { SetJobRetriesDto } from '../models/SetJobRetriesDto';
import type { SetRemovalTimeToHistoricBatchesDto } from '../models/SetRemovalTimeToHistoricBatchesDto';
import type { SetRemovalTimeToHistoricDecisionInstancesDto } from '../models/SetRemovalTimeToHistoricDecisionInstancesDto';
import type { SetRemovalTimeToHistoricProcessInstancesDto } from '../models/SetRemovalTimeToHistoricProcessInstancesDto';
import type { SetRetriesForExternalTasksDto } from '../models/SetRetriesForExternalTasksDto';
import type { SetVariablesAsyncDto } from '../models/SetVariablesAsyncDto';
import type { SignalDto } from '../models/SignalDto';
import type { StartProcessInstanceDto } from '../models/StartProcessInstanceDto';
import type { StatisticsResultDto } from '../models/StatisticsResultDto';
import type { SuspensionStateDto } from '../models/SuspensionStateDto';
import type { TaskBpmnErrorDto } from '../models/TaskBpmnErrorDto';
import type { TaskDto } from '../models/TaskDto';
import type { TaskEscalationDto } from '../models/TaskEscalationDto';
import type { TaskQueryDto } from '../models/TaskQueryDto';
import type { TelemetryConfigurationDto } from '../models/TelemetryConfigurationDto';
import type { TelemetryDataDto } from '../models/TelemetryDataDto';
import type { TenantDto } from '../models/TenantDto';
import type { UserCredentialsDto } from '../models/UserCredentialsDto';
import type { UserDto1 } from '../models/UserDto1';
import type { UserIdDto } from '../models/UserIdDto';
import type { UserOperationLogEntryDto } from '../models/UserOperationLogEntryDto';
import type { UserProfileDto } from '../models/UserProfileDto';
import type { VariableValueDto } from '../models/VariableValueDto';
import type { VersionDto } from '../models/VersionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DefaultService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getActivityInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/activity-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public postActivityInstance({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricActivityInstanceQueryDto,
    }): Observable<Array<HistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/activity-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getActivityInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/activity-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postActivityInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricActivityInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/activity-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getActivityInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/activity-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AuthorizationDto OK
     * @throws ApiError
     */
    public getAuthorization({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<AuthorizationDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authorization',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsAuthorization(): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/authorization',
        });
    }

    /**
     * @returns AuthorizationCheckResultDto OK
     * @throws ApiError
     */
    public getAuthorizationCheck({
        permissionName,
        resourceId,
        resourceName,
        resourceType,
        userId,
    }: {
        permissionName?: string,
        resourceId?: string,
        resourceName?: string,
        resourceType?: number,
        userId?: string,
    }): Observable<AuthorizationCheckResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authorization/check',
            query: {
                'permissionName': permissionName,
                'resourceId': resourceId,
                'resourceName': resourceName,
                'resourceType': resourceType,
                'userId': userId,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getAuthorizationCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authorization/count',
        });
    }

    /**
     * @returns AuthorizationDto OK
     * @throws ApiError
     */
    public postAuthorizationCreate({
        requestBody,
    }: {
        requestBody?: AuthorizationCreateDto,
    }): Observable<AuthorizationDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/authorization/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AuthorizationDto OK
     * @throws ApiError
     */
    public getAuthorization1({
        id,
    }: {
        id: string,
    }): Observable<AuthorizationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/authorization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putAuthorization({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: AuthorizationDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/authorization/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteAuthorization({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/authorization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsAuthorization1({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/authorization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getBatch({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricBatchDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CleanableHistoricBatchReportResultDto OK
     * @throws ApiError
     */
    public getBatchCleanableBatchReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricBatchReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch/cleanable-batch-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getBatchCleanableBatchReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch/cleanable-batch-report/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getBatchCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch/count',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postBatchSetRemovalTime({
        requestBody,
    }: {
        requestBody?: SetRemovalTimeToHistoricBatchesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/batch/set-removal-time',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BatchStatisticsDto OK
     * @throws ApiError
     */
    public getBatchStatistics({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<BatchStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch/statistics',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getBatchStatisticsCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch/statistics/count',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getBatch1({
        id,
    }: {
        id: string,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/batch/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteBatch({
        id,
        cascade,
    }: {
        id: string,
        cascade?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/batch/{id}',
            path: {
                'id': id,
            },
            query: {
                'cascade': cascade,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putBatchSuspended({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: SuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/batch/{id}/suspended',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricCaseActivityInstanceDto OK
     * @throws ApiError
     */
    public getCaseActivityInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-activity-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getCaseActivityInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-activity-instance/count',
        });
    }

    /**
     * @returns HistoricCaseActivityInstanceDto OK
     * @throws ApiError
     */
    public getCaseActivityInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricCaseActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-activity-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getCaseDefinition({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CaseDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CleanableHistoricCaseInstanceReportResultDto OK
     * @throws ApiError
     */
    public getCaseDefinitionCleanableCaseInstanceReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricCaseInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/cleanable-case-instance-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getCaseDefinitionCleanableCaseInstanceReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/cleanable-case-instance-report/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getCaseDefinitionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/count',
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getCaseDefinitionKey({
        key,
    }: {
        key: string,
    }): Observable<CaseDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/key/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postCaseDefinitionKeyCreate({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: CreateCaseInstanceDto,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-definition/key/{key}/create',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getCaseDefinitionKeyDiagram({
        key,
    }: {
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/key/{key}/diagram',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putCaseDefinitionKeyHistoryTimeToLive({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/case-definition/key/{key}/history-time-to-live',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getCaseDefinitionKeyTenantId({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<CaseDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postCaseDefinitionKeyTenantIdCreate({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: CreateCaseInstanceDto,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-definition/key/{key}/tenant-id/{tenantId}/create',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getCaseDefinitionKeyTenantIdDiagram({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/key/{key}/tenant-id/{tenantId}/diagram',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putCaseDefinitionKeyTenantIdHistoryTimeToLive({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/case-definition/key/{key}/tenant-id/{tenantId}/history-time-to-live',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getCaseDefinitionKeyTenantIdXml({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<CaseDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/key/{key}/tenant-id/{tenantId}/xml',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns CaseDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getCaseDefinitionKeyXml({
        key,
    }: {
        key: string,
    }): Observable<CaseDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/key/{key}/xml',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getCaseDefinition1({
        id,
    }: {
        id: string,
    }): Observable<CaseDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postCaseDefinitionCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CreateCaseInstanceDto,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-definition/{id}/create',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getCaseDefinitionDiagram({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/{id}/diagram',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putCaseDefinitionHistoryTimeToLive({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/case-definition/{id}/history-time-to-live',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricCaseActivityStatisticsDto OK
     * @throws ApiError
     */
    public getCaseDefinitionStatistics({
        id,
    }: {
        id: string,
    }): Observable<Array<HistoricCaseActivityStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/{id}/statistics',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CaseDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getCaseDefinitionXml({
        id,
    }: {
        id: string,
    }): Observable<CaseDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-definition/{id}/xml',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CaseExecutionDto OK
     * @throws ApiError
     */
    public getCaseExecution({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CaseExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CaseExecutionDto OK
     * @throws ApiError
     */
    public postCaseExecution({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: CaseExecutionQueryDto,
    }): Observable<Array<CaseExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getCaseExecutionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postCaseExecutionCount({
        requestBody,
    }: {
        requestBody?: CaseExecutionQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseExecutionDto OK
     * @throws ApiError
     */
    public getCaseExecution1({
        id,
    }: {
        id: string,
    }): Observable<CaseExecutionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionComplete({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/complete',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionDisable({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/disable',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getCaseExecutionLocalVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}/localVariables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionLocalVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/localVariables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getCaseExecutionLocalVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putCaseExecutionLocalVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/case-execution/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteCaseExecutionLocalVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/case-execution/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getCaseExecutionLocalVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}/localVariables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionLocalVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/localVariables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionManualStart({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/manual-start',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionReenable({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/reenable',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionTerminate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/terminate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getCaseExecutionVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}/variables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/variables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getCaseExecutionVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putCaseExecutionVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/case-execution/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteCaseExecutionVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/case-execution/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getCaseExecutionVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-execution/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseExecutionVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-execution/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getCaseInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postCaseInstance({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getCaseInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postCaseInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getCaseInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseInstanceClose({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance/{id}/close',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseInstanceComplete({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance/{id}/complete',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseInstanceTerminate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance/{id}/terminate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getCaseInstanceVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-instance/{id}/variables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseInstanceVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance/{id}/variables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getCaseInstanceVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-instance/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putCaseInstanceVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/case-instance/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteCaseInstanceVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/case-instance/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getCaseInstanceVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/case-instance/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCaseInstanceVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/case-instance/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ProcessInstanceDto OK
     * @throws ApiError
     */
    public postCondition({
        requestBody,
    }: {
        requestBody?: EvaluationConditionDto,
    }): Observable<Array<ProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/condition',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionDefinition({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<DecisionDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CleanableHistoricDecisionInstanceReportResultDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionCleanableDecisionInstanceReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricDecisionInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/cleanable-decision-instance-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionCleanableDecisionInstanceReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/cleanable-decision-instance-report/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/count',
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionKey({
        key,
    }: {
        key: string,
    }): Observable<DecisionDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/key/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDecisionDefinitionKeyDiagram({
        key,
    }: {
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/key/{key}/diagram',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public postDecisionDefinitionKeyEvaluate({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: EvaluateDecisionDto,
    }): Observable<Array<Record<string, VariableValueDto>>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/decision-definition/key/{key}/evaluate',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putDecisionDefinitionKeyHistoryTimeToLive({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/decision-definition/key/{key}/history-time-to-live',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionKeyTenantId({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<DecisionDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDecisionDefinitionKeyTenantIdDiagram({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/key/{key}/tenant-id/{tenantId}/diagram',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public postDecisionDefinitionKeyTenantIdEvaluate({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: EvaluateDecisionDto,
    }): Observable<Array<Record<string, VariableValueDto>>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/decision-definition/key/{key}/tenant-id/{tenantId}/evaluate',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putDecisionDefinitionKeyTenantIdHistoryTimeToLive({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/decision-definition/key/{key}/tenant-id/{tenantId}/history-time-to-live',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionKeyTenantIdXml({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<DecisionDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/key/{key}/tenant-id/{tenantId}/xml',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns DecisionDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionKeyXml({
        key,
    }: {
        key: string,
    }): Observable<DecisionDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/key/{key}/xml',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionDefinition1({
        id,
    }: {
        id: string,
    }): Observable<DecisionDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDecisionDefinitionDiagram({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/{id}/diagram',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public postDecisionDefinitionEvaluate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: EvaluateDecisionDto,
    }): Observable<Array<Record<string, VariableValueDto>>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/decision-definition/{id}/evaluate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putDecisionDefinitionHistoryTimeToLive({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/decision-definition/{id}/history-time-to-live',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getDecisionDefinitionXml({
        id,
    }: {
        id: string,
    }): Observable<DecisionDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-definition/{id}/xml',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getDecisionInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDecisionInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getDecisionInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-instance/count',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postDecisionInstanceDelete({
        requestBody,
    }: {
        requestBody?: DeleteHistoricDecisionInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/decision-instance/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postDecisionInstanceSetRemovalTime({
        requestBody,
    }: {
        requestBody?: SetRemovalTimeToHistoricDecisionInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/decision-instance/set-removal-time',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getDecisionInstance1({
        id,
        disableBinaryFetching,
        disableCustomObjectDeserialization,
        includeInputs,
        includeOutputs,
    }: {
        id: string,
        disableBinaryFetching?: boolean,
        disableCustomObjectDeserialization?: boolean,
        includeInputs?: boolean,
        includeOutputs?: boolean,
    }): Observable<HistoricDecisionInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-instance/{id}',
            path: {
                'id': id,
            },
            query: {
                'disableBinaryFetching': disableBinaryFetching,
                'disableCustomObjectDeserialization': disableCustomObjectDeserialization,
                'includeInputs': includeInputs,
                'includeOutputs': includeOutputs,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinition({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<DecisionRequirementsDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/count',
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionKey({
        key,
    }: {
        key: string,
    }): Observable<DecisionRequirementsDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/key/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionKeyDiagram({
        key,
    }: {
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/key/{key}/diagram',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionKeyTenantId({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<DecisionRequirementsDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/key/{key}/tenant-id/{tenant-id}',
            path: {
                'key': key,
                'tenant-id': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionKeyTenantIdDiagram({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/key/{key}/tenant-id/{tenant-id}/diagram',
            path: {
                'key': key,
                'tenant-id': tenantId,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionXmlDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionKeyTenantIdXml({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<DecisionRequirementsDefinitionXmlDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/key/{key}/tenant-id/{tenant-id}/xml',
            path: {
                'key': key,
                'tenant-id': tenantId,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionXmlDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionKeyXml({
        key,
    }: {
        key: string,
    }): Observable<DecisionRequirementsDefinitionXmlDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/key/{key}/xml',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinition1({
        id,
    }: {
        id: string,
    }): Observable<DecisionRequirementsDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionDiagram({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/{id}/diagram',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceStatisticsDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionStatistics({
        id,
        decisionInstanceId,
    }: {
        id: string,
        decisionInstanceId?: string,
    }): Observable<Array<HistoricDecisionInstanceStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/{id}/statistics',
            path: {
                'id': id,
            },
            query: {
                'decisionInstanceId': decisionInstanceId,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionXmlDto OK
     * @throws ApiError
     */
    public getDecisionRequirementsDefinitionXml({
        id,
    }: {
        id: string,
    }): Observable<DecisionRequirementsDefinitionXmlDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/decision-requirements-definition/{id}/xml',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public getDeployment({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<DeploymentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getDeploymentCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment/count',
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public postDeploymentCreate({
        formData,
    }: {
        formData?: MultipartFormData,
    }): Observable<DeploymentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/deployment/create',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getDeploymentRegistered(): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment/registered',
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public getDeployment1({
        id,
    }: {
        id: string,
    }): Observable<DeploymentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteDeployment({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/deployment/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public postDeploymentRedeploy({
        id,
    }: {
        id: string,
    }): Observable<DeploymentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/deployment/{id}/redeploy',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentResourceDto OK
     * @throws ApiError
     */
    public getDeploymentResources({
        id,
    }: {
        id: string,
    }): Observable<Array<DeploymentResourceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment/{id}/resources',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentResourceDto OK
     * @throws ApiError
     */
    public getDeploymentResources1({
        id,
        resourceId,
    }: {
        id: string,
        resourceId: string,
    }): Observable<DeploymentResourceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment/{id}/resources/{resourceId}',
            path: {
                'id': id,
                'resourceId': resourceId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getDeploymentResourcesData({
        id,
        resourceId,
    }: {
        id: string,
        resourceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deployment/{id}/resources/{resourceId}/data',
            path: {
                'id': id,
                'resourceId': resourceId,
            },
        });
    }

    /**
     * @returns ProcessEngineDto OK
     * @throws ApiError
     */
    public getEngine(): Observable<Array<ProcessEngineDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine',
        });
    }

    /**
     * @returns AuthorizationDto OK
     * @throws ApiError
     */
    public getEngineAuthorization({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<AuthorizationDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/authorization',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineAuthorization({
        name,
    }: {
        name: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/authorization',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns AuthorizationCheckResultDto OK
     * @throws ApiError
     */
    public getEngineAuthorizationCheck({
        name,
        permissionName,
        resourceId,
        resourceName,
        resourceType,
        userId,
    }: {
        name: string,
        permissionName?: string,
        resourceId?: string,
        resourceName?: string,
        resourceType?: number,
        userId?: string,
    }): Observable<AuthorizationCheckResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/authorization/check',
            path: {
                'name': name,
            },
            query: {
                'permissionName': permissionName,
                'resourceId': resourceId,
                'resourceName': resourceName,
                'resourceType': resourceType,
                'userId': userId,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineAuthorizationCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/authorization/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns AuthorizationDto OK
     * @throws ApiError
     */
    public postEngineAuthorizationCreate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: AuthorizationCreateDto,
    }): Observable<AuthorizationDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/authorization/create',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AuthorizationDto OK
     * @throws ApiError
     */
    public getEngineAuthorization1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<AuthorizationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/authorization/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineAuthorization({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: AuthorizationDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/authorization/{id}',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineAuthorization({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/authorization/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineAuthorization1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/authorization/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getEngineBatch({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricBatchDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/batch',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineBatchCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/batch/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns BatchStatisticsDto OK
     * @throws ApiError
     */
    public getEngineBatchStatistics({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<BatchStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/batch/statistics',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineBatchStatisticsCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/batch/statistics/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getEngineBatch1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/batch/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineBatch({
        name,
        id,
        cascade,
    }: {
        name: string,
        id: string,
        cascade?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/batch/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'cascade': cascade,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineBatchSuspended({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: SuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/batch/{id}/suspended',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinition({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CaseDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionKey({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<CaseDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/key/{key}',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postEngineCaseDefinitionKeyCreate({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: CreateCaseInstanceDto,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-definition/key/{key}/create',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionKeyDiagram({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/key/{key}/diagram',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineCaseDefinitionKeyHistoryTimeToLive({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/case-definition/key/{key}/history-time-to-live',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionKeyTenantId({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<CaseDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postEngineCaseDefinitionKeyTenantIdCreate({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: CreateCaseInstanceDto,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-definition/key/{key}/tenant-id/{tenantId}/create',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionKeyTenantIdDiagram({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/key/{key}/tenant-id/{tenantId}/diagram',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineCaseDefinitionKeyTenantIdHistoryTimeToLive({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/case-definition/key/{key}/tenant-id/{tenantId}/history-time-to-live',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionKeyTenantIdXml({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<CaseDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/key/{key}/tenant-id/{tenantId}/xml',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns CaseDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionKeyXml({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<CaseDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/key/{key}/xml',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns CaseDefinitionDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinition1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<CaseDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postEngineCaseDefinitionCreate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CreateCaseInstanceDto,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-definition/{id}/create',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionDiagram({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/{id}/diagram',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineCaseDefinitionHistoryTimeToLive({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/case-definition/{id}/history-time-to-live',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineCaseDefinitionXml({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<CaseDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-definition/{id}/xml',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CaseExecutionDto OK
     * @throws ApiError
     */
    public getEngineCaseExecution({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CaseExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CaseExecutionDto OK
     * @throws ApiError
     */
    public postEngineCaseExecution({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: CaseExecutionQueryDto,
    }): Observable<Array<CaseExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineCaseExecutionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineCaseExecutionCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: CaseExecutionQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CaseExecutionDto OK
     * @throws ApiError
     */
    public getEngineCaseExecution1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<CaseExecutionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionComplete({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/complete',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionDisable({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/disable',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineCaseExecutionLocalVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}/localVariables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionLocalVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/localVariables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineCaseExecutionLocalVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineCaseExecutionLocalVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/case-execution/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineCaseExecutionLocalVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/case-execution/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineCaseExecutionLocalVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}/localVariables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionLocalVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/localVariables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionManualStart({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/manual-start',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionReenable({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/reenable',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionTerminate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/terminate',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineCaseExecutionVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineCaseExecutionVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineCaseExecutionVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/case-execution/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineCaseExecutionVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/case-execution/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineCaseExecutionVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-execution/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseExecutionVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-execution/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getEngineCaseInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postEngineCaseInstance({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineCaseInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineCaseInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getEngineCaseInstance1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseInstanceClose({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance/{id}/close',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseInstanceComplete({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance/{id}/complete',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseInstanceTerminate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CaseExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance/{id}/terminate',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineCaseInstanceVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-instance/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseInstanceVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineCaseInstanceVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-instance/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineCaseInstanceVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/case-instance/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineCaseInstanceVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/case-instance/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineCaseInstanceVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/case-instance/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineCaseInstanceVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/case-instance/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineCondition({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: EvaluationConditionDto,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/condition',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinition({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<DecisionDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionKey({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<DecisionDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/key/{key}',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionKeyDiagram({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/key/{key}/diagram',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public postEngineDecisionDefinitionKeyEvaluate({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: EvaluateDecisionDto,
    }): Observable<Array<Record<string, VariableValueDto>>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/decision-definition/key/{key}/evaluate',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineDecisionDefinitionKeyHistoryTimeToLive({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/decision-definition/key/{key}/history-time-to-live',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionKeyTenantId({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<DecisionDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionKeyTenantIdDiagram({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/key/{key}/tenant-id/{tenantId}/diagram',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public postEngineDecisionDefinitionKeyTenantIdEvaluate({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: EvaluateDecisionDto,
    }): Observable<Array<Record<string, VariableValueDto>>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/decision-definition/key/{key}/tenant-id/{tenantId}/evaluate',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineDecisionDefinitionKeyTenantIdHistoryTimeToLive({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/decision-definition/key/{key}/tenant-id/{tenantId}/history-time-to-live',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionKeyTenantIdXml({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<DecisionDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/key/{key}/tenant-id/{tenantId}/xml',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns DecisionDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionKeyXml({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<DecisionDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/key/{key}/xml',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns DecisionDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinition1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<DecisionDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionDiagram({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/{id}/diagram',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public postEngineDecisionDefinitionEvaluate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: EvaluateDecisionDto,
    }): Observable<Array<Record<string, VariableValueDto>>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/decision-definition/{id}/evaluate',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineDecisionDefinitionHistoryTimeToLive({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/decision-definition/{id}/history-time-to-live',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DecisionDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineDecisionDefinitionXml({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<DecisionDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-definition/{id}/xml',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinition({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<DecisionRequirementsDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionKey({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<DecisionRequirementsDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/key/{key}',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionKeyDiagram({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/key/{key}/diagram',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionKeyTenantId({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<DecisionRequirementsDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/key/{key}/tenant-id/{tenant-id}',
            path: {
                'name': name,
                'key': key,
                'tenant-id': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionKeyTenantIdDiagram({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/key/{key}/tenant-id/{tenant-id}/diagram',
            path: {
                'name': name,
                'key': key,
                'tenant-id': tenantId,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionXmlDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionKeyTenantIdXml({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<DecisionRequirementsDefinitionXmlDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/key/{key}/tenant-id/{tenant-id}/xml',
            path: {
                'name': name,
                'key': key,
                'tenant-id': tenantId,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionXmlDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionKeyXml({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<DecisionRequirementsDefinitionXmlDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/key/{key}/xml',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinition1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<DecisionRequirementsDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionDiagram({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/{id}/diagram',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns DecisionRequirementsDefinitionXmlDto OK
     * @throws ApiError
     */
    public getEngineDecisionRequirementsDefinitionXml({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<DecisionRequirementsDefinitionXmlDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/decision-requirements-definition/{id}/xml',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public getEngineDeployment({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<DeploymentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineDeploymentCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public postEngineDeploymentCreate({
        name,
        formData,
    }: {
        name: string,
        formData?: MultipartFormData,
    }): Observable<DeploymentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/deployment/create',
            path: {
                'name': name,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getEngineDeploymentRegistered({
        name,
    }: {
        name: string,
    }): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment/registered',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public getEngineDeployment1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<DeploymentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineDeployment({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/deployment/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentDto OK
     * @throws ApiError
     */
    public postEngineDeploymentRedeploy({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<DeploymentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/deployment/{id}/redeploy',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentResourceDto OK
     * @throws ApiError
     */
    public getEngineDeploymentResources({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<Array<DeploymentResourceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment/{id}/resources',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns DeploymentResourceDto OK
     * @throws ApiError
     */
    public getEngineDeploymentResources1({
        name,
        id,
        resourceId,
    }: {
        name: string,
        id: string,
        resourceId: string,
    }): Observable<DeploymentResourceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment/{id}/resources/{resourceId}',
            path: {
                'name': name,
                'id': id,
                'resourceId': resourceId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineDeploymentResourcesData({
        name,
        id,
        resourceId,
    }: {
        name: string,
        id: string,
        resourceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/deployment/{id}/resources/{resourceId}/data',
            path: {
                'name': name,
                'id': id,
                'resourceId': resourceId,
            },
        });
    }

    /**
     * @returns EventSubscriptionDto OK
     * @throws ApiError
     */
    public getEngineEventSubscription({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<EventSubscriptionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/event-subscription',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineEventSubscriptionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/event-subscription/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns ExecutionDto OK
     * @throws ApiError
     */
    public getEngineExecution({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<ExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ExecutionDto OK
     * @throws ApiError
     */
    public postEngineExecution({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: ExecutionQueryDto,
    }): Observable<Array<ExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineExecutionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineExecutionCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ExecutionQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ExecutionDto OK
     * @throws ApiError
     */
    public getEngineExecution1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ExecutionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public postEngineExecutionCreateIncident({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CreateIncidentDto,
    }): Observable<HistoricIncidentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution/{id}/create-incident',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineExecutionLocalVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution/{id}/localVariables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExecutionLocalVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution/{id}/localVariables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineExecutionLocalVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineExecutionLocalVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/execution/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineExecutionLocalVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/execution/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineExecutionLocalVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution/{id}/localVariables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExecutionLocalVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution/{id}/localVariables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns EventSubscriptionDto OK
     * @throws ApiError
     */
    public getEngineExecutionMessageSubscriptions({
        name,
        id,
        messageName,
    }: {
        name: string,
        id: string,
        messageName: string,
    }): Observable<EventSubscriptionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/execution/{id}/messageSubscriptions/{messageName}',
            path: {
                'name': name,
                'id': id,
                'messageName': messageName,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExecutionMessageSubscriptionsTrigger({
        name,
        id,
        messageName,
        requestBody,
    }: {
        name: string,
        id: string,
        messageName: string,
        requestBody?: ExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution/{id}/messageSubscriptions/{messageName}/trigger',
            path: {
                'name': name,
                'id': id,
                'messageName': messageName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExecutionSignal({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/execution/{id}/signal',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ExternalTaskDto OK
     * @throws ApiError
     */
    public getEngineExternalTask({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<ExternalTaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/external-task',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ExternalTaskDto OK
     * @throws ApiError
     */
    public postEngineExternalTask({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: ExternalTaskQueryDto,
    }): Observable<Array<ExternalTaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineExternalTaskCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/external-task/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineExternalTaskCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ExternalTaskQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LockedExternalTaskDto OK
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskFetchAndLock({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: FetchExternalTasksDto,
    }): Observable<Array<LockedExternalTaskDto> | any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/fetchAndLock',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineExternalTaskRetries({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetRetriesForExternalTasksDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/external-task/retries',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineExternalTaskRetriesAsync({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetRetriesForExternalTasksDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/retries-async',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getEngineExternalTaskTopicNames({
        name,
        withLockedTasks,
        withRetriesLeft,
        withUnlockedTasks,
    }: {
        name: string,
        withLockedTasks?: boolean,
        withRetriesLeft?: boolean,
        withUnlockedTasks?: boolean,
    }): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/external-task/topic-names',
            path: {
                'name': name,
            },
            query: {
                'withLockedTasks': withLockedTasks,
                'withRetriesLeft': withRetriesLeft,
                'withUnlockedTasks': withUnlockedTasks,
            },
        });
    }

    /**
     * @returns ExternalTaskDto OK
     * @throws ApiError
     */
    public getEngineExternalTask1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ExternalTaskDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/external-task/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskBpmnError({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ExternalTaskBpmnError,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/{id}/bpmnError',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskComplete({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CompleteExternalTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/{id}/complete',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getEngineExternalTaskErrorDetails({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/external-task/{id}/errorDetails',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskExtendLock({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ExtendLockOnExternalTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/{id}/extendLock',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskFailure({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ExternalTaskFailureDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/{id}/failure',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskLock({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: LockExternalTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/{id}/lock',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineExternalTaskPriority({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PriorityDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/external-task/{id}/priority',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineExternalTaskRetries1({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: RetriesDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/external-task/{id}/retries',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineExternalTaskUnlock({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/external-task/{id}/unlock',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns FilterDto OK
     * @throws ApiError
     */
    public getEngineFilter({
        name,
        firstResult,
        itemCount,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        itemCount?: boolean,
        maxResults?: number,
    }): Observable<Array<FilterDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/filter',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'itemCount': itemCount,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineFilter({
        name,
    }: {
        name: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/filter',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineFilterCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/filter/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns FilterDto OK
     * @throws ApiError
     */
    public postEngineFilterCreate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: FilterDto,
    }): Observable<FilterDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/filter/create',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FilterDto OK
     * @throws ApiError
     */
    public getEngineFilter1({
        name,
        id,
        itemCount,
    }: {
        name: string,
        id: string,
        itemCount?: boolean,
    }): Observable<FilterDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/filter/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'itemCount': itemCount,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineFilter({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: FilterDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/filter/{id}',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineFilter({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/filter/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineFilter1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/filter/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineFilterCount1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/filter/{id}/count',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineFilterCount({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/filter/{id}/count',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineFilterList({
        name,
        id,
        firstResult,
        maxResults,
    }: {
        name: string,
        id: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/filter/{id}/list',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postEngineFilterList({
        name,
        id,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        id: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/filter/{id}/list',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineFilterSingleResult({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/filter/{id}/singleResult',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postEngineFilterSingleResult({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/filter/{id}/singleResult',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GroupDto OK
     * @throws ApiError
     */
    public getEngineGroup({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<GroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/group',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns GroupDto OK
     * @throws ApiError
     */
    public postEngineGroup({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: GroupQueryDto,
    }): Observable<Array<GroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/group',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineGroup({
        name,
    }: {
        name: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/group',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineGroupCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/group/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineGroupCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: GroupQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/group/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineGroupCreate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: GroupDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/group/create',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GroupDto OK
     * @throws ApiError
     */
    public getEngineGroup1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<GroupDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/group/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineGroup({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: GroupDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/group/{id}',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineGroup({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/group/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineGroup1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/group/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineGroupMembers({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/group/{id}/members',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineGroupMembers({
        name,
        id,
        userId,
    }: {
        name: string,
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/group/{id}/members/{userId}',
            path: {
                'name': name,
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineGroupMembers({
        name,
        id,
        userId,
    }: {
        name: string,
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/group/{id}/members/{userId}',
            path: {
                'name': name,
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryActivityInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/activity-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public postEngineHistoryActivityInstance({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricActivityInstanceQueryDto,
    }): Observable<Array<HistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/activity-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryActivityInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/activity-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryActivityInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricActivityInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/activity-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryActivityInstance1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/activity-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getEngineHistoryBatch({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricBatchDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/batch',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CleanableHistoricBatchReportResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryBatchCleanableBatchReport({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricBatchReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/batch/cleanable-batch-report',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryBatchCleanableBatchReportCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/batch/cleanable-batch-report/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryBatchCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/batch/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineHistoryBatchSetRemovalTime({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetRemovalTimeToHistoricBatchesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/batch/set-removal-time',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getEngineHistoryBatch1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/batch/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineHistoryBatch({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/history/batch/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricCaseActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseActivityInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-activity-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseActivityInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-activity-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricCaseActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseActivityInstance1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricCaseActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-activity-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CleanableHistoricCaseInstanceReportResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseDefinitionCleanableCaseInstanceReport({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricCaseInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-definition/cleanable-case-instance-report',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseDefinitionCleanableCaseInstanceReportCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-definition/cleanable-case-instance-report/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricCaseActivityStatisticsDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseDefinitionStatistics({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<Array<HistoricCaseActivityStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-definition/{id}/statistics',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postEngineHistoryCaseInstance({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/case-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryCaseInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/case-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryCaseInstance1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/case-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public postEngineHistoryCleanup({
        name,
        immediatelyDue = true,
    }: {
        name: string,
        immediatelyDue?: boolean,
    }): Observable<JobDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/cleanup',
            path: {
                'name': name,
            },
            query: {
                'immediatelyDue': immediatelyDue,
            },
        });
    }

    /**
     * @returns HistoryCleanupConfigurationDto OK
     * @throws ApiError
     */
    public getEngineHistoryCleanupConfiguration({
        name,
    }: {
        name: string,
    }): Observable<HistoryCleanupConfigurationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/cleanup/configuration',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getEngineHistoryCleanupJob({
        name,
    }: {
        name: string,
    }): Observable<JobDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/cleanup/job',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getEngineHistoryCleanupJobs({
        name,
    }: {
        name: string,
    }): Observable<Array<JobDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/cleanup/jobs',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CleanableHistoricDecisionInstanceReportResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryDecisionDefinitionCleanableDecisionInstanceReport({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricDecisionInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/decision-definition/cleanable-decision-instance-report',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryDecisionDefinitionCleanableDecisionInstanceReportCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/decision-definition/cleanable-decision-instance-report/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryDecisionInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDecisionInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/decision-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryDecisionInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/decision-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineHistoryDecisionInstanceDelete({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: DeleteHistoricDecisionInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/decision-instance/delete',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineHistoryDecisionInstanceSetRemovalTime({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetRemovalTimeToHistoricDecisionInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/decision-instance/set-removal-time',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryDecisionInstance1({
        name,
        id,
        disableBinaryFetching,
        disableCustomObjectDeserialization,
        includeInputs,
        includeOutputs,
    }: {
        name: string,
        id: string,
        disableBinaryFetching?: boolean,
        disableCustomObjectDeserialization?: boolean,
        includeInputs?: boolean,
        includeOutputs?: boolean,
    }): Observable<HistoricDecisionInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/decision-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'disableBinaryFetching': disableBinaryFetching,
                'disableCustomObjectDeserialization': disableCustomObjectDeserialization,
                'includeInputs': includeInputs,
                'includeOutputs': includeOutputs,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceStatisticsDto OK
     * @throws ApiError
     */
    public getEngineHistoryDecisionRequirementsDefinitionStatistics({
        name,
        id,
        decisionInstanceId,
    }: {
        name: string,
        id: string,
        decisionInstanceId?: string,
    }): Observable<Array<HistoricDecisionInstanceStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/decision-requirements-definition/{id}/statistics',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'decisionInstanceId': decisionInstanceId,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getEngineHistoryDetail({
        name,
        deserializeValues = true,
        firstResult,
        maxResults,
    }: {
        name: string,
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/detail',
            path: {
                'name': name,
            },
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public postEngineHistoryDetail({
        name,
        deserializeValues = true,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricDetailQueryDto,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/detail',
            path: {
                'name': name,
            },
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryDetailCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/detail/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getEngineHistoryDetail1({
        name,
        id,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        deserializeValue?: boolean,
    }): Observable<HistoricDetailDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/detail/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineHistoryDetailData({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/detail/{id}/data',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public getEngineHistoryExternalTaskLog({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricExternalTaskLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/external-task-log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public postEngineHistoryExternalTaskLog({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricExternalTaskLogQueryDto,
    }): Observable<Array<HistoricExternalTaskLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/external-task-log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryExternalTaskLogCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/external-task-log/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryExternalTaskLogCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricExternalTaskLogQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/external-task-log/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public getEngineHistoryExternalTaskLog1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricExternalTaskLogDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/external-task-log/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getEngineHistoryExternalTaskLogErrorDetails({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/external-task-log/{id}/error-details',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricIdentityLinkLogDto OK
     * @throws ApiError
     */
    public getEngineHistoryIdentityLinkLog({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIdentityLinkLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/identity-link-log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryIdentityLinkLogCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/identity-link-log/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getEngineHistoryIncident({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/incident',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryIncidentCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/incident/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public getEngineHistoryJobLog({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricJobLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/job-log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public postEngineHistoryJobLog({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricJobLogQueryDto,
    }): Observable<Array<HistoricJobLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/job-log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryJobLogCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/job-log/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryJobLogCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricJobLogQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/job-log/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public getEngineHistoryJobLog1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricJobLogDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/job-log/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getEngineHistoryJobLogStacktrace({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/job-log/{id}/stacktrace',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CleanableHistoricProcessInstanceReportResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryProcessDefinitionCleanableProcessInstanceReport({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricProcessInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-definition/cleanable-process-instance-report',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryProcessDefinitionCleanableProcessInstanceReportCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-definition/cleanable-process-instance-report/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricActivityStatisticsDto OK
     * @throws ApiError
     */
    public getEngineHistoryProcessDefinitionStatistics({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<Array<HistoricActivityStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-definition/{id}/statistics',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryProcessInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineHistoryProcessInstance({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/process-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryProcessInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryProcessInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/process-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineHistoryProcessInstanceDelete({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: DeleteHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/process-instance/delete',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineHistoryProcessInstanceReport({
        name,
    }: {
        name: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-instance/report',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineHistoryProcessInstanceSetRemovalTime({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetRemovalTimeToHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/process-instance/set-removal-time',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryProcessInstance1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/process-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineHistoryProcessInstance({
        name,
        id,
        failIfNotExists = true,
    }: {
        name: string,
        id: string,
        failIfNotExists?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/history/process-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'failIfNotExists': failIfNotExists,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineHistoryProcessInstanceVariableInstances({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/history/process-instance/{id}/variable-instances',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryTask({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/task',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public postEngineHistoryTask({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricTaskInstanceQueryDto,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/task',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryTaskCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/task/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryTaskCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricTaskInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/task/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineHistoryTaskReport({
        name,
    }: {
        name: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/task/report',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns UserOperationLogEntryDto OK
     * @throws ApiError
     */
    public getEngineHistoryUserOperation({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<UserOperationLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/user-operation',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryUserOperationCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/user-operation/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putEngineHistoryUserOperationClearAnnotation({
        name,
        operationId,
    }: {
        name: string,
        operationId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/history/user-operation/{operationId}/clear-annotation',
            path: {
                'name': name,
                'operationId': operationId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putEngineHistoryUserOperationSetAnnotation({
        name,
        operationId,
        requestBody,
    }: {
        name: string,
        operationId: string,
        requestBody?: AnnotationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/history/user-operation/{operationId}/set-annotation',
            path: {
                'name': name,
                'operationId': operationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricVariableInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryVariableInstance({
        name,
        deserializeValues = true,
        firstResult,
        maxResults,
    }: {
        name: string,
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricVariableInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/variable-instance',
            path: {
                'name': name,
            },
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricVariableInstanceDto OK
     * @throws ApiError
     */
    public postEngineHistoryVariableInstance({
        name,
        deserializeValues = true,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricVariableInstanceQueryDto,
    }): Observable<Array<HistoricVariableInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/variable-instance',
            path: {
                'name': name,
            },
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineHistoryVariableInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/variable-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineHistoryVariableInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricVariableInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/history/variable-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricVariableInstanceDto OK
     * @throws ApiError
     */
    public getEngineHistoryVariableInstance1({
        name,
        id,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        deserializeValue?: boolean,
    }): Observable<HistoricVariableInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/variable-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineHistoryVariableInstance({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/history/variable-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineHistoryVariableInstanceData({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/history/variable-instance/{id}/data',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns GroupInfoDto OK
     * @throws ApiError
     */
    public getEngineIdentityGroups({
        name,
        userId,
    }: {
        name: string,
        userId?: string,
    }): Observable<GroupInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/identity/groups',
            path: {
                'name': name,
            },
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineIdentityPasswordPolicy({
        name,
    }: {
        name: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/identity/password-policy',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postEngineIdentityPasswordPolicy({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: PasswordPolicyRequestDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/identity/password-policy',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AuthenticationResult OK
     * @throws ApiError
     */
    public postEngineIdentityVerify({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: BasicUserCredentialsDto,
    }): Observable<AuthenticationResult> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/identity/verify',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getEngineIncident({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/incident',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineIncidentCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/incident/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getEngineIncident1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricIncidentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/incident/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineIncident({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/incident/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putEngineIncidentAnnotation({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: AnnotationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/incident/{id}/annotation',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineIncidentAnnotation({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/incident/{id}/annotation',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getEngineJob({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<JobDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public postEngineJob({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: JobQueryDto,
    }): Observable<Array<JobDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobDefinitionDto OK
     * @throws ApiError
     */
    public getEngineJobDefinition({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<JobDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job-definition',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns JobDefinitionDto OK
     * @throws ApiError
     */
    public postEngineJobDefinition({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: JobDefinitionQueryDto,
    }): Observable<Array<JobDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job-definition',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineJobDefinitionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job-definition/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineJobDefinitionCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: JobDefinitionQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job-definition/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobDefinitionSuspended({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: JobDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job-definition/suspended',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobDefinitionDto OK
     * @throws ApiError
     */
    public getEngineJobDefinition1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<JobDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job-definition/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobDefinitionJobPriority({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: JobDefinitionPriorityDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job-definition/{id}/jobPriority',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobDefinitionRetries({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: RetriesDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job-definition/{id}/retries',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobDefinitionSuspended1({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: JobDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job-definition/{id}/suspended',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineJobCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineJobCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: JobQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineJobRetries({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetJobRetriesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job/retries',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobSuspended({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: JobSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job/suspended',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getEngineJob1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<JobDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineJob({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/job/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobDuedate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: JobDuedateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job/{id}/duedate',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineJobDuedateRecalculate({
        name,
        id,
        creationDateBased = true,
    }: {
        name: string,
        id: string,
        creationDateBased?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job/{id}/duedate/recalculate',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'creationDateBased': creationDateBased,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineJobExecute({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/job/{id}/execute',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobPriority({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PriorityDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job/{id}/priority',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobRetries({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: RetriesDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job/{id}/retries',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getEngineJobStacktrace({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/job/{id}/stacktrace',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineJobSuspended1({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: JobSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/job/{id}/suspended',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postEngineMessage({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: CorrelationMessageDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/message',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MetricsIntervalResultDto OK
     * @throws ApiError
     */
    public getEngineMetrics({
        name,
    }: {
        name: string,
    }): Observable<Array<MetricsIntervalResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/metrics',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineMetricsTaskWorker({
        name,
        date,
    }: {
        name: string,
        date?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/metrics/task-worker',
            path: {
                'name': name,
            },
            query: {
                'date': date,
            },
        });
    }

    /**
     * @returns MetricsResultDto OK
     * @throws ApiError
     */
    public getEngineMetricsSum({
        name,
        metricName,
    }: {
        name: string,
        metricName: string,
    }): Observable<MetricsResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/metrics/{metricName}/sum',
            path: {
                'name': name,
                'metricName': metricName,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineMigrationExecute({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: MigrationExecutionDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/migration/execute',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineMigrationExecuteAsync({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: MigrationExecutionDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/migration/executeAsync',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MigrationPlanDto OK
     * @throws ApiError
     */
    public postEngineMigrationGenerate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: MigrationPlanGenerationDto,
    }): Observable<MigrationPlanDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/migration/generate',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MigrationPlanReportDto OK
     * @throws ApiError
     */
    public postEngineMigrationValidate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: MigrationPlanDto,
    }): Observable<MigrationPlanReportDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/migration/validate',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineModificationExecute({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ModificationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/modification/execute',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineModificationExecuteAsync({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ModificationDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/modification/executeAsync',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OptimizeHistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeActivityInstanceCompleted({
        name,
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        name: string,
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<OptimizeHistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/activity-instance/completed',
            path: {
                'name': name,
            },
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns OptimizeHistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeActivityInstanceRunning({
        name,
        maxResults,
        startedAfter,
        startedAt,
    }: {
        name: string,
        maxResults?: number,
        startedAfter?: string,
        startedAt?: string,
    }): Observable<Array<OptimizeHistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/activity-instance/running',
            path: {
                'name': name,
            },
            query: {
                'maxResults': maxResults,
                'startedAfter': startedAfter,
                'startedAt': startedAt,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeDecisionInstance({
        name,
        evaluatedAfter,
        evaluatedAt,
        maxResults,
    }: {
        name: string,
        evaluatedAfter?: string,
        evaluatedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricDecisionInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/decision-instance',
            path: {
                'name': name,
            },
            query: {
                'evaluatedAfter': evaluatedAfter,
                'evaluatedAt': evaluatedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns OptimizeHistoricIdentityLinkLogDto OK
     * @throws ApiError
     */
    public getEngineOptimizeIdentityLinkLog({
        name,
        maxResults,
        occurredAfter,
        occurredAt,
    }: {
        name: string,
        maxResults?: number,
        occurredAfter?: string,
        occurredAt?: string,
    }): Observable<Array<OptimizeHistoricIdentityLinkLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/identity-link-log',
            path: {
                'name': name,
            },
            query: {
                'maxResults': maxResults,
                'occurredAfter': occurredAfter,
                'occurredAt': occurredAt,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getEngineOptimizeIncidentCompleted({
        name,
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        name: string,
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/incident/completed',
            path: {
                'name': name,
            },
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getEngineOptimizeIncidentOpen({
        name,
        createdAfter,
        createdAt,
        maxResults,
    }: {
        name: string,
        createdAfter?: string,
        createdAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/incident/open',
            path: {
                'name': name,
            },
            query: {
                'createdAfter': createdAfter,
                'createdAt': createdAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeProcessInstanceCompleted({
        name,
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        name: string,
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/process-instance/completed',
            path: {
                'name': name,
            },
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeProcessInstanceRunning({
        name,
        maxResults,
        startedAfter,
        startedAt,
    }: {
        name: string,
        maxResults?: number,
        startedAfter?: string,
        startedAt?: string,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/process-instance/running',
            path: {
                'name': name,
            },
            query: {
                'maxResults': maxResults,
                'startedAfter': startedAfter,
                'startedAt': startedAt,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeTaskInstanceCompleted({
        name,
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        name: string,
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/task-instance/completed',
            path: {
                'name': name,
            },
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public getEngineOptimizeTaskInstanceRunning({
        name,
        maxResults,
        startedAfter,
        startedAt,
    }: {
        name: string,
        maxResults?: number,
        startedAfter?: string,
        startedAt?: string,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/task-instance/running',
            path: {
                'name': name,
            },
            query: {
                'maxResults': maxResults,
                'startedAfter': startedAfter,
                'startedAt': startedAt,
            },
        });
    }

    /**
     * @returns UserOperationLogEntryDto OK
     * @throws ApiError
     */
    public getEngineOptimizeUserOperation({
        name,
        maxResults,
        occurredAfter,
        occurredAt,
    }: {
        name: string,
        maxResults?: number,
        occurredAfter?: string,
        occurredAt?: string,
    }): Observable<Array<UserOperationLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/user-operation',
            path: {
                'name': name,
            },
            query: {
                'maxResults': maxResults,
                'occurredAfter': occurredAfter,
                'occurredAt': occurredAt,
            },
        });
    }

    /**
     * @returns OptimizeHistoricVariableUpdateDto OK
     * @throws ApiError
     */
    public getEngineOptimizeVariableUpdate({
        name,
        excludeObjectValues,
        maxResults,
        occurredAfter,
        occurredAt,
    }: {
        name: string,
        excludeObjectValues?: boolean,
        maxResults?: number,
        occurredAfter?: string,
        occurredAt?: string,
    }): Observable<Array<OptimizeHistoricVariableUpdateDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/optimize/variable-update',
            path: {
                'name': name,
            },
            query: {
                'excludeObjectValues': excludeObjectValues,
                'maxResults': maxResults,
                'occurredAfter': occurredAfter,
                'occurredAt': occurredAt,
            },
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinition({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<ProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKey({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<ProcessDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineProcessDefinitionKey({
        name,
        key,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        name: string,
        key: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-definition/key/{key}',
            path: {
                'name': name,
                'key': key,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineProcessDefinitionKeyDelete({
        name,
        key,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        name: string,
        key: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-definition/key/{key}/delete',
            path: {
                'name': name,
                'key': key,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyDeployedStartForm({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/deployed-start-form',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyDiagram({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/diagram',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyFormVariables({
        name,
        key,
        deserializeValues = true,
        variableNames,
    }: {
        name: string,
        key: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/form-variables',
            path: {
                'name': name,
                'key': key,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionKeyHistoryTimeToLive({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/key/{key}/history-time-to-live',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyRenderedForm({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/rendered-form',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyRestart({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/restart',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyRestartAsync({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/restart-async',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyStart({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/start',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyStartForm({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/startForm',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns CalledProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyStaticCalledProcessDefinitions({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<Array<CalledProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/static-called-process-definitions',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyStatistics({
        name,
        key,
        failedJobs,
        incidents,
        incidentsForType,
    }: {
        name: string,
        key: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/statistics',
            path: {
                'name': name,
                'key': key,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeySubmitForm({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/submit-form',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionKeySuspended({
        name,
        key,
        requestBody,
    }: {
        name: string,
        key: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/key/{key}/suspended',
            path: {
                'name': name,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantId({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<ProcessDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineProcessDefinitionKeyTenantId({
        name,
        key,
        tenantId,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        name: string,
        key: string,
        tenantId: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineProcessDefinitionKeyTenantIdDelete({
        name,
        key,
        tenantId,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        name: string,
        key: string,
        tenantId: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/delete',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdDeployedStartForm({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/deployed-start-form',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdDiagram({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/diagram',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdFormVariables({
        name,
        key,
        tenantId,
        deserializeValues = true,
        variableNames,
    }: {
        name: string,
        key: string,
        tenantId: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/form-variables',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionKeyTenantIdHistoryTimeToLive({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/history-time-to-live',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdRenderedForm({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/rendered-form',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyTenantIdRestart({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/restart',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyTenantIdRestartAsync({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/restart-async',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyTenantIdStart({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/start',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdStartForm({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/startForm',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns CalledProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdStaticCalledProcessDefinitions({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<Array<CalledProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/static-called-process-definitions',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdStatistics({
        name,
        key,
        tenantId,
        failedJobs,
        incidents,
        incidentsForType,
    }: {
        name: string,
        key: string,
        tenantId: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/statistics',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionKeyTenantIdSubmitForm({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/submit-form',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionKeyTenantIdSuspended({
        name,
        key,
        tenantId,
        requestBody,
    }: {
        name: string,
        key: string,
        tenantId: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/suspended',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyTenantIdXml({
        name,
        key,
        tenantId,
    }: {
        name: string,
        key: string,
        tenantId: string,
    }): Observable<ProcessDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/tenant-id/{tenantId}/xml',
            path: {
                'name': name,
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns ProcessDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionKeyXml({
        name,
        key,
    }: {
        name: string,
        key: string,
    }): Observable<ProcessDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/key/{key}/xml',
            path: {
                'name': name,
                'key': key,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionStatistics({
        name,
        failedJobs,
        incidents,
        incidentsForType,
        rootIncidents,
    }: {
        name: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
        rootIncidents?: boolean,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/statistics',
            path: {
                'name': name,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
                'rootIncidents': rootIncidents,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionSuspended({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/suspended',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinition1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ProcessDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteEngineProcessDefinition({
        name,
        id,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        name: string,
        id: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-definition/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionDeployedStartForm({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/deployed-start-form',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionDiagram({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/diagram',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionFormVariables({
        name,
        id,
        deserializeValues = true,
        variableNames,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/form-variables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionHistoryTimeToLive({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/{id}/history-time-to-live',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionRenderedForm({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/rendered-form',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineProcessDefinitionRestart({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/{id}/restart',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionRestartAsync({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/{id}/restart-async',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionStart({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/{id}/start',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionStartForm({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/startForm',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CalledProcessDefinitionDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionStaticCalledProcessDefinitions({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<Array<CalledProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/static-called-process-definitions',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionStatistics1({
        name,
        id,
        failedJobs,
        incidents,
        incidentsForType,
    }: {
        name: string,
        id: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/statistics',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessDefinitionSubmitForm({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-definition/{id}/submit-form',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessDefinitionSuspended1({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-definition/{id}/suspended',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getEngineProcessDefinitionXml({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ProcessDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-definition/{id}/xml',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getEngineProcessInstance({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postEngineProcessInstance({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineProcessInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceDelete({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: DeleteHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/delete',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceDeleteHistoricQueryBased({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: DeleteHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/delete-historic-query-based',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceJobRetries({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetJobRetriesByProcessDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/job-retries',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceJobRetriesHistoricQueryBased({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetJobRetriesByProcessDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/job-retries-historic-query-based',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceMessageAsync({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: CorrelationMessageAsyncDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/message-async',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessInstanceSuspended({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ProcessInstanceSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-instance/suspended',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceSuspendedAsync({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: ProcessInstanceSuspensionStateAsyncDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/suspended-async',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceVariablesAsync({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SetVariablesAsyncDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/variables-async',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getEngineProcessInstance1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineProcessInstance({
        name,
        id,
        failIfNotExists = true,
        skipCustomListeners = false,
        skipIoMappings = false,
        skipSubprocesses = false,
    }: {
        name: string,
        id: string,
        failIfNotExists?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
        skipSubprocesses?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'failIfNotExists': failIfNotExists,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
                'skipSubprocesses': skipSubprocesses,
            },
        });
    }

    /**
     * @returns ActivityInstanceDto OK
     * @throws ApiError
     */
    public getEngineProcessInstanceActivityInstances({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance/{id}/activity-instances',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineProcessInstanceModification({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ProcessInstanceModificationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/{id}/modification',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postEngineProcessInstanceModificationAsync({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: ProcessInstanceModificationDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/{id}/modification-async',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessInstanceSuspended1({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: SuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-instance/{id}/suspended',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineProcessInstanceVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineProcessInstanceVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineProcessInstanceVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineProcessInstanceVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/process-instance/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineProcessInstanceVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/process-instance/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineProcessInstanceVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/process-instance/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineProcessInstanceVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/process-instance/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns SchemaLogEntryDto OK
     * @throws ApiError
     */
    public getEngineSchemaLog({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<SchemaLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/schema/log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns SchemaLogEntryDto OK
     * @throws ApiError
     */
    public postEngineSchemaLog({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: SchemaLogQueryDto,
    }): Observable<Array<SchemaLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/schema/log',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineSignal({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: SignalDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/signal',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTask({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns TaskDto OK
     * @throws ApiError
     */
    public postEngineTask({
        name,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: TaskQueryDto,
    }): Observable<Array<TaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineTaskCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineTaskCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: TaskQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskCreate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: TaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/create',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTaskReportCandidateGroupCount({
        name,
    }: {
        name: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/report/candidate-group-count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTask1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineTask({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: TaskDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/task/{id}',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTask({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/task/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskAssignee({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: UserIdDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/assignee',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AttachmentDto OK
     * @throws ApiError
     */
    public getEngineTaskAttachment({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<Array<AttachmentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/attachment',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns AttachmentDto OK
     * @throws ApiError
     */
    public postEngineTaskAttachmentCreate({
        name,
        id,
        formData,
    }: {
        name: string,
        id: string,
        formData?: MultipartFormData,
    }): Observable<AttachmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/attachment/create',
            path: {
                'name': name,
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns AttachmentDto OK
     * @throws ApiError
     */
    public getEngineTaskAttachment1({
        name,
        id,
        attachmentId,
    }: {
        name: string,
        id: string,
        attachmentId: string,
    }): Observable<AttachmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/attachment/{attachmentId}',
            path: {
                'name': name,
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTaskAttachment({
        name,
        id,
        attachmentId,
    }: {
        name: string,
        id: string,
        attachmentId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/task/{id}/attachment/{attachmentId}',
            path: {
                'name': name,
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }

    /**
     * @returns binary OK
     * @throws ApiError
     */
    public getEngineTaskAttachmentData({
        name,
        id,
        attachmentId,
    }: {
        name: string,
        id: string,
        attachmentId: string,
    }): Observable<Blob> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/attachment/{attachmentId}/data',
            path: {
                'name': name,
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskBpmnError({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: TaskBpmnErrorDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/bpmnError',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskBpmnEscalation({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: TaskEscalationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/bpmnEscalation',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskClaim({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: UserIdDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/claim',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CommentDto OK
     * @throws ApiError
     */
    public getEngineTaskComment({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<Array<CommentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/comment',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns CommentDto OK
     * @throws ApiError
     */
    public postEngineTaskCommentCreate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CommentDto,
    }): Observable<CommentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/comment/create',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CommentDto OK
     * @throws ApiError
     */
    public getEngineTaskComment1({
        name,
        id,
        commentId,
    }: {
        name: string,
        id: string,
        commentId: string,
    }): Observable<CommentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/comment/{commentId}',
            path: {
                'name': name,
                'id': id,
                'commentId': commentId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postEngineTaskComplete({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CompleteTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/complete',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskDelegate({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: UserIdDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/delegate',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTaskDeployedForm({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/deployed-form',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getEngineTaskForm({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/form',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineTaskFormVariables({
        name,
        id,
        deserializeValues = true,
        variableNames,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/form-variables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns IdentityLinkDto OK
     * @throws ApiError
     */
    public getEngineTaskIdentityLinks({
        name,
        id,
        type,
    }: {
        name: string,
        id: string,
        type?: string,
    }): Observable<Array<IdentityLinkDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/identity-links',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskIdentityLinks({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: IdentityLinkDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/identity-links',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskIdentityLinksDelete({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: IdentityLinkDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/identity-links/delete',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineTaskLocalVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/localVariables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskLocalVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/localVariables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineTaskLocalVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineTaskLocalVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/task/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTaskLocalVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/task/{id}/localVariables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTaskLocalVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/localVariables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskLocalVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/localVariables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTaskRenderedForm({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/rendered-form',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskResolve({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CompleteTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/resolve',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postEngineTaskSubmitForm({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: CompleteTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/submit-form',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskUnclaim({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/unclaim',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineTaskVariables({
        name,
        id,
        deserializeValues = true,
    }: {
        name: string,
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskVariables({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/variables',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getEngineTaskVariables1({
        name,
        id,
        varId,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineTaskVariables({
        name,
        id,
        varId,
        requestBody,
    }: {
        name: string,
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/task/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTaskVariables({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/task/{id}/variables/{varId}',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineTaskVariablesData({
        name,
        id,
        varId,
    }: {
        name: string,
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/task/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTaskVariablesData({
        name,
        id,
        varId,
        formData,
    }: {
        name: string,
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/task/{id}/variables/{varId}/data',
            path: {
                'name': name,
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns TelemetryConfigurationDto OK
     * @throws ApiError
     */
    public getEngineTelemetryConfiguration({
        name,
    }: {
        name: string,
    }): Observable<TelemetryConfigurationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/telemetry/configuration',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTelemetryConfiguration({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: TelemetryConfigurationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/telemetry/configuration',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TelemetryDataDto OK
     * @throws ApiError
     */
    public getEngineTelemetryData({
        name,
    }: {
        name: string,
    }): Observable<TelemetryDataDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/telemetry/data',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns TenantDto OK
     * @throws ApiError
     */
    public getEngineTenant({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<TenantDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/tenant',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineTenant({
        name,
    }: {
        name: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/tenant',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineTenantCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/tenant/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineTenantCreate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: TenantDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/tenant/create',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TenantDto OK
     * @throws ApiError
     */
    public getEngineTenant1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<TenantDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/tenant/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineTenant({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: TenantDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/tenant/{id}',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTenant({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/tenant/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineTenant1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/tenant/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineTenantGroupMembers({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/tenant/{id}/group-members',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineTenantGroupMembers({
        name,
        id,
        groupId,
    }: {
        name: string,
        id: string,
        groupId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/tenant/{id}/group-members/{groupId}',
            path: {
                'name': name,
                'id': id,
                'groupId': groupId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTenantGroupMembers({
        name,
        id,
        groupId,
    }: {
        name: string,
        id: string,
        groupId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/tenant/{id}/group-members/{groupId}',
            path: {
                'name': name,
                'id': id,
                'groupId': groupId,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineTenantUserMembers({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/tenant/{id}/user-members',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineTenantUserMembers({
        name,
        id,
        userId,
    }: {
        name: string,
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/tenant/{id}/user-members/{userId}',
            path: {
                'name': name,
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineTenantUserMembers({
        name,
        id,
        userId,
    }: {
        name: string,
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/tenant/{id}/user-members/{userId}',
            path: {
                'name': name,
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns UserProfileDto OK
     * @throws ApiError
     */
    public getEngineUser({
        name,
        firstResult,
        maxResults,
    }: {
        name: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<UserProfileDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/user',
            path: {
                'name': name,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineUser({
        name,
    }: {
        name: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/user',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineUserCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/user/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineUserCreate({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: UserDto1,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/user/create',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteEngineUser({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/engine/{name}/user/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsEngineUser1({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/engine/{name}/user/{id}',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineUserCredentials({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: UserCredentialsDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/user/{id}/credentials',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserProfileDto OK
     * @throws ApiError
     */
    public getEngineUserProfile({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<UserProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/user/{id}/profile',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putEngineUserProfile({
        name,
        id,
        requestBody,
    }: {
        name: string,
        id: string,
        requestBody?: UserProfileDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/engine/{name}/user/{id}/profile',
            path: {
                'name': name,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postEngineUserUnlock({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/user/{id}/unlock',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getEngineVariableInstance({
        name,
        deserializeValues = true,
        firstResult,
        maxResults,
    }: {
        name: string,
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/variable-instance',
            path: {
                'name': name,
            },
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public postEngineVariableInstance({
        name,
        deserializeValues = true,
        firstResult,
        maxResults,
        requestBody,
    }: {
        name: string,
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricDetailQueryDto,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/variable-instance',
            path: {
                'name': name,
            },
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEngineVariableInstanceCount({
        name,
    }: {
        name: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/variable-instance/count',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postEngineVariableInstanceCount({
        name,
        requestBody,
    }: {
        name: string,
        requestBody?: HistoricDetailQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/engine/{name}/variable-instance/count',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getEngineVariableInstance1({
        name,
        id,
        deserializeValue = true,
    }: {
        name: string,
        id: string,
        deserializeValue?: boolean,
    }): Observable<HistoricDetailDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/variable-instance/{id}',
            path: {
                'name': name,
                'id': id,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getEngineVariableInstanceData({
        name,
        id,
    }: {
        name: string,
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/variable-instance/{id}/data',
            path: {
                'name': name,
                'id': id,
            },
        });
    }

    /**
     * @returns VersionDto OK
     * @throws ApiError
     */
    public getEngineVersion({
        name,
    }: {
        name: string,
    }): Observable<VersionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/engine/{name}/version',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns EventSubscriptionDto OK
     * @throws ApiError
     */
    public getEventSubscription({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<EventSubscriptionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/event-subscription',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getEventSubscriptionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/event-subscription/count',
        });
    }

    /**
     * @returns ExecutionDto OK
     * @throws ApiError
     */
    public getExecution({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<ExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ExecutionDto OK
     * @throws ApiError
     */
    public postExecution({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: ExecutionQueryDto,
    }): Observable<Array<ExecutionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getExecutionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postExecutionCount({
        requestBody,
    }: {
        requestBody?: ExecutionQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ExecutionDto OK
     * @throws ApiError
     */
    public getExecution1({
        id,
    }: {
        id: string,
    }): Observable<ExecutionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public postExecutionCreateIncident({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CreateIncidentDto,
    }): Observable<HistoricIncidentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution/{id}/create-incident',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getExecutionLocalVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution/{id}/localVariables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExecutionLocalVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution/{id}/localVariables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getExecutionLocalVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putExecutionLocalVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/execution/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteExecutionLocalVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/execution/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getExecutionLocalVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution/{id}/localVariables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExecutionLocalVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution/{id}/localVariables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns EventSubscriptionDto OK
     * @throws ApiError
     */
    public getExecutionMessageSubscriptions({
        id,
        messageName,
    }: {
        id: string,
        messageName: string,
    }): Observable<EventSubscriptionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/execution/{id}/messageSubscriptions/{messageName}',
            path: {
                'id': id,
                'messageName': messageName,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExecutionMessageSubscriptionsTrigger({
        id,
        messageName,
        requestBody,
    }: {
        id: string,
        messageName: string,
        requestBody?: ExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution/{id}/messageSubscriptions/{messageName}/trigger',
            path: {
                'id': id,
                'messageName': messageName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExecutionSignal({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ExecutionTriggerDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/execution/{id}/signal',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ExternalTaskDto OK
     * @throws ApiError
     */
    public getExternalTask({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<ExternalTaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ExternalTaskDto OK
     * @throws ApiError
     */
    public postExternalTask({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: ExternalTaskQueryDto,
    }): Observable<Array<ExternalTaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public getExternalTaskLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricExternalTaskLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public postExternalTaskLog({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricExternalTaskLogQueryDto,
    }): Observable<Array<HistoricExternalTaskLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getExternalTaskLogCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task-log/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postExternalTaskLogCount({
        requestBody,
    }: {
        requestBody?: HistoricExternalTaskLogQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task-log/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public getExternalTaskLog1({
        id,
    }: {
        id: string,
    }): Observable<HistoricExternalTaskLogDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task-log/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getExternalTaskLogErrorDetails({
        id,
    }: {
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task-log/{id}/error-details',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getExternalTaskCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postExternalTaskCount({
        requestBody,
    }: {
        requestBody?: ExternalTaskQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LockedExternalTaskDto OK
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskFetchAndLock({
        requestBody,
    }: {
        requestBody?: FetchExternalTasksDto,
    }): Observable<Array<LockedExternalTaskDto> | any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/fetchAndLock',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putExternalTaskRetries({
        requestBody,
    }: {
        requestBody?: SetRetriesForExternalTasksDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/external-task/retries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postExternalTaskRetriesAsync({
        requestBody,
    }: {
        requestBody?: SetRetriesForExternalTasksDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/retries-async',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getExternalTaskTopicNames({
        withLockedTasks,
        withRetriesLeft,
        withUnlockedTasks,
    }: {
        withLockedTasks?: boolean,
        withRetriesLeft?: boolean,
        withUnlockedTasks?: boolean,
    }): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task/topic-names',
            query: {
                'withLockedTasks': withLockedTasks,
                'withRetriesLeft': withRetriesLeft,
                'withUnlockedTasks': withUnlockedTasks,
            },
        });
    }

    /**
     * @returns ExternalTaskDto OK
     * @throws ApiError
     */
    public getExternalTask1({
        id,
    }: {
        id: string,
    }): Observable<ExternalTaskDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskBpmnError({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ExternalTaskBpmnError,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/{id}/bpmnError',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskComplete({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CompleteExternalTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/{id}/complete',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getExternalTaskErrorDetails({
        id,
    }: {
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/external-task/{id}/errorDetails',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskExtendLock({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ExtendLockOnExternalTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/{id}/extendLock',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskFailure({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ExternalTaskFailureDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/{id}/failure',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskLock({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: LockExternalTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/{id}/lock',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putExternalTaskPriority({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PriorityDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/external-task/{id}/priority',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putExternalTaskRetries1({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: RetriesDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/external-task/{id}/retries',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postExternalTaskUnlock({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/external-task/{id}/unlock',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns FilterDto OK
     * @throws ApiError
     */
    public getFilter({
        firstResult,
        itemCount,
        maxResults,
    }: {
        firstResult?: number,
        itemCount?: boolean,
        maxResults?: number,
    }): Observable<Array<FilterDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/filter',
            query: {
                'firstResult': firstResult,
                'itemCount': itemCount,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsFilter(): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/filter',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getFilterCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/filter/count',
        });
    }

    /**
     * @returns FilterDto OK
     * @throws ApiError
     */
    public postFilterCreate({
        requestBody,
    }: {
        requestBody?: FilterDto,
    }): Observable<FilterDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/filter/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FilterDto OK
     * @throws ApiError
     */
    public getFilter1({
        id,
        itemCount,
    }: {
        id: string,
        itemCount?: boolean,
    }): Observable<FilterDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/filter/{id}',
            path: {
                'id': id,
            },
            query: {
                'itemCount': itemCount,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putFilter({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: FilterDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/filter/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteFilter({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/filter/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsFilter1({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/filter/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getFilterCount1({
        id,
    }: {
        id: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/filter/{id}/count',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postFilterCount({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: string,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/filter/{id}/count',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getFilterList({
        id,
        firstResult,
        maxResults,
    }: {
        id: string,
        firstResult?: number,
        maxResults?: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/filter/{id}/list',
            path: {
                'id': id,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postFilterList({
        id,
        firstResult,
        maxResults,
        requestBody,
    }: {
        id: string,
        firstResult?: number,
        maxResults?: number,
        requestBody?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/filter/{id}/list',
            path: {
                'id': id,
            },
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getFilterSingleResult({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/filter/{id}/singleResult',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postFilterSingleResult({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/filter/{id}/singleResult',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GroupDto OK
     * @throws ApiError
     */
    public getGroup({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<GroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/group',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns GroupDto OK
     * @throws ApiError
     */
    public postGroup({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: GroupQueryDto,
    }): Observable<Array<GroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/group',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsGroup(): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/group',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getGroupCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/group/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postGroupCount({
        requestBody,
    }: {
        requestBody?: GroupQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/group/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postGroupCreate({
        requestBody,
    }: {
        requestBody?: GroupDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/group/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GroupDto OK
     * @throws ApiError
     */
    public getGroup1({
        id,
    }: {
        id: string,
    }): Observable<GroupDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/group/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putGroup({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: GroupDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/group/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteGroup({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/group/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsGroup1({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/group/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsGroupMembers({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/group/{id}/members',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putGroupMembers({
        id,
        userId,
    }: {
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/group/{id}/members/{userId}',
            path: {
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteGroupMembers({
        id,
        userId,
    }: {
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/group/{id}/members/{userId}',
            path: {
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getHistoryActivityInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/activity-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public postHistoryActivityInstance({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricActivityInstanceQueryDto,
    }): Observable<Array<HistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/activity-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryActivityInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/activity-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryActivityInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricActivityInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/activity-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getHistoryActivityInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/activity-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getHistoryBatch({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricBatchDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/batch',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CleanableHistoricBatchReportResultDto OK
     * @throws ApiError
     */
    public getHistoryBatchCleanableBatchReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricBatchReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/batch/cleanable-batch-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryBatchCleanableBatchReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/batch/cleanable-batch-report/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryBatchCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/batch/count',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postHistoryBatchSetRemovalTime({
        requestBody,
    }: {
        requestBody?: SetRemovalTimeToHistoricBatchesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/batch/set-removal-time',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public getHistoryBatch1({
        id,
    }: {
        id: string,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/batch/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteHistoryBatch({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/history/batch/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricCaseActivityInstanceDto OK
     * @throws ApiError
     */
    public getHistoryCaseActivityInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-activity-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryCaseActivityInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-activity-instance/count',
        });
    }

    /**
     * @returns HistoricCaseActivityInstanceDto OK
     * @throws ApiError
     */
    public getHistoryCaseActivityInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricCaseActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-activity-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CleanableHistoricCaseInstanceReportResultDto OK
     * @throws ApiError
     */
    public getHistoryCaseDefinitionCleanableCaseInstanceReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricCaseInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-definition/cleanable-case-instance-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryCaseDefinitionCleanableCaseInstanceReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-definition/cleanable-case-instance-report/count',
        });
    }

    /**
     * @returns HistoricCaseActivityStatisticsDto OK
     * @throws ApiError
     */
    public getHistoryCaseDefinitionStatistics({
        id,
    }: {
        id: string,
    }): Observable<Array<HistoricCaseActivityStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-definition/{id}/statistics',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getHistoryCaseInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public postHistoryCaseInstance({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<Array<HistoricCaseInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/case-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryCaseInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryCaseInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricCaseInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/case-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricCaseInstanceDto OK
     * @throws ApiError
     */
    public getHistoryCaseInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricCaseInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/case-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public postHistoryCleanup({
        immediatelyDue = true,
    }: {
        immediatelyDue?: boolean,
    }): Observable<JobDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/cleanup',
            query: {
                'immediatelyDue': immediatelyDue,
            },
        });
    }

    /**
     * @returns HistoryCleanupConfigurationDto OK
     * @throws ApiError
     */
    public getHistoryCleanupConfiguration(): Observable<HistoryCleanupConfigurationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/cleanup/configuration',
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getHistoryCleanupJob(): Observable<JobDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/cleanup/job',
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getHistoryCleanupJobs(): Observable<Array<JobDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/cleanup/jobs',
        });
    }

    /**
     * @returns CleanableHistoricDecisionInstanceReportResultDto OK
     * @throws ApiError
     */
    public getHistoryDecisionDefinitionCleanableDecisionInstanceReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricDecisionInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/decision-definition/cleanable-decision-instance-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryDecisionDefinitionCleanableDecisionInstanceReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/decision-definition/cleanable-decision-instance-report/count',
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getHistoryDecisionInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDecisionInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/decision-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryDecisionInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/decision-instance/count',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postHistoryDecisionInstanceDelete({
        requestBody,
    }: {
        requestBody?: DeleteHistoricDecisionInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/decision-instance/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postHistoryDecisionInstanceSetRemovalTime({
        requestBody,
    }: {
        requestBody?: SetRemovalTimeToHistoricDecisionInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/decision-instance/set-removal-time',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getHistoryDecisionInstance1({
        id,
        disableBinaryFetching,
        disableCustomObjectDeserialization,
        includeInputs,
        includeOutputs,
    }: {
        id: string,
        disableBinaryFetching?: boolean,
        disableCustomObjectDeserialization?: boolean,
        includeInputs?: boolean,
        includeOutputs?: boolean,
    }): Observable<HistoricDecisionInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/decision-instance/{id}',
            path: {
                'id': id,
            },
            query: {
                'disableBinaryFetching': disableBinaryFetching,
                'disableCustomObjectDeserialization': disableCustomObjectDeserialization,
                'includeInputs': includeInputs,
                'includeOutputs': includeOutputs,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceStatisticsDto OK
     * @throws ApiError
     */
    public getHistoryDecisionRequirementsDefinitionStatistics({
        id,
        decisionInstanceId,
    }: {
        id: string,
        decisionInstanceId?: string,
    }): Observable<Array<HistoricDecisionInstanceStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/decision-requirements-definition/{id}/statistics',
            path: {
                'id': id,
            },
            query: {
                'decisionInstanceId': decisionInstanceId,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getHistoryDetail({
        deserializeValues = true,
        firstResult,
        maxResults,
    }: {
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/detail',
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public postHistoryDetail({
        deserializeValues = true,
        firstResult,
        maxResults,
        requestBody,
    }: {
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricDetailQueryDto,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/detail',
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryDetailCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/detail/count',
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getHistoryDetail1({
        id,
        deserializeValue = true,
    }: {
        id: string,
        deserializeValue?: boolean,
    }): Observable<HistoricDetailDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/detail/{id}',
            path: {
                'id': id,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getHistoryDetailData({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/detail/{id}/data',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public getHistoryExternalTaskLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricExternalTaskLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/external-task-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public postHistoryExternalTaskLog({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricExternalTaskLogQueryDto,
    }): Observable<Array<HistoricExternalTaskLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/external-task-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryExternalTaskLogCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/external-task-log/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryExternalTaskLogCount({
        requestBody,
    }: {
        requestBody?: HistoricExternalTaskLogQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/external-task-log/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricExternalTaskLogDto OK
     * @throws ApiError
     */
    public getHistoryExternalTaskLog1({
        id,
    }: {
        id: string,
    }): Observable<HistoricExternalTaskLogDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/external-task-log/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getHistoryExternalTaskLogErrorDetails({
        id,
    }: {
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/external-task-log/{id}/error-details',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricIdentityLinkLogDto OK
     * @throws ApiError
     */
    public getHistoryIdentityLinkLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIdentityLinkLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/identity-link-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryIdentityLinkLogCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/identity-link-log/count',
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getHistoryIncident({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/incident',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryIncidentCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/incident/count',
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public getHistoryJobLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricJobLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/job-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public postHistoryJobLog({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricJobLogQueryDto,
    }): Observable<Array<HistoricJobLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/job-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryJobLogCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/job-log/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryJobLogCount({
        requestBody,
    }: {
        requestBody?: HistoricJobLogQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/job-log/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public getHistoryJobLog1({
        id,
    }: {
        id: string,
    }): Observable<HistoricJobLogDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/job-log/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getHistoryJobLogStacktrace({
        id,
    }: {
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/job-log/{id}/stacktrace',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CleanableHistoricProcessInstanceReportResultDto OK
     * @throws ApiError
     */
    public getHistoryProcessDefinitionCleanableProcessInstanceReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricProcessInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-definition/cleanable-process-instance-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryProcessDefinitionCleanableProcessInstanceReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-definition/cleanable-process-instance-report/count',
        });
    }

    /**
     * @returns HistoricActivityStatisticsDto OK
     * @throws ApiError
     */
    public getHistoryProcessDefinitionStatistics({
        id,
    }: {
        id: string,
    }): Observable<Array<HistoricActivityStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-definition/{id}/statistics',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getHistoryProcessInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postHistoryProcessInstance({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/process-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryProcessInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryProcessInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/process-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postHistoryProcessInstanceDelete({
        requestBody,
    }: {
        requestBody?: DeleteHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/process-instance/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getHistoryProcessInstanceReport(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-instance/report',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postHistoryProcessInstanceSetRemovalTime({
        requestBody,
    }: {
        requestBody?: SetRemovalTimeToHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/process-instance/set-removal-time',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getHistoryProcessInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/process-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteHistoryProcessInstance({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/history/process-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteHistoryProcessInstanceVariableInstances({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/history/process-instance/{id}/variable-instances',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public getHistoryTask({
        firstResult,
        maxResults,
        taskId,
        processInstanceId,
        sortBy,
        sortOrder,
    }: {
        firstResult?: number,
        maxResults?: number,
        taskId?: string,
        processInstanceId?: string,
        sortBy?: string,
        sortOrder?: string,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/task',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
                'taskId': taskId,
                'processInstanceId': processInstanceId,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public postHistoryTask({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricTaskInstanceQueryDto,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/task',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryTaskCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/task/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryTaskCount({
        requestBody,
    }: {
        requestBody?: HistoricTaskInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/task/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getHistoryTaskReport(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/task/report',
        });
    }

    /**
     * @returns UserOperationLogEntryDto OK
     * @throws ApiError
     */
    public getHistoryUserOperation({
        firstResult,
        maxResults,
        taskId,
        sortOrder,
        sortBy,
        operationType,
    }: {
        firstResult?: number,
        maxResults?: number,
        taskId?: string,
        sortOrder?: 'asc' | 'desc',
        sortBy?: 'timestamp',
        operationType?: 'Assign' | 'Claim' | 'Complete' | 'Create' | 'Delegate' | 'Delete' | 'Resolve' | 'SetOwner' | 'SetPriority' | 'Update',
    }): Observable<Array<UserOperationLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/user-operation',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
                'taskId': taskId,
                'sortOrder': sortOrder,
                'sortBy': sortBy,
                'operationType': operationType,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryUserOperationCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/user-operation/count',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putHistoryUserOperationClearAnnotation({
        operationId,
    }: {
        operationId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/history/user-operation/{operationId}/clear-annotation',
            path: {
                'operationId': operationId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putHistoryUserOperationSetAnnotation({
        operationId,
        requestBody,
    }: {
        operationId: string,
        requestBody?: AnnotationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/history/user-operation/{operationId}/set-annotation',
            path: {
                'operationId': operationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricVariableInstanceDto OK
     * @throws ApiError
     */
    public getHistoryVariableInstance({
        deserializeValues = true,
        firstResult,
        maxResults,
    }: {
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricVariableInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/variable-instance',
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricVariableInstanceDto OK
     * @throws ApiError
     */
    public postHistoryVariableInstance({
        deserializeValues = true,
        firstResult,
        maxResults,
        requestBody,
    }: {
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricVariableInstanceQueryDto,
    }): Observable<Array<HistoricVariableInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/variable-instance',
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getHistoryVariableInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/variable-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postHistoryVariableInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricVariableInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/history/variable-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricVariableInstanceDto OK
     * @throws ApiError
     */
    public getHistoryVariableInstance1({
        id,
        deserializeValue = true,
    }: {
        id: string,
        deserializeValue?: boolean,
    }): Observable<HistoricVariableInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/variable-instance/{id}',
            path: {
                'id': id,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteHistoryVariableInstance({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/history/variable-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getHistoryVariableInstanceData({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/history/variable-instance/{id}/data',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricIdentityLinkLogDto OK
     * @throws ApiError
     */
    public getIdentityLinkLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIdentityLinkLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/identity-link-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getIdentityLinkLogCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/identity-link-log/count',
        });
    }

    /**
     * @returns GroupInfoDto OK
     * @throws ApiError
     */
    public getIdentityGroups({
        userId,
    }: {
        userId?: string,
    }): Observable<GroupInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/identity/groups',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getIdentityPasswordPolicy(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/identity/password-policy',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postIdentityPasswordPolicy({
        requestBody,
    }: {
        requestBody?: PasswordPolicyRequestDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/identity/password-policy',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AuthenticationResult OK
     * @throws ApiError
     */
    public postIdentityVerify({
        requestBody,
    }: {
        requestBody?: BasicUserCredentialsDto,
    }): Observable<AuthenticationResult> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/identity/verify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getIncident({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/incident',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getIncidentCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/incident/count',
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getIncident1({
        id,
    }: {
        id: string,
    }): Observable<HistoricIncidentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/incident/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteIncident({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/incident/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putIncidentAnnotation({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: AnnotationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/incident/{id}/annotation',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteIncidentAnnotation({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/incident/{id}/annotation',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getJob({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<JobDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public postJob({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: JobQueryDto,
    }): Observable<Array<JobDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobDefinitionDto OK
     * @throws ApiError
     */
    public getJobDefinition({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<JobDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-definition',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns JobDefinitionDto OK
     * @throws ApiError
     */
    public postJobDefinition({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: JobDefinitionQueryDto,
    }): Observable<Array<JobDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job-definition',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getJobDefinitionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-definition/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postJobDefinitionCount({
        requestBody,
    }: {
        requestBody?: JobDefinitionQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job-definition/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobDefinitionSuspended({
        requestBody,
    }: {
        requestBody?: JobDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job-definition/suspended',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobDefinitionDto OK
     * @throws ApiError
     */
    public getJobDefinition1({
        id,
    }: {
        id: string,
    }): Observable<JobDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-definition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobDefinitionJobPriority({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: JobDefinitionPriorityDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job-definition/{id}/jobPriority',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobDefinitionRetries({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: RetriesDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job-definition/{id}/retries',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobDefinitionSuspended1({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: JobDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job-definition/{id}/suspended',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public getJobLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricJobLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public postJobLog({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricJobLogQueryDto,
    }): Observable<Array<HistoricJobLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job-log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getJobLogCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-log/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postJobLogCount({
        requestBody,
    }: {
        requestBody?: HistoricJobLogQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job-log/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricJobLogDto OK
     * @throws ApiError
     */
    public getJobLog1({
        id,
    }: {
        id: string,
    }): Observable<HistoricJobLogDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-log/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getJobLogStacktrace({
        id,
    }: {
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job-log/{id}/stacktrace',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getJobCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postJobCount({
        requestBody,
    }: {
        requestBody?: JobQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postJobRetries({
        requestBody,
    }: {
        requestBody?: SetJobRetriesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job/retries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobSuspended({
        requestBody,
    }: {
        requestBody?: JobSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job/suspended',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobDto OK
     * @throws ApiError
     */
    public getJob1({
        id,
    }: {
        id: string,
    }): Observable<JobDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteJob({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/job/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobDuedate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: JobDuedateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job/{id}/duedate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postJobDuedateRecalculate({
        id,
        creationDateBased = true,
    }: {
        id: string,
        creationDateBased?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job/{id}/duedate/recalculate',
            path: {
                'id': id,
            },
            query: {
                'creationDateBased': creationDateBased,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postJobExecute({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/job/{id}/execute',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobPriority({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PriorityDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job/{id}/priority',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobRetries({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: RetriesDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job/{id}/retries',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getJobStacktrace({
        id,
    }: {
        id: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/job/{id}/stacktrace',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putJobSuspended1({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: JobSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/job/{id}/suspended',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postMessage({
        requestBody,
    }: {
        requestBody?: CorrelationMessageDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/message',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MetricsIntervalResultDto OK
     * @throws ApiError
     */
    public getMetrics(): Observable<Array<MetricsIntervalResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/metrics',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteMetricsTaskWorker({
        date,
    }: {
        date?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/metrics/task-worker',
            query: {
                'date': date,
            },
        });
    }

    /**
     * @returns MetricsResultDto OK
     * @throws ApiError
     */
    public getMetricsSum({
        name,
    }: {
        name: string,
    }): Observable<MetricsResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/metrics/{name}/sum',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postMigrationExecute({
        requestBody,
    }: {
        requestBody?: MigrationExecutionDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/migration/execute',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postMigrationExecuteAsync({
        requestBody,
    }: {
        requestBody?: MigrationExecutionDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/migration/executeAsync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MigrationPlanDto OK
     * @throws ApiError
     */
    public postMigrationGenerate({
        requestBody,
    }: {
        requestBody?: MigrationPlanGenerationDto,
    }): Observable<MigrationPlanDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/migration/generate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MigrationPlanReportDto OK
     * @throws ApiError
     */
    public postMigrationValidate({
        requestBody,
    }: {
        requestBody?: MigrationPlanDto,
    }): Observable<MigrationPlanReportDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/migration/validate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postModificationExecute({
        requestBody,
    }: {
        requestBody?: ModificationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/modification/execute',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postModificationExecuteAsync({
        requestBody,
    }: {
        requestBody?: ModificationDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/modification/executeAsync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns OptimizeHistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeActivityInstanceCompleted({
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<OptimizeHistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/activity-instance/completed',
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns OptimizeHistoricActivityInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeActivityInstanceRunning({
        maxResults,
        startedAfter,
        startedAt,
    }: {
        maxResults?: number,
        startedAfter?: string,
        startedAt?: string,
    }): Observable<Array<OptimizeHistoricActivityInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/activity-instance/running',
            query: {
                'maxResults': maxResults,
                'startedAfter': startedAfter,
                'startedAt': startedAt,
            },
        });
    }

    /**
     * @returns HistoricDecisionInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeDecisionInstance({
        evaluatedAfter,
        evaluatedAt,
        maxResults,
    }: {
        evaluatedAfter?: string,
        evaluatedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricDecisionInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/decision-instance',
            query: {
                'evaluatedAfter': evaluatedAfter,
                'evaluatedAt': evaluatedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns OptimizeHistoricIdentityLinkLogDto OK
     * @throws ApiError
     */
    public getOptimizeIdentityLinkLog({
        maxResults,
        occurredAfter,
        occurredAt,
    }: {
        maxResults?: number,
        occurredAfter?: string,
        occurredAt?: string,
    }): Observable<Array<OptimizeHistoricIdentityLinkLogDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/identity-link-log',
            query: {
                'maxResults': maxResults,
                'occurredAfter': occurredAfter,
                'occurredAt': occurredAt,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getOptimizeIncidentCompleted({
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/incident/completed',
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricIncidentDto OK
     * @throws ApiError
     */
    public getOptimizeIncidentOpen({
        createdAfter,
        createdAt,
        maxResults,
    }: {
        createdAfter?: string,
        createdAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricIncidentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/incident/open',
            query: {
                'createdAfter': createdAfter,
                'createdAt': createdAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeProcessInstanceCompleted({
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/process-instance/completed',
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeProcessInstanceRunning({
        maxResults,
        startedAfter,
        startedAt,
    }: {
        maxResults?: number,
        startedAfter?: string,
        startedAt?: string,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/process-instance/running',
            query: {
                'maxResults': maxResults,
                'startedAfter': startedAfter,
                'startedAt': startedAt,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeTaskInstanceCompleted({
        finishedAfter,
        finishedAt,
        maxResults,
    }: {
        finishedAfter?: string,
        finishedAt?: string,
        maxResults?: number,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/task-instance/completed',
            query: {
                'finishedAfter': finishedAfter,
                'finishedAt': finishedAt,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricTaskInstanceDto OK
     * @throws ApiError
     */
    public getOptimizeTaskInstanceRunning({
        maxResults,
        startedAfter,
        startedAt,
    }: {
        maxResults?: number,
        startedAfter?: string,
        startedAt?: string,
    }): Observable<Array<HistoricTaskInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/task-instance/running',
            query: {
                'maxResults': maxResults,
                'startedAfter': startedAfter,
                'startedAt': startedAt,
            },
        });
    }

    /**
     * @returns UserOperationLogEntryDto OK
     * @throws ApiError
     */
    public getOptimizeUserOperation({
        maxResults,
        occurredAfter,
        occurredAt,
    }: {
        maxResults?: number,
        occurredAfter?: string,
        occurredAt?: string,
    }): Observable<Array<UserOperationLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/user-operation',
            query: {
                'maxResults': maxResults,
                'occurredAfter': occurredAfter,
                'occurredAt': occurredAt,
            },
        });
    }

    /**
     * @returns OptimizeHistoricVariableUpdateDto OK
     * @throws ApiError
     */
    public getOptimizeVariableUpdate({
        excludeObjectValues,
        maxResults,
        occurredAfter,
        occurredAt,
    }: {
        excludeObjectValues?: boolean,
        maxResults?: number,
        occurredAfter?: string,
        occurredAt?: string,
    }): Observable<Array<OptimizeHistoricVariableUpdateDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/optimize/variable-update',
            query: {
                'excludeObjectValues': excludeObjectValues,
                'maxResults': maxResults,
                'occurredAfter': occurredAfter,
                'occurredAt': occurredAt,
            },
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinition({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<ProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CleanableHistoricProcessInstanceReportResultDto OK
     * @throws ApiError
     */
    public getProcessDefinitionCleanableProcessInstanceReport({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<CleanableHistoricProcessInstanceReportResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/cleanable-process-instance-report',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getProcessDefinitionCleanableProcessInstanceReportCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/cleanable-process-instance-report/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getProcessDefinitionCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/count',
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKey({
        key,
    }: {
        key: string,
    }): Observable<ProcessDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteProcessDefinitionKey({
        key,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        key: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-definition/key/{key}',
            path: {
                'key': key,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteProcessDefinitionKeyDelete({
        key,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        key: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-definition/key/{key}/delete',
            path: {
                'key': key,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyDeployedStartForm({
        key,
    }: {
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/deployed-start-form',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyDiagram({
        key,
    }: {
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/diagram',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyFormVariables({
        key,
        deserializeValues = true,
        variableNames,
    }: {
        key: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/form-variables',
            path: {
                'key': key,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionKeyHistoryTimeToLive({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/key/{key}/history-time-to-live',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyRenderedForm({
        key,
    }: {
        key: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/rendered-form',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProcessDefinitionKeyRestart({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/restart',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessDefinitionKeyRestartAsync({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/restart-async',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessDefinitionKeyStart({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/start',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyStartForm({
        key,
    }: {
        key: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/startForm',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns CalledProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyStaticCalledProcessDefinitions({
        key,
    }: {
        key: string,
    }): Observable<Array<CalledProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/static-called-process-definitions',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyStatistics({
        key,
        failedJobs,
        incidents,
        incidentsForType,
    }: {
        key: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/statistics',
            path: {
                'key': key,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessDefinitionKeySubmitForm({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/submit-form',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionKeySuspended({
        key,
        requestBody,
    }: {
        key: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/key/{key}/suspended',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantId({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<ProcessDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteProcessDefinitionKeyTenantId({
        key,
        tenantId,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        key: string,
        tenantId: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteProcessDefinitionKeyTenantIdDelete({
        key,
        tenantId,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        key: string,
        tenantId: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/delete',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdDeployedStartForm({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/deployed-start-form',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdDiagram({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/diagram',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdFormVariables({
        key,
        tenantId,
        deserializeValues = true,
        variableNames,
    }: {
        key: string,
        tenantId: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/form-variables',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionKeyTenantIdHistoryTimeToLive({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/history-time-to-live',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdRenderedForm({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/rendered-form',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProcessDefinitionKeyTenantIdRestart({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/restart',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessDefinitionKeyTenantIdRestartAsync({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/restart-async',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessDefinitionKeyTenantIdStart({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/start',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdStartForm({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/startForm',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns CalledProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdStaticCalledProcessDefinitions({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<Array<CalledProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/static-called-process-definitions',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdStatistics({
        key,
        tenantId,
        failedJobs,
        incidents,
        incidentsForType,
    }: {
        key: string,
        tenantId: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/statistics',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessDefinitionKeyTenantIdSubmitForm({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/submit-form',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionKeyTenantIdSuspended({
        key,
        tenantId,
        requestBody,
    }: {
        key: string,
        tenantId: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/suspended',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyTenantIdXml({
        key,
        tenantId,
    }: {
        key: string,
        tenantId: string,
    }): Observable<ProcessDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/tenant-id/{tenantId}/xml',
            path: {
                'key': key,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns ProcessDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getProcessDefinitionKeyXml({
        key,
    }: {
        key: string,
    }): Observable<ProcessDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/key/{key}/xml',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns StatisticsResultDto OK
     * @throws ApiError
     */
    public getProcessDefinitionStatistics({
        failedJobs,
        incidents,
        incidentsForType,
        rootIncidents,
    }: {
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
        rootIncidents?: boolean,
    }): Observable<Array<StatisticsResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/statistics',
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
                'rootIncidents': rootIncidents,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionSuspended({
        requestBody,
    }: {
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/suspended',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinition1({
        id,
    }: {
        id: string,
    }): Observable<ProcessDefinitionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteProcessDefinition({
        id,
        cascade,
        skipCustomListeners,
        skipIoMappings,
    }: {
        id: string,
        cascade?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-definition/{id}',
            path: {
                'id': id,
            },
            query: {
                'cascade': cascade,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionDeployedStartForm({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/deployed-start-form',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionDiagram({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/diagram',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getProcessDefinitionFormVariables({
        id,
        deserializeValues = true,
        variableNames,
    }: {
        id: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/form-variables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionHistoryTimeToLive({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: HistoryTimeToLiveDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/{id}/history-time-to-live',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessDefinitionRenderedForm({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/rendered-form',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProcessDefinitionRestart({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/{id}/restart',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessDefinitionRestartAsync({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: RestartProcessInstanceDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/{id}/restart-async',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessDefinitionStart({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/{id}/start',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getProcessDefinitionStartForm({
        id,
    }: {
        id: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/startForm',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CalledProcessDefinitionDto OK
     * @throws ApiError
     */
    public getProcessDefinitionStaticCalledProcessDefinitions({
        id,
    }: {
        id: string,
    }): Observable<Array<CalledProcessDefinitionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/static-called-process-definitions',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricActivityStatisticsDto OK
     * @throws ApiError
     */
    public getProcessDefinitionStatistics1({
        id,
        failedJobs,
        incidents,
        incidentsForType,
    }: {
        id: string,
        failedJobs?: boolean,
        incidents?: boolean,
        incidentsForType?: string,
    }): Observable<Array<HistoricActivityStatisticsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/statistics',
            path: {
                'id': id,
            },
            query: {
                'failedJobs': failedJobs,
                'incidents': incidents,
                'incidentsForType': incidentsForType,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessDefinitionSubmitForm({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: StartProcessInstanceDto,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-definition/{id}/submit-form',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessDefinitionSuspended1({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ProcessDefinitionSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-definition/{id}/suspended',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProcessDefinitionDiagramDto OK
     * @throws ApiError
     */
    public getProcessDefinitionXml({
        id,
    }: {
        id: string,
    }): Observable<ProcessDefinitionDiagramDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-definition/{id}/xml',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getProcessInstance({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public postProcessInstance({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<Array<HistoricProcessInstanceDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getProcessInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postProcessInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricProcessInstanceQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceDelete({
        requestBody,
    }: {
        requestBody?: DeleteHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceDeleteHistoricQueryBased({
        requestBody,
    }: {
        requestBody?: DeleteHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/delete-historic-query-based',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceJobRetries({
        requestBody,
    }: {
        requestBody?: SetJobRetriesByProcessDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/job-retries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceJobRetriesHistoricQueryBased({
        requestBody,
    }: {
        requestBody?: SetJobRetriesByProcessDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/job-retries-historic-query-based',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceMessageAsync({
        requestBody,
    }: {
        requestBody?: CorrelationMessageAsyncDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/message-async',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessInstanceReport(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/report',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceSetRemovalTime({
        requestBody,
    }: {
        requestBody?: SetRemovalTimeToHistoricProcessInstancesDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/set-removal-time',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessInstanceSuspended({
        requestBody,
    }: {
        requestBody?: ProcessInstanceSuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-instance/suspended',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceSuspendedAsync({
        requestBody,
    }: {
        requestBody?: ProcessInstanceSuspensionStateAsyncDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/suspended-async',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceVariablesAsync({
        requestBody,
    }: {
        requestBody?: SetVariablesAsyncDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/variables-async',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricProcessInstanceDto OK
     * @throws ApiError
     */
    public getProcessInstance1({
        id,
    }: {
        id: string,
    }): Observable<HistoricProcessInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteProcessInstance({
        id,
        failIfNotExists = true,
        skipCustomListeners = false,
        skipIoMappings = false,
        skipSubprocesses = false,
    }: {
        id: string,
        failIfNotExists?: boolean,
        skipCustomListeners?: boolean,
        skipIoMappings?: boolean,
        skipSubprocesses?: boolean,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-instance/{id}',
            path: {
                'id': id,
            },
            query: {
                'failIfNotExists': failIfNotExists,
                'skipCustomListeners': skipCustomListeners,
                'skipIoMappings': skipIoMappings,
                'skipSubprocesses': skipSubprocesses,
            },
        });
    }

    /**
     * @returns ActivityInstanceDto OK
     * @throws ApiError
     */
    public getProcessInstanceActivityInstances({
        id,
    }: {
        id: string,
    }): Observable<ActivityInstanceDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/{id}/activity-instances',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProcessInstanceModification({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ProcessInstanceModificationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/{id}/modification',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HistoricBatchDto OK
     * @throws ApiError
     */
    public postProcessInstanceModificationAsync({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: ProcessInstanceModificationDto,
    }): Observable<HistoricBatchDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/{id}/modification-async',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessInstanceSuspended1({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: SuspensionStateDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-instance/{id}/suspended',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteProcessInstanceVariableInstances({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-instance/{id}/variable-instances',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getProcessInstanceVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/{id}/variables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProcessInstanceVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/{id}/variables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getProcessInstanceVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putProcessInstanceVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/process-instance/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteProcessInstanceVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/process-instance/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getProcessInstanceVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/process-instance/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProcessInstanceVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/process-instance/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns SchemaLogEntryDto OK
     * @throws ApiError
     */
    public getSchemaLog({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<SchemaLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/schema/log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns SchemaLogEntryDto OK
     * @throws ApiError
     */
    public postSchemaLog({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: SchemaLogQueryDto,
    }): Observable<Array<SchemaLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/schema/log',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postSignal({
        requestBody,
    }: {
        requestBody?: SignalDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/signal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTask({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns TaskDto OK
     * @throws ApiError
     */
    public postTask({
        firstResult,
        maxResults,
        requestBody,
    }: {
        firstResult?: number,
        maxResults?: number,
        requestBody?: TaskQueryDto,
    }): Observable<Array<TaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getTaskCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postTaskCount({
        requestBody,
    }: {
        requestBody?: TaskQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskCreate({
        requestBody,
    }: {
        requestBody?: TaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTaskReportCandidateGroupCount(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/report/candidate-group-count',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTask1({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putTask({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: TaskDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/task/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTask({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/task/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskAssignee({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: UserIdDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/assignee',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AttachmentDto OK
     * @throws ApiError
     */
    public getTaskAttachment({
        id,
    }: {
        id: string,
    }): Observable<Array<AttachmentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/attachment',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AttachmentDto OK
     * @throws ApiError
     */
    public postTaskAttachmentCreate({
        id,
        formData,
    }: {
        id: string,
        formData?: MultipartFormData,
    }): Observable<AttachmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/attachment/create',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns AttachmentDto OK
     * @throws ApiError
     */
    public getTaskAttachment1({
        id,
        attachmentId,
    }: {
        id: string,
        attachmentId: string,
    }): Observable<AttachmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/attachment/{attachmentId}',
            path: {
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTaskAttachment({
        id,
        attachmentId,
    }: {
        id: string,
        attachmentId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/task/{id}/attachment/{attachmentId}',
            path: {
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }

    /**
     * @returns binary OK
     * @throws ApiError
     */
    public getTaskAttachmentData({
        id,
        attachmentId,
    }: {
        id: string,
        attachmentId: string,
    }): Observable<Blob> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/attachment/{attachmentId}/data',
            path: {
                'id': id,
                'attachmentId': attachmentId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskBpmnError({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: TaskBpmnErrorDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/bpmnError',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskBpmnEscalation({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: TaskEscalationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/bpmnEscalation',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskClaim({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: UserIdDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/claim',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CommentDto OK
     * @throws ApiError
     */
    public getTaskComment({
        id,
    }: {
        id: string,
    }): Observable<Array<CommentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/comment',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CommentDto OK
     * @throws ApiError
     */
    public postTaskCommentCreate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CommentDto,
    }): Observable<CommentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/comment/create',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CommentDto OK
     * @throws ApiError
     */
    public getTaskComment1({
        id,
        commentId,
    }: {
        id: string,
        commentId: string,
    }): Observable<CommentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/comment/{commentId}',
            path: {
                'id': id,
                'commentId': commentId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postTaskComplete({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CompleteTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/complete',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskDelegate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: UserIdDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/delegate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTaskDeployedForm({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/deployed-form',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns FormDto OK
     * @throws ApiError
     */
    public getTaskForm({
        id,
    }: {
        id: string,
    }): Observable<FormDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/form',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getTaskFormVariables({
        id,
        deserializeValues = true,
        variableNames,
    }: {
        id: string,
        deserializeValues?: boolean,
        variableNames?: string,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/form-variables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
                'variableNames': variableNames,
            },
        });
    }

    /**
     * @returns IdentityLinkDto OK
     * @throws ApiError
     */
    public getTaskIdentityLinks({
        id,
        type,
    }: {
        id: string,
        type?: string,
    }): Observable<Array<IdentityLinkDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/identity-links',
            path: {
                'id': id,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskIdentityLinks({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: IdentityLinkDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/identity-links',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskIdentityLinksDelete({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: IdentityLinkDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/identity-links/delete',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getTaskLocalVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/localVariables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskLocalVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/localVariables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getTaskLocalVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putTaskLocalVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/task/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTaskLocalVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/task/{id}/localVariables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTaskLocalVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/localVariables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskLocalVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/localVariables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTaskRenderedForm({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/rendered-form',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskResolve({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CompleteTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/resolve',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public postTaskSubmitForm({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: CompleteTaskDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/submit-form',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskUnclaim({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/unclaim',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getTaskVariables({
        id,
        deserializeValues = true,
    }: {
        id: string,
        deserializeValues?: boolean,
    }): Observable<Record<string, VariableValueDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/variables',
            path: {
                'id': id,
            },
            query: {
                'deserializeValues': deserializeValues,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskVariables({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchVariablesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/variables',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VariableValueDto OK
     * @throws ApiError
     */
    public getTaskVariables1({
        id,
        varId,
        deserializeValue = true,
    }: {
        id: string,
        varId: string,
        deserializeValue?: boolean,
    }): Observable<VariableValueDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putTaskVariables({
        id,
        varId,
        requestBody,
    }: {
        id: string,
        varId: string,
        requestBody?: VariableValueDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/task/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTaskVariables({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/task/{id}/variables/{varId}',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getTaskVariablesData({
        id,
        varId,
    }: {
        id: string,
        varId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTaskVariablesData({
        id,
        varId,
        formData,
    }: {
        id: string,
        varId: string,
        formData?: MultipartFormData,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/task/{id}/variables/{varId}/data',
            path: {
                'id': id,
                'varId': varId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns TelemetryConfigurationDto OK
     * @throws ApiError
     */
    public getTelemetryConfiguration(): Observable<TelemetryConfigurationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/telemetry/configuration',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTelemetryConfiguration({
        requestBody,
    }: {
        requestBody?: TelemetryConfigurationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/telemetry/configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TelemetryDataDto OK
     * @throws ApiError
     */
    public getTelemetryData(): Observable<TelemetryDataDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/telemetry/data',
        });
    }

    /**
     * @returns TenantDto OK
     * @throws ApiError
     */
    public getTenant({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<TenantDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/tenant',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsTenant(): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/tenant',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getTenantCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/tenant/count',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postTenantCreate({
        requestBody,
    }: {
        requestBody?: TenantDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/tenant/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TenantDto OK
     * @throws ApiError
     */
    public getTenant1({
        id,
    }: {
        id: string,
    }): Observable<TenantDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/tenant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putTenant({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: TenantDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/tenant/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTenant({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/tenant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsTenant1({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/tenant/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsTenantGroupMembers({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/tenant/{id}/group-members',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putTenantGroupMembers({
        id,
        groupId,
    }: {
        id: string,
        groupId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/tenant/{id}/group-members/{groupId}',
            path: {
                'id': id,
                'groupId': groupId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTenantGroupMembers({
        id,
        groupId,
    }: {
        id: string,
        groupId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/tenant/{id}/group-members/{groupId}',
            path: {
                'id': id,
                'groupId': groupId,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsTenantUserMembers({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/tenant/{id}/user-members',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putTenantUserMembers({
        id,
        userId,
    }: {
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/tenant/{id}/user-members/{userId}',
            path: {
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteTenantUserMembers({
        id,
        userId,
    }: {
        id: string,
        userId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/tenant/{id}/user-members/{userId}',
            path: {
                'id': id,
                'userId': userId,
            },
        });
    }

    /**
     * @returns UserProfileDto OK
     * @throws ApiError
     */
    public getUser({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<UserProfileDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/user',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsUser(): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/user',
        });
    }

    /**
     * @returns UserOperationLogEntryDto OK
     * @throws ApiError
     */
    public getUserOperation({
        firstResult,
        maxResults,
    }: {
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<UserOperationLogEntryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/user-operation',
            query: {
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getUserOperationCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/user-operation/count',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putUserOperationClearAnnotation({
        operationId,
    }: {
        operationId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/user-operation/{operationId}/clear-annotation',
            path: {
                'operationId': operationId,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public putUserOperationSetAnnotation({
        operationId,
        requestBody,
    }: {
        operationId: string,
        requestBody?: AnnotationDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/user-operation/{operationId}/set-annotation',
            path: {
                'operationId': operationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getUserCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/user/count',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postUserCreate({
        requestBody,
    }: {
        requestBody?: UserDto1,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/user/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteUser({
        id,
    }: {
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ResourceOptionsDto OK
     * @throws ApiError
     */
    public optionsUser1({
        id,
    }: {
        id: string,
    }): Observable<ResourceOptionsDto> {
        return __request(OpenAPI, this.http, {
            method: 'OPTIONS',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putUserCredentials({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: UserCredentialsDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/user/{id}/credentials',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserProfileDto OK
     * @throws ApiError
     */
    public getUserProfile({
        id,
    }: {
        id: string,
    }): Observable<UserProfileDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/user/{id}/profile',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public putUserProfile({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: UserProfileDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/user/{id}/profile',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postUserUnlock({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/user/{id}/unlock',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getVariableInstance({
        deserializeValues = true,
        firstResult,
        maxResults,
    }: {
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/variable-instance',
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public postVariableInstance({
        deserializeValues = true,
        firstResult,
        maxResults,
        requestBody,
    }: {
        deserializeValues?: boolean,
        firstResult?: number,
        maxResults?: number,
        requestBody?: HistoricDetailQueryDto,
    }): Observable<Array<HistoricDetailDto>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/variable-instance',
            query: {
                'deserializeValues': deserializeValues,
                'firstResult': firstResult,
                'maxResults': maxResults,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public getVariableInstanceCount(): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/variable-instance/count',
        });
    }

    /**
     * @returns CountResultDto OK
     * @throws ApiError
     */
    public postVariableInstanceCount({
        requestBody,
    }: {
        requestBody?: HistoricDetailQueryDto,
    }): Observable<CountResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/variable-instance/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getVariableInstanceReport(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/variable-instance/report',
        });
    }

    /**
     * @returns HistoricDetailDto OK
     * @throws ApiError
     */
    public getVariableInstance1({
        id,
        deserializeValue = true,
    }: {
        id: string,
        deserializeValue?: boolean,
    }): Observable<HistoricDetailDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/variable-instance/{id}',
            path: {
                'id': id,
            },
            query: {
                'deserializeValue': deserializeValue,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteVariableInstance({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/variable-instance/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getVariableInstanceData({
        id,
    }: {
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/variable-instance/{id}/data',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VersionDto OK
     * @throws ApiError
     */
    public getVersion(): Observable<VersionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/version',
        });
    }

}
