import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { AssetDeal, Guarantor } from '@api/loan-approval';
import { combineLatest, filter, first } from 'rxjs';
import { exhaustiveCheck, getUniqueUuid } from '@sib/shared/util';
import {
  AssetCategoryFacadeService,
  AssetTypesFacadeService,
  CarBrandsFacadeService,
  EquipmentTypesFacadeService,
  MeasurementUnitsFacadeService,
  RegionsFacadeService,
} from '@sib/shared/store';
import { RegionDto } from '@api/loan-org-structure';
import { Dictionary } from '@ngrx/entity/src/models';
import { generateTermsSecuringTitle, generateTermsSecuringTitleGuarantor } from './utils';
import { AssetTypeDto, CarBrandDto, EquipmentTypeDto, MeasurementUnitDto } from '@api/dictionaries';
import { TermsSecuringItemComponent } from './terms-securing-item/terms-securing-item.component';
import { MatDialog } from '@angular/material/dialog';
import { TermsSecuringCreateItemDialogComponent } from './terms-securing-create-item-dialog/terms-securing-create-item-dialog.component';
import { ControlContainer, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TermsSecuringLoanGroupService } from './terms-securing-loan-group.service';
import { TERM_SECURING_LOAN, TermsSecuringLoanConfig } from './token';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AgreementsFacadeService, FullProvidingInfo } from '@sib/task/shared/store';

@Component({
  selector: 'sib-terms-securing-loan-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    TermsSecuringItemComponent,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: './terms-securing-loan-form.component.html',
  styleUrls: ['./terms-securing-loan-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TermsSecuringLoanFormComponent implements OnInit, OnChanges {
  @Input() dealNumber!: string;
  @Input() assetDeals!: AssetDeal[];
  @Input() assetComment = '';
  @Output() assetCommentChanged = new EventEmitter<string>();
  public termsSecuring: Record<string, { title: string; array: AssetDeal[] }> = {
    deposit: {
      title: 'Майнові права',
      array: [],
    },
    realty: {
      title: 'Нерухомість',
      array: [],
    },
    land: {
      title: 'Земля',
      array: [],
    },
    goods: {
      title: 'Товари',
      array: [],
    },
    equipment: {
      title: 'Обладнання',
      array: [],
    },
    vehicles: {
      title: 'Авто',
      array: [],
    },
    guarantor: {
      title: 'Порука',
      array: [],
    },
  };

  public regionsEntities!: Dictionary<RegionDto>;
  public measurementUtilsEntities!: Dictionary<MeasurementUnitDto>;
  public assetTypesEntities!: Dictionary<AssetTypeDto>;
  public equipmentTypesEntities!: Dictionary<EquipmentTypeDto>;
  public carBrandsEntities!: Dictionary<CarBrandDto>;
  public assets: FullProvidingInfo[] = [];
  public guarantors: Guarantor[] = [];
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private regionFacade: RegionsFacadeService,
    private measurementUtilsFacade: MeasurementUnitsFacadeService,
    private assetTypesFacade: AssetTypesFacadeService,
    private assetCategoryFacade: AssetCategoryFacadeService,
    private equipmentTypesFacade: EquipmentTypesFacadeService,
    private carBrandsFacade: CarBrandsFacadeService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public termsSecuringLoanGroupService: TermsSecuringLoanGroupService,
    private agreementsFacadeService: AgreementsFacadeService,
    @Inject(TERM_SECURING_LOAN) public termsSecuringLoanConfig: TermsSecuringLoanConfig,
  ) {}

  ngOnInit() {
    this.regionFacade.loadRegions();
    this.measurementUtilsFacade.loadMeasurementUtils();
    this.assetTypesFacade.loadAssetTypes();
    this.assetCategoryFacade.loadAssetCategories();
    this.equipmentTypesFacade.loadEquipmentTypes();
    this.carBrandsFacade.loadCarBrands();

    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dealNumber'] && !changes['dealNumber'].firstChange) {
      this.init();
    }
  }

  change() {
    this.assetCommentChanged.emit(this.assetComment);
  }

  init() {
    Object.entries(this.termsSecuring).forEach((item) => {
      this.termsSecuring[item[0]].array = [];
    });
    combineLatest([
      this.regionFacade.regionsEntities$.pipe(filter((obj) => !!Object.keys(obj).length)),
      this.measurementUtilsFacade.measurementUtilsEntities$.pipe(filter((obj) => !!Object.keys(obj).length)),
      this.assetTypesFacade.assetTypesEntities$.pipe(filter((obj) => !!Object.keys(obj).length)),
      this.equipmentTypesFacade.equipmentTypesEntities$.pipe(filter((obj) => !!Object.keys(obj).length)),
      this.carBrandsFacade.carBrandsEntities$.pipe(filter((obj) => !!Object.keys(obj).length)),
      this.agreementsFacadeService.selectProvidingByDealNumber$(this.dealNumber).pipe(first()),
      this.agreementsFacadeService.selectGuarantorsByDealNumber$(this.dealNumber).pipe(first()),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([
          regionsEntities,
          measurementUtilsEntities,
          assetTypesEntities,
          equipmentTypesEntities,
          carBrandsEntities,
          assets,
          guarantors,
        ]) => {
          this.regionsEntities = regionsEntities;
          this.measurementUtilsEntities = measurementUtilsEntities;
          this.assetTypesEntities = assetTypesEntities;
          this.equipmentTypesEntities = equipmentTypesEntities;
          this.carBrandsEntities = carBrandsEntities;
          this.assets = assets;
          this.guarantors = guarantors;
          if (this.assetDeals?.length) {
            this.fillByAssetDeal(this.assetDeals);
          } else {
            this.generateAssets(assets);
            this.generateGuarantors(guarantors);
            this.fillFormGroup();
          }
          this.cdr.markForCheck();
        },
      });
  }

  generateAssets(asset: FullProvidingInfo[]) {
    asset.forEach((item) => {
      this.termsSecuring[item.assetCategory].array.push({
        id: item.id,
        positionId: getUniqueUuid(),
        categoryId: item.assetCategory,
        title: this.generateTermsSecuringTitle(item),
        date: '',
      });
    });
  }

  fillByAssetDeal(assetDeal: AssetDeal[]) {
    assetDeal.forEach((item) => {
      this.termsSecuring[item.categoryId!].array.push(item);
    });
    this.validateProviding(assetDeal);
    this.validateGuarantors(assetDeal);
    this.fillFormGroup();
  }

  validateProviding(assetDeals: AssetDeal[]) {
    // check for new providing in relations with the agreement, if any new, add it
    this.assets?.forEach((item) => {
      const assetDealIndex = assetDeals.findIndex((a) => a.id === item.id);
      if (assetDealIndex === -1) {
        this.termsSecuring[item.assetCategory].array.push({
          id: item?.id,
          positionId: getUniqueUuid(),
          categoryId: item.assetCategory,
          title: this.generateTermsSecuringTitle(item),
          date: '',
        });
      }
    });

    // checking for missing relations with the agreement to the data already saved, if missing, delete it
    assetDeals.forEach((item) => {
      if (item.categoryId !== 'guarantor' && item.id) {
        const providingIndex = this.assets?.findIndex((a) => a.id === item.id);
        if (providingIndex === -1) {
          this.termsSecuring[item.categoryId!].array.splice(
            this.termsSecuring[item.categoryId!].array.findIndex(
              (i) => i.id === this.assets?.find((a) => a.id === item.id)?.id,
            ),
            1,
          );
        }
      }
    });
  }

  validateGuarantors(assetDeals: AssetDeal[]) {
    // check for new guarantors in relations with the agreement, if any new, add it
    this.guarantors?.forEach((item) => {
      const assetDealIndex = assetDeals.findIndex((a) => a.id === item.bpCode);
      if (assetDealIndex === -1) {
        this.termsSecuring['guarantor'].array.push({
          id: item?.bpCode,
          positionId: getUniqueUuid(),
          categoryId: 'guarantor',
          title: generateTermsSecuringTitleGuarantor(item),
          date: '',
        });
      }
    });

    // checking for missing relations with the agreement to the data already saved, if missing, delete it
    assetDeals.forEach((item) => {
      if (item.categoryId === 'guarantor' && item.id) {
        const guarantorIndex = this.guarantors?.findIndex((g) => g.bpCode === item.id);
        if (guarantorIndex === -1) {
          this.termsSecuring['guarantor'].array.splice(
            this.termsSecuring[item.categoryId!].array.findIndex((i) => i.id === item.id),
            1,
          );
        }
      }
    });
  }

  fillFormGroup() {
    let assetDeals: AssetDeal[] = [];
    Object.values(this.termsSecuring).forEach((item) => {
      assetDeals = [...assetDeals, ...item.array];
    });
    this.termsSecuringLoanGroupService.patchValue({
      assetDeals: assetDeals,
    });
  }

  generateGuarantors(guarantors: Guarantor[]) {
    guarantors.forEach((item) => {
      this.termsSecuring['guarantor'].array.push({
        id: item.bpCode,
        positionId: getUniqueUuid(),
        categoryId: 'guarantor',
        title: generateTermsSecuringTitleGuarantor(item),
        date: '',
      });
    });
  }

  addNewItem(categoryId?: string) {
    this.dialog
      .open(TermsSecuringCreateItemDialogComponent, {
        width: '640px',
        data: {
          categoryId: categoryId || null,
        },
      })
      .afterClosed()
      .subscribe((v) => {
        if (!v) return;
        this.termsSecuring[v.categoryId].array.push(v);
        this.termsSecuring[v.categoryId] = { ...this.termsSecuring[v.categoryId] };
        this.fillFormGroup();
        this.cdr.markForCheck();
        this.termsSecuringLoanGroupService.markAsDirty();
      });
  }

  updateItem(item: AssetDeal) {
    const itemIndex = this.termsSecuring[item.categoryId!].array.findIndex((i) => i.positionId === item.positionId);
    const editableArray = [...this.termsSecuring[item.categoryId!].array];
    editableArray[itemIndex] = {
      ...item,
    };

    this.termsSecuring[item.categoryId!].array = editableArray;
    this.fillFormGroup();
    this.termsSecuringLoanGroupService.markAsDirty();
  }

  generateNewTitle(item: AssetDeal) {
    if (item.categoryId === 'guarantor') {
      const itemInfo = this.guarantors.find((i) => i.bpCode === item.id);
      const itemIndex = this.termsSecuring[item.categoryId!].array.findIndex((i) => i.id === item.id);
      const editableArray = [...this.termsSecuring[item.categoryId!].array];
      editableArray[itemIndex] = {
        ...item,
        title: generateTermsSecuringTitleGuarantor(itemInfo!),
      };
      this.termsSecuring[item.categoryId!].array = editableArray;
    } else {
      const itemInfo = this.assets.find((i) => i.id === item.id);
      const itemIndex = this.termsSecuring[item.categoryId!].array.findIndex((i) => i.id === item.id);
      const editableArray = [...this.termsSecuring[item.categoryId!].array];
      editableArray[itemIndex] = {
        ...item,
        title: this.generateTermsSecuringTitle(itemInfo!),
      };
      this.termsSecuring[item.categoryId!].array = editableArray;
    }
    this.termsSecuringLoanGroupService.markAsDirty();
    this.fillFormGroup();
  }

  deleteItem(item: AssetDeal) {
    const itemIndex = this.termsSecuring[item.categoryId!].array.findIndex((i) => i.positionId === item.positionId);
    const editableArray = [...this.termsSecuring[item.categoryId!].array];
    editableArray.splice(itemIndex, 1);
    this.termsSecuring[item.categoryId!].array = editableArray;
    this.fillFormGroup();
    this.termsSecuringLoanGroupService.markAsDirty();
  }

  private generateTermsSecuringTitle(item: FullProvidingInfo) {
    switch (item.assetCategory) {
      case 'deposit':
        return generateTermsSecuringTitle({ item });
      case 'realty':
        return generateTermsSecuringTitle({
          item,
          regionsEntities: this.regionsEntities,
          assetTypesEntities: this.assetTypesEntities,
        });
      case 'land':
        return generateTermsSecuringTitle({
          item,
          regionsEntities: this.regionsEntities,
          assetTypesEntities: this.assetTypesEntities,
        });
      case 'goods':
        return generateTermsSecuringTitle({
          item,
          regionsEntities: this.regionsEntities,
          measurementUtilsEntities: this.measurementUtilsEntities,
        });

      case 'vehicles':
        return generateTermsSecuringTitle({
          item,
          carBrandsEntities: this.carBrandsEntities,
        });
      case 'equipment':
        return generateTermsSecuringTitle({
          item,
          regionsEntities: this.regionsEntities,
          equipmentTypesEntities: this.equipmentTypesEntities,
        });
      default:
        exhaustiveCheck();
    }
  }
}
