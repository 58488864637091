import { InjectionToken } from '@angular/core';

export const CREDIT_AGREEMENT_CONTRACT_CONFIG = new InjectionToken<CreditAgreementContractConfig>(
  'credit_agreement_contract_config',
);

export interface CreditAgreementContractConfig {
  title: string;
  nameTitle: string;
  dealTitle: string;
  showEdrpouCode: boolean;
}
