import { inject, Injectable } from '@angular/core';
import { first, map, switchMap } from 'rxjs/operators';
import { Guarantor, GuarantorAgreementRelation, GuarantorService, SapIntegrationService } from '@api/loan-approval';
import { EMPTY, Observable } from 'rxjs';
import { deleteTaskV2ProcessVariable, GuarantorsFacadeService, setTaskV2ProcessVariable } from '@sib/task/shared/store';
import { AbstractStoreService } from './abstract-store.service';
import { exhaustiveCheck } from '@sib/shared/util';

@Injectable({ providedIn: 'root' })
export class BusinessPartnerService extends AbstractStoreService {
  private readonly guarantorService = inject(GuarantorService);
  private readonly sapIntegrationService = inject(SapIntegrationService);
  private readonly guarantorsFacadeService = inject(GuarantorsFacadeService);

  loadLoanApprovalGuarantor({ bpCode }: { bpCode: string }): Observable<Guarantor>;
  loadLoanApprovalGuarantor({ idNumber }: { idNumber: number }): Observable<Guarantor>;
  loadLoanApprovalGuarantor({ idNumber, bpCode }: { idNumber?: number; bpCode?: string }): Observable<Guarantor> {
    if (bpCode) {
      return this.guarantorService.getSapGuarantor({ bpCode });
    } else if (idNumber) {
      return this.sapIntegrationService
        .getSapBpSearchInn({
          inn: idNumber,
        })
        .pipe(switchMap((result) => this.guarantorService.getSapGuarantor({ bpCode: result.code })));
    }

    exhaustiveCheck();
  }

  createGuarantor(guarantor: Guarantor) {
    this.store.dispatch(setTaskV2ProcessVariable({ requestBody: { guarantor: [guarantor] } }));
    return this.waitForResponse(guarantor);
  }

  updateGuarantorAgreementRelation(updatedGuarantorAgreementRelation: GuarantorAgreementRelation[]) {
    return this.guarantorsFacadeService.selectGuarantorAgreementRelation$.pipe(
      first(),
      map((guarantorAgreementRelation) =>
        guarantorAgreementRelation
          .filter((item) => item.bpCode === updatedGuarantorAgreementRelation[0].bpCode)
          .filter(
            (item) => !updatedGuarantorAgreementRelation.some((selection) => selection.dealNumber === item.dealNumber),
          ),
      ),
      switchMap((guarantorAgreementRelationForDelete) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              guarantorAgreementRelation: updatedGuarantorAgreementRelation,
            },
          }),
        );

        return this.waitForResponse(updatedGuarantorAgreementRelation).pipe(
          map(() => guarantorAgreementRelationForDelete),
        );
      }),
      switchMap((guarantorAgreementRelation) => {
        if (guarantorAgreementRelation.length) {
          return this.deleteGuarantorAgreementRelation(guarantorAgreementRelation);
        }
        return EMPTY;
      }),
    );
  }

  deleteGuarantorAgreementRelation(guarantorAgreementRelation: GuarantorAgreementRelation[]) {
    this.store.dispatch(
      deleteTaskV2ProcessVariable({
        requestBody: {
          guarantorAgreementRelation,
        },
      }),
    );

    return this.waitForResponse(guarantorAgreementRelation);
  }
}
