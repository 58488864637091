import { inject, Injectable } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaskChecksService } from '@api/loan-approval';
import { MatDialog } from '@angular/material/dialog';
import { TaskControlsDialogComponent } from './task-controls-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TaskControlsHandlerService {
  private dialogsService = inject(MatDialog);
  private taskChecksService = inject(TaskChecksService);

  checkAccessToTaskEvent(
    taskId: string,
    requestNumber: string,
    taskEvent: 'ASSIGN' | 'COMPLETE',
    processDefinitionKey?: string,
  ) {
    return this.taskChecksService
      .postTaskChecksEvent({
        taskEvent,
        taskId,
      })
      .pipe(
        switchMap((errors) => {
          if (!errors.length) {
            return of(true);
          }

          return this.dialogsService
            .open(TaskControlsDialogComponent, {
              data: {
                taskEvent,
                taskId,
                requestNumber,
                errors,
                processDefinitionKey,
              },
            })
            .afterClosed()
            .pipe(map((result) => !result && !errors.some((error) => error.isBlocker)));
        }),
      );
  }
}
