<h3 mat-dialog-title>Помилки в заявці № {{ data.requestNumber }}</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <mat-accordion [multi]="true">
    <mat-expansion-panel *ngFor="let item of data.errors; let index = index" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Помилка № {{ index + 1 }}</mat-panel-title>
      </mat-expansion-panel-header>

      <h4 class="description">{{ item.errorMessage }}</h4>
      <div class="error-actions">
        <button
          *ngIf="data.taskEvent === 'COMPLETE' && actionsComplete[item.errorType]"
          mat-stroked-button
          color="primary"
          (click)="complete(item.errorType, item.extraParam)"
        >
          {{ actionsComplete[item.errorType] }}
        </button>

        <button
          *ngIf="data.taskEvent === 'ASSIGN' && actionsAssign[item.errorType]"
          mat-stroked-button
          color="primary"
          (click)="assign(item.errorType)"
        >
          {{ actionsAssign[item.errorType] }}
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
