import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadMonitoringResults, selectMonitoringResults } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class MonitoringResultsFacadeService {
  public monitoringResults$ = this.store.select(selectMonitoringResults);
  constructor(private store: Store) {}

  public loadMonitoringResults() {
    this.store.dispatch(loadMonitoringResults());
  }
}
