import { createAction, props } from '@ngrx/store';
import { ControlGroupDto } from '@api/dictionaries';

export const loadControlGroups = createAction('[ControlGroups/API] Load ControlGroups');

export const loadControlGroupsReset = createAction('[ControlGroups/API] Load ControlGroups Reset');
export const loadControlGroupsSuccess = createAction(
  '[ControlGroups/API] Load Control Groups Success',
  props<{ controlGroups: ControlGroupDto[] }>(),
);
export const loadControlGroupsFailure = createAction(
  '[ControlGroups/API] Load Control Groups Failure',
  props<{ error: unknown }>(),
);
