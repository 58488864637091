import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as ParticipantsActions from './participants.actions';
import { ParticipantDTO } from '@api/dictionaries';

export const participantsFeatureKey = 'participants';

export interface State extends EntityState<ParticipantDTO> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<ParticipantDTO>({
  selectId: (participant) => participant.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ParticipantsActions.loadParticipants, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ParticipantsActions.loadParticipantsSuccess, (state, { participants }) => ({
    ...adapter.setAll(participants, state),
    isLoading: false,
  })),

  on(ParticipantsActions.loadParticipantsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
