import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ConditionGroupsActions from './condition-groups.actions';
import * as ConditionGroupsSelectors from './condition-groups.selectors';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ConditionGroupService } from '@api/dictionaries';

@Injectable()
export class ConditionGroupsEffects {
  loadConditionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConditionGroupsActions.loadConditionGroups),
      concatLatestFrom((action) => [this.store.select(ConditionGroupsSelectors.selectAllConditionGroups)]),
      switchMap(([action, allConditionGroups]) =>
        allConditionGroups.length ? of(allConditionGroups) : this.conditionGroupService.getApiConditionGroups(),
      ),
      switchMap((response) => [
        ConditionGroupsActions.loadConditionGroupsSuccess({
          conditionGroups: response,
        }),
      ]),
      catchError((error) => {
        this.toastrService.error(error?.error?.message);
        return of(ConditionGroupsActions.loadConditionGroupsFailure({ error }));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private conditionGroupService: ConditionGroupService,
    private toastrService: ToastrService,
  ) {}
}
