import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as ProtocolConditionTypesActions from './protocol-condition-types.actions';
import { ProtocolConditionTypeDto } from '@api/dictionaries';

export const protocolConditionTypesFeatureKey = 'protocol-condition-types';

export interface State extends EntityState<ProtocolConditionTypeDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<ProtocolConditionTypeDto>({
  selectId: (protocolConditionType) => protocolConditionType.type,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ProtocolConditionTypesActions.loadProtocolConditionTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ProtocolConditionTypesActions.loadProtocolConditionTypesSuccess, (state, { protocolConditionTypes }) => ({
    ...adapter.setAll(protocolConditionTypes, state),
    isLoading: false,
  })),
  on(ProtocolConditionTypesActions.loadProtocolConditionTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
