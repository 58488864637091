import { createSelector } from '@ngrx/store';
import * as fromAssetCategory from './asset-category.reducer';
import { selectActiveAssetTypes } from '../asset-types/asset-types.selectors';
import { AssetCategoryDto } from '@api/loan-org-structure';
import { selectAssetCategoryState } from '../+shell/reducers.index';

export const selectAssetCategoryIsLoading = createSelector(selectAssetCategoryState, fromAssetCategory.selectIsLoading);

export const selectAssetCategoryEntities = createSelector(selectAssetCategoryState, fromAssetCategory.selectEntities);

export const selectAssetCateogryByName = (name: string) =>
  createSelector(selectAssetCategoryEntities, (assetCategoryEntities) => assetCategoryEntities[name]);

export const selectAllAssetCategory = createSelector(selectAssetCategoryState, fromAssetCategory.selectAll);

export const selectActiveAssetCategory = createSelector(
  selectAllAssetCategory,
  selectActiveAssetTypes,
  (assetCategory, activeAssetTypes) => {
    let activeAssetCategories: AssetCategoryDto[] = [];
    activeAssetTypes.forEach((type) => {
      const filteredByTypes = assetCategory.filter((i) => i.name === type.assetTypeAddData);
      activeAssetCategories = [...new Set([...activeAssetCategories, ...filteredByTypes])];
    });

    return activeAssetCategories;
  },
);
