import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StickyControllerService {
  private stickyItemsSource = new BehaviorSubject<Record<string, number>>({});
  public stickyItems$ = this.stickyItemsSource.asObservable();

  public upsertStickyItem(id: string, stickyItemHeight: number) {
    this.stickyItemsSource.next({ ...this.stickyItemsSource.value, [id]: stickyItemHeight });
  }

  public deleteStickyItem(id: string) {
    delete this.stickyItemsSource.value[id];
    this.stickyItemsSource.next({ ...this.stickyItemsSource.value });
  }
}
