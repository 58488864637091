import { createReducer, on } from '@ngrx/store';
import * as EquipmentTypes from './equipment-types.actions';
import { EquipmentTypeDto } from '@api/dictionaries';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const equipmentTypesFeatureKey = 'equipment-types';

export const adapter: EntityAdapter<EquipmentTypeDto> = createEntityAdapter<EquipmentTypeDto>({
  selectId: (equipment: EquipmentTypeDto) => equipment.id,
  sortComparer: false,
});

export interface State extends EntityState<EquipmentTypeDto> {
  isLoading: boolean;
  error: any;
}

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(EquipmentTypes.loadEquipmentTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(EquipmentTypes.loadEquipmentTypesSuccess, (state, { equipmentTypes }) => ({
    ...adapter.setAll(equipmentTypes, state),
    isLoading: false,
  })),
  on(EquipmentTypes.loadEquipmentTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
