import { createSelector } from '@ngrx/store';
import * as fromOrgUnits from './org-units.reducer';
import { selectOrgUnitsState } from '../+shell/reducers.index';

export const selectOrgUnitsIsLoading = createSelector(selectOrgUnitsState, fromOrgUnits.selectIsLoading);

export const selectOrgUnitsEntities = createSelector(selectOrgUnitsState, fromOrgUnits.selectEntities);

export const selectOrgUnitByUnitCode = (unitCode: number) =>
  createSelector(selectOrgUnitsEntities, (orgUnitsEntities) => orgUnitsEntities[unitCode]);

export const selectAllOrgUnits = createSelector(selectOrgUnitsState, fromOrgUnits.selectAll);
export const selectIsAllOrgUnitsLoading = createSelector(selectOrgUnitsState, (state) => state.isLoading);

export const selectAllOrgUnitsByScopeCode = (scopeCode: string) =>
  createSelector(selectAllOrgUnits, (allOrgUnits) =>
    allOrgUnits.filter((orgUnit) => orgUnit.scopeCode === scopeCode && orgUnit.unitShortCode?.match(/^\d+$/)),
  );
