import { Directive } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

@Directive()
export abstract class AbstractSelection<T> {
  public abstract dataSource: MatTableDataSource<T>;
  public selection = new SelectionModel<T>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  selectionToggle(row: T) {
    this.selection.toggle(row);
  }
}
