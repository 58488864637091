/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AnnulmentTypeDto } from './models/AnnulmentTypeDto';
export type { ApplicationConstantDto } from './models/ApplicationConstantDto';
export type { AssessmentApproachDto } from './models/AssessmentApproachDto';
export type { AssessmentKindDto } from './models/AssessmentKindDto';
export type { AssessmentTypeDto } from './models/AssessmentTypeDto';
export type { AssetTypeDto } from './models/AssetTypeDto';
export type { AssociatedDocumentsDto } from './models/AssociatedDocumentsDto';
export type { BankServiceDTO } from './models/BankServiceDTO';
export type { BelongingObjectDto } from './models/BelongingObjectDto';
export type { BpRoleCourtCasesDto } from './models/BpRoleCourtCasesDto';
export type { BPRoleDto } from './models/BPRoleDto';
export type { BusinessLevelDto } from './models/BusinessLevelDto';
export type { CarBrandDto } from './models/CarBrandDto';
export type { CategoryTypeDto } from './models/CategoryTypeDto';
export type { CheckupDto } from './models/CheckupDto';
export type { ConditionDto } from './models/ConditionDto';
export type { ConditionGroupDto } from './models/ConditionGroupDto';
export type { ConditionTypeDto } from './models/ConditionTypeDto';
export type { ConsiderationLevelDto } from './models/ConsiderationLevelDto';
export type { ControlGroupDto } from './models/ControlGroupDto';
export type { CourtCasesTypeDto } from './models/CourtCasesTypeDto';
export type { CurrencyDto } from './models/CurrencyDto';
export type { DecisionDTO } from './models/DecisionDTO';
export type { DictionaryDTO } from './models/DictionaryDTO';
export type { DictionaryGroupDTO } from './models/DictionaryGroupDTO';
export type { DictionaryQueryDTO } from './models/DictionaryQueryDTO';
export type { DocumentFailureDto } from './models/DocumentFailureDto';
export type { DocumentGroupDto } from './models/DocumentGroupDto';
export type { DocumentProcessDto } from './models/DocumentProcessDto';
export type { DocumentTemplateMetadata } from './models/DocumentTemplateMetadata';
export type { DocumentTypeDto } from './models/DocumentTypeDto';
export type { EquipmentTypeDto } from './models/EquipmentTypeDto';
export type { ErrorDto } from './models/ErrorDto';
export type { ErrorMessageTypeDto } from './models/ErrorMessageTypeDto';
export type { KeyDto } from './models/KeyDto';
export type { LoanClassDTO } from './models/LoanClassDTO';
export type { LoanTypeDto } from './models/LoanTypeDto';
export type { LocationAkdDto } from './models/LocationAkdDto';
export type { MeasurementUnitDto } from './models/MeasurementUnitDto';
export type { MessageTemplateDTO } from './models/MessageTemplateDTO';
export type { MonitoringResultDto } from './models/MonitoringResultDto';
export type { NestedAssociatedTypeDoc } from './models/NestedAssociatedTypeDoc';
export type { ObligationTypeDTO } from './models/ObligationTypeDTO';
export type { ParticipantDTO } from './models/ParticipantDTO';
export type { ProcessErrorDto } from './models/ProcessErrorDto';
export type { ProcessTaskDto } from './models/ProcessTaskDto';
export type { ProductCategoryDto } from './models/ProductCategoryDto';
export type { ProductDto } from './models/ProductDto';
export type { PromotionDto } from './models/PromotionDto';
export type { ProposalTypeDto } from './models/ProposalTypeDto';
export type { ProtocolConditionTypeDto } from './models/ProtocolConditionTypeDto';
export type { RepaymentTypeDto } from './models/RepaymentTypeDto';
export type { ReportDto } from './models/ReportDto';
export type { ReportRoleDto } from './models/ReportRoleDto';
export type { RequestTypeDto } from './models/RequestTypeDto';
export type { RiskSegmentDto } from './models/RiskSegmentDto';
export type { RoleAccessDto } from './models/RoleAccessDto';
export type { RoleAnnulmentTypeDTO } from './models/RoleAnnulmentTypeDTO';
export type { RoleDto } from './models/RoleDto';
export type { SchedulerSyncDto } from './models/SchedulerSyncDto';
export type { ScopeDto } from './models/ScopeDto';
export type { SodDto } from './models/SodDto';
export type { SourceAssessmentDto } from './models/SourceAssessmentDto';
export type { SubprocessDocumentGroupDto } from './models/SubprocessDocumentGroupDto';
export type { SubprocessEnum } from './models/SubprocessEnum';
export type { TaskEventTypeDTO } from './models/TaskEventTypeDTO';
export type { TypeDocOwnershipDto } from './models/TypeDocOwnershipDto';
export type { TypeDocUseLandDto } from './models/TypeDocUseLandDto';
export type { UITabDto } from './models/UITabDto';
export type { UseTypeDto } from './models/UseTypeDto';
export type { VotingOptionDto } from './models/VotingOptionDto';

export { AnnulmentTypeService } from './services/AnnulmentTypeService';
export { ApplicationConstantService } from './services/ApplicationConstantService';
export { AssessmentApproachService } from './services/AssessmentApproachService';
export { AssessmentKindService } from './services/AssessmentKindService';
export { AssessmentTypeService } from './services/AssessmentTypeService';
export { AssetTypeService } from './services/AssetTypeService';
export { AssociatedDocumentService } from './services/AssociatedDocumentService';
export { BankServiceService } from './services/BankServiceService';
export { BelongingObjectService } from './services/BelongingObjectService';
export { BpRoleCourtCasesService } from './services/BpRoleCourtCasesService';
export { BpRolesService } from './services/BpRolesService';
export { BuildVersionService } from './services/BuildVersionService';
export { BusinessLevelService } from './services/BusinessLevelService';
export { CarBrandService } from './services/CarBrandService';
export { CategoryTypeService } from './services/CategoryTypeService';
export { CheckupService } from './services/CheckupService';
export { ConditionService } from './services/ConditionService';
export { ConditionGroupService } from './services/ConditionGroupService';
export { ConditionTypeService } from './services/ConditionTypeService';
export { ConsLevelService } from './services/ConsLevelService';
export { ControlGroupService } from './services/ControlGroupService';
export { CurrencyService } from './services/CurrencyService';
export { DecisionService } from './services/DecisionService';
export { DictionaryService } from './services/DictionaryService';
export { DictionaryEditorService } from './services/DictionaryEditorService';
export { DictionaryGroupService } from './services/DictionaryGroupService';
export { DocumentFailureService } from './services/DocumentFailureService';
export { DocumentGroupService } from './services/DocumentGroupService';
export { DocumentProcessService } from './services/DocumentProcessService';
export { DocumentTemplateService } from './services/DocumentTemplateService';
export { DocumentTypeService } from './services/DocumentTypeService';
export { EquipmentTypeService } from './services/EquipmentTypeService';
export { ErrorsService } from './services/ErrorsService';
export { HealthStatusService } from './services/HealthStatusService';
export { LawCourtService } from './services/LawCourtService';
export { LoanClassService } from './services/LoanClassService';
export { LoanTypeService } from './services/LoanTypeService';
export { LocationAkdService } from './services/LocationAkdService';
export { MeasurementUnitService } from './services/MeasurementUnitService';
export { MessageTemplateService } from './services/MessageTemplateService';
export { MonitoringResultService } from './services/MonitoringResultService';
export { ObligationTypeService } from './services/ObligationTypeService';
export { ParticipantService } from './services/ParticipantService';
export { ProcessTaskService } from './services/ProcessTaskService';
export { ProductService } from './services/ProductService';
export { ProductCategoryService } from './services/ProductCategoryService';
export { PromotionService } from './services/PromotionService';
export { ProposalTypeService } from './services/ProposalTypeService';
export { ProtocolConditionTypeService } from './services/ProtocolConditionTypeService';
export { RepaymentTypeService } from './services/RepaymentTypeService';
export { ReportService } from './services/ReportService';
export { ReportRoleService } from './services/ReportRoleService';
export { RequestTypeService } from './services/RequestTypeService';
export { RiskSegmentService } from './services/RiskSegmentService';
export { RoleService } from './services/RoleService';
export { RoleAccessService } from './services/RoleAccessService';
export { RoleAnnulmentTypeService } from './services/RoleAnnulmentTypeService';
export { SchedulerService } from './services/SchedulerService';
export { ScopeService } from './services/ScopeService';
export { SodService } from './services/SodService';
export { SourceAssessmentService } from './services/SourceAssessmentService';
export { SubprocessDocumentGroupService } from './services/SubprocessDocumentGroupService';
export { SubprocessNamesService } from './services/SubprocessNamesService';
export { SyncService } from './services/SyncService';
export { TypeDocOwnershipService } from './services/TypeDocOwnershipService';
export { TypeDocUseLandService } from './services/TypeDocUseLandService';
export { UiTabService } from './services/UiTabService';
export { UseTypeService } from './services/UseTypeService';
export { VotingOptionService } from './services/VotingOptionService';
