import { inject, Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  reduce,
  switchMap,
  toArray,
} from 'rxjs';
import { IDictionaryProposal } from '../interfaces/IDictionaryProposal';
import { convertArrayToObjectTyped, PROCESS_DEFINITION_KEY_IN, PROCESS_DEFINITION_KEYS } from '@sib/shared/util';
import { DictionariesService, selectEmployeeByLogin } from '@sib/shared/store';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { TaskVariableService } from '@app/core/services/task-variable.service';
import {
  DefaultService,
  HistoricTaskInstanceDto,
  TaskDto,
  UserOperationLogEntryDto,
  VariableValueDto,
} from '@api/camunda';
import { compareAsc, parseISO } from 'date-fns';
import { CamundaService, VariablesService } from '@api/loan-approval';
import { JwtService } from '@sib/shared/da';
import { TaskControlsHandlerService } from '@sib/task/shared/feature';
import { EmployeeDto } from '@api/loan-org-structure';
import { concatLatestFrom } from '@ngrx/effects';

export interface IDictionary {
  proposalType: IDictionaryProposal[];
}

interface MyTask extends TaskDto, Record<string, any> {
  assignHistory: UserOperationLogEntryDto[];
  history: HistoricTaskInstanceDto[];
  rootProcessStartTime: string;
}

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private readonly defaultService = inject(DefaultService);
  public newTasks: any = [];
  public myTasks: MyTask[] = [];
  public departmentTasks: any = [];

  public dictionaries: IDictionary = {
    proposalType: [],
  };

  public currentTaskVariable: any = {};

  public employees: Record<string, EmployeeDto> = {};

  constructor(
    private dictionariesServ: DictionariesService,
    private toastrService: ToastrService,
    private store: Store,
    private taskVariableService: TaskVariableService,
    private camundaService: CamundaService,
    private variablesService: VariablesService,
    private jwtService: JwtService,
    private taskControlsHandlerService: TaskControlsHandlerService,
  ) {}

  createGroupOperationsTasksAssignee(selectedTasks: any[]) {
    return from(selectedTasks.filter((selectedTask) => !selectedTask.assignee)).pipe(
      concatMap((selectedTask) =>
        this.taskControlsHandlerService
          .checkAccessToTaskEvent(selectedTask.id || selectedTask.taskId, selectedTask.requestNumber, 'ASSIGN')
          .pipe(
            filter(Boolean),
            switchMap(() => this.assigneeTaskById(selectedTask.id || selectedTask.taskId)),
          ),
      ),
      toArray(),
    );
  }

  createGroupOperationsTasksReAssignee(selectedTasks: any[], login: string) {
    return from(selectedTasks).pipe(
      mergeMap((selectedTask) => this.assigneeTaskById(selectedTask.id || selectedTask.taskId, login)),
      toArray(),
    );
  }

  resetTasks() {
    this.newTasks = [];
    this.myTasks = [];
  }

  // Todo NGRX
  loadNewTasksFullInfo() {
    const proposalTypes$ = this.dictionariesServ.loadProposalTypes();

    const newTasksDetailsInfo$: Observable<MyTask[]> = this.defaultService
      .postTask({
        requestBody: {
          sortBy: 'created',
          sortOrder: 'asc',
          candidateUser: this.jwtService.getUserLogin(),
          processDefinitionKeyIn: PROCESS_DEFINITION_KEYS,
        },
      })
      .pipe(
        mergeMap((newTasks) =>
          from(newTasks).pipe(
            concatLatestFrom((newTask) => this.store.select(selectEmployeeByLogin(newTask.assignee!))),
            map(([newTask, employee]) =>
              newTask.assignee ? { ...newTask, fullName: employee ? employee.fullName : '' } : newTask,
            ),
            mergeMap((newTask) =>
              this.defaultService
                .getHistoryUserOperation({
                  taskId: newTask.id,
                  operationType: 'Assign',
                  firstResult: 0,
                  maxResults: 1,
                })
                .pipe(map((assignHistory) => ({ ...newTask, assignHistory: [...assignHistory] }))),
            ),
            mergeMap((newTask) =>
              this.defaultService
                .getHistoryTask({ taskId: newTask.id })
                .pipe(map((history) => ({ ...newTask, taskHistory: [...history] }))),
            ),
            toArray(),
          ),
        ),
        mergeMap((newTasks) =>
          from(newTasks).pipe(
            mergeMap((newTask) => {
              if (newTask.taskHistory[0]) {
                return this.defaultService
                  .getHistoryProcessInstance1({ id: newTask.taskHistory[0].rootProcessInstanceId! })
                  .pipe(
                    map((processInfo) => ({
                      ...newTask,
                      rootProcessStartTime: processInfo.startTime,
                    })),
                  );
              } else {
                return of({ ...newTask });
              }
            }),
            toArray(),
          ),
        ),
        mergeMap((newTasks) =>
          from(newTasks).pipe(
            mergeMap((newTask) =>
              this.taskVariableService
                .loadTaskVariable(newTask.id)
                .pipe(map((newTaskInfo) => ({ ...newTask, ...newTaskInfo }))),
            ),
            toArray(),
          ),
        ),
      );
    return combineLatest([newTasksDetailsInfo$, proposalTypes$]).pipe(
      map(([newTasksDetailsInfo, proposalTypes]) => {
        const proposalsTypesListEntities = convertArrayToObjectTyped(proposalTypes, 'id');
        return newTasksDetailsInfo
          .map((newTasksDetailsInfo, index) => ({
            ...newTasksDetailsInfo,
            applicationTitle: proposalsTypesListEntities[+newTasksDetailsInfo['applicationType']]?.title,
            index,
          }))
          .sort((a, b) => compareAsc(parseISO(a.rootProcessStartTime), parseISO(b.rootProcessStartTime)));
      }),
    );
  }

  loadNewTasksFullInfoSubs(): void {
    this.loadNewTasksFullInfo().subscribe((result) => (this.newTasks = result));
  }

  loadMyTasksFullInfo() {
    const proposalTypes$ = this.dictionariesServ.loadProposalTypes();
    const myTasksDetailsInfo$: Observable<MyTask[]> = this.defaultService
      .postTask({
        requestBody: {
          sortBy: 'created',
          sortOrder: 'asc',
          assignee: this.jwtService.getUserLogin(),
          processDefinitionKeyIn: PROCESS_DEFINITION_KEYS,
        },
      })
      .pipe(
        mergeMap((myTasks) =>
          forkJoin(
            myTasks.map((newTask) =>
              forkJoin([
                this.defaultService.getHistoryUserOperation({
                  taskId: newTask.id,
                  operationType: 'Assign',
                  firstResult: 0,
                  maxResults: 1,
                }),
                this.defaultService.getHistoryTask({ taskId: newTask.id }),
                this.taskVariableService.loadTaskVariable(newTask.id),
              ]).pipe(
                map(([assignHistory, history, myTaskInfo]) => ({
                  ...newTask,
                  ...myTaskInfo,
                  assignHistory: [...assignHistory],
                  taskHistory: [...history],
                })),
              ),
            ),
          ),
        ),

        mergeMap((newTasks) =>
          from(newTasks).pipe(
            concatLatestFrom((newTask) => this.store.select(selectEmployeeByLogin(newTask.assignee!))),
            map(([newTask, employee]) =>
              newTask.assignee ? { ...newTask, fullName: employee ? employee.fullName : '' } : newTask,
            ),
            mergeMap((newTask) => {
              if (newTask.taskHistory[0]) {
                return this.defaultService
                  .getHistoryProcessInstance1({ id: newTask.taskHistory[0].rootProcessInstanceId })
                  .pipe(
                    map((processInfo) => ({
                      ...newTask,
                      rootProcessStartTime: processInfo.startTime,
                    })),
                  );
              } else {
                return of({ ...newTask });
              }
            }),
            toArray(),
          ),
        ),
      );
    return combineLatest([myTasksDetailsInfo$, proposalTypes$]).pipe(
      map(([myTasksDetailsInfo, proposalTypes]) => {
        const proposalsTypesListEntities = convertArrayToObjectTyped(proposalTypes, 'id');
        return myTasksDetailsInfo
          .map((myTasksDetailsInfo, index) => ({
            ...myTasksDetailsInfo,
            applicationTitle: proposalsTypesListEntities[+myTasksDetailsInfo['applicationType']]?.title,
            index,
          }))
          .sort((a, b) => compareAsc(parseISO(a.rootProcessStartTime), parseISO(b.rootProcessStartTime)));
      }),
    );
  }

  loadMyTasksFullInfoSubs(): void {
    this.loadMyTasksFullInfo().subscribe((result) => (this.myTasks = result || []));
  }

  createGroupUnclaim(selectedTasks: any[]) {
    return of(selectedTasks).pipe(
      map((selectedTasks) =>
        selectedTasks.filter((selectedTask) => selectedTask.assignee || selectedTask.stepNameAssignee),
      ),
      mergeMap((selectedTasks: any[]) =>
        from(selectedTasks).pipe(
          mergeMap((selectedTask) =>
            this.defaultService.postTaskUnclaim({ id: selectedTask.id || selectedTask.taskId }),
          ),
          toArray(),
        ),
      ),
      catchError((error) => {
        this.toastrService.error(`Помилка: ${error?.message}`);
        return of(error);
      }),
    );
  }

  loadDepartmentTasks() {
    return merge(
      this.camundaService.getCamundaTaskOrgUnitCandidate({
        login: this.jwtService.getUserLogin(),
        processDefinitionKeyIn: PROCESS_DEFINITION_KEY_IN,
      }),
      this.camundaService.getCamundaTaskOrgUnitAssignee({
        login: this.jwtService.getUserLogin(),
        processDefinitionKeyIn: PROCESS_DEFINITION_KEY_IN,
      }),
    ).pipe(
      reduce((tasksOrgUnitCandidate, tasksOrgUnitAssignee) => tasksOrgUnitCandidate.concat(tasksOrgUnitAssignee)),
      map((departmentTasks) => departmentTasks.sort((a, b) => compareAsc(parseISO(a.created!), parseISO(b.created!)))),
    );
  }

  loadDepartmentTasksFullInfo() {
    const proposalTypes$ = this.dictionariesServ.loadProposalTypes();
    const departmentTasksDetailsInfo$ = this.loadDepartmentTasks().pipe(
      mergeMap((departmentTasks) =>
        from(departmentTasks).pipe(
          concatLatestFrom((departmentTask) => this.store.select(selectEmployeeByLogin(departmentTask.assignee!))),
          map(([departmentTask, employee]) =>
            departmentTask.assignee
              ? { ...departmentTask, fullName: employee ? employee.fullName : '' }
              : departmentTask,
          ),
          mergeMap((newTask) =>
            this.defaultService
              .getHistoryUserOperation({
                taskId: newTask.id,
                operationType: 'Assign',
                firstResult: 0,
                maxResults: 1,
              })
              .pipe(map((assignHistory) => ({ ...newTask, assignHistory: [...assignHistory] }))),
          ),
          mergeMap((newTask) =>
            this.defaultService
              .getHistoryTask({ taskId: newTask.id })
              .pipe(map((history) => ({ ...newTask, taskHistory: [...history] }))),
          ),
          toArray(),
        ),
      ),
      mergeMap((newTasks) =>
        from(newTasks).pipe(
          mergeMap((newTask) => {
            if (newTask.taskHistory[0]) {
              return this.defaultService
                .getHistoryProcessInstance1({ id: newTask.taskHistory[0].rootProcessInstanceId! })
                .pipe(
                  map((processInfo) => ({
                    ...newTask,
                    rootProcessStartTime: processInfo.startTime,
                  })),
                );
            } else {
              return of({ ...newTask });
            }
          }),
          toArray(),
        ),
      ),
      mergeMap((departmentTasks) =>
        from(departmentTasks).pipe(
          mergeMap((departmentTask) =>
            this.variablesService.getCamundaTaskVariables({ taskId: departmentTask.id! }).pipe(
              map((departmentTaskInfo) => ({
                ...departmentTask,
                ...departmentTaskInfo,
              })),
            ),
          ),
          toArray(),
        ),
      ),
    );
    return combineLatest([departmentTasksDetailsInfo$, proposalTypes$]).pipe(
      map(([departmentTasksDetailsInfo, proposalTypes]) => {
        const proposalsTypesListEntities = convertArrayToObjectTyped(proposalTypes, 'id');
        return departmentTasksDetailsInfo
          .map((departmentTasksDetailsInfo, index) => ({
            ...departmentTasksDetailsInfo,
            applicationTitle: proposalsTypesListEntities[+departmentTasksDetailsInfo.applicationType]?.title,
            index,
          }))
          .sort((a, b) => compareAsc(parseISO(a.rootProcessStartTime!), parseISO(b.rootProcessStartTime!)));
      }),
    );
  }

  loadDepartmentTasksFullInfoSubs(): void {
    this.loadDepartmentTasksFullInfo().subscribe((result) => (this.departmentTasks = result));
  }

  assigneeTaskById(currentTaskId: string, login?: string) {
    return this.defaultService.postTaskAssignee({
      id: currentTaskId,
      requestBody: { userId: login || this.jwtService.getUserLogin() },
    });
  }

  upsertTaskVariables(taskId: string, taskVariable: Record<string, VariableValueDto>) {
    return this.defaultService
      .postTaskVariables({
        id: taskId,
        requestBody: {
          modifications: {
            ...taskVariable,
          },
        },
      })
      .pipe(map(() => taskVariable));
  }
}
