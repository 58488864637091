import { createSelector } from '@ngrx/store';
import { selectAllAssets, selectAssetsEntities } from '../../+state/asset/asset.selectors';
import { selectActiveAssetId } from '../providing/providing.selectors';
import { selectEquipmentsEntities } from '../../+state/equipment/equipment.selectors';
import { selectGoodsEntities } from '../../+state/goods/goods.selectors';
import { selectDepositEntities } from '../../+state/deposit/deposit.selectors';
import { selectVehiclesEntities } from '../../+state/vehicles/vehicles.selectors';
import { selectAssetSubTypes } from '@sib/shared/store';
import { selectLandsEntities } from '../../+state/land/land.selectors';
import { selectRealtyEntities } from '../../+state/realty/realty.selectors';
import { selectProcessInstanceId } from '../../+state/task-details/task-details.selectors';
import { selectAssetAgreementRelation, selectPropertyOwner } from '../../+state/variables/variables.selectors';
import { selectAgreementGuarantyEntities } from '../../+state/agreement-guaranty/agreement-guaranty.selectors';

export const selectAssetById = (id: string) =>
  createSelector(selectAssetsEntities, (assetsEntities) => assetsEntities[id]);

export const selectCurrentAsset = createSelector(
  selectActiveAssetId,
  selectAssetsEntities,
  (assetId, assetsEntities) => assetsEntities[assetId],
);

export const selectCurrentDeposit = createSelector(
  selectActiveAssetId,
  selectDepositEntities,
  (assetId, depositEntities) => depositEntities[assetId],
);

export const selectCurrentGood = createSelector(
  selectActiveAssetId,
  selectGoodsEntities,
  (assetId, goodsEntities) => goodsEntities[assetId],
);

export const selectCurrentEquipment = createSelector(
  selectActiveAssetId,
  selectEquipmentsEntities,
  (assetId, equipmentsEntities) => equipmentsEntities[assetId],
);

export const selectCurrentVehicle = createSelector(
  selectActiveAssetId,
  selectVehiclesEntities,
  (assetId, vehiclesEntities) => vehiclesEntities[assetId],
);

export const selectCurrentLand = createSelector(
  selectActiveAssetId,
  selectLandsEntities,
  (assetId, landsEntities) => landsEntities[assetId],
);

export const selectCurrentRealty = createSelector(
  selectActiveAssetId,
  selectRealtyEntities,
  (assetId, realtyEntities) => realtyEntities[assetId],
);

export const selectAssetSubTypesByCategory = createSelector(
  selectCurrentAsset,
  selectAssetSubTypes,
  (asset, assetSubTypes) => assetSubTypes.filter((item) => item.astCategoryName === asset?.assetCategory),
);

export const selectCurrentPropertyOwners = createSelector(
  selectActiveAssetId,
  selectPropertyOwner,
  (assetId, propertyOwners) => propertyOwners.filter((item) => item.assetId === assetId),
);

export const selectIsLockedProcessID = createSelector(
  selectCurrentAsset,
  selectProcessInstanceId,
  (asset, processInstanceId) => !!(asset?.lockedProcessID && asset.lockedProcessID !== processInstanceId),
);

export const selectDepositById = (assetId: string) =>
  createSelector(selectDepositEntities, (depositEntities) => depositEntities[assetId]);

export const selectGoodById = (assetId: string) =>
  createSelector(selectGoodsEntities, (goodsEntities) => goodsEntities[assetId]);

export const selectEquipmentById = (assetId: string) =>
  createSelector(selectEquipmentsEntities, (equipmentsEntities) => equipmentsEntities[assetId]);

export const selectVehicleById = (assetId: string) =>
  createSelector(selectVehiclesEntities, (vehiclesEntities) => vehiclesEntities[assetId]);

export const selectLandById = (assetId: string) =>
  createSelector(selectLandsEntities, (landsEntities) => landsEntities[assetId]);

export const selectRealtyById = (assetId: string) =>
  createSelector(selectRealtyEntities, (realtyEntities) => realtyEntities[assetId]);

export const selectPropertyOwnersById = (assetId: string) =>
  createSelector(selectPropertyOwner, (propertyOwners) => propertyOwners.filter((item) => item.assetId === assetId));

export const selectAgreementRelationsById = (assetId: string) =>
  createSelector(selectAssetAgreementRelation, (assetAgreementRelation) =>
    assetAgreementRelation.filter((item) => item.astId === assetId),
  );

export const selectAgreementGuarantyForDeleteByCagId = (cagId: string) =>
  createSelector(selectAllAssets, selectAgreementGuarantyEntities, (assets, agreementGuaranties) =>
    assets.filter((asset) => asset.cagId === cagId).length > 1 ? undefined : agreementGuaranties[cagId],
  );
