import { createSelector } from '@ngrx/store';
import * as fromBpRoleCourtCases from './bp-role-court-cases.reducer';
import { selectBpRoleCourtCasesState } from '../+shell/reducers.index';

export const selectBpRoleCourtCasesIsLoading = createSelector(
  selectBpRoleCourtCasesState,
  fromBpRoleCourtCases.selectIsLoading,
);

export const selectBpRoleCourtCasesEntities = createSelector(
  selectBpRoleCourtCasesState,
  fromBpRoleCourtCases.selectEntities,
);

export const selectBpRoleCourtCaseById = (id: number) =>
  createSelector(selectBpRoleCourtCasesEntities, (bpRoleCourtCasesEntities) => bpRoleCourtCasesEntities[id]);

export const selectAllBpRoleCourtCases = createSelector(selectBpRoleCourtCasesState, fromBpRoleCourtCases.selectAll);
