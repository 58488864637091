/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CurrencyRate } from '../models/CurrencyRate';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CurrencyRateService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get currencies rate
     * Get currencies rate by curr codes and rate date.
     * @returns CurrencyRate Rates found
     * @throws ApiError
     */
    public getSapCurrencyRates({
        currCode,
        currCodes,
        rateDate,
    }: {
        currCode: Array<string>,
        /**
         * Currencies codes
         */
        currCodes: Array<string>,
        /**
         * Rate date
         */
        rateDate: string,
    }): Observable<Array<CurrencyRate>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/currency-rates',
            query: {
                'currCode': currCode,
                'currCodes': currCodes,
                'rateDate': rateDate,
            },
            errors: {
                400: `bad request`,
            },
        });
    }

    /**
     * Get currency rate
     * Get currency rate by curr code and rate date.
     * @returns any Rates found
     * @throws ApiError
     */
    public getSapCurrencyRates1({
        currCode,
        rateDate,
    }: {
        /**
         * Currency code
         */
        currCode: string,
        /**
         * Rate date
         */
        rateDate: string,
    }): Observable<{
        rateDate?: string;
        currCode?: string;
        currRate?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/currency-rates/{currCode}/{rateDate}',
            path: {
                'currCode': currCode,
                'rateDate': rateDate,
            },
            errors: {
                400: `bad request`,
            },
        });
    }

}
