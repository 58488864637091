import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DocumentActions from './documents.actions';
import * as DocumentsSelectors from './documents.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class DocumentsEffects {
  loadLocationsAkd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.loadLocationAkd),
      concatLatestFrom((action) => [this.store$.select(DocumentsSelectors.selectAllLocationAkd)]),
      switchMap(([action, allLocationAkd]) =>
        (allLocationAkd.length ? of(allLocationAkd) : this.dictionariesService.loadLocationAkd()).pipe(
          map((response) =>
            DocumentActions.loadLocationAkdSuccess({
              locationAkd: response,
            }),
          ),
          catchError((error) => of(DocumentActions.loadLocationAkdFailure({ error }))),
        ),
      ),
    ),
  );

  loadDocTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.loadDocTypes),
      concatLatestFrom((action) => [this.store$.select(DocumentsSelectors.selectAllDocTypes)]),
      switchMap(([action, allDocTypes]) =>
        (allDocTypes.length ? of(allDocTypes) : this.dictionariesService.loadDocTypes()).pipe(
          map((response) =>
            DocumentActions.loadDocTypesSuccess({
              docTypes: response,
            }),
          ),
          catchError((error) => of(DocumentActions.loadDocTypesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
