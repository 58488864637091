import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment, SapGeneralError } from '@sib/shared/util';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  public override handleError(error: Error | HttpErrorResponse) {
    const toastr = this.injector.get(ToastrService);

    if ('body' in error) {
      if (error?.body instanceof SapGeneralError) {
        toastr.error(error.body.errorMessage);
      } else {
        if (environment.envType !== 'prod') {
          toastr.error('Не визначена помилка з серверу. (Повідомлення  тільки для тестового середовища!)');
        }
      }
    } else {
      if (environment.envType !== 'prod') {
        toastr.error('Щось пішло не так. (Повідомлення  тільки для тестового середовища!)');
      }
    }

    super.handleError(error);
  }
}
