/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssociatedDocumentsDto } from '../models/AssociatedDocumentsDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssociatedDocumentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get associated document
     * Get associated document by definition key.
     * @returns AssociatedDocumentsDto associated document found
     * @throws ApiError
     */
    public getApiAssociatedDocs({
        applicationId,
        processDefinitionKey,
    }: {
        applicationId?: number,
        processDefinitionKey?: string,
    }): Observable<Array<AssociatedDocumentsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/associated-docs',
            query: {
                'applicationId': applicationId,
                'processDefinitionKey': processDefinitionKey,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get associated document
     * Get associated document by ID.
     * @returns AssociatedDocumentsDto associated document found
     * @throws ApiError
     */
    public getApiAssociatedDocs1({
        appId,
    }: {
        /**
         * Application ID. Cannot be empty.
         */
        appId: number,
    }): Observable<Array<AssociatedDocumentsDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/associated-docs/{appId}',
            path: {
                'appId': appId,
            },
            errors: {
                404: `associated document not found`,
                500: `server error`,
            },
        });
    }

}
