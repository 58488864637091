import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllCreditHistory } from '../../+state/credit-history/credit-history.selectors';

@Injectable({ providedIn: 'root' })
export class CreditHistoryFacade {
  private store = inject(Store);

  public selectAllCreditHistory$ = this.store.select(selectAllCreditHistory);
}
