/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DefaultService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postProductsExpireDateNotify({
        productId,
    }: {
        productId: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/products/{productId}/expire-date-notify',
            path: {
                'productId': productId,
            },
        });
    }

}
