import { createAction, props } from '@ngrx/store';
import { ErrorMessageTypeDto } from '@api/dictionaries';

export const loadMessageType = createAction('[Dictionaries/API] Load Message Type');
export const loadMessageTypeSuccess = createAction(
  '[Dictionaries/API] Load Message Type Success',
  props<{ messageType: ErrorMessageTypeDto[] }>(),
);
export const loadMessageTypeFailure = createAction(
  '[Dictionaries/API] Load Message Type Failure',
  props<{ error: unknown }>(),
);
