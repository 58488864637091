import { AbstractSelection } from '@sib/shared/util';
import { PageType, TaskAccessFacadeService } from '@sib/task/shared/store';
import { inject } from '@angular/core';
import { filter, take } from 'rxjs';

export abstract class AbstractTableAccess<T> extends AbstractSelection<T> {
  public readonly taskAccessFacadeService = inject(TaskAccessFacadeService);

  public abstract pageType: PageType | undefined;

  override selectionToggle(row: T) {
    this.taskAccessFacadeService
      .selectIsAccessLimited('providing')
      .pipe(
        take(1),
        filter((isLimited) => !isLimited),
      )
      .subscribe(() => {
        this.selection.toggle(row);
      });
  }
}
