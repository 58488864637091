import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { GuaranteeCategory } from '@api/loan-approval';

@Injectable()
export class FinancingConditionsGuaranteeService extends FormGroup<
  ReturnType<typeof FinancingConditionsGuaranteeService.form>
> {
  static readonly form = () => ({
    productId: new FormControl<number | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    conditionsSetId: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    productName: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    agreementSum: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    currencyCode: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    endDate: new FormControl<Date | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    guaranteeCat: new FormControl<GuaranteeCategory | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    dealNumberSAP: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    promotion: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    dealTitle: new FormControl<string | undefined>(undefined, { nonNullable: true }),
  });

  constructor() {
    super(FinancingConditionsGuaranteeService.form());
  }

  get dealNumberSAP() {
    return this.controls.dealNumberSAP;
  }
  get guaranteeCat() {
    return this.controls.guaranteeCat;
  }

  get productName() {
    return this.controls.productName;
  }

  get currencyCode() {
    return this.controls.currencyCode;
  }

  get agreementSum() {
    return this.controls.agreementSum;
  }

  get conditionsSetId() {
    return this.controls.conditionsSetId;
  }
}
