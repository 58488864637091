import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CurrenciesFacadeService } from '@sib/shared/store';
import { ResponsibilityContractGroupService } from './responsibility-contract-group.service';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TERMS_DEADLINE_LIST } from '@sib/task/shared/util';
import { Agreement } from '@api/loan-approval';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { CurrencyMaskDirective } from 'ngx-currency';
import { GuarantorsFacadeService } from '@sib/task/shared/store';
import { yearsDifference } from '@sib/shared/util';
import { parseISO } from 'date-fns';

@UntilDestroy()
@Component({
  selector: 'sib-responsibility-contract-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    DigitOnlyModule,
    CurrencyMaskDirective,
  ],
  templateUrl: './responsibility-contract-form.component.html',
  styleUrls: ['./responsibility-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ResponsibilityContractFormComponent {
  constructor(
    public currenciesFacadeService: CurrenciesFacadeService,
    private responsibilityContractGroupService: ResponsibilityContractGroupService,
    private guarantorsFacadeService: GuarantorsFacadeService,
  ) {
    this.guarantorsFacadeService.selectAgreementRelatedToGuarantor$
      .pipe(filter(Boolean))
      .pipe(untilDestroyed(this))
      .subscribe((agreement) => {
        this.fillFormByCurrentTaskAgreement(agreement);
      });
  }

  private fillFormByCurrentTaskAgreement(agreement: Agreement): void {
    const GUARANTEE_AMOUNT_PERCENTAGE = 150; // 150%
    const currentGuarantorSum = (+agreement.agreementSum! / 100) * GUARANTEE_AMOUNT_PERCENTAGE;
    this.responsibilityContractGroupService.patchValue({
      gtSum: currentGuarantorSum,
      gtEkv: currentGuarantorSum,
      gtCurr: agreement.currencyCode,
      gtTerm: this.calculateYears(agreement),
    });
  }

  private calculateYears(agreement: Agreement): number {
    const GUARANTEE_AMOUNT_TERM = 10; // 10 years
    let years = 0;
    if (agreement.agreementTermMeasure) {
      // if Days
      if (agreement.agreementTermMeasure === TERMS_DEADLINE_LIST[0].name) {
        years = agreement.agreementTerm! / 365;
      }
      // if Months
      if (agreement.agreementTermMeasure === TERMS_DEADLINE_LIST[1].name) {
        years = agreement.agreementTerm! / 12;
      }
    } else if (agreement.endDate && agreement.dealDate) {
      // calculation for DOCUMENTARY_OPERATION
      const difference = yearsDifference(parseISO(agreement.endDate), parseISO(agreement.dealDate));
      years = difference === 0 ? 1 : difference;
    }

    return Math.ceil(years) + GUARANTEE_AMOUNT_TERM;
  }
}
