import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { selectDecisions } from './decisions.selectors';
import { loadDecisions } from './decisions.actions';

@Injectable({
  providedIn: 'root',
})
export class DecisionsFacadeService {
  public selectDecisions = this.store.select(selectDecisions, filter(Boolean));

  constructor(private store: Store) {}

  loadDecisions(): void {
    this.store.dispatch(loadDecisions());
  }
}
