<ng-container [formGroup]="control">
  <mat-form-field>
    <mat-label>Період</mat-label>
    <mat-select formControlName="predefinedPeriod">
      <mat-option *ngFor="let item of predefinedPeriod" [value]="item.value">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field (click)="picker.open()">
    <mat-label>Період</mat-label>
    <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
      <input matStartDate placeholder="Початок" (dateChange)="dateChange()" formControlName="from" />
      <input matEndDate placeholder="Кінець" (dateChange)="dateChange()" formControlName="to" />
    </mat-date-range-input>
    <mat-hint>дд.мм.рррр – дд.мм.рррр</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</ng-container>
