import {
  selectAgreementGuarantyForDeleteByCagId,
  selectAgreementRelationsById,
  selectAssetById,
  selectAssetSubTypesByCategory,
  selectCurrentAsset,
  selectCurrentDeposit,
  selectCurrentEquipment,
  selectCurrentGood,
  selectCurrentLand,
  selectCurrentPropertyOwners,
  selectCurrentRealty,
  selectCurrentVehicle,
  selectDepositById,
  selectEquipmentById,
  selectGoodById,
  selectIsLockedProcessID,
  selectLandById,
  selectPropertyOwnersById,
  selectRealtyById,
  selectVehicleById,
} from './assets.selectors';
import { Store } from '@ngrx/store';
import { inject, Injectable } from '@angular/core';
import { selectAllAssets } from '../../+state/asset/asset.selectors';
import { selectActiveAssetId } from '../providing/providing.selectors';
import { selectAllDeposits } from '../../+state/deposit/deposit.selectors';

@Injectable({ providedIn: 'root' })
export class AssetsFacadeService {
  private store = inject(Store);

  public selectAssetById$ = (id: string) => this.store.select(selectAssetById(id));
  public selectActiveAssetId$ = this.store.select(selectActiveAssetId);
  public selectAllAssets$ = this.store.select(selectAllAssets);
  public selectCurrentAsset$ = this.store.select(selectCurrentAsset);
  public currentDeposit$ = this.store.select(selectCurrentDeposit);
  public currentGood$ = this.store.select(selectCurrentGood);
  public currentEquipment$ = this.store.select(selectCurrentEquipment);
  public currentVehicle$ = this.store.select(selectCurrentVehicle);
  public currentLand$ = this.store.select(selectCurrentLand);
  public currentRealty$ = this.store.select(selectCurrentRealty);
  public assetSubTypesByCategory$ = this.store.select(selectAssetSubTypesByCategory);
  public selectCurrentPropertyOwners$ = this.store.select(selectCurrentPropertyOwners);
  public isLockedProcessID$ = this.store.select(selectIsLockedProcessID);

  public selectGoodById$ = (id: string) => this.store.select(selectGoodById(id));
  public selectEquipmentById$ = (id: string) => this.store.select(selectEquipmentById(id));
  public selectVehicleById$ = (id: string) => this.store.select(selectVehicleById(id));
  public selectLandById$ = (id: string) => this.store.select(selectLandById(id));
  public selectRealtyById$ = (id: string) => this.store.select(selectRealtyById(id));
  public selectDepositByAssetId$ = (id: string) => this.store.select(selectDepositById(id));
  public selectPropertyOwnersById$ = (id: string) => this.store.select(selectPropertyOwnersById(id));
  public selectAgreementRelationsById$ = (id: string) => this.store.select(selectAgreementRelationsById(id));
  public selectAgreementGuarantyForDeleteByCagId$ = (cagId: string) =>
    this.store.select(selectAgreementGuarantyForDeleteByCagId(cagId));

  public selectAllDeposits$ = this.store.select(selectAllDeposits);
}
