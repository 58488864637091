import { inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VariablesService } from '@api/loan-approval';

@Injectable({
  providedIn: 'root',
})
export class TaskVariableService {
  private readonly variablesService = inject(VariablesService);

  loadTaskVariable(taskId: string) {
    return this.variablesService.getCamundaTaskVariables({ taskId }).pipe(catchError(() => of({})));
  }
}
