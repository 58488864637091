import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProtocolConditionTypesActions from './protocol-condition-types.actions';
import * as ProtocolConditionTypesSelectors from './protocol-condition-types.selectors';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ProtocolConditionTypeService } from '@api/dictionaries';

@Injectable()
export class ProtocolConditionTypesEffects {
  loadProtocolConditionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolConditionTypesActions.loadProtocolConditionTypes),
      concatLatestFrom((action) => [
        this.store$.select(ProtocolConditionTypesSelectors.selectAllProtocolConditionTypes),
      ]),
      switchMap(([action, allProtocolConditionTypes]) =>
        allProtocolConditionTypes.length
          ? of(allProtocolConditionTypes)
          : this.protocolConditionTypeService.getApiProtocolConditionTypes(),
      ),
      switchMap((response) => [
        ProtocolConditionTypesActions.loadProtocolConditionTypesSuccess({
          protocolConditionTypes: response,
        }),
      ]),
      catchError((error) => {
        this.toastrService.error(error?.error?.message);
        return of(ProtocolConditionTypesActions.loadProtocolConditionTypesFailure({ error }));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private toastrService: ToastrService,
    private protocolConditionTypeService: ProtocolConditionTypeService,
  ) {}
}
