import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { CamundaService, HumanTaskHistoryDto } from '@api/loan-approval';
import { DefaultService, UserOperationLogEntryDto } from '@api/camunda';
import { map } from 'rxjs/operators';
import { intervalToDuration } from 'date-fns';
import { Merge } from 'ts-toolbelt/out/Object/Merge';
import { EmployeeService } from '@api/loan-org-structure';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderComponent } from '@sib/shared/ui';
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'sib-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LoaderComponent, MatTooltipModule, NgFor, DatePipe],
})
export class HistoryDialogComponent implements OnInit {
  public history: Merge<HumanTaskHistoryDto, { operations: UserOperationLogEntryDto[] }>[] = [];
  public historyList: Merge<
    (typeof this.history)[0],
    { timeAssigne?: string | null; assignLogin?: string | null; timeComplete?: string | null; fullName?: string }
  >[] = [];
  public isLoading = false;

  private typeRequest = this.camundaService.getCamundaProcessAppHumanTaskHistory({
    processId: this.dialogRef.data?.processId,
  });

  constructor(
    private dialogRef: DialogRef,
    private camundaService: CamundaService,
    private defaultService: DefaultService,
    private employeeService: EmployeeService,
    private cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    if (this.dialogRef.data?.isTaskHistoryEstimateNumber) {
      this.typeRequest = this.camundaService.getCamundaProcessAppHumanTaskHistoryEstimateNumber({
        estimateNumber: this.dialogRef.data?.number,
      });
    }

    this.typeRequest
      .pipe(
        switchMap((result) =>
          forkJoin(
            result.map((item) =>
              this.defaultService
                .getHistoryUserOperation({ taskId: item.taskId, sortOrder: 'asc', sortBy: 'timestamp' })
                .pipe(map((operations) => Object.assign(item, { operations }))),
            ),
          ),
        ),
      )
      .subscribe((result) => {
        this.history = result;

        if (result.length === 0) {
          this.isLoading = true;
          this.cdr.markForCheck();
          return;
        }
        this.updateHistory();
      });
  }

  updateHistory() {
    let historyItem: (typeof this.historyList)[0];
    let historyProcessed = 0;
    this.history.forEach((item) => {
      const assign = item.operations.filter((i) => i.operationType === 'Assign' && i.newValue);
      const complete = item.operations.filter((i) => i.operationType === 'Complete');
      let itemsProcessed = 0;
      assign.forEach((itemOperations) => {
        historyItem = Object.assign(item, {
          timeAssigne: itemOperations.timestamp,
          assignLogin: itemOperations.newValue,
        });
        itemsProcessed++;
        if (itemsProcessed === assign.length && complete[0]) {
          historyItem = Object.assign(historyItem, { timeComplete: complete[0]?.timestamp });
        } else {
          const time = item.operations.find(
            (i) => i.operationType === 'Assign' && !i.newValue && i.orgValue === itemOperations.newValue,
          )?.timestamp;
          let timeComplete = null;
          if (new Date(time!).getTime() > new Date(historyItem.timeAssigne!).getTime()) {
            timeComplete = item.operations.find(
              (i) => i.operationType === 'Assign' && !i.newValue && i.orgValue === itemOperations.newValue,
            )?.timestamp;
          }
          historyItem = Object.assign(historyItem, { timeComplete: timeComplete });
        }
        this.historyList.push(historyItem);
      });
      if (assign.length === 0) {
        historyItem = Object.assign(item, { timeAssigne: null, timeComplete: null, assignLogin: null });
        this.historyList.push(historyItem);
      }
    });
    this.historyList.forEach((element) => {
      if (element.assignLogin) {
        this.employeeService
          .getApiEmployeesLoginFullName({ login: element.assignLogin })
          .pipe(catchError(() => of({ fullName: element.assignLogin })))
          .subscribe((name) => {
            Object.assign(element, name);
            historyProcessed++;
            if (historyProcessed === this.historyList.length) {
              this.isLoading = true;
              this.cdr.markForCheck();
            }
          });
      } else {
        historyProcessed++;
        if (historyProcessed === this.historyList.length) {
          this.isLoading = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  getDuration(row: (typeof this.historyList)[0]) {
    if (!row.timeAssigne && !row.timeComplete) {
      return '00:00:00';
    }

    const {
      days = 0,
      hours = 0,
      minutes = 0,
    } = intervalToDuration({
      start: new Date(row.timeAssigne!),
      end: new Date(row.timeComplete ? row.timeComplete : new Date()),
    });

    return `${days < 10 ? '0' + days : days}:${hours < 10 ? '0' + hours : hours}:${
      minutes < 10 ? '0' + minutes : minutes
    }`;
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
