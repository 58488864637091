<mat-card formGroupName="creditAgreement">
  <mat-card-header>
    <mat-card-title>{{ creditAgreementContractConfig.title }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="row">
      <mat-form-field>
        <mat-label>{{ creditAgreementContractConfig.nameTitle }}</mat-label>
        <input
          matInput
          type="text"
          [placeholder]="creditAgreementContractConfig.nameTitle"
          formControlName="clientName"
        />
      </mat-form-field>

      <mat-form-field *ngIf="creditAgreementContractConfig.showEdrpouCode">
        <mat-label>Код ЄДРПОУ/ІПН</mat-label>
        <input matInput type="text" placeholder="Код ЄДРПОУ/ІПН" formControlName="edrpouCode" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Сума кредиту</mat-label>
        <input matInput type="text" placeholder="Сума кредиту" formControlName="agreementSum" currencyMask />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Валюта</mat-label>
        <mat-select placeholder="Виберіть тип валюти" formControlName="currencyCode">
          <mat-option *ngFor="let item of currenciesFacadeService.currencies$ | async" [value]="item.code">
            {{ item.definition }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Еквівалент у гривнях за курсом НБУ</mat-label>
        <input matInput type="text" placeholder="Сума кредиту" formControlName="agreementSumUAH" currencyMask />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>{{ creditAgreementContractConfig.dealTitle }}</mat-label>
        <input
          matInput
          type="text"
          [placeholder]="creditAgreementContractConfig.dealTitle"
          formControlName="dealTitle"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Номер договору</mat-label>
        <input matInput type="text" placeholder="Номер договору" formControlName="dealNumberSAP" />
      </mat-form-field>

      <mat-form-field (click)="datepicker.open()">
        <mat-label>Дата договору</mat-label>
        <input matInput formControlName="dealDate" [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field (click)="datepicker2.open()">
        <mat-label>Кінцева дата повернення коштів</mat-label>
        <input matInput formControlName="dealDateEnd" [matDatepicker]="datepicker2" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker2"></mat-datepicker-toggle>
        <mat-datepicker #datepicker2></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
