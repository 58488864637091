import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAssessmentTypes, selectAssessmentTypes } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class AssessmentTypesFacadeService {
  public assessmentTypes$ = this.store.select(selectAssessmentTypes);
  constructor(private store: Store) {}

  public loadAssessmentTypes() {
    this.store.dispatch(loadAssessmentTypes());
  }
}
