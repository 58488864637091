import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Controller } from '@api/loan-approval';

@Component({
  selector: 'sib-beneficiary-controllers-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTableModule,
  ],
  templateUrl: './beneficiary-controllers-form.component.html',
  styleUrls: ['./beneficiary-controllers-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class BeneficiaryControllersFormComponent {
  @Input() set controllers(controllers: Controller[] | undefined) {
    if (controllers?.length) {
      this.dataSource.data = controllers.map((i, index) => ({
        id: i.id,
        index: index + 1,
        buController: i.buController || '',
        controllerName: i.controllerName || '',
        controllerCountry: i.controllerCountry || '',
      }));
    }
  }

  public displayedColumns = ['index', 'buController', 'controllerName', 'controllerCountry'];
  public dataSource = new MatTableDataSource<Controller>();
}
