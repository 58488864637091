import { Component } from '@angular/core';

@Component({
  selector: 'sib-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
})
export class HomeComponent {
  constructor() {}
}
