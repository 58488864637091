import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Agreement, SapIntegrationService } from '@api/loan-approval';
import { LoanRepaymentProcedureGroupService } from './loan-repayment-procedure-group.service';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'sib-loan-repayment-procedure-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,

    MatDatepickerModule,
  ],
  templateUrl: './loan-repayment-procedure-form.component.html',
  styleUrls: ['./loan-repayment-procedure-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class LoanRepaymentProcedureFormComponent implements OnInit {
  @Input() loanClassId!: number | null;
  @Input() agreementSource!: Agreement | undefined;

  public readonly LOAN_CREDIT_LINE = [2, 3, 4];
  public readonly LOAN_CREDIT = [1, 6];

  constructor(public form: LoanRepaymentProcedureGroupService, private sapIntegrationService: SapIntegrationService) {}

  public ngOnInit() {
    if (this.agreementSource?.dealNumberSAP && this.agreementSource?.repaymentsConditionName === 'Ануїтет') {
      this.sapIntegrationService
        .getSapGetAnnuityPayment({ dealNumber: this.agreementSource?.dealNumberSAP })
        .subscribe((res) => {
          this.form.controls.repaymentAmount.setValue(String(res?.annuityPaymentSum));
          this.form.controls.repaymentAmount.disable();
        });
    }
  }
}
