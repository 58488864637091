import { createAction, props } from '@ngrx/store';
import { AssetCategoryDto } from '@api/loan-org-structure';

export const loadAssetCategory = createAction('[Dictionaries/API] Load Asset Category');
export const loadAssetCategorySuccess = createAction(
  '[Dictionaries/API] Load Asset Category Success',
  props<{ assetCategory: AssetCategoryDto[] }>(),
);
export const loadAssetCategoryFailure = createAction(
  '[Dictionaries/API] Load Asset Category Failure',
  props<{ error: any }>(),
);
