/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DecisionDTO } from '../models/DecisionDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DecisionService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all decisions
     * Returns all decisions.
     * @returns DecisionDTO all decisions
     * @throws ApiError
     */
    public getApiDecisions(): Observable<Array<DecisionDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/decisions',
        });
    }

    /**
     * Create decision
     * Method for creating decision.
     * @returns DecisionDTO decision created
     * @throws ApiError
     */
    public postApiDecisions({
        requestBody,
    }: {
        /**
         * Decision object that needs to be created.
         */
        requestBody: DecisionDTO,
    }): Observable<DecisionDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/decisions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get decision
     * Get decision by ID.
     * @returns any decision found
     * @throws ApiError
     */
    public getApiDecisions1({
        id,
    }: {
        /**
         * Decision ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        decisionTitle?: string;
        info?: boolean;
        consLevels?: Array<string>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/decisions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `decision not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update decision
     * Updates the decision with the given ID.
     * @returns any decision updated
     * @throws ApiError
     */
    public putApiDecisions({
        id,
        requestBody,
    }: {
        /**
         * Decision ID. Cannot be empty.
         */
        id: string,
        /**
         * Decision object that needs to be updated.
         */
        requestBody: DecisionDTO,
    }): Observable<{
        id: string;
        decisionTitle?: string;
        info?: boolean;
        consLevels?: Array<string>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/decisions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete decision
     * Deletes the decision with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDecisions({
        id,
    }: {
        /**
         * Decision ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/decisions/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add Consideration Level to product
     * Add Consideration Level to product
     * @returns void
     * @throws ApiError
     */
    public postApiDecisionsConsiderationLevels({
        considerationLevelId,
        id,
    }: {
        /**
         * Asset type code. Cannot be empty.
         */
        considerationLevelId: string,
        /**
         * Decision ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/decisions/{id}/consideration-levels/{considerationLevelId}',
            path: {
                'considerationLevelId': considerationLevelId,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Remove assetType from product
     * Remove assetType from product
     * @returns void
     * @throws ApiError
     */
    public deleteApiDecisionsConsiderationLevels({
        considerationLevelId,
        id,
    }: {
        /**
         * Asset type code. Cannot be empty.
         */
        considerationLevelId: number,
        /**
         * Product type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/decisions/{id}/consideration-levels/{considerationLevelId}',
            path: {
                'considerationLevelId': considerationLevelId,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
