import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Condition } from '@api/loan-approval';

const conditionsFeatureKey = 'conditions';

export type ConditionsState = EntityState<Condition>;

export const adapter = createEntityAdapter<Condition>({
  selectId: (item) => item.id,
});

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.conditions || [], state)),
);

export const conditionsFeature = createFeature({
  name: conditionsFeatureKey,
  reducer,
  extraSelectors: ({ selectConditionsState }) => ({
    ...adapter.getSelectors(selectConditionsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = conditionsFeature;
