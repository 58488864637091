/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssetSubtypeDto } from '../models/AssetSubtypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssetSubtypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all asset subtypes
     * Returns a list of all asset subtypes.
     * @returns AssetSubtypeDto List of asset subtypes returned
     * @throws ApiError
     */
    public getApiAssetSubtypes(): Observable<Array<AssetSubtypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/asset-subtypes',
        });
    }

    /**
     * Create new asset subtype
     * Creates a new asset subtype based on the provided data.
     * @returns AssetSubtypeDto Asset subtype created successfully
     * @throws ApiError
     */
    public postApiAssetSubtypes({
        requestBody,
    }: {
        /**
         * Asset subtype object that needs to be added.
         */
        requestBody: AssetSubtypeDto,
    }): Observable<AssetSubtypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/asset-subtypes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update asset subtype
     * Updates an existing asset subtype based on the provided data.
     * @returns void
     * @throws ApiError
     */
    public patchApiAssetSubtypes({
        requestBody,
    }: {
        /**
         * Asset subtype object that needs to be updated.
         */
        requestBody: AssetSubtypeDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/asset-subtypes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find asset subtype by ID
     * Returns an asset subtype with the given ID.
     * @returns AssetSubtypeDto Asset subtype found and returned
     * @throws ApiError
     */
    public getApiAssetSubtypes1({
        id,
    }: {
        /**
         * ID of the asset subtype to be found. Cannot be empty.
         */
        id: number,
    }): Observable<AssetSubtypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/asset-subtypes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Asset subtype not found`,
            },
        });
    }

    /**
     * Delete asset subtype
     * Deletes the asset subtype with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiAssetSubtypes({
        id,
    }: {
        /**
         * ID of the asset subtype to be deleted. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/asset-subtypes/{id}',
            path: {
                'id': id,
            },
        });
    }

}
