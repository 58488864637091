<mat-card formGroupName="financingConditions">
  <mat-card-header>
    <mat-card-title>Умови фінансування</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="row" *ngIf="financingConditionsFiveSevenNineService.dealNumberSAP.value">
      <ng-container>
        <mat-form-field>
          <mat-label>№ договору в SAP</mat-label>
          <input matInput type="text" formControlName="dealNumberSAP" />
        </mat-form-field>
        <mat-form-field (click)="datepicker.open()">
          <mat-label> Дата договору</mat-label>
          <input matInput [matDatepicker]="datepicker" formControlName="dealDate" />
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label> Продукт</mat-label>
        <mat-select
          placeholder="Виберіть продукт"
          formControlName="conditionsSetId"
          (selectionChange)="onSelectChangeProduct($event)"
        >
          <mat-option *ngFor="let item of dictionariesCustomProducts | async" [value]="item.groupId">
            {{ item.productName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label> Сума</mat-label>
        <input matInput type="text" formControlName="agreementSum" placeholder="Введіть сумму" currencyMask />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Валюта</mat-label>
        <mat-select placeholder="Виберіть тип валюти" formControlName="currencyCode">
          <mat-option *ngFor="let item of currenciesFacadeService.currencies$ | async" [value]="item.code">
            {{ item.definition }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label> Макс.строк</mat-label>
        <input matInput sibDigitsOnly type="text" formControlName="agreementTerm" maxlength="4" placeholder="Cтрок" />
      </mat-form-field>

      <mat-form-field>
        <mat-label></mat-label>
        <mat-select formControlName="agreementTermMeasure">
          <mat-option *ngFor="let item of termsDeadlineOptions" [value]="item.name">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label> Компенсаційна ставка, %</mat-label>
        <input
          matInput
          type="text"
          readonly
          sibTwoDigitDecimalNumber
          max="5"
          placeholder="Компенсаційна ставка %"
          formControlName="interestRate"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Ставка компенсації, %</mat-label>
        <input
          matInput
          type="number"
          readonly
          sibTwoDigitDecimalNumber
          max="5"
          placeholder="Ставка компенсації %"
          formControlName="interestRateAdd"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Базова ставка, %</mat-label>
        <input
          matInput
          type="text"
          readonly
          sibTwoDigitDecimalNumber
          max="5"
          placeholder="Ставка компенсації %"
          formControlName="interestRateBasic"
        />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label> Мета кредитування</mat-label>
        <textarea
          matInput
          type="text"
          placeholder="Введіть мету кредитування"
          formControlName="lendingPurpose"
        ></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Пріоритетний напрям кредитування</mat-label>
        <textarea
          matInput
          type="text"
          placeholder="Введіть пріоритетний напрям кредитування"
          formControlName="priorityDirection"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label> Сегмент</mat-label>
        <mat-select placeholder="Виберіть Сегмент" formControlName="busnSegment">
          <mat-option *ngFor="let item of dictionariesBusnSegment" [value]="item.type">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label> Акція</mat-label>
        <mat-select placeholder="Виберіть Акцію" formControlName="promotion">
          <mat-option *ngFor="let item of promotion$ | async" [value]="item.code">
            {{ item.description }}
          </mat-option></mat-select
        >
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
