import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AssetDeal } from '@api/loan-approval';

@Injectable()
export class TermsSecuringLoanGroupService extends FormGroup<
  ReturnType<typeof TermsSecuringLoanGroupService.formGroup>
> {
  private static readonly formGroup = () => ({
    assetDeals: new FormControl<AssetDeal[]>([], { nonNullable: true }),
  });

  constructor() {
    super(TermsSecuringLoanGroupService.formGroup());
  }
}
