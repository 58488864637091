import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { DictionariesService } from '../dictionaries.service';
import { Store } from '@ngrx/store';
import * as ProductCategories from './product-categories.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as Selectors from './product-categories.selectors';

@Injectable()
export class ProductCategoriesEffects {
  productCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCategories.loadProductCategories),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectProductCategories)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadProductCategories()).pipe(
          map((response) =>
            ProductCategories.loadProductCategoriesSuccess({
              productCategories: response,
            }),
          ),
          catchError((error) => of(ProductCategories.loadProductCategoriesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
