import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveVersion, selectVersion } from '@app/core/store';

@Injectable({
  providedIn: 'root',
})
export class UiFacadeService {
  public version$ = this.store.select(selectVersion);

  constructor(private store: Store) {}

  public saveVersion(version: string[]) {
    this.store.dispatch(saveVersion({ version }));
  }
}
