import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsLoadingObligationTypes, selectObligationTypes } from './obligation-types.selectors';
import { loadObligationTypes } from './obligation-types.actions';

@Injectable({
  providedIn: 'root',
})
export class ObligationTypesFacadeService {
  public selectObligationTypes$ = this.store.select(selectObligationTypes);
  public selectIsLoading$ = this.store.select(selectIsLoadingObligationTypes);

  constructor(private store: Store) {}

  public loadObligationTypes() {
    this.store.dispatch(loadObligationTypes());
  }
}
