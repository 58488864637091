import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllAgreementGuaranties } from '../../+state/agreement-guaranty/agreement-guaranty.selectors';
import { selectActiveAgreementGuaranty, selectAgreementGuaranties } from './agreement-guaranty.selectors';

@Injectable({ providedIn: 'root' })
export class AgreementGuarantyFacade {
  private readonly store = inject(Store);

  selectAllAgreementGuaranties$ = this.store.select(selectAllAgreementGuaranties);
  selectActiveAgreementGuaranty$ = this.store.select(selectActiveAgreementGuaranty);
  selectAgreementGuaranties$ = this.store.select(selectAgreementGuaranties);
}
