import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutofocusDirective } from '../../directives';

export interface PromptDialogData {
  title: string;
  message: string;
  closeLabel: string;
  confirmLabel: string;
  fieldLabel: string;
  placeholder: string;
  maxLength?: number;
  comment: string;
}
@Component({
  selector: 'sib-irb-cc-comment-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    AutofocusDirective,
  ],
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptDialogComponent implements OnInit {
  public form = new FormGroup({
    comment: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, ...(this.data.maxLength ? [Validators.maxLength(this.data.maxLength)] : [])],
    }),
  });
  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent, { comment: string }>,
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogData,
  ) {}

  ngOnInit() {
    this.form.patchValue({
      comment: this.data.comment,
    });
  }

  onSubmitDialog() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.getRawValue());
    }
  }
}
