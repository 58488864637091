import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { PropertyOwner } from '@api/loan-approval';

const propertyOwnerFeatureKey = 'propertyOwner';

export type PropertyOwnerState = EntityState<PropertyOwner>;

// TODO assetId not unique
export const adapter = createEntityAdapter<PropertyOwner>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.propertyOwner || [], state)),
);

export const propertyOwnerFeature = createFeature({
  name: propertyOwnerFeatureKey,
  reducer,
  extraSelectors: ({ selectPropertyOwnerState }) => ({
    ...adapter.getSelectors(selectPropertyOwnerState),
  }),
});

export const { selectIds, selectEntities, selectTotal } = propertyOwnerFeature;
