import { createFeature, createReducer, on } from '@ngrx/store';
import { HistoricProcessInstanceDto } from '@api/camunda';
import { processDetailsActions } from './process-details.action';

const processDetailsKey = 'processDetails';

export interface processDetailsState extends HistoricProcessInstanceDto {
  id: string;
  businessKey: string;
  processDefinitionId: string | undefined;
  processDefinitionKey: string | undefined;
  processDefinitionName: string | undefined;
  processDefinitionVersion: number | undefined;
  startTime: string;
  endTime: string | undefined;
  removalTime: string | undefined;
  durationInMillis: number | undefined;
  startUserId: string | undefined;
  startActivityId: string | undefined;
  deleteReason: string | undefined;
  rootProcessInstanceId: string | undefined;
  superProcessInstanceId: string | undefined;
  superCaseInstanceId: string | undefined;
  caseInstanceId: string | undefined;
  tenantId: string | undefined;
  state: 'COMPLETED' | 'ACTIVE' | undefined | string;
}

export const initialState: processDetailsState = {
  id: '',
  businessKey: '',
  processDefinitionId: '',
  processDefinitionKey: '',
  processDefinitionName: '',
  processDefinitionVersion: undefined,
  startTime: '',
  endTime: '',
  removalTime: '',
  durationInMillis: undefined,
  startUserId: '',
  startActivityId: '',
  deleteReason: '',
  rootProcessInstanceId: '',
  superProcessInstanceId: '',
  superCaseInstanceId: '',
  caseInstanceId: '',
  tenantId: '',
  state: undefined,
};

export const reducer = createReducer<processDetailsState>(
  initialState,
  on(processDetailsActions.loadSuccess, (state, { processDetails }) => ({ ...state, ...processDetails })),
);

export const processDetailsFeature = createFeature({
  name: processDetailsKey,
  reducer,
});
