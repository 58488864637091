import { createAction, props } from '@ngrx/store';
import { ProtocolConditionTypeDto } from '@api/dictionaries';

export const loadProtocolConditionTypes = createAction('[Dictionaries/API] Load Protocol Condition Types');
export const loadProtocolConditionTypesSuccess = createAction(
  '[Dictionaries/API] Load Protocol Condition Types Success',
  props<{ protocolConditionTypes: ProtocolConditionTypeDto[] }>(),
);
export const loadProtocolConditionTypesFailure = createAction(
  '[Dictionaries/API] Load Protocol Condition Types Failure',
  props<{ error: any }>(),
);
