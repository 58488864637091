import { InjectionToken } from '@angular/core';

export const REQUISITES_CONTRACT_CONFIG = new InjectionToken<RequisitesContractConfig>('requisites_contract_config');

export interface RequisitesContractConfig {
  addressLetterTitle: string;
  addressLocationTitle: string;
  phoneFaxTitle: string;
  passpDocTitle: string;
  addressLetterBankTitle: string;
  phoneFaxBankTitle: string;
  isShowPasspDoc: boolean;
  isShowEmail: boolean;
  isShowEmailBank: boolean;
  emailTitle: string;
  emailTitleBank: string;
}
