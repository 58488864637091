/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BorrowerGroup } from '../models/BorrowerGroup';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BorrowerGroupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get borrower groups
     * Get borrower groups by bp code.
     * @returns BorrowerGroup borrower groups found
     * @throws ApiError
     */
    public getSapBorrowerGroups({
        bpCode,
    }: {
        /**
         * BP code
         */
        bpCode: string,
    }): Observable<Array<BorrowerGroup>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/borrower-groups',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `borrower groups not found`,
                500: `server error`,
            },
        });
    }

}
