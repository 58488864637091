export const REPAYMENT_TERM_LIST = [
  {
    name: 'Стандартний графік погашення',
    graphType: 1,
  },
  {
    name: 'Індивідуальний графік погашення',
    graphType: 1,
  },
  {
    name: 'В кінці строку',
    graphType: 1,
  },
  {
    name: 'Ануїтет',
    graphType: 3,
  },
];
