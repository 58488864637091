import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { taskSettingsActions } from './task-settings.actions';
import { taskSettingsFeature, TaskSettingsState } from './task-settings.reducer';

@Injectable({ providedIn: 'root' })
export class TaskSettingsFacadeService {
  private readonly store = inject(Store);

  public selectTaskSettings$ = this.store.select(taskSettingsFeature.selectTaskSettingsState);
  public selectUserTabsSettingName$ = this.store.select(taskSettingsFeature.selectUserTabsSettingName);
  public selectIsReadonly$ = this.store.select(taskSettingsFeature.selectIsReadonly);

  public updateTaskSettings(taskSettings: Partial<TaskSettingsState>) {
    this.store.dispatch(taskSettingsActions.updateSettings({ taskSettings }));
  }

  public resetTaskSettings() {
    this.store.dispatch(taskSettingsActions.resetSettings());
  }
}
