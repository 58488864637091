import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '@sib/shared/da';
import { NgxPermissionsConfigurationService } from 'ngx-permissions';

@Component({
  selector: 'sib-root',
  template: ` <ng-progress></ng-progress>
    <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private authServ: AuthService,
    private ngxPermissionsConfigurationService: NgxPermissionsConfigurationService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.authServ.setPermissions();

    this.setConfigPermissionStrategy();
  }

  private setConfigPermissionStrategy(): void {
    this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', (templateRef) => {
      this.renderer.setAttribute(templateRef?.elementRef.nativeElement.previousSibling, 'disabled', 'true');
      this.renderer.setAttribute(templateRef?.elementRef.nativeElement.previousSibling, 'readonly', 'true');
    });

    this.ngxPermissionsConfigurationService.addPermissionStrategy('enable', (templateRef) => {
      this.renderer.removeAttribute(templateRef?.elementRef.nativeElement.previousSibling, 'disabled');
      this.renderer.removeAttribute(templateRef?.elementRef.nativeElement.previousSibling, 'readonly');
    });
  }
}
