import { Injectable, NgZone } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RectResizeObserver {
  private resizeObserver?: ResizeObserver;
  private subject = new Subject<ResizeObserverEntry>();
  private observables = new Map<Element, Observable<ResizeObserverEntry>>();

  constructor(private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = window.ResizeObserver
        ? new ResizeObserver((entries) => {
            for (const entry of entries) {
              this.ngZone.run(() => {
                this.subject.next(entry);
              });
            }
          })
        : undefined;
    });
  }

  public resize$(element: Element) {
    if (!this.resizeObserver) {
      const resizeObserverEntry: ResizeObserverEntry = {
        target: element,
        contentRect: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 0,
          height: 0,
          x: 0,
          y: 0,

          // eslint-disable-next-line @typescript-eslint/no-empty-function
          toJSON: () => {},
        },
        borderBoxSize: [],
        contentBoxSize: [],
        devicePixelContentBoxSize: [],
      };
      return of(resizeObserverEntry);
    }

    if (this.observables.get(element)) {
      this.observables.get(element);
    }
    const observable = this.createObservable(element).pipe(share());
    this.observables.set(element, observable);
    return observable;
  }

  private createObservable(element: Element) {
    return new Observable<ResizeObserverEntry>((observer) => {
      this.subject.pipe(filter((entry) => entry.target === element)).subscribe(observer);
      this.ngZone.runOutsideAngular(() => {
        this.resizeObserver!.observe(element);
      });
      return () => {
        this.ngZone.runOutsideAngular(() => {
          this.resizeObserver!.unobserve(element);
        });
        this.observables.delete(element);
      };
    });
  }
}
