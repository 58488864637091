/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ControlGroupDto } from '../models/ControlGroupDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ControlGroupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all control groups
     * Returns all control groups.
     * @returns ControlGroupDto all control groups
     * @throws ApiError
     */
    public getApiControlGroups(): Observable<Array<ControlGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/control-groups',
        });
    }

    /**
     * Create control group
     * Method for creating control group.
     * @returns any control group created
     * @throws ApiError
     */
    public postApiControlGroups({
        requestBody,
    }: {
        /**
         * Control group object that needs to be created.
         */
        requestBody: ControlGroupDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/control-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get control group
     * Get control group by ID.
     * @returns any Control group found
     * @throws ApiError
     */
    public getApiControlGroups1({
        id,
    }: {
        /**
         * Control group ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id?: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/control-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Control group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update control group
     * Updates the control group.
     * @returns void
     * @throws ApiError
     */
    public putApiControlGroups({
        id,
        requestBody,
    }: {
        /**
         * Control group ID.  Cannot be empty.
         */
        id: number,
        /**
         * Control group object that needs to be updated.
         */
        requestBody: ControlGroupDto,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/control-groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete control group
     * Deletes the control group with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiControlGroups({
        id,
    }: {
        /**
         * Control group ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/control-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
