<mat-card>
  <mat-card-header>
    <mat-card-title>{{ pledgerContractConfig.title }}</mat-card-title>
  </mat-card-header>

  <mat-card-content formGroupName="pledgerContract">
    <mat-form-field>
      <mat-label>Назва/ПІБ</mat-label>
      <input matInput type="text" placeholder="Введіть Назва/ПІБ" formControlName="partnerName" />
    </mat-form-field>
    <p *ngIf="pledgerContractConfig.isFop">
      <mat-checkbox formControlName="fop"> {{ pledgerContractConfig.fopTitle }} </mat-checkbox>
      <mat-divider></mat-divider>
    </p>
    <p>
      <mat-checkbox formControlName="ownSign">{{ pledgerContractConfig.ownSignTitle }}</mat-checkbox>
      <mat-divider></mat-divider>
    </p>

    <div class="row">
      <mat-form-field>
        <mat-label>Номер БП</mat-label>

        <input
          matInput
          type="number"
          placeholder="Введіть Номер БП"
          formControlName="bpNumber"
          (blur)="getGuarantor()"
        />

        <mat-error *ngIf="pledgerContractGroupService.bpNumber.hasError('verifyGuarantor')">
          {{ pledgerContractGroupService.bpNumber.errors?.['verifyGuarantor'] }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>ПІБ (Називний відмінок)</mat-label>

        <input matInput type="text" placeholder="Введіть ПІБ" formControlName="partnerNameSign" />
      </mat-form-field>
    </div>
    <p>
      <mat-checkbox formControlName="agent"> {{ pledgerContractConfig.agentTitle }} </mat-checkbox>
      <mat-divider></mat-divider>
    </p>

    <div class="row">
      <mat-form-field>
        <mat-label>Посада</mat-label>

        <input matInput type="text" placeholder="Введіть посаду" formControlName="position" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Діє на підставі</mat-label>

        <input matInput type="text" formControlName="doc" />
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
