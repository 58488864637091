import { createSelector } from '@ngrx/store';
import { selectAllAssets } from '../../+state/asset/asset.selectors';
import { selectPropertyOwnerEntities } from '../../+state/property-owner/property-owner.selectors';
import { selectAssetTypesEntities, selectRouterParam } from '@sib/shared/store';
import { typedFilter } from '@sib/shared/util';
import { selectAllAgreements } from '../../+state/agreements/agreement.selectors';
import { selectAssetAgreementRelation, selectControlOptions } from '../../+state/variables/variables.selectors';
import { selectProcessDefinitionKey, selectProcessInstanceId } from '../../+state/task-details/task-details.selectors';
import { selectActiveContractId } from '../agreement-guaranty/agreement-guaranty.selectors';

export const selectActiveAssetId = selectRouterParam('assetId');

export const selectProvidingList = createSelector(
  selectPropertyOwnerEntities,
  selectAllAssets,
  selectAssetTypesEntities,
  selectProcessInstanceId,
  (propertyOwnerEntities, assets, assetTypesEntities, processInstanceId) =>
    assets.map((asset) => ({
      ...asset,
      ...propertyOwnerEntities[asset.id]!,
      assetTypeName: assetTypesEntities[asset.assetType]?.assetTypeName || '',
      locked: !!asset.lockedProcessID && asset.lockedProcessID !== processInstanceId,
    })),
);

export const selectProvidingByAgreementId = (id?: string) =>
  createSelector(
    selectAllAgreements,
    selectAssetAgreementRelation,
    selectProvidingList,
    (agreements, assetAgreementRelation, providingList) =>
      id
        ? typedFilter(providingList, (list) =>
            assetAgreementRelation?.some(
              (item) =>
                (item.dealNumber === agreements.find((i) => i.id === id)?.dealNumber ? item.astId : '') === list.id,
            ),
          )
        : providingList,
  );

export const selectCurrentProvidingList = createSelector(
  selectProvidingList,
  selectActiveContractId,
  (providingList, contractId: string) =>
    typedFilter(providingList || [], (providing) => providing.cagId === contractId),
);

export const selectCurrentProviding = createSelector(
  selectProvidingList,
  selectActiveContractId,
  (providingList, contractId: string) => providingList.find((providing) => providing.cagId === contractId),
);

export const selectAgreementRelatedToAsset = createSelector(
  selectCurrentProviding,
  selectAllAgreements,
  selectAssetAgreementRelation,
  (providing, agreements, assetAgreementRelations) =>
    agreements.find(
      (item) =>
        item.dealNumber === (assetAgreementRelations || []).find((i) => i.astId === providing?.assetId)?.dealNumber,
    ) || null,
);

export const selectAccessAssetOperation = createSelector(
  selectControlOptions,
  selectProcessDefinitionKey,
  (controlOptions, processDefinitionKey) =>
    processDefinitionKey !== 'subprocess_property_assessment' ||
    (processDefinitionKey === 'subprocess_property_assessment' && controlOptions['allowAssetOperation'] === 'true'),
);
