/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ProductDto } from '../models/ProductDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ProductService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all products
     * Returns all products.
     * @returns ProductDto all products
     * @throws ApiError
     */
    public getApiProducts(): Observable<Array<ProductDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/products',
        });
    }

    /**
     * Update product
     * Updates the product with the given ID.
     * @returns any product updated
     * @throws ApiError
     */
    public putApiProducts({
        requestBody,
    }: {
        /**
         * Product object that needs to be updated.
         */
        requestBody: ProductDto,
    }): Observable<{
        id: number;
        starDate?: string;
        endDate?: string;
        busnLevelId?: number;
        loanClassId?: number;
        genFlag?: boolean;
        genProdId?: number;
        prodName?: string;
        prodSSicher?: string;
        prodTerm?: string;
        prodTermDays?: string;
        productCategories?: Array<number>;
        assetTypeCodes?: Array<string>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Create product
     * Method for creating product.
     * @returns ProductDto product created
     * @throws ApiError
     */
    public postApiProducts({
        requestBody,
    }: {
        /**
         * Product object that needs to be created.
         */
        requestBody: ProductDto,
    }): Observable<ProductDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get product
     * Get product by business level ID.
     * @returns ProductDto product found
     * @throws ApiError
     */
    public getApiProductsBusinessLevels({
        id,
    }: {
        /**
         * Business level ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<ProductDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/products/business-levels/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `product not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get product
     * Get product by ID.
     * @returns any product found
     * @throws ApiError
     */
    public getApiProducts1({
        id,
    }: {
        /**
         * Product ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        starDate?: string;
        endDate?: string;
        busnLevelId?: number;
        loanClassId?: number;
        genFlag?: boolean;
        genProdId?: number;
        prodName?: string;
        prodSSicher?: string;
        prodTerm?: string;
        prodTermDays?: string;
        productCategories?: Array<number>;
        assetTypeCodes?: Array<string>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/products/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `product not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete product
     * Deletes the product with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiProducts({
        id,
    }: {
        /**
         * Product ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/products/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add assetType to product
     * Add assetType to product
     * @returns void
     * @throws ApiError
     */
    public postApiProductsAssetTypes({
        assetCode,
        id,
    }: {
        /**
         * Asset type code. Cannot be empty.
         */
        assetCode: string,
        /**
         * Product type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/products/{id}/asset-types/{assetCode}',
            path: {
                'assetCode': assetCode,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Remove assetType from product
     * Remove assetType from product
     * @returns void
     * @throws ApiError
     */
    public deleteApiProductsAssetTypes({
        assetCode,
        id,
    }: {
        /**
         * Asset type code. Cannot be empty.
         */
        assetCode: string,
        /**
         * Product type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/products/{id}/asset-types/{assetCode}',
            path: {
                'assetCode': assetCode,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add Condition Group to product
     * Add Condition Group to product
     * @returns void
     * @throws ApiError
     */
    public postApiProductsConditionGroups({
        conditionGroupId,
        id,
    }: {
        /**
         * Condition Group Id. Cannot be empty.
         */
        conditionGroupId: number,
        /**
         * Product type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/products/{id}/condition-groups/{conditionGroupId}',
            path: {
                'conditionGroupId': conditionGroupId,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Remove Condition Group from product
     * Remove Condition Group from product
     * @returns void
     * @throws ApiError
     */
    public deleteApiProductsConditionGroups({
        conditionGroupId,
        id,
    }: {
        /**
         * Asset type code. Cannot be empty.
         */
        conditionGroupId: number,
        /**
         * Condition Group Id. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/products/{id}/condition-groups/{conditionGroupId}',
            path: {
                'conditionGroupId': conditionGroupId,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add productCategory to product
     * Add productCategory to product
     * @returns void
     * @throws ApiError
     */
    public postApiProductsProductCategories({
        productCategoryId,
        productId,
    }: {
        /**
         * Product category Id. Cannot be empty.
         */
        productCategoryId: number,
        /**
         * Product type ID. Cannot be empty.
         */
        productId: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/products/{productId}/product-categories/{productCategoryId}',
            path: {
                'productCategoryId': productCategoryId,
                'productId': productId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Remove product category from product
     * Remove assetType from product
     * @returns void
     * @throws ApiError
     */
    public deleteApiProductsProductCategories({
        productCategoryId,
        productId,
    }: {
        /**
         * Product category type code. Cannot be empty.
         */
        productCategoryId: number,
        /**
         * Product Id. Cannot be empty.
         */
        productId: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/products/{productId}/product-categories/{productCategoryId}',
            path: {
                'productCategoryId': productCategoryId,
                'productId': productId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
