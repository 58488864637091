import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromConsiderationLevel from '../consideration-level/consideration-level.reducer';
import * as fromLoanTypes from '../loan-types/loan-types.reducer';
import * as fromLoanClasses from '../loan-classes/loan-classes.reducer';
import * as fromProduct from '../product/product.reducer';
import * as fromBpRoles from '../bp-roles/bp-roles.reducer';
import * as fromBpRoleCourtCases from '../bp-role-court-cases/bp-role-court-cases.reducer';
import * as fromLawCaseTypes from '../law-case-types/law-case-types.reducer';
import * as fromProposalsTypes from '../proposals-types/proposals-types.reducer';
import * as fromAssetTypes from '../asset-types/asset-types.reducer';
import * as fromAssetCategory from '../asset-category/asset-category.reducer';
import * as fromCurrencies from '../currencies/currencies.reducer';
import * as fromConditionGroups from '../condition-groups/condition-groups.reducer';
import * as fromUiTabs from '../ui-tabs/ui-tabs.reducer';
import * as fromProtocolConditionTypes from '../protocol-condition-types/protocol-condition-types.reducer';
import * as fromEmployees from '../employees/employees.reducer';
import * as fromMessageType from '../message-type/message-type.reducer';
import * as fromErrors from '../errors/errors.reducer';
import * as fromDocuments from '../documents/documents.reducer';
import * as fromOrgUnits from '../org-units/org-units.reducer';
import * as fromParticipants from '../participants/participants.reducer';
import * as fromRoles from '../roles/roles.reducer';
import * as fromVotingOptions from '../voting-options/voting-options.reducer';
import * as fromReports from '../reports/reports.reducer';
import * as fromDictionaries from '../dictionaries/dictionaries.reducer';
import * as fromBankServices from '../bank-services/bank-services.reducer';
import * as fromDictionaryGroups from '../dictionary-groups/dictionary-groups.reducer';
import * as fromProductCategories from '../product-categories/product-categories.reducer';
import * as fromAssessmentTypes from '../assessment-types/assessment-types.reducer';
import * as fromSOD from '../sods/sods.reducers';
import * as fromRequestTypes from '../request-types/request-types.reducer';
import * as fromSourceAssessments from '../source-assessments/source-assessments.reducers';
import * as fromAssessmentApproaches from '../assessment-approaches/assessment-approaches.reducers';
import * as fromMonitoringResults from '../monitoring-results/monitoring-results.reducers';
import * as fromBelongingObjects from '../belonging-objects/belonging-objects.reducers';
import * as fromRegions from '../regions/regions.reducers';
import * as fromCarBrands from '../car-brands/car-brands.reducers';
import * as fromUseTypes from '../use-types/use-types.reducers';
import * as fromTypeDocUseLands from '../type-doc-use-lands/type-doc-use-lands.reducers';
import * as fromTypeDocsOwnership from '../type-docs-ownership/type-docs-ownership.reducers';
import * as fromEquipmentTypes from '../equipment-types/equipment-types.reducers';
import * as fromMeasurementUnits from '../measurement-units/measurement-units.reducers';
import * as fromAssessmentKinds from '../assessment-kinds/assessment-kinds.redusers';
import * as fromAssetSubTypes from '../asset-subtypes/asset-subtypes.reducers';
import * as fromCheckups from '../checkups/checkups.reducer';
import * as fromControlGroups from '../control-groups/control-groups.reducer';
import * as fromDecisions from '../decisions/decisions.reducers';
import * as fromRiskSegments from '../risk-segments/risk-segments.reducer';
import * as fromObligationTypes from '../obligation-types/obligation-types.reducer';
import * as agreementsCount from '../agreements-count/agreements-count.reducers';
import * as conditionTypes from '../condition-types/condition-types.reducer';
import * as productCategoryTypes from '../product-category-types/product-category-types.reducers';
import { userTabRoleKey, UserTabRoleState, userTabToleReducer } from '../user-tab-roles/user-tab-roles.reducers';
import * as promotion from '../promotion/promotion.reducer';

export const sharedFeatureKey = 'shared';

export interface State {
  [fromConsiderationLevel.considerationLevelFeatureKey]: fromConsiderationLevel.State;
  [fromLoanTypes.loanTypesFeatureKey]: fromLoanTypes.State;
  [fromLoanClasses.loanClassesFeatureKey]: fromLoanClasses.State;
  [fromProduct.productFeatureKey]: fromProduct.State;
  [fromBpRoles.bpRolesFeatureKey]: fromBpRoles.State;
  [fromBpRoleCourtCases.bpRoleCourtCasesFeatureKey]: fromBpRoleCourtCases.State;
  [fromLawCaseTypes.lawCaseTypesFeatureKey]: fromLawCaseTypes.State;
  [fromProposalsTypes.proposalTypesFeatureKey]: fromProposalsTypes.State;
  [fromAssetTypes.assetTypesFeatureKey]: fromAssetTypes.State;
  [fromAssetCategory.assetCategoryFeatureKey]: fromAssetCategory.State;
  [fromCurrencies.currenciesFeatureKey]: fromCurrencies.State;
  [fromConditionGroups.conditionGroupsFeatureKey]: fromConditionGroups.State;
  [fromUiTabs.uiTabsFeatureKey]: fromUiTabs.State;
  [fromProtocolConditionTypes.protocolConditionTypesFeatureKey]: fromProtocolConditionTypes.State;
  [fromEmployees.employeesFeatureKey]: fromEmployees.State;
  [fromMessageType.messageTypeFeatureKey]: fromMessageType.State;
  [fromErrors.errorsFeatureKey]: fromErrors.State;
  [fromDocuments.documentsFeatureKey]: fromDocuments.State;
  [fromOrgUnits.orgUnitsFeatureKey]: fromOrgUnits.State;
  [fromParticipants.participantsFeatureKey]: fromParticipants.State;
  [fromRoles.rolesFeatureKey]: fromRoles.State;
  [fromVotingOptions.votingOptionsFeatureKey]: fromVotingOptions.State;
  [fromReports.reportsFeatureKey]: fromReports.State;
  [fromDictionaries.dictionariesFeatureKey]: fromDictionaries.State;
  [fromBankServices.bankServicesFeatureKey]: fromBankServices.State;
  [fromDictionaryGroups.dictionaryGroupsFeatureKey]: fromDictionaryGroups.State;
  [fromProductCategories.productCategoriesKey]: fromProductCategories.State;
  [fromAssessmentTypes.assessmentTypesFeatureKey]: fromAssessmentTypes.State;
  [fromSOD.SODFeatureKey]: fromSOD.State;
  [fromRequestTypes.requestTypesFeatureKey]: fromRequestTypes.State;
  [fromSourceAssessments.sourceAssessmentsFeatureKey]: fromSourceAssessments.State;
  [fromAssessmentApproaches.assessmentApproachesFeatureKey]: fromAssessmentApproaches.State;
  [fromMonitoringResults.monitoringResultsFeatureKey]: fromMonitoringResults.State;
  [fromBelongingObjects.belongingObjectsFeatureKey]: fromBelongingObjects.State;
  [fromRegions.regionsFeatureKey]: fromRegions.State;
  [fromCarBrands.carBrandsFeatureKey]: fromCarBrands.State;
  [fromUseTypes.useTypesFeatureKey]: fromUseTypes.State;
  [fromTypeDocUseLands.typeDocUseLandsFeatureKey]: fromTypeDocUseLands.State;
  [fromTypeDocsOwnership.typeDocsOwnershipFeatureKey]: fromTypeDocsOwnership.State;
  [fromEquipmentTypes.equipmentTypesFeatureKey]: fromEquipmentTypes.State;
  [fromMeasurementUnits.measurementUnitsFeatureKey]: fromMeasurementUnits.State;
  [fromAssessmentKinds.assessmentKindsFeatureKey]: fromAssessmentKinds.State;
  [fromAssetSubTypes.assetSubTypesFeatureKey]: fromAssetSubTypes.State;
  [fromCheckups.checkupsFeatureKey]: fromCheckups.State;
  [fromControlGroups.controlGroupsFeatureKey]: fromControlGroups.State;
  [fromDecisions.decisionsFeatureKey]: fromDecisions.State;
  [fromRiskSegments.riskSegmentsFeatureKey]: fromRiskSegments.State;
  [fromObligationTypes.obligationTypesFeatureKey]: fromObligationTypes.State;
  [agreementsCount.agreementsCountFeatureKey]: agreementsCount.State;
  [conditionTypes.conditionTypesFeatureKey]: conditionTypes.State;
  [productCategoryTypes.productCategoryTypesFeatureKey]: productCategoryTypes.State;
  [userTabRoleKey]: UserTabRoleState;
  [promotion.promotionFeatureKey]: promotion.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [agreementsCount.agreementsCountFeatureKey]: agreementsCount.reducer,
    [fromAssessmentApproaches.assessmentApproachesFeatureKey]: fromAssessmentApproaches.reducer,
    [fromAssessmentKinds.assessmentKindsFeatureKey]: fromAssessmentKinds.reducer,
    [fromAssessmentTypes.assessmentTypesFeatureKey]: fromAssessmentTypes.reducer,
    [fromAssetCategory.assetCategoryFeatureKey]: fromAssetCategory.reducer,
    [fromAssetSubTypes.assetSubTypesFeatureKey]: fromAssetSubTypes.reducer,
    [fromAssetTypes.assetTypesFeatureKey]: fromAssetTypes.reducer,
    [fromBankServices.bankServicesFeatureKey]: fromBankServices.reducer,
    [fromBelongingObjects.belongingObjectsFeatureKey]: fromBelongingObjects.reducer,
    [fromBpRoleCourtCases.bpRoleCourtCasesFeatureKey]: fromBpRoleCourtCases.reducer,
    [fromBpRoles.bpRolesFeatureKey]: fromBpRoles.reducer,
    [fromCarBrands.carBrandsFeatureKey]: fromCarBrands.reducer,
    [fromCheckups.checkupsFeatureKey]: fromCheckups.reducer,
    [fromConditionGroups.conditionGroupsFeatureKey]: fromConditionGroups.reducer,
    [conditionTypes.conditionTypesFeatureKey]: conditionTypes.reducer,
    [fromConsiderationLevel.considerationLevelFeatureKey]: fromConsiderationLevel.reducer,
    [fromControlGroups.controlGroupsFeatureKey]: fromControlGroups.reducer,
    [fromCurrencies.currenciesFeatureKey]: fromCurrencies.reducer,
    [fromDecisions.decisionsFeatureKey]: fromDecisions.reducer,
    [fromDictionaries.dictionariesFeatureKey]: fromDictionaries.reducer,
    [fromDictionaryGroups.dictionaryGroupsFeatureKey]: fromDictionaryGroups.reducer,
    [fromDocuments.documentsFeatureKey]: fromDocuments.reducer,
    [fromEmployees.employeesFeatureKey]: fromEmployees.reducer,
    [fromEquipmentTypes.equipmentTypesFeatureKey]: fromEquipmentTypes.reducer,
    [fromErrors.errorsFeatureKey]: fromErrors.reducer,
    [fromLawCaseTypes.lawCaseTypesFeatureKey]: fromLawCaseTypes.reducer,
    [fromLoanClasses.loanClassesFeatureKey]: fromLoanClasses.reducer,
    [fromLoanTypes.loanTypesFeatureKey]: fromLoanTypes.reducer,
    [fromMeasurementUnits.measurementUnitsFeatureKey]: fromMeasurementUnits.reducer,
    [fromMessageType.messageTypeFeatureKey]: fromMessageType.reducer,
    [fromMonitoringResults.monitoringResultsFeatureKey]: fromMonitoringResults.reducer,
    [fromObligationTypes.obligationTypesFeatureKey]: fromObligationTypes.reducer,
    [fromOrgUnits.orgUnitsFeatureKey]: fromOrgUnits.reducer,
    [fromParticipants.participantsFeatureKey]: fromParticipants.reducer,
    [fromProduct.productFeatureKey]: fromProduct.reducer,
    [fromProductCategories.productCategoriesKey]: fromProductCategories.reducer,
    [productCategoryTypes.productCategoryTypesFeatureKey]: productCategoryTypes.reducer,
    [promotion.promotionFeatureKey]: promotion.reducer,
    [fromProposalsTypes.proposalTypesFeatureKey]: fromProposalsTypes.reducer,
    [fromProtocolConditionTypes.protocolConditionTypesFeatureKey]: fromProtocolConditionTypes.reducer,
    [fromRegions.regionsFeatureKey]: fromRegions.reducer,
    [fromReports.reportsFeatureKey]: fromReports.reducer,
    [fromRequestTypes.requestTypesFeatureKey]: fromRequestTypes.reducer,
    [fromRiskSegments.riskSegmentsFeatureKey]: fromRiskSegments.reducer,
    [fromRoles.rolesFeatureKey]: fromRoles.reducer,
    [fromSOD.SODFeatureKey]: fromSOD.reducer,
    [fromSourceAssessments.sourceAssessmentsFeatureKey]: fromSourceAssessments.reducer,
    [fromTypeDocUseLands.typeDocUseLandsFeatureKey]: fromTypeDocUseLands.reducer,
    [fromTypeDocsOwnership.typeDocsOwnershipFeatureKey]: fromTypeDocsOwnership.reducer,
    [fromUiTabs.uiTabsFeatureKey]: fromUiTabs.reducer,
    [fromUseTypes.useTypesFeatureKey]: fromUseTypes.reducer,
    [userTabRoleKey]: userTabToleReducer,
    [fromVotingOptions.votingOptionsFeatureKey]: fromVotingOptions.reducer,
  })(state, action);
}

export const getSharedState = createFeatureSelector<State>(sharedFeatureKey);

export const selectConsiderationLevelState = createSelector(
  getSharedState,
  (state: State) => state[fromConsiderationLevel.considerationLevelFeatureKey],
);

export const selectLoanTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromLoanTypes.loanTypesFeatureKey],
);

export const selectLoanClassesState = createSelector(
  getSharedState,
  (state: State) => state[fromLoanClasses.loanClassesFeatureKey],
);

export const selectProductListState = createSelector(
  getSharedState,
  (state: State) => state[fromProduct.productFeatureKey],
);

export const selectBpRolesState = createSelector(
  getSharedState,
  (state: State) => state[fromBpRoles.bpRolesFeatureKey],
);

export const selectBpRoleCourtCasesState = createSelector(
  getSharedState,
  (state: State) => state[fromBpRoleCourtCases.bpRoleCourtCasesFeatureKey],
);

export const selectLawCaseTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromLawCaseTypes.lawCaseTypesFeatureKey],
);

export const selectProposalsTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromProposalsTypes.proposalTypesFeatureKey],
);

export const selectAssetTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromAssetTypes.assetTypesFeatureKey],
);

export const selectAssetCategoryState = createSelector(
  getSharedState,
  (state: State) => state[fromAssetCategory.assetCategoryFeatureKey],
);

export const selectCurrenciesState = createSelector(
  getSharedState,
  (state: State) => state[fromCurrencies.currenciesFeatureKey],
);

export const selectConditionGroupsState = createSelector(
  getSharedState,
  (state: State) => state[fromConditionGroups.conditionGroupsFeatureKey],
);

export const selectUiTabsState = createSelector(getSharedState, (state: State) => state[fromUiTabs.uiTabsFeatureKey]);

export const selectProtocolConditionTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromProtocolConditionTypes.protocolConditionTypesFeatureKey],
);

export const selectEmployeesState = createSelector(
  getSharedState,
  (state: State) => state[fromEmployees.employeesFeatureKey],
);

export const selectMessageTypeState = createSelector(
  getSharedState,
  (state: State) => state[fromMessageType.messageTypeFeatureKey],
);

export const selectErrorsState = createSelector(getSharedState, (state: State) => state[fromErrors.errorsFeatureKey]);

export const selectOrgUnitsState = createSelector(
  getSharedState,
  (state: State) => state[fromOrgUnits.orgUnitsFeatureKey],
);

export const selectDocumentsState = createSelector(
  getSharedState,
  (state: State) => state[fromDocuments.documentsFeatureKey],
);

export const selectParticipantsState = createSelector(
  getSharedState,
  (state: State) => state[fromParticipants.participantsFeatureKey],
);

export const selectRolesState = createSelector(getSharedState, (state: State) => state[fromRoles.rolesFeatureKey]);

export const selectVotingOptionsState = createSelector(
  getSharedState,
  (state: State) => state[fromVotingOptions.votingOptionsFeatureKey],
);

export const selectReportsState = createSelector(
  getSharedState,
  (state: State) => state[fromReports.reportsFeatureKey],
);

export const selectDictionariesState = createSelector(
  getSharedState,
  (state: State) => state[fromDictionaries.dictionariesFeatureKey],
);

export const selectBankServicesState = createSelector(
  getSharedState,
  (state: State) => state[fromBankServices.bankServicesFeatureKey],
);

export const selectDictionaryGroupsState = createSelector(
  getSharedState,
  (state: State) => state[fromDictionaryGroups.dictionaryGroupsFeatureKey],
);

export const selectProductCategoriesState = createSelector(
  getSharedState,
  (state: State) => state[fromProductCategories.productCategoriesKey],
);

export const selectAssessmentTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromAssessmentTypes.assessmentTypesFeatureKey],
);

export const selectSODState = createSelector(getSharedState, (state: State) => state[fromSOD.SODFeatureKey]);

export const selectRequestTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromRequestTypes.requestTypesFeatureKey],
);

export const selectSourceAssessmentsState = createSelector(
  getSharedState,
  (state: State) => state[fromSourceAssessments.sourceAssessmentsFeatureKey],
);

export const selectAssessmentApproachesState = createSelector(
  getSharedState,
  (state: State) => state[fromAssessmentApproaches.assessmentApproachesFeatureKey],
);

export const selectMonitoringResultsState = createSelector(
  getSharedState,
  (state: State) => state[fromMonitoringResults.monitoringResultsFeatureKey],
);

export const selectBelongingObjectsState = createSelector(
  getSharedState,
  (state: State) => state[fromBelongingObjects.belongingObjectsFeatureKey],
);

export const selectRegionsState = createSelector(
  getSharedState,
  (state: State) => state[fromRegions.regionsFeatureKey],
);

export const selectCarBrandsState = createSelector(
  getSharedState,
  (state: State) => state[fromCarBrands.carBrandsFeatureKey],
);

export const selectUseTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromUseTypes.useTypesFeatureKey],
);

export const selectTypeDocUseLandsState = createSelector(
  getSharedState,
  (state: State) => state[fromTypeDocUseLands.typeDocUseLandsFeatureKey],
);

export const selectTypeDocsOwnershipState = createSelector(
  getSharedState,
  (state: State) => state[fromTypeDocsOwnership.typeDocsOwnershipFeatureKey],
);

export const selectEquipmentTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromEquipmentTypes.equipmentTypesFeatureKey],
);

export const selectMeasurementUnitsState = createSelector(
  getSharedState,
  (state: State) => state[fromMeasurementUnits.measurementUnitsFeatureKey],
);

export const selectAssessmentKindsState = createSelector(
  getSharedState,
  (state: State) => state[fromAssessmentKinds.assessmentKindsFeatureKey],
);

export const selectAssetSubTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromAssetSubTypes.assetSubTypesFeatureKey],
);

export const selectCheckupsState = createSelector(
  getSharedState,
  (state: State) => state[fromCheckups.checkupsFeatureKey],
);

export const selectControlGroupsState = createSelector(
  getSharedState,
  (state: State) => state[fromControlGroups.controlGroupsFeatureKey],
);

export const selectDecisionsState = createSelector(
  getSharedState,
  (state: State) => state[fromDecisions.decisionsFeatureKey],
);

export const selectRiskSegmentsState = createSelector(
  getSharedState,
  (state: State) => state[fromRiskSegments.riskSegmentsFeatureKey],
);

export const selectObligationTypesState = createSelector(
  getSharedState,
  (state: State) => state[fromObligationTypes.obligationTypesFeatureKey],
);

export const selectAgreementsCountState = createSelector(
  getSharedState,
  (state: State) => state[agreementsCount.agreementsCountFeatureKey],
);

export const selectConditionTypesState = createSelector(
  getSharedState,
  (state: State) => state[conditionTypes.conditionTypesFeatureKey],
);

export const selectProductCategoryTypesState = createSelector(
  getSharedState,
  (state: State) => state[productCategoryTypes.productCategoryTypesFeatureKey],
);

export const selectUserTypeRoleState = createSelector(getSharedState, (state: State) => state[userTabRoleKey]);
export const selectPromotionState = createSelector(
  getSharedState,
  (state: State) => state[promotion.promotionFeatureKey],
);
