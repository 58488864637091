import { createAction, props } from '@ngrx/store';
import type { TaskCountDto } from '@api/loan-approval';

export const loadTasksCount = createAction('[Root Tasks/API] Load Tasks Count');
export const loadTasksCountSuccess = createAction(
  '[Root Tasks/API] Load Tasks Count Success',
  props<{ tasksCount: TaskCountDto; tasksAssessment: TaskCountDto }>(),
);
export const loadTasksCountFailure = createAction('[Root Tasks/API] Load Tasks Count Failure', props<{ error: any }>());

export const resetTasksState = createAction('[Root Tasks] Reset Tasks State');
