<mat-card formGroupName="beneficiaryControllers">
  <mat-card-header>
    <mat-card-title>Бенефіціар та контролери</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="grid">
      <div class="column half">
        <h3>Бенефіціар</h3>

        <div class="row">
          <mat-form-field>
            <mat-label>Номер БП</mat-label>
            <input type="text" matInput formControlName="buBeneficiary" />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <mat-label>Найменування/ПІБ</mat-label>
            <input type="text" matInput formControlName="beneficiaryName" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Код ЄДРПОУ/ІПН</mat-label>
            <input type="text" matInput formControlName="beneficiaryEdrpouCode" />
          </mat-form-field>
        </div>
      </div>

      <div class="column half">
        <h3>Контролери</h3>
        <div class="row">
          <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef>№</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.index }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="buController">
              <mat-header-cell *matHeaderCellDef> Номер БП</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.buController }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="controllerName">
              <mat-header-cell *matHeaderCellDef> Назва/ПІБ</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.controllerName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="controllerCountry">
              <mat-header-cell *matHeaderCellDef> Країна</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.controllerCountry }}
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            <div *matNoDataRow>Записи відсутні</div>
          </mat-table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
