import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Deposit } from '@api/loan-approval';

const depositFeatureKey = 'deposit';

export type DepositState = EntityState<Deposit>;

export const adapter = createEntityAdapter<Deposit>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.deposit || [], state)),
);

export const depositFeature = createFeature({
  name: depositFeatureKey,
  reducer,
  extraSelectors: ({ selectDepositState }) => ({
    ...adapter.getSelectors(selectDepositState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = depositFeature;
