import { Agreement } from '@api/loan-approval';

export const mainInfo = [
  'typeTask',
  'initiatorFullName',
  'rdTitle',
  'ruTitle',
  'assessmentType',
  'regionCode',
  'bpCode',
  'bpClientName',
  'requestNumber',
  'agreementSum',
];

export const agreementInfo = ['dealNumberSAP', 'productName', 'total', 'currency', 'term', 'rate', 'purposeLending'];

export const genAgreementInfo = ['productName', 'total', 'currency', 'term'];

export const existingGenAgreementInfo = ['dealNumberSAP', 'total', 'currency', 'startDate', 'endDate'];

export type InfoKey =
  | (typeof mainInfo)[number]
  | (typeof agreementInfo)[number]
  | (typeof genAgreementInfo)[number]
  | (typeof existingGenAgreementInfo)[number];

export const titleDictionary = new Map<InfoKey, string>([
  ['rdTitle', 'Назва РД'],
  ['ruTitle', 'Назва РУ'],
  ['initiatorFullName', 'ПІБ ініціатора'],
  ['typeTask', 'Тип заявки'],
  ['assessmentType', 'Тип оцінки'],
  ['regionCode', 'Код регіону'],
  ['agreementSum', 'Сумма в гривні'],
  ['requestNumber', 'Номер заявки'],
  ['bpCode', 'Код позичальника'],
  ['bpClientName', 'Позичальник'],
  ['dealNumberSAP', '№ договору в SAP'],
  ['productName', 'Продукт'],
  ['currency', 'Валюта'],
  ['total', 'Сума'],
  ['term', 'Строк'],
  ['rate', 'Ставка, %'],
  ['purposeLending', 'Мета кредитування'],
  ['startDate', 'Дата початку'],
  ['endDate', 'Дата завершення'],
]);

export const getInfoType = (agreement?: { general?: boolean; dealNumberSAP?: string }) => {
  switch (true) {
    case agreement && !agreement.general:
      return 'LOAN';
    case agreement && agreement.general && !agreement.dealNumberSAP:
      return 'GEN_LOAN';
    case !!(agreement && agreement.general && agreement.dealNumberSAP):
      return 'EXISTING_GEN_LOAN';
  }

  return undefined;
};

export const getInfoDictionaryByType = (type?: 'LOAN' | 'GEN_LOAN' | 'EXISTING_GEN_LOAN' | 'MAIN_INFO') => {
  switch (type) {
    case 'LOAN':
      return agreementInfo;
    case 'GEN_LOAN':
      return genAgreementInfo;
    case 'EXISTING_GEN_LOAN':
      return existingGenAgreementInfo;
    case 'MAIN_INFO':
      return mainInfo;
    default:
      return [];
  }
};

export const infoDictionaryMapper = (tableRow: string[], info: Record<InfoKey, any>) =>
  tableRow?.reduce(
    (acc, item: InfoKey) =>
      info[item]
        ? [
            ...acc,
            {
              name: titleDictionary.get(item)!,
              value: info[item],
            },
          ]
        : acc,
    [] as {
      name: string;
      value: any;
    }[],
  ) || [];

export const selectAgreementsTabsMapper = (agreements: Agreement[]) => [
  ...agreements
    .filter((agreement) => !agreement.general)
    .map((agreement, index) => ({
      id: agreement.id,
      type: 'agreement',
      title: `${index + 1} Угода`,
    })),
  ...agreements
    .filter((agreement) => agreement.general)
    .map((agreement, index) => ({
      id: agreement.id,
      type: 'genAgreement',
      title: `${index + 1}  Ген. Угода`,
    })),
];
