import { createAction, props } from '@ngrx/store';
import { ParticipantDTO } from '@api/dictionaries';

export const loadParticipants = createAction('[Dictionaries/API] Load Participants');
export const loadParticipantsSuccess = createAction(
  '[Dictionaries/API] Load Participants Success',
  props<{ participants: ParticipantDTO[] }>(),
);
export const loadParticipantsFailure = createAction(
  '[Dictionaries/API] Load Participants Failure',
  props<{ error: unknown }>(),
);
