export type PageType =
  | 'terms-agreement'
  | 'new-limits'
  | 'borrower-group'
  | 'current-loans'
  | 'credit-history'
  | 'check_KYC'
  | 'legal-cases'
  | 'providing'
  | 'guarantors'
  | 'notifications'
  | 'documents'
  | 'auto-check'
  | 'protocol'
  | 'agreement_deal'
  | 'providing_deal'
  | 'check-list';

export interface Tab {
  name: string;
  type: PageType;
  route: string;
}
