import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BankAgreementContractConfig {
  isActive: boolean;
  isValidatorsRequired: boolean;
}

export const bankAgreementContractToken = new InjectionToken<BehaviorSubject<BankAgreementContractConfig>>(
  'bankAgreementContractToken',
  {
    providedIn: 'root',
    factory: () =>
      new BehaviorSubject<BankAgreementContractConfig>({
        isActive: false,
        isValidatorsRequired: true,
      }),
  },
);
