import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectMeasurementUnits, selectMeasurementUnitsEntities } from './measurement-units.selectors';
import { loadMeasurementUnits } from './measurement-units.actions';

@Injectable({
  providedIn: 'root',
})
export class MeasurementUnitsFacadeService {
  public measurementUtils$ = this.store.select(selectMeasurementUnits);
  public measurementUtilsEntities$ = this.store.select(selectMeasurementUnitsEntities);

  constructor(private store: Store) {}

  public loadMeasurementUtils() {
    this.store.dispatch(loadMeasurementUnits());
  }
}
