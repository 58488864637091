<h3 mat-dialog-title>Додати запис</h3>

<button type="button" mat-button mat-dialog-close="">
  <mat-icon>close</mat-icon>
  Закрити
</button>

<div mat-dialog-content>
  <div class="content" [formGroup]="termsSecuringCreate">
    <div class="row type">
      <mat-radio-group
        formControlName="type"
        [value]="termsSecuringCreate.get('type')?.value"
        (change)="onSelectType()"
      >
        <mat-radio-button class="decision-item" value="providing"> Забезпечення</mat-radio-button>
        <mat-radio-button class="decision-item" value="guarantor"> Порука</mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container *ngIf="termsSecuringCreate.get('type')?.value === 'providing'">
      <div class="row category">
        <mat-form-field>
          <mat-label> Виберіть категорію забезпечення</mat-label>
          <mat-select placeholder="Виберіть категорію забезпечення" formControlName="categoryId">
            <mat-option
              *ngFor="let category of assetCategoryFacade.assetActiveCategories$ | async"
              [value]="category.name"
              >{{ category.categoryTitle }}</mat-option
            >
          </mat-select>
          <mat-error>Виберіть категорію забезпечення</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="row">
      <mat-form-field>
        <mat-label> Опис</mat-label>
        <textarea matInput formControlName="title" placeholder="Введіть опис"></textarea>
        <mat-error
          >Введіть опис
          {{ termsSecuringCreate.get('type')?.value === 'providing' ? 'забезпечення' : 'поруку' }}</mat-error
        >
      </mat-form-field>
    </div>

    <div class="row">
      <p>
        Дата, не пізніше якої Клієнт зобов'язується надати
        {{ termsSecuringCreate.get('type')?.value === 'providing' ? 'забезпечення' : 'поруку' }}
      </p>

      <mat-form-field (click)="datepicker.open()">
        <mat-label>Дата</mat-label>
        <input matInput formControlName="date" [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button type="reset" mat-stroked-button [mat-dialog-close]="">Вийти</button>

  <button type="submit" mat-raised-button (click)="onSubmitDialog()" color="primary">Підтвердити</button>
</div>
