/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BusinessLevelDto } from '../models/BusinessLevelDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BusinessLevelService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all business levels
     * Returns all business levels.
     * @returns BusinessLevelDto all business levels
     * @throws ApiError
     */
    public getApiBusinessLevels(): Observable<Array<BusinessLevelDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/business-levels',
        });
    }

    /**
     * Update business level
     * Updates the business level.
     * @returns any business level updated
     * @throws ApiError
     */
    public putApiBusinessLevels({
        requestBody,
    }: {
        /**
         * Business level object that needs to be updated.
         */
        requestBody: BusinessLevelDto,
    }): Observable<{
        id: number;
        name?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/business-levels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Create business level
     * Method for creating business level.
     * @returns BusinessLevelDto business level created
     * @throws ApiError
     */
    public postApiBusinessLevels({
        requestBody,
    }: {
        /**
         * Business level object that needs to be created.
         */
        requestBody: BusinessLevelDto,
    }): Observable<BusinessLevelDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/business-levels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get business level
     * Get business level by ID.
     * @returns any business level found
     * @throws ApiError
     */
    public getApiBusinessLevels1({
        id,
    }: {
        /**
         * Business level ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        name?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/business-levels/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `business level not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete business level
     * Deletes the business level with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiBusinessLevels({
        id,
    }: {
        /**
         * Business level ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/business-levels/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
