import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CashFlowZKR } from '@api/loan-approval';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CustomErrorStateMatcher } from '@sib/shared/util';
import { getYear } from 'date-fns';
import { cashFlowZkrDateValidation } from './cash-flow-zkrs-table.validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CurrencyMaskDirective } from 'ngx-currency';

@Component({
  selector: 'sib-cash-flow-zkrs-table',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    DigitOnlyModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    CurrencyMaskDirective,
  ],
  templateUrl: './cash-flow-zkrs-table.component.html',
  styleUrls: ['./cash-flow-zkrs-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CashFlowZKRSTableComponent,
      multi: true,
    },
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
  ],
})
export class CashFlowZKRSTableComponent implements ControlValueAccessor, OnInit {
  private destroyRef = inject(DestroyRef);

  private static readonly currentYear = getYear(new Date());
  private static readonly maxYearPeriod = 100;

  public isDisabled = false;
  @Input()
  public flowType: CashFlowZKR['flowType'] = 'FOT_UAH';

  @HostBinding('class.ng-invalid')
  private ngInvalid = false;

  public columnsDef: Array<keyof CashFlowZKR | 'settings' | 'error'> = [
    'flowType',
    'month1',
    'year1',
    'month2',
    'year2',
    'kvrtSum',
    'settings',
    'error',
  ];

  public dataSource = new MatTableDataSource<ReturnType<typeof CashFlowZKRSTableComponent.defineCashFlowZKRS>>();

  public form = new FormGroup({
    cashFlowZKR: new FormArray<ReturnType<typeof CashFlowZKRSTableComponent.defineCashFlowZKRS>>([]),
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (_: CashFlowZKR[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};

  public static readonly defineCashFlowZKRS = (flowType: CashFlowZKR['flowType']) =>
    new FormGroup(
      {
        flowType: new FormControl(
          { value: flowType, disabled: false },
          { validators: [Validators.required], nonNullable: true },
        ),
        month1: new FormControl('', {
          validators: [Validators.required, Validators.min(1), Validators.max(12)],
          nonNullable: true,
        }),
        year1: new FormControl('', {
          validators: [
            Validators.required,
            Validators.min(CashFlowZKRSTableComponent.currentYear),
            Validators.max(CashFlowZKRSTableComponent.currentYear + CashFlowZKRSTableComponent.maxYearPeriod),
          ],
          nonNullable: true,
        }),
        month2: new FormControl('', {
          validators: [Validators.required, Validators.min(1), Validators.max(12)],
          nonNullable: true,
        }),
        year2: new FormControl('', {
          validators: [
            Validators.required,
            Validators.min(CashFlowZKRSTableComponent.currentYear),
            Validators.max(CashFlowZKRSTableComponent.currentYear + CashFlowZKRSTableComponent.maxYearPeriod),
          ],
          nonNullable: true,
        }),
        kvrtSum: new FormControl(0, { validators: [Validators.min(0.01), Validators.required], nonNullable: true }),
      },
      { validators: [cashFlowZkrDateValidation] },
    );

  ngOnInit() {
    this.form.controls.cashFlowZKR.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });
  }

  public writeValue(value: CashFlowZKR[]) {
    this.form.controls.cashFlowZKR.clear({ emitEvent: false });
    value?.forEach((item: CashFlowZKR) => {
      const group = CashFlowZKRSTableComponent.defineCashFlowZKRS(this.flowType);
      group.patchValue(item);

      this.form.controls.cashFlowZKR.push(group as FormGroup, { emitEvent: false });
    });
    this.dataSource.data = this.form.controls.cashFlowZKR.controls;
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    return isDisabled ? this.form.disable({ emitEvent: false }) : this.form.enable();
  }

  public registerOnChange(fn: (value: unknown) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  addCashFlowZKRS() {
    this.form.controls.cashFlowZKR.push(CashFlowZKRSTableComponent.defineCashFlowZKRS(this.flowType));
    this.dataSource.data = this.form.controls.cashFlowZKR.controls;
  }

  removeAllCashFlowZKRS() {
    this.form.controls.cashFlowZKR.clear();
    this.dataSource.data = this.form.controls.cashFlowZKR.controls;
  }

  removeByIndexCashFlowZKRS(index: number) {
    this.form.controls.cashFlowZKR.removeAt(index);
    this.dataSource.data = this.form.controls.cashFlowZKR.controls;
  }
}
