import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllPromotion } from './promotion.selectors';
import { loadPromotion } from './promotion.actions';

@Injectable({
  providedIn: 'root',
})
export class PromotionFacadeService {
  public getPromotion$ = this.store.select(selectAllPromotion);

  constructor(private store: Store) {}

  public loadPromotion() {
    this.store.dispatch(loadPromotion());
  }
}
