<h2>{{ title }}</h2>

<div class="navigation mat-subtitle-2">
  <a [routerLink]="[item.route]" routerLinkActive="router-link-active" *ngFor="let item of navigation">
    <ng-container [ngSwitch]="item.icon">
      <mat-icon color="primary" *ngSwitchCase="'arrow'">call_made</mat-icon>
      <mat-icon color="primary" *ngSwitchCase="'multiCheck'" [svgIcon]="'account_multiple_check'"></mat-icon>
      <mat-icon color="primary" *ngSwitchCase="'group'" [svgIcon]="'account_group'"></mat-icon>
    </ng-container>
    {{ item.title }}
  </a>
</div>

<div class="breadcrumbs">
  <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
  <ng-template #iconTemplate>
    <mat-icon>arrow_forward</mat-icon>
  </ng-template>
</div>
