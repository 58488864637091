import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AssetCategoryActions from './asset-category.actions';
import * as AssetCategorySelectors from './asset-category.selectors';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class AssetCategoryEffects {
  loadAssetCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetCategoryActions.loadAssetCategory),
      concatLatestFrom((action) => [this.store$.select(AssetCategorySelectors.selectAllAssetCategory)]),
      switchMap(([action, allAssetCategory]) =>
        (allAssetCategory.length ? of(allAssetCategory) : this.dictionariesService.loadAssetCategory()).pipe(
          map((response) =>
            AssetCategoryActions.loadAssetCategorySuccess({
              assetCategory: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(AssetCategoryActions.loadAssetCategoryFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
