import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GuaranteeCategory } from '@api/loan-approval';

@Injectable({
  providedIn: 'root',
})
export class ScopeContractGroupService extends FormGroup<ReturnType<typeof ScopeContractGroupService.form>> {
  static readonly form = () => ({
    agreementSum: new FormControl<string>(
      { value: '', disabled: true },
      {
        validators: [Validators.required],
        nonNullable: true,
      },
    ),
    agreementSumUAH: new FormControl<number>({ value: 0, disabled: true }, { nonNullable: true }),
    currencyCode: new FormControl<string>(
      { value: '', disabled: true },
      {
        validators: [Validators.required],
        nonNullable: true,
      },
    ),
    endDate: new FormControl<string>(
      { value: '', disabled: true },
      {
        validators: [Validators.required],
        nonNullable: true,
      },
    ),
    guaranteeCat: new FormControl<GuaranteeCategory>(
      { value: 'COVERED_GUARANTEE', disabled: true },
      {
        validators: [Validators.required],
        nonNullable: true,
      },
    ),
    coverageAccount: new FormControl<string>({ value: '', disabled: true }),
    curAccount: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
  });

  constructor() {
    super(ScopeContractGroupService.form());
  }
}
