import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, NgModule } from '@angular/core';
import { appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RequestInterceptor } from './core/interceptors/app-request-interceptor.interceptor';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { LayoutComponent } from './pages/layout/layout.component';
import { HomeComponent } from './pages/home/home.component';

import { environment } from '@sib/shared/util';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
// !NGRX
// !NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effects, metaReducers, ROOT_REDUCERS } from './core/store';
import * as fromRouter from '../../libs/shared/store/src/lib/router-state/router.reducer';
import { authFeature, routerFeatureKey } from '@sib/shared/store';
// !End NGRX
// !End NGRX
import { DialogModule } from '@ngneat/dialog';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DATE_PIPE_DEFAULT_OPTIONS, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';

import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from './shared/shared.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { uk } from 'date-fns/locale';
import { LoginService, TokenService } from '@api/auth';
import {
  AgreementService,
  AksIntegrationService,
  ApplicationStatisticService,
  AssociatedDocumentService as AssociatedDocumentServiceLoanApproval,
  BorrowerGroupService,
  CamundaService,
  CheckListService,
  CurrencyRateService,
  FileStoreService,
  GroupLimitService,
  GuarantorService,
  ReportService,
  SapAutoCheckService,
  SapIntegrationService,
  TaskChecksService,
  TaskService,
  TechService,
  VariablesService,
} from '@api/loan-approval';

import {
  AnnulmentTypeService,
  AssessmentApproachService,
  AssessmentKindService,
  AssessmentTypeService,
  AssetTypeService,
  AssociatedDocumentService,
  BankServiceService,
  BelongingObjectService,
  BpRoleCourtCasesService,
  BpRolesService,
  CarBrandService,
  CategoryTypeService,
  CheckupService,
  ConditionGroupService,
  ConditionService,
  ConditionTypeService,
  ConsLevelService,
  ControlGroupService,
  CurrencyService,
  DecisionService,
  DictionaryEditorService,
  DictionaryGroupService,
  DictionaryService,
  DocumentTemplateService,
  DocumentTypeService,
  EquipmentTypeService,
  ErrorsService,
  LawCourtService,
  LoanClassService,
  LoanTypeService,
  LocationAkdService,
  MeasurementUnitService,
  MonitoringResultService,
  ObligationTypeService,
  ParticipantService,
  ProductCategoryService,
  ProductService,
  PromotionService,
  ProposalTypeService,
  ProtocolConditionTypeService,
  ReportService as ReportServiceDictionary,
  RequestTypeService,
  RiskSegmentService,
  RoleAccessService,
  RoleAnnulmentTypeService,
  RoleService,
  SodService,
  SourceAssessmentService,
  TypeDocOwnershipService,
  TypeDocUseLandService,
  UiTabService,
  UseTypeService,
  VotingOptionService,
} from '@api/dictionaries';
import {
  AssetCategoryService,
  AssetSubtypeService,
  EmployeeService,
  OrgUnitService,
  RegionService,
} from '@api/loan-org-structure';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { DefaultService } from '@api/camunda';
import { GlobalErrorHandler } from '@app/core/interceptors/global-error-handler';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { StatusService } from '@api/health-service';
import { UiFacadeService } from '@app/core/store/facades/ui.facade.service';
import { catchError, tap } from 'rxjs/operators';
import { CURRENCY_MASK_CONFIG, CurrencyMaskInputMode } from 'ngx-currency';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  provideRouter,
  RouteReuseStrategy,
  RouterOutlet,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { TemplateService } from '@api/document-template';
import { NG_PROGRESS_CONFIG, NgProgressComponent } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { TASK_FEATURE_KEY, taskEffects, taskReducer, taskSettingsFeature } from '@sib/task/shared/store';
import { setBaseUrl } from '@sib/shared/da';
import { DateFnsConfigurationService } from 'ngx-date-fns';

const ukrainianConfig = new DateFnsConfigurationService();
ukrainianConfig.setLocale(uk);

/**
 * Route strategy that reloads component if navigate on the same route path but with different params
 */
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  /**
   * Determines if a route should be reused.
   * This strategy returns `true` when the future route config and current route config are
   * identical.
   */
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
    return future.routeConfig === curr.routeConfig && JSON.stringify(future.params) === JSON.stringify(curr.params);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SidebarComponent,
    HeaderComponent,
    MatTooltipModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BreadcrumbModule,
    MatDialogModule,
    SharedModule,
    // !DialogModule
    DialogModule.forRoot(),
    // !Toast
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    // !
    // !NGRX
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        // This check verifies if the state is serializable.
        // A serializable state is important to be able
        // to persist the current state to be able to rehydrate the state in the future.
        // As we don't have SSR we don't need rehydration
        strictStateSerializability: false,
        // Please note, you may not need to set strictActionSerializability to true
        // unless you are storing/replaying actions using external resources, for example localStorage.
        // We use only Redux DevTools for debug, for now it is not mandatory
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'NGRX Store App',
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: fromRouter.routerFeatureKey,
      serializer: fromRouter.CustomSerializer,
    }),
    EffectsModule.forRoot(effects),
    NgxPermissionsModule.forRoot(),
    MatDateFnsModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        extendsFromRoot: true,
        background: 'var(--sib-color-basic-200)',
      },
    }),
    LayoutComponent,
    HomeComponent,
    RouterOutlet,
    NgProgressComponent,
    StoreModule.forFeature(TASK_FEATURE_KEY, taskReducer),
    StoreModule.forFeature(routerFeatureKey, routerReducer),
    StoreModule.forFeature(authFeature),
    StoreModule.forFeature(taskSettingsFeature),
    EffectsModule.forFeature(taskEffects),
  ],
  providers: [
    provideRouter(
      appRoutes,
      // withDebugTracing(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
      withInMemoryScrolling({ scrollPositionRestoration: 'disabled' }),
      withComponentInputBinding(),
    ),
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    BreadcrumbService,
    {
      provide: APP_INITIALIZER,
      useFactory: (statusService: StatusService, uiFacadeService: UiFacadeService) => () =>
        statusService
          .postApiStatusAppsVersions({
            requestBody: ['loan-approval', 'document-template', 'dictionaries', 'orgstructure'],
          })
          .pipe(
            tap((result: string[]) => uiFacadeService.saveVersion(result)),
            catchError(() => []),
          ),
      deps: [StatusService, UiFacadeService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: 'min(900px, 90vw)',
        autoFocus: 'dialog',
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined',
      },
    },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd.MM.yyyy' } },
    { provide: MAT_DATE_LOCALE, useValue: uk },
    { provide: DateFnsConfigurationService, useValue: ukrainianConfig },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: {
        align: 'right',
        allowNegative: false,
        allowZero: true,
        decimal: '.',
        precision: 2,
        prefix: '',
        suffix: '',
        thousands: ' ',
        nullable: false,
        inputMode: CurrencyMaskInputMode.FINANCIAL,
      },
    },
    importProvidersFrom(NgProgressHttpModule),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask({ thousandSeparator: ' ' }),
    {
      provide: NG_PROGRESS_CONFIG,
      useValue: {
        trickleSpeed: 200,
        min: 8,
        spinner: false,
        color: 'var(--sib-color-accent-500)',
      },
    },
    LoginService,
    TokenService,
    VariablesService,
    ConditionService,
    BorrowerGroupService,
    AgreementService,
    ApplicationStatisticService,
    EmployeeService,
    ReportService,
    ReportServiceDictionary,
    CamundaService,
    SapIntegrationService,
    GuarantorService,
    DecisionService,
    ProtocolConditionTypeService,
    ConditionGroupService,
    RiskSegmentService,
    ObligationTypeService,
    DefaultService,
    RoleAccessService,
    AksIntegrationService,
    AnnulmentTypeService,
    RoleAnnulmentTypeService,
    ConsLevelService,
    LoanTypeService,
    ProductService,
    LoanClassService,
    LawCourtService,
    BpRolesService,
    BpRoleCourtCasesService,
    ProposalTypeService,
    AssetTypeService,
    AssetCategoryService,
    CurrencyService,
    ErrorsService,
    UiTabService,
    EmployeeService,
    RoleService,
    OrgUnitService,
    LocationAkdService,
    DocumentTypeService,
    AssociatedDocumentService,
    ParticipantService,
    VotingOptionService,
    DictionaryService,
    DictionaryEditorService,
    DictionaryGroupService,
    BankServiceService,
    ProductCategoryService,
    AssessmentTypeService,
    SodService,
    RequestTypeService,
    SourceAssessmentService,
    AssessmentApproachService,
    MonitoringResultService,
    BelongingObjectService,
    RegionService,
    CarBrandService,
    UseTypeService,
    TypeDocUseLandService,
    TypeDocOwnershipService,
    EquipmentTypeService,
    MeasurementUnitService,
    AssessmentKindService,
    AssetSubtypeService,
    CheckupService,
    ControlGroupService,
    DocumentTemplateService,
    StatusService,
    CurrencyRateService,
    TechService,
    TaskChecksService,
    ConditionTypeService,
    SapAutoCheckService,
    FileStoreService,
    TemplateService,
    CategoryTypeService,
    AssociatedDocumentServiceLoanApproval,
    CheckListService,
    PromotionService,
    GroupLimitService,
    TaskService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    setBaseUrl(!environment.production);
  }
}
