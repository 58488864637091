/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BpRoleCourtCasesDto } from '../models/BpRoleCourtCasesDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BpRoleCourtCasesService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all bp role court cases
     * Returns all bp role court cases.
     * @returns BpRoleCourtCasesDto all bp role court cases
     * @throws ApiError
     */
    public getApiBpRoleCourtCases(): Observable<Array<BpRoleCourtCasesDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/bp-role-court-cases',
        });
    }

    /**
     * Create a bp role court cases
     * Method for creating a bp role court cases.
     * @returns any bp role court cases created
     * @throws ApiError
     */
    public postApiBpRoleCourtCases({
        requestBody,
    }: {
        /**
         * Bp role court cases object that needs to be created.
         */
        requestBody: BpRoleCourtCasesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/bp-role-court-cases',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get bp role court cases
     * Get bp role court cases by ID.
     * @returns any bp role court cases found
     * @throws ApiError
     */
    public getApiBpRoleCourtCases1({
        id,
    }: {
        /**
         * Bp role court cases ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/bp-role-court-cases/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `bp role court cases not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update a bp role court cases
     * Updates the bp role court cases.
     * @returns any bp role court cases updated
     * @throws ApiError
     */
    public putApiBpRoleCourtCases({
        id,
        requestBody,
    }: {
        /**
         * Bp role court cases ID. Cannot be empty.
         */
        id: number,
        /**
         * Bp role court cases object that needs to be updated.
         */
        requestBody: BpRoleCourtCasesDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/bp-role-court-cases/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete bp role court cases
     * Deletes the bp role court cases with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiBpRoleCourtCases({
        id,
    }: {
        /**
         * Bp role court cases ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/bp-role-court-cases/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
