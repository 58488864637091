import { createAction, props } from '@ngrx/store';
import { DocumentTypeDto, LocationAkdDto } from '@api/dictionaries';

export const loadLocationAkd = createAction('[Dictionaries/API] Load Location Akd');
export const loadLocationAkdSuccess = createAction(
  '[Dictionaries/API] Load Location Akd Success',
  props<{ locationAkd: LocationAkdDto[] }>(),
);
export const loadLocationAkdFailure = createAction(
  '[Dictionaries/API] Load Location Akd Failure',
  props<{ error: unknown }>(),
);

export const loadDocTypes = createAction('[Dictionaries/API] Load Doc Types');
export const loadDocTypesSuccess = createAction(
  '[Dictionaries/API] Load Doc Types Success',
  props<{ docTypes: DocumentTypeDto[] }>(),
);
export const loadDocTypesFailure = createAction(
  '[Dictionaries/API] Load Doc Types Failure',
  props<{ error: unknown }>(),
);
