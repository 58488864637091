import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as ConsiderationLevelActions from './consideration-level.actions';
import { ConsiderationLevelDto } from '@api/dictionaries';

export const considerationLevelFeatureKey = 'consideration-level';

export interface State extends EntityState<ConsiderationLevelDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ConsiderationLevelDto> = createEntityAdapter<ConsiderationLevelDto>({
  selectId: (considerationLevelItem: ConsiderationLevelDto) => considerationLevelItem.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ConsiderationLevelActions.loadConsiderationLevel, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ConsiderationLevelActions.loadConsiderationLevelSuccess, (state, { considerationLevelList }) => ({
    ...adapter.setAll(considerationLevelList, state),
    isLoading: false,
  })),

  on(ConsiderationLevelActions.loadConsiderationLevelFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
