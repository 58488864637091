import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DictionariesActions from './dictionaries.actions';
import * as DictionariesSelectors from './dictionaries.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class DictionariesEffects {
  loadDictionaryEditorList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DictionariesActions.loadDictionaries),
      concatLatestFrom((action) => [this.store$.select(DictionariesSelectors.selectAllDictionaries)]),
      switchMap(([action, dictionaryEditorList]) =>
        (dictionaryEditorList.length ? of(dictionaryEditorList) : this.dictionariesService.loadDictionaryEditor()).pipe(
          map((response) =>
            DictionariesActions.loadDictionariesSuccess({
              dictionaries: response,
            }),
          ),
          catchError((error) => of(DictionariesActions.loadDictionariesFailure({ error }))),
        ),
      ),
    ),
  );

  reloadDictionaryEditorList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DictionariesActions.reloadDictionaries),
      switchMap((action) =>
        this.dictionariesService.loadDictionaryEditor().pipe(
          map((response) =>
            DictionariesActions.reloadDictionariesSuccess({
              dictionaries: response,
            }),
          ),
          catchError((error) => of(DictionariesActions.reloadDictionariesFailure({ error }))),
        ),
      ),
    ),
  );

  loadDictionaryOrgstructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DictionariesActions.loadDictionariesOrgstructure),
      concatLatestFrom((action) => [this.store$.select(DictionariesSelectors.selectAllDictionariesOrgstructure)]),
      switchMap(([action, allDictionaryOrgstructure]) =>
        (allDictionaryOrgstructure.length
          ? of(allDictionaryOrgstructure)
          : this.dictionariesService.loadDictionaryOrgstructure()
        ).pipe(
          map((response) =>
            DictionariesActions.loadDictionariesOrgstructureSuccess({
              dictionariesOrgstructure: response,
            }),
          ),
          catchError((error) => of(DictionariesActions.loadDictionariesOrgstructureFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
