import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { DialogService } from '@ngneat/dialog';
import { TokenService } from '@api/auth';
import { JwtService } from './jwt.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private interval: string | number | NodeJS.Timer | undefined;

  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private tokenService: TokenService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private jwtService: JwtService,
  ) {}

  back() {
    window.history.back();
  }

  get isLoggedIn() {
    return !!this.jwtService.token;
  }

  setPermissions() {
    if (this.jwtService.decodedToken) {
      const decoded = this.jwtService.decodedToken;
      this.checkTokenLife(decoded.exp);
      if (decoded.groups) {
        this.permissionsService.loadPermissions(decoded.groups);
        return;
      }

      this.permissionsService.loadPermissions([]);
    }
  }

  checkTokenLife(exp: number) {
    const tokenExp = new Date(Number(exp + '000')).getTime();
    let timeNow = new Date().getTime();

    if (timeNow > tokenExp) {
      this.logout();
    } else {
      this.interval = setInterval(() => {
        timeNow = new Date().getTime();
        if (tokenExp - timeNow <= 60000) {
          this.getNewToken();
        } else if (!this.jwtService.token) {
          this.logout();
        } else if (!(tokenExp - timeNow > 0)) {
          this.logout();
        }
      }, 10000);
    }
  }

  logout() {
    this.jwtService.clearToken();
    this.router.navigate(['/login']);
    this.dialogService.closeAll();
    this.matDialog.closeAll();
    clearInterval(this.interval);
  }

  getNewToken() {
    this.tokenService.getToken().subscribe({
      next: ({ token }) => {
        if (token) {
          this.jwtService.setToken(token);
          this.setPermissions();
        }
      },
      error: () => {
        clearInterval(this.interval);
        this.logout();
      },
    });
  }

  checkIfUserHasRole(role: string) {
    let state = false;
    const roles = this.jwtService.getUserRoles();
    if (roles.findIndex((i: string) => i === role) !== -1) {
      state = true;
    }

    return state;
  }
}
