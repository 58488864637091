import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class CreditAgreementContractGroupService extends FormGroup<
  ReturnType<typeof CreditAgreementContractGroupService.formGroup>
> {
  private static readonly formGroup = () => ({
    clientName: new FormControl<string>({ value: '', disabled: true }),
    dealTitle: new FormControl<string>({ value: '', disabled: true }),
    agreementSum: new FormControl<string>({ value: '', disabled: true }),
    currencyCode: new FormControl<string>({ value: '', disabled: true }),
    agreementSumUAH: new FormControl<string | number>({ value: '', disabled: true }),
    dealNumberSAP: new FormControl<string>({ value: '', disabled: true }),
    dealDate: new FormControl<Date | string>({ value: '', disabled: true }),
    dealDateEnd: new FormControl<Date | string>({ value: '', disabled: true }),
    edrpouCode: new FormControl<string | null>({ value: null, disabled: true }),
  });

  constructor() {
    super(CreditAgreementContractGroupService.formGroup());
  }
}
