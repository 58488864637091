<h2>{{ title }}</h2>

<mat-table *ngIf="dataSource.data.length" [dataSource]="dataSource">
  <ng-container matColumnDef="index">
    <mat-header-cell *matHeaderCellDef> № з/п</mat-header-cell>
    <mat-cell
      *matCellDef="let element; let i = index"
      [ngxMatContextMenuTriggerFor]="menu"
      [ngxMatContextMenuTriggerData]="{ row: element, index: i }"
    >
      {{ i + 1 }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef> Опис</mat-header-cell>
    <mat-cell
      *matCellDef="let element; let i = index"
      [matTooltip]="element.title"
      [ngxMatContextMenuTriggerFor]="menu"
      [ngxMatContextMenuTriggerData]="{ row: element, index: i }"
    >
      <span class="ellipsis">
        {{ element.title }}
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index" style="justify-content: flex-end">
      <button
        type="button"
        mat-icon-button
        color="primary"
        [disabled]="disabled"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{ row: element, index: i }"
      >
        <mat-icon>pending</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-menu #menu="matMenu">
  <ng-template let-row="row" let-index="index" matMenuContent>
    <button type="button" mat-menu-item disabled>Операції із записом {{ index + 1 }}</button>
    <ng-container>
      <button type="button" mat-menu-item (click)="addNew(row.categoryId)" [disabled]="disabled">
        <mat-icon color="primary">assignment_add</mat-icon>
        Додати нову позицію
      </button>
      <button type="button" mat-menu-item (click)="edit(row)" [disabled]="disabled">
        <mat-icon color="primary">edit</mat-icon>
        Відредагувати
      </button>
      <button type="button" mat-menu-item (click)="generate(row)" [disabled]="!row.id || disabled">
        <mat-icon color="primary">refresh</mat-icon>
        Переформувати
      </button>
      <button type="button" mat-menu-item (click)="delete(row)" [disabled]="disabled" *ngIf="!row.id">
        <mat-icon color="primary">delete</mat-icon>
        Видалити запис
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
