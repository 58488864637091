import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { clearToken, setToken } from './openapi';

export interface ParsedToken {
  aud: string;
  exp: number;
  groups: string[];
  iss: string;
  sub: string;
  'unit-groups': string[];
}

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  public decodedToken: ParsedToken | null = null;
  public token = '';

  constructor() {
    const token = localStorage.getItem('token');
    if (token) {
      this.setToken(token);
    }
  }

  getUserLogin() {
    return this.decodedToken?.sub || '';
  }

  getUserRoles() {
    return this.decodedToken?.groups || [];
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
    this.decodedToken = jwt_decode<ParsedToken>(token);
    this.token = token;
    setToken(token);
  }

  clearToken(): void {
    localStorage.removeItem('token');
    this.decodedToken = null;
    this.token = '';
    clearToken();
  }
}
