/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SubprocessDocumentGroupDto } from '../models/SubprocessDocumentGroupDto';
import type { SubprocessEnum } from '../models/SubprocessEnum';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SubprocessDocumentGroupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get list of subprocess - document group
     * Get list of subprocess - document group
     * @returns any document group found
     * @throws ApiError
     */
    public getApiSubprocessDocumentGroups(): Observable<{
        id: number;
        code?: string;
        description?: string;
        documentTypeIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/subprocess-document-groups',
            errors: {
                404: `document group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Create subprocess-document group
     * Method for creating subprocess-document group.
     * @returns SubprocessDocumentGroupDto subprocess-document group created
     * @throws ApiError
     */
    public postApiSubprocessDocumentGroups({
        requestBody,
    }: {
        /**
         * Subprocess-document group object that needs to be created.
         */
        requestBody: SubprocessDocumentGroupDto,
    }): Observable<SubprocessDocumentGroupDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/subprocess-document-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get subprocess-document group by document group ID
     * Get subprocess-document group by document group ID
     * @returns SubprocessDocumentGroupDto document group found
     * @throws ApiError
     */
    public getApiSubprocessDocumentGroupsByDocumentGroup({
        id,
    }: {
        /**
         * Document group ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<SubprocessDocumentGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/subprocess-document-groups/by-document-group/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `document group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get subprocess-document group by subprocess
     * Get subprocess-document group by document group ID
     * @returns SubprocessDocumentGroupDto subprocess document group found
     * @throws ApiError
     */
    public getApiSubprocessDocumentGroupsBySubprocess({
        subprocess,
    }: {
        subprocess: SubprocessEnum,
    }): Observable<Array<SubprocessDocumentGroupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/subprocess-document-groups/by-subprocess/{subprocess}',
            path: {
                'subprocess': subprocess,
            },
            errors: {
                404: `document group not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Create subprocess-document group
     * Method for creating subprocess-document group.
     * @returns SubprocessDocumentGroupDto subprocess-document group created
     * @throws ApiError
     */
    public postApiSubprocessDocumentGroupsDocumentGroupIdSubprocess({
        documentGroupId,
        subprocess,
    }: {
        /**
         * Document group ID. Cannot be empty.
         */
        documentGroupId: number,
        /**
         * Subprocess. Cannot be empty.
         */
        subprocess: string,
    }): Observable<SubprocessDocumentGroupDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/subprocess-document-groups/document-group-id/{documentGroupId}/subprocess/{subprocess}',
            path: {
                'documentGroupId': documentGroupId,
                'subprocess': subprocess,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteApiSubprocessDocumentGroupsDocumentGroupIdSubprocess({
        documentGroupId,
        subprocess,
    }: {
        /**
         * Document group ID. Cannot be empty.
         */
        documentGroupId: number,
        /**
         * Subprocess. Cannot be empty.
         */
        subprocess: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/subprocess-document-groups/document-group-id/{documentGroupId}/subprocess/{subprocess}',
            path: {
                'documentGroupId': documentGroupId,
                'subprocess': subprocess,
            },
        });
    }

}
