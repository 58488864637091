import { createSelector } from '@ngrx/store';
import * as fromRiskSegments from './risk-segments.reducer';
import { convertArrayToObjectTyped } from '@sib/shared/util';
import { selectRiskSegmentsState } from '../+shell/reducers.index';

export const selectIsLoadingRiskSegments = createSelector(selectRiskSegmentsState, fromRiskSegments.selectIsLoading);

export const selectRiskSegments = createSelector(selectRiskSegmentsState, fromRiskSegments.selectAll);

export const selectRiskSegmentsEntities = createSelector(selectRiskSegments, (allRiskSegments) =>
  convertArrayToObjectTyped(allRiskSegments, 'id'),
);

export const selectRiskSegmentById = (riskSegmentId: string) =>
  createSelector(selectRiskSegmentsEntities, (riskSegmentsEntities) => riskSegmentsEntities[riskSegmentId]);
