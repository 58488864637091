import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ConsiderationLevelActions from './consideration-level.actions';
import * as ConsiderationLevelSelectors from './consideration-level.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ConsiderationLevelEffects {
  loadConsiderationLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsiderationLevelActions.loadConsiderationLevel),
      concatLatestFrom((action) => [this.store$.select(ConsiderationLevelSelectors.selectAllConsiderationLevelList)]),
      switchMap(([action, considerationLevelList]) =>
        (considerationLevelList.length
          ? of(considerationLevelList)
          : this.dictionariesService.loadConsiderationLevel()
        ).pipe(
          map((response) =>
            ConsiderationLevelActions.loadConsiderationLevelSuccess({
              considerationLevelList: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(ConsiderationLevelActions.loadConsiderationLevelFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
