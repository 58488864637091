import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DictionaryGroupsActions from './dictionary-groups.actions';
import * as DictionaryGroupsSelectors from './dictionary-groups.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class DictionaryGroupsEffects {
  loadDictionaryGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DictionaryGroupsActions.loadDictionaryGroups),
      concatLatestFrom((action) => [this.store$.select(DictionaryGroupsSelectors.selectDictionaryGroups)]),
      switchMap(([action, allDictionaryGroups]) =>
        (allDictionaryGroups.length ? of(allDictionaryGroups) : this.dictionariesService.loadDictionaryGroups()).pipe(
          map((response) =>
            DictionaryGroupsActions.loadDictionaryGroupsSuccess({
              dictionaryGroups: response,
            }),
          ),
          catchError((error) => of(DictionaryGroupsActions.loadDictionaryGroupsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
