import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@sib/shared/util';
import {
  AnnulmentTypeService,
  AssessmentApproachDto,
  AssessmentApproachService,
  AssessmentKindDto,
  AssessmentKindService,
  AssessmentTypeDto,
  AssessmentTypeService,
  AssetTypeDto,
  AssetTypeService,
  AssociatedDocumentService,
  BankServiceDTO,
  BankServiceService,
  BelongingObjectDto,
  BelongingObjectService,
  BpRoleCourtCasesService,
  BpRolesService,
  CarBrandDto,
  CarBrandService,
  CheckupDto,
  CheckupService,
  ConsLevelService,
  ControlGroupDto,
  ControlGroupService,
  CurrencyService,
  DictionaryEditorService,
  DictionaryGroupService,
  DictionaryService,
  DocumentTypeService,
  EquipmentTypeDto,
  EquipmentTypeService,
  ErrorsService,
  LawCourtService,
  LoanClassService,
  LoanTypeService,
  LocationAkdService,
  MeasurementUnitDto,
  MeasurementUnitService,
  MonitoringResultDto,
  MonitoringResultService,
  ParticipantService,
  ProductCategoryDto,
  ProductCategoryService,
  ProductService,
  ProposalTypeDto,
  ProposalTypeService,
  ReportService,
  RequestTypeDto,
  RequestTypeService,
  RoleAnnulmentTypeService,
  RoleService,
  SodDto,
  SodService,
  SourceAssessmentDto,
  SourceAssessmentService,
  TypeDocOwnershipDto,
  TypeDocOwnershipService,
  TypeDocUseLandDto,
  TypeDocUseLandService,
  UiTabService,
  UseTypeDto,
  UseTypeService,
  VotingOptionService,
} from '@api/dictionaries';
import {
  AssetCategoryDto,
  AssetCategoryService,
  AssetSubtypeDto,
  AssetSubtypeService,
  DictionaryDTO,
  DictionaryQueryDTO,
  EmployeeService,
  OrgUnitService,
  RegionDto,
  RegionService,
} from '@api/loan-org-structure';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  constructor(
    private https: HttpClient,
    private annulmentTypeService: AnnulmentTypeService,
    private roleAnnulmentTypeService: RoleAnnulmentTypeService,
    private consLevelService: ConsLevelService,
    private loanTypeService: LoanTypeService,
    private productService: ProductService,
    private loanClassService: LoanClassService,
    private lawCourtService: LawCourtService,
    private bpRolesService: BpRolesService,
    private bpRoleCourtCasesService: BpRoleCourtCasesService,
    private proposalTypeService: ProposalTypeService,
    private assetTypeService: AssetTypeService,
    private assetCategoryService: AssetCategoryService,
    private currencyService: CurrencyService,
    private errorsService: ErrorsService,
    private uiTabService: UiTabService,
    private employeeService: EmployeeService,
    private roleService: RoleService,
    private orgUnitService: OrgUnitService,
    private locationAkdService: LocationAkdService,
    private documentTypeService: DocumentTypeService,
    private associatedDocumentService: AssociatedDocumentService,
    private participantService: ParticipantService,
    private votingOptionService: VotingOptionService,
    private reportService: ReportService,
    private dictionaryService: DictionaryService,
    private dictionaryEditorService: DictionaryEditorService,
    private dictionaryGroupService: DictionaryGroupService,
    private bankServiceService: BankServiceService,
    private productCategoryService: ProductCategoryService,
    private assessmentTypeService: AssessmentTypeService,
    private sodService: SodService,
    private requestTypeService: RequestTypeService,
    private sourceAssessmentService: SourceAssessmentService,
    private assessmentApproachService: AssessmentApproachService,
    private monitoringResultService: MonitoringResultService,
    private belongingObjectService: BelongingObjectService,
    private regionService: RegionService,
    private carBrandService: CarBrandService,
    private useTypeService: UseTypeService,
    private typeDocUseLandService: TypeDocUseLandService,
    private typeDocOwnershipService: TypeDocOwnershipService,
    private equipmentTypeService: EquipmentTypeService,
    private measurementUnitService: MeasurementUnitService,
    private assessmentKindService: AssessmentKindService,
    private assetSubtypeService: AssetSubtypeService,
    private checkupService: CheckupService,
    private controlGroupService: ControlGroupService,
  ) {}

  getAnnulmentItems() {
    return this.annulmentTypeService.getApiAnnulmentsAnnulmentTypes();
  }

  getRoleAnnulmentTypes() {
    return this.roleAnnulmentTypeService.getApiRoleAnnulmentTypes();
  }

  loadConsiderationLevel() {
    return this.consLevelService.getApiConsiderationLevels();
  }

  loadLoanTypes(title?: string) {
    return this.loanTypeService.getApiLoanTypes({ title });
  }

  loadProductList() {
    return this.productService.getApiProducts();
  }

  loadLoanClasses() {
    return this.loanClassService.getApiLoanClasses();
  }

  loadLawCaseTypes() {
    return this.lawCourtService.getApiLawCaseTypes();
  }

  loadBpRoles(roleTitle?: string) {
    return this.bpRolesService.getApiBpRoles({ roleTitle });
  }

  loadBpRoleCourtCases() {
    return this.bpRoleCourtCasesService.getApiBpRoleCourtCases();
  }

  loadProposalTypes(): Observable<ProposalTypeDto[]> {
    return this.proposalTypeService.getApiProposalsProposalTypes();
  }

  loadAssetTypes(): Observable<AssetTypeDto[]> {
    return this.assetTypeService.getApiAssetsAssetTypes();
  }

  loadAssetCategory(): Observable<AssetCategoryDto[]> {
    return this.assetCategoryService.getApiAssetCategories();
  }

  loadCurrencies() {
    return this.currencyService.getApiCurrencies();
  }

  loadErrorCodes(message?: string) {
    return this.errorsService.getApiErrors({ message });
  }

  loadErrorsProcess(errorId?: number, processId?: string) {
    return this.errorsService.getApiErrorsProcesses({ errorId, processId });
  }

  loadUiTabs() {
    return this.uiTabService.getApiTabAccessUiTab();
  }

  loadEmployees() {
    return this.employeeService.getApiEmployees();
  }

  loadRoles() {
    return this.roleService.getApiRoles();
  }

  loadMessageType(text?: string): Observable<any> {
    return this.errorsService.getApiErrorsMessageTypes({ text });
  }

  loadOrgUnits() {
    return this.orgUnitService.getApiOrgUnits();
  }

  loadLocationAkd() {
    return this.locationAkdService.getApiDocumentsLocationAkd();
  }

  loadDocTypes() {
    return this.documentTypeService.getApiDocumentsDocTypes();
  }

  loadAssociatedDocs(applicationType = 1) {
    return this.associatedDocumentService.getApiAssociatedDocs({ applicationId: applicationType });
  }

  loadParticipants() {
    return this.participantService.getApiParticipants();
  }

  loadVotingOptions() {
    return this.votingOptionService.getApiVotingOptions();
  }

  loadReports() {
    return this.reportService.getApiReports();
  }

  loadDictionaryEditor() {
    return this.dictionaryService.getApiDictionaries();
  }

  loadDictionaryEditorById(id: number) {
    return this.dictionaryEditorService.getApiDictionaryEditor1({ id });
  }

  loadDictionaryOrgstructureEditorById(id: number): Observable<DictionaryQueryDTO[]> {
    return this.https.get<DictionaryQueryDTO[]>(
      `${environment.production ? '' : '/proxy'}/api/orgstructure/api/dictionary-editor/${id}`,
    );
  }

  loadDictionaryGroups() {
    return this.dictionaryGroupService.getApiDictionaryGroups();
  }

  loadBankServices(loanType?: number): Observable<BankServiceDTO[]> {
    return this.bankServiceService.getApiBankServices({ loanType });
  }

  loadDictionaryOrgstructure(): Observable<DictionaryDTO[]> {
    return this.https.get<DictionaryDTO[]>(
      `${environment.production ? '' : '/proxy'}/api/orgstructure/api/dictionaries`,
    );
  }

  loadProductCategories(): Observable<ProductCategoryDto[]> {
    return this.productCategoryService.getApiProductCategories();
  }

  loadAssessmentTypes(): Observable<AssessmentTypeDto[]> {
    return this.assessmentTypeService.getApiAssessmentTypes();
  }

  loadSOD(): Observable<SodDto[]> {
    return this.sodService.getApiSods();
  }

  loadRequestTypes(): Observable<RequestTypeDto[]> {
    return this.requestTypeService.getApiRequestTypes();
  }

  loadSourceAssessments(): Observable<SourceAssessmentDto[]> {
    return this.sourceAssessmentService.getApiSourceAssessments();
  }

  loadAssessmentApproaches(): Observable<AssessmentApproachDto[]> {
    return this.assessmentApproachService.getApiAssessmentApproaches();
  }

  loadMonitoringResults(): Observable<MonitoringResultDto[]> {
    return this.monitoringResultService.getApiMonitoringResults();
  }

  loadBelongingObjects(): Observable<BelongingObjectDto[]> {
    return this.belongingObjectService.getApiBelongingObjects();
  }

  loadRegions(): Observable<RegionDto[]> {
    return this.regionService.getApiRegions();
  }

  loadCarBrands(): Observable<CarBrandDto[]> {
    return this.carBrandService.getApiCarBrands();
  }

  loadUseTypes(): Observable<UseTypeDto[]> {
    return this.useTypeService.getApiUseTypes();
  }

  loadTypeDocUseLands(): Observable<TypeDocUseLandDto[]> {
    return this.typeDocUseLandService.getApiTypeDocUseLands();
  }

  loadTypeDocsOwnership(): Observable<TypeDocOwnershipDto[]> {
    return this.typeDocOwnershipService.getApiTypeDocsOwnership();
  }

  loadEquipmentTypes(): Observable<EquipmentTypeDto[]> {
    return this.equipmentTypeService.getApiEquipmentTypes();
  }

  loadMeasurementUnits(): Observable<MeasurementUnitDto[]> {
    return this.measurementUnitService.getApiMeasurementUnits();
  }

  loadAssessmentKinds(): Observable<AssessmentKindDto[]> {
    return this.assessmentKindService.getApiAssessmentKinds();
  }

  loadAssetSubTypes(): Observable<AssetSubtypeDto[]> {
    return this.assetSubtypeService.getApiAssetSubtypes();
  }

  loadCheckups(): Observable<CheckupDto[]> {
    return this.checkupService.getApiCheckups({ ids: [] });
  }

  loadControlGroups(): Observable<ControlGroupDto[]> {
    return this.controlGroupService.getApiControlGroups();
  }
}
