import { Period } from '../index';

export const predefinedPeriods: { name: string; value: Period }[] = [
  {
    name: 'Свій варіант',
    value: 'nothing',
  },
  {
    name: 'Поточний тиждень',
    value: 'currentWeek',
  },
  {
    name: 'Поточний місяць',
    value: 'currentMonth',
  },
  {
    name: 'Поточний квартал',
    value: 'currentQuarter',
  },
  {
    name: 'Поточний рік',
    value: 'currentYear',
  },
  {
    name: 'За останній тиждень',
    value: 'lastWeek',
  },
  {
    name: 'За останній місяць',
    value: 'lastMonth',
  },
  {
    name: 'За останній квартал',
    value: 'lastQuarter',
  },
  {
    name: 'За останні пів року',
    value: 'lastHalfYear',
  },
  {
    name: 'За останній рік',
    value: 'lastYear',
  },
];

export const takePredefinedPeriods = (
  periods: string[],
): {
  name: string;
  value: Period;
}[] => predefinedPeriods.filter((period) => periods.includes(period.value));
