import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllConditions } from '../../+state/conditions/condition.selectors';

@Injectable({
  providedIn: 'root',
})
export class ConditionsFacadeService {
  private store = inject(Store);

  selectAllConditions$ = this.store.select(selectAllConditions);
}
