import { createAction, props } from '@ngrx/store';
import { CourtCasesTypeDto } from '@api/dictionaries';

export const loadLawCaseTypes = createAction('[Dictionaries/API] Load Law Case Types');
export const loadLawCaseTypesSuccess = createAction(
  '[Dictionaries/API] Load Law Case Types Success',
  props<{ lawCaseTypes: CourtCasesTypeDto[] }>(),
);
export const loadLawCaseTypesFailure = createAction(
  '[Dictionaries/API] Load Law Case Types Failure',
  props<{ error: unknown }>(),
);
