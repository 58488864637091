import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadBelongingObjects, selectBelongingObjects } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class BelongingObjectsFacadeService {
  public belongingObjects$ = this.store.select(selectBelongingObjects);
  constructor(private store: Store) {}

  public loadBelongingObjects() {
    this.store.dispatch(loadBelongingObjects());
  }
}
