import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import * as SOD from './sods.action';
import * as Selectors from './sods.selectors';
import { BpShortInfoDto, SapIntegrationService } from '@api/loan-approval';

@Injectable()
export class SODEffects {
  SODS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SOD.loadSOD),
      concatLatestFrom((action) => [this.store.select(Selectors.selectSOD)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadSOD()).pipe(
          map((response) =>
            SOD.loadSODSuccess({
              SOD: response.filter((i) => i.accreditSod),
            }),
          ),
          catchError((error) => of(SOD.loadSODFailure({ error }))),
        ),
      ),
    ),
  );

  SODSNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SOD.loadSODSuccess),
      concatLatestFrom((action) => [this.store.select(Selectors.selectSOD)]),
      switchMap(([action, sodDto]) => {
        const reqArray = sodDto.map((i) =>
          this.sapIntegrationService.getSapBpSearchBpCode({ bpCode: i.id }).pipe(
            map((res) => res),
            catchError((error) => of({} as BpShortInfoDto)),
          ),
        );
        return forkJoin(reqArray).pipe(
          map((res) => {
            // TODO fix typing
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sodDto = res.map((item) => {
              const find = sodDto.find((i) => i.id === item.code);
              return {
                ...find,
                nameWithId: find?.id + (item.name ? '/' + item.name : ''),
              };
            });

            return SOD.loadSODNameSuccess({
              SOD: sodDto[0] ? sodDto : [],
            });
          }),
          catchError((error) => of(SOD.loadSODFailure({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dictionariesService: DictionariesService,
    private sapIntegrationService: SapIntegrationService,
  ) {}
}
