import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as Actions from './promotion.actions';
import { PromotionDto } from '@api/dictionaries';

export const promotionFeatureKey = 'promotion';

export interface State extends EntityState<PromotionDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<PromotionDto> = createEntityAdapter<PromotionDto>({
  selectId: (item) => item.code,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(Actions.loadPromotion, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(Actions.loadPromotionSuccess, (state, { promotion }) => ({
    ...adapter.setAll(promotion, state),
    isLoading: false,
  })),
  on(Actions.loadPromotionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
