/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class PrintedFormService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get bail contract printed form
     * Method for get bail contract printed form.
     * @returns any Successful operation
     * @throws ApiError
     */
    public getPrintedFormsProcessInstanceBailContract({
        bailContractId,
        processInstanceId,
    }: {
        /**
         * Bail contract ID
         */
        bailContractId: string,
        /**
         * Process instance ID
         */
        processInstanceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/printed-forms/process-instance/{processInstanceId}/bail-contract/{bailContractId}',
            path: {
                'bailContractId': bailContractId,
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `The document template was not found`,
            },
        });
    }

    /**
     * Get check-list printed form
     * Method for get check-list printed form.
     * @returns any Successful operation
     * @throws ApiError
     */
    public getPrintedFormsProcessInstanceCheckList({
        processInstanceId,
    }: {
        /**
         * Root process instance ID
         */
        processInstanceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/printed-forms/process-instance/{processInstanceId}/checkList',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `The document template was not found`,
            },
        });
    }

    /**
     * Get genagreement documentary transactions printed form
     * Method for get genagreement documentary transactions printed form.
     * @returns any Successful operation
     * @throws ApiError
     */
    public getPrintedFormsProcessInstanceDocumentaryOperations({
        agreementId,
        processInstanceId,
    }: {
        /**
         * Agreement ID
         */
        agreementId: string,
        /**
         * Process instance ID
         */
        processInstanceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/printed-forms/process-instance/{processInstanceId}/documentary-operations/{agreementId}',
            path: {
                'agreementId': agreementId,
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `The document template was not found`,
            },
        });
    }

    /**
     * Get loan contract printed form
     * Method for get loan contract printed form.
     * @returns any Successful operation
     * @throws ApiError
     */
    public getPrintedFormsProcessInstanceLoanContract({
        agreementId,
        processInstanceId,
    }: {
        /**
         * Agreement ID
         */
        agreementId: string,
        /**
         * Process instance ID
         */
        processInstanceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/printed-forms/process-instance/{processInstanceId}/loan-contract/{agreementId}',
            path: {
                'agreementId': agreementId,
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `The document template was not found`,
            },
        });
    }

    /**
     * Get protocol  printed form
     * Method for get protocol  printed form.
     * @returns any Successful operation
     * @throws ApiError
     */
    public getPrintedFormsProcessInstanceProtocolCc({
        processInstanceId,
    }: {
        /**
         * Process instance ID
         */
        processInstanceId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/printed-forms/process-instance/{processInstanceId}/protocol-cc',
            path: {
                'processInstanceId': processInstanceId,
            },
            errors: {
                404: `The document template was not found`,
            },
        });
    }

}
