import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { AgreementGuaranty } from '@api/loan-approval';

const agreementGuarantyFeatureKey = 'agreementGuaranty';

export type agreementGuarantyState = EntityState<AgreementGuaranty>;

export const adapter = createEntityAdapter<AgreementGuaranty>({
  selectId: (item) => item.cagId,
});

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.agreementGuaranty || [], state)),
);

export const agreementGuarantyFeature = createFeature({
  name: agreementGuarantyFeatureKey,
  reducer,
  extraSelectors: ({ selectAgreementGuarantyState }) => ({
    ...adapter.getSelectors(selectAgreementGuarantyState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = agreementGuarantyFeature;
