import { createAction, props } from '@ngrx/store';
import { CurrencyDto } from '@api/dictionaries';

export const loadCurrencies = createAction('[Dictionaries/API] Load Currencies');
export const loadCurrenciesSuccess = createAction(
  '[Dictionaries/API] Load Currencies Success',
  props<{ currencies: CurrencyDto[] }>(),
);
export const loadCurrenciesFailure = createAction(
  '[Dictionaries/API] Load Currencies Failure',
  props<{ error: unknown }>(),
);
