import { inject, Injectable } from '@angular/core';
import { AbstractStoreService } from './abstract-store.service';
import {
  AgreementsFacadeService,
  AssetAgreementRelationFacadeService,
  AssetsFacadeService,
  BorrowerGroupFacade,
  deleteTaskV2ProcessVariable,
  DocumentEntryListFacadeService,
  setTaskV2ProcessVariable,
  TaskDetailsFacadeService,
  VariablesFacadeService,
} from '@sib/task/shared/store';
import { combineLatest, concatMap, defer, filter, forkJoin, from, of, switchMap, take } from 'rxjs';
import * as fromShared from '@sib/shared/store';
import { AuthFacadeService } from '@sib/shared/store';
import { NestedAssociatedTypeDoc } from '@api/dictionaries';
import { b64toBlob, getUniqueUuid, onGetDate } from '@sib/shared/util';
import { first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  AksIntegrationService,
  AssociatedDocumentService,
  DocumentEntry,
  FileStoreService,
  OpenAPI,
} from '@api/loan-approval';
import { concatLatestFrom } from '@ngrx/effects';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends AbstractStoreService {
  private readonly taskDetailsFacadeService = inject(TaskDetailsFacadeService);
  private readonly variablesFacadeService = inject(VariablesFacadeService);
  private readonly agreementsFacadeService = inject(AgreementsFacadeService);
  private readonly assetsFacadeService = inject(AssetsFacadeService);
  private readonly assetAgreementRelationFacadeService = inject(AssetAgreementRelationFacadeService);
  private readonly borrowerGroupFacade = inject(BorrowerGroupFacade);
  private readonly documentEntryListFacadeService = inject(DocumentEntryListFacadeService);
  private readonly https = inject(HttpClient);
  private readonly fileStoreService = inject(FileStoreService);
  private readonly authFacadeService = inject(AuthFacadeService);
  private readonly aksIntegrationService = inject(AksIntegrationService);
  private readonly associatedDocumentService = inject(AssociatedDocumentService);

  addDocuments(typeDoc: (NestedAssociatedTypeDoc & { documentGroupCode: string })[]) {
    return combineLatest([
      this.agreementsFacadeService.selectActiveAgreement$,
      this.assetAgreementRelationFacadeService.selectAssetAgreementRelationByActiveAgreement$,
      this.borrowerGroupFacade.selectAllBorrowerGroups$,
      this.assetsFacadeService.selectAllAssets$,
      this.variablesFacadeService.selectRequestNumber$,
      this.store.select(fromShared.selectAllDocTypes).pipe(filter(Boolean)),
      this.taskDetailsFacadeService.selectProcessDefinitionKey$,
      this.taskDetailsFacadeService.selectProcessInstanceId$,
      this.store.select(fromShared.selectAllLocationAkd),
    ]).pipe(
      take(1),
      switchMap(
        ([
          activeAgreement,
          assetAgreementRelation,
          borrowerGroups,
          assets,
          requestNumber,
          allDocTypes,
          processDefinitionKey,
          processInstanceId,
          locationAkd,
        ]) => {
          const newDocuments = typeDoc.map((item) => {
            const akdFolderId = allDocTypes.find((i) => i.docType === item.documentTypeCode)?.akdFolderId;
            const akdFolder = locationAkd.find((i) => i.id === akdFolderId)?.codeFolder || '';

            const bindingCode =
              processDefinitionKey === 'subprocess_agreement_execution' &&
              (!akdFolder || akdFolder === 'AKD_CREDIT') &&
              !!activeAgreement
                ? activeAgreement.id
                : processDefinitionKey === 'subprocess_agreement_execution' && akdFolder === 'AKD_PROPERTY'
                ? assetAgreementRelation[0].astId
                : processDefinitionKey === 'subprocess_agreement_execution' && akdFolder === 'AKD_PARTNER_FILE'
                ? borrowerGroups[0].buPartner
                : processDefinitionKey === 'subprocess_property_assessment'
                ? assets[0].id
                : undefined;

            return {
              requestNumber: Number(requestNumber),
              documentId: getUniqueUuid(),
              documentGroup: item.documentGroupCode,
              documentType: item.documentTypeCode,
              documentECP: false,
              akdFolder,
              locked: false,
              lockedProcessID:
                processDefinitionKey === 'subprocess_property_assessment' ? processInstanceId : undefined,
              bindingCode,
              digitalSignatures: [],
              forAllDeals: false,
              aksUploadData: [],
            } as DocumentEntry;
          });

          this.store.dispatch(
            setTaskV2ProcessVariable({
              requestBody: {
                documentEntryList: newDocuments,
              },
            }),
          );

          return this.waitForResponse(undefined);
        },
      ),
    );
  }

  updateComment(documentId: string, comment: string) {
    return this.documentEntryListFacadeService.selectDocumentEntryById$(documentId).pipe(
      first(Boolean),
      switchMap((documentEntry) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              documentEntryList: [{ ...documentEntry, comment: comment }],
            },
          }),
        );

        return this.waitForResponse(undefined);
      }),
    );
  }

  updateBinding(documentId: string, bindingCode: string, forAllDeals: boolean) {
    return this.documentEntryListFacadeService.selectDocumentEntryById$(documentId).pipe(
      first(Boolean),
      switchMap((documentEntry) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              documentEntryList: [{ ...documentEntry, bindingCode, forAllDeals }],
            },
          }),
        );

        return this.waitForResponse(undefined);
      }),
    );
  }

  updateBindingByBindingCode(currentBindingCode: string, bindingCode: string) {
    return this.documentEntryListFacadeService.getAllDocumentEntryList$.pipe(
      first(Boolean),
      switchMap((documentEntries) => {
        const updatedDocuments = documentEntries
          .filter((item) => item.bindingCode === currentBindingCode)
          .map(
            (item) =>
              ({
                ...item,
                bindingCode: bindingCode,
              } as DocumentEntry),
          );

        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              documentEntryList: updatedDocuments,
            },
          }),
        );

        return this.waitForResponse(updatedDocuments);
      }),
    );
  }

  updateLockDocument(documentId: string, locked: boolean) {
    return this.documentEntryListFacadeService.selectDocumentEntryById$(documentId).pipe(
      first(Boolean),
      switchMap((documentEntry) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              documentEntryList: [{ ...documentEntry, locked }],
            },
          }),
        );

        return this.waitForResponse(undefined);
      }),
    );
  }

  downloadFile(documentId: string) {
    return this.download(documentId);
  }

  downloadDigitalSignatories(digitalSignatures: { digitalSignatureId?: string }[]) {
    return forkJoin(digitalSignatures?.map((item) => this.download(item.digitalSignatureId || '')));
  }

  deleteFile(id: string) {
    return this.taskDetailsFacadeService.selectProcessInstanceId$.pipe(
      first(),
      switchMap((processInstanceId) => this.fileStoreService.deleteFileStoreProcess({ id, processInstanceId })),
      switchMap(() => this.taskDetailsFacadeService.selectTaskId$),
      first(),
      switchMap((taskId) => this.variablesFacadeService.getTaskVariable(taskId)),
    );
  }

  deleteFiles(ids: string[]) {
    return from(ids).pipe(concatMap((id) => this.deleteFile(id)));
  }

  deleteDocumentEntry(id: string) {
    return this.documentEntryListFacadeService.selectDocumentEntryById$(id).pipe(
      first(Boolean),
      switchMap((documentEntry) =>
        documentEntry.fileAddingDate ? this.deleteFile(id).pipe(map(() => documentEntry)) : of(documentEntry),
      ),
      switchMap((documentEntry) => {
        this.store.dispatch(
          deleteTaskV2ProcessVariable({
            requestBody: {
              documentEntryList: [documentEntry],
            },
          }),
        );
        return this.waitForResponse(id);
      }),
    );
  }

  deleteDocumentEntries(ids: string[]) {
    return from(ids).pipe(concatMap((id) => this.deleteDocumentEntry(id)));
  }

  uploadFile(file: {
    documentId: string;
    bindingCode: string;
    forAllDeals: boolean;
    isFileReplaced: boolean;
    akdFolder?: string;
    base64: string;
    fileName: string;
    type: string;
  }) {
    return combineLatest([
      this.documentEntryListFacadeService.selectDocumentEntryById$(file.documentId).pipe(first(Boolean)),
      this.taskDetailsFacadeService.selectProcessDefinitionKey$.pipe(first(Boolean)),
      this.agreementsFacadeService.selectActiveAgreement$.pipe(first(Boolean)),
      this.variablesFacadeService.selectRequestNumber$,
      this.variablesFacadeService.selectApplicationType$,
      this.authFacadeService.selectLogin$.pipe(first(Boolean)),
      this.taskDetailsFacadeService.selectProcessInstanceId$,
    ]).pipe(
      switchMap(
        ([
          documentEntry,
          processDefinitionKey,
          agreement,
          requestNumber,
          applicationType,
          userLogin,
          processInstanceId,
        ]) => {
          let bindingCode = '';
          if (file.bindingCode) {
            bindingCode = file.bindingCode;
          } else if (processDefinitionKey === 'subprocess_agreement_execution' && documentEntry.akdFolder) {
            bindingCode = agreement.id;
          }

          const fileObj = {
            id: file.documentId,
            procType: applicationType,
            applicationId: requestNumber,
            content: b64toBlob(file.base64),
            fileName: file.fileName.toLowerCase(),
            loginAddedBy: userLogin,
            mimeType: file.type,
            bindingCode,
            forAllDeals: file.forAllDeals || false,
            uploadDate: format(new Date(), 'dd-MM-yyyy HH:mm'),
          };

          return defer(() =>
            file.isFileReplaced
              ? this.fileStoreService.putFileStoreProcess({
                  processInstanceId,
                  formData: fileObj,
                })
              : this.fileStoreService.postFileStoreProcess({
                  processInstanceId,
                  formData: fileObj,
                }),
          ).pipe(
            switchMap(() => this.taskDetailsFacadeService.selectTaskId$),
            first(),
            switchMap((taskId) => this.variablesFacadeService.getTaskVariable(taskId)),
            map(() => fileObj),
          );
        },
      ),
    );
  }

  uploadDigitalSignatory(data: { documentId: string; content: Blob; filename: string }) {
    const date = onGetDate();
    const customFormat = format(new Date(date), 'dd-MM-yyyy HH:mm');
    const digitalSignatureId = getUniqueUuid();
    return combineLatest([
      this.variablesFacadeService.selectRequestNumber$,
      this.variablesFacadeService.selectApplicationType$,
      this.authFacadeService.selectLogin$,
      this.authFacadeService.selectId$,
    ]).pipe(
      first(),
      switchMap(([requestNumber, applicationType, userLogin, userId]) => {
        const fileName = `${data.filename.slice(0, data.filename.indexOf('.'))}(${userId}).p7s`;
        return this.fileStoreService
          .postFileStore({
            formData: {
              id: digitalSignatureId,
              procType: applicationType,
              applicationId: requestNumber,
              content: data.content,
              fileName: fileName,
              loginAddedBy: userLogin,
              mimeType: 'application/pkcs7-signature',
              uploadDate: customFormat,
            },
          })
          .pipe(
            map(() => ({
              digitalSignatureId: digitalSignatureId,
              edsFileName: fileName,
              addedTime: date,
              edsPersonalNo: userId,
            })),
          );
      }),
      concatLatestFrom(() =>
        this.documentEntryListFacadeService.selectDocumentEntryById$(data.documentId).pipe(filter(Boolean)),
      ),
      switchMap(([digitalSignatory, documentEntry]) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              documentEntryList: [
                {
                  ...documentEntry,
                  digitalSignatures: [...(documentEntry.digitalSignatures || []), digitalSignatory],
                },
              ],
            },
          }),
        );

        return this.waitForResponse(undefined);
      }),
    );
  }

  sendDocumentsToAKS() {
    return this.taskDetailsFacadeService.selectProcessInstanceId$.pipe(
      first(Boolean),
      switchMap((processInstanceId) =>
        this.aksIntegrationService.postAksDocuments({ processInstanceId: processInstanceId }),
      ),
      switchMap(() => this.taskDetailsFacadeService.selectTaskId$),
      first(),
      switchMap((taskId) => this.variablesFacadeService.getTaskVariable(taskId)),
    );
  }

  loadAssociatedDocs() {
    return this.taskDetailsFacadeService.selectProcessInstanceId$.pipe(
      first(Boolean),
      switchMap((processInstanceId) =>
        this.associatedDocumentService.getAssociatedDocsProcessId({
          processId: processInstanceId,
        }),
      ),
    );
  }

  private download(id: string) {
    return this.https.get(`${OpenAPI.BASE}/file-store/${id}`, {
      responseType: 'blob',
    });
  }
}
