import { inject } from '@angular/core';
import { TaskDetailsFacadeService } from './task-details.facade.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TaskV2FacadeService } from '../../denormalized';

export const taskDetailsResolver = (route: ActivatedRouteSnapshot) =>
  inject(TaskDetailsFacadeService).getTaskDetails(route.paramMap.get('taskId') || '');

export const historicTaskDetailsResolver = (route: ActivatedRouteSnapshot) =>
  inject(TaskDetailsFacadeService).getHistoricTaskDetails(route.paramMap.get('processInstanceId') || '');

export const taskDetailsDeactivate = () => {
  inject(TaskV2FacadeService).resetVariables();
  return true;
};
