import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Guarantor } from '@api/loan-approval';

const guarantorFeatureKey = 'guarantor';

export type GuarantorState = EntityState<Guarantor>;

export const adapter = createEntityAdapter<Guarantor>({ selectId: (item) => item.bpCode });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.guarantor || [], state)),
);

export const guarantorFeature = createFeature({
  name: guarantorFeatureKey,
  reducer,
  extraSelectors: ({ selectGuarantorState }) => ({
    ...adapter.getSelectors(selectGuarantorState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = guarantorFeature;
