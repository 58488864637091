import { createReducer, on } from '@ngrx/store';
import * as TypeDocUseLands from './type-doc-use-lands.actions';
import { TypeDocUseLandDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const typeDocUseLandsFeatureKey = 'type-doc-use-lands';

export interface State extends EntityState<TypeDocUseLandDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<TypeDocUseLandDto>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(TypeDocUseLands.loadTypeDocUseLands, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TypeDocUseLands.loadTypeDocUseLandsSuccess, (state, { typeDocUseLands }) => ({
    ...adapter.setAll(typeDocUseLands, state),
    isLoading: false,
  })),
  on(TypeDocUseLands.loadTypeDocUseLandsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectTypeDocUseLandsState = selectAll;
