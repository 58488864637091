/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CarBrandDto } from '../models/CarBrandDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CarBrandService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all car brands
     * Returns all car brands.
     * @returns CarBrandDto all car brands
     * @throws ApiError
     */
    public getApiCarBrands(): Observable<Array<CarBrandDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/car-brands',
        });
    }

    /**
     * Create a car brand
     * Method for creating a car brand.
     * @returns CarBrandDto car brand created
     * @throws ApiError
     */
    public postApiCarBrands({
        requestBody,
    }: {
        /**
         * Car brand object that needs to be created.
         */
        requestBody: CarBrandDto,
    }): Observable<CarBrandDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/car-brands',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get car brand
     * Get car brand by ID.
     * @returns any car brand found
     * @throws ApiError
     */
    public getApiCarBrands1({
        id,
    }: {
        /**
         * Car brand ID.  Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        brand?: string;
        originCountryCode?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/car-brands/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `car brand not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update a car brand
     * Updates the car brand with the given ID.
     * @returns any car brand updated
     * @throws ApiError
     */
    public putApiCarBrands({
        id,
        requestBody,
    }: {
        /**
         * Car brand ID.  Cannot be empty.
         */
        id: string,
        /**
         * Car brand object that needs to be updated.
         */
        requestBody: CarBrandDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/car-brands/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete car brand
     * Deletes the car brand with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiCarBrands({
        id,
    }: {
        /**
         * Car brand ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/car-brands/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
