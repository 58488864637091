import { Component, OnInit } from '@angular/core';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { AsyncPipe } from '@angular/common';
import { PageHeadlineComponent } from '@sib/shared/ui';

@Component({
  selector: 'sib-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, AsyncPipe, PageHeadlineComponent],
})
export class ArchiveComponent implements OnInit {
  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@Archive', 'Архів');
  }

  openBorrowerSearchSapDialog(): void {}
}
