/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssessmentTypeDto } from '../models/AssessmentTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssessmentTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all assessment types
     * Returns all assessment types.
     * @returns AssessmentTypeDto all assessment types
     * @throws ApiError
     */
    public getApiAssessmentTypes(): Observable<Array<AssessmentTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assessment-types',
        });
    }

    /**
     * Create an assessment type
     * Method for creating an assessment type.
     * @returns AssessmentTypeDto assessment type created
     * @throws ApiError
     */
    public postApiAssessmentTypes({
        requestBody,
    }: {
        /**
         * Assessment type object that needs to be created.
         */
        requestBody: AssessmentTypeDto,
    }): Observable<AssessmentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/assessment-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get assessment type
     * Get assessment type by ID.
     * @returns any assessment type found
     * @throws ApiError
     */
    public getApiAssessmentTypes1({
        id,
    }: {
        /**
         * Assessment type ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assessment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `assessment type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update an assessment type
     * Updates the assessment type with the given ID.
     * @returns any assessment type updated
     * @throws ApiError
     */
    public putApiAssessmentTypes({
        id,
        requestBody,
    }: {
        /**
         * Assessment type ID. Cannot be empty.
         */
        id: string,
        /**
         * Assessment type object that needs to be updated.
         */
        requestBody: AssessmentTypeDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/assessment-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete assessment type
     * Deletes the assessment type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiAssessmentTypes({
        id,
    }: {
        /**
         * Assessment type ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/assessment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
