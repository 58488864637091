import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectConditionTypes, selectConditionTypesEntities } from './condition-types.selectors';
import { loadConditionTypes } from './condition-types.actions';

@Injectable({
  providedIn: 'root',
})
export class ConditionTypesFacadeService {
  public conditionTypes$ = this.store.select(selectConditionTypes);
  public conditionTypesEntities$ = this.store.select(selectConditionTypesEntities);

  constructor(private store: Store) {}

  public loadConditionTypes() {
    this.store.dispatch(loadConditionTypes());
  }
}
