import { createSelector } from '@ngrx/store';
import * as fromDictionaries from './dictionaries.reducer';
import { convertArrayToObjectTyped } from '@sib/shared/util';
import { selectDictionariesState } from '../+shell/reducers.index';
import { selectRouterParam } from '../../router-state/router.selectors';

export const selectActiveDictionaryId = selectRouterParam('id');

export const selectDictionariesIsLoading = createSelector(selectDictionariesState, fromDictionaries.selectIsLoading);

export const selectAllDictionaries = createSelector(selectDictionariesState, fromDictionaries.selectDictionaries);

export const selectDictionariesEntities = createSelector(selectAllDictionaries, (allDictionaries) =>
  convertArrayToObjectTyped(allDictionaries, 'id'),
);

export const selectDictionaryById = createSelector(
  selectDictionariesEntities,
  selectActiveDictionaryId,
  (dictionariesEntities, id: string) => dictionariesEntities[id],
);

export const selectAllDictionariesOrgstructure = createSelector(
  selectDictionariesState,
  fromDictionaries.selectDictionariesOrgstructure,
);

export const selectDictionariesOrgstructureEntities = createSelector(
  selectAllDictionariesOrgstructure,
  (allDictionariesOrgstructure) => convertArrayToObjectTyped(allDictionariesOrgstructure, 'id'),
);

export const selectDictionaryOrgstructureById = createSelector(
  selectDictionariesOrgstructureEntities,
  selectActiveDictionaryId,
  (dictionariesEntities: any, id: any) => dictionariesEntities[id],
);
