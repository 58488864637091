import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as BpRoleCourtCasesActions from './bp-role-court-cases.actions';
import * as BpRoleCourtCasesSelectors from './bp-role-court-cases.selectors';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class BpRoleCourtCasesEffects {
  loadBpRoleCourtCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BpRoleCourtCasesActions.loadBpRoleCourtCases),
      concatLatestFrom((action) => [this.store$.select(BpRoleCourtCasesSelectors.selectAllBpRoleCourtCases)]),
      switchMap(([action, allBpRoleCourtCases]) =>
        (allBpRoleCourtCases.length ? of(allBpRoleCourtCases) : this.dictionariesService.loadBpRoleCourtCases()).pipe(
          map((response) =>
            BpRoleCourtCasesActions.loadBpRoleCourtCasesSuccess({
              bpRoleCourtCases: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(BpRoleCourtCasesActions.loadBpRoleCourtCasesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
