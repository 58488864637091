import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { ErrMessage } from '@api/loan-approval';

const requestErrorsFeatureKey = 'requestErrors';

export type RequestErrorsState = EntityState<ErrMessage>;

export const adapter = createEntityAdapter<ErrMessage>({ selectId: (item) => item.errMsgId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.requestErrors || [], state)),
);

export const requestErrorsFeature = createFeature({
  name: requestErrorsFeatureKey,
  reducer,
  extraSelectors: ({ selectRequestErrorsState }) => ({
    ...adapter.getSelectors(selectRequestErrorsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = requestErrorsFeature;
