import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as UseTypes from './use-types.actions';
import * as Selectors from './use-types.selectors';
import { UseTypeService } from '@api/dictionaries';

@Injectable()
export class UseTypesEffects {
  private useTypeService = inject(UseTypeService);

  useTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UseTypes.loadUseTypes),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectUseTypes)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.useTypeService.getApiUseTypes()).pipe(
          map((response) =>
            UseTypes.loadUseTypesSuccess({
              useTypes: response,
            }),
          ),
          catchError((error) => of(UseTypes.loadUseTypesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store) {}
}
