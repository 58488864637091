import { createSelector } from '@ngrx/store';
import * as fromDictionaryGroups from './dictionary-groups.reducer';
import { selectAllDictionaries, selectAllDictionariesOrgstructure } from '../dictionaries/dictionaries.selectors';
import { selectDictionaryGroupsState } from '../+shell/reducers.index';

export const selectIsLoadingDictionaryGroups = createSelector(
  selectDictionaryGroupsState,
  fromDictionaryGroups.selectIsLoading,
);

export const selectDictionaryGroups = createSelector(
  selectDictionaryGroupsState,
  fromDictionaryGroups.selectDictionaryGroups,
);

export const selectDictionaryGroupsForRender = createSelector(
  selectDictionaryGroups,
  selectAllDictionaries,
  (dictionaryGroups, dictionaries) => {
    const renderArr = dictionaryGroups.map((item: any) => {
      const groups = dictionaries.filter((i) => i.dictionaryGroupId === item.id);
      let showGroupSynchronization = false;
      if (groups.findIndex((i) => i.maintenanceMode === 'G') !== -1) {
        showGroupSynchronization = true;
      }
      return { ...item, opened: false, showGroupSynchronization, groups };
    });
    return renderArr;
  },
);

export const selectDictionaryOgrgstructureForRender = createSelector(
  selectDictionaryGroups,
  selectAllDictionariesOrgstructure,
  (dictionaryGroups, dictionariesOrgstructure) => {
    const renderArr: any[] = [];
    dictionaryGroups.forEach((item: any) => {
      const groups = dictionariesOrgstructure.filter((i) => i.dictionaryGroupId === item.id);
      let showGroupSynchronization = false;
      if (groups.findIndex((i) => i.maintenanceMode === 'G') !== -1) {
        showGroupSynchronization = true;
      }
      if (groups?.length > 0) {
        renderArr.push({ ...item, opened: false, showGroupSynchronization, groups });
      }
    });
    return renderArr;
  },
);
