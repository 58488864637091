/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SourceAssessmentDto } from '../models/SourceAssessmentDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SourceAssessmentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all source assessments
     * Returns all source assessments.
     * @returns SourceAssessmentDto all source assessments
     * @throws ApiError
     */
    public getApiSourceAssessments(): Observable<Array<SourceAssessmentDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/source-assessments',
        });
    }

    /**
     * Create source assessment
     * Method for creating source assessment.
     * @returns SourceAssessmentDto source assessment created
     * @throws ApiError
     */
    public postApiSourceAssessments({
        requestBody,
    }: {
        /**
         * Source assessment object that needs to be created.
         */
        requestBody: SourceAssessmentDto,
    }): Observable<SourceAssessmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/source-assessments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get source assessment
     * Get source assessment by ID.
     * @returns any source assessment found
     * @throws ApiError
     */
    public getApiSourceAssessments1({
        id,
    }: {
        /**
         * Source assessment ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        descr: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/source-assessments/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `source assessment not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update source assessment
     * Updates the source assessment with the given ID.
     * @returns any Source assessment updated
     * @throws ApiError
     */
    public putApiSourceAssessments({
        id,
        requestBody,
    }: {
        /**
         * Source assessment ID. Cannot be empty.
         */
        id: string,
        /**
         * Source assessment object that needs to be updated.
         */
        requestBody: SourceAssessmentDto,
    }): Observable<{
        id: string;
        descr: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/source-assessments/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete source assessment
     * Deletes the source assessment with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiSourceAssessments({
        id,
    }: {
        /**
         * Source assessment ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/source-assessments/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
