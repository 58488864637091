import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Realty } from '@api/loan-approval';

const realtyFeatureKey = 'realty';

export type RealtyState = EntityState<Realty>;

export const adapter = createEntityAdapter<Realty>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.realty || [], state)),
);

export const realtyFeature = createFeature({
  name: realtyFeatureKey,
  reducer,
  extraSelectors: ({ selectRealtyState }) => ({
    ...adapter.getSelectors(selectRealtyState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = realtyFeature;
