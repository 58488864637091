/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { TaskCountDto } from '../models/TaskCountDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TaskService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get number of assessment's task
     * Returns response with number of tasks
     * @returns TaskCountDto Assessment Tasks Count data was successfully returned
     * @throws ApiError
     */
    public getTaskAssessmentCount(): Observable<TaskCountDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/assessment/count',
            errors: {
                404: `Specified process instance not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get number of task
     * Returns response with number of task
     * @returns TaskCountDto Task Count data was successfully returned
     * @throws ApiError
     */
    public getTaskCount(): Observable<TaskCountDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/task/count',
            errors: {
                404: `Specified process instance not found`,
                500: `server error`,
            },
        });
    }

}
