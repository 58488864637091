import { createAction, props } from '@ngrx/store';
import { TypeDocOwnershipDto } from '@api/dictionaries';

export const loadTypeDocsOwnership = createAction('[Dictionaries/API] Load Type Docs Ownership');
export const loadTypeDocsOwnershipSuccess = createAction(
  '[Dictionaries/API] Load Type Docs Ownership Success',
  props<{ typeDocsOwnership: TypeDocOwnershipDto[] }>(),
);
export const loadTypeDocsOwnershipFailure = createAction(
  '[Dictionaries/API] Load Type Docs Ownership Failure',
  props<{ error: unknown }>(),
);
