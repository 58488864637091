import { createAction, props } from '@ngrx/store';
import { VotingOptionDto } from '@api/dictionaries';

export const loadVotingOptions = createAction('[Dictionaries/API] Load Voting Options');
export const loadVotingOptionsSuccess = createAction(
  '[Dictionaries/API]  Load Voting Options Success',
  props<{ votingOptions: VotingOptionDto[] }>(),
);
export const loadVotingOptionsFailure = createAction(
  '[Dictionaries/API]  Load Voting Options Failure',
  props<{ error: unknown }>(),
);
