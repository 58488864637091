import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PartnerAccount, SapIntegrationService } from '@api/loan-approval';
import { Observable } from 'rxjs';
import { CurrencyInputViewComponent } from '@sib/shared/ui';
import { LoanGrantingProcedureGroupService } from './loan-granting-procedure-group.service';
import { CurrencyMaskDirective } from 'ngx-currency';

@Component({
  selector: 'sib-loan-granting-procedure-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    ReactiveFormsModule,

    MatCheckboxModule,
    MatDividerModule,
    MatOptionModule,
    MatSelectModule,
    CurrencyInputViewComponent,
    CurrencyMaskDirective,
  ],
  templateUrl: './loan-granting-procedure-form.component.html',
  styleUrls: ['./loan-granting-procedure-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class LoanGrantingProcedureFormComponent implements OnInit {
  @Input()
  public bpCode!: number | null;

  public account$!: Observable<PartnerAccount[]>;

  constructor(
    public sapIntegrationService: SapIntegrationService,
    public formGroup: LoanGrantingProcedureGroupService,
  ) {}

  public ngOnInit() {
    this.account$ = this.sapIntegrationService.getSapGetPartnerAccount({
      bpCode: String(this.bpCode),
    });
  }
}
