import { createSelector } from '@ngrx/store';
import * as fromLoanTypes from './loan-types.reducer';
import { selectLoanTypesState } from '../+shell/reducers.index';

export const selectLoanTypesListIsLoading = createSelector(selectLoanTypesState, fromLoanTypes.selectIsLoading);

export const selectLoanTypesListEntities = createSelector(selectLoanTypesState, fromLoanTypes.selectEntities);

export const selectLoanTypeById = (id: string) =>
  createSelector(selectLoanTypesListEntities, (loanTypesListEntities) => loanTypesListEntities[id]);

export const selectAllLoanTypesList = createSelector(selectLoanTypesState, fromLoanTypes.selectAll);
