import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BpHoldersGroupService } from './bp-holders-group.service';
import { ApiError, GuarantorService, SapIntegrationService, Signator } from '@api/loan-approval';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { catchError } from 'rxjs/operators';
import { formatDateWithPoint, markForCheck, SapGeneralError } from '@sib/shared/util';
import { filter, of, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HOLDERS_CONTRACT_CONFIG, HoldersContractConfig } from './token';

@UntilDestroy()
@Component({
  selector: 'sib-holders-bp-contract-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
  ],
  templateUrl: './holders-bp-contract-form.component.html',
  styleUrls: ['./holders-bp-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class HoldersBpContractFormComponent {
  public displayedColumns = ['radio', 'num', 'date', 'notaryCirc', 'notaryName'];
  public dataSource = new MatTableDataSource<Signator>();

  constructor(
    public bpHoldersGroupService: BpHoldersGroupService,
    private guarantorService: GuarantorService,
    private cdr: ChangeDetectorRef,
    private sapIntegrationService: SapIntegrationService,
    @Inject(HOLDERS_CONTRACT_CONFIG) public holdersContractConfig: HoldersContractConfig,
  ) {
    this.bpHoldersGroupService.setSignatory$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((signatory) => {
      this.getSignatories(this.bpHoldersGroupService.controls.bpNumber.value, signatory);
    });
  }

  public getGuarantor() {
    if (this.bpHoldersGroupService.controls.bpNumber.value) {
      this.guarantorService
        .getSapGuarantor({
          bpCode: this.bpHoldersGroupService.controls.bpNumber.value,
        })
        .pipe(
          catchError((error: ApiError) => {
            if (error.body instanceof SapGeneralError) {
              this.bpHoldersGroupService.controls.bpNumber.setErrors({ verifyGuarantor: error.body.errorMessage });
              return of(undefined);
            }
            return throwError(() => error);
          }),
          markForCheck(this.cdr),
          filter(Boolean),
        )
        .subscribe({
          next: (result) => {
            this.bpHoldersGroupService.patchValue({ partnerName: result.orgName });
          },
        });

      this.getSignatories(this.bpHoldersGroupService.controls.bpNumber.value);
    }
  }

  private getSignatories(bpCode: string, signatory?: Signator) {
    if (!bpCode) return;
    this.sapIntegrationService
      .postSapGetClientPartnerSet({
        bpCode,
        requestBody: { params: {} },
      })
      .pipe(
        catchError((error: ApiError) =>
          error.body instanceof SapGeneralError ? of({ results: [] }) : throwError(() => error),
        ),
      )
      .subscribe(({ results }) => {
        this.dataSource.data =
          results?.map((item) => ({
            num: item.num!,
            date: item.date ? formatDateWithPoint(new Date(item.date)) : '',
            notaryCirc: item.notaryCirc || '',
            notaryName: item.notaryName || '',
          })) || [];

        if (signatory) {
          this.bpHoldersGroupService.patchValue({
            signatory: this.dataSource.data.find((item) => item.num === signatory.num),
          });
        }
      });
  }
}
