import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { iconAccountGroup, iconAccountMultipleCheck, MatIconsRegistry } from '@sib/shared/ui-icons';

export type HeadlineNavigation = { title: string; icon: 'arrow' | 'group' | 'multiCheck'; route: string }[];

@Component({
  selector: 'sib-page-headline',
  standalone: true,
  imports: [CommonModule, BreadcrumbModule, RouterLinkActive, RouterLink, MatIconModule],
  templateUrl: './page-headline.component.html',
  styleUrls: ['./page-headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeadlineComponent {
  private matIconsRegistry = inject(MatIconsRegistry);

  @Input({ required: true })
  public title = '';

  @Input()
  public navigation: HeadlineNavigation = [];

  constructor() {
    this.matIconsRegistry.add(iconAccountGroup, iconAccountMultipleCheck);
  }
}
