/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ScopeDto } from '../models/ScopeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScopeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all scopes
     * Returns all scopes.
     * @returns ScopeDto List of scopes or empty list
     * @throws ApiError
     */
    public getApiScopes(): Observable<Array<ScopeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scopes',
        });
    }

    /**
     * Add scope
     * Creates a new scope object.
     * @returns ScopeDto Scope created successfully
     * @throws ApiError
     */
    public postApiScopes({
        requestBody,
    }: {
        /**
         * Scope data.
         */
        requestBody: ScopeDto,
    }): Observable<ScopeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scopes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Scope with such scope code already exists`,
            },
        });
    }

    /**
     * Get scope by scope code
     * Returns the scope for the specified scope code.
     * @returns ScopeDto Scope exist
     * @throws ApiError
     */
    public getApiScopes1({
        scopeCode,
    }: {
        /**
         * The scope code. Cannot be empty.
         */
        scopeCode: string,
    }): Observable<ScopeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scopes/{scopeCode}',
            path: {
                'scopeCode': scopeCode,
            },
            errors: {
                404: `Scope with such id not found`,
            },
        });
    }

    /**
     * Update scope
     * Updates the scope with the specified scope code.
     * @returns ScopeDto Scope updated
     * @throws ApiError
     */
    public putApiScopes({
        scopeCode,
        requestBody,
    }: {
        /**
         * The scope code. Cannot be empty.
         */
        scopeCode: string,
        /**
         * Scope data.
         */
        requestBody: ScopeDto,
    }): Observable<ScopeDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/scopes/{scopeCode}',
            path: {
                'scopeCode': scopeCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Scope DTO has wrong scope code`,
                404: `Scope not found`,
            },
        });
    }

    /**
     * Delete scope by scope code.
     * Deletes the scope object with the specified scope code.
     * @returns void
     * @throws ApiError
     */
    public deleteApiScopes({
        scopeCode,
    }: {
        /**
         * The scope code. Cannot be empty.
         */
        scopeCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scopes/{scopeCode}',
            path: {
                'scopeCode': scopeCode,
            },
        });
    }

}
