/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DictionaryDTO } from '../models/DictionaryDTO';
import type { DictionaryQueryDTO } from '../models/DictionaryQueryDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DictionaryService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all dictionaries
     * Retrieve a list of all dictionaries
     * @returns DictionaryDTO Returns a list of dictionaries or empty list
     * @throws ApiError
     */
    public getApiDictionaries(): Observable<Array<DictionaryDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionaries',
        });
    }

    /**
     * Create a new dictionary
     * Create a new dictionary from the provided DTO
     * @returns DictionaryDTO Creates a new dictionary
     * @throws ApiError
     */
    public postApiDictionaries({
        requestBody,
    }: {
        /**
         * Dictionary object that needs to be added.
         */
        requestBody: DictionaryDTO,
    }): Observable<DictionaryDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/dictionaries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get migration files
     * Retrieve a zip file containing migration files for specified IDs
     * @returns any Returns a zip file of migration files
     * @throws ApiError
     */
    public getApiDictionariesMigrationFiles({
        ids,
    }: {
        /**
         * IDs of dictionaries to include in migration files. Cannot be empty.
         */
        ids: Array<number>,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionaries/migration-files',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * Get a dictionary by ID
     * Retrieve a specific dictionary by ID
     * @returns DictionaryDTO Returns a specific dictionary
     * @throws ApiError
     */
    public getApiDictionaries1({
        id,
    }: {
        /**
         * ID of the dictionary to be obtained. Cannot be empty.
         */
        id: number,
    }): Observable<DictionaryDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionaries/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Dictionary not found`,
            },
        });
    }

    /**
     * Update a dictionary by ID
     * Updates a specific dictionary by ID
     * @returns DictionaryDTO Dictionary updated
     * @throws ApiError
     */
    public putApiDictionaries({
        id,
        requestBody,
    }: {
        /**
         * ID of the dictionary to be updated. Cannot be empty.
         */
        id: number,
        /**
         * Dictionary object that needs to be updated.
         */
        requestBody: DictionaryDTO,
    }): Observable<DictionaryDTO> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/dictionaries/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Dictionary DTO has wrong ID`,
                404: `Dictionary not found`,
            },
        });
    }

    /**
     * Delete a dictionary by ID
     * Deletes a specific dictionary by ID
     * @returns void
     * @throws ApiError
     */
    public deleteApiDictionaries({
        id,
    }: {
        /**
         * ID of the dictionary to be deleted. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/dictionaries/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Say hello
     * Say hello
     * @returns string String response with greeting
     * @throws ApiError
     */
    public getApiDictionaryEditor(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor',
        });
    }

    /**
     * Get dictionary query data by id
     * Get dictionary query data by id
     * @returns DictionaryQueryDTO Dictionary query data
     * @throws ApiError
     */
    public getApiDictionaryEditor1({
        id,
    }: {
        /**
         * ID of the dictionary query to be obtained. Cannot be empty.
         */
        id: number,
    }): Observable<DictionaryQueryDTO> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get dictionary all detail queries data by id and master id
     * Get dictionary all detail queries data by id and master id
     * @returns any Dictionary all detail queries data
     * @throws ApiError
     */
    public getApiDictionaryEditorDetails({
        id,
        masterId,
    }: {
        /**
         * ID of the dictionary detail to be obtained. Cannot be empty.
         */
        id: number,
        /**
         * MasterId of the dictionary detail to be obtained. Cannot be empty.
         */
        masterId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor/{id}/details/{masterId}',
            path: {
                'id': id,
                'masterId': masterId,
            },
            errors: {
                404: `Dictionary not found`,
            },
        });
    }

    /**
     * Get dictionary detail data by params.
     * Get dictionary detail data by id, master id and detail name
     * @returns any Dictionary detail data
     * @throws ApiError
     */
    public getApiDictionaryEditorDetails1({
        detailName,
        id,
        masterId,
    }: {
        /**
         * Detail name of the dictionary detail to be obtained. Cannot be empty.
         */
        detailName: string,
        /**
         * ID of the dictionary detail to be obtained. Cannot be empty.
         */
        id: number,
        /**
         * MasterId of the dictionary detail to be obtained. Cannot be empty.
         */
        masterId: string,
    }): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/dictionary-editor/{id}/details/{masterId}/{detailName}',
            path: {
                'detailName': detailName,
                'id': id,
                'masterId': masterId,
            },
            errors: {
                404: `Dictionary not found`,
            },
        });
    }

}
