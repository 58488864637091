import { inject, Injectable } from '@angular/core';
import { filter, Observable, switchMap, take } from 'rxjs';
import { EcpService } from '@sib/shared/feature';
import { VariablesFacadeService } from '@sib/task/shared/store';
import { DocumentsService } from './documents.service';
import { b64toBlob } from '@sib/shared/util';

@Injectable({
  providedIn: 'root',
})
export class EcpSignService {
  private ecpService = inject(EcpService);
  private variablesFacadeService = inject(VariablesFacadeService);
  private documentsService = inject(DocumentsService);

  onSignECP(base64: string, documentId: string) {
    if (base64) {
      return this.onSaveECP(b64toBlob(base64));
    } else {
      return this.variablesFacadeService.selectIsFileStoreEnable$.pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.documentsService.downloadFile(documentId)),
        switchMap((blob) => this.onSaveECP(blob)),
      );
    }
  }

  onSaveECP(blob: Blob) {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        const data = e.target?.result!;
        this.ecpService
          .getSign(data)
          .pipe(filter(Boolean))
          .subscribe((result) => {
            observer.next(result);
          });
      };

      reader.onerror = (e: any) => observer.error(e);

      reader.readAsArrayBuffer(blob);
    });
  }
}
