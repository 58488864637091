/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ReportDto } from '../models/ReportDto';
import type { ReportRoleDto } from '../models/ReportRoleDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ReportService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all reports
     * Returns all reports.
     * @returns ReportDto all reports
     * @throws ApiError
     */
    public getApiReports(): Observable<Array<ReportDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/reports',
        });
    }

    /**
     * Create report
     * Method for creating report.
     * @returns ReportDto report created
     * @throws ApiError
     */
    public postApiReports({
        requestBody,
    }: {
        /**
         * Report object that needs to be created.
         */
        requestBody: ReportDto,
    }): Observable<ReportDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get report
     * Get report by title.
     * @returns any report found
     * @throws ApiError
     */
    public getApiReportsTitle({
        title,
    }: {
        /**
         * Report title. Cannot be empty.
         */
        title: string,
    }): Observable<{
        reportId: string;
        title?: string;
        actFlg?: boolean;
        nameId?: string;
        reportRoles?: Array<ReportRoleDto>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/reports/title/{title}',
            path: {
                'title': title,
            },
            errors: {
                404: `report not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update report
     * Updates the report with the given ID.
     * @returns any report updated
     * @throws ApiError
     */
    public putApiReports({
        id,
        requestBody,
    }: {
        /**
         * Report ID. Cannot be empty.
         */
        id: string,
        /**
         * Report object that needs to be updated.
         */
        requestBody: ReportDto,
    }): Observable<{
        reportId: string;
        title?: string;
        actFlg?: boolean;
        nameId?: string;
        reportRoles?: Array<ReportRoleDto>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/reports/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete report
     * Deletes the report with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiReports({
        id,
    }: {
        /**
         * Report ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get report
     * Get report by ID.
     * @returns any report found
     * @throws ApiError
     */
    public getApiReports1({
        reportId,
    }: {
        /**
         * Report ID. Cannot be empty.
         */
        reportId: string,
    }): Observable<{
        reportId: string;
        title?: string;
        actFlg?: boolean;
        nameId?: string;
        reportRoles?: Array<ReportRoleDto>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/reports/{reportId}',
            path: {
                'reportId': reportId,
            },
            errors: {
                404: `report not found`,
                500: `server error`,
            },
        });
    }

}
