import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryControllersGroupService extends FormGroup<
  ReturnType<typeof BeneficiaryControllersGroupService.form>
> {
  static readonly form = () => ({
    buBeneficiary: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    beneficiaryName: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    beneficiaryEdrpouCode: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
  });

  constructor() {
    super(BeneficiaryControllersGroupService.form());
  }
}
