import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as PromotionActions from './promotion.actions';
import { ToastrService } from 'ngx-toastr';
import { DictionariesService } from '../dictionaries.service';
import { PromotionFacadeService } from './promotion.facade.service';
import { PromotionService } from '@api/dictionaries';

@Injectable()
export class PromotionEffects {
  loadPromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PromotionActions.loadPromotion),
      concatLatestFrom((action) => [this.promotionFacadeService.getPromotion$.pipe(take(1))]),
      switchMap(([action, promotion]) =>
        (promotion.length ? of(promotion) : this.promotionService.getApiPromotions()).pipe(
          map((response) =>
            PromotionActions.loadPromotionSuccess({
              promotion: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(PromotionActions.loadPromotionFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
    private promotionFacadeService: PromotionFacadeService,
    private promotionService: PromotionService,
  ) {}
}
