import {
  BadRequest,
  Forbidden,
  InternalServerError,
  MethodNotAllowed,
  NotAcceptable,
  NotFound,
  ProxyAuthenticationRequired,
  RequestTimeout,
  SapGeneralError,
  SapMultipleError,
  ServiceUnavailable,
  Unauthorized,
  UnexpectedError,
  UnprocessableEntity,
} from './error.models';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export const getErrorByStatus = ({ error, status }: HttpErrorResponse) => {
  switch (status) {
    case HttpStatusCode.BadRequest:
      return new BadRequest(error);

    case HttpStatusCode.Unauthorized:
      return new Unauthorized(error);

    case HttpStatusCode.Forbidden:
      return new Forbidden(error);

    case HttpStatusCode.NotFound:
      return new NotFound(error);

    case HttpStatusCode.MethodNotAllowed:
      return new MethodNotAllowed(error);

    case HttpStatusCode.NotAcceptable:
      return new NotAcceptable(error);

    case HttpStatusCode.ProxyAuthenticationRequired:
      return new ProxyAuthenticationRequired(error);

    case HttpStatusCode.RequestTimeout:
      return new RequestTimeout(error);

    case HttpStatusCode.UnprocessableEntity:
      return new UnprocessableEntity(error);

    case SapMultipleError.statusCode:
      return new SapMultipleError(error, error.error);

    case SapGeneralError.statusCode:
      return new SapGeneralError(error, error.message, error.error.code, error.error.message);

    case HttpStatusCode.InternalServerError:
      return new InternalServerError(error);

    case HttpStatusCode.ServiceUnavailable:
      return new ServiceUnavailable(error);

    default:
      return new UnexpectedError();
  }
};
