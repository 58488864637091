import { createSelector } from '@ngrx/store';
import * as fromLawCaseTypes from './law-case-types.reducer';
import { selectLawCaseTypesState } from '../+shell/reducers.index';

export const selectLawCaseTypesIsLoading = createSelector(selectLawCaseTypesState, fromLawCaseTypes.selectIsLoading);

export const selectLawCaseTypesEntities = createSelector(selectLawCaseTypesState, fromLawCaseTypes.selectEntities);

export const selectLawCaseTypeById = (casesType: string) =>
  createSelector(selectLawCaseTypesEntities, (lawCaseTypesEntities) => lawCaseTypesEntities[casesType]);

export const selectAllLawCaseTypes = createSelector(selectLawCaseTypesState, fromLawCaseTypes.selectAll);
