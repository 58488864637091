import { createSelector } from '@ngrx/store';
import {
  selectBpClientName,
  selectBpCode,
  selectProtocolCC,
  selectVotingComments,
  selectVotingResults,
} from '../../+state/variables/variables.selectors';
import { selectAllAgreements } from '../../+state/agreements/agreement.selectors';
import { selectEmployeesEntities } from '@sib/shared/store';

export const selectProtocolDate = createSelector(selectProtocolCC, (protocol) => protocol.protDate || new Date());

export const selectVotingList = createSelector(
  selectVotingResults,
  selectAllAgreements,
  selectBpClientName,
  selectBpCode,
  (votingResults, agreements, bpClientName, bpCode) =>
    agreements
      .filter((agreements) => agreements.genType !== 'ACTUAL')
      .map((item) => ({
        ...item,
        votingResult: votingResults?.filter((voting) => voting.agreementId === item.id),
        bpClientName: bpClientName,
        bpCode: bpCode,
      })),
);

export const selectVotingCommentsView = createSelector(
  selectVotingComments,
  selectEmployeesEntities,
  (votingResults, employees) =>
    votingResults.map((item) => ({
      comment: item.voteComment || '',
      name: employees[item.employeeId!]?.fullName || '',
    })),
);
