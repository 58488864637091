import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class RequisitesContractGroupService extends FormGroup<ReturnType<typeof RequisitesContractGroupService.form>> {
  private static readonly form = () => ({
    addressLetter: new FormControl<string>('', { validators: [], nonNullable: true }),
    addressLocation: new FormControl<string>('', { validators: [], nonNullable: true }),
    phoneFax: new FormControl<string>('', { validators: [], nonNullable: true }),
    passpDoc: new FormControl<string>('', { nonNullable: true }),
    addressLetterBank: new FormControl<string>('', { validators: [], nonNullable: true }),
    phoneFaxBank: new FormControl<string>('', { validators: [], nonNullable: true }),
    email: new FormControl<string>('', { validators: [], nonNullable: true }),
    emailBank: new FormControl<string>('', { validators: [], nonNullable: true }),
  });

  constructor() {
    super(RequisitesContractGroupService.form());
  }
}
