import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AutofocusDirective } from '@sib/shared/ui';

@Component({
  selector: 'sib-terms-securing-edit-title-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AutofocusDirective,
  ],
  templateUrl: './terms-securing-edit-title-dialog.component.html',
  styleUrls: ['./terms-securing-edit-title-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsSecuringEditTitleDialogComponent implements OnInit {
  public titleForm = new FormGroup({
    title: new FormControl<string>(''),
  });

  constructor(
    public dialogRef: MatDialogRef<TermsSecuringEditTitleDialogComponent, { title: string }>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string },
  ) {}

  ngOnInit(): void {
    this.fillForm();
  }

  private fillForm(): void {
    this.titleForm.patchValue({
      title: this.data.title || '',
    });
  }

  onSubmitDialog(): void {
    this.dialogRef.close({ title: this.titleForm.controls.title.value! });
  }
}
