import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObligationTypesActions from './obligation-types.actions';
import * as ObligationTypesSelectors from './obligation-types.selectors';
import { Store } from '@ngrx/store';
import { ObligationTypeService } from '@api/dictionaries';

@Injectable()
export class ObligationTypesEffects {
  loadRiskSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObligationTypesActions.loadObligationTypes),
      concatLatestFrom((action) => [this.store$.select(ObligationTypesSelectors.selectObligationTypes)]),
      switchMap(([action, obligationTypes]) =>
        obligationTypes.length ? of(obligationTypes) : this.obligationTypeService.getApiObligationTypes(),
      ),
      switchMap((response) => [
        ObligationTypesActions.loadObligationTypesSuccess({
          obligationTypes: response,
        }),
      ]),
      catchError((error) => of(ObligationTypesActions.loadObligationTypesFailure({ error }))),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private obligationTypeService: ObligationTypeService) {}
}
