import { createSelector } from '@ngrx/store';
import { selectEmployeesEntities, selectRolesEntities } from '@sib/shared/store';
import { selectProcessParticipant } from '../../+state/variables/variables.selectors';

export const buildParticipants = createSelector(
  selectProcessParticipant,
  selectRolesEntities,
  selectEmployeesEntities,
  (participants, roles, employees) =>
    participants.map((item) => ({
      roleName: roles[item.roleId!]?.name,
      name: employees[item.employeeId!]?.fullName,
    })),
);
