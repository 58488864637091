<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label> Код БП</mat-label>
      <input
        matInput
        autofocus
        type="text"
        inputmode="numeric"
        digitOnly
        maxlength="10"
        placeholder="Введіть код БП"
        formControlName="bpCode"
        [sibDisableControl]="!!f.idNumber.value"
      />
      <mat-error *ngIf="f.idNumber.hasError('serverError')">{{ f.idNumber.getError('serverError') }} </mat-error>
    </mat-form-field>
    <span>або</span>
    <mat-form-field subscriptSizing="dynamic">
      <mat-label> Код ЄДРПОУ/ІПН</mat-label>
      <input
        matInput
        type="text"
        inputmode="numeric"
        digitOnly
        placeholder="Введіть код ЄДРПОУ/ІПН"
        formControlName="idNumber"
        [sibDisableControl]="!!f.bpCode.value"
      />
      <mat-error *ngIf="f.idNumber.hasError('serverError')">{{ f.idNumber.getError('serverError') }} </mat-error>
    </mat-form-field>

    <button mat-stroked-button type="submit" color="primary">
      <mat-icon> assignment_add</mat-icon>
      Пошук
    </button>
  </div>
</form>
