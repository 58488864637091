/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ScopedEmployeeRoleDto } from '../models/ScopedEmployeeRoleDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScopedEmployeeRoleService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all scoped employee roles by effective role ID
     * Returns a list of all scoped employee roles for a given effective role ID.
     * @returns ScopedEmployeeRoleDto List of scoped employee roles or empty list
     * @throws ApiError
     */
    public getApiScopedRolesEffectiveRole({
        effectiveRoleId,
    }: {
        /**
         * The effective role ID. Cannot be empty.
         */
        effectiveRoleId: string,
    }): Observable<Array<ScopedEmployeeRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scoped-roles/effective-role/{effectiveRoleId}',
            path: {
                'effectiveRoleId': effectiveRoleId,
            },
        });
    }

    /**
     * Get all scoped employee roles by login
     * Returns a list of all scoped employee roles for a given login.
     * @returns ScopedEmployeeRoleDto List of scoped employee roles or empty list
     * @throws ApiError
     */
    public getApiScopedRolesLogin({
        login,
    }: {
        /**
         * The employee login. Cannot be empty.
         */
        login: string,
    }): Observable<Array<ScopedEmployeeRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scoped-roles/login/{login}',
            path: {
                'login': login,
            },
        });
    }

    /**
     * Get all scoped employee roles by role ID
     * Returns a list of all scoped employee roles for a given role ID.
     * @returns ScopedEmployeeRoleDto List of scoped employee roles or empty list
     * @throws ApiError
     */
    public getApiScopedRolesRole({
        roleId,
    }: {
        /**
         * The role ID. Cannot be empty.
         */
        roleId: string,
    }): Observable<Array<ScopedEmployeeRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scoped-roles/role/{roleId}',
            path: {
                'roleId': roleId,
            },
        });
    }

}
