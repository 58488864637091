import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as BelongingObjects from './belonging-objects.actions';
import * as Selectors from './belonging-objects.selectors';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class BelongingObjectsEffects {
  belongingObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BelongingObjects.loadBelongingObjects),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectBelongingObjects)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadBelongingObjects()).pipe(
          map((response) =>
            BelongingObjects.loadBelongingObjectsSuccess({
              belongingObjects: response,
            }),
          ),
          catchError((error) => of(BelongingObjects.loadBelongingObjectsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
