import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFilteredTabs } from './task-tabs.selectors';

@Injectable({
  providedIn: 'root',
})
export class TaskTabsFacadeService {
  private store = inject(Store);

  public getTaskTabs$ = this.store.select(selectFilteredTabs);
}
