/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ApplicationStatisticDto } from '../models/ApplicationStatisticDto';
import type { ApplicationStatisticParamsDto } from '../models/ApplicationStatisticParamsDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ApplicationStatisticService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get application statistics.
     * @returns ApplicationStatisticDto Successful operation
     * @throws ApiError
     */
    public postApplicationStatistics({
        requestBody,
    }: {
        /**
         * The parameters for the application statistics query.
         */
        requestBody: ApplicationStatisticParamsDto,
    }): Observable<ApplicationStatisticDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/application-statistics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
