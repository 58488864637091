import { createReducer, on } from '@ngrx/store';
import * as Decisions from './decisions.actions';
import { DecisionDTO } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const decisionsFeatureKey = 'decisions';

export interface State extends EntityState<DecisionDTO> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<DecisionDTO>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(Decisions.loadDecisions, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(Decisions.loadDecisionsSuccess, (state, { decisions }) => ({
    ...adapter.setAll(decisions, state),
    isLoading: false,
  })),
  on(Decisions.loadDecisionsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDecisionsState = selectAll;
