import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as ConditionTypesActions from './condition-types.actions';

import { ConditionTypeDto } from '@api/dictionaries';

export const conditionTypesFeatureKey = 'condition-types';

export interface State extends EntityState<ConditionTypeDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ConditionTypeDto> = createEntityAdapter<ConditionTypeDto>({
  selectId: (conditionType: ConditionTypeDto) => conditionType.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ConditionTypesActions.loadConditionTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ConditionTypesActions.loadConditionTypesSuccess, (state, { conditionTypes }) => ({
    ...adapter.setAll(conditionTypes, state),
    isLoading: false,
  })),

  on(ConditionTypesActions.loadConditionTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
