/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { PromotionDto } from '../models/PromotionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class PromotionService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all promotions
     * Returns all promotions.
     * @returns PromotionDto all promotions
     * @throws ApiError
     */
    public getApiPromotions(): Observable<Array<PromotionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/promotions',
        });
    }

    /**
     * Create promotion
     * Method for creating promotion.
     * @returns PromotionDto promotion created
     * @throws ApiError
     */
    public postApiPromotions({
        requestBody,
    }: {
        /**
         * Promotion object that needs to be created.
         */
        requestBody: PromotionDto,
    }): Observable<PromotionDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/promotions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get promotion
     * Get promotion by code.
     * @returns any promotion found
     * @throws ApiError
     */
    public getApiPromotions1({
        code,
    }: {
        /**
         * Promotion code. Cannot be empty.
         */
        code: number,
    }): Observable<{
        code: number;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/promotions/{code}',
            path: {
                'code': code,
            },
            errors: {
                404: `promotion not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update promotion
     * Updates the promotion with the given CODE.
     * @returns any promotion updated
     * @throws ApiError
     */
    public putApiPromotions({
        code,
        requestBody,
    }: {
        /**
         * Promotion code.  Cannot be empty.
         */
        code: number,
        /**
         * Promotion object that needs to be updated.
         */
        requestBody: PromotionDto,
    }): Observable<{
        code: number;
        description?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/promotions/{code}',
            path: {
                'code': code,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete promotion
     * Deletes the promotion with the given CODE.
     * @returns void
     * @throws ApiError
     */
    public deleteApiPromotions({
        code,
    }: {
        /**
         * Promotion code.  Cannot be empty.
         */
        code: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/promotions/{code}',
            path: {
                'code': code,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
