import { createSelector } from '@ngrx/store';
import * as fromReports from './reports.reducer';
import { selectReportsState } from '../+shell/reducers.index';
import { selectRouterParam } from '../../router-state/router.selectors';

export const selectActiveReportId = selectRouterParam('id');

export const selectReportsIsLoading = createSelector(selectReportsState, fromReports.selectIsLoading);

export const selectAllReports = createSelector(selectReportsState, fromReports.selectAll);

export const selectReportsEntities = createSelector(selectReportsState, fromReports.selectEntities);

export const selectReportById = (reportId: string) =>
  createSelector(selectReportsEntities, (reportsEntities) => reportsEntities[reportId]);
