import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAvailableErrors, selectCountRequestErrorMessages } from './request-errors.selectors';
import { selectAllRequestErrors } from '../../+state/request-errors/request-errors.selectors';

@Injectable({
  providedIn: 'root',
})
export class RequestErrorsFacadeService {
  private readonly store = inject(Store);

  selectCountRequestErrorMessages$ = this.store.select(selectCountRequestErrorMessages);
  selectAllRequestErrors$ = this.store.select(selectAllRequestErrors);
  selectAvailableErrors$ = this.store.select(selectAvailableErrors);
}
