/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class StatusService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Obtaining services builds versions
     * @returns any[]
     * @throws ApiError
     */
    public postApiStatusAppsVersions({
        requestBody,
    }: {
        requestBody: any[],
    }): Observable<any[]> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/status/apps/versions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getApiStatus({
        serviceName,
    }: {
        serviceName: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/status/{serviceName}',
            path: {
                'serviceName': serviceName,
            },
        });
    }

}
