import { createAction, props } from '@ngrx/store';
import { BankServiceDTO } from '@api/dictionaries';

export const loadBankServices = createAction('[Dictionaries/API] Load Bank Services');
export const loadBankServicesSuccess = createAction(
  '[Dictionaries/API] Load Bank Services Success',
  props<{ bankServices: BankServiceDTO[] }>(),
);
export const loadBankServicesFailure = createAction(
  '[Dictionaries/API] Load Bank Services Failure',
  props<{ error: unknown }>(),
);
