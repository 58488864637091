/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BpSapError } from '../models/BpSapError';
import type { BPSapModel } from '../models/BPSapModel';
import type { BpShortInfoDto } from '../models/BpShortInfoDto';
import type { ClientCredit } from '../models/ClientCredit';
import type { ClientPartnerSetResult } from '../models/ClientPartnerSetResult';
import type { Commission } from '../models/Commission';
import type { Deal } from '../models/Deal';
import type { GenDeal } from '../models/GenDeal';
import type { PartnerAccount } from '../models/PartnerAccount';
import type { PartnerSpouseData } from '../models/PartnerSpouseData';
import type { PledgeAgreementsType } from '../models/PledgeAgreementsType';
import type { RepaymentScheduleDto } from '../models/RepaymentScheduleDto';
import type { SapParamsDto } from '../models/SapParamsDto';
import type { SumSpellSet } from '../models/SumSpellSet';
import type { WorkDay } from '../models/WorkDay';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SapIntegrationService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get actual client loans
     * Get actual client loans by BP code.
     * @returns ClientCredit actual client loans found
     * @throws ApiError
     */
    public getSapActualClientLoans({
        bpCode,
    }: {
        /**
         * Business partner BP code.
         */
        bpCode: string,
    }): Observable<Array<ClientCredit>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/actual-client-loans',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `actual client loans not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get business partner info
     * Get business partner info by BP code.
     * @returns BPSapModel business partner found
     * @throws ApiError
     */
    public getSapBpInfo({
        bpCode,
    }: {
        /**
         * Business partner BP code.
         */
        bpCode: string,
    }): Observable<BPSapModel> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/bp-info',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `business partner not found`,
            },
        });
    }

    /**
     * Get business partner
     * Get business partner by BP code.
     * @returns BpShortInfoDto business partner found
     * @throws ApiError
     */
    public getSapBpSearchBpCode({
        bpCode,
    }: {
        bpCode: string,
    }): Observable<BpShortInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/bp-search/bp-code/{bpCode}',
            path: {
                'bpCode': bpCode,
            },
            errors: {
                400: `bad request`,
                404: `business partner by BP code number was not found`,
                461: `business partner not found`,
            },
        });
    }

    /**
     * Get business partner
     * Get business partner by INN.
     * @returns BpShortInfoDto business partner found
     * @throws ApiError
     */
    public getSapBpSearchInn({
        inn,
    }: {
        /**
         * INN
         */
        inn: number,
    }): Observable<BpShortInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/bp-search/inn/{inn}',
            path: {
                'inn': inn,
            },
            errors: {
                400: `bad request`,
                404: `business partner by inn number was not found`,
                461: `business partner not found`,
            },
        });
    }

    /**
     * Get closed client loans
     * Get closed client loans by BP code.
     * @returns ClientCredit closed client loans found
     * @throws ApiError
     */
    public getSapClosedClientLoans({
        bpCode,
    }: {
        /**
         * Business partner BP code.
         */
        bpCode: string,
    }): Observable<Array<ClientCredit>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/closed-client-loans',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `closed client loans not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get deals for limits
     * Get deals for limits by bp code and reporting date.
     * @returns Deal deals for limits found
     * @throws ApiError
     */
    public getSapDealsForLimits({
        bpCode,
        reportingDate,
    }: {
        /**
         * BP code
         */
        bpCode: string,
        /**
         * Reporting date
         */
        reportingDate: string,
    }): Observable<Array<Deal>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/deals-for-limits',
            query: {
                'bp-code': bpCode,
                'reporting-date': reportingDate,
            },
            errors: {
                400: `bad request`,
                461: `deals for limits not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get gen deals
     * Get gen deals by bp code.
     * @returns GenDeal Gen deals not found
     * @throws ApiError
     */
    public getSapGenDeals({
        bpCode,
        categoryType,
    }: {
        /**
         * BP Code
         */
        bpCode: string,
        /**
         * Used for filtering of requested data
         */
        categoryType?: string,
    }): Observable<Array<GenDeal>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/gen-deals',
            query: {
                'bp-code': bpCode,
                'categoryType': categoryType,
            },
            errors: {
                400: `bad request`,
                461: `Gen deals not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get annuity payment
     * Get annuity payment by loan agreement deal number.
     * @returns any Annuity payment found
     * @throws ApiError
     */
    public getSapGetAnnuityPayment({
        dealNumber,
    }: {
        /**
         * Loan agreement deal number
         */
        dealNumber: string,
    }): Observable<{
        DealNumberSAP?: string;
        annuityPaymentSum?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-annuity-payment',
            query: {
                'dealNumber': dealNumber,
            },
            errors: {
                400: `Bad request`,
                461: `Annuity payment not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get client kyc data
     * Get client kyc data by bp code.
     * @returns any Client KYC data found
     * @throws ApiError
     */
    public getSapGetClientKycData({
        bpCode,
    }: {
        /**
         * BP code
         */
        bpCode: string,
    }): Observable<{
        bpNum?: string;
        identDate?: string;
        identDateNext?: string;
        anketaKind?: string;
        riskLevel?: string;
        decisionTxt?: string;
        decisionDate?: string;
        boCheck?: boolean;
        comment?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-client-kyc-data',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `Client KYC data not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get client partner set
     * Get client partner set by bp code and params.
     * @returns any Client Partner Set data found
     * @throws ApiError
     */
    public postSapGetClientPartnerSet({
        bpCode,
        requestBody,
    }: {
        /**
         * BP code
         */
        bpCode: string,
        /**
         * Sap params data.
         */
        requestBody: SapParamsDto,
    }): Observable<{
        results?: Array<ClientPartnerSetResult>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/get-client-partner-set/{bp-code}',
            path: {
                'bp-code': bpCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                461: `Client Partner Set data not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get commissions
     * Get a list of commissions, the order of their calculation and payment.
     * @returns Commission Commissions found
     * @throws ApiError
     */
    public getSapGetCommissions({
        dealNumber,
    }: {
        /**
         * Loan agreement deal number
         */
        dealNumber: string,
    }): Observable<Array<Commission>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-commissions',
            query: {
                'dealNumber': dealNumber,
            },
            errors: {
                400: `Bad request`,
                461: `Commissions not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get partner account
     * Get partner account by bp code.
     * @returns PartnerAccount Partner accounts found
     * @throws ApiError
     */
    public getSapGetPartnerAccount({
        bpCode,
    }: {
        /**
         * BP Code
         */
        bpCode: string,
    }): Observable<Array<PartnerAccount>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-partner-account',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `Partner account not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get partner full name info
     * Get partner full name info by bp-code.
     * @returns any Business partner full name info found
     * @throws ApiError
     */
    public getSapGetPartnerFullName({
        bpCode,
    }: {
        /**
         * BP Code
         */
        bpCode: string,
    }): Observable<{
        bpNum?: string;
        fullName?: string;
        email?: string;
        phone?: string;
        shortName?: string;
        passpDoc?: string;
        taxId?: string;
        locationAddr?: string;
        mailingAddr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-partner-full-name',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `Bad request`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get partner individual info
     * Get partner individual info bp code.
     * @returns any Business partner individual info set found
     * @throws ApiError
     */
    public getSapGetPartnerIndividualInfo({
        bpCode,
    }: {
        /**
         * BP Code
         */
        bpCode: string,
    }): Observable<{
        bpNumber?: string;
        errorMsg?: string;
        lastName?: string;
        firstName?: string;
        patronymic?: string;
        natio?: string;
        reside?: string;
        birthDate?: string;
        birthPlace?: string;
        postCode?: string;
        country?: string;
        countryName?: string;
        region?: string;
        city?: string;
        street?: string;
        houseNo?: string;
        apartment?: string;
        postCodeF?: string;
        countryF?: string;
        countryNameF?: string;
        regionF?: string;
        cityF?: string;
        streetF?: string;
        houseNoF?: string;
        apartmentF?: string;
        phone?: string;
        phoneMobile?: string;
        phoneFax?: string;
        email?: string;
        innOkpo?: string;
        passSerie?: string;
        passNum?: string;
        passDoc?: string;
        passInst?: string;
        passDate?: string;
        famEng?: string;
        nameEng?: string;
        addrEng?: string;
        sex?: string;
        marst?: string;
        bez20?: string;
        regionCode?: string;
        regionCodeF?: string;
        cndsc?: string;
        cndscLandx?: string;
        regionCodeW?: string;
        regionW?: string;
        cityW?: string;
        streetW?: string;
        houseNoW?: string;
        apartmentW?: string;
        postCodeW?: string;
        countryW?: string;
        countryNameW?: string;
        svidName?: string;
        svidIdNumber?: string;
        svidInstitute?: string;
        svidValidDateFrom?: string;
        svidFoundDate?: string;
        typeBp?: string;
        kved?: string;
        tabNum?: string;
        pep?: string;
        codeRegistr?: string;
        codeLiving?: string;
        market?: string;
        marketName?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-partner-individual-info',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get partner spouse data
     * Get partner spouse data by bp code.
     * @returns PartnerSpouseData Partner spouse data found
     * @throws ApiError
     */
    public getSapGetPartnerSpouseData({
        bpCode,
    }: {
        /**
         * BP Code
         */
        bpCode: string,
    }): Observable<Array<PartnerSpouseData>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-partner-spouse-data',
            query: {
                'bp-code': bpCode,
            },
            errors: {
                400: `bad request`,
                461: `Partner spouse data not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get repayment schedules
     * Get a list of repayment schedules.
     * @returns RepaymentScheduleDto Repayment schedules found
     * @throws ApiError
     */
    public getSapGetRepaymentSchedule({
        dealNumber,
    }: {
        /**
         * Loan agreement deal number
         */
        dealNumber: string,
    }): Observable<Array<RepaymentScheduleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/get-repayment-schedule',
            query: {
                'dealNumber': dealNumber,
            },
            errors: {
                400: `Bad request`,
                461: `Repayment schedules not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get sum spell set
     * Get sum spell set by params.
     * @returns any Sum spell set data found
     * @throws ApiError
     */
    public postSapGetSumSpellSet({
        requestBody,
    }: {
        /**
         * Sap params data.
         */
        requestBody: SapParamsDto,
    }): Observable<{
        error?: BpSapError;
        'd'?: SumSpellSet;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sap/get-sum-spell-set',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get org unit address
     * Get org unit address by orgUnit ID.
     * @returns any Org unit address found
     * @throws ApiError
     */
    public getSapOrgUnitAddress({
        orgUnitId,
    }: {
        /**
         * Org unit id
         */
        orgUnitId: string,
    }): Observable<{
        orgUnitId?: string;
        city?: string;
        address?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/org-unit-address',
            query: {
                'org-unit-id': orgUnitId,
            },
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get pledge agreements types
     * Get pledge agreements types by BP assets types codes.
     * @returns PledgeAgreementsType Pledge agreements types found
     * @throws ApiError
     */
    public getSapPledgeAgreementsTypes({
        assetsTypesCodes,
    }: {
        /**
         * Assets types codes
         */
        assetsTypesCodes: Array<string>,
    }): Observable<Array<PledgeAgreementsType>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/pledge-agreements-types',
            query: {
                'assetsTypesCodes': assetsTypesCodes,
            },
            errors: {
                400: `Bad request`,
                461: `Pledge agreements types not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get work day
     * Get work day by date.
     * @returns WorkDay working hours of the day found
     * @throws ApiError
     */
    public getSapWorkDay({
        date,
    }: {
        /**
         * Date
         */
        date: string,
    }): Observable<WorkDay> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/work-day/{date}',
            path: {
                'date': date,
            },
            errors: {
                500: `Server error`,
            },
        });
    }

    /**
     * Get work days
     * Get work days by years period.
     * @returns WorkDay working hours of the day found
     * @throws ApiError
     */
    public getSapWorkDays({
        firstYear,
        secondYear,
    }: {
        /**
         * First year
         */
        firstYear: number,
        /**
         * Second year
         */
        secondYear: number,
    }): Observable<Array<WorkDay>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/sap/work-days',
            query: {
                'first-year': firstYear,
                'second-year': secondYear,
            },
            errors: {
                500: `Server error`,
            },
        });
    }

}
