/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ProtocolConditionTypeDto } from '../models/ProtocolConditionTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ProtocolConditionTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all protocol condition types
     * Returns all protocol condition types.
     * @returns ProtocolConditionTypeDto all protocol condition types
     * @throws ApiError
     */
    public getApiProtocolConditionTypes(): Observable<Array<ProtocolConditionTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/protocol-condition-types',
        });
    }

    /**
     * Create protocol condition type
     * Method for creating protocol condition type.
     * @returns any protocol condition type created
     * @throws ApiError
     */
    public postApiProtocolConditionTypes({
        requestBody,
    }: {
        /**
         * Protocol condition type object that needs to be created.
         */
        requestBody: ProtocolConditionTypeDto,
    }): Observable<{
        type: string;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/protocol-condition-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get protocol condition type
     * Get protocol condition type by type.
     * @returns any protocol condition type found
     * @throws ApiError
     */
    public getApiProtocolConditionTypes1({
        type,
    }: {
        /**
         * Protocol condition type. Cannot be empty.
         */
        type: string,
    }): Observable<{
        type: string;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/protocol-condition-types/{type}',
            path: {
                'type': type,
            },
            errors: {
                404: `protocol condition type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update protocol condition type
     * Updates the protocol condition type with the given type.
     * @returns any protocol condition type updated
     * @throws ApiError
     */
    public putApiProtocolConditionTypes({
        type,
        requestBody,
    }: {
        /**
         * Protocol condition type. Cannot be empty.
         */
        type: string,
        /**
         * Assessment type object that needs to be updated.
         */
        requestBody: ProtocolConditionTypeDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/protocol-condition-types/{type}',
            path: {
                'type': type,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete protocol condition type
     * Deletes the protocol condition type with the given type.
     * @returns void
     * @throws ApiError
     */
    public deleteApiProtocolConditionTypes({
        type,
    }: {
        /**
         * Protocol condition type. Cannot be empty.
         */
        type: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/protocol-condition-types/{type}',
            path: {
                'type': type,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
