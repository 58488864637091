/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RoleAccessDto } from '../models/RoleAccessDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RoleAccessService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all role access
     * Returns all role access.
     * @returns RoleAccessDto all role access
     * @throws ApiError
     */
    public getApiTabAccessRoleAccess(): Observable<Array<RoleAccessDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/tab-access/role-access',
        });
    }

    /**
     * Create role access
     * Method for creating role access.
     * @returns RoleAccessDto role access created
     * @throws ApiError
     */
    public postApiTabAccessRoleAccess({
        requestBody,
    }: {
        /**
         * Role access object that needs to be created.
         */
        requestBody: RoleAccessDto,
    }): Observable<RoleAccessDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/tab-access/role-access',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get accesses by role ids
     * Returns all role accesses for specified role ids.
     * @returns RoleAccessDto All role accesses for specified role ids
     * @throws ApiError
     */
    public getApiTabAccessRoleAccessRoles({
        roleIds,
    }: {
        /**
         * List of role ids
         */
        roleIds?: any[],
    }): Observable<Array<RoleAccessDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/tab-access/role-access/roles',
            query: {
                'roleIds': roleIds,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get role access
     * Get role access by ID.
     * @returns any role access found
     * @throws ApiError
     */
    public getApiTabAccessRoleAccess1({
        id,
    }: {
        /**
         * Role access ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        roleId: string;
        tabCode: string;
        tabAccess: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/tab-access/role-access/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `role access not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update role access
     * Updates the role access with the given ID.
     * @returns any role access updated
     * @throws ApiError
     */
    public putApiTabAccessRoleAccess({
        id,
        requestBody,
    }: {
        /**
         * Role access ID. Cannot be empty.
         */
        id: number,
        /**
         * Role access object that needs to be updated.
         */
        requestBody: RoleAccessDto,
    }): Observable<{
        id: number;
        roleId: string;
        tabCode: string;
        tabAccess: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/tab-access/role-access/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete role access
     * Deletes the role access with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiTabAccessRoleAccess({
        id,
    }: {
        /**
         * Role access ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/tab-access/role-access/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
