import { createSelector } from '@ngrx/store';
import * as fromProductCategories from './product-categories.reducer';
import { selectProductCategoriesState } from '../+shell/reducers.index';

export const selectProductCategoriesIsLoading = createSelector(
  selectProductCategoriesState,
  fromProductCategories.selectIsLoading,
);
export const selectProductCategories = createSelector(
  selectProductCategoriesState,
  fromProductCategories.selectProductCategoriesState,
);

export const selectProductCategoryById = (id: number) =>
  createSelector(selectProductCategories, (categories) => categories.find((category) => category?.id === id));
