import { createAction, props } from '@ngrx/store';
import { ProductDto } from '@api/dictionaries';

export const loadProductList = createAction('[Dictionaries/API] Load Product List');
export const loadProductListSuccess = createAction(
  '[Dictionaries/API] Load Product List Success',
  props<{ productList: ProductDto[] }>(),
);
export const loadProductListFailure = createAction(
  '[Dictionaries/API] Load Product List Failure',
  props<{ error: any }>(),
);
