/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { OrgUnitDto } from '../models/OrgUnitDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OrgUnitService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all OrgUnits
     * Returns a list of all OrgUnits.
     * @returns OrgUnitDto List of Org units or empty list
     * @throws ApiError
     */
    public getApiOrgUnits(): Observable<Array<OrgUnitDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/org-units',
        });
    }

    /**
     * Add OrgUnit
     * Creates a new OrgUnit object.
     * @returns OrgUnitDto OrgUnit created successfully
     * @throws ApiError
     */
    public postApiOrgUnits({
        requestBody,
    }: {
        /**
         * OrgUnit data.
         */
        requestBody: OrgUnitDto,
    }): Observable<OrgUnitDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/org-units',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `OrgUnit with such code already exists`,
            },
        });
    }

    /**
     * Get OrgUnit by position ID
     * Returns the OrgUnit object with the specified position ID.
     * @returns OrgUnitDto OrgUnit exist
     * @throws ApiError
     */
    public getApiOrgUnitsPosition({
        positionId,
    }: {
        /**
         * The position id. Cannot be empty.
         */
        positionId: number,
    }): Observable<OrgUnitDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/org-units/position/{positionId}',
            path: {
                'positionId': positionId,
            },
            errors: {
                404: `OrgUnit with such position id not found`,
            },
        });
    }

    /**
     * Get OrgUnit by unit code
     * Returns the OrgUnit object with the specified unit code.
     * @returns OrgUnitDto OrgUnit exist
     * @throws ApiError
     */
    public getApiOrgUnits1({
        unitCode,
    }: {
        /**
         * The unit code. Cannot be empty.
         */
        unitCode: number,
    }): Observable<OrgUnitDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/org-units/{unitCode}',
            path: {
                'unitCode': unitCode,
            },
            errors: {
                404: `OrgUnit with such code not found`,
            },
        });
    }

    /**
     * Delete OrgUnit by unit code
     * Deletes the OrgUnit object with the specified unit code.
     * @returns void
     * @throws ApiError
     */
    public deleteApiOrgUnits({
        unitCode,
    }: {
        /**
         * The unit code. Cannot be empty.
         */
        unitCode: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/org-units/{unitCode}',
            path: {
                'unitCode': unitCode,
            },
        });
    }

    /**
     * Get OrgUnit by unit code and scope
     * Returns the OrgUnit object with the specified unit code and scope.
     * @returns OrgUnitDto OrgUnit exist
     * @throws ApiError
     */
    public getApiOrgUnitsScope({
        scope,
        unitCode,
    }: {
        /**
         * The scope. Cannot be empty.
         */
        scope: string,
        /**
         * The unit code. Cannot be empty.
         */
        unitCode: number,
    }): Observable<OrgUnitDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/org-units/{unitCode}/scope/{scope}',
            path: {
                'scope': scope,
                'unitCode': unitCode,
            },
            errors: {
                404: `OrgUnit with such code and scope not found`,
            },
        });
    }

}
