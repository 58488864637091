export const PROCESS_DEFINITION_KEYS = [
  'loan-request-approval',
  'subprocess-consideration-and-decision',
  'subprocess_decision_kpuu',
  'subprocess_agreement_execution',
  'subprocess_conclusions_negotiation',
  'subprocess_protocol_updating_kk',
  'subprocess-protocol-approval-signing',
  'subprocess_export_to_acd',
  'subprocess_services_approval',
];

export const PROCESS_DEFINITION_KEY_IN = PROCESS_DEFINITION_KEYS.join(',');
