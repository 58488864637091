import { Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { FileDrop, FileDropComponent } from '../../components';

@Component({
  selector: 'sib-file-drop-dialog',
  templateUrl: './file-drop-dialog.component.html',
  styleUrls: ['./file-drop-dialog.component.scss'],
  standalone: true,
  imports: [FileDropComponent],
})
export class FileDropDialogComponent {
  public acceptFiles = ['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg', '.tiff', '.zip'];

  constructor(private dialogRef: DialogRef) {}

  onFileDropped(file: FileDrop): void {
    this.dialogRef.close(file);
  }
}
