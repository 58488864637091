/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BPRoleDto } from '../models/BPRoleDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BpRolesService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all bp roles
     * Returns all bp roles by role title.
     * @returns BPRoleDto all bp roles
     * @throws ApiError
     */
    public getApiBpRoles({
        roleTitle,
    }: {
        /**
         * Bp role ID.
         */
        roleTitle?: string,
    }): Observable<Array<BPRoleDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/bp-roles',
            query: {
                'roleTitle': roleTitle,
            },
        });
    }

    /**
     * Create bp role
     * Method for creating bp role.
     * @returns BPRoleDto bp role created
     * @throws ApiError
     */
    public postApiBpRoles({
        requestBody,
    }: {
        /**
         * Bp role object that needs to be created.
         */
        requestBody: BPRoleDto,
    }): Observable<BPRoleDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/bp-roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get bp role
     * Get bp role by ID.
     * @returns any bp role found
     * @throws ApiError
     */
    public getApiBpRoles1({
        id,
    }: {
        /**
         * Bp role ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/bp-roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `bp role not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update bp role
     * Updates the bp role with the given ID.
     * @returns any bp role updated
     * @throws ApiError
     */
    public putApiBpRoles({
        id,
        requestBody,
    }: {
        /**
         * Bp role ID. Cannot be empty.
         */
        id: number,
        /**
         * Bp role object that needs to be updated.
         */
        requestBody: BPRoleDto,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/bp-roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete bp role
     * Deletes the bp role with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiBpRoles({
        id,
    }: {
        /**
         * Bp role ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/bp-roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
