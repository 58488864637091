import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllDocumentEntryList } from '../../+state/document-entry-list/document-entry-list.selectors';
import { selectDocumentEntryById } from './document-entry-list.selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentEntryListFacadeService {
  private store = inject(Store);

  getAllDocumentEntryList$ = this.store.select(selectAllDocumentEntryList);
  selectDocumentEntryById$ = (id: string) => this.store.select(selectDocumentEntryById(id));
}
