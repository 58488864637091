import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectEquipmentTypes, selectEquipmentTypesEntities } from './equipment-types.selectors';
import { loadEquipmentTypes } from './equipment-types.actions';

@Injectable({
  providedIn: 'root',
})
export class EquipmentTypesFacadeService {
  public equipmentTypes$ = this.store.select(selectEquipmentTypes);
  public equipmentTypesEntities$ = this.store.select(selectEquipmentTypesEntities);

  constructor(private store: Store) {}

  public loadEquipmentTypes() {
    this.store.dispatch(loadEquipmentTypes());
  }
}
