/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BankServiceDTO } from '../models/BankServiceDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BankServiceService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get bank service
     * Get bank service by loan type.
     * @returns BankServiceDTO bank service found
     * @throws ApiError
     */
    public getApiBankServices({
        loanType,
    }: {
        /**
         * Loan type.  Cannot be empty.
         */
        loanType?: number,
    }): Observable<Array<BankServiceDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/bank-services',
            query: {
                'loan-type': loanType,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Create an bank service
     * Method for creating an bank service.
     * @returns BankServiceDTO bank service created
     * @throws ApiError
     */
    public postApiBankServices({
        requestBody,
    }: {
        /**
         * Bank service object that needs to be created.
         */
        requestBody: BankServiceDTO,
    }): Observable<BankServiceDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/bank-services',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get bank service
     * Get bank service by loan type.
     * @returns any bank service found
     * @throws ApiError
     */
    public getApiBankServices1({
        serviceCode,
    }: {
        /**
         * Service code.  Cannot be empty.
         */
        serviceCode: string,
    }): Observable<{
        serviceCode: string;
        serviceName?: string;
        proposalTypeIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/bank-services/{serviceCode}',
            path: {
                'serviceCode': serviceCode,
            },
            errors: {
                404: `bank service not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update a bank service
     * Updates the bank service.
     * @returns BankServiceDTO bank service updated
     * @throws ApiError
     */
    public putApiBankServices({
        serviceCode,
        requestBody,
    }: {
        /**
         * Service code.  Cannot be empty.
         */
        serviceCode: string,
        /**
         * Bank service object that needs to be updated.
         */
        requestBody: BankServiceDTO,
    }): Observable<BankServiceDTO> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/bank-services/{serviceCode}',
            path: {
                'serviceCode': serviceCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete bank service
     * Deletes the bank service with the given service code.
     * @returns void
     * @throws ApiError
     */
    public deleteApiBankServices({
        serviceCode,
    }: {
        /**
         * Service code.  Cannot be empty.
         */
        serviceCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/bank-services/{serviceCode}',
            path: {
                'serviceCode': serviceCode,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add a proposal type to a bank service
     * Method for adding a proposal type to a bank service.
     * @returns void
     * @throws ApiError
     */
    public postApiBankServicesProposalTypes({
        proposalTypeId,
        serviceCode,
    }: {
        /**
         * Proposal type ID. Cannot be empty.
         */
        proposalTypeId: number,
        /**
         * Service code. Cannot be empty.
         */
        serviceCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/bank-services/{serviceCode}/proposal-types/{proposalTypeId}',
            path: {
                'proposalTypeId': proposalTypeId,
                'serviceCode': serviceCode,
            },
            errors: {
                400: `bad request`,
            },
        });
    }

    /**
     * Delete a proposal type from a bank service
     * Method for deleting a proposal type from a bank service.
     * @returns void
     * @throws ApiError
     */
    public deleteApiBankServicesProposalTypes({
        proposalTypeId,
        serviceCode,
    }: {
        /**
         * Proposal type ID.  Cannot be empty.
         */
        proposalTypeId: number,
        /**
         * Service code. Cannot be empty.
         */
        serviceCode: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/bank-services/{serviceCode}/proposal-types/{proposalTypeId}',
            path: {
                'proposalTypeId': proposalTypeId,
                'serviceCode': serviceCode,
            },
        });
    }

}
