import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'sib-bank-agreement-contract-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatDatepickerModule],
  templateUrl: './bank-agreement-contract-form.component.html',
  styleUrls: ['./bank-agreement-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class BankAgreementContractFormComponent {}
