import { Directive, HostListener } from '@angular/core';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

@Directive({
  selector: '[sibAgGridResize]',
  standalone: true,
})
export class AgGridResizeDirective {
  private gridApi!: GridApi;

  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) {
      return;
    }
    setTimeout(() => this.gridApi.sizeColumnsToFit());
  }

  @HostListener('gridReady', ['$event'])
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}
