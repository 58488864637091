/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RoleAnnulmentTypeDTO } from '../models/RoleAnnulmentTypeDTO';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RoleAnnulmentTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all role annulment types
     * Returns all role annulment types.
     * @returns RoleAnnulmentTypeDTO all role annulment types
     * @throws ApiError
     */
    public getApiRoleAnnulmentTypes(): Observable<Array<RoleAnnulmentTypeDTO>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/role-annulment-types',
        });
    }

    /**
     * Create role annulment type
     * Method for creating role annulment type.
     * @returns RoleAnnulmentTypeDTO role annulment type created
     * @throws ApiError
     */
    public postApiRoleAnnulmentTypes({
        requestBody,
    }: {
        /**
         * Role annulment type object that needs to be created.
         */
        requestBody: RoleAnnulmentTypeDTO,
    }): Observable<RoleAnnulmentTypeDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/role-annulment-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add role to role annulment type
     * Method for adding role to role annulment type.
     * @returns RoleAnnulmentTypeDTO role added to role annulment type
     * @throws ApiError
     */
    public postApiRoleAnnulmentTypesAnnulmentTypeRole({
        annulmentTypeId,
        roleId,
    }: {
        /**
         * Role annulment type ID. Cannot be empty.
         */
        annulmentTypeId: number,
        /**
         * Role ID. Cannot be empty.
         */
        roleId: number,
    }): Observable<RoleAnnulmentTypeDTO> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/role-annulment-types/annulment-type/{annulmentTypeId}/role/{roleId}',
            path: {
                'annulmentTypeId': annulmentTypeId,
                'roleId': roleId,
            },
            errors: {
                404: `role annulment type or role not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete role from role annulment type
     * Method for deleting role from role annulment type.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRoleAnnulmentTypesAnnulmentTypeRole({
        annulmentTypeId,
        roleId,
    }: {
        /**
         * Role annulment type ID. Cannot be empty.
         */
        annulmentTypeId: number,
        /**
         * Role ID. Cannot be empty.
         */
        roleId: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/role-annulment-types/annulment-type/{annulmentTypeId}/role/{roleId}',
            path: {
                'annulmentTypeId': annulmentTypeId,
                'roleId': roleId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get role annulment type
     * Get role annulment type by ID.
     * @returns any role annulment type found
     * @throws ApiError
     */
    public getApiRoleAnnulmentTypes1({
        id,
    }: {
        /**
         * Role annulment type ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        roleId?: string;
        annulmentTypeId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/role-annulment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `role annulment type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update role annulment type
     * Updates the role annulment type with the given ID.
     * @returns any role annulment type updated
     * @throws ApiError
     */
    public putApiRoleAnnulmentTypes({
        id,
        requestBody,
    }: {
        /**
         * Role annulment type ID. Cannot be empty.
         */
        id: number,
        /**
         * Role annulment type object that needs to be updated.
         */
        requestBody: RoleAnnulmentTypeDTO,
    }): Observable<{
        id: number;
        roleId?: string;
        annulmentTypeId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/role-annulment-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete role annulment type
     * Deletes the role annulment type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiRoleAnnulmentTypes({
        id,
    }: {
        /**
         * Role annulment type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/role-annulment-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
