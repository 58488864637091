<header class="tabs__header" sibStickyBar>
  <div class="navigation" *ngIf="getTaskTabs$ | async as tabsView">
    <ul
      class="list mat-subtitle-2"
      cdkDropList
      [cdkDropListData]="tabsView"
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)"
    >
      <li *ngFor="let tab of tabsView; trackBy: trackByTypeFn" cdkDrag class="item" routerLinkActive="is-active">
        <a class="link" [routerLink]="[tab.route]">
          {{ tab.name }}
        </a>
      </li>
    </ul>
  </div>
</header>

<router-outlet></router-outlet>
