import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserTabRolesGroupAction } from './user-tab-roles.actions';
import { map, switchMap } from 'rxjs/operators';
import { RoleAccessService } from '@api/dictionaries';
import { AuthFacadeService } from '../../auth';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTabRolesFacadeService {
  private readonly store = inject(Store);
  private readonly roleAccessService = inject(RoleAccessService);
  private readonly authFacadeService = inject(AuthFacadeService);

  public loadRoles() {
    return this.authFacadeService.selectUserRoles$.pipe(
      first((result) => !!result.length),
      switchMap((roleIds) => this.roleAccessService.getApiTabAccessRoleAccessRoles({ roleIds })),
      map((response) =>
        this.store.dispatch(
          UserTabRolesGroupAction.loadSuccess({
            userTypeRoles: response,
          }),
        ),
      ),
    );
  }
}
