import { createSelector } from '@ngrx/store';
import { Agreement, Guarantor } from '@api/loan-approval';
import { convertArrayToObjectTyped } from '@sib/shared/util';
import { selectBpClientName, selectBpCode, selectPropertyOwner } from '../../+state/variables/variables.selectors';
import { selectAllAgreements } from '../../+state/agreements/agreement.selectors';
import { selectAllGuarantors } from '../../+state/guarantors/guarantors.selectors';
import { selectAllAgreementGuaranties } from '../../+state/agreement-guaranty/agreement-guaranty.selectors';
import { selectAllAssets } from '../../+state/asset/asset.selectors';
import { selectAllBailContracts } from '../../+state/bail-contracts/bail-contracts.selectors';

export const selectKycBpCodes = createSelector(
  selectBpCode,
  selectBpClientName,
  selectAllAgreements,
  selectAllGuarantors,
  selectPropertyOwner,
  selectAllAgreementGuaranties,
  selectAllAssets,
  selectAllBailContracts,
  (
    bpCode: number | null,
    bpClientName: string,
    agreements: Agreement[],
    guarantor,
    propertyOwner,
    agreementGuaranty,
    asset,
    bailContracts,
  ) => {
    const kycBpCodes: { bpCode: number | null; name: string; role: string }[] = [
      { bpCode: bpCode, role: 'Позичальник', name: bpClientName },
    ];

    if (agreements.length) {
      agreements.reduce(
        (acc, agreement) =>
          agreement.signatories?.reduce((result, signatory) => {
            if (signatory.roleTitle === 'BANK' && signatory.partnerSign) {
              result.push({
                bpCode: Number(signatory.partnerSign),
                name: signatory.partnerNameSign!,
                role: 'Підпис договору (Банк)',
              });
              return result;
            } else if (signatory.roleTitle === 'CLIENT' && signatory.partnerSign) {
              result.push({
                bpCode: Number(signatory.partnerSign),
                name: signatory.partnerNameSign!,
                role: 'Підпис договору (Клієнт)',
              });
              return result;
            }
            return result;
          }, acc) || acc,
        kycBpCodes,
      );
    }

    if (guarantor?.length) {
      guarantor.forEach((i: Guarantor) => {
        kycBpCodes.push({ bpCode: Number(i.bpCode!), name: i.orgName!, role: 'Фінансовий поручитель' });
        if (bailContracts?.length) {
          const bailContract = bailContracts.find(
            (item) => item.bpCode === i.bpCode && item.dealNumber === agreements[0].dealNumber,
          );
          if (bailContract) {
            bailContract.signatories?.forEach((sign) => {
              if (sign.roleTitle === 'CLIENT' && i.bpCode !== sign.partnerSign) {
                kycBpCodes.push({
                  bpCode: Number(sign.partnerSign!),
                  name: sign.partnerNameSign!,
                  role: 'Підпис поруки (Клієнт)',
                });
              }
            });
          }
        }
      });
    }

    if (propertyOwner?.length) {
      propertyOwner.forEach((i) => {
        kycBpCodes.push({ bpCode: Number(i.partnerBpCode), name: i.partnerName, role: 'Майновий поручитель' });
      });
    }

    if (agreementGuaranty?.length) {
      agreementGuaranty.forEach((i) => {
        if (i.signatories?.length) {
          i.signatories.forEach((sign) => {
            const assetIndex = asset.findIndex((ast) => ast.cagId === i.cagId);
            const propertyOwnerByObject = convertArrayToObjectTyped(propertyOwner, 'assetId');
            if (
              assetIndex !== -1 &&
              sign.roleTitle === 'CLIENT' &&
              sign.partnerSign &&
              sign.partnerSign !== propertyOwnerByObject[asset[assetIndex].id].partnerBpCode
            ) {
              kycBpCodes.push({
                bpCode: Number(sign.partnerSign!),
                name: sign.partnerNameSign!,
                role: 'Підпис застави (Клієнт)',
              });
            }
          });
        }
      });
    }

    return kycBpCodes.filter(
      (value, index, self) => self.findIndex((el) => el.bpCode === value.bpCode && el.role === value.role) === index,
    );
  },
);
