import { createSelector } from '@ngrx/store';
import * as fromConditionGroups from './condition-groups.reducer';
import * as ProductSelectors from '../product/product.selectors';
import * as ProductCategoriesSelectors from '../product-categories/product-categories.selectors';
import { selectConditionGroupsState } from '../+shell/reducers.index';

export const selectConditionGroupsIsLoading = createSelector(
  selectConditionGroupsState,
  fromConditionGroups.selectIsLoading,
);

export const selectConditionGroupsEntities = createSelector(
  selectConditionGroupsState,
  fromConditionGroups.selectEntities,
);

export const selectConditionGroupById = (id: number) =>
  createSelector(selectConditionGroupsEntities, (conditionGroupsEntities) => conditionGroupsEntities[id]);

export const selectAllConditionGroups = createSelector(selectConditionGroupsState, fromConditionGroups.selectAll);

// TODO define return type
export const selectDictionariesCustomProducts = (productCategoryId: number | null) =>
  createSelector(
    selectAllConditionGroups,
    ProductSelectors.selectProductListEntities,
    ProductCategoriesSelectors.selectProductCategories,
    (allConditionGroups, productListEntities, productCategories) => {
      if (!productCategoryId) {
        return [];
      }
      const productCategoriesArray: number[] = [];
      productCategories
        .filter((pc) => pc.id === productCategoryId)
        .map((item) => {
          if (item.active && item.products?.length) {
            item.products.forEach((listId) => {
              productCategoriesArray.push(listId);
            });
          }
        });
      allConditionGroups = allConditionGroups.filter((list) => productCategoriesArray.includes(list.prodId!));

      if (allConditionGroups?.length) {
        return allConditionGroups.map((conditionGroup) => ({
          ...conditionGroup,
          ...productListEntities[conditionGroup.prodId!],
          productName: `${conditionGroup.val} / ${productListEntities[conditionGroup.prodId!]?.prodName}`,
          groupId: conditionGroup.id,
        }));
      } else {
        return productCategoriesArray.map((item) => ({
          ...productListEntities[item],
          productName: `${productListEntities[item]?.prodName}`,
        }));
      }
    },
  );
