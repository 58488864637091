import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProductCategories, selectProductCategoryById } from './product-categories.selectors';
import { loadProductCategories } from './product-categories.actions';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoriesFacadeService {
  public selectProductCategories$ = this.store.select(selectProductCategories);
  public selectProductCategoriesById$ = (id: number) => this.store.select(selectProductCategoryById(id));

  constructor(private store: Store) {}

  public loadProductCategories() {
    this.store.dispatch(loadProductCategories());
  }
}
