import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { BeneficiaryDocOperation } from '@api/loan-approval';

const beneficiaryDocOperationsFeatureKey = 'beneficiaryDocOperations';

export type BeneficiaryDocOperationsFeatureKeyState = EntityState<BeneficiaryDocOperation>;

export const adapter = createEntityAdapter<BeneficiaryDocOperation>({ selectId: (item) => item.agreementId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) =>
    adapter.setAll(variables.beneficiaryDocOperations || [], state),
  ),
);

export const beneficiaryDocOperationsFeature = createFeature({
  name: beneficiaryDocOperationsFeatureKey,
  reducer,
  extraSelectors: ({ selectBeneficiaryDocOperationsState }) => ({
    ...adapter.getSelectors(selectBeneficiaryDocOperationsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = beneficiaryDocOperationsFeature;
