import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { REQUISITES_CONTRACT_CONFIG, RequisitesContractConfig } from './token';
import { RequisitesService } from '@sib/task/shared/da';
import { RequisitesContractGroupService } from './requisites-contract-group.service';
import { MatButtonModule } from '@angular/material/button';
import { EMAIL_REGEX } from '@sib/shared/util';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'sib-requisites-contract-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule],
  templateUrl: './requisites-contract-form.component.html',
  styleUrls: ['./requisites-contract-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class RequisitesContractFormComponent {
  @Input()
  public bpCode!: number | string | null;

  @Input()
  public orgUnit: number | null = null;
  public EMAIL_REGEX = EMAIL_REGEX;
  constructor(
    @Inject(REQUISITES_CONTRACT_CONFIG) public requisitesContractConfig: RequisitesContractConfig,
    private requisitesService: RequisitesService,
    public requisitesContract: RequisitesContractGroupService,
  ) {}

  public refreshPartnerRequisites() {
    combineLatest([
      this.requisitesService.getPartnerRequisites(String(this.bpCode!)),
      this.requisitesService.getPartnerPasspDoc(String(this.bpCode!)),
    ]).subscribe(([partnerRequisites, partnerPasspDoc]) => {
      this.requisitesContract.patchValue({
        addressLetter: partnerPasspDoc?.mailingAddr || '',
        addressLocation: partnerPasspDoc?.locationAddr || '',
        email: partnerRequisites.email,
        passpDoc: partnerPasspDoc?.passpDoc || '',
      });
    });
  }

  public refreshBankRequisites() {
    if (this.orgUnit) {
      this.requisitesService
        .getOrgUnitAddress(this.orgUnit)
        .subscribe((result) => this.requisitesContract.patchValue({ addressLetterBank: result.address }));
    } else {
      throw { message: 'Should`t reach here' };
    }
  }
}
