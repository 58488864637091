import { createFeature, createReducer, on } from '@ngrx/store';
import { taskSettingsActions } from './task-settings.actions';
import type { PageType } from '../../denormalized/task-tabs/types';

const taskSettingsKey = 'taskSettings';

export interface TaskSettingsState {
  userTabsSettingName: 'task-tabs' | 'task-assessment-tabs';
  isReadonly: boolean;
  restrictedTabTypes: PageType[];
}

export const initialState: TaskSettingsState = {
  userTabsSettingName: 'task-tabs',
  isReadonly: false,
  restrictedTabTypes: [],
};

export const reducer = createReducer<TaskSettingsState>(
  initialState,
  on(taskSettingsActions.updateSettings, (state, { taskSettings }) => ({ ...state, ...taskSettings })),
  on(taskSettingsActions.resetSettings, () => ({ ...initialState })),
);

export const taskSettingsFeature = createFeature({
  name: taskSettingsKey,
  reducer,
});
