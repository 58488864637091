/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { PositionDto } from '../models/PositionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class PositionService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all positions
     * Returns a list of all positions.
     * @returns PositionDto List of positions or empty list
     * @throws ApiError
     */
    public getApiPositions(): Observable<Array<PositionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/positions',
        });
    }

    /**
     * Add position
     * Creates a new position object.
     * @returns PositionDto Position created successfully
     * @throws ApiError
     */
    public postApiPositions({
        requestBody,
    }: {
        /**
         * Position data.
         */
        requestBody: PositionDto,
    }): Observable<PositionDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/positions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Position with such id already exists`,
            },
        });
    }

    /**
     * Get positions by name
     * Returns the positions with the specified name.
     * @returns PositionDto List of positions or empty list
     * @throws ApiError
     */
    public getApiPositionsName({
        positionName,
    }: {
        /**
         * The position name. Cannot be empty.
         */
        positionName: string,
    }): Observable<Array<PositionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/positions/name/{positionName}',
            path: {
                'positionName': positionName,
            },
        });
    }

    /**
     * Get position by ID
     * Returns the position object with the specified ID.
     * @returns PositionDto Position exist
     * @throws ApiError
     */
    public getApiPositions1({
        id,
    }: {
        /**
         * The position ID. Cannot be empty.
         */
        id: number,
    }): Observable<PositionDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/positions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Position with such id not found`,
            },
        });
    }

    /**
     * Delete position by ID
     * Deletes the position object with the specified ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiPositions({
        id,
    }: {
        /**
         * The position ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/positions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Find effective roles by position ID.
     * Returns the list of effective roles for the position with the specified ID.
     * @returns string List of positions or empty list
     * @throws ApiError
     */
    public getApiPositionsRoles({
        id,
    }: {
        /**
         * The position ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/positions/{id}/roles',
            path: {
                'id': id,
            },
        });
    }

}
