import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sibAgGridDropDownPosition]',
  standalone: true,
})
export class AgGridDropDownPositionDirective implements DoCheck {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngDoCheck(): void {
    if (this.elementRef.nativeElement.classList.contains('active')) {
      this.setDropDirection();
    }
  }

  private setDropDirection(): void {
    const moreAction = this.elementRef.nativeElement;
    const moreActionDropDown = moreAction.querySelector('.more-actions-box');
    const canDropDown = this.menuHeight(moreActionDropDown) < this.distanceFromBottom(moreAction);

    if (canDropDown) {
      this.renderer.addClass(moreActionDropDown, 'more-actions-box--top');
      this.renderer.removeClass(moreActionDropDown, 'more-actions-box--bottom');
    }

    if (!canDropDown) {
      this.renderer.addClass(moreActionDropDown, 'more-actions-box--bottom');
      this.renderer.removeClass(moreActionDropDown, 'more-actions-box--top');
    }
  }

  private menuHeight(moreActionDropDown: any): number {
    return moreActionDropDown.getBoundingClientRect().height;
  }

  private distanceFromBottom(moreAction: any): number {
    const moreActionPosition = moreAction.getBoundingClientRect();
    const distanceFromWindowTop = moreActionPosition.top - document.body.scrollTop;
    return window.innerHeight - distanceFromWindowTop - moreActionPosition.height;
  }
}
