<div class="general-information">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="currencyCode">
      <mat-header-cell *matHeaderCellDef>Валюта</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.currencyCode }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <b>
          {{ columnName.currencies }}
          <mat-form-field class="currency-picker" subscriptSizing="dynamic">
            <mat-label>Валюта</mat-label>
            <mat-select [formControl]="currencyCodeControl">
              <mat-option *ngFor="let item of currencies$ | async" [value]="item.code">{{ item.code }}</mat-option>
            </mat-select>
          </mat-form-field>
          на {{ todayDate | date }}:
        </b>
      </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="sum">
      <mat-header-cell *matHeaderCellDef> {{ columnName.sum }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.sum | customCurrency : ' ' : 'symbol' : '0.2-2' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sumEquivalent">
      <mat-header-cell *matHeaderCellDef>
        {{ columnName.sumEquivalent }}{{ currencyCodeControl.value }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.sumEquivalent | customCurrency : ' ' : 'symbol' : '0.2-2' }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        {{ sumTotal() | customCurrency : ' ' : 'symbol' : '0.2-2' }}
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['currencyCode', 'sumEquivalent']"></mat-footer-row>

    <div *matNoDataRow>Записи відсутні</div>
  </mat-table>
</div>
