/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ApplicationInfoDto } from '../models/ApplicationInfoDto';
import type { ApplicationsDataDto } from '../models/ApplicationsDataDto';
import type { HumanTaskHistoryDto } from '../models/HumanTaskHistoryDto';
import type { PropertyAssessmentInfo } from '../models/PropertyAssessmentInfo';
import type { TaskDto } from '../models/TaskDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CamundaService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Notify about deleted asset subtype
     * Send a notification about a deleted asset subtype.
     * @returns any Successful operation
     * @throws ApiError
     */
    public postCamundaNotificationAssetSubtype({
        astCode,
    }: {
        /**
         * The asset subtype code.
         */
        astCode: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/notification/asset-subtype',
            query: {
                'astCode': astCode,
            },
        });
    }

    /**
     * Get all processes
     * Get all processes by login and process definition key.
     * @returns ApplicationInfoDto Successful operation
     * @throws ApiError
     */
    public getCamundaProcess({
        isClosed,
        login,
        processDefinitionKeyIn,
    }: {
        /**
         * Flag to indicate if only closed processes should be returned. Default is false.
         */
        isClosed: boolean,
        /**
         * The employee login.
         */
        login: string,
        /**
         * A comma-separated list of process definition keys to filter by
         */
        processDefinitionKeyIn: string,
    }): Observable<Array<ApplicationInfoDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process',
            query: {
                'isClosed': isClosed,
                'login': login,
                'processDefinitionKeyIn': processDefinitionKeyIn,
            },
        });
    }

    /**
     * Get task history
     * Get human task history by request estimate number
     * @returns HumanTaskHistoryDto Successful operation
     * @throws ApiError
     */
    public getCamundaProcessAppHumanTaskHistoryEstimateNumber({
        estimateNumber,
    }: {
        /**
         * Estimate number
         */
        estimateNumber: string,
    }): Observable<Array<HumanTaskHistoryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/app-human-task-history/estimate-number/{estimate-number}',
            path: {
                'estimate-number': estimateNumber,
            },
        });
    }

    /**
     * Get task history
     * Get human task history by process ID
     * @returns HumanTaskHistoryDto Successful operation
     * @throws ApiError
     */
    public getCamundaProcessAppHumanTaskHistory({
        processId,
    }: {
        /**
         * process ID
         */
        processId: string,
    }): Observable<Array<HumanTaskHistoryDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/app-human-task-history/{processId}',
            path: {
                'processId': processId,
            },
        });
    }

    /**
     * Change process initiator data
     * Change the initiator data for a given process.
     * @returns void
     * @throws ApiError
     */
    public postCamundaProcessChangeInitiatorsData({
        initiatorId,
        processId,
    }: {
        /**
         * Initiator ID
         */
        initiatorId: string,
        /**
         * Process ID
         */
        processId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/process/change-initiators-data',
            query: {
                'initiatorId': initiatorId,
                'processId': processId,
            },
        });
    }

    /**
     * Change subdivision for active processes initiated by user
     * @returns any Successfully changed initiator for active processes initiated by user
     * @throws ApiError
     */
    public postCamundaProcessChangeSubdivision({
        initiatorLogin,
        orgUnit,
        requestBody,
    }: {
        /**
         * Initiator login
         */
        initiatorLogin: string,
        /**
         * Org unit code
         */
        orgUnit: number,
        /**
         * Applications data.
         */
        requestBody: ApplicationsDataDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/process/change-subdivision/{initiatorLogin}/{orgUnit}',
            path: {
                'initiatorLogin': initiatorLogin,
                'orgUnit': orgUnit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server error`,
            },
        });
    }

    /**
     * Clear initiator for active processes initiated by user
     * Clear initiator for all active processes initiated by user
     * @returns any Successfully clear initiator for active processes initiated by user
     * @throws ApiError
     */
    public postCamundaProcessClearInitiator({
        initiatorLogin,
    }: {
        /**
         * Initiator login
         */
        initiatorLogin: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/process/clear-initiator/{initiatorLogin}',
            path: {
                'initiatorLogin': initiatorLogin,
            },
            errors: {
                500: `Server error`,
            },
        });
    }

    /**
     * Notify about closed org unit requests
     * @returns any Notification was Successfully sent
     * @throws ApiError
     */
    public postCamundaProcessCloseOrgunit({
        orgUnit,
    }: {
        /**
         * Org unit code
         */
        orgUnit: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/process/close-orgunit/{org-unit}',
            path: {
                'org-unit': orgUnit,
            },
            errors: {
                500: `Server error`,
            },
        });
    }

    /**
     * Get processes linked with borrower group
     * Get all processes linked with borrower group by bpCode
     * @returns ApplicationInfoDto Successful operation
     * @throws ApiError
     */
    public getCamundaProcessLinkedWithBorrowerGroup({
        bpCode,
        excludeDefinitionIds,
        includeDefinitionIds,
    }: {
        /**
         * BP Code
         */
        bpCode: string,
        /**
         * BP Code
         */
        excludeDefinitionIds?: string,
        /**
         * BP Code
         */
        includeDefinitionIds?: string,
    }): Observable<Array<ApplicationInfoDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/linked-with-borrower-group',
            query: {
                'bpCode': bpCode,
                'excludeDefinitionIds': excludeDefinitionIds,
                'includeDefinitionIds': includeDefinitionIds,
            },
        });
    }

    /**
     * Get all processes of current employee
     * Get all processes of current employee by login and process definition key.
     * @returns ApplicationInfoDto Successful operation
     * @throws ApiError
     */
    public getCamundaProcessMyApp({
        login,
        processDefinitionKeyIn,
    }: {
        /**
         * The employee login.
         */
        login: string,
        /**
         * A comma-separated list of process definition keys to filter by
         */
        processDefinitionKeyIn: string,
    }): Observable<Array<ApplicationInfoDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/my-app',
            query: {
                'login': login,
                'processDefinitionKeyIn': processDefinitionKeyIn,
            },
        });
    }

    /**
     * Get property assessment processes
     * Get all property assessment requests for the user with the specified login and status.
     * @returns PropertyAssessmentInfo Successful operation
     * @throws ApiError
     */
    public getCamundaProcessPropertyAssessment({
        isClosed,
        login,
    }: {
        /**
         * Flag to indicate if only closed processes should be returned. Default is false.
         */
        isClosed: boolean,
        /**
         * The employee login.
         */
        login: string,
    }): Observable<Array<PropertyAssessmentInfo>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/property-assessment',
            query: {
                'isClosed': isClosed,
                'login': login,
            },
        });
    }

    /**
     * Get property assessment processes
     * Get all property assessment requests for the user with the specified login.
     * @returns PropertyAssessmentInfo Successful operation
     * @throws ApiError
     */
    public getCamundaProcessPropertyAssessmentMyApp({
        login,
    }: {
        /**
         * The employee login.
         */
        login: string,
    }): Observable<Array<PropertyAssessmentInfo>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/property-assessment/my-app',
            query: {
                'login': login,
            },
        });
    }

    /**
     * Notify if property assessment instances exist
     * Notify if there are property assessment instances for the specified SOD id and status.
     * @returns void
     * @throws ApiError
     */
    public postCamundaProcessPropertyAssessment({
        isClosed,
        sodId,
    }: {
        /**
         * Flag to indicate if only closed processes should be returned. Default is false.
         */
        isClosed: boolean,
        /**
         * The sod ID.
         */
        sodId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/process/property-assessment/{sodId}/{isClosed}',
            path: {
                'isClosed': isClosed,
                'sodId': sodId,
            },
        });
    }

    /**
     * Get assignee tasks in org-unit
     * Retrieves all tasks assigned to the user in the given organizational unit.
     * @returns TaskDto Successful operation
     * @throws ApiError
     */
    public getCamundaTaskOrgUnitAssignee({
        login,
        processDefinitionKeyIn,
    }: {
        /**
         * The employee login.
         */
        login: string,
        /**
         * A comma-separated list of process definition keys to filter by
         */
        processDefinitionKeyIn: string,
    }): Observable<Array<TaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/task/org-unit/assignee',
            query: {
                'login': login,
                'processDefinitionKeyIn': processDefinitionKeyIn,
            },
        });
    }

    /**
     * Get candidate tasks in org-unit
     * Retrieves all tasks available to the user in the given organizational unit.
     * @returns TaskDto Successful operation
     * @throws ApiError
     */
    public getCamundaTaskOrgUnitCandidate({
        login,
        processDefinitionKeyIn,
    }: {
        /**
         * The employee login.
         */
        login: string,
        /**
         * A comma-separated list of process definition keys to filter by
         */
        processDefinitionKeyIn: string,
    }): Observable<Array<TaskDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/task/org-unit/candidate',
            query: {
                'login': login,
                'processDefinitionKeyIn': processDefinitionKeyIn,
            },
        });
    }

    /**
     * Unassign active user tasks by login
     * @returns void
     * @throws ApiError
     */
    public postCamundaTaskUnassign({
        login,
    }: {
        /**
         * The employee login.
         */
        login: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/task/{login}/unassign',
            path: {
                'login': login,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Unassign candidate tasks by roles
     * Unassign all candidate tasks from user with specified roles.
     * @returns void
     * @throws ApiError
     */
    public postCamundaTaskUnassignTaskByRoles({
        login,
        requestBody,
    }: {
        /**
         * The employee login.
         */
        login: string,
        /**
         * List of name roles.
         */
        requestBody: Array<string>,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/task/{login}/unassign-task-by-roles',
            path: {
                'login': login,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get candidates for task
     * Get candidates for task by task id
     * @returns string Successful operation
     * @throws ApiError
     */
    public getCamundaTasksCandidatesTaskId({
        taskId,
    }: {
        /**
         * The task id.
         */
        taskId: string,
    }): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/tasks/candidates/taskId/{taskId}',
            path: {
                'taskId': taskId,
            },
        });
    }

    /**
     * Get property assessment in progress tasks
     * Retrieve a list of property assessment tasks that are currently in progress.
     * @returns PropertyAssessmentInfo Successful operation
     * @throws ApiError
     */
    public getCamundaTasksPropertyAssessmentInProgress({
        login,
    }: {
        /**
         * The employee login.
         */
        login: string,
    }): Observable<Array<PropertyAssessmentInfo>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/tasks/property-assessment/in-progress',
            query: {
                'login': login,
            },
        });
    }

    /**
     * Get property assessment new tasks
     * Retrieve a list of new property assessment tasks.
     * @returns PropertyAssessmentInfo Successful operation
     * @throws ApiError
     */
    public getCamundaTasksPropertyAssessmentNew({
        login,
    }: {
        /**
         * The employee login.
         */
        login: string,
    }): Observable<Array<PropertyAssessmentInfo>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/tasks/property-assessment/new',
            query: {
                'login': login,
            },
        });
    }

    /**
     * Get property assessment org unit tasks
     * Retrieve a list of property assessment tasks assigned to the user's organization unit.
     * @returns PropertyAssessmentInfo Successful operation
     * @throws ApiError
     */
    public getCamundaTasksPropertyAssessmentOrgUnit({
        login,
    }: {
        /**
         * The employee login.
         */
        login: string,
    }): Observable<Array<PropertyAssessmentInfo>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/tasks/property-assessment/org-unit',
            query: {
                'login': login,
            },
        });
    }

}
