import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAgreementsCount } from './agreements-count.selections';
import { loadAgreementsCount } from './agreements-count.actions';

@Injectable({
  providedIn: 'root',
})
export class AgreementsCountFacadeService {
  public selectAgreementsCount$ = this.store.select(selectAgreementsCount);

  constructor(private store: Store) {}

  public loadAgreementsCount(taskId: string) {
    this.store.dispatch(loadAgreementsCount({ taskId }));
  }
}
