<mat-card formGroupName="financingConditions">
  <mat-card-header>
    <mat-card-title>Умови фінансування</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <ng-container *ngIf="financingConditionsAgreementGeneralService.dealNumberSAP.value; else dealNumberSAP">
      <div class="row">
        <mat-form-field *ngIf="!financingConditionsAgreementGeneralService.isActual.value">
          <mat-label> Продукт</mat-label>
          <mat-select
            placeholder="Виберіть продукт"
            formControlName="conditionsSetId"
            (selectionChange)="onSelectChangeProduct($event)"
          >
            <mat-option *ngFor="let item of dictionariesCustomProducts | async" [value]="item.groupId">
              {{ item.productName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Номер SAP</mat-label>
          <input matInput type="text" formControlName="dealNumberSAP" placeholder="Номер SAP" />
        </mat-form-field>
        <mat-form-field>
          <mat-label> Сума ліміту</mat-label>
          <input matInput type="text" formControlName="agreementSum" placeholder="Введіть сумму ліміту" currencyMask />
        </mat-form-field>
        <mat-form-field>
          <mat-label> Валюта</mat-label>
          <mat-select placeholder="Виберіть тип валюти" formControlName="currencyCode">
            <mat-option *ngFor="let item of currenciesFacadeService.currencies$ | async" [value]="item.code">
              {{ item.definition }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field (click)="datepicker1.open()">
          <mat-label> Дата початку</mat-label>
          <input matInput [matDatepicker]="datepicker1" formControlName="startDate" />
          <mat-datepicker-toggle matIconSuffix [for]="datepicker1"></mat-datepicker-toggle>
          <mat-datepicker #datepicker1></mat-datepicker>
        </mat-form-field>

        <mat-form-field (click)="datepicker2.open()">
          <mat-label> Дата завершення</mat-label>
          <input matInput [matDatepicker]="datepicker2" formControlName="endDate" />
          <mat-datepicker-toggle matIconSuffix [for]="datepicker2"></mat-datepicker-toggle>
          <mat-datepicker #datepicker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label> Акція</mat-label>
          <mat-select placeholder="Виберіть Акцію" formControlName="promotion">
            <mat-option *ngFor="let item of promotion$ | async" [value]="item.code">
              {{ item.description }}
            </mat-option></mat-select
          >
        </mat-form-field>
      </div>
    </ng-container>

    <ng-template #dealNumberSAP>
      <div class="row">
        <mat-form-field *ngIf="!financingConditionsAgreementGeneralService.isActual.value">
          <mat-label> Продукт</mat-label>
          <mat-select
            placeholder="Виберіть продукт"
            formControlName="conditionsSetId"
            (selectionChange)="onSelectChangeProduct($event)"
          >
            <mat-option *ngFor="let item of dictionariesCustomProducts | async" [value]="item.groupId">
              {{ item.productName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="financingConditionsAgreementGeneralService.isActual.value">
          <mat-label>Продукт</mat-label>
          <input matInput type="text" formControlName="productName" placeholder="Продукт" />
        </mat-form-field>
        <mat-form-field>
          <mat-label> Сума ліміту</mat-label>
          <input matInput type="text" formControlName="agreementSum" placeholder="Введіть сумму ліміту" currencyMask />
        </mat-form-field>
        <mat-form-field>
          <mat-label> Валюта</mat-label>
          <mat-select placeholder="Виберіть тип валюти" formControlName="currencyCode">
            <mat-option *ngFor="let item of currenciesFacadeService.currencies$ | async" [value]="item.code">
              {{ item.definition }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label> Строк</mat-label>
          <input matInput sibDigitsOnly type="text" formControlName="agreementTerm" maxlength="4" placeholder="Cтрок" />
        </mat-form-field>

        <div class="row">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select formControlName="agreementTermMeasure">
              <mat-option *ngFor="let item of termsDeadlineOptions" [value]="item.name">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label> Акція</mat-label>
            <mat-select placeholder="Виберіть Акцію" formControlName="promotion">
              <mat-option *ngFor="let item of promotion$ | async" [value]="item.code">
                {{ item.description }}
              </mat-option></mat-select
            >
          </mat-form-field>
        </div>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
