import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectInitiator, selectMainInfoTable, selectOrgUnitId } from './variables.selector';
import {
  selectApplicationGroupName,
  selectApplicationStartDate,
  selectApplicationType,
  selectAutoChecks,
  selectBpClientName,
  selectBpCode,
  selectControlOptions,
  selectEstimateNumber,
  selectFilterParams,
  selectGuarantorAgreementRelation,
  selectInitiatorFullName,
  selectInitiatorId,
  selectIsFileStoreEnable,
  selectLawCases,
  selectRequestNumber,
  selectResolvableErrors,
  selectServiceUnitCode,
  selectUnitCode,
  selectVariables,
  selectVariablesInRootProcess,
} from '../../+state/variables/variables.selectors';
import { VariablesService } from '@api/loan-approval';
import { tap } from 'rxjs';
import { loadTaskV2VariablesSuccess } from '../../+state/task/task.actions';
import { VariablesState } from '../../+state/variables/variables.reducer';

@Injectable({
  providedIn: 'root',
})
export class VariablesFacadeService {
  private readonly store = inject(Store);
  private readonly variablesService = inject(VariablesService);

  getMainInfoTable$ = this.store.select(selectMainInfoTable);
  getApplicationStartDate$ = this.store.select(selectApplicationStartDate);
  getApplicationGroupName$ = this.store.select(selectApplicationGroupName);
  getRequestNumber$ = this.store.select(selectRequestNumber);
  getEstimateNumber$ = this.store.select(selectEstimateNumber);
  getResolvableErrors$ = this.store.select(selectResolvableErrors);
  selectBpCode$ = this.store.select(selectBpCode);
  selectUnitCode$ = this.store.select(selectUnitCode);
  selectServiceUnitCode$ = this.store.select(selectServiceUnitCode);
  selectVariables$ = this.store.select(selectVariables);
  getVariablesInRootProcess$ = this.store.select(selectVariablesInRootProcess);
  selectIsFileStoreEnable$ = this.store.select(selectIsFileStoreEnable);
  selectLawCases$ = this.store.select(selectLawCases);
  selectAutoChecks$ = this.store.select(selectAutoChecks);
  selectInitiatorId$ = this.store.select(selectInitiatorId);
  selectInitiator$ = this.store.select(selectInitiator);
  selectBpClientName$ = this.store.select(selectBpClientName);
  selectOrgUnitId$ = this.store.select(selectOrgUnitId);
  selectFilterParams$ = this.store.select(selectFilterParams);
  selectGuarantorAgreementRelation$ = this.store.select(selectGuarantorAgreementRelation);
  selectRequestNumber$ = this.store.select(selectRequestNumber);
  selectInitiatorFullName$ = this.store.select(selectInitiatorFullName);
  selectApplicationType$ = this.store.select(selectApplicationType);
  selectControlOptions$ = this.store.select(selectControlOptions);

  getTaskVariable(taskId: string) {
    return this.variablesService.getCamundaTaskVariables({ taskId }).pipe(tap((result) => this.setVariable(result)));
  }

  getProcessVariable(processId: string) {
    return this.variablesService
      .getCamundaProcessVariables({ processId })
      .pipe(tap((result) => this.setVariable(result)));
  }

  setVariable(response: VariablesState) {
    this.store.dispatch(
      loadTaskV2VariablesSuccess({
        variables: response,
      }),
    );
  }
}
