import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { buildParticipants } from './process-participant.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProcessParticipantFacadeService {
  private store = inject(Store);

  public processParticipants$ = this.store.select(buildParticipants);
}
