/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class HealthStatusService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get health status
     * Returns a dummy string 'OK' indicating that the service is healthy.
     * @returns string Successful operation
     * @throws ApiError
     */
    public getApiStatus(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/status',
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
