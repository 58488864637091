import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { AssetCategoryFacadeService } from '@sib/shared/store';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { getUniqueUuid, removeObjectProperty } from '@sib/shared/util';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AssetDeal } from '@api/loan-approval';

@Component({
  selector: 'sib-terms-securing-create-item-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatOptionModule,
    FormsModule,
    MatSelectModule,
    MatDatepickerModule,
  ],
  templateUrl: './terms-securing-create-item-dialog.component.html',
  styleUrls: ['./terms-securing-create-item-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsSecuringCreateItemDialogComponent implements OnInit {
  public termsSecuringCreate = new FormGroup({
    type: new FormControl<string>('providing', { nonNullable: true, validators: [Validators.required] }),
    id: new FormControl<string>(''),
    positionId: new FormControl<string>(getUniqueUuid()),
    categoryId: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    title: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    date: new FormControl<Date>(new Date(), { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    public dialogRef: MatDialogRef<TermsSecuringCreateItemDialogComponent, AssetDeal>,
    @Inject(MAT_DIALOG_DATA) public data: { categoryId: string },
    public assetCategoryFacade: AssetCategoryFacadeService,
  ) {}

  ngOnInit() {
    if (this.data.categoryId) {
      this.termsSecuringCreate.patchValue({
        type: this.data.categoryId === 'guarantor' ? 'guarantor' : 'providing',
        categoryId: this.data.categoryId,
      });
      this.termsSecuringCreate.controls.type.disable();
      this.termsSecuringCreate.controls.categoryId.disable();
    }
  }

  onSelectType() {
    if (this.termsSecuringCreate.controls.type.value === 'guarantor') {
      this.termsSecuringCreate.patchValue({
        categoryId: 'guarantor',
      });
    }
  }

  onSubmitDialog() {
    this.termsSecuringCreate.markAllAsTouched();
    if (this.termsSecuringCreate.valid) {
      this.dialogRef.close(removeObjectProperty(this.termsSecuringCreate.getRawValue(), ['type']));
    }
  }
}
