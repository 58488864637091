import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';
import { NgFor } from '@angular/common';

@Component({
  selector: 'sib-documents-akd-guarantors-dialog',
  templateUrl: './documents-akd-guarantors-dialog.component.html',
  styleUrls: ['./documents-akd-guarantors-dialog.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgFor],
})
export class DocumentsAkdGuarantorsDialogComponent implements OnInit {
  public guarantors = this.dialogRef?.data.guarantorsList;
  public guarantorFrom = new FormGroup({
    bpCode: new FormControl(null, [Validators.required]),
  });

  constructor(public dialogRef: DialogRef) {}

  ngOnInit(): void {
    if (this.dialogRef?.data.bindingCode) {
      this.guarantorFrom.patchValue({ bpCode: this.dialogRef?.data.bindingCode });
    }
  }

  onSubmitDialog() {
    if (this.guarantorFrom.valid) {
      this.dialogRef.close({ bindingCode: this.guarantorFrom.getRawValue().bpCode });
    }
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
}
