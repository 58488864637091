<mat-card formGroupName="loanRepaymentProcedure">
  <mat-card-header>
    <mat-card-title>Порядок погашення кредиту</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <p>Повернення кредиту в повному обсязі здійснюється:</p>

    <mat-radio-group formControlName="returnType">
      <ng-container *ngIf="agreementSource?.repaymentsConditionName !== 'Ануїтет'">
        <div class="row">
          <mat-radio-button value="returnDeadline"> В термін, не пізніше</mat-radio-button>
          <mat-form-field style="width: 200px" (click)="datepickerDealDateEnd2.open()" subscriptSizing="dynamic">
            <input matInput formControlName="dealDateEnd" [matDatepicker]="datepickerDealDateEnd2" />
            <mat-datepicker-toggle matIconSuffix [for]="datepickerDealDateEnd2"></mat-datepicker-toggle>
            <mat-datepicker #datepickerDealDateEnd2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="LOAN_CREDIT.includes(loanClassId || 0)">
          <mat-radio-button value="returnGPK"> В терміни, встановлені графіком погашення кредиту</mat-radio-button>
        </div>
        <div class="row" *ngIf="LOAN_CREDIT_LINE.includes(loanClassId || 0)">
          <mat-radio-button value="returnGLK">
            В терміни, встановлені графіком зниження ліміту кредитної лінії
          </mat-radio-button>
        </div>
        <div class="row" *ngIf="LOAN_CREDIT_LINE.includes(loanClassId || 0)">
          <mat-radio-button value="returnTranche">
            В терміни, встановлені в угодах про надання траншів
          </mat-radio-button>
        </div>
      </ng-container>
      <div class="row">
        <mat-radio-button
          value="returnAn"
          disabled="{{ agreementSource?.repaymentsConditionName === 'Ануїтет' ? 'disabled' : '' }}"
        >
          Сплатою ануїтентних платежів
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <div class="row">
      <mat-form-field *ngIf="agreementSource?.repaymentsConditionName === 'Ануїтет'">
        <mat-label>Розмір ануїтентних платежів</mat-label>
        <input type="text" matInput formControlName="repaymentAmount" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>День погашення</mat-label>
        <input type="number" matInput formControlName="repaymentDay" />

        <mat-error> Значення має бути в проміжку від 1 до 31 </mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
