export const agreementTabMapper = (list: { general?: boolean; id: string }[]) => [
  ...list
    .filter((agreement) => !agreement.general)
    .map((agreement, index) => ({
      id: agreement.id,
      title: `Угода`,
      agreementNumber: index + 1,
    })),
  ...list
    .filter((agreement) => agreement.general)
    .map((agreement, index) => ({
      id: agreement.id,
      title: `Ген. Угода`,
      agreementNumber: index + 1,
    })),
];
