import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectReportById } from './reports.selectors';

@Injectable({
  providedIn: 'root',
})
export class ReportFacadeService {
  public currentReportPage$ = (reportId: string) => this.store.select(selectReportById(reportId));

  constructor(private store: Store) {}
}
