/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConditionTypeDto } from '../models/ConditionTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ConditionTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all condition types
     * Returns all condition types.
     * @returns ConditionTypeDto all condition types
     * @throws ApiError
     */
    public getApiConditionTypes(): Observable<Array<ConditionTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-types',
        });
    }

    /**
     * Create condition type
     * Method for creating an condition type.
     * @returns ConditionTypeDto condition type created
     * @throws ApiError
     */
    public postApiConditionTypes({
        requestBody,
    }: {
        /**
         * Condition type object that needs to be created.
         */
        requestBody: ConditionTypeDto,
    }): Observable<ConditionTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/condition-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get condition type
     * Get condition type by ID.
     * @returns any condition type found
     * @throws ApiError
     */
    public getApiConditionTypes1({
        id,
    }: {
        /**
         * Condition type ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/condition-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `condition type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update condition type
     * Updates the condition type with the given ID.
     * @returns any condition type updated
     * @throws ApiError
     */
    public putApiConditionTypes({
        id,
        requestBody,
    }: {
        /**
         * Condition type ID. Cannot be empty.
         */
        id: number,
        /**
         * Condition type object that needs to be updated.
         */
        requestBody: ConditionTypeDto,
    }): Observable<{
        id: number;
        title?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/condition-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete condition type
     * Deletes the condition type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiConditionTypes({
        id,
    }: {
        /**
         * Condition type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/condition-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
