/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { PlaceHolderDto } from '../models/PlaceHolderDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TemplateService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Format a template
     * Method for formatting a template.
     * @returns any Successful operation
     * @throws ApiError
     */
    public postApiTemplate({
        id,
        requestBody,
    }: {
        /**
         * Template ID
         */
        id: number,
        /**
         * Placeholders
         */
        requestBody: Array<PlaceHolderDto>,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/template/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Template not found`,
            },
        });
    }

    /**
     * Get a list of template placeholders
     * Method for obtaining a list of template placeholders.
     * @returns PlaceHolderDto Successful operation
     * @throws ApiError
     */
    public getApiTemplatePlaceholders({
        id,
    }: {
        /**
         * Template ID
         */
        id: number,
    }): Observable<Array<PlaceHolderDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/template/{id}/placeholders',
            path: {
                'id': id,
            },
            errors: {
                404: `Template not found`,
            },
        });
    }

}
