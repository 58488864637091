/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EmployeeCustomDto } from '../models/EmployeeCustomDto';
import type { EmployeeDto } from '../models/EmployeeDto';
import type { EmployeeIdentityProviderDto } from '../models/EmployeeIdentityProviderDto';
import type { OrgUnitDto } from '../models/OrgUnitDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class EmployeeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all employees
     * Get a list of all employees or an empty list if none exist
     * @returns EmployeeDto List of employees or empty list
     * @throws ApiError
     */
    public getApiEmployees(): Observable<Array<EmployeeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees',
        });
    }

    /**
     * Create a new employee
     * Create a new employee with the provided data
     * @returns EmployeeDto Employee created successfully
     * @throws ApiError
     */
    public postApiEmployees({
        requestBody,
    }: {
        /**
         * Asset category object that needs to be added.
         */
        requestBody: EmployeeDto,
    }): Observable<EmployeeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/employees',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Employee with such id already exists`,
            },
        });
    }

    /**
     * Get employees by parameter
     * Returns a list of employees filtered by the specified parameter.
     * @returns EmployeeIdentityProviderDto List of employees or empty list
     * @throws ApiError
     */
    public getApiEmployeesEmployeeByParam({
        direction,
        email,
        emailLike,
        firstName,
        firstNameLike,
        groupId,
        login,
        logins,
        orderedProperty,
        propertyName,
        surname,
        surnameLike,
        tenantId,
    }: {
        direction?: string,
        email?: string,
        emailLike?: string,
        firstName?: string,
        firstNameLike?: string,
        groupId?: string,
        login?: string,
        logins?: string,
        orderedProperty?: boolean,
        propertyName?: string,
        surname?: string,
        surnameLike?: string,
        tenantId?: string,
    }): Observable<Array<EmployeeIdentityProviderDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/employee-by-param',
            query: {
                'direction': direction,
                'email': email,
                'emailLike': emailLike,
                'firstName': firstName,
                'firstNameLike': firstNameLike,
                'groupId': groupId,
                'login': login,
                'logins': logins,
                'orderedProperty': orderedProperty,
                'propertyName': propertyName,
                'surname': surname,
                'surnameLike': surnameLike,
                'tenantId': tenantId,
            },
        });
    }

    /**
     * Get an employee by login
     * Get an employee by login
     * @returns EmployeeDto Employee with such login exists
     * @throws ApiError
     */
    public getApiEmployeesLogin({
        login,
    }: {
        /**
         * Login of the employee to be obtained. Cannot be empty.
         */
        login: string,
    }): Observable<EmployeeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/login/{login}',
            path: {
                'login': login,
            },
            errors: {
                404: `Employee with such login not found`,
            },
        });
    }

    /**
     * Get the full name of an employee by their login
     * Returns the full name of an employee identified by their login.
     * @returns string Employee with such login exists
     * @throws ApiError
     */
    public getApiEmployeesLoginFullName({
        login,
    }: {
        /**
         * Login of the employee. Cannot be empty.
         */
        login: string,
    }): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/login/{login}/full-name',
            path: {
                'login': login,
            },
            errors: {
                404: `Employee with such login not found`,
            },
        });
    }

    /**
     * Get the organizational unit of an employee by their login
     * Returns the organizational unit of an employee identified by their login.
     * @returns OrgUnitDto OrgUnit
     * @throws ApiError
     */
    public getApiEmployeesLoginOrgUnit({
        login,
    }: {
        /**
         * The login of the employee. Cannot be empty.
         */
        login: string,
    }): Observable<OrgUnitDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/login/{login}/org-unit',
            path: {
                'login': login,
            },
            errors: {
                404: `Nothing`,
            },
        });
    }

    /**
     * Get a list of employees with the specified role in the specified units
     * Returns a list of employees with the specified role in the specified organizational units.
     * @returns EmployeeDto List of employees or empty list
     * @throws ApiError
     */
    public getApiEmployeesRole({
        roleId,
        orgUnit,
    }: {
        /**
         * The ID of the role to search for. Cannot be empty.
         */
        roleId: string,
        /**
         * The codes of the organizational units to filter by. Cannot be empty.
         */
        orgUnit: Array<number>,
    }): Observable<Array<EmployeeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/role/{role-id}',
            path: {
                'role-id': roleId,
            },
            query: {
                'org-unit': orgUnit,
            },
        });
    }

    /**
     * Get a list of employees with the specified roles
     * Returns a list of employees with the specified roles, optionally filtered by region.
     * @returns EmployeeDto List of employees
     * @throws ApiError
     */
    public getApiEmployeesRoles({
        regionId,
        roleId,
    }: {
        /**
         * The ID of the region to filter by.
         */
        regionId?: number,
        /**
         * The IDs of the roles to search for.
         */
        roleId?: Array<string>,
    }): Observable<Array<EmployeeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/roles',
            query: {
                'region-id': regionId,
                'role-id': roleId,
            },
        });
    }

    /**
     * Get a set of employees in the specified unit
     * Returns a set of employees in the specified organizational unit.
     * @returns EmployeeDto List of employees or empty list
     * @throws ApiError
     */
    public getApiEmployeesUnitCode({
        unitCode,
    }: {
        /**
         * The code of the organizational unit to search for. Cannot be empty.
         */
        unitCode: number,
    }): Observable<Array<EmployeeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/unit-code/{unitCode}',
            path: {
                'unitCode': unitCode,
            },
        });
    }

    /**
     * Get an employee by ID
     * Get an employee by ID
     * @returns EmployeeDto Employee exists
     * @throws ApiError
     */
    public getApiEmployees1({
        id,
    }: {
        /**
         * ID of the employee to be obtained. Cannot be empty.
         */
        id: number,
    }): Observable<EmployeeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Employee not found`,
            },
        });
    }

    /**
     * Delete an employee by ID
     * Delete an employee by ID
     * @returns void
     * @throws ApiError
     */
    public deleteApiEmployees({
        id,
    }: {
        /**
         * The ID of the employee to delete. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/employees/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get employee customization by id
     * Returns employee customization for the specified employee id.
     * @returns EmployeeCustomDto Custom exists
     * @throws ApiError
     */
    public getApiEmployeesCustom({
        id,
    }: {
        /**
         * ID of the employee. Cannot be empty.
         */
        id: number,
    }): Observable<EmployeeCustomDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/employees/{id}/custom',
            path: {
                'id': id,
            },
            errors: {
                404: `Employee customization not found`,
            },
        });
    }

    /**
     * Update employee customization by id
     * Updates employee customization for the specified employee id.
     * @returns EmployeeCustomDto Custom exists
     * @throws ApiError
     */
    public putApiEmployeesCustom({
        id,
        requestBody,
    }: {
        /**
         * ID of the employee. Cannot be empty.
         */
        id: number,
        /**
         * Employee customization data.
         */
        requestBody: EmployeeCustomDto,
    }): Observable<EmployeeCustomDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/employees/{id}/custom',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Employee customization not found`,
            },
        });
    }

    /**
     * Add employee customization
     * Adds employee customization for the specified employee id.
     * @returns EmployeeCustomDto Custom exists
     * @throws ApiError
     */
    public postApiEmployeesCustom({
        id,
        requestBody,
    }: {
        /**
         * ID of the employee. Cannot be empty.
         */
        id: number,
        /**
         * Employee customization data.
         */
        requestBody: EmployeeCustomDto,
    }): Observable<EmployeeCustomDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/employees/{id}/custom',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Employee customization not found`,
            },
        });
    }

    /**
     * Delete employee customization data.
     * Deletes employee customization data with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiEmployeesCustom({
        id,
    }: {
        /**
         * ID of the employee. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/employees/{id}/custom',
            path: {
                'id': id,
            },
            errors: {
                404: `Employee customization not found`,
            },
        });
    }

}
