import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[sibStopPropagation]',
  standalone: true,
})
export class StopPropagationDirective {
  @HostListener('click', ['$event'])
  @HostListener('dblclick', ['$event'])
  @HostListener('keydown', ['$event'])
  onEvent(event: Event) {
    event.stopPropagation();
  }
}
