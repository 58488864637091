import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from '../../../../libs/shared/store/src/lib/shared-state';
import { format } from 'date-fns';
import { CommonModule, JsonPipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatExpansionModule } from '@angular/material/expansion';
import { environment, getUserRoles } from '@sib/shared/util';
import { ReportRoleDto } from '@api/dictionaries';
import { UiFacadeService } from '@app/core/store/facades/ui.facade.service';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { MatButtonModule } from '@angular/material/button';
import { TasksFacadeService } from '@app/core/store/tasks-facade.service';

@Component({
  selector: 'sib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgxPermissionsModule,
    MatExpansionModule,
    MtxPopoverModule,
    MatButtonModule,
    JsonPipe,
  ],
})
export class SidebarComponent implements OnInit {
  public tasksFacadeService = inject(TasksFacadeService);
  public readonly version = `${environment.hash} - ${format(new Date(environment.timestamp), 'dd.MM.yy HH:mm')}`;

  public get timeInfo() {
    const timeInfo = Intl.DateTimeFormat().resolvedOptions();
    return { timezone: timeInfo.timeZone, calendar: timeInfo.calendar };
  }

  public sidebarType = 'normal';
  public reportsList$ = this.store.select(fromShared.selectAllReports);
  @Output() getSidebarType = new EventEmitter();

  constructor(public router: Router, private store: Store, public uiFacadeService: UiFacadeService) {}

  ngOnInit(): void {
    this.store.dispatch(fromShared.loadReports());
    this.store.dispatch(fromShared.loadDictionaries());
    this.store.dispatch(fromShared.loadDictionariesOrgstructure());
    this.store.dispatch(fromShared.loadDictionaryGroups());
  }

  checkIfUserCanViewReport(reportRoles: ReportRoleDto[] | undefined) {
    const userRoles = getUserRoles();

    return reportRoles?.some((reportRole: ReportRoleDto) =>
      userRoles.some((userRole: string) => reportRole.roleId === userRole),
    );
  }

  changeSidebarState() {
    if (this.sidebarType === 'normal') {
      this.sidebarType = 'small';
    } else {
      this.sidebarType = 'normal';
    }

    this.getSidebarType.emit(this.sidebarType);
  }

  triggerAccordion(event: any) {
    const target = event.target;
    const content = target.nextElementSibling;
    const parent = target.parentElement;
    const contentItems = event.target.querySelectorAll('.accordion-content.active');
    const targetItems = event.target.parentNode.querySelectorAll('.accordion-title.active');
    const parentItems = event.target.parentNode.querySelectorAll('.accordion-item-container.active');

    function removeClass(elements: any, className: any) {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i]) {
          elements[i].classList.remove(className);
        }
      }
    }

    function toggleClass(elements: any, className: any) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.toggle(className);
      }
    }

    if (target.classList) {
      if (!target.classList.contains('accordion-title')) {
        return;
      }
      event.preventDefault();

      if (!content) {
        return;
      }

      if (target.classList.contains('active')) {
        removeClass([content, target, parent], 'active');
        for (let i = 0; i < targetItems.length; i++) {
          removeClass([contentItems[i], targetItems[i], parentItems[i]], 'active');
        }
        return;
      }
      toggleClass([content, target, parent], 'active');
    }
  }
}
