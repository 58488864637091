/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { PlaceHolderDto } from './models/PlaceHolderDto';

export { BuildVersionService } from './services/BuildVersionService';
export { HealthStatusService } from './services/HealthStatusService';
export { TemplateService } from './services/TemplateService';
