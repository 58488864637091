<form [formGroup]="form">
  <mat-form-field>
    <mat-label> Код БП </mat-label>
    <input
      matInput
      type="text"
      inputmode="numeric"
      digitOnly
      maxlength="10"
      [decimal]="true"
      formControlName="bpCode"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label> Назва БП </mat-label>
    <input matInput type="text" formControlName="orgName" />
  </mat-form-field>
  <mat-form-field>
    <mat-label> Адреса </mat-label>
    <textarea matInput type="text" formControlName="address"></textarea>
  </mat-form-field>
  <mat-form-field>
    <mat-label> Код ЄДРПОУ/ІПН </mat-label>
    <input matInput type="text" formControlName="idNumber" />
  </mat-form-field>
  <mat-form-field>
    <mat-label> Дата реєстрації/Дата народження </mat-label>

    <input matInput formControlName="dateOfFoundation" [matDatepicker]="datepicker" />
    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
</form>
