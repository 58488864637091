/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConsiderationLevelDto } from '../models/ConsiderationLevelDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ConsLevelService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all consideration levels
     * Returns all consideration levels.
     * @returns ConsiderationLevelDto all consideration levels
     * @throws ApiError
     */
    public getApiConsiderationLevels(): Observable<Array<ConsiderationLevelDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/consideration-levels',
        });
    }

    /**
     * Create consideration level
     * Method for creating an consideration level.
     * @returns ConsiderationLevelDto consideration level created
     * @throws ApiError
     */
    public postApiConsiderationLevels({
        requestBody,
    }: {
        /**
         * Consideration level object that needs to be created.
         */
        requestBody: ConsiderationLevelDto,
    }): Observable<ConsiderationLevelDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/consideration-levels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get consideration level
     * Get consideration level by ID.
     * @returns any consideration level found
     * @throws ApiError
     */
    public getApiConsiderationLevels1({
        id,
    }: {
        /**
         * Consideration level ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        levelTitle?: string;
        levelTitleLong?: string;
        protTitle?: string;
        decisions?: Array<string>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/consideration-levels/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `consideration level not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update consideration level
     * Updates the consideration level with the given ID.
     * @returns any consideration level updated
     * @throws ApiError
     */
    public putApiConsiderationLevels({
        id,
        requestBody,
    }: {
        /**
         * Consideration level ID. Cannot be empty.
         */
        id: number,
        /**
         * Consideration level object that needs to be updated.
         */
        requestBody: ConsiderationLevelDto,
    }): Observable<{
        id: string;
        levelTitle?: string;
        levelTitleLong?: string;
        protTitle?: string;
        decisions?: Array<string>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/consideration-levels/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete consideration level
     * Deletes the consideration level with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiConsiderationLevels({
        id,
    }: {
        /**
         * Consideration level ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/consideration-levels/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
