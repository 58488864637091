import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import * as MonitoringResults from './monitoring-results.actions';
import * as Selectors from './monitoring-results.selectors';

@Injectable()
export class MonitoringResultsEffects {
  monitoringResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringResults.loadMonitoringResults),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectMonitoringResults)]),
      switchMap(([action, all]) =>
        (all.length ? of(all) : this.dictionariesService.loadMonitoringResults()).pipe(
          map((response) =>
            MonitoringResults.loadMonitoringResultsSuccess({
              monitoringResults: response,
            }),
          ),
          catchError((error) => of(MonitoringResults.loadMonitoringResultsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
