import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadSOD, selectSOD } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class SodsFacadeService {
  public SOD$ = this.store.select(selectSOD);
  constructor(private store: Store) {}

  public loadSods() {
    this.store.dispatch(loadSOD());
  }
}
