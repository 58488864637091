/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AcdResponse } from './models/AcdResponse';
export type { Address } from './models/Address';
export type { Agreement } from './models/Agreement';
export type { AgreementCommission } from './models/AgreementCommission';
export type { AgreementGuaranty } from './models/AgreementGuaranty';
export type { AgreementGuarantyAsset } from './models/AgreementGuarantyAsset';
export type { AgreementGuarantyDto } from './models/AgreementGuarantyDto';
export type { AgreementParametersDto } from './models/AgreementParametersDto';
export type { AgreementRequestDto } from './models/AgreementRequestDto';
export type { AksUploadData } from './models/AksUploadData';
export type { AppData } from './models/AppData';
export type { ApplicationInfoDto } from './models/ApplicationInfoDto';
export type { ApplicationsDataDto } from './models/ApplicationsDataDto';
export type { ApplicationStatisticDto } from './models/ApplicationStatisticDto';
export type { ApplicationStatisticParamsDto } from './models/ApplicationStatisticParamsDto';
export type { Asset } from './models/Asset';
export type { AssetAgreementRelation } from './models/AssetAgreementRelation';
export type { AssetDeal } from './models/AssetDeal';
export type { AssetsAndAgreementGuarantyDto } from './models/AssetsAndAgreementGuarantyDto';
export type { AssociatedDocumentsDto } from './models/AssociatedDocumentsDto';
export type { AutoCheck } from './models/AutoCheck';
export type { AutoCheckRunSetParameters } from './models/AutoCheckRunSetParameters';
export type { AutoCheckRunSetParametersItem } from './models/AutoCheckRunSetParametersItem';
export type { AutoCheckRunSetRequestDto } from './models/AutoCheckRunSetRequestDto';
export type { AutoCheckRunSetResponseDto } from './models/AutoCheckRunSetResponseDto';
export type { AutoCheckSetForProductDto } from './models/AutoCheckSetForProductDto';
export type { AutoCheckSetResultDto } from './models/AutoCheckSetResultDto';
export type { AutoCheckSetResultLogDto } from './models/AutoCheckSetResultLogDto';
export type { BailAgreementRequestDto } from './models/BailAgreementRequestDto';
export type { BailContract } from './models/BailContract';
export type { BeneficiaryDocOperation } from './models/BeneficiaryDocOperation';
export type { BorrowerGroup } from './models/BorrowerGroup';
export type { BpSapError } from './models/BpSapError';
export type { BPSapModel } from './models/BPSapModel';
export type { BpShortInfoDto } from './models/BpShortInfoDto';
export type { BusnSegment } from './models/BusnSegment';
export type { CashFlowZKR } from './models/CashFlowZKR';
export type { CheckListFormingResultDto } from './models/CheckListFormingResultDto';
export type { CheckListFormingStatus } from './models/CheckListFormingStatus';
export type { CheckListItem } from './models/CheckListItem';
export type { CheckListItemDto } from './models/CheckListItemDto';
export type { CheckListItemResultDto } from './models/CheckListItemResultDto';
export type { CheckListResponseDto } from './models/CheckListResponseDto';
export type { ClientCredit } from './models/ClientCredit';
export type { ClientPartnerSetResult } from './models/ClientPartnerSetResult';
export type { Commission } from './models/Commission';
export type { Condition } from './models/Condition';
export type { Controller } from './models/Controller';
export type { CurrencyRate } from './models/CurrencyRate';
export type { CurrentApplicationsStatusReportParameters } from './models/CurrentApplicationsStatusReportParameters';
export type { CurrentApplicationStatusReportData } from './models/CurrentApplicationStatusReportData';
export type { Deal } from './models/Deal';
export type { DeferredConditions } from './models/DeferredConditions';
export type { Deposit } from './models/Deposit';
export type { DigitalSignature } from './models/DigitalSignature';
export type { DocumentaryOperation } from './models/DocumentaryOperation';
export type { DocumentEntry } from './models/DocumentEntry';
export type { Equipment } from './models/Equipment';
export type { ErrMessage } from './models/ErrMessage';
export type { ErrorMessage } from './models/ErrorMessage';
export type { ErrorType } from './models/ErrorType';
export type { FileStoreMultipart } from './models/FileStoreMultipart';
export type { GenDeal } from './models/GenDeal';
export type { GenType } from './models/GenType';
export type { Good } from './models/Good';
export type { GroupLimitDto } from './models/GroupLimitDto';
export type { GroupLimitItemDto } from './models/GroupLimitItemDto';
export type { GuaranteeCategory } from './models/GuaranteeCategory';
export type { Guarantor } from './models/Guarantor';
export type { GuarantorAgreementRelation } from './models/GuarantorAgreementRelation';
export type { HumanTaskHistoryDto } from './models/HumanTaskHistoryDto';
export type { IncludeToDealsLimitsCalculationDto } from './models/IncludeToDealsLimitsCalculationDto';
export type { Land } from './models/Land';
export type { LandDocument } from './models/LandDocument';
export type { LawCases } from './models/LawCases';
export type { LimitByCurrencyDto } from './models/LimitByCurrencyDto';
export type { LimitCalculationDto } from './models/LimitCalculationDto';
export type { LimitDeadlineTermMeasure } from './models/LimitDeadlineTermMeasure';
export type { LoanAccountRequestDto } from './models/LoanAccountRequestDto';
export type { LoanCommissionRequestDto } from './models/LoanCommissionRequestDto';
export type { LoanDeferConditionRequestDto } from './models/LoanDeferConditionRequestDto';
export type { LoanFlowScheduleRequestDto } from './models/LoanFlowScheduleRequestDto';
export type { NestedAssociatedTypeDoc } from './models/NestedAssociatedTypeDoc';
export type { PartnerAccount } from './models/PartnerAccount';
export type { PartnerSpouseData } from './models/PartnerSpouseData';
export type { PaymentScheduleEntryRequestDto } from './models/PaymentScheduleEntryRequestDto';
export type { PeriodFilter } from './models/PeriodFilter';
export type { PledgeAgreementsType } from './models/PledgeAgreementsType';
export type { ProcessParticipant } from './models/ProcessParticipant';
export type { ProductCategoryTypeCode } from './models/ProductCategoryTypeCode';
export type { PropertyAssessmentInfo } from './models/PropertyAssessmentInfo';
export type { PropertyOwner } from './models/PropertyOwner';
export type { PropertyRightDocument } from './models/PropertyRightDocument';
export type { ProtDateDto } from './models/ProtDateDto';
export type { ProtocolCC } from './models/ProtocolCC';
export type { Realty } from './models/Realty';
export type { RepaymentScheduleDto } from './models/RepaymentScheduleDto';
export type { RepaymentScheduleEntry } from './models/RepaymentScheduleEntry';
export type { ResponseParams } from './models/ResponseParams';
export type { SapParamsDto } from './models/SapParamsDto';
export type { Signator } from './models/Signator';
export type { Spouse } from './models/Spouse';
export type { StatisticData } from './models/StatisticData';
export type { SumSpellSet } from './models/SumSpellSet';
export type { TaskChecksErrorEntity } from './models/TaskChecksErrorEntity';
export type { TaskCountDto } from './models/TaskCountDto';
export type { TaskDto } from './models/TaskDto';
export type { TermMeasure } from './models/TermMeasure';
export type { UpdateGrrDto } from './models/UpdateGrrDto';
export type { UploadFailure } from './models/UploadFailure';
export type { Using } from './models/Using';
export type { ValuationParameters } from './models/ValuationParameters';
export type { VariablesDto } from './models/VariablesDto';
export type { Vehicle } from './models/Vehicle';
export type { VotingComment } from './models/VotingComment';
export type { VotingResults } from './models/VotingResults';
export type { WeekDetail } from './models/WeekDetail';
export type { WorkDay } from './models/WorkDay';

export { AgreementService } from './services/AgreementService';
export { AksIntegrationService } from './services/AksIntegrationService';
export { ApplicationStatisticService } from './services/ApplicationStatisticService';
export { AssociatedDocumentService } from './services/AssociatedDocumentService';
export { BorrowerGroupService } from './services/BorrowerGroupService';
export { BuildVersionService } from './services/BuildVersionService';
export { CamundaService } from './services/CamundaService';
export { CheckListService } from './services/CheckListService';
export { CurrencyRateService } from './services/CurrencyRateService';
export { DefaultService } from './services/DefaultService';
export { FileStoreService } from './services/FileStoreService';
export { GroupLimitService } from './services/GroupLimitService';
export { GuarantorService } from './services/GuarantorService';
export { HealthStatusService } from './services/HealthStatusService';
export { PrintedFormService } from './services/PrintedFormService';
export { ReportService } from './services/ReportService';
export { SapAutoCheckService } from './services/SapAutoCheckService';
export { SapIntegrationService } from './services/SapIntegrationService';
export { TaskService } from './services/TaskService';
export { TaskChecksService } from './services/TaskChecksService';
export { TechService } from './services/TechService';
export { VariablesService } from './services/VariablesService';
