import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as CurrenciesActions from './currencies.actions';
import { CurrencyDto } from '@api/dictionaries';

export const currenciesFeatureKey = 'currencies';

export interface State extends EntityState<CurrencyDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<CurrencyDto>({
  selectId: (currency) => currency.code,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(CurrenciesActions.loadCurrencies, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(CurrenciesActions.loadCurrenciesSuccess, (state, { currencies }) => ({
    ...adapter.setAll(currencies, state),
    isLoading: false,
  })),

  on(CurrenciesActions.loadCurrenciesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
