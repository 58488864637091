import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectAllErrors } from './errors.selectors';
import { loadErrors } from './errors.actions';

@Injectable({
  providedIn: 'root',
})
export class ErrorsFacadeService {
  public errorCodes$ = this.store.select(selectAllErrors).pipe(filter(Boolean));

  constructor(private store: Store) {}

  public loadErrors() {
    this.store.dispatch(loadErrors());
  }
}
