/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ErrorDto } from '../models/ErrorDto';
import type { ErrorMessageTypeDto } from '../models/ErrorMessageTypeDto';
import type { ProcessErrorDto } from '../models/ProcessErrorDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ErrorsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all errors
     * Returns all errors.
     * @returns ErrorDto all errors
     * @throws ApiError
     */
    public getApiErrors({
        message,
    }: {
        message?: string,
    }): Observable<Array<ErrorDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/errors',
            query: {
                'message': message,
            },
        });
    }

    /**
     * Create error
     * Method for creating error.
     * @returns ErrorDto error created
     * @throws ApiError
     */
    public postApiErrors({
        requestBody,
    }: {
        /**
         * Error object that needs to be created.
         */
        requestBody: ErrorDto,
    }): Observable<ErrorDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/errors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get all error message types
     * Returns all error message types by text.
     * @returns ErrorMessageTypeDto all error message types
     * @throws ApiError
     */
    public getApiErrorsMessageTypes({
        text,
    }: {
        /**
         * Error message type text. Cannot be empty.
         */
        text?: string,
    }): Observable<Array<ErrorMessageTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/errors/message-types',
            query: {
                'text': text,
            },
        });
    }

    /**
     * Create error message type
     * Method for creating error message type.
     * @returns ErrorMessageTypeDto error message type created
     * @throws ApiError
     */
    public postApiErrorsMessageTypes({
        requestBody,
    }: {
        /**
         * Error message type object that needs to be created.
         */
        requestBody: ErrorMessageTypeDto,
    }): Observable<ErrorMessageTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/errors/message-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get error message type
     * Get error message type by ID.
     * @returns any error message type found
     * @throws ApiError
     */
    public getApiErrorsMessageTypes1({
        id,
    }: {
        /**
         * Error message type ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        text?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/errors/message-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `error message type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update error message type
     * Updates the error message type with the given ID.
     * @returns any error message type updated
     * @throws ApiError
     */
    public putApiErrorsMessageTypes({
        id,
        requestBody,
    }: {
        /**
         * Error message type ID. Cannot be empty.
         */
        id: string,
        /**
         * Error message type object that needs to be created.
         */
        requestBody: ErrorMessageTypeDto,
    }): Observable<{
        id: string;
        text?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/errors/message-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete error message type
     * Deletes the error message type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiErrorsMessageTypes({
        id,
    }: {
        /**
         * Error message type ID. Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/errors/message-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get all process error
     * Returns all process error by process ID, errorID.
     * @returns ProcessErrorDto all process error
     * @throws ApiError
     */
    public getApiErrorsProcesses({
        errorId,
        processId,
    }: {
        /**
         * Error ID. Cannot be empty.
         */
        errorId?: number,
        /**
         * Process ID. Cannot be empty.
         */
        processId?: string,
    }): Observable<Array<ProcessErrorDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/errors/processes',
            query: {
                'errorId': errorId,
                'processId': processId,
            },
        });
    }

    /**
     * Create process error
     * Method for creating an process error.
     * @returns ProcessErrorDto process error created
     * @throws ApiError
     */
    public postApiErrorsProcesses({
        requestBody,
    }: {
        /**
         * Process error object that needs to be created.
         */
        requestBody: ProcessErrorDto,
    }): Observable<ProcessErrorDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/errors/processes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get process error
     * Get process error by ID.
     * @returns any process error found
     * @throws ApiError
     */
    public getApiErrorsProcesses1({
        id,
    }: {
        /**
         * Process error ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        processId?: string;
        stepId?: string;
        errorId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/errors/processes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `process error not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update process error
     * Updates the process error with the given ID.
     * @returns any process error updated
     * @throws ApiError
     */
    public putApiErrorsProcesses({
        id,
        requestBody,
    }: {
        /**
         * Process error ID. Cannot be empty.
         */
        id: number,
        /**
         * Process error object that needs to be updated.
         */
        requestBody: ProcessErrorDto,
    }): Observable<{
        id: number;
        processId?: string;
        stepId?: string;
        errorId?: number;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/errors/processes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete process error
     * Deletes the process error with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiErrorsProcesses({
        id,
    }: {
        /**
         * Process error ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/errors/processes/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get error
     * Get error by ID.
     * @returns any error found
     * @throws ApiError
     */
    public getApiErrors1({
        id,
    }: {
        /**
         * Error ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        message?: string;
        active?: boolean;
        processErrorList?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/errors/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `error not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update error
     * Updates the error with the given ID.
     * @returns any error updated
     * @throws ApiError
     */
    public putApiErrors({
        id,
        requestBody,
    }: {
        /**
         * Error ID. Cannot be empty.
         */
        id: number,
        /**
         * Error object that needs to be updated.
         */
        requestBody: ErrorDto,
    }): Observable<{
        id: number;
        message?: string;
        active?: boolean;
        processErrorList?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/errors/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete error
     * Deletes the error with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiErrors({
        id,
    }: {
        /**
         * Error ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/errors/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
