import { createAction, props } from '@ngrx/store';
import { AssessmentApproachDto } from '@api/dictionaries';

export const loadAssessmentApproaches = createAction('[Dictionaries/API] Load Assessment Approaches');
export const loadAssessmentApproachesSuccess = createAction(
  '[Dictionaries/API] Load Assessment Approaches Success',
  props<{ assessmentApproaches: AssessmentApproachDto[] }>(),
);
export const loadAssessmentApproachesFailure = createAction(
  '[Dictionaries/API] Load Assessment Approaches Failure',
  props<{ error: unknown }>(),
);
