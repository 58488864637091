import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class PledgerContractGroupService extends FormGroup<ReturnType<typeof PledgerContractGroupService.formGroup>> {
  private static readonly formGroup = () => ({
    partnerName: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ), // назва-піб (називний відмінок)
    ownerBpCode: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    fop: new FormControl<boolean>(false, { nonNullable: true }),
    ownSign: new FormControl<boolean>(false, { nonNullable: true }), // самостійно підписує договір
    bpNumber: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    partnerNameSign: new FormControl<string>('', { nonNullable: true }),
    agent: new FormControl<boolean>(false, { nonNullable: true }), // представник - посадова особа
    position: new FormControl<string>('', { nonNullable: true }), // посада
    doc: new FormControl<string>('', { nonNullable: true }), // посада
  });

  public get ownSign() {
    return this.controls.ownSign;
  }

  private get agent() {
    return this.controls.agent;
  }

  public get bpNumber() {
    return this.controls.bpNumber;
  }

  private get partnerName() {
    return this.controls.partnerName;
  }

  private get partnerNameSign() {
    return this.controls.partnerNameSign;
  }

  private get position() {
    return this.controls.position;
  }

  private get doc() {
    return this.controls.doc;
  }

  private get ownerBpCode() {
    return this.controls.ownerBpCode;
  }

  constructor() {
    super(PledgerContractGroupService.formGroup());

    this.ownSign.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (ownSign) => {
        if (this.ownSign.status === 'DISABLED') return;
        if (ownSign) {
          this.bpNumber.disable();
          this.partnerNameSign.disable();
          this.bpNumber.setValue(this.ownerBpCode.value);
          this.partnerNameSign.setValue(this.partnerName.value);
        } else {
          this.bpNumber.enable();
          this.partnerNameSign.enable();
          this.bpNumber.setValue('');
          this.partnerNameSign.setValue('');
        }
      },
    });

    this.agent.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (isAgent) => {
        if (this.agent.status === 'DISABLED') return;
        if (isAgent) {
          this.position.addValidators(Validators.required);
          this.doc.addValidators(Validators.required);
        } else {
          this.position.removeValidators(Validators.required);
          this.doc.removeValidators(Validators.required);
        }
        this.position.updateValueAndValidity();
        this.doc.updateValueAndValidity();
      },
    });
  }
}
