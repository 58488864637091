import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

export interface InfoTable {
  name: string;
  value: string | number;
}

@Component({
  selector: 'sib-record-table',
  standalone: true,
  imports: [CommonModule, MatTableModule],
  templateUrl: './record-table.component.html',
  styleUrls: ['./record-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordTableComponent {
  public matTableDataSource = new MatTableDataSource<InfoTable>([]);

  @Input()
  public set datasource(datasource: InfoTable[]) {
    this.matTableDataSource.data = datasource;
  }

  trackByName(index: number, item: { name: string }) {
    return item.name;
  }
}
