import { createSelector } from '@ngrx/store';
import { selectAgreementEntities, selectAllAgreements } from '../../+state/agreements/agreement.selectors';
import { selectAllGuarantors, selectGuarantorEntities } from '../../+state/guarantors/guarantors.selectors';
import { selectGuarantorAgreementRelation } from '../../+state/variables/variables.selectors';
import {
  selectAllBailContracts,
  selectBailContractEntities,
} from '../../+state/bail-contracts/bail-contracts.selectors';
import { selectRouterParam } from '@sib/shared/store';

export const selectActiveGuarantorIdByRouteParam = selectRouterParam('guarantorId');

export const selectGuarantorByAgreementId = (id?: string) =>
  createSelector(
    selectAgreementEntities,
    selectGuarantorEntities,
    selectAllGuarantors,
    selectGuarantorAgreementRelation,
    (agreements, guarantorEntities, guarantors, relations) => {
      if (id) {
        const filteredRelations = relations.filter((relation) => relation.dealNumber === agreements[id]?.dealNumber);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return filteredRelations.map((item) => guarantorEntities[item.bpCode]!).filter(Boolean);
      }

      return guarantors;
    },
  );

export const selectCurrentGuarantor = createSelector(
  selectGuarantorEntities,
  selectActiveGuarantorIdByRouteParam,
  (guarantorList, activeGuarantorBpCode: string) => guarantorList[activeGuarantorBpCode],
);

export const selectBailContracts = createSelector(
  selectAllAgreements,
  selectAllBailContracts,
  selectGuarantorAgreementRelation,
  (agreements, bailContracts, relations) =>
    bailContracts.map((bailContract) => {
      const agreementId = agreements.find(
        (i) => i.dealNumber === relations.find((r) => r.bpCode === bailContract.bpCode)?.dealNumber,
      )?.id;

      const dealNumber = relations.find((r) => r.bpCode === bailContract.bpCode)?.dealNumber;
      return {
        ...bailContract,
        agreementId: agreementId,
        dealNumber: dealNumber,
      };
    }),
);

export const selectActiveBailContract = createSelector(
  selectActiveGuarantorIdByRouteParam,
  selectBailContractEntities,
  (bpCode, bailContracts) => bailContracts[bpCode],
);

export const selectAgreementRelatedToGuarantor = createSelector(
  selectActiveBailContract,
  selectAllAgreements,
  (bailContract, agreements) => agreements.find((item) => item.dealNumber === bailContract?.dealNumber),
);
