import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Agreement } from '@api/loan-approval';

const agreementsFeatureKey = 'agreements';

export type AgreementState = EntityState<Agreement>;

export const adapter = createEntityAdapter<Agreement>();

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) =>
    adapter.setAll([...(variables.agreement ? [variables.agreement] : []), ...(variables.agreements || [])], state),
  ),
);

export const agreementsFeature = createFeature({
  name: agreementsFeatureKey,
  reducer,
  extraSelectors: ({ selectAgreementsState }) => ({
    ...adapter.getSelectors(selectAgreementsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = agreementsFeature;
