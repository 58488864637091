import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadRiskSegments, selectIsLoadingRiskSegments, selectRiskSegments } from '../index';

@Injectable({
  providedIn: 'root',
})
export class RiskSegmentsFacadeService {
  public selectRiskSegments$ = this.store.select(selectRiskSegments);
  public selectIsLoading$ = this.store.select(selectIsLoadingRiskSegments);

  constructor(private store: Store) {}

  public loadRiskSegments() {
    this.store.dispatch(loadRiskSegments());
  }
}
