import { inject } from '@angular/core';
import { TaskSettingsFacadeService } from './task-settings.facade.service';
import { TaskSettingsState } from './task-settings.reducer';

export const taskSettingsResolver = (settings: Partial<TaskSettingsState>) => () =>
  inject(TaskSettingsFacadeService).updateTaskSettings(settings);

export const taskSettingsDeactivateGuard = () => {
  inject(TaskSettingsFacadeService).resetTaskSettings();
  return true;
};
