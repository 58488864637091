import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { selectAllCurrencies } from './currencies.selectors';
import { loadCurrencies } from './currencies.actions';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesFacadeService {
  public currencies$ = this.store.select(selectAllCurrencies);

  constructor(private store: Store) {}

  loadCurrencies(): void {
    this.store.dispatch(loadCurrencies());
  }
}
