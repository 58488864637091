import { ChangeDetectorRef, Directive, inject } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { FormInfoDialogModel, InfoDialogComponent } from '@sib/shared/ui';
import { FormGroup } from '@angular/forms';
import { TaskAccessFacadeService } from '@sib/task/shared/store';
import { AuthService } from '@sib/shared/da';

@UntilDestroy()
@Directive()
export abstract class SharedPendingChangesBase {
  private matDialog = inject(MatDialog);
  private authService = inject(AuthService);
  protected cdr = inject(ChangeDetectorRef);
  protected taskAccessFacadeService = inject(TaskAccessFacadeService);
  protected description = 'У вас є незбережені дані, бажаєте їх зберегти?';
  public abstract form: { dirty: boolean; disable: () => void; formGroup: FormGroup };

  public canDeactivate() {
    return this.taskAccessFacadeService.selectIsReadonly$.pipe(
      switchMap((status) => {
        if (this.form?.dirty && this.authService.isLoggedIn && !status) {
          return this.matDialog
            .open<InfoDialogComponent, FormInfoDialogModel, boolean>(InfoDialogComponent, {
              data: {
                title: 'Увага',
                description: this.description,
                buttonTitleNo: 'Ні',
                buttonYesHidden: false,
              },
            })
            .afterClosed()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return this.saveFn();
                } else if (result === false) {
                  this.form.formGroup.markAsPristine();
                  return of(true);
                }

                return of(false);
              }),
              tap(() => this.cdr.markForCheck()),
            );
        }

        return of(true);
      }),
    );
  }

  abstract saveFn(): Observable<boolean>;
}
