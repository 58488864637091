import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { BailContract } from '@api/loan-approval';

const bailContractsFeatureKey = 'bailContracts';

export type BailContractsState = EntityState<BailContract>;

export const adapter = createEntityAdapter<BailContract>({ selectId: (item) => item.bpCode });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.bailContracts || [], state)),
);

export const bailContractsFeature = createFeature({
  name: bailContractsFeatureKey,
  reducer,
  extraSelectors: ({ selectBailContractsState }) => ({
    ...adapter.getSelectors(selectBailContractsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = bailContractsFeature;
