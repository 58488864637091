import { Injectable } from '@angular/core';
import { setTaskV2ProcessVariable } from '@sib/task/shared/store';
import { BorrowerGroup } from '@api/loan-approval';
import { AbstractStoreService } from './abstract-store.service';

@Injectable({ providedIn: 'root' })
export class BorrowerGroupService extends AbstractStoreService {
  updateBorrowerGroup(item: BorrowerGroup) {
    this.store.dispatch(
      setTaskV2ProcessVariable({
        requestBody: {
          borrowerGroup: [item],
        },
      }),
    );

    return this.waitForResponse(item);
  }
}
