import { OpenAPI as OpenAPIAuth } from '@api/auth';
import { OpenAPI as OpenAPIDictionaries } from '@api/dictionaries';
import { OpenAPI as OpenAPILoanApproval } from '@api/loan-approval';
import { OpenAPI as OpenAPILoanOrgStructure } from '@api/loan-org-structure';
import { OpenAPI as OpenAPICamunda } from '@api/camunda';
import { OpenAPI as OpenAPIDocumentTemplate } from '@api/document-template';
import { OpenAPI as OpenAPIHealthService } from '@api/health-service';

export const setBaseUrl = (isLocal: boolean) => {
  const BASE = isLocal ? 'proxy' : '';
  OpenAPIAuth.BASE = BASE + OpenAPIAuth.BASE;
  OpenAPIDictionaries.BASE = BASE + '/api' + OpenAPIDictionaries.BASE;
  OpenAPILoanApproval.BASE = BASE + '/api' + OpenAPILoanApproval.BASE;
  OpenAPILoanOrgStructure.BASE = BASE + '/api' + OpenAPILoanOrgStructure.BASE;
  OpenAPICamunda.BASE = BASE + '/api' + OpenAPICamunda.BASE;
  OpenAPIDocumentTemplate.BASE = BASE + '/api' + OpenAPIDocumentTemplate.BASE;
  OpenAPIHealthService.BASE = BASE + '/api' + OpenAPIHealthService.BASE;
};

export const setToken = (token: string) => {
  OpenAPIAuth.TOKEN = token;
  OpenAPIDictionaries.TOKEN = token;
  OpenAPILoanApproval.TOKEN = token;
  OpenAPILoanOrgStructure.TOKEN = token;
  OpenAPICamunda.TOKEN = token;
  OpenAPIDocumentTemplate.TOKEN = token;
};

export const clearToken = () => {
  OpenAPIAuth.TOKEN = undefined;
  OpenAPIDictionaries.TOKEN = undefined;
  OpenAPILoanApproval.TOKEN = undefined;
  OpenAPILoanOrgStructure.TOKEN = undefined;
  OpenAPIDocumentTemplate.TOKEN = undefined;
  OpenAPIHealthService.TOKEN = undefined;
};
