import { createAction, props } from '@ngrx/store';
import { LoanClassDTO } from '@api/dictionaries';

export const loadLoanClasses = createAction('[Dictionaries/API] Load Loan Classes');
export const loadLoanClassesSuccess = createAction(
  '[Dictionaries/API] Load Loan Classes Success',
  props<{ loanClassesList: LoanClassDTO[] }>(),
);
export const loadLoanClassesFailure = createAction(
  '[Dictionaries/API] Load Loan Classes Failure',
  props<{ error: unknown }>(),
);
