import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as DocumentsActions from './documents.actions';
import { DocumentTypeDto, LocationAkdDto } from '@api/dictionaries';

export const documentsFeatureKey = 'documents';

export interface State extends EntityState<LocationAkdDto> {
  isLoading: boolean;
  locationAkd: LocationAkdDto[];
  docTypes: DocumentTypeDto[];
}

export const adapter: EntityAdapter<LocationAkdDto> = createEntityAdapter<LocationAkdDto>({
  selectId: (locationAkd: LocationAkdDto) => locationAkd.codeFolder!,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  locationAkd: [],
  docTypes: [],
});

export const reducer = createReducer(
  initialState,

  on(DocumentsActions.loadLocationAkd, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DocumentsActions.loadLocationAkdSuccess, (state, { locationAkd }) => ({
    ...adapter.setAll(locationAkd, state),
    isLoading: false,
    locationAkd,
  })),

  on(DocumentsActions.loadLocationAkdFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),

  on(DocumentsActions.loadDocTypes, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(DocumentsActions.loadDocTypesSuccess, (state, { docTypes }) => ({
    ...state,
    isLoading: false,
    docTypes,
  })),

  on(DocumentsActions.loadDocTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
export const selectLocationAkd = (state: State) => state.locationAkd;
export const selectDocTypes = (state: State) => state.docTypes;
