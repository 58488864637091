import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as ErrorsActions from './errors.actions';
import { ErrorDto, ProcessErrorDto } from '@api/dictionaries';

export const errorsFeatureKey = 'errors';

export interface State extends EntityState<ErrorDto> {
  isLoading: boolean;
  errorsProcess: ProcessErrorDto[];
}

export const adapter: EntityAdapter<ErrorDto> = createEntityAdapter<ErrorDto>({
  selectId: (error: ErrorDto) => error.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  errorsProcess: [],
});

export const reducer = createReducer(
  initialState,

  on(ErrorsActions.loadErrors, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ErrorsActions.loadErrorsSuccess, (state, { errors }) => ({
    ...adapter.setAll(errors, state),
    isLoading: false,
  })),

  on(ErrorsActions.loadErrorsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),

  on(ErrorsActions.loadErrorsProcess, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ErrorsActions.loadErrorsProcessSuccess, (state, { errorsProcess }) => ({
    ...state,
    errorsProcess,
    isLoading: false,
  })),

  on(ErrorsActions.loadErrorsProcessFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;

export const selectAllErrorsProcess = (state: State) => state.errorsProcess;
