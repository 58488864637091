import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { DocumentEntry } from '@api/loan-approval';

const documentEntryListFeatureKey = 'documentEntryList';

export type DocumentEntryListFeatureKeyState = EntityState<DocumentEntry>;

export const adapter = createEntityAdapter<DocumentEntry>({ selectId: (item) => item.documentId! });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.documentEntryList || [], state)),
);

export const documentEntryListFeature = createFeature({
  name: documentEntryListFeatureKey,
  reducer,
  extraSelectors: ({ selectDocumentEntryListState }) => ({
    ...adapter.getSelectors(selectDocumentEntryListState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = documentEntryListFeature;
