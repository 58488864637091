/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CourtCasesTypeDto } from '../models/CourtCasesTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LawCourtService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all law courts
     * Returns all law courts.
     * @returns CourtCasesTypeDto all law courts
     * @throws ApiError
     */
    public getApiLawCaseTypes(): Observable<Array<CourtCasesTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/law-case-types',
        });
    }

    /**
     * Create law court
     * Method for creating law court.
     * @returns CourtCasesTypeDto law court created
     * @throws ApiError
     */
    public postApiLawCaseTypes({
        requestBody,
    }: {
        /**
         * Law court object that needs to be created.
         */
        requestBody: CourtCasesTypeDto,
    }): Observable<CourtCasesTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/law-case-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get law court
     * Get law court by ID.
     * @returns any law court found
     * @throws ApiError
     */
    public getApiLawCaseTypes1({
        caseTypeId,
    }: {
        /**
         * Case type ID. Cannot be empty.
         */
        caseTypeId: string,
    }): Observable<{
        casesType: string;
        typeTitle?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/law-case-types/{caseTypeId}',
            path: {
                'caseTypeId': caseTypeId,
            },
            errors: {
                404: `law court not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete law court
     * Deletes the law court with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiLawCaseTypes({
        caseTypeId,
    }: {
        /**
         * Case type ID.  Cannot be empty.
         */
        caseTypeId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/law-case-types/{caseTypeId}',
            path: {
                'caseTypeId': caseTypeId,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Update law court
     * Updates the law court with the given ID.
     * @returns any assessment type updated
     * @throws ApiError
     */
    public putApiLawCaseTypes({
        id,
        requestBody,
    }: {
        /**
         * Law court ID. Cannot be empty.
         */
        id: string,
        /**
         * Law court object that needs to be updated.
         */
        requestBody: CourtCasesTypeDto,
    }): Observable<{
        casesType: string;
        typeTitle?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/law-case-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

}
