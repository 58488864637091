import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AgreementsCount from './agreements-count.actions';
import { VariablesService } from '@api/loan-approval';

@Injectable()
export class AgreementsCountEffects {
  AgreementsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgreementsCount.loadAgreementsCount),
      switchMap(({ taskId }) =>
        this.variablesService.getCamundaTaskAgreementsCount({ taskId: taskId }).pipe(
          map((response) =>
            AgreementsCount.loadAgreementsCountSuccess({
              agreementsCount: response,
            }),
          ),
          catchError((error) => of(AgreementsCount.loadAgreementsCountFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private variablesService: VariablesService) {}
}
