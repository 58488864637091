import { Injectable } from '@angular/core';
import { formatDateUTCShort } from '@sib/shared/util';
import { CurrencyRateService } from '@api/loan-approval';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyRatesService {
  constructor(private currencyRatesService: CurrencyRateService) {}

  loadLoanApprovalCurrencyRates(currCode: string, rateDate?: string | Date) {
    const currentRateDate = formatDateUTCShort(new Date());
    const selectedCurrentRateDate = rateDate ? formatDateUTCShort(new Date(rateDate)) : currentRateDate;

    return this.currencyRatesService.getSapCurrencyRates1({ currCode, rateDate: selectedCurrentRateDate });
  }

  loadLoanApprovalCurrencyRatesByCurrencyCodes(currCodes: string[], rateDate?: string | Date) {
    const currentRateDate = formatDateUTCShort(new Date());
    const selectedCurrentRateDate = rateDate ? formatDateUTCShort(new Date(rateDate)) : currentRateDate;

    return combineLatest(
      currCodes.map((currCode) =>
        this.currencyRatesService.getSapCurrencyRates1({
          currCode,
          rateDate: selectedCurrentRateDate,
        }),
      ),
    );
  }
}
