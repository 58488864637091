/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { VariablesDto } from '../models/VariablesDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class VariablesService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all variables by process ID
     * Returns a map of all variables for a given process ID.
     * @returns any Map of all variables returned
     * @throws ApiError
     */
    public getCamundaProcessVariables({
        processId,
    }: {
        /**
         * The ID of the process to get variables for.
         */
        processId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/process/{processId}/variables',
            path: {
                'processId': processId,
            },
        });
    }

    /**
     * Set process variables
     * Updates the process variables for a given process instance.
     * @returns any Successful operation
     * @throws ApiError
     */
    public postCamundaProcessVariables({
        processInstanceId,
        requestBody,
        toRootProcessInstance,
    }: {
        /**
         * The ID of the process instance to update the variables for.
         */
        processInstanceId: string,
        /**
         * The new variables values.
         */
        requestBody: VariablesDto,
        /**
         * If set to true, the variables will be updated in the root process instance.
         */
        toRootProcessInstance?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/camunda/process/{processInstanceId}/variables',
            path: {
                'processInstanceId': processInstanceId,
            },
            query: {
                'toRootProcessInstance': toRootProcessInstance,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Server error`,
            },
        });
    }

    /**
     * Delete process variables
     * Deletes the process variables for a given process instance.
     * @returns any Successful operation
     * @throws ApiError
     */
    public deleteCamundaProcessVariables({
        processInstanceId,
        requestBody,
        inRootProcessInstance,
    }: {
        /**
         * The ID of the process instance to delete the variables for.
         */
        processInstanceId: string,
        /**
         * The variables values to delete.
         */
        requestBody: VariablesDto,
        /**
         * If set to true, the variables will be deleted in the root process instance.
         */
        inRootProcessInstance?: boolean,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/camunda/process/{processInstanceId}/variables',
            path: {
                'processInstanceId': processInstanceId,
            },
            query: {
                'inRootProcessInstance': inRootProcessInstance,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Server error`,
            },
        });
    }

    /**
     * Get agreements count by task ID
     * Returns a count of agreements for a given task ID.
     * @returns number Returns the count of agreements
     * @throws ApiError
     */
    public getCamundaTaskAgreementsCount({
        taskId,
    }: {
        /**
         * The ID of the task to get agreements count for.
         */
        taskId: string,
    }): Observable<number> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/task/{taskId}/agreements-count',
            path: {
                'taskId': taskId,
            },
            errors: {
                404: `Agreements not found`,
            },
        });
    }

    /**
     * Get all variables by task ID
     * Returns a map of all variables for a given task ID.
     * @returns any Map of all variables returned
     * @throws ApiError
     */
    public getCamundaTaskVariables({
        taskId,
    }: {
        /**
         * The ID of the task to get variables for.
         */
        taskId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/task/{taskId}/variables',
            path: {
                'taskId': taskId,
            },
        });
    }

    /**
     * Get a specific variable from root by task ID and variable name
     * Returns a specific variable for a given task ID and variable name.
     * @returns any Specific variable returned
     * @throws ApiError
     */
    public getCamundaTaskVariables1({
        name,
        taskId,
    }: {
        /**
         * The name of the variable to get.
         */
        name: string,
        /**
         * The ID of the task to get variables for.
         */
        taskId: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/camunda/task/{taskId}/variables/{name}',
            path: {
                'name': name,
                'taskId': taskId,
            },
        });
    }

}
