import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllOrgUnits, selectAllOrgUnitsByScopeCode, selectIsAllOrgUnitsLoading } from './org-units.selectors';
import { loadOrgUnits } from './org-units.actions';

@Injectable({
  providedIn: 'root',
})
export class OrgUnitsFacadeService {
  public selectAllOrgUnit$ = this.store.select(selectAllOrgUnits);
  public selectIsLoading$ = this.store.select(selectIsAllOrgUnitsLoading);
  public selectAllOrgUnitsByScopeCode$ = (scopeCode: string) =>
    this.store.select(selectAllOrgUnitsByScopeCode(scopeCode));

  constructor(private store: Store) {}

  public loadAllOrgUnits() {
    this.store.dispatch(loadOrgUnits());
  }
}
