<mat-card formGroupName="financingConditions">
  <mat-card-header>
    <mat-card-title>Умови фінансування</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="row">
      <mat-form-field *ngIf="!financingConditionsGuaranteeGeneralService.isActual.value">
        <mat-label> Продукт</mat-label>
        <mat-select
          placeholder="Виберіть продукт"
          formControlName="conditionsSetId"
          (selectionChange)="onSelectChangeProduct($event)"
        >
          <mat-option *ngFor="let item of dictionariesCustomProducts | async" [value]="item.groupId">
            {{ item.productName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="
          !financingConditionsGuaranteeGeneralService.dealNumberSAP.value &&
          financingConditionsGuaranteeGeneralService.isActual.value
        "
      >
        <mat-label>Продукт</mat-label>
        <input matInput type="text" formControlName="productName" placeholder="Продукт" />
      </mat-form-field>

      <mat-form-field *ngIf="financingConditionsGuaranteeGeneralService.dealNumberSAP.value">
        <mat-label>Номер SAP</mat-label>
        <input matInput type="text" formControlName="dealNumberSAP" placeholder="Номер SAP" />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Сума (Ліміт)</mat-label>
        <input matInput type="text" formControlName="agreementSum" placeholder="Введіть сумму (ліміт)" currencyMask />
      </mat-form-field>

      <mat-form-field>
        <mat-label> Валюта</mat-label>
        <mat-select placeholder="Виберіть тип валюти" formControlName="currencyCode">
          <mat-option *ngFor="let item of currenciesFacadeService.currencies$ | async" [value]="item.code">
            {{ item.definition }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field (click)="datepicker.open()">
        <mat-label>Строк дії Ліміту (до)</mat-label>
        <input matInput [matDatepicker]="datepicker" formControlName="endDate" [min]="minDate" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label> Акція</mat-label>
        <mat-select placeholder="Виберіть Акцію" formControlName="promotion">
          <mat-option *ngFor="let item of promotion$ | async" [value]="item.code">
            {{ item.description }}
          </mat-option></mat-select
        >
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
