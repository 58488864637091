import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsAccessLimited, selectIsAssignee, selectIsPageAccessible } from './task-access.selectors';
import { PageType } from '../task-tabs/types';
import { taskSettingsFeature } from '../../+state/task-settings/task-settings.reducer';

@Injectable({ providedIn: 'root' })
export class TaskAccessFacadeService {
  private readonly store = inject(Store);

  public selectIsAccessLimited = (pageType: PageType) => this.store.select(selectIsAccessLimited(pageType));
  public selectIsPageAccessible$ = (pageType: PageType) => this.store.select(selectIsPageAccessible(pageType));
  public selectIsAssignee$ = this.store.select(selectIsAssignee);
  public selectIsReadonly$ = this.store.select(taskSettingsFeature.selectIsReadonly);
}
