import { createReducer, on } from '@ngrx/store';
import * as RequestTypes from './request-types.action';
import { RequestTypeDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const requestTypesFeatureKey = 'request-types';

export interface State extends EntityState<RequestTypeDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<RequestTypeDto>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(RequestTypes.loadRequestTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RequestTypes.loadRequestTypesSuccess, (state, { requestTypes }) => ({
    ...adapter.setAll(requestTypes, state),
    isLoading: false,
  })),
  on(RequestTypes.loadRequestTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectRequestTypesState = selectAll;
