import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectActiveBailContract,
  selectAgreementRelatedToGuarantor,
  selectBailContracts,
  selectCurrentGuarantor,
  selectGuarantorByAgreementId,
} from './guarantors.selectors';
import { selectGuarantorAgreementRelation } from '../../+state/variables/variables.selectors';
import { selectAllGuarantors, selectGuarantorByBpCode } from '../../+state/guarantors/guarantors.selectors';
import { selectAllBailContracts } from '../../+state/bail-contracts/bail-contracts.selectors';

@Injectable({
  providedIn: 'root',
})
export class GuarantorsFacadeService {
  private readonly store = inject(Store);

  selectGuarantorByAgreementId$ = (id?: string) => this.store.select(selectGuarantorByAgreementId(id));
  selectGuarantorAgreementRelation$ = this.store.select(selectGuarantorAgreementRelation);
  selectGuarantorByBpCode$ = (bpCode: string) => this.store.select(selectGuarantorByBpCode(bpCode));
  selectCurrentGuarantor$ = this.store.select(selectCurrentGuarantor);
  selectAllGuarantors$ = this.store.select(selectAllGuarantors);

  selectBailContracts$ = this.store.select(selectBailContracts);
  selectAllBailContracts$ = this.store.select(selectAllBailContracts);
  selectActiveBailContract$ = this.store.select(selectActiveBailContract);

  selectAgreementRelatedToGuarantor$ = this.store.select(selectAgreementRelatedToGuarantor);
}
