import { DestroyRef, Directive, inject, OnInit } from '@angular/core';
import { PageType } from '@sib/task/shared/store';
import { SharedPendingChangesBase } from '@sib/shared/feature';
import { FormArray, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface DisableState {
  [key: string]: boolean | DisableState;
}

@Directive()
export abstract class AbstractFormAccess extends SharedPendingChangesBase implements OnInit {
  public destroyReference = inject(DestroyRef);

  public abstract pageType: PageType;
  private disableState: DisableState = {};

  public ngOnInit() {
    this.taskAccessFacadeService
      .selectIsAccessLimited(this.pageType)
      .pipe(takeUntilDestroyed(this.destroyReference))
      .subscribe((isLimited) => {
        if (isLimited) {
          this.disableState = this.mapDisabledState(this.form.formGroup);
          this.form.formGroup.disable({ onlySelf: true });
        } else if (Object.keys(this.disableState).length) {
          this.form.formGroup.enable();
          this.restoreFormDisabledState(this.form.formGroup, this.disableState);
        }
      });
  }

  mapDisabledState(form: FormGroup | FormArray) {
    const result: DisableState = { rootFormDisabledStatus: form.disabled };
    Object.keys(form.controls).forEach((key) => {
      const abstractControl = (form.controls as any)[key];
      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        result[key] = this.mapDisabledState(abstractControl);
      } else {
        result[key] = abstractControl.disabled;
      }
    });
    return result;
  }

  restoreFormDisabledState(form: FormGroup | FormArray, keymap: DisableState) {
    Object.keys(keymap).forEach((key) => {
      if (key === 'rootFormDisabledStatus') {
        if (keymap[key]) {
          form.disable({ onlySelf: true, emitEvent: false });
        }
      } else if (form) {
        const abstractControl: FormGroup = (form.controls as any)[key];
        const result = keymap[key];
        if (typeof result === 'object') {
          this.restoreFormDisabledState(abstractControl, keymap[key] as DisableState);
        } else if (result) {
          abstractControl.disable({ onlySelf: true, emitEvent: false });
        }
      }
    });
  }
}
