import { inject, Injectable } from '@angular/core';
import { EMPTY, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { borrowerGroupActions } from './borrower-group.actions';
import { ToastrService } from 'ngx-toastr';
import { BorrowerGroupService } from '@api/loan-approval';
import { VariablesFacadeService } from '../variables/variables.facade.service';
import { setTaskV2ProcessVariable } from '../../+state/task/task.actions';
import { SapGeneralError } from '@sib/shared/util';
import { BorrowerGroupFacade } from './borrower-group.facade';

@Injectable()
export class BorrowerGroupEffects {
  private readonly variablesFacadeService = inject(VariablesFacadeService);
  private readonly borrowerGroupFacade = inject(BorrowerGroupFacade);

  loadSapBorrowerGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(borrowerGroupActions.loadSapBorrowerGroups),
      concatLatestFrom((action) => [this.variablesFacadeService.selectBpCode$]),
      switchMap(([action, bpCode]) =>
        this.borrowerGroupsService.getSapBorrowerGroups({ bpCode: String(bpCode) || '' }).pipe(
          catchError((error) => {
            if (error.body instanceof SapGeneralError) {
              this.toasrtService.error(error.body.errorMessage);
              return EMPTY;
            }
            return throwError(() => error);
          }),
        ),
      ),
      concatLatestFrom(() => this.borrowerGroupFacade.selectAllBorrowerGroups$),
      switchMap(([response, borrowers]) => [
        setTaskV2ProcessVariable({
          requestBody: {
            borrowerGroup: response.map((item) => {
              const borrower = borrowers.find((borrower) => item.buPartner === borrower.buPartner);
              return borrower ? { ...item, comment: borrower.comment } : item;
            }),
          },
        }),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private toasrtService: ToastrService,
    private borrowerGroupsService: BorrowerGroupService,
  ) {}
}
