import { createFeature, createReducer, on } from '@ngrx/store';
import { taskDetailsActions } from './task-details.actions';

const taskDetailsKey = 'taskDetails';

export interface TaskDetailsState {
  id: string;
  processDefinitionKey: string | undefined;
  processDefinitionId: string | undefined;
  processInstanceId: string;
  executionId: string | undefined;
  caseDefinitionKey: string | undefined;
  caseDefinitionId: string | undefined;
  caseInstanceId: string | undefined;
  caseExecutionId: string | undefined;
  activityInstanceId: string | undefined;
  name: string | undefined;
  description: string | undefined;
  deleteReason: string | undefined;
  owner: string | undefined;
  assignee: string | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  duration: number | undefined;
  taskDefinitionKey: string | undefined;
  priority: number | undefined;
  due: string | undefined;
  parentTaskId: string | undefined;
  followUp: string | undefined;
  tenantId: string | undefined;
  removalTime: string | undefined;
  rootProcessInstanceId: string | undefined;
}

export const initialState: TaskDetailsState = {
  id: '',
  processDefinitionKey: '',
  processDefinitionId: '',
  processInstanceId: '',
  executionId: '',
  caseDefinitionKey: '',
  caseDefinitionId: '',
  caseInstanceId: '',
  caseExecutionId: '',
  activityInstanceId: '',
  name: '',
  description: '',
  deleteReason: '',
  owner: '',
  assignee: '',
  startTime: '',
  endTime: '',
  duration: 0,
  taskDefinitionKey: '',
  priority: 0,
  due: '',
  parentTaskId: '',
  followUp: '',
  tenantId: '',
  removalTime: '',
  rootProcessInstanceId: '',
};

export const reducer = createReducer<TaskDetailsState>(
  initialState,
  on(taskDetailsActions.loadSuccess, (state, { taskDetails }) => ({ ...state, ...taskDetails })),
);

export const taskDetailsFeature = createFeature({
  name: taskDetailsKey,
  reducer,
});
