import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { getRangeByPeriod, Period, predefinedPeriods } from '@sib/shared/util';
import { animationFrameScheduler, filter, observeOn } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface PeriodSelectorControl {
  predefinedPeriod: Period;
  from: Date;
  to: Date;
}

@Component({
  selector: 'sib-period-selector',
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, MatSelectModule, ReactiveFormsModule, MatFormFieldModule],
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PeriodSelectorComponent,
      multi: true,
    },
  ],
})
export class PeriodSelectorComponent implements ControlValueAccessor, OnInit {
  private destroyRef = inject(DestroyRef);

  public readonly maxDate = new Date();

  @Input()
  public defaultPeriod: Period = 'currentYear';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (_: PeriodSelectorControl) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};
  public predefinedPeriod = predefinedPeriods;

  public control = new FormGroup({
    predefinedPeriod: new FormControl<Period>(this.defaultPeriod, { nonNullable: true }),
    from: new FormControl(getRangeByPeriod(this.defaultPeriod).from, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    to: new FormControl(getRangeByPeriod(this.defaultPeriod).to, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public ngOnInit() {
    this.initCustomPeriodWatch();

    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) =>
      this.onChange({
        predefinedPeriod: value.predefinedPeriod!,
        from: value.from!,
        to: value.to!,
      }),
    );
  }

  public writeValue(value: PeriodSelectorControl) {
    this.control.setValue(
      value || {
        predefinedPeriod: this.defaultPeriod,
        ...getRangeByPeriod(this.defaultPeriod),
      },
    );
  }

  public registerOnChange(fn: (value: PeriodSelectorControl) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    return isDisabled ? this.control.disable({ emitEvent: false }) : this.control.enable();
  }

  private initCustomPeriodWatch() {
    this.control.controls.predefinedPeriod.valueChanges
      .pipe(
        filter((value) => value !== 'nothing'),
        // for native reset form needs delay to patch control, otherwise it will be cleared
        observeOn(animationFrameScheduler),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.control.patchValue(getRangeByPeriod(value), { emitEvent: false });
      });
  }

  public dateChange() {
    this.control.controls.predefinedPeriod.setValue('nothing', { emitEvent: false });
  }
}
