/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DocumentFailureDto } from '../models/DocumentFailureDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DocumentFailureService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all document failures
     * Returns all document failures.
     * @returns DocumentFailureDto all document failure
     * @throws ApiError
     */
    public getApiDocumentsDocFailures(): Observable<Array<DocumentFailureDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-failures',
        });
    }

    /**
     * Create document failure
     * Method for creating document failure.
     * @returns DocumentFailureDto document failure created
     * @throws ApiError
     */
    public postApiDocumentsDocFailures({
        requestBody,
    }: {
        /**
         * Document failure object that needs to be created.
         */
        requestBody: DocumentFailureDto,
    }): Observable<DocumentFailureDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/documents/doc-failures',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get document failure
     * Get document failure by reason ID.
     * @returns any document failure found
     * @throws ApiError
     */
    public getApiDocumentsDocFailuresGetByReasonId({
        reasonId,
    }: {
        /**
         * Reason ID. Cannot be empty.
         */
        reasonId: number,
    }): Observable<{
        id: number;
        documentTypeId?: number;
        reasonId?: number;
        reasonText?: string;
        requiredComment?: boolean;
        isExclude?: boolean;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-failures/get-by-reason-id',
            query: {
                'reasonId': reasonId,
            },
            errors: {
                404: `document failure not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get document failure
     * Get document failure by ID.
     * @returns any document failure found
     * @throws ApiError
     */
    public getApiDocumentsDocFailures1({
        id,
    }: {
        /**
         * Document failure ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        documentTypeId?: number;
        reasonId?: number;
        reasonText?: string;
        requiredComment?: boolean;
        isExclude?: boolean;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-failures/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `document failure not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update document failure
     * Updates the document failure with the given ID.
     * @returns any document failure updated
     * @throws ApiError
     */
    public putApiDocumentsDocFailures({
        id,
        requestBody,
    }: {
        /**
         * Document failure ID. Cannot be empty.
         */
        id: number,
        /**
         * Document failure object that needs to be updated.
         */
        requestBody: DocumentFailureDto,
    }): Observable<{
        id: number;
        documentTypeId?: number;
        reasonId?: number;
        reasonText?: string;
        requiredComment?: boolean;
        isExclude?: boolean;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/documents/doc-failures/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Delete document failure
     * Deletes the document failure with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDocumentsDocFailures({
        id,
    }: {
        /**
         * Document failure ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/documents/doc-failures/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
