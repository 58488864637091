import { createSelector } from '@ngrx/store';
import * as fromErrors from './errors.reducer';
import { selectErrorsState } from '../+shell/reducers.index';

export const selectErrorsIsLoading = createSelector(selectErrorsState, fromErrors.selectIsLoading);

export const selectErrorsEntities = createSelector(selectErrorsState, fromErrors.selectEntities);

export const selectErrorById = (id: string) =>
  createSelector(selectErrorsEntities, (errorsEntities) => errorsEntities[id]);

export const selectAllErrors = createSelector(selectErrorsState, fromErrors.selectAll);

export const selectAllErrorsProcess = createSelector(selectErrorsState, fromErrors.selectAllErrorsProcess);

export const selectActiveErrorsProcess = createSelector(
  selectAllErrors,
  selectAllErrorsProcess,
  (errors, errorsProcess) => errorsProcess.filter((item) => errors.some((i) => i.id === item.errorId && i.active)),
);
