import { createReducer, on } from '@ngrx/store';
import * as AssessmentTypes from './assessment-types.action';
import { AssessmentTypeDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const assessmentTypesFeatureKey = 'assessment-types';

export interface State extends EntityState<AssessmentTypeDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<AssessmentTypeDto>({
  selectId: (item: AssessmentTypeDto) => item.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(AssessmentTypes.loadAssessmentTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AssessmentTypes.loadAssessmentTypesSuccess, (state, { assessmentTypes }) => ({
    ...adapter.setAll(assessmentTypes, state),
    isLoading: false,
  })),
  on(AssessmentTypes.loadAssessmentTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAssessmentTypesState = selectAll;
export const selectAssessmentTypesEntities = selectEntities;
