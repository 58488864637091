import { createAction, props } from '@ngrx/store';
import { ConditionTypeDto } from '@api/dictionaries';

export const loadConditionTypes = createAction('[Dictionaries/API] Load ConditionTypes');
export const loadConditionTypesSuccess = createAction(
  '[Dictionaries/API] Load ConditionTypes Success',
  props<{ conditionTypes: ConditionTypeDto[] }>(),
);
export const loadConditionTypesFailure = createAction(
  '[Dictionaries/API] Load ConditionTypes Failure',
  props<{ error: unknown }>(),
);
