import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AssessmentKindsFacadeService,
  AssetCategoryFacadeService,
  AssetTypesFacadeService,
  CurrenciesFacadeService,
  EmployeesFacadeService,
} from '@sib/shared/store';
import {
  selectAccessAssetOperation,
  selectAgreementRelatedToAsset,
  selectCurrentProviding,
  selectCurrentProvidingList,
  selectProvidingByAgreementId,
  selectProvidingList,
} from './providing.selectors';
import { selectAssetAgreementRelation } from '../../+state/variables/variables.selectors';
import { Observable } from 'rxjs';
import { Asset, PropertyOwner } from '@api/loan-approval';

@Injectable({
  providedIn: 'root',
})
export class ProvidingFacadeService {
  private store = inject(Store);
  private assetTypesFacadeService = inject(AssetTypesFacadeService);
  private currenciesFacadeService = inject(CurrenciesFacadeService);
  private employeesFacadeService = inject(EmployeesFacadeService);
  private assetCategoryFacadeService = inject(AssetCategoryFacadeService);
  private assessmentKindsFacadeService = inject(AssessmentKindsFacadeService);

  public selectProvidingList$: Observable<(Asset & PropertyOwner)[]> = this.store.select(selectProvidingList);
  public selectProvidingByAgreementId$ = (id?: string) => this.store.select(selectProvidingByAgreementId(id));
  public selectCurrentProvidingList$ = this.store.select(selectCurrentProvidingList);
  public selectAgreementRelatedToAsset$ = this.store.select(selectAgreementRelatedToAsset);
  public selectCurrentProviding$ = this.store.select(selectCurrentProviding);

  public assetAgreementRelation$ = this.store.select(selectAssetAgreementRelation);
  public selectAccessAssetOperation$ = this.store.select(selectAccessAssetOperation);

  initProviding() {
    this.assetTypesFacadeService.loadAssetTypes();
    this.currenciesFacadeService.loadCurrencies();
    this.employeesFacadeService.loadEmployees();
    this.assetCategoryFacadeService.loadAssetCategories();
    this.assessmentKindsFacadeService.loadAssessmentKinds();
  }
}
