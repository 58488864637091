export const composeAddress = (data: {
  region?: string;
  city?: string;
  street?: string;
  houseNo?: string;
  apartment?: string;
}) =>
  `${data.region + ',' || ''} ${data.city + ',' || ''} ${data.street + ',' || ''} ${data.houseNo || ''} ${
    data.apartment ? ', ' + data.apartment : ''
  }`;

export const getRequisites = (data: {
  passSerie?: string;
  passNum?: string;
  passInst?: string;
  passDate?: string;
  region?: string;
  city?: string;
  street?: string;
  houseNo?: string;
  email?: string;
  apartment?: string;
}) => ({
  addressLocation: composeAddress(data),
  addressLetter: composeAddress(data),
  email: data.email,
});
