import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FinancingConditionsFiveSevenNineService extends FormGroup<
  ReturnType<typeof FinancingConditionsFiveSevenNineService.form>
> {
  static readonly form = () => ({
    productId: new FormControl<number | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    conditionsSetId: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    productName: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    agreementSum: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    currencyCode: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    dealDate: new FormControl<Date | undefined>(
      { value: undefined, disabled: true },
      {
        nonNullable: true,
      },
    ),
    dealNumberSAP: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    interestRate: new FormControl<string>('', { nonNullable: true }),
    interestRateAdd: new FormControl<number>(0, { nonNullable: true }),
    interestRateBasic: new FormControl<number>(0, { nonNullable: true }),
    agreementTerm: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    agreementTermMeasure: new FormControl<string>('Дні', { validators: [Validators.required], nonNullable: true }),
    lendingPurpose: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    priorityDirection: new FormControl<string>('', { nonNullable: true }),
    busnSegment: new FormControl<string>('', { nonNullable: true }),
    promotion: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    dealTitle: new FormControl<string | undefined>(undefined, { nonNullable: true }),
  });

  constructor() {
    super(FinancingConditionsFiveSevenNineService.form());
  }

  get dealNumberSAP() {
    return this.controls.dealNumberSAP;
  }

  get conditionsSetId() {
    return this.controls.conditionsSetId;
  }

  get currencyCode() {
    return this.controls.currencyCode;
  }

  get agreementTerm() {
    return this.controls.agreementTerm;
  }

  get interestRate() {
    return this.controls.interestRate;
  }

  get agreementTermMeasure() {
    return this.controls.agreementTermMeasure;
  }

  get agreementSum() {
    return this.controls.agreementSum;
  }
}
