import { createSelector } from '@ngrx/store';
import * as fromDocuments from './documents.reducer';
import { selectDocumentsState } from '../+shell/reducers.index';

export const selectDocumentsIsLoading = createSelector(selectDocumentsState, fromDocuments.selectIsLoading);

export const selectLocationAkdEntities = createSelector(selectDocumentsState, fromDocuments.selectEntities);

export const selectLocationAkdByCode = (code: string) =>
  createSelector(selectLocationAkdEntities, (locationAkd) => locationAkd[code]);

export const selectAllLocationAkd = createSelector(selectDocumentsState, fromDocuments.selectLocationAkd);

export const selectAllDocTypes = createSelector(selectDocumentsState, fromDocuments.selectDocTypes);
