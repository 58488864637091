import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as BpRoleCourtCasesActions from './bp-role-court-cases.actions';
import { BpRoleCourtCasesDto } from '@api/dictionaries';

export const bpRoleCourtCasesFeatureKey = 'bp-role-court-cases';

export interface State extends EntityState<BpRoleCourtCasesDto> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<BpRoleCourtCasesDto> = createEntityAdapter<BpRoleCourtCasesDto>({
  selectId: (bpRoleCourtCase: BpRoleCourtCasesDto) => bpRoleCourtCase.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(BpRoleCourtCasesActions.loadBpRoleCourtCases, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(BpRoleCourtCasesActions.loadBpRoleCourtCasesSuccess, (state, { bpRoleCourtCases }) => ({
    ...adapter.setAll(bpRoleCourtCases, state),
    isLoading: false,
  })),

  on(BpRoleCourtCasesActions.loadBpRoleCourtCasesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
