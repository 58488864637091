/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { MonitoringResultDto } from '../models/MonitoringResultDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MonitoringResultService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all monitoring results
     * Returns all monitoring results.
     * @returns MonitoringResultDto all monitoring results
     * @throws ApiError
     */
    public getApiMonitoringResults(): Observable<Array<MonitoringResultDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/monitoring-results',
        });
    }

    /**
     * Create monitoring result
     * Method for creating monitoring result.
     * @returns MonitoringResultDto monitoring result created
     * @throws ApiError
     */
    public postApiMonitoringResults({
        requestBody,
    }: {
        /**
         * Monitoring result object that needs to be created.
         */
        requestBody: MonitoringResultDto,
    }): Observable<MonitoringResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/monitoring-results',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get monitoring result
     * Get monitoring result by description.
     * @returns any monitoring result found
     * @throws ApiError
     */
    public getApiMonitoringResultsDescription({
        descr,
    }: {
        /**
         * Monitoring result description.  Cannot be empty.
         */
        descr: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/monitoring-results/description/{descr}',
            path: {
                'descr': descr,
            },
            errors: {
                404: `monitoring result not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get monitoring result
     * Get monitoring result by ID.
     * @returns any monitoring result found
     * @throws ApiError
     */
    public getApiMonitoringResults1({
        id,
    }: {
        /**
         * Monitoring result ID. Cannot be empty.
         */
        id: string,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/monitoring-results/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `monitoring result not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update monitoring result
     * Updates the monitoring result with the given ID.
     * @returns any assessment type updated
     * @throws ApiError
     */
    public putApiMonitoringResults({
        id,
        requestBody,
    }: {
        /**
         * Monitoring result ID. Cannot be empty.
         */
        id: string,
        /**
         * Monitoring result object that needs to be updated.
         */
        requestBody: MonitoringResultDto,
    }): Observable<{
        id: string;
        descr?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/monitoring-results/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete monitoring result
     * Deletes the monitoring result with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiMonitoringResults({
        id,
    }: {
        /**
         * Monitoring result ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/monitoring-results/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
