<div class="container mat-button-small">
  <div class="left-section">
    <sib-logo [routerLink]="['']"></sib-logo>

    <nav class="nav">
      <a mat-button href="https://p-conflu-app0.bnppua.net.intra/confluence/x/w62xDg" target="_blank">
        <mat-icon svgIcon="help"></mat-icon>
        Допомога
      </a>

      <button
        *ngIf="env.envType !== 'prod'"
        mat-raised-button
        [color]="env.envType === 'dev' ? 'primary' : env.envType === 'local' ? undefined : 'warn'"
      >
        {{ env.envType }}
      </button>
    </nav>
  </div>

  <div class="right-section">
    <div class="name" *ngIf="employee$ | async as employee">
      {{ employee.fullName }}
    </div>

    <button mat-stroked-button color="accent">
      <mat-icon svgIcon="cog_outline" color="accent"></mat-icon>
      Налаштування
    </button>
    <button data-e2e="logOut" mat-stroked-button color="warn" (click)="onLogout()">Вийти</button>
  </div>
</div>
