import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import * as fromRoot from '@app/core/store';
import { Store } from '@ngrx/store';
import * as fromShared from '../../../../libs/shared/store/src/lib/shared-state';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { HeaderComponent } from '../../components/header/header.component';
import { StickyBarDirective } from '@sib/shared/ui';

@Component({
  selector: 'sib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [StickyBarDirective, HeaderComponent, SidebarComponent, RouterOutlet],
})
export class LayoutComponent implements OnInit {
  public sidebarType = 'normal';

  constructor(private store: Store, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@Home', 'Головна');
    this.store.dispatch(fromRoot.loadTasksCount());
    this.store.dispatch(fromShared.loadEmployees());
  }

  onLogout(): void {
    this.store.dispatch(fromRoot.logout());
  }
}
