import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { mergeMap, Observable, retryWhen, throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtService } from '@sib/shared/da';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private readonly maxAttempts = 3;
  private readonly delay = 1000;

  constructor(private jwtService: JwtService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let headers: any;
    const token = this.jwtService.token;

    if (token) {
      headers = {
        Authorization: 'Bearer ' + token,
      };
    }

    return next
      .handle(
        request.clone({
          setHeaders: headers,
        }),
      )
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error, i) =>
              // FIX for working from vpn
              error.status === HttpStatusCode.GatewayTimeout && i + 1 < this.maxAttempts
                ? timer(this.delay)
                : throwError(error),
            ),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }
}
