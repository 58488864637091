import { createAction, props } from '@ngrx/store';

export const loadAgreementsCount = createAction(
  '[Dictionaries/API] Load Agreements Count',
  props<{ taskId: string }>(),
);
export const loadAgreementsCountSuccess = createAction(
  '[Dictionaries/API] Load Agreements Count Success',
  props<{ agreementsCount: number }>(),
);
export const loadAgreementsCountFailure = createAction(
  '[Dictionaries/API] Load Agreements Count Failure',
  props<{ error: any }>(),
);
