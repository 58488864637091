import { createSelector } from '@ngrx/store';
import * as fromProposalsTypes from './proposals-types.reducer';
import { selectProposalsTypesState } from '../+shell/reducers.index';

export const selectProposalsTypesListIsLoading = createSelector(
  selectProposalsTypesState,
  fromProposalsTypes.selectIsLoading,
);

export const selectProposalsTypesListEntities = createSelector(
  selectProposalsTypesState,
  fromProposalsTypes.selectEntities,
);

export const selectProposalsTypeById = (id: number) =>
  createSelector(selectProposalsTypesListEntities, (proposalsTypesListEntities) => proposalsTypesListEntities[id]);

export const selectAllProposalsTypesList = createSelector(selectProposalsTypesState, fromProposalsTypes.selectAll);
