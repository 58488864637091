import { createAction, props } from '@ngrx/store';
import { DictionaryDTO } from '@api/loan-org-structure';

export const loadDictionaries = createAction('[Dictionaries/API] Load Dictionaries');
export const loadDictionariesSuccess = createAction(
  '[Dictionaries/API] Load Dictionaries Success',
  props<{ dictionaries: any[] }>(),
);
export const loadDictionariesFailure = createAction(
  '[Dictionaries/API] Load Dictionaries Failure',
  props<{ error: any }>(),
);

export const reloadDictionaries = createAction('[Dictionaries/API] Reload Dictionaries');
export const reloadDictionariesSuccess = createAction(
  '[Dictionaries/API] Reload Dictionaries Success',
  props<{ dictionaries: any[] }>(),
);
export const reloadDictionariesFailure = createAction(
  '[Dictionaries/API] Reload Dictionaries Failure',
  props<{ error: unknown }>(),
);

export const loadDictionariesOrgstructure = createAction('[Dictionaries/API] Load Dictionaries Orgstructure');

export const loadDictionariesOrgstructureSuccess = createAction(
  '[Dictionaries/API] Load Dictionaries Orgstructure Success',
  props<{ dictionariesOrgstructure: DictionaryDTO[] }>(),
);

export const loadDictionariesOrgstructureFailure = createAction(
  '[Dictionaries/API] Load Dictionaries Orgstructure Failure',
  props<{ error: unknown }>(),
);
