import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyMaskDirective } from 'ngx-currency';
import { ControlContainer, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { first, Observable } from 'rxjs';
import { FinancingConditionsAgreementGeneralService } from './financing-conditions-agreement-general.service';
import { CurrenciesFacadeService, PromotionFacadeService } from '@sib/shared/store';
import { TERMS_DEADLINE_LIST } from '@sib/task/shared/util';
import { DigitsOnlyDirective } from '@sib/shared/ui';

@Component({
  selector: 'sib-financing-conditions-agreement-general',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyMaskDirective,
    FormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    DigitsOnlyDirective,
  ],
  templateUrl: './financing-conditions-agreement-general.component.html',
  styleUrls: ['./financing-conditions-agreement-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FinancingConditionsAgreementGeneralComponent {
  public financingConditionsAgreementGeneralService = inject(FinancingConditionsAgreementGeneralService);
  public currenciesFacadeService = inject(CurrenciesFacadeService);
  private promotionFacadeService = inject(PromotionFacadeService);

  @Input() dictionariesCustomProducts!: Observable<any[]>;

  public termsDeadlineOptions = TERMS_DEADLINE_LIST;
  public promotion$ = this.promotionFacadeService.getPromotion$;

  onSelectChangeProduct({ value }: { value: number }) {
    if (this.financingConditionsAgreementGeneralService.isActual.value) return;

    this.dictionariesCustomProducts.pipe(first()).subscribe((dictionaries) => {
      const item = dictionaries.find((item) => value === item.groupId);
      this.financingConditionsAgreementGeneralService.patchValue({
        productId: item.id,
        currencyCode: item.val,
        productName: item.prodName,
        dealTitle: item.loanClassId === (1 || 6) ? 'Кредитний договір' : 'Генеральний договір про кредитування',
      });
    });
  }
}
