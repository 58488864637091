import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Equipment } from '@api/loan-approval';

const equipmentFeatureKey = 'equipment';

export type EquipmentState = EntityState<Equipment>;

export const adapter = createEntityAdapter<Equipment>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.equipment || [], state)),
);

export const equipmentFeature = createFeature({
  name: equipmentFeatureKey,
  reducer,
  extraSelectors: ({ selectEquipmentState }) => ({
    ...adapter.getSelectors(selectEquipmentState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = equipmentFeature;
