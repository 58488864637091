import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs';
import { JwtService } from './jwt.service';
import { EmployeeService } from '@api/loan-org-structure';

export interface UserModel {
  id: number;
  surname: string;
  email: string;
  login: string;
  certificateCodeIds: number[];
  regionIds: number[];
  fullName: string;
  json: NonNullable<Record<string, any>>;
  groups: string[];
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private employeeService: EmployeeService, private jwtService: JwtService) {}

  loadUserByLogin() {
    return this.employeeService.getApiEmployeesLogin({ login: this.jwtService.getUserLogin() });
  }

  loadUserSettingById(employeeId: number) {
    return this.employeeService.getApiEmployeesCustom({ id: employeeId });
  }

  loadUserFullInfo() {
    return this.loadUserByLogin().pipe(
      mergeMap((user) =>
        this.loadUserSettingById(user.id).pipe(
          map((userSettings) => ({
            ...user,
            json: userSettings?.json ? JSON.parse(userSettings.json) : {},
            groups: this.jwtService.getUserRoles(),
          })),
        ),
      ),
    );
  }

  createUserSetting(user: UserModel, userSettings: any) {
    const payload = { ...user.json, ...userSettings };
    return this.employeeService
      .postApiEmployeesCustom({
        id: user.id,
        requestBody: { id: user.id, json: JSON.stringify(payload) },
      })
      .pipe(
        map((response) => ({
          ...user,
          json: JSON.parse(response.json || '{}'),
        })),
      );
  }

  updateUserSetting(user: UserModel, userSettings: any) {
    const payload = { ...user.json, ...userSettings };
    return this.employeeService
      .putApiEmployeesCustom({
        id: user.id,
        requestBody: { id: user.id, json: JSON.stringify(payload) },
      })
      .pipe(
        map((response) => ({
          ...user,
          json: JSON.parse(response.json || '{}'),
        })),
      );
  }

  removeUserSettings(id: number) {
    return this.employeeService.deleteApiEmployeesCustom({ id });
  }

  loadEmployees() {
    return this.employeeService.getApiEmployees();
  }
}
