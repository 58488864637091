import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { parseDate } from '@sib/shared/util';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Guarantor } from '@api/loan-approval';

@Component({
  selector: 'sib-business-partner-information-form',
  templateUrl: './business-partner-information-form.component.html',
  styleUrls: ['./business-partner-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DigitOnlyModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class BusinessPartnerInformationFormComponent {
  @Input()
  set businessPartner(partner: Guarantor | undefined) {
    if (partner) {
      this.fillForm(partner);
    }
  }

  public form = new FormGroup({
    bpCode: new FormControl({ value: '', disabled: true }),
    orgName: new FormControl({ value: '', disabled: true }),
    address: new FormControl({ value: '', disabled: true }),
    idNumber: new FormControl({ value: '', disabled: true }),
    comment: new FormControl(''),
    dateOfFoundation: new FormControl<string | Date>({ value: '', disabled: true }),
    executionDate: new FormControl({ value: '', disabled: true }),
  });

  get f() {
    return this.form.controls;
  }

  private fillForm(partner: Guarantor): void {
    const dateOfFoundation =
      partner.dateOfFoundation !== '00000000' ? parseDate(partner.dateOfFoundation!, 'yyyyMMdd') : '';
    this.form.patchValue({
      ...partner,
      dateOfFoundation: dateOfFoundation,
    });
  }
}
