<mat-card formGroupName="termDocumentaryOperation">
  <mat-card-header>
    <mat-card-title>Строк документарної операції</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="form-group">
      <mat-checkbox formControlName="notLaterLastDay">
        В термін, не пізніше останнього дня строку дії Ліміту
      </mat-checkbox>
    </div>
    <div class="form-group">
      <mat-checkbox formControlName="limitDeadlineTermCheckBox">
        Не повинен перевищувати терміну від дати відкриття/ надання документарної операції, а саме:
      </mat-checkbox>
      <mat-form-field *ngIf="form.limitDeadlineTermCheckBox.value">
        <mat-label>Строк</mat-label>
        <input type="number" matInput formControlName="limitDeadlineTerm" />
      </mat-form-field>
      <mat-form-field *ngIf="form.limitDeadlineTermCheckBox.value">
        <mat-label>*</mat-label>
        <mat-select placeholder="Виберіть тип заявки" formControlName="limitDeadlineTermMeasure">
          <mat-option *ngFor="let item of terms" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-checkbox formControlName="daysBeforeEndDepositTermCheckBox">
        Має спливати не пізніше, ніж за встановлену кількість календарних днів до закінчення строку дії депозитного
        договору, майнові права за яким передані в заставу Банку, а саме:
      </mat-checkbox>
      <mat-form-field *ngIf="form.daysBeforeEndDepositTermCheckBox.value">
        <mat-label>Кал.днів</mat-label>
        <input type="number" matInput formControlName="daysBeforeEndDepositTerm" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-checkbox formControlName="numberMonthsTermCheckBox">
        Не може перевищувати строк дії ліміту договору на строк до:
      </mat-checkbox>
      <mat-form-field *ngIf="form.numberMonthsTermCheckBox.value">
        <mat-label>Місяців</mat-label>
        <input type="number" matInput formControlName="numberMonthsTerm" />
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
