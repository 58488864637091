import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as OrgUnitsAction from './org-units.actions';
import { OrgUnitDto } from '@api/loan-org-structure';

export const orgUnitsFeatureKey = 'org-units';

export interface State extends EntityState<OrgUnitDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<OrgUnitDto>({
  selectId: (orgUnit) => orgUnit.unitCode,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(OrgUnitsAction.loadOrgUnits, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(OrgUnitsAction.loadOrgUnitsSuccess, (state, { orgUnits }) => ({
    ...adapter.setAll(orgUnits, state),
    isLoading: false,
  })),
  on(OrgUnitsAction.loadOrgUnitsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
