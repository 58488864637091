<mat-card>
  <mat-card-header>
    <mat-card-title>Зобов'язання позичальника</mat-card-title>
  </mat-card-header>

  <mat-card-content formGroupName="obligationsBorrower">
    <sib-currency-input>
      <mat-form-field>
        <mat-label>Розмір неустойки, ГРН</mat-label>
        <input type="text" currencyMask matInput formControlName="penaltySum" />
      </mat-form-field>
    </sib-currency-input>

    <mat-checkbox formControlName="isCashFlow">Наявне рішення КК щодо грошових потоків</mat-checkbox>

    <div class="flow-type" *ngIf="formGroup.isCashFlow.value">
      <mat-radio-group class="mat-radio-group-global-custom" formControlName="cashFlows">
        <div class="form-group">
          <mat-radio-button value="monthFlows">
            Забезпечити щомісячне надходження на рахунки Банку в сумі не менше ніж
          </mat-radio-button>
          <sib-currency-input *ngIf="formGroup.cashFlows.value === 'monthFlows'">
            <mat-form-field>
              <mat-label>сума, грн</mat-label>
              <input type="text" currencyMask matInput formControlName="sumFlows" />
            </mat-form-field>
          </sib-currency-input>
        </div>
        <mat-radio-button value="quarterFlows">
          Забезпечити щоквартальне надходження на рахунки Банку
        </mat-radio-button>
      </mat-radio-group>

      <div class="cash-flow" *ngIf="formGroup.cashFlows.value === 'quarterFlows'">
        <sib-cash-flow-zkrs-table [flowType]="'FLOW_UAH'" formControlName="quarterFlows"></sib-cash-flow-zkrs-table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
