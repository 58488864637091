import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LoanClassesActions from './loan-classes.actions';
import * as LoanClassesSelectors from './loan-classes.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LoanClassesEffects {
  loadLoanClasses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoanClassesActions.loadLoanClasses),
      concatLatestFrom((action) => [this.store$.select(LoanClassesSelectors.selectAllLoanClassesList)]),
      switchMap(([action, allLoanClassesList]) =>
        (allLoanClassesList.length ? of(allLoanClassesList) : this.dictionariesService.loadLoanClasses()).pipe(
          map((response) =>
            LoanClassesActions.loadLoanClassesSuccess({
              loanClassesList: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(LoanClassesActions.loadLoanClassesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
