/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DocumentTemplateMetadata } from '../models/DocumentTemplateMetadata';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DocumentTemplateService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get document template
     * Get document template by ID and date.
     * @returns any document template found
     * @throws ApiError
     */
    public getApiDocTemplates({
        id,
        validFrom,
    }: {
        id?: number,
        validFrom?: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/doc-templates',
            query: {
                'id': id,
                'validFrom': validFrom,
            },
            errors: {
                404: `document template not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update document template
     * Updates the document template with the given ID and date.
     * @returns void
     * @throws ApiError
     */
    public putApiDocTemplates({
        id,
        validFrom,
        formData,
    }: {
        id?: number,
        validFrom?: string,
        formData?: {
            id?: number;
            title?: string;
            docTypeId?: number;
            content?: Blob;
            validFrom?: string;
            fileName?: string;
            codeTab?: string;
        },
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/doc-templates',
            query: {
                'id': id,
                'validFrom': validFrom,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Create document template
     * Method for creating document template.
     * @returns any document template created
     * @throws ApiError
     */
    public postApiDocTemplates({
        id,
        formData,
    }: {
        id?: number,
        formData?: {
            id?: number;
            title?: string;
            docTypeId?: number;
            content?: Blob;
            validFrom?: string;
            fileName?: string;
            codeTab?: string;
        },
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/doc-templates',
            query: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Partial update document template
     * Updates partial the document template with the given ID and date.
     * @returns void
     * @throws ApiError
     */
    public patchApiDocTemplates({
        id,
        validFrom,
        formData,
    }: {
        id?: number,
        validFrom?: string,
        formData?: {
            id?: number;
            title?: string;
            docTypeId?: number;
            content?: Blob;
            validFrom?: string;
            fileName?: string;
            codeTab?: string;
        },
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/doc-templates',
            query: {
                'id': id,
                'validFrom': validFrom,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get all document template meta data
     * Get all document template meta data.
     * @returns DocumentTemplateMetadata templates metadata found
     * @throws ApiError
     */
    public getApiDocTemplatesMetadata(): Observable<Array<DocumentTemplateMetadata>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/doc-templates/metadata',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get document template meta data
     * Get document template meta data by ui tabCode.
     * @returns DocumentTemplateMetadata document template metadata found
     * @throws ApiError
     */
    public getApiDocTemplatesMetadata1({
        codeTab,
    }: {
        codeTab: string,
    }): Observable<DocumentTemplateMetadata> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/doc-templates/metadata/{codeTab}',
            path: {
                'codeTab': codeTab,
            },
            errors: {
                404: `document template metadata not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get document template
     * Get document template by ID.
     * @returns any document template found
     * @throws ApiError
     */
    public getApiDocTemplates1({
        id,
    }: {
        /**
         * Document template ID. Cannot be empty.
         */
        id: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/doc-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `document template not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get document template meta data
     * Get document template meta data by ID.
     * @returns DocumentTemplateMetadata document template metadata found
     * @throws ApiError
     */
    public getApiDocTemplatesMetadata2({
        id,
    }: {
        /**
         * Document template meta data ID. Cannot be empty.
         */
        id: number,
    }): Observable<Array<DocumentTemplateMetadata>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/doc-templates/{id}/metadata',
            path: {
                'id': id,
            },
            errors: {
                404: `document template metadata not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete document template
     * Deletes the document template with the given ID and date.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDocTemplates({
        id,
        validFrom,
    }: {
        id: number,
        validFrom: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/doc-templates/{id}/{validFrom}',
            path: {
                'id': id,
                'validFrom': validFrom,
            },
        });
    }

}
