import { createAction, props } from '@ngrx/store';
import { ObligationTypeDTO } from '@api/dictionaries';

export const loadObligationTypes = createAction('[Dictionaries/API] Load ObligationTypes');
export const loadObligationTypesSuccess = createAction(
  '[Dictionaries/API] Load ObligationTypes Success',
  props<{ obligationTypes: ObligationTypeDTO[] }>(),
);
export const loadObligationTypesFailure = createAction(
  '[Dictionaries/API] Load ObligationTypes Failure',
  props<{ error: unknown }>(),
);
