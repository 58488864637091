import { inject, Injectable } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { AbstractStoreService } from './abstract-store.service';
import { DefaultService, VariableValueDto } from '@api/camunda';
import { TaskDetailsFacadeService, VariablesFacadeService } from '@sib/task/shared/store';

@Injectable({ providedIn: 'root' })
export class NotificationService extends AbstractStoreService {
  private readonly camundaService = inject(DefaultService);
  private readonly variablesFacadeService = inject(VariablesFacadeService);
  private readonly taskDetailsFacadeService = inject(TaskDetailsFacadeService);

  saveNotifications(notifications: Record<string, VariableValueDto>) {
    return this.taskDetailsFacadeService.selectTaskId$.pipe(
      first(),
      switchMap((taskId) =>
        this.camundaService
          .postTaskVariables({
            id: taskId,
            requestBody: {
              modifications: notifications,
            },
          })
          .pipe(switchMap(() => this.variablesFacadeService.getTaskVariable(taskId))),
      ),
    );
  }
}
