/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class FileStoreService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @deprecated
     * Update file application
     * Updates an existing file in the system.
     * @returns void
     * @throws ApiError
     */
    public putFileStore({
        formData,
    }: {
        formData?: {
            id?: string;
            procType?: string;
            applicationId?: string;
            content?: Blob;
            fileName?: string;
            loginAddedBy?: string;
            mimeType?: string;
            bindingCode?: string;
            forAllDeals?: boolean;
            uploadDate?: string;
        },
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/file-store',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @deprecated
     * Add file application
     * Adds a new file to the system.
     * @returns any file created
     * @throws ApiError
     */
    public postFileStore({
        formData,
    }: {
        formData?: {
            id?: string;
            procType?: string;
            applicationId?: string;
            content?: Blob;
            fileName?: string;
            loginAddedBy?: string;
            mimeType?: string;
            bindingCode?: string;
            forAllDeals?: boolean;
            uploadDate?: string;
        },
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/file-store',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Delete files application
     * Deletes a file from the system finished processes from period
     * @returns void
     * @throws ApiError
     */
    public postFileStoreClean(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/file-store/clean',
        });
    }

    /**
     * Update file application
     * Updates an existing file in the system and update documentEntry
     * @returns void
     * @throws ApiError
     */
    public putFileStoreProcess({
        processInstanceId,
        formData,
    }: {
        processInstanceId: string,
        formData?: {
            id?: string;
            procType?: string;
            applicationId?: string;
            content?: Blob;
            fileName?: string;
            loginAddedBy?: string;
            mimeType?: string;
            bindingCode?: string;
            forAllDeals?: boolean;
            uploadDate?: string;
        },
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/file-store/process/{processInstanceId}',
            path: {
                'processInstanceId': processInstanceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Add file application
     * Adds a new file to the system and update documentEntry
     * @returns any file created
     * @throws ApiError
     */
    public postFileStoreProcess({
        processInstanceId,
        formData,
    }: {
        processInstanceId: string,
        formData?: {
            id?: string;
            procType?: string;
            applicationId?: string;
            content?: Blob;
            fileName?: string;
            loginAddedBy?: string;
            mimeType?: string;
            bindingCode?: string;
            forAllDeals?: boolean;
            uploadDate?: string;
        },
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/file-store/process/{processInstanceId}',
            path: {
                'processInstanceId': processInstanceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get file application
     * Returns a file for a given ID.
     * @returns any file found
     * @throws ApiError
     */
    public getFileStore({
        id,
    }: {
        /**
         * The ID of the file to get.
         */
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/file-store/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `file not found`,
                500: `server error`,
            },
        });
    }

    /**
     * @deprecated
     * Delete file application
     * Deletes a file from the system.
     * @returns void
     * @throws ApiError
     */
    public deleteFileStore({
        id,
    }: {
        /**
         * The ID of the file to delete.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/file-store/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get file application metadata
     * Returns the metadata for a file for a given ID.
     * @returns any file found
     * @throws ApiError
     */
    public getFileStoreMetadata({
        id,
    }: {
        /**
         * The ID of the file to get the metadata for.
         */
        id: string,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/file-store/{id}/metadata',
            path: {
                'id': id,
            },
            errors: {
                404: `file not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete file application
     * Deletes a file from the system and update documentEntry
     * @returns void
     * @throws ApiError
     */
    public deleteFileStoreProcess({
        id,
        processInstanceId,
    }: {
        /**
         * The ID of the file to delete.
         */
        id: string,
        processInstanceId: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/file-store/{id}/process/{processInstanceId}',
            path: {
                'id': id,
                'processInstanceId': processInstanceId,
            },
        });
    }

}
