import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllProductCategoryTypes,
  selectCategoryTypeByProductId,
  selectProductCategoryTypesEntities,
} from './product-category-types.selections';
import { loadProductCategoryTypes } from './product-category-types.actions';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryTypesFacadeService {
  public productCategoryTypes$ = this.store.select(selectAllProductCategoryTypes);
  public productCategoryTypesEntities$ = this.store.select(selectProductCategoryTypesEntities);
  public categoryTypeByProductId$ = (productId: number) => this.store.select(selectCategoryTypeByProductId(productId));

  constructor(private store: Store) {}

  public loadProductCategoriesTypes() {
    this.store.dispatch(loadProductCategoryTypes());
  }
}
