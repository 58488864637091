import { createReducer, on } from '@ngrx/store';
import * as AgreementsCount from './agreements-count.actions';

export const agreementsCountFeatureKey = 'agreements-count';

export interface State {
  isLoading: boolean;
  agreementsCount: number;
  error: any;
}

export const initialState: State = {
  isLoading: false,
  agreementsCount: 0,
  error: null,
};

export const reducer = createReducer(
  initialState,

  on(AgreementsCount.loadAgreementsCount, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AgreementsCount.loadAgreementsCountSuccess, (state, { agreementsCount }) => ({
    ...state,
    isLoading: false,
    agreementsCount,
  })),
  on(AgreementsCount.loadAgreementsCountFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const selectAgreementsCountState = (state: State) => state.agreementsCount;
