<ngx-file-drop
  class="ngx-file-drop file-drop"
  [class.is-hidden]="isCustomDndActive"
  #ngxFileDrop
  [directory]="directory"
  [multiple]="multiple"
  (onFileDrop)="onFileDrop($event)"
>
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="file-drop__body">
      <h5 class="file-drop__title">Перетягніть сюди документ, щоб завантажити</h5>
      <p class="file-drop__text">Drag & Drop</p>
      <button mat-raised-button color="accent" type="button" (click)="openFileSelector()">
        <mat-icon>upload</mat-icon>
        Завантажити файл
      </button>
    </div>
  </ng-template>
</ngx-file-drop>

<div *ngIf="isCustomDndActive" class="custom-file-drop" sibDnd (fileDropped)="onFileDropped($event)">
  <div
    class="custom-file-drop__body"
    (dragover)="onDragOver()"
    (dragleave)="onDragLeave()"
    [class.is-drag-active]="isDragActive"
  >
    <h5 class="custom-file-drop__title">Перетягніть сюди документ, щоб завантажити</h5>
    <p class="custom-file-drop__text">Drag & Drop</p>
    <button mat-raised-button color="accent" type="button" (click)="ngxFileDrop.openFileSelector()">
      <mat-icon>upload</mat-icon>
      Завантажити файл
    </button>
  </div>
</div>
