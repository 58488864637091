import { createSelector } from '@ngrx/store';
import * as fromUiTabs from './ui-tabs.reducer';
import { selectUiTabsState } from '../+shell/reducers.index';

export const selectUiTabsIsLoading = createSelector(selectUiTabsState, fromUiTabs.selectIsLoading);

export const selectUiTabsEntities = createSelector(selectUiTabsState, fromUiTabs.selectEntities);

export const selectUiTabByTabCode = (tabCode: string) =>
  createSelector(selectUiTabsEntities, (UiTabsEntities) => UiTabsEntities[tabCode]?.tabName || '-');

export const selectAllUiTabs = createSelector(selectUiTabsState, fromUiTabs.selectAll);
