import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Selectors from './bank-services.selectors';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as BankServices from './bank-services.action';
import { DictionariesService } from '../dictionaries.service';

@Injectable()
export class BankServicesEffects {
  bankServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankServices.loadBankServices),
      concatLatestFrom((action) => [this.store$.select(Selectors.selectBankServices)]),
      switchMap(([action, allBankServices]) =>
        (allBankServices.length ? of(allBankServices) : this.dictionariesService.loadBankServices()).pipe(
          map((response) =>
            BankServices.loadBankServicesSuccess({
              bankServices: response,
            }),
          ),
          catchError((error) => of(BankServices.loadBankServicesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private dictionariesService: DictionariesService) {}
}
