import { createReducer, on } from '@ngrx/store';
import * as ControlGroups from './control-groups.actions';
import { ControlGroupDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const controlGroupsFeatureKey = 'control-groups';

export interface State extends EntityState<ControlGroupDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<ControlGroupDto>({
  selectId: (item) => item.id!,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(ControlGroups.loadControlGroups, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ControlGroups.loadControlGroupsSuccess, (state, { controlGroups }) => ({
    ...adapter.setAll(controlGroups, state),
    isLoading: false,
  })),
  on(ControlGroups.loadControlGroupsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(ControlGroups.loadControlGroupsReset, () => initialState),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectControlGroupsState = selectAll;
