/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ApplicationConstantDto } from '../models/ApplicationConstantDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ApplicationConstantService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getApiApplicationConstants(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/application-constants',
        });
    }

    /**
     * Update an application constant
     * Updates the application constant.
     * @returns ApplicationConstantDto application constant updated
     * @throws ApiError
     */
    public patchApiApplicationConstants({
        requestBody,
    }: {
        /**
         * Application constant object that needs to be updated.
         */
        requestBody: ApplicationConstantDto,
    }): Observable<ApplicationConstantDto> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/application-constants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `application constant not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get application constant
     * Get application constant by ID.
     * @returns ApplicationConstantDto application constant found
     * @throws ApiError
     */
    public getApiApplicationConstants1({
        id,
    }: {
        /**
         * Application constant ID.  Cannot be empty.
         */
        id: string,
    }): Observable<ApplicationConstantDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/application-constants/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `application constant not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Create an application constant
     * Method for creating an application constant.
     * @returns ApplicationConstantDto application constant created
     * @throws ApiError
     */
    public putApiApplicationConstants({
        id,
        requestBody,
    }: {
        /**
         * Application constant ID.  Cannot be empty.
         */
        id: string,
        /**
         * Application constant object that needs to be created.
         */
        requestBody: ApplicationConstantDto,
    }): Observable<ApplicationConstantDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/application-constants/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete application constant
     * Deletes the application constant with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiApplicationConstants({
        id,
    }: {
        /**
         * Application constant ID.  Cannot be empty.
         */
        id: string,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/application-constants/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `application constant not found`,
                500: `server error`,
            },
        });
    }

}
