import { createReducer, on } from '@ngrx/store';
import * as Regions from './regions.actions';
import { RegionDto } from '@api/loan-org-structure';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const regionsFeatureKey = 'regions';

export const adapter = createEntityAdapter<RegionDto>({
  selectId: (region) => region.id,
  sortComparer: false,
});

export interface State extends EntityState<RegionDto> {
  isLoading: boolean;
  error: unknown;
}

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(
    Regions.loadRegions,
    (state): State => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(Regions.loadRegionsSuccess, (state, { regions }) => ({
    ...adapter.setAll(regions, state),
    isLoading: false,
  })),
  on(
    Regions.loadRegionsFailure,
    (state, { error }): State => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
