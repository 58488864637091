import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyMaskDirective } from 'ngx-currency';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { TERMS_DEADLINE_LIST } from '@sib/task/shared/util';
import { DigitsOnlyDirective, TwoDigitDecimalNumberDirective } from '@sib/shared/ui';
import { ConditionGroupsFacadeService, CurrenciesFacadeService, PromotionFacadeService } from '@sib/shared/store';
import { first, Observable } from 'rxjs';
import { FinancingConditionsFiveSevenNineService } from './financing-conditions-five-seven-nine.service';

@Component({
  selector: 'sib-financing-conditions-five-seven-nine',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyMaskDirective,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    DigitsOnlyDirective,
    TwoDigitDecimalNumberDirective,
  ],
  templateUrl: './financing-conditions-five-seven-nine.component.html',
  styleUrls: ['./financing-conditions-five-seven-nine.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FinancingConditionsFiveSevenNineComponent {
  public currenciesFacadeService = inject(CurrenciesFacadeService);
  public financingConditionsFiveSevenNineService = inject(FinancingConditionsFiveSevenNineService);
  private promotionFacadeService = inject(PromotionFacadeService);
  private conditionGroupsFacadeService = inject(ConditionGroupsFacadeService);

  @Input() dictionariesCustomProducts!: Observable<any[]>;

  public termsDeadlineOptions = TERMS_DEADLINE_LIST;
  public dictionariesBusnSegment = [
    { type: 'MICRO_BUSINESS', name: 'Суб’єкт мікро підприємництва' },
    { type: 'SMALL_BUSINESS', name: 'Суб’єкт малого підприємництва' },
    { type: 'MEDIUM_BUSINESS', name: 'Суб’єкт середнього підприємництва' },
    { type: 'LARGE_BUSINESS', name: 'Суб’єкт великого підприємництва' },
  ];
  public promotion$ = this.promotionFacadeService.getPromotion$;

  constructor() {
    this.conditionGroupsFacadeService.loadConditionGroups();
  }

  onSelectChangeProduct({ value }: { value: number }) {
    this.dictionariesCustomProducts.pipe(first()).subscribe((dictionaries) => {
      const item = dictionaries.find((item) => value === item.groupId);
      this.financingConditionsFiveSevenNineService.patchValue({
        productId: item.id,
        currencyCode: item.val,
        productName: item.prodName,
        interestRate: item.rate,
        interestRateAdd: item.rateAdd,
        interestRateBasic: item.rate + item.rateAdd,
        dealTitle: item.loanClassId === (1 || 6) ? 'Кредитний договір' : 'Генеральний договір про кредитування',
      });
    });
  }
}
