import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import { agreementsFeature, AgreementState } from '../agreements/agreement.reducer';
import { conditionsFeature, ConditionsState } from '../conditions/condition.reducer';
import { currentCreditsFeature, CurrentCreditsState } from '../current-credits/current-credits.reducer';
import { borrowerGroupFeature, BorrowerGroupState } from '../borrower-group/borrower-group.reducer';
import { creditHistoryFeature, CreditHistoryState } from '../credit-history/credit-history.reducer';
import { assetFeature, AssetState } from '../asset/asset.reducer';
import { agreementGuarantyFeature, agreementGuarantyState } from '../agreement-guaranty/agreement-guaranty.reducer';
import { realtyFeature, RealtyState } from '../realty/realty.reducer';
import { depositFeature, DepositState } from '../deposit/deposit.reducer';
import { vehicleFeature, VehiclesState } from '../vehicles/vehicles.reducer';
import { landFeature, LandState } from '../land/land.reducer';
import { goodsFeature, GoodsState } from '../goods/goods.reducer';
import { equipmentFeature, EquipmentState } from '../equipment/equipment.reducer';
import { guarantorFeature, GuarantorState } from '../guarantors/guarantor.reducer';
import { variablesFeature, VariablesState } from '../variables/variables.reducer';
import { resetVariables } from './task.actions';
import { requestErrorsFeature, RequestErrorsState } from '../request-errors/request-errors.reducer';
import { taskDetailsFeature, TaskDetailsState } from '../task-details/task-details.reducer';
import { bailContractsFeature, BailContractsState } from '../bail-contracts/bail-contracts.reducer';
import {
  beneficiaryDocOperationsFeature,
  BeneficiaryDocOperationsFeatureKeyState,
} from '../beneficiaryDocOperations/beneficiaryDocOperation.reducer';
import {
  documentaryOperationsFeature,
  DocumentaryOperationsFeatureKeyState,
} from '../documentary-operations/documentary-operation.reducer';
import {
  documentEntryListFeature,
  DocumentEntryListFeatureKeyState,
} from '../document-entry-list/document-entry-list.reducer';
import { propertyOwnerFeature, PropertyOwnerState } from '../property-owner/property-owner.reducer';
import { processDetailsFeature, processDetailsState } from '../process-details/process-details.reducer';

export const TASK_FEATURE_KEY = 'taskV2';

export interface TaskState {
  [taskDetailsFeature.name]: TaskDetailsState;
  [processDetailsFeature.name]: processDetailsState;
  [agreementsFeature.name]: AgreementState;
  [conditionsFeature.name]: ConditionsState;
  [currentCreditsFeature.name]: CurrentCreditsState;
  [borrowerGroupFeature.name]: BorrowerGroupState;
  [creditHistoryFeature.name]: CreditHistoryState;
  [assetFeature.name]: AssetState;
  [agreementGuarantyFeature.name]: agreementGuarantyState;
  [realtyFeature.name]: RealtyState;
  [depositFeature.name]: DepositState;
  [vehicleFeature.name]: VehiclesState;
  [landFeature.name]: LandState;
  [goodsFeature.name]: GoodsState;
  [equipmentFeature.name]: EquipmentState;
  [guarantorFeature.name]: GuarantorState;
  [bailContractsFeature.name]: BailContractsState;
  [variablesFeature.name]: VariablesState;
  [requestErrorsFeature.name]: RequestErrorsState;
  [beneficiaryDocOperationsFeature.name]: BeneficiaryDocOperationsFeatureKeyState;
  [documentaryOperationsFeature.name]: DocumentaryOperationsFeatureKeyState;
  [documentEntryListFeature.name]: DocumentEntryListFeatureKeyState;
  [propertyOwnerFeature.name]: PropertyOwnerState;
}

const combinedReducers = combineReducers({
  [taskDetailsFeature.name]: taskDetailsFeature.reducer,
  [processDetailsFeature.name]: processDetailsFeature.reducer,
  [variablesFeature.name]: variablesFeature.reducer,
  [agreementGuarantyFeature.name]: agreementGuarantyFeature.reducer,
  [agreementsFeature.name]: agreementsFeature.reducer,
  [assetFeature.name]: assetFeature.reducer,
  [bailContractsFeature.name]: bailContractsFeature.reducer,
  [beneficiaryDocOperationsFeature.name]: beneficiaryDocOperationsFeature.reducer,
  [borrowerGroupFeature.name]: borrowerGroupFeature.reducer,
  [conditionsFeature.name]: conditionsFeature.reducer,
  [creditHistoryFeature.name]: creditHistoryFeature.reducer,
  [currentCreditsFeature.name]: currentCreditsFeature.reducer,
  [depositFeature.name]: depositFeature.reducer,
  [documentEntryListFeature.name]: documentEntryListFeature.reducer,
  [documentaryOperationsFeature.name]: documentaryOperationsFeature.reducer,
  [equipmentFeature.name]: equipmentFeature.reducer,
  [goodsFeature.name]: goodsFeature.reducer,
  [guarantorFeature.name]: guarantorFeature.reducer,
  [landFeature.name]: landFeature.reducer,
  [propertyOwnerFeature.name]: propertyOwnerFeature.reducer,
  [realtyFeature.name]: realtyFeature.reducer,
  [requestErrorsFeature.name]: requestErrorsFeature.reducer,
  [vehicleFeature.name]: vehicleFeature.reducer,
});

export const taskReducer = (state: TaskState, action: Action) => {
  if (action.type === resetVariables.type) {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

export const taskFeatureSelector = createFeatureSelector<TaskState>(TASK_FEATURE_KEY);
