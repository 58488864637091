import { createSelector } from '@ngrx/store';
import type { PageType, Tab } from './types';
import { isAllow, tabs } from '../../helpers/task-tabs.helper';
import { selectJson, selectRolesAccess, selectUserRoles } from '@sib/shared/store';
import { selectTabsAccessRestriction } from '../../+state/variables/variables.selectors';
import { taskSettingsFeature } from '../../+state/task-settings/task-settings.reducer';

export const selectTabs = createSelector(
  taskSettingsFeature.selectUserTabsSettingName,
  selectJson,
  (userTabsSettingName = 'task-tabs', json) => json?.[userTabsSettingName] as { type: Tab['type'] }[],
);

export const selectTaskTabs = createSelector(
  selectTabs,

  (taskTabs) =>
    taskTabs
      ? [
          ...tabs.sort(
            (a, b) =>
              taskTabs.findIndex((item) => item.type === a.type) - taskTabs.findIndex((item) => item.type === b.type),
          ),
        ]
      : tabs,
);

export const selectFilteredTabs = createSelector(
  selectUserRoles,
  selectTaskTabs,
  selectRolesAccess,
  selectTabsAccessRestriction,
  taskSettingsFeature.selectRestrictedTabTypes,
  (userRoles, taskTabs, userTabRoles, tabsAccessRestriction, restrictedTabTypes) =>
    taskTabs
      .filter((item) => isAllow(item.type, userRoles, userTabRoles, tabsAccessRestriction))
      .filter((item) => (restrictedTabTypes.length ? restrictedTabTypes.includes(item.type) : true)),
);

export const selectIsTabDenied = (tabType: PageType) =>
  createSelector(
    selectUserRoles,
    selectRolesAccess,
    selectTabsAccessRestriction,
    (userRoles, rolesAccess, tabsAccessRestriction) => {
      isAllow(tabType, userRoles, rolesAccess, tabsAccessRestriction);
    },
  );
