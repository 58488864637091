import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentBeneficiaryDocOperationByAgreementId } from './beneficiary-doc-operations.selectors';
import { selectBeneficiaryDocOperationEntities } from '../../+state/beneficiaryDocOperations/beneficiaryDocOperation.selectors';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryDocOperationsFacadeServices {
  private store = inject(Store);

  public selectBeneficiaryDocOperationEntities$ = this.store.select(selectBeneficiaryDocOperationEntities);

  getCurrentBeneficiaryDocOperationByAgreementId$ = (agreementId: string) =>
    this.store.select(selectCurrentBeneficiaryDocOperationByAgreementId(agreementId));
}
