<div class="logo">
  <sib-logo></sib-logo>
</div>

<div class="box-center">
  <div class="headline">
    <div class="headline__title">CREDIT WORKFLOW</div>
    <div class="headline__description">Вхід в робоче середовище</div>
  </div>
  <form class="form" [formGroup]="loginForm" autocomplete="on" (ngSubmit)="login()">
    <p>
      <mat-form-field>
        <mat-label>Ім'я користувача</mat-label>
        <input
          matInput
          formControlName="username"
          name="username"
          autocomplete="username"
          placeholder="Введіть імʼя користувача"
        />
        <mat-error>
          {{ loginForm.controls.username.getError('formError') }}
        </mat-error>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Пароль</mat-label>
        <input
          matInput
          [type]="passwordType"
          formControlName="password"
          name="password"
          autocomplete="password"
          placeholder="Введіть ваш пароль"
        />

        <mat-icon
          class="eye-icon"
          [svgIcon]="passwordType === 'password' ? 'eye' : 'slash_eye'"
          (click)="changeInputType()"
          matSuffix
        >
        </mat-icon>
        <mat-error>
          {{ loginForm.controls.password.getError('formError') }}
        </mat-error>
      </mat-form-field>
    </p>

    <p>
      <button mat-flat-button type="submit" color="accent">
        <mat-icon>login</mat-icon>
        <span> Увійти в систему </span>
      </button>
    </p>
  </form>
</div>

<div class="info-section">
  Для входу в робоче середовище введіть свої <br />
  <span class="color"> логін та пароль для входу в систему банку </span>
</div>
