<mat-card formGroupName="bpHolders">
  <mat-card-header>
    <mat-card-title>{{ holdersContractConfig.title }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="row">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Номер БП</mat-label>
        <input type="number" matInput (blur)="getGuarantor()" formControlName="bpNumber" />

        <mat-error *ngIf="bpHoldersGroupService.controls.bpNumber.hasError('verifyGuarantor')">
          {{ bpHoldersGroupService.controls.bpNumber.errors?.['verifyGuarantor'] }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>ПІБ (Називний відмінок)</mat-label>
        <input type="text" matInput formControlName="partnerName" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Посада</mat-label>
        <input type="text" matInput formControlName="position" />
      </mat-form-field>
    </div>
  </mat-card-content>

  <mat-card-content>
    <h4>Діє на підставі</h4>

    <mat-radio-group formControlName="signatory">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="radio">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-radio-button [value]="element"></mat-radio-button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="num">
          <mat-header-cell *matHeaderCellDef> №</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.num }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Дата</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notaryCirc">
          <mat-header-cell *matHeaderCellDef> Нотаріальний округ</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.notaryCirc }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notaryName">
          <mat-header-cell *matHeaderCellDef> ПІБ нотаріуса</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.notaryName }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

        <div *matNoDataRow>Нема записів по обраному номеру БП</div>
      </mat-table>

      <mat-error class="radio-error">Виберіть варіант зі списку</mat-error>
    </mat-radio-group>
  </mat-card-content>
</mat-card>
