import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllProposalsTypesList, selectProposalsTypeById } from './proposals-types.selectors';
import { loadProposalsTypes } from './proposals-types.actions';

@Injectable({
  providedIn: 'root',
})
export class ProposalsTypesFacadeService {
  public selectAllProposalsTypesList$ = this.store.select(selectAllProposalsTypesList);
  public selectProposalsTypeById$ = (id: string) => this.store.select(selectProposalsTypeById(Number(id)));

  constructor(private store: Store) {}

  public loadProposalsTypes() {
    this.store.dispatch(loadProposalsTypes());
  }
}
