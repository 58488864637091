import { createSelector } from '@ngrx/store';
import { selectAllRequestErrors } from '../../+state/request-errors/request-errors.selectors';
import { selectActiveErrorsProcess, selectErrorsEntities } from '@sib/shared/store';
import { selectTaskDetails } from '../../+state/task-details/task-details.selectors';

export const selectCountRequestErrorMessages = createSelector(
  selectAllRequestErrors,
  (requestErrors) => requestErrors.filter((i) => i.typeId === 'ERROR' && !i.fix).length,
);

export const selectAvailableErrors = createSelector(
  selectTaskDetails,
  selectActiveErrorsProcess,
  selectErrorsEntities,
  (processInfo, errorsProcessList, allErrors) =>
    errorsProcessList
      ?.filter(
        (i) =>
          i.processId === processInfo.processDefinitionKey && (i.stepId === processInfo.taskDefinitionKey || !i.stepId),
      )
      .map((i) => allErrors[i.errorId!]!) || [],
);
