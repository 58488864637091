import { ChangeDetectorRef } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Call ChangeDetectorRef.markForCheck on every next, error or complete event.
 * @param cdr ChangeDetectorRef
 */
export function markForCheck<T>(cdr: ChangeDetectorRef): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    source.pipe(
      tap({
        next: () => cdr.markForCheck(),
        error: () => cdr.markForCheck(),
        complete: () => cdr.markForCheck(),
      }),
    );
}
