import { DestroyRef, inject, Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { LimitDeadlineTermMeasure } from '@api/loan-approval';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class TermDocumentaryOperationGroupService extends FormGroup<
  ReturnType<typeof TermDocumentaryOperationGroupService.form>
> {
  static readonly form = () => ({
    notLaterLastDay: new FormControl<boolean>(false, { nonNullable: true }),
    limitDeadlineTermCheckBox: new FormControl<boolean>(false, { nonNullable: true }),
    limitDeadlineTerm: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    limitDeadlineTermMeasure: new FormControl<LimitDeadlineTermMeasure | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    daysBeforeEndDepositTermCheckBox: new FormControl<boolean>(false, { nonNullable: true }),
    daysBeforeEndDepositTerm: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    numberMonthsTermCheckBox: new FormControl<boolean>(false, { nonNullable: true }),
    numberMonthsTerm: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  private destroyRef = inject(DestroyRef);

  get notLaterLastDay() {
    return this.controls.notLaterLastDay;
  }
  get limitDeadlineTermCheckBox() {
    return this.controls.limitDeadlineTermCheckBox;
  }
  get limitDeadlineTerm() {
    return this.controls.limitDeadlineTerm;
  }
  get limitDeadlineTermMeasure() {
    return this.controls.limitDeadlineTermMeasure;
  }
  get daysBeforeEndDepositTermCheckBox() {
    return this.controls.daysBeforeEndDepositTermCheckBox;
  }
  get daysBeforeEndDepositTerm() {
    return this.controls.daysBeforeEndDepositTerm;
  }
  get numberMonthsTermCheckBox() {
    return this.controls.numberMonthsTermCheckBox;
  }
  get numberMonthsTerm() {
    return this.controls.numberMonthsTerm;
  }
  constructor() {
    super(TermDocumentaryOperationGroupService.form(), {
      validators: TermDocumentaryOperationGroupService.requiredValidator,
    });

    this.notLaterLastDay.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (this.notLaterLastDay.status === 'DISABLED') return;
      if (value) {
        this.limitDeadlineTermCheckBox.disable();
        this.limitDeadlineTermCheckBox.reset();
        this.daysBeforeEndDepositTermCheckBox.disable();
        this.daysBeforeEndDepositTermCheckBox.reset();
        this.numberMonthsTermCheckBox.disable();
        this.numberMonthsTermCheckBox.reset();

        this.limitDeadlineTerm.reset();
        this.limitDeadlineTerm.disable();
        this.limitDeadlineTermMeasure.reset();
        this.limitDeadlineTermMeasure.disable();
        this.daysBeforeEndDepositTerm.reset();
        this.daysBeforeEndDepositTerm.disable();
        this.numberMonthsTerm.reset();
        this.numberMonthsTerm.disable();
      } else if (
        !this.limitDeadlineTermCheckBox.value ||
        !this.daysBeforeEndDepositTermCheckBox.value ||
        !this.numberMonthsTermCheckBox.value
      ) {
        this.limitDeadlineTermCheckBox.enable();
        this.daysBeforeEndDepositTermCheckBox.enable();
        this.numberMonthsTermCheckBox.enable();
      }
    });

    this.limitDeadlineTermCheckBox.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (!value) {
        this.limitDeadlineTerm.disable();
        this.limitDeadlineTermMeasure.disable();
        this.limitDeadlineTerm.reset();
        this.limitDeadlineTermMeasure.reset();
      } else {
        this.limitDeadlineTerm.enable();
        this.limitDeadlineTermMeasure.enable();
      }
    });

    this.daysBeforeEndDepositTermCheckBox.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (!value) {
        this.daysBeforeEndDepositTerm.disable();
        this.daysBeforeEndDepositTerm.reset();
      } else {
        this.daysBeforeEndDepositTerm.enable();
      }
    });

    this.numberMonthsTermCheckBox.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (!value) {
        this.numberMonthsTerm.disable();
        this.numberMonthsTerm.reset();
      } else {
        this.numberMonthsTerm.enable();
      }
    });
  }

  private static requiredValidator(control: AbstractControl): ValidationErrors | null {
    const notLaterLastDay = control.get('notLaterLastDay')?.value;
    const limitDeadlineTermCheckBox = control.get('limitDeadlineTermCheckBox')?.value;
    const daysBeforeEndDepositTermCheckBox = control.get('daysBeforeEndDepositTermCheckBox')?.value;
    const numberMonthsTermCheckBox = control.get('numberMonthsTermCheckBox')?.value;

    if (
      !notLaterLastDay &&
      !limitDeadlineTermCheckBox &&
      !daysBeforeEndDepositTermCheckBox &&
      !numberMonthsTermCheckBox
    ) {
      return { required: true };
    }

    return null;
  }
}
