<mat-card formGroupName="loanGrantingProcedure">
  <mat-card-header>
    <mat-card-title>Порядок надання кредиту</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <sib-currency-input>
      <mat-form-field>
        <mat-label>Сума</mat-label>
        <input type="text" matInput formControlName="agreementSum" currencyMask />
      </mat-form-field>

      <mat-form-field currency>
        <mat-label>Валюта</mat-label>
        <input type="text" readonly matInput formControlName="currencyCode" />
      </mat-form-field>
    </sib-currency-input>

    <div class="equivalent" *ngIf="formGroup.controls.currencyCode.value !== 'UAH'">
      <p>Еквівалент в грн. на дату {{ formGroup.controls.rateDate.value | date }}</p>
      <div class="row">
        <sib-currency-input>
          <mat-form-field>
            <mat-label>Еквівалент в грн.</mat-label>
            <input type="text" matInput formControlName="agreementSumUAH" currencyMask />
          </mat-form-field>
        </sib-currency-input>
      </div>
    </div>

    <mat-form-field>
      <mat-label>Цільове призначення кредиту</mat-label>
      <input type="text" matInput formControlName="lendingPurpose" />
    </mat-form-field>

    <p>Банківські реквізити для зарахування кредитних коштів:</p>

    <mat-form-field>
      <mat-label>Поточний рахунок</mat-label>
      <mat-select placeholder="Виберіть поточний рахунок" formControlName="curAccount">
        <mat-option *ngFor="let item of account$ | async" [value]="item.accNum">
          {{ item.accNum }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="komPayFlag">
      Позичальник сплачує комісію за надання кредиту за рахунок кредитних коштів
    </mat-checkbox>
  </mat-card-content>
</mat-card>
