import { HttpStatusCode } from '@angular/common/http';

/* eslint-disable */
export abstract class Error {
  abstract status?: number;

  constructor(error?: any) {}
}

export class BadRequest extends Error {
  status = HttpStatusCode.BadRequest;

  constructor(private error: { message: string }) {
    super(error);
  }

  get errorMessage() {
    return this.error.message;
  }
}

export class Unauthorized extends Error {
  status = HttpStatusCode.Unauthorized;
}

export class Forbidden extends Error {
  status = HttpStatusCode.Forbidden;
}

export class NotFound extends Error {
  status = HttpStatusCode.NotFound;

  constructor(private error: { message: string }) {
    super(error);
  }

  get errorMessage() {
    return this.error.message;
  }
}

export class MethodNotAllowed extends Error {
  status = HttpStatusCode.MethodNotAllowed;
}

export class NotAcceptable extends Error {
  status = HttpStatusCode.NotAcceptable;
}

export class ProxyAuthenticationRequired extends Error {
  status = HttpStatusCode.ProxyAuthenticationRequired;
}

export class RequestTimeout extends Error {
  status = HttpStatusCode.RequestTimeout;
}

export class UnprocessableEntity extends Error {
  status = HttpStatusCode.UnprocessableEntity;
}

export class SapGeneralError extends Error {
  static readonly statusCode = 461;
  readonly status = SapGeneralError.statusCode;

  constructor(
    error: any,
    public message: string,
    public code: string,
    public extraInfo: {
      lang: string;
      value: string;
    },
  ) {
    super(error);
  }

  get errorMessage() {
    return `${this.extraInfo.value} Код помилки: ${this.code}`;
  }
}

export class SapMultipleError extends Error {
  static readonly statusCode = 460;
  readonly status = SapMultipleError.statusCode;

  constructor(
    error: any,
    public content: Record<
      string,
      {
        message: { lang: string; value: string };
        code: string;
      }
    >,
  ) {
    super(error);
  }
}

export class InternalServerError extends Error {
  status = HttpStatusCode.InternalServerError;
  message = 'Невизначена помилка серверу, зверніться будь ласка до адміністратора.';
}

export class ServiceUnavailable extends Error {
  status = HttpStatusCode.ServiceUnavailable;
  message = 'Сервер не відповідає, зверніться будь ласка до адміністратора.';
}

export class UnexpectedError extends Error {
  status = undefined;
  message = 'Перевірте інтернет зʼєднання та спробуйте ще раз, або зверніться до адміністратора.';
}
/* eslint-enable */
