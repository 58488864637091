import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DefaultService, HistoricProcessInstanceDto } from '@api/camunda';
import { tap } from 'rxjs';
import { processDetailsActions } from './process-details.action';
import { selectProcessDefinitionKey, selectState } from './process-details.selectors';

@Injectable({ providedIn: 'root' })
export class ProcessDetailsFacadeService {
  private readonly store = inject(Store);
  private readonly camundaService = inject(DefaultService);

  public selectState$ = this.store.select(selectState);
  public selectProcessDefinitionKey$ = this.store.select(selectProcessDefinitionKey);

  public getHistoryProcessDetails(id: string) {
    return this.camundaService
      .getHistoryProcessInstance1({ id })
      .pipe(tap((result) => this.setRootProcessDetails(result)));
  }

  public setRootProcessDetails(rootProcessDetails: HistoricProcessInstanceDto) {
    this.store.dispatch(processDetailsActions.loadSuccess({ processDetails: rootProcessDetails }));
  }
}
