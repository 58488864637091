import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadRequestTypes, selectRequestTypes } from '@sib/shared/store';

@Injectable({
  providedIn: 'root',
})
export class RequestTypesFacadeService {
  public requestTypes$ = this.store.select(selectRequestTypes);
  constructor(private store: Store) {}

  public loadRequestTypes() {
    this.store.dispatch(loadRequestTypes());
  }
}
