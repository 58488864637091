import { createReducer, on } from '@ngrx/store';
import { UserTabRolesGroupAction } from './user-tab-roles.actions';
import { RoleAccessDto } from '@api/dictionaries';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const userTabRoleKey = 'userTabRole';

export interface UserTabRoleState extends EntityState<RoleAccessDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<RoleAccessDto>({
  sortComparer: false,
});

export const initialState = adapter.getInitialState({
  isLoading: false,
});

export const userTabToleReducer = createReducer(
  initialState,
  on(UserTabRolesGroupAction.loadSuccess, (state, { userTypeRoles }) => ({
    ...adapter.setAll(userTypeRoles, state),
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();
