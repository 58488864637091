import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as RiskSegmentActions from './risk-segments.actions';
import { RiskSegmentDto } from '@api/dictionaries';

export const riskSegmentsFeatureKey = 'risk-segment';

export interface State extends EntityState<RiskSegmentDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<RiskSegmentDto>({
  selectId: (riskSegment) => riskSegment.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(RiskSegmentActions.loadRiskSegments, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(RiskSegmentActions.loadRiskSegmentsSuccess, (state, { riskSegments }) => ({
    ...adapter.setAll(riskSegments, state),
    isLoading: false,
  })),

  on(RiskSegmentActions.loadRiskSegmentsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectIsLoading = (state: State) => state.isLoading;
