import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FinancingConditionsAgreementGeneralService extends FormGroup<
  ReturnType<typeof FinancingConditionsAgreementGeneralService.form>
> {
  static readonly form = () => ({
    productId: new FormControl<number | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    conditionsSetId: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    productName: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    agreementSum: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    currencyCode: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true },
    ),
    agreementTerm: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    agreementTermMeasure: new FormControl<string>('Дні', { validators: [Validators.required], nonNullable: true }),
    startDate: new FormControl<Date | undefined>(
      { value: undefined, disabled: true },
      {
        nonNullable: true,
      },
    ),
    endDate: new FormControl<Date | undefined>(
      { value: undefined, disabled: true },
      {
        nonNullable: true,
      },
    ),
    dealNumberSAP: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    promotion: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    dealTitle: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    isActual: new FormControl<boolean>(false, { nonNullable: true }),
  });

  constructor() {
    super(FinancingConditionsAgreementGeneralService.form());
  }

  get dealNumberSAP() {
    return this.controls.dealNumberSAP;
  }
  get isActual() {
    return this.controls.isActual;
  }

  get productName() {
    return this.controls.productName;
  }

  get currencyCode() {
    return this.controls.currencyCode;
  }

  get agreementSum() {
    return this.controls.agreementSum;
  }

  get conditionsSetId() {
    return this.controls.conditionsSetId;
  }
}
