import { createAction, props } from '@ngrx/store';
import { ProductCategoryDto } from '@api/dictionaries';

export const loadProductCategories = createAction('[Dictionaries/API] Load Product Categories');
export const loadProductCategoriesSuccess = createAction(
  '[Dictionaries/API] Load Product Categories Success',
  props<{ productCategories: ProductCategoryDto[] }>(),
);
export const loadProductCategoriesFailure = createAction(
  '[Dictionaries/API] Load Product Categories Failure',
  props<{ error: unknown }>(),
);
