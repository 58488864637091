/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CategoryTypeDto } from '../models/CategoryTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class CategoryTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all category types
     * Returns all category types.
     * @returns CategoryTypeDto all category types
     * @throws ApiError
     */
    public getApiProductCategoryTypes(): Observable<Array<CategoryTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/product-category-types',
        });
    }

    /**
     * Create Category type
     * Method for creating category type
     * @returns CategoryTypeDto Category type created
     * @throws ApiError
     */
    public postApiProductCategoryTypes({
        requestBody,
    }: {
        /**
         * Category type object that needs to be created.
         */
        requestBody: CategoryTypeDto,
    }): Observable<CategoryTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/product-category-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get category type
     * Get category type by ID.
     * @returns any category type found
     * @throws ApiError
     */
    public getApiProductCategoryTypes1({
        id,
    }: {
        /**
         * Category type id cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title?: string;
        code?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/product-category-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Category type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update category type
     * Updates the category type with the given ID.
     * @returns any Category type updated
     * @throws ApiError
     */
    public putApiProductCategoryTypes({
        id,
        requestBody,
    }: {
        /**
         * Category type ID. Cannot be empty.
         */
        id: number,
        /**
         * Category type object that needs to be updated.
         */
        requestBody: CategoryTypeDto,
    }): Observable<{
        id: number;
        title?: string;
        code?: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/product-category-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Category type id doesn't match Category type id from body`,
                404: `Category type with such id not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete Category type
     * Deletes the Category type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiProductCategoryTypes({
        id,
    }: {
        /**
         * Category type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/product-category-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
