import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  checkUserPermission,
  selectCurrentUser,
  selectId,
  selectLogin,
  selectUserGroups,
  selectUserRoles,
} from './auth.selectors';
import { upsertUserSetting } from './auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthFacadeService {
  public getCurrentUser$ = this.store.select(selectCurrentUser);
  public getUserGroups$ = this.store.select(selectUserGroups);
  public checkUserPermission$ = (permission: string) => this.store.select(checkUserPermission(permission));
  public selectUserRoles$ = this.store.select(selectUserRoles);
  public selectLogin$ = this.store.select(selectLogin);
  public selectId$ = this.store.select(selectId);

  constructor(private store: Store) {}

  upsertUserSetting(userSettings: Record<string, any>) {
    this.store.dispatch(upsertUserSetting({ userSettings }));
  }
}
