import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectActiveAssetCategory,
  selectAllAssetCategory,
  selectAssetCategoryEntities,
} from './asset-category.selectors';
import { loadAssetCategory } from './asset-category.actions';

@Injectable({
  providedIn: 'root',
})
export class AssetCategoryFacadeService {
  public assetCategories$ = this.store.select(selectAllAssetCategory);
  public assetCategoriesEntities$ = this.store.select(selectAssetCategoryEntities);
  public assetActiveCategories$ = this.store.select(selectActiveAssetCategory);

  constructor(private store: Store) {}

  public loadAssetCategories() {
    this.store.dispatch(loadAssetCategory());
  }
}
