import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadTaskV2VariablesSuccess, resetVariables } from '../../+state/task/task.actions';
import { VariablesDto } from '@api/loan-approval';

@Injectable({
  providedIn: 'root',
})
export class TaskV2FacadeService {
  private store = inject(Store);

  public loadTaskV2VariablesSuccess(variables: VariablesDto) {
    this.store.dispatch(loadTaskV2VariablesSuccess({ variables }));
  }

  public resetVariables() {
    this.store.dispatch(resetVariables());
  }
}
