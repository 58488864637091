import { createReducer, on } from '@ngrx/store';
import * as SOD from './sods.action';
import { SodDto } from '@api/dictionaries';

export const SODFeatureKey = 'SOD';

export interface ExpandedSOD extends SodDto {
  nameWithId?: string;
}

export interface State {
  isLoading: boolean;
  SOD: ExpandedSOD[];
  error: any;
}

export const initialState: State = {
  isLoading: false,
  SOD: [],
  error: null,
};

export const reducer = createReducer(
  initialState,

  on(SOD.loadSOD, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(SOD.loadSODSuccess, SOD.loadSODNameSuccess, (state, { SOD }) => ({
    ...state,
    isLoading: false,
    SOD,
  })),
  on(SOD.loadSODFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const selectSODState = (state: State) => state.SOD;
