<mat-card>
  <mat-card-header>
    <mat-card-title>Умови надання траншу</mat-card-title>
  </mat-card-header>

  <mat-card-content formGroupName="termsGrantingTranche">
    <mat-radio-group class="mat-radio-group-global-custom" formControlName="conditionTerm">
      <div class="form-group">
        <mat-radio-button value="conditionTermMinMax">
          В рішенні КК передбачено мін. та макс. строки траншів
        </mat-radio-button>
        <div class="row" *ngIf="formGroup.conditionTermType.value === 'conditionTermMinMax'">
          <mat-form-field>
            <mat-label>не менше</mat-label>
            <input type="text" matInput formControlName="termMin" digitOnly />
            <span matTextSuffix>днів</span>
          </mat-form-field>
          <mat-form-field>
            <mat-label>не більше</mat-label>
            <input type="text" matInput formControlName="termMax" digitOnly />
            <span matTextSuffix>днів</span>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <mat-radio-button value="conditionTermMin">В рішенні КК передбачено лише мін. строк траншу</mat-radio-button>
        <div>
          <mat-form-field *ngIf="formGroup.conditionTermType.value === 'conditionTermMin'">
            <mat-label>не менше</mat-label>
            <input type="text" matInput formControlName="termMin" digitOnly />
            <span matTextSuffix>днів</span>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <mat-radio-button value="conditionTermMax">В рішенні КК передбачено лише макс. строк траншу</mat-radio-button>
        <div>
          <mat-form-field *ngIf="formGroup.conditionTermType.value === 'conditionTermMax'">
            <mat-label>не більше</mat-label>
            <input type="text" matInput formControlName="termMax" digitOnly />
            <span matTextSuffix>днів</span>
          </mat-form-field>
        </div>
      </div>

      <mat-radio-button value="conditionTermMiss">
        В рішенні КК не передбачено мін./макс. строк траншу
      </mat-radio-button>
      <mat-divider></mat-divider>
    </mat-radio-group>

    <div class="warn">
      <p *ngIf="formGroup.termMin.hasError('minMax') || formGroup.termMax.hasError('minMax')">
        Макс. значення має бути більшим ніж мін.
      </p>

      <p *ngIf="formGroup.termMax.hasError('max')">{{ formGroup.termMax.getError('max') }}</p>
    </div>

    <div class="form-group">
      <mat-checkbox formControlName="conditionSumMin">
        В рішенні КК передбачено мінімальну суму для траншів
      </mat-checkbox>
      <div>
        <mat-form-field *ngIf="formGroup.conditionSumMin.value">
          <mat-label>Сума</mat-label>
          <input type="text" matInput formControlName="sumMin" currencyMask />
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>
