import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';
/**
 * TODO fix import
 */
import { FormControl } from '@angular/forms';
import { formatDateUTC } from './date-utils';

// there is no type for library
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export * as deepEqual from 'deep-equal';

/* eslint-disable*/
export function convertArrayToObjectTyped<T extends Record<string, any>>(array: T[], key: string): Record<string, T> {
  return array.reduce((obj, item) => ({ ...obj, [item[key]]: item }), {});
}

/* eslint-disable*/

export function checkIsObjectKeyValueEmpty(object: Record<string, unknown>): boolean {
  return Object.values(object).every((x) => x === null || x === '');
}

export function exhaustiveCheck(param?: never): never {
  throw new Error('should not reach here');
}

export function convertToUniqueArray<T extends Record<string, unknown>>(array: T[], key: string): T[] {
  return array.reduce<T[]>((acc, current) => {
    const findedElement = acc.find((item: T) => item[key] === current[key]);
    if (!findedElement) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
}

// ...(({ course_range_date, ...rest }) => rest)(this.courseForm.value),
export function removeObjectProperty<T extends Record<string, unknown>, U extends string[]>(
  obj: T,
  keys: U,
): Omit<T, U[number]> {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
}

export function getObjectByString(object: any, string: string): any {
  string = string.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  string = string.replace(/^\./, ''); // strip a leading dot
  const array = string.split('.');
  for (let i = 0; i < array.length; ++i) {
    const key = array[i];
    if (key in object) {
      object = object[key];
    } else {
      return;
    }
  }
  return object;
}

export function blobToBase64(blob: Blob) {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function b64toBlob(base64: string): Blob {
  // Split into two parts
  const parts = base64.split(';base64,');
  // Hold the content type
  const contentType = parts[0].split(':')[1];
  // Decode Base64 string
  const decodedData = window.atob(parts[1]);
  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);
  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: contentType });
}

export function createFormData(formValue: any): FormData {
  const formData = new FormData();
  Object.keys(formValue).forEach((key: string) => {
    if (formValue[key] instanceof Object && !Array.isArray(formValue[key])) {
      formData.append(key, b64toBlob(formValue[key].base64), formValue[key].name);
    } else if (Array.isArray(formValue[key]) && formValue[key].length) {
      formValue[key].forEach((attachment: any) =>
        formData.append(`${key}[]`, b64toBlob(attachment.base64), attachment.name),
      );
    } else {
      formData.append(key, formValue[key] || '');
    }
  });

  return formData;
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function convertArrayToTree(array: any[], key: string, id: number | null = null): any[] {
  return array
    .filter((item) => item[key] === id)
    .map((item) => ({
      ...item,
      children: convertArrayToTree(array, key, item.id),
    }));
}

export function getUniqueUuid(): string {
  return uuidv4();
}

export function checkStringTrueFalse(val: 'true' | 'false'): boolean {
  return val === 'true' || (val === 'false' ? false : val);
}

export function onGetDate() {
  return formatDateUTC(new Date());
}

// @deprecated use selectUserRole instead
export function getUserRoles() {
  const decodedToken = jwt_decode<any>(localStorage.getItem('token')!);
  const userRoles = decodedToken.groups;

  return userRoles.filter((i: string) => {
    return [
      'task_reassignment',
      'system_user',
      'application_cancellation',
      'change_responsible',
      'dictionary_admin',
      'request_list_access',
      'doc_block',
      'request_list_tasks',
      'valuation_list_tasks',
      'valuation_list_tasks',
      'agro_accountable',
    ].every((item) => item !== i);
  });
}

// @deprecated use selectUserRole instead
export const getUserRole = () => getUserRoles()[0];

// Array.prototype.filter() sometimes destroys type, this implementation fixes it
export const typedFilter = <T>(a: T[], f: (e: T) => boolean): T[] => a.filter(f);

export function rangeValidator(control: FormControl): { [key: string]: any } | null {
  const value = Number(control.value);
  if (value === 1 || (value >= 11 && value <= Number.MAX_SAFE_INTEGER)) {
    return null;
  } else {
    return { range: true };
  }
}

export function rangeAmount(amount: string) {
  const value = Number(amount);
  return value === 1 || (value >= 11 && value <= Number.MAX_SAFE_INTEGER);
}

export const filterUnique = <T = string>(array: T[]) => new Set(array);

export const hasDuplicates = <T = string>(array: T[]) => filterUnique(array).size !== array.length;

export const isAllDuplicates = <T = string>(array: T[]) => filterUnique(array).size === 1;
