import { createSelector } from '@ngrx/store';
import * as AssessmentTypes from './assessment-types.reducer';
import { selectAssessmentTypesState } from '../+shell/reducers.index';

export const selectAssessmentTypes = createSelector(
  selectAssessmentTypesState,
  AssessmentTypes.selectAssessmentTypesState,
);

export const selectAssessmentTypesEntities = createSelector(
  selectAssessmentTypesState,
  AssessmentTypes.selectAssessmentTypesEntities,
);
