import { inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiError, SapIntegrationService } from '@api/loan-approval';
import { SapGeneralError } from '@sib/shared/util';
import { of, throwError } from 'rxjs';
import { getRequisites } from '@sib/task/shared/util';

@Injectable({
  providedIn: 'root',
})
export class RequisitesService {
  private sapIntegrationService = inject(SapIntegrationService);

  getPartnerPasspDoc(partnerBpCode: string) {
    return this.sapIntegrationService.getSapGetPartnerFullName({ bpCode: partnerBpCode }).pipe(
      catchError((error: ApiError) => {
        if (error.body instanceof SapGeneralError) {
          return of(undefined);
        }
        return throwError(() => error);
      }),
      map((result) => result),
    );
  }
  getPartnerRequisites(partnerBpCode: string) {
    return this.sapIntegrationService.getSapGetPartnerIndividualInfo({ bpCode: partnerBpCode }).pipe(
      catchError((error: ApiError) => {
        if (error.body instanceof SapGeneralError) {
          return of(undefined);
        }
        return throwError(() => error);
      }),
      map((result) => (result ? getRequisites(result) : { addressLocation: '', addressLetter: '', email: '' })),
    );
  }

  getOrgUnitAddress(unitCode: string | number) {
    return this.sapIntegrationService
      .getSapOrgUnitAddress({
        orgUnitId: `${unitCode}`,
      })
      .pipe(
        catchError((error: ApiError) => {
          if (error.body instanceof SapGeneralError) {
            return of(undefined);
          }
          return throwError(() => error);
        }),
        map((result) => result || { orgUnitId: '', city: '', address: '' }),
      );
  }

  getSapGetPartnerSpouseData(bpCode: string | number) {
    return this.sapIntegrationService.getSapGetPartnerSpouseData({ bpCode: String(bpCode) }).pipe(
      catchError((error: ApiError) => {
        if (error.body instanceof SapGeneralError) {
          return of(undefined);
        }
        return throwError(() => error);
      }),
      map((result) => result || []),
    );
  }
}
