/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { VotingOptionDto } from '../models/VotingOptionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class VotingOptionService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all voting options
     * Returns all voting options.
     * @returns VotingOptionDto all voting options
     * @throws ApiError
     */
    public getApiVotingOptions(): Observable<Array<VotingOptionDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/voting-options',
        });
    }

    /**
     * Create voting option
     * Method for creating voting option.
     * @returns any voting option created
     * @throws ApiError
     */
    public postApiVotingOptions({
        requestBody,
    }: {
        /**
         * Voting option object that needs to be created.
         */
        requestBody: VotingOptionDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/voting-options',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Get voting option
     * Get voting option by ID.
     * @returns any voting option found
     * @throws ApiError
     */
    public getApiVotingOptions1({
        id,
    }: {
        /**
         * Voting option ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        title: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/voting-options/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `voting option not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update voting option
     * Updates the voting option with the given ID.
     * @returns any voting option updated
     * @throws ApiError
     */
    public putApiVotingOptions({
        id,
        requestBody,
    }: {
        /**
         * Voting option ID. Cannot be empty.
         */
        id: number,
        /**
         * Voting option object that needs to be created.
         */
        requestBody: VotingOptionDto,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/voting-options/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete voting option
     * Deletes the voting option with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiVotingOptions({
        id,
    }: {
        /**
         * Voting option ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/voting-options/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
