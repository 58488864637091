import { createAction, props } from '@ngrx/store';
import { CheckupDto } from '@api/dictionaries';

export const loadCheckups = createAction('[Checkups/API] Load Сheckups');

export const loadCheckupsReset = createAction('[Checkups/API] Load Сheckups Reset');
export const loadCheckupsSuccess = createAction(
  '[Checkups/API] Load Сheckups Success',
  props<{ checkups: CheckupDto[] }>(),
);
export const loadCheckupsFailure = createAction('[Checkups/API] Load Сheckups Failure', props<{ error: unknown }>());
