import { createReducer, on } from '@ngrx/store';
import * as Assessment from './asset-subtypes.actions';
import { AssetSubtypeDto } from '@api/loan-org-structure';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const assetSubTypesFeatureKey = 'asset-subtypes';

export interface State extends EntityState<AssetSubtypeDto> {
  isLoading: boolean;
}

export const adapter = createEntityAdapter<AssetSubtypeDto>({
  selectId: (item) => item.astCode,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,

  on(Assessment.loadAssetSubTypes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(Assessment.loadAssetSubTypesSuccess, (state, { assetSubTypes }) => ({
    ...adapter.setAll(assetSubTypes, state),
    isLoading: false,
  })),
  on(Assessment.loadAssetSubTypesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAssetSubTypesState = selectAll;
