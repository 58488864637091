import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { loadTaskV2VariablesSuccess } from '../task/task.actions';
import { Good } from '@api/loan-approval';

const goodsFeatureKey = 'goods';

export type GoodsState = EntityState<Good>;

export const adapter = createEntityAdapter<Good>({ selectId: (item) => item.assetId });

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(loadTaskV2VariablesSuccess, (state, { variables }) => adapter.setAll(variables.goods || [], state)),
);

export const goodsFeature = createFeature({
  name: goodsFeatureKey,
  reducer,
  extraSelectors: ({ selectGoodsState }) => ({
    ...adapter.getSelectors(selectGoodsState),
  }),
});

export const { selectIds, selectEntities, selectAll, selectTotal } = goodsFeature;
