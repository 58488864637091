import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProductCategoryTypesAction from './product-category-types.actions';
import * as ProductCategoryTypesSelectors from './product-category-types.selections';
import { Store } from '@ngrx/store';
import { CategoryTypeService } from '@api/dictionaries';

@Injectable()
export class ProductCategoryTypesEffects {
  loadProductCategoryTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCategoryTypesAction.loadProductCategoryTypes),
      concatLatestFrom((action) => [this.store$.select(ProductCategoryTypesSelectors.selectAllProductCategoryTypes)]),
      switchMap(([action, productCategoryTypes]) =>
        (productCategoryTypes.length
          ? of(productCategoryTypes)
          : this.categoryTypeService.getApiProductCategoryTypes()
        ).pipe(
          map((response) =>
            ProductCategoryTypesAction.loadProductCategoryTypesSuccess({
              productCategoryTypes: response,
            }),
          ),
          catchError((error) => of(ProductCategoryTypesAction.loadProductCategoryTypesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store$: Store, private categoryTypeService: CategoryTypeService) {}
}
