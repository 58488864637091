/* global EndUserLibraryLoader */
/* eslint-disable */
// @ts-nocheck

/**
 * BNPPUA Ukrsibbank service-layer by SL in ISDC to use the euswll.js/eusw.js by IIT
 * Release v1.1.0_build_220401
 * #! This possible to use without direct announcement of objects as:
 * #! &lt;object class="edge://extensions/?id=jffafkigfgmjafhpkoibhfefeaebmccg/" /
 * id="formSign" style="display: none; visibility: hidden; "&gt;&lt;/object&gt;
 * #! &lt;object class="chrome-extension://jffafkigfgmjafhpkoibhfefeaebmccg/" /
 * id="formSign" style="display: none; visibility: hidden; "&gt;&lt;/object&gt;
 * #! or as ActiveX mode:
 * #! &lt;object classid="clsid:A7D1E5B8-F3D4-480C-8F2E-125D2A7AE971" /
 * id="formSign" style="display: none"&gt;&lt;/object&gt;
 * #! (p.s. there are some problems when use callback functions)
 */

/*
<!--meta http-equiv="X-UA-Compatible" content="IE=11"-->
<!--meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/-->
<!--object classid="clsid:A7D1E5B8-F3D4-480C-8F2E-125D2A7AE971" id="formSign" style="display: none"></object-->
<!--object class="edge://extensions/?id=jffafkigfgmjafhpkoibhfefeaebmccg/" id="formSign" style="/ * display: none; * / / * visibility: hidden; * /"></object-->
<!--object class="extension://jffafkigfgmjafhpkoibhfefeaebmccg" id="formSign" style="/ * display: none; * / / * visibility: hidden; * /"></object-->
<!--object class="chrome-extension://jffafkigfgmjafhpkoibhfefeaebmccg/" id="formSign" style="/ * display: none; * / / * visibility: hidden; * /"></object-->
include("./eusw.js");
include("./euswll.js");
*/

declare let EndUserLibraryLoader: any;

var isEnabled = function (elem) {
  var result = !elem.disabled;
  if (result) elem.style.color = 'black';
  else elem.style.color = 'silver';
  return result;
};

var toEnable = function (elem) {
  var result = isEnabled(elem);
  elem.style.color = 'black';
  elem.removeAttribute('disabled');
  return result;
};

var toDisable = function (elem) {
  var result = isEnabled(elem);
  elem.style.color = 'silver';
  elem.setAttribute('disabled', ' ');
  return result;
};

export const EuswSL = {
  id: 'euSign',
  obj: null,
  isDebug: false,
  EU_LIBRARY_TYPE: EndUserLibraryLoader./*LIBRARY_TYPE_DEFAULT/*LIBRARY_TYPE_ACTIVE_X */ LIBRARY_TYPE_WEB_EXTENSION,
  EU_LANG: EndUserLibraryLoader.EU_DEFAULT_LANG,
  autoload: true /*if false then -> 1)EuswSL.load(); -> 2) EuswSL.runtimeInitialize();*/,
  isRuntimeInitialize: null,
  isUiMode: true,
  loader: null,
  isLoaded: null,
  isKeyReaded: null,
  isKeyReadCertShow: false,
  privateKeyUserCode: null,
  lastError: null,
  lastInfo: null,
  onlyWebExt: true,

  /* ********************************** */
  verifySign: function (sign, data, successFunction, errorFunction) {
    var info = 'EuswSL->verifySign';
    var result = null;
    try {
      if (!EuswSL.runtimeInitialize()) {
        // alert(info +': invalid EuswSL->runtimeInitialize');
        return result;
      }
      EuswSL.iResetOperation(info);

      result = EuswSL.iVerify(info, sign, data, false, successFunction, errorFunction);

      return result;
    } catch (e) {
      EuswSL.lastError = info + '[catch]: ' + e;
      console.log(EuswSL.lastError);
      if (EuswSL.obj !== null) {
        try {
          EuswSL.iResetOperation(info);
        } catch (ee) {}
      }
      return result;
    }
  } /* ~verifySign */,

  /* ************************** */
  getSign: function (data, successFunction, errorFunction) {
    var info = 'EuswSL->getSign';
    var step = '';
    var result = null;
    try {
      step = 'EuswSL.runtimeInitialize';
      if (!EuswSL.runtimeInitialize()) {
        // alert(info +': invalid EuswSL->runtimeInitialize');
        return result;
      }

      EuswSL.readKey();

      step = 'EuswSL.iSign';
      result = EuswSL.iSign(info, data, successFunction, errorFunction);

      //iContinueSign(info, '' +data);
      //result = EuswSL.iEndSign(info);

      return result;
    } catch (e) {
      EuswSL.lastError = info + ' [catch,' + step + ']: ' + e;
      console.log(EuswSL.lastError);
      if (EuswSL.obj !== null) {
        try {
          EuswSL.iResetOperation(info);
        } catch (ee) {}
      }
      return result;
    }
  } /* ~getSign */,

  /* ******************** */
  getHash: function (data) {
    var info = 'EuswSL->getHash';
    var result = null;
    try {
      if (!EuswSL.runtimeInitialize()) {
        // alert(info +': invalid EuswSL->runtimeInitialize');
        return result;
      }

      EuswSL.iContinueHash(info, data);
      result = EuswSL.iEndHash(info);

      return result;
    } catch (e) {
      EuswSL.lastError = info + '[catch]: ' + e;
      console.log(EuswSL.lastError);
      if (EuswSL.obj !== null) {
        try {
          EuswSL.iResetOperation(info);
        } catch (ee) {}
      }
      return result;
    }
  } /* ~getHash */,

  /* ***************************** */
  getRawSignOnHash: function (data) {
    var info = 'EuswSL->getEdsOnHash';
    var result = EuswSL.getHash(data);
    try {
      if (EuswSL.isKeyReaded !== true) {
        EuswSL.readKey();
      } else {
        EuswSL.iResetOperation(info);
      }

      return EuswSL.iRawSignHash(info, result);
    } catch (e) {
      EuswSL.lastError = info + '[catch]: ' + e;
      console.log(EuswSL.lastError);
      if (EuswSL.obj !== null) {
        try {
          //tut ?!
          EuswSL.iResetOperation(info);
        } catch (ee) {}
      }
      return result;
    }
  } /* ~getEdsOnHash */,

  /* **************** */
  readKey: function () {
    var info = 'EuswSL->readKey';
    var step = '';
    try {
      step = 'EuswSL.runtimeInitialize';
      EuswSL.runtimeInitialize();

      if (EuswSL.isKeyReaded === true) {
        // alert(info +': invalid EuswSL->runtimeInitialize');
        return EuswSL.isKeyReaded;
      }
      step = 'EuswSL.iResetOperation';
      EuswSL.iResetOperation(info);

      step = 'EuswSL.iResetPrivateKey';
      EuswSL.iResetPrivateKey(info);

      step = 'EuswSL.iReadPrivateKey';
      EuswSL.iReadPrivateKey(info);

      if (EuswSL.isKeyReadCertShow) {
        step = 'EuswSL.iShowOwnCertificate';
        EuswSL.iShowOwnCertificate(info);
      }
    } catch (e) {
      EuswSL.isKeyReaded = false;
      EuswSL.lastError = info + ' [catch-' + step + ']: Ключ не активирован. ' + e;
      console.log(EuswSL.lastError);
    }
    return EuswSL.isKeyReaded;
  } /* ~readKey */,

  /* **************** */
  readKeySilently: function (typeIndex, devIndex, password) {
    var info = 'EuswSL->readKeySilently';
    var step = '';
    try {
      step = 'EuswSL.runtimeInitialize';
      EuswSL.runtimeInitialize();
      if (!EuswSL.runtimeInitialize()) {
        // alert(info +': invalid EuswSL->runtimeInitialize');
        return EuswSL.isKeyReaded;
      }

      step = 'EuswSL.iResetOperation';
      EuswSL.iResetOperation(info);

      step = 'EuswSL.iResetPrivateKey';
      EuswSL.iResetPrivateKey(info);

      step = 'EuswSL.iReadPrivateKeySilently';
      EuswSL.iReadPrivateKeySilently(info, typeIndex, devIndex, password);

      if (EuswSL.isKeyReadCertShow) {
        step = 'EuswSL.iShowOwnCertificate';
        EuswSL.iShowOwnCertificate(info);
      }
    } catch (e) {
      EuswSL.isKeyReaded = false;
      EuswSL.lastError = info + '[catch-' + step + ']: Ключ не активирован. ' + e;
      console.log(EuswSL.lastError);
    }
    return EuswSL.isKeyReaded;
  } /* ~readKeySilently */,

  /* *************************************** */
  iBeginVerify: function (info, signInBase64) {
    EuswSL.info(info + ': EuswSL.obj.BeginVerify');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.BeginVerify');
    return EuswSL.obj.BeginVerify(signInBase64);
    /*
    // Початок ітеративної перевірки зовнішнього ЕЦП
    function BeginVerify(
        [string] sign,				// Вхідний. Підпис для перевірки у
                                // вигляді BASE64-строки
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* It is to begin or continue, to get the result call the iEndVerify
   ***************************************************** */
  iContinueVerify: function (info, data, offset, length) {
    EuswSL.info(info + ': EuswSL.obj.ContinueVerify[args: ' + arguments.length + ']');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.ContinueVerify');
    if (arguments.length < 3) {
      return EuswSL.obj.ContinueVerify(data);
    } else {
      return EuswSL.obj.ContinueVerify(data, offset, length);
    }
    /*
    // Ітеративна перевірка зовнішнього ЕЦП. Функція може викликатися необмежену
    // кількість раз. Для завершення ітеративної перевірки ЕЦП необхідно
    // викликати функцію EndVerify
    function ContinueVerify(
        [Uint8Array(string)] data,		// Вхідний. Дані для перевірки ЕЦП
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    // Ітеративна перевірка зовнішнього ЕЦП. Функція може викликатися необмежену
    // кількість раз. Для завершення ітеративної перевірки ЕЦП необхідно
    // викликати функцію EndVerify
    function ContinueVerify(
        [Uint8Array(string)] data,		// Вхідний. Дані для перевірки ЕЦП
        [number] offset,				// Вхідний. Зміщення даних для
                                // перевірки ЕЦП в масиві data
        [numbet] length,				// Вхідний. Довжина даних для
                                // перевірки ЕЦП
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* To get the result, to begin or continue call the iContinueVerify
   *  When [showSignerInfo] :
   *  - true/false then it return [string]
   *  - not specified then it return [EndUserSignInfo]
   ****************************************** */
  iEndVerify: function (info, showSignerInfo) {
    EuswSL.info(info + ': EuswSL.obj.EndVerify[args: ' + arguments.length + ']');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.EndVerify');
    if (arguments.length > 1) {
      return /*[string]*/ EuswSL.obj.EndVerify(showSignerInfo);
    } else {
      return /*[EndUserSignInfo]*/ EuswSL.obj.EndVerify();
    }
    /*
    // Завершення ітеративної перевірки зовнішнього ЕЦП. Повертає інформацію про
    // підписувача
    function [string] EndVerify(
        [boolean] showSignerInfo,		// Вхідний. Відображати інформацію про ЕЦП
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] signInfo),		// Вихідний. Інформація про підпис
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    // Завершення ітеративної перевірки зовнішнього ЕЦП. Повертає інформацію про
    // підписувача
    function [EndUserSignInfo] EndVerify(
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [EndUserSignInfo] signInfo),	// Вихідний. Інформація про підпис
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* ************************ */
  iSign: function (info, data, successFunction, errorFunction) {
    EuswSL.info(info + ': EuswSL.obj.Sign');
    var rsp = EuswSL.SimpleResponse('EuswSL.obj.Sign');

    //return /*[string]*/EuswSL.obj.Sign(data, function(result) { /**/console.log('>'+result); /*rsp.onSuccess()*/ }, rsp.onError());
    return /*[string]*/ EuswSL.obj.Sign(
      data,
      successFunction, //function(result) { console.log(result); }
      errorFunction, //function(error) { console.error(error.toString()); }
    );
    /*
    // Формування зовнішнього (підпис знаходиться окремо від даних) електронного
    // цифрового підпису (ЕЦП). Повертається підпис у вигляді BASE64-строки
    function [string] Sign(
        [Uint8Array (string)] data,		// Вхідний. Дані для підпису
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] signanure),		// Вихідний. Підпис у вигляді
                                // BASE64-строки
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /*
   * When [showSignerInfo] :
   *  - true/false then it return [string]
   *  - not specified then it return [EndUserSignInfo]
   *************************************************** */
  iVerify: function (info, signInBase64, data, showSignerInfo, successFunction, errorFunction) {
    EuswSL.info(info + ': EuswSL.obj.Verify[args: ' + arguments.length + ']');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.Verify');
    if (arguments.length > 5) {
      return /*[string]*/ EuswSL.obj.Verify(
        signInBase64,
        data,
        showSignerInfo,
        successFunction, // ([string] signInfo) -- out info about sign
        errorFunction, // (e) -- error obj with .toString()
      );
    } else {
      return /*[EndUserSignInfo]*/ EuswSL.obj.Verify(
        signInBase64,
        data,
        successFunction, // ([EndUserSignInfo] signInfo) -- out obj
        errorFunction, // (e) -- error obj with .toString()
      );
    }
    /*
    // Перевірка зовнішнього ЕЦП. Повертається інформація про підпис у
    // вигляді строки
    function [string] Verify(
        [string] sign,				// Вхідний. Підпис у вигляді
                                // BASE64-строки
        [Uint8Array (string)] data,		// Вхідний. Дані для перевірки
        [boolean] showSignerInfo,		// Вхідний. Признак необхідності
                                // відображатиінформацію про ЕЦП
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] signInfo),		// Вихідний. Інформація про підпис
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    // Перевірка зовнішнього ЕЦП. Повертається детальна інформація про підпис
    function [EndUserSignInfo] Verify(
        [string] sign,				// Вхідний. Підпис у вигляді
                                // BASE64-строки
        [Uint8Array (string)] data,		// Вхідний. Дані для перевірки
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [EndUserSignInfo] signInfo),	// Вихідний. Інформація про підпис
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* It is to begin or continue, to get the result call the iEndSign
   *************************************************** */
  iContinueSign: function (info, data, offset, length) {
    EuswSL.info(info + ': EuswSL.obj.ContinueSign[args: ' + arguments.length + ']');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.ContinueSign');
    if (arguments.length < 3) {
      return EuswSL.obj.ContinueSign(data);
    } else {
      return EuswSL.obj.ContinueSign(data, offset, length);
    }
    /*
    // Початок ітеративного додавання зовнішнього ЕЦП. Ітеративне формування
    // продовжується фунцією ContinueSign. Для завершення ітеративно формування ЕЦП
    // необхідно викликати функцію SignDataEnd (SL: ?!)
    function ContinueSign(
        [Uint8Array (string)] data,		// Вхідний. Дані для підпису
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException
    // Початок ітеративного додавання зовнішнього ЕЦП. Ітеративне формування
    // продовжується фунцією ContinueSign. Для завершення ітеративно формування ЕЦП
    // необхідно викликати функцію EndSign
    function ContinueSign(
        [Uint8Array (string)] data,		// Вхідний. Дані для підпису
        [number] offset,				// Вхідний. Зміщення даних для
                                // підпису в масиві data
        [number] length,				// Вхідний. Довжина даних для підпису
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* It is to get the result, to begin or continue call the iContinueSign
   ************************ */
  iEndSign: function (info) {
    EuswSL.info(info + ': EuswSL.obj.EndSign');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.EndSign');
    return /*[string]*/ EuswSL.obj.EndSign();
    /*
    // Завершення ітеративного формування зовнішнього ЕЦП. Повертає підпис у
    // вигляді BASE64-строки
    function [string] EndSign(
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] signature),		// Вихідний. Підпис у вигляді
                                // BASE64-строки
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~iEndSign */,

  /* *************************************** */
  iRawSignHash: function (info, hashInBase64) {
    EuswSL.info(info + ': EuswSL.obj.RawSignHash');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.RawSignHash');
    /* !!! expected to be deprecated !!! */
    return /*[string]*/ EuswSL.obj.RawSignHash(hashInBase64);
    /*
    // Формування спрощеного ЕЦП від гешу. Повертає підпис у вигляді BASE64-строки
    // Примітка. В наступних версіях бібліотеки функція буде видалена, тому що
    // формат підпису не відповідає вимогам законодавства
    function [string] RawSignHash(
        [string] hash,				// Вхідний. Геш значення у вигляді
                                // BASE64-строки
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] sign),			// Вихідний. Підпис у вигляді
                                // BASE64-строки
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~iRawSignHash */,

  /*
   * When [showSignerInfo] :
   *  - true/false then return [string]
   *  - not specified then return [EndUserSignInfo]
   ************************************************************************** */
  iRawVerifyHash: function (info, hashInBase64, signInBase64, showSignerInfo) {
    EuswSL.info(info + ': EuswSL.obj.Verify[args: ' + arguments.length + ']');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.RawVerifyHash');
    if (arguments.length > 3) {
      return /*[string]*/ EuswSL.obj.RawVerifyHash(hashInBase64, signInBase64, showSignerInfo);
    } else {
      return /*[EndUserSignInfo]*/ EuswSL.obj.RawVerifyHash(hashInBase64, signInBase64);
    }
    /*
    // Перевірка спрощеного ЕЦП від гешу. Повертає інформацію про підписувача	// Примітка. В наступних версіях бібліотеки функція буде видалена, тому що
    // формат підпису не відповідає вимогам законодавства
    function [string] RawVerifyHash(
        [string] hash,				// Вхідний. Геш значення у вигляді
                                // BASE64-строки
        [string] sign,				// Вхідний. Підпис у вигляді
                                // BASE64-строки
        [boolean] showSignerInfo,		// Вхідний. Признак необхідності
                                // відображатиінформацію про ЕЦП
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] signInfo),		// Вихідний. Інформація про підпис
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    // Перевірка спрощеного ЕЦП від гешу. Повертає інформацію про підписувача
    // Примітка. В наступних версіях бібліотеки функція буде видалена, тому що
    // формат підпису не відповідає вимогам законодавства
    function [EndUserSignInfo] RawVerifyHash(
        [string] hash,				// Вхідний. Геш значення у вигляді
                                // BASE64-строки
        [string] sign,				// Вхідний. Підпис у вигляді
                                // BASE64-строки
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [EndUserSignInfo] signInfo),	// Вихідний. Інформація про підпис
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~iRawVerifyHash */,

  /* It is to begin or continue, to get the result call the iEndHash
   *************************************************** */
  iContinueHash: function (info, data, offset, length) {
    EuswSL.info(info + ': EuswSL.obj.ContinueHash[args: ' + arguments.length + ']');
    //var rsp = EuswSL.SimpleResponse('EuswSL.obj.ContinueHash');
    if (arguments.length < 3) {
      EuswSL.obj.ContinueHash(
        /*Uint8Array (string)*/ data,
        /*,rsp.onSuccess()
        ,rsp.onError() */
      );
    } else {
      EuswSL.obj.ContinueHash(
        /*Uint8Array (string)*/ data,
        offset,
        length,
        /*,rsp.onSuccess()
        ,rsp.onError() */
      );
    }
    /*
    // Ітеративне формування геша. Функція може викликатися необмежену
    // кількість раз. Для завершення ітеративного формування геша необхідно
    // викликати функцію EndHash
    function ContinueHash(
        [Uint8Array (string)] data,		// Вхідний. Дані для гешування
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    // Ітеративне формування геша. Функція може викликатися необмежену
    // кількість раз. Для завершення ітеративного формування геша необхідно
    // викликати функцію EndHash
    function ContinueHash(
        [Uint8Array (string)] data,		// Вхідний. Дані для гешування
        [number] offset,				// Вхідний. Зміщення даних для
                                // геша в масиві data
        [numbet] length,				// Вхідний. Довжина даних для геша
        [{optional} function] onSuccess(),	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
        [{optional} function] onError(	// Вхідний Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error)		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* It is to get the result, to begin or continue call the iContinueHash
   ************************ */
  iEndHash: function (info) {
    EuswSL.info(info + ': EuswSL.obj.EndHash');
    return EuswSL.obj
      .EndHash
      /*function(/*[string]* /hash) {
          alert('[callback] EuswSL.obj.EndHash: ' +hash);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.EndHash').onError()*/
      ();
  } /* ~iEndHash */,

  /* ******************************** */
  iShowOwnCertificate: function (info) {
    EuswSL.info(info + ': EuswSL.obj.ShowOwnCertificate');
    let rsp = EuswSL.SimpleResponse('EuswSL.obj.ShowOwnCertificate');
    EuswSL.obj.ShowOwnCertificate(rsp.onSuccess(), rsp.onError());
  } /* ~iShowOwnCertificate */,

  /* ******************************** */
  iGetOwnCertificate: function (info, certKeyType, keyUsage) {
    EuswSL.info(info + ': EuswSL.obj.GetOwnCertificate');
    let rsp = EuswSL.SimpleResponse('EuswSL.obj.GetOwnCertificate');
    EuswSL.obj.GetOwnCertificate(certKeyType, keyUsage, rsp.onSuccess(), rsp.onError());
  } /* ~iShowOwnCertificate */,

  /* **************************** */
  iReadPrivateKey: function (info) {
    EuswSL.info(info + ': EuswSL.obj.ReadPrivateKey');
    let rsp = EuswSL.SimpleResponse('EuswSL.obj.ReadPrivateKey');
    EuswSL.obj.ReadPrivateKey(
      function () {
        //rsp.onSuccess()
        EuswSL.isKeyReaded = true;
        console.log(rsp.msg + ': Sucсess');
        EuswSL.obj.EnumOwnCertificates(
          0,
          function (certificate) {
            EuswSL.privateKeyUserCode = certificate.GetSubjUserCode();
            console.log(rsp.msg + ' [EnumOwnCertificates]: Sucсess. User Tab =' + EuswSL.privateKeyUserCode);
            //ip.admin.ui.readUserCodeCallBack(userCode);
          },
          function (e) {
            toError(rsp.msg + ' [EnumOwnCertificates] failed: ', e.toString());
          },
        );
      },
      function (e) {
        //rsp.onError()
        EuswSL.isKeyReaded = false;
        console.log(rsp.msg + ' failed: ' + e.toString());
      },
    );
  } /* ~iReadPrivateKey */,

  /* **************************** */
  iReadPrivateKeySilently: function (info, typeIndex, devIndex, password) {
    EuswSL.info(info + ': EuswSL.obj.ReadPrivateKeySilently');
    let rsp = EuswSL.SimpleResponse('EuswSL.obj.ReadPrivateKeySilently');
    rsp.errorAlert = true;
    EuswSL.obj.ReadPrivateKeySilently(
      typeIndex,
      devIndex,
      password,
      function () {
        //rsp.onSuccess()
        EuswSL.isKeyReaded = true;
        console.log(rsp.msg + ': Sucсess');
        EuswSL.obj.EnumOwnCertificates(
          0,
          function (certificate) {
            EuswSL.privateKeyUserCode = certificate.GetSubjUserCode();
            console.log(rsp.msg + ' [EnumOwnCertificates]: Sucсess. User Tab =' + EuswSL.privateKeyUserCode);
            //ip.admin.ui.readUserCodeCallBack(userCode);
          },
          function (e) {
            toError(rsp.msg + ' [bnpuaiit.EnumOwnCertificates] failed: ', e.toString());
          },
        );
      },
      function (e) {
        //rsp.onError()
        EuswSL.isKeyReaded = false;
        console.log(rsp.msg + ' failed: ' + e.toString());
      },
    );
  } /* ~iReadPrivateKey */,

  /* ***************************** */
  iResetPrivateKey: function (info) {
    EuswSL.info(info + ': EuswSL.obj.ResetPrivateKey');
    let rsp = EuswSL.SimpleResponse('EuswSL.obj.ResetPrivateKey');
    EuswSL.obj.ResetPrivateKey(rsp.onSuccess(), rsp.onError());
  } /* ~iResetPrivateKey */,

  /* **************************** */
  iResetOperation: function (info) {
    EuswSL.info(info + ': EuswSL.obj.ResetOperation');
    let rsp = EuswSL.SimpleResponse('EuswSL.obj.ResetOperation');
    EuswSL.obj.ResetOperation(rsp.onSuccess(), rsp.onError());
  } /* ~iResetOperation */,

  /* ************************************* */
  iBASE64Encode: function (info, dataUint8) {
    EuswSL.info(info + ': EuswSL.obj.BASE64Encode');
    return /*[string]*/ EuswSL.obj.BASE64Encode(
      /*[Uint8Array]*/ dataUint8,
      /*function(/*[string]* / encodedData) {
          alert('[callback] EuswSL.obj.BASE64Encode: ' +encodedData);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.BASE64Encode').onError()*/
    );
    /*
    // Формування BASE64-строки. Повертаються закодовані дані у вигляді
    // BASE64-строки
    function [string] BASE64Encode(
        [Uint8Array] data,			// Вхідний. Дані у вигляді масиву
                                // байт
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] encodedData),		// Вихідний. Закодовані дані у
                                // вигляді BASE64-строки
        [{optional} function] onError(	// Вхідний. Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~iBASE64Encode */,

  /* ******************************************** */
  iBASE64DecodeToUint8: function (info, strBase64) {
    EuswSL.info(info + ': EuswSL.obj.BASE64Decode');
    return /*[Uint8Array]*/ EuswSL.obj.BASE64Decode(
      /*[string]*/ strBase64,
      /*function(/*[Uint8Array]* / decodedData) {
          alert('[callback] EuswSL.obj.BASE64Decode: ' +decodedData);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.BASE64Decode').onError()*/
    );
    /*
    // Отримання даних з BASE64-строки. Повертаються декодовані дані у вигляді
    // масиву байт
    function [Uint8Array] BASE64Decode(
        [string] data,				// Вхідний. Дані у вигляді
                                // BASE64-строки
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [Uint8Array] decodedData),	// Вихідний. Декодовані дані у
                                // вигляді масиву байт
        [{optional} function] onError(	// Вхідний. Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~iBASE64Decode */,

  /* ************************************* */
  iStringToBytesUint8: function (info, str) {
    EuswSL.info(info + ': EuswSL.obj.StringToBytes');
    return /*[Uint8Array]*/ EuswSL.obj.StringToBytes(
      /*[string]*/ str,
      /*function(/*[Uint8Array]* / encodedData) {
          alert('[callback] EuswSL.obj.StringToBytes: ' +encodedData);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.StringToBytes').onError()*/
    );
    /*
    // Перетворення строки в масив байт з використанням кодування встановленого
    // функцією SetCharset. Повертаються закодовані дані у вигляді масиву байт
    function [Uint8Array] StringToBytes(
        [string] data,				// Вхідний. Дані у вигляді строки
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [Uint8Array] encodedData),	// Вихідний. Закодовані дані у
                                // вигляді масиву байт
        [{optional} function] onError(	// Вхідний. Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* ************************************** */
  iBytesToString: function (info, dataUint8) {
    EuswSL.info(info + ': EuswSL.obj.BytesToString');
    return /*[string]*/ EuswSL.obj.BytesToString(
      /*[Uint8Array]*/ dataUint8,
      /*function(/*[string]* / str) {
          alert('[callback] EuswSL.obj.BytesToString: ' +str);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.BytesToString').onError()*/
    );
    /*
    // Перетворення масиву байт в строку з використанням кодування встановленого
    // функцією SetCharset. Повертається декодована строка
    function [string] BytesToString (
        [Uint8Array] data,			// Вхідний. Дані у вигляді масиву
                                // байт
        [{optional} function] onSuccess(	// Вхідний. Функція зворотньо виклику
                                // при успішному виконанні
            [string] decodedData),		// Вихідний. Декодована строка
        [{optional} function] onError(	// Вхідний. Функція зворотньо виклику
                                // при виникненні помилки
            [EndUserError] error		// Вихідний. Інформація про помилку
    ) throws EndUserException;
    */
  } /* ~ */,

  /* ***************************************** */
  iEnumKeyMediaTypes: function (info, devIndex) {
    EuswSL.info(info + ': EuswSL.obj.EnumKeyMediaTypes');

    /*
    var typeIndex = 0;
while(true) {
        let result = EuswSL.obj.EnumKeyMediaTypes(parseInt(typeIndex, 10));
        if (result === '')
            break;
        EuswSL.info(': ' +result);
        typeIndex++;
    }
    */

    return /*[]*/ EuswSL.EnumKeyMediaTypes(
      /*[]*/ devIndex,
      /*function(/*[string]* / str) {
          alert('[callback] EuswSL.obj.BytesToString: ' +str);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.BytesToString').onError()*/
    );
    /*
     */
  } /* ~ */,

  /* ********************************************************************************* */
  enumTheKeyMediaTypesToSelect: function (selectElement, clearPrevious) {
    if (clearPrevious) {
      doEpmtySelect(selectElement, false);
    }

    var typeIndex = 0;
    addElementToSelect(selectElement, 'not selected', 0);
    while (true) {
      let result = EuswSL.obj.EnumKeyMediaTypes(parseInt(typeIndex, 10));
      if (result === '') break;
      result = typeIndex + ': ' + result;
      EuswSL.info(result);
      typeIndex++;
      addElementToSelect(selectElement, result, typeIndex);
    }
  } /* ~enumTheKeyMediaTypesToSelect */,

  /* ********************************************************* */
  iEnumKeyMediaDevices: function (info, typeIndex, deviceIndex) {
    EuswSL.info(info + ': EnumKeyMediaDevices');

    /*
    var deviceIndex = 0;
    while(true) {
        var result = EuswSL.obj.;
        if (result == "")
                break;
        formContent += "<option value=\"" + deviceIndex+"\">" + result + "</option>";
        deviceIndex++;
    }
    */

    return /*[]*/ EuswSL.EnumKeyMediaDevices(
      /*[]*/ parseInt(typeIndex, 10),
      /*[]*/ parseInt(deviceIndex, 10),
      /*function(/*[string]* / str) {
          alert('[callback] EuswSL.obj.BytesToString: ' +str);
      }
      ,EuswSL.SimpleResponse('EuswSL.obj.BytesToString').onError()*/
    );
    /*
     */
  } /* ~ */,

  /* ********************************************************************************* */
  enumTheKeyMediaDevicesToSelect: function (typeIndex, selectElement, clearPrevious) {
    if (clearPrevious) {
      doEpmtySelect(selectElement, false);
    }

    var devIndex = 0;
    addElementToSelect(selectElement, 'not selected', 0);
    while (true) {
      let result = EuswSL.obj.EnumKeyMediaDevices(parseInt(typeIndex, 10), parseInt(devIndex, 10));
      if (result === '') break;
      result = devIndex + ': ' + result;
      EuswSL.info(result);
      devIndex++;
      addElementToSelect(selectElement, result, devIndex);
    }
  } /* ~enumKeyMediaDevicesToSelect */,

  /* ****************************** */
  runtimeInitialize: function (obj) {
    if (!EuswSL.isLoaded) {
      EuswSL.load();
    }

    try {
      if (EuswSL.isRuntimeInitialize === true) {
        // console.warn(
        //     'EuswSL->runtimeInitialize: is already initialized for instance '
        //     +EuswSL.obj
        // );
        return EuswSL.isRuntimeInitialize;
      }

      EuswSL.isRuntimeInitialize = false;

      //if (obj) {
      //EuswSL.obj = obj;
      //}

      let rsp = EuswSL.SimpleResponse('EuswSL.obj.SetUIMode');
      EuswSL.info(rsp.msg);
      //if (EuswSL.isAsync)
      EuswSL.obj.SetUIMode(EuswSL.isUiMode, rsp.onSuccess(), rsp.onError());

      //was: IITSign.formSign.SetCodePage(65001);  // WideCharToMultiByte http://msdn.microsoft.com/en-us/library/windows/desktop/dd374130(v=vs.85).aspx
      EuswSL.obj.SetCharset('UTF-8');

      /*/ onSetRuntimeParameter({"0":"SaveSettings","1":0})
      rsp = EuswSL.SimpleResponse('EuswSL.obj.SetRuntimeParameter');
      EuswSL.info(rsp.msg);
      EuswSL.obj.SetRuntimeParameter(
          "SaveSettings"   // Вхідний. Ім'я параметру
          , 0  // Вхідний. Значення параметру
          , rsp.onSuccess()
          , rsp.onError()
      );
      */

      EuswSL.info('EuswSL->Initialize');
      EuswSL.obj.Initialize(
        function () {
          EuswSL.isRuntimeInitialize = true;
          EuswSL.lastError = '';
          console.log('[callback] EuswSL.obj.Initialize: Library initialized');
        },
        function (e) {
          EuswSL.isRuntimeInitialize = false;
          EuswSL.lastError = '[callback] EuswSL.obj.Initialize failed: ' + e.toString();
          console.log(EuswSL.lastError);
          // alert(
          //   'Виникла помилка при ' + 'ініціалізації криптографічної бібліотеки. ' + 'Опис помилки: ' + e.toString(),
          // );
        },
      );

      EuswSL.isRuntimeInitialize = true;
      EuswSL.info('EuswSL->runtimeInitialize: done');
    } catch (e) {
      EuswSL.isRuntimeInitialize = false;
      EuswSL.lastError = '[catch] EuswSL->runtimeInitialize [' + EuswSL.lastInfo + ']: ' + e;
      console.log(EuswSL.lastError);
    }
    return EuswSL.isRuntimeInitialize;
  } /* ~runtimeInitialize */,

  /* **************** */
  isAsync: function () {
    var rsp = EuswSL.SimpleResponse('EuswSL.obj.IsAsync');
    return EuswSL.obj.IsAsync(rsp.onSuccess(), rsp.onError());
  },

  /* *************** */
  unload: function () {
    try {
      if (EuswSL.isLoaded) {
        EuswSL.loader.unload();
      }
    } catch (e) {}
    EuswSL.isRuntimeInitialize = null;
    EuswSL.loader = null;
    EuswSL.isLoaded = false;
    EuswSL.isKeyReaded = null;
  },

  /* ************* */
  load: function (successCallback?: (...args: any) => void, errorCallback?: (...args: any) => void) {
    EuswSL.unload();

    try {
      EuswSL.isLoaded = false;
      EuswSL.lastError = null;

      EuswSL.loader = new EndUserLibraryLoader(
        EuswSL.EU_LIBRARY_TYPE,
        EuswSL.id,
        EuswSL.EU_LANG,
        true /*false ; не завантажувати Java-апплет
                (тільки при значенні libraryType = LIBRARY_TYPE_DEFAULT)*/,
        true /*false ; завантажувати веб-розширення
                (тільки при значенні libraryType = LIBRARY_TYPE_DEFAULT)*/,
      );

      EuswSL.loader.onload = function (/*[EUSignCP]*/ library) {
        //!: this works in when for ActiveX and is not guaranteed for WebExt
        EuswSL.obj = library;
        EuswSL.isLoaded = true;
        EuswSL.info('[callback: EuswSL.loader.onload] Library loaded');
        EuswSL.isRuntimeInitialize = false;
        EuswSL.runtimeInitialize(library);
        successCallback(library);
      };

      EuswSL.loader.onerror = function (msg, e, /*[EUSignCP]or[null]*/ libraryOrNull) {
        console.log(msg, e, /*[EUSignCP]or[null]*/ libraryOrNull);
        EuswSL.isRuntimeInitialize = false;
        EuswSL.isLoaded = false;
        EuswSL.lastError =
          '[callback: EuswSL.loader.onerror] Library not loaded! \n' +
          'Виникла помилка при завантаженні криптографічної бібліотеки. \n' +
          'Опис помилки: ' +
          e +
          ' ' +
          msg +
          ' ' +
          libraryOrNull +
          EuswSL.lastError;
        console.log(EuswSL.lastError);
        errorCallback(msg);
      };

      EuswSL.loader.load();
      EuswSL.info('EuswSL.load: done');
    } catch (e) {
      EuswSL.isLoaded = false;
      EuswSL.lastError = 'load [catch]: ' + e;
      console.log(EuswSL.lastError);
    }

    return EuswSL.isLoaded;
  } /* ~load */,

  /* *************************** */
  info: function (text) {
    EuswSL.lastInfo = text;
    if (this.isDebug) {
      console.log(text);
    }
    return EuswSL;
  } /* ~info */,

  /* *************************** */
  SimpleResponse: function (text, errorAlert) {
    var objt = {
      msg: 'SimpleResponse',
      errorAlert: false,
      /* ******************* */
      onSuccess: function () {
        // Вхідний. Функція зворотньо виклику
        // при успішному виконанні
        var info = objt.msg;
        return function () {
          EuswSL.info('[callback-onSuccess] ' + info + ': Ok');
        };
      },
      /* ********************** */
      onError: function (err) {
        // Вхідний. Функція зворотньо виклику
        // при виникненні помилки
        var info = objt.msg;
        var errorAlert = objt.errorAlert;
        return function (e) {
          var data = '[callback-onError] ' + info + ': ' + /*[EndUserError]*/ e.toString();
          console.log(data);
          // if (errorAlert) {
          //   alert(data);
          // }
        };
      },
    };
    objt.msg = text;
    if (errorAlert) {
      objt.errorAlert = errorAlert;
    }
    return objt;
  } /* ~SimpleResponse */,
}; /*~EuswSL~*/

var doEpmtySelect = function (selectElement, withConfirm) {
  var isOk = !withConfirm;
  if (withConfirm) {
    isOk = confirm('Clear the list ' + selectElement.length + ' values?');
  } else {
    isOk = true;
  }
  if (!isOk) return false;
  while (selectElement.length) {
    selectElement.remove(0);
  }
  return true;
};

var addElementToSelect = function (selectElement, text, value) {
  var newOption = document.createElement('option');
  newOption.value = value;
  //newOption.label = result;
  newOption.text = text;
  selectElement.add(newOption, null);
  //formContent += "<option value=\""+typeIndex+"\">"+result+"</option>";
};
