import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ErrorsActions from './errors.actions';
import * as ErrorsSelectors from './errors.selectors';
import { Store } from '@ngrx/store';
import { DictionariesService } from '../dictionaries.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorsEffects {
  loadErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorsActions.loadErrors),
      concatLatestFrom((action) => [this.store$.select(ErrorsSelectors.selectAllErrors)]),
      switchMap(([action, allErrors]) =>
        (allErrors.length ? of(allErrors) : this.dictionariesService.loadErrorCodes()).pipe(
          map((response) =>
            ErrorsActions.loadErrorsSuccess({
              errors: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(ErrorsActions.loadErrorsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  loadErrorsProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorsActions.loadErrorsProcess),
      concatLatestFrom((action) => [this.store$.select(ErrorsSelectors.selectAllErrorsProcess)]),
      switchMap(([action, allErrorsProcess]) =>
        (allErrorsProcess?.length ? of(allErrorsProcess) : this.dictionariesService.loadErrorsProcess()).pipe(
          map((response) =>
            ErrorsActions.loadErrorsProcessSuccess({
              errorsProcess: response,
            }),
          ),
          catchError((error) => {
            this.toastrService.error(error?.error?.message);
            return of(ErrorsActions.loadErrorsProcessFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dictionariesService: DictionariesService,
    private toastrService: ToastrService,
  ) {}
}
