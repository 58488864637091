import { inject, Injectable } from '@angular/core';
import { concatMap, filter, first, map, switchMap, tap } from 'rxjs/operators';
import {
  AgreementGuaranty,
  AgreementService,
  Asset,
  AssetAgreementRelation,
  AssetsAndAgreementGuarantyDto,
  Deposit,
  Equipment,
  Good,
  Land,
  PropertyOwner,
  Realty,
  SapIntegrationService,
  Vehicle,
} from '@api/loan-approval';
import {
  AgreementGuarantyFacade,
  AssetsFacadeService,
  deleteTaskV2ProcessVariable,
  ProvidingFacadeService,
  setTaskV2ProcessVariable,
} from '@sib/task/shared/store';
import { combineLatest, throwError } from 'rxjs';
import { AbstractStoreService } from './abstract-store.service';
import { DocumentsService } from './documents.service';
import { concatLatestFrom } from '@ngrx/effects';
import * as fromShared from '../../../../../shared/store/src/lib/shared-state';

@Injectable({ providedIn: 'root' })
export class ProvidingService extends AbstractStoreService {
  private readonly assetsFacadeService = inject(AssetsFacadeService);
  private readonly documentsService = inject(DocumentsService);
  private readonly agreementService = inject(AgreementService);
  private readonly agreementGuarantyFacade = inject(AgreementGuarantyFacade);
  private readonly providingFacadeService = inject(ProvidingFacadeService);
  private readonly sapIntegrationService = inject(SapIntegrationService);

  patchAsset(id: string, partialAsset: Partial<Asset>) {
    return this.assetsFacadeService.selectAssetById$(id).pipe(
      first(),
      tap((item) => {
        if (!item) {
          return throwError(() => ({ message: 'there is no asset by passed id' }));
        }
        return;
      }),
      filter(Boolean),
      map((asset) => {
        const newAsset = { ...asset, ...partialAsset };
        this.store.dispatch(setTaskV2ProcessVariable({ requestBody: { asset: [newAsset] } }));
        return newAsset;
      }),
      switchMap((asset) => this.waitForResponse(asset)),
    );
  }

  updateAssets(assets: Asset[]) {
    this.store.dispatch(setTaskV2ProcessVariable({ requestBody: { asset: assets } }));

    return this.waitForResponse(assets);
  }

  createProvidingContract({ agreementGuaranty, asset }: { agreementGuaranty: AgreementGuaranty; asset: Asset[] }) {
    this.store.dispatch(
      setTaskV2ProcessVariable({
        requestBody: {
          agreementGuaranty: agreementGuaranty ? [agreementGuaranty] : undefined,
          asset,
        },
      }),
    );

    return this.waitForResponse(agreementGuaranty);
  }

  updateAssetsDetails(
    payload: {
      asset: Asset[];
      deposit?: Deposit[];
      goods?: Good[];
      equipment?: Equipment[];
      vehicles?: Vehicle[];
      land?: Land[];
      realty?: Realty[];
      propertyOwner: PropertyOwner[];
    },
    forDelete: { assetAgreementRelation: AssetAgreementRelation[]; propertyOwner: PropertyOwner[] },
  ) {
    this.store.dispatch(
      deleteTaskV2ProcessVariable({
        requestBody: {
          ...forDelete,
        },
      }),
    );
    return this.waitForResponse(undefined).pipe(
      switchMap(() => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              ...payload,
            },
          }),
        );
        return this.waitForResponse(true);
      }),
    );
  }

  deleteAsset({ id, cagId = '' }: Asset) {
    return combineLatest([
      this.assetsFacadeService.selectAssetById$(id),
      this.assetsFacadeService.selectDepositByAssetId$(id),
      this.assetsFacadeService.selectVehicleById$(id),
      this.assetsFacadeService.selectEquipmentById$(id),
      this.assetsFacadeService.selectLandById$(id),
      this.assetsFacadeService.selectGoodById$(id),
      this.assetsFacadeService.selectRealtyById$(id),
      this.assetsFacadeService.selectPropertyOwnersById$(id),
      this.assetsFacadeService.selectAgreementRelationsById$(id),
      this.assetsFacadeService.selectAgreementGuarantyForDeleteByCagId$(cagId),
    ]).pipe(
      first(),
      switchMap(
        ([
          asset,
          deposit,
          vehicle,
          equipment,
          land,
          goods,
          realty,
          propertyOwner,
          assetAgreementRelation,
          agreementGuaranty,
        ]) => {
          this.store.dispatch(
            deleteTaskV2ProcessVariable({
              requestBody: {
                asset: asset ? [asset] : [],
                deposit: deposit ? [deposit] : [],
                vehicles: vehicle ? [vehicle] : [],
                equipment: equipment ? [equipment] : [],
                land: land ? [land] : [],
                goods: goods ? [goods] : [],
                realty: realty ? [realty] : [],
                propertyOwner,
                assetAgreementRelation,
                agreementGuaranty: agreementGuaranty ? [agreementGuaranty] : [],
              },
            }),
          );

          return this.waitForResponse(id);
        },
      ),
    );
  }

  deleteAssetWithRelatedFiles(asset: Asset) {
    return this.documentsService.updateBindingByBindingCode(asset.id, '').pipe(
      first(),
      switchMap((updatedDocument) => this.documentsService.deleteFiles(updatedDocument.map((item) => item.documentId))),
      concatMap(() => this.deleteAsset(asset)),
    );
  }

  deleteAssetAndLink(asset: Asset) {
    return this.documentsService
      .updateBindingByBindingCode(asset.id, '')
      .pipe(switchMap(() => this.deleteAsset(asset)));
  }

  deleteAssetWithNewDocumentLink(asset: Asset, bindingCode: string) {
    return this.documentsService
      .updateBindingByBindingCode(asset.id, bindingCode)
      .pipe(switchMap(() => this.deleteAsset(asset)));
  }

  createProvidingContractSAP(contractSAPPayload: AssetsAndAgreementGuarantyDto) {
    return this.agreementService.postSapAssetsAndAgreementGuaranty({ requestBody: contractSAPPayload }).pipe(
      concatLatestFrom(() => [this.agreementGuarantyFacade.selectActiveAgreementGuaranty$.pipe(first(Boolean))]),
      switchMap(([response, currentAgreementGuaranty]) => {
        const agreementGuaranty = {
          ...currentAgreementGuaranty,
          cagGuid: response.collateralGuid,
          cagmtId: response.collateralId,
        };

        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              agreementGuaranty: [agreementGuaranty],
            },
          }),
        );

        return this.waitForResponse(agreementGuaranty);
      }),
    );
  }

  updateProvidingContract$({ agreementGuaranty, asset }: { agreementGuaranty: AgreementGuaranty; asset?: Deposit }) {
    return this.assetsFacadeService.selectDepositByAssetId$(asset?.assetId || '').pipe(
      first(Boolean),
      switchMap((deposit) => {
        this.store.dispatch(
          setTaskV2ProcessVariable({
            requestBody: {
              agreementGuaranty: [agreementGuaranty],
              ...(deposit
                ? {
                    deposit: [{ ...deposit, ...asset }],
                  }
                : {}),
            },
          }),
        );

        return this.waitForResponse(agreementGuaranty);
      }),
    );
  }

  getPledgeAgreementsTypes() {
    return combineLatest([
      this.providingFacadeService.selectCurrentProviding$.pipe(
        map((currentProviding) => currentProviding?.assetType),
        filter(Boolean),
      ),
      this.store.select(fromShared.selectAssetTypesEntities),
      this.store.select(fromShared.selectAssetTypesAll).pipe(filter((items) => !!items.length)),
    ]).pipe(
      first(),
      switchMap(([assetType, allAssetTypes]) =>
        this.sapIntegrationService.getSapPledgeAgreementsTypes({
          assetsTypesCodes: [allAssetTypes[+assetType]?.sapCodeMain || ''],
        }),
      ),
    );
  }
}
