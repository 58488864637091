import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DigitOnlyModule } from '@uiowa/digit-only';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AutofocusDirective, DisableControlDirective } from '@sib/shared/ui';
import { Store } from '@ngrx/store';
import { BusinessPartnerService } from '@sib/task/shared/da';
import { catchError, EMPTY, throwError } from 'rxjs';
import { ApiError } from '@api/camunda';
import { markForCheck, NotFound, SapGeneralError } from '@sib/shared/util';
import { Guarantor } from '@api/loan-approval';

@Component({
  selector: 'sib-bp-search-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DigitOnlyModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    AutofocusDirective,
    DisableControlDirective,
  ],
  templateUrl: './bp-search-form.component.html',
  styleUrls: ['./bp-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpSearchFormComponent {
  public businessPartnerService = inject(BusinessPartnerService);
  public store = inject(Store);
  private cdr = inject(ChangeDetectorRef);

  public form = new FormGroup({
    bpCode: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    idNumber: new FormControl('', { validators: [Validators.required], nonNullable: true }),
  });

  @Output()
  public bpFound = new EventEmitter<Guarantor>();
  @Output()
  public isLoading = new EventEmitter<boolean>();

  get f() {
    return this.form.controls;
  }

  onSubmit(): void {
    const formData = this.form.getRawValue();
    if (!formData.bpCode && !formData.idNumber) {
      return;
    }
    this.isLoading.emit(true);
    this.businessPartnerService
      .loadLoanApprovalGuarantor(formData)
      .pipe(
        catchError((error: ApiError) => {
          if (error.body instanceof SapGeneralError) {
            Object.values(this.form.controls).forEach((control) =>
              control.setErrors({ serverError: error.body.errorMessage }),
            );
            return EMPTY;
          }

          if (error.body instanceof NotFound) {
            Object.values(this.form.controls).forEach((control) =>
              control.setErrors({ serverError: `Код ${formData.idNumber} не знайдено.` }),
            );
            return EMPTY;
          }

          return throwError(() => error);
        }),
        markForCheck(this.cdr),
      )
      .subscribe({
        next: (result) => {
          this.bpFound.emit(result);
        },
        complete: () => {
          this.isLoading.emit(false);
        },
      });
  }
}
