import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

export interface FormInfoDialogModel {
  title?: string;
  description: string;
  buttonTitleYes?: string;
  buttonTitleNo?: string;
  buttonNoHidden?: boolean;
  buttonYesHidden?: boolean;
  titleHidden?: boolean;
}

@Component({
  selector: 'sib-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
})
export class InfoDialogComponent implements OnInit {
  public defaultConfig: FormInfoDialogModel = {
    title: 'Помилка',
    description: '',
    buttonTitleYes: 'Так',
    buttonTitleNo: 'Назад',
    buttonNoHidden: false,
    buttonYesHidden: true,
    titleHidden: false,
  };

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: FormInfoDialogModel,
  ) {}

  ngOnInit(): void {
    this.data = { ...this.defaultConfig, ...this.data };
  }
}
