/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TechService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get protocol number by protocol date
     * Returns the protocol number for a given protocol date.
     * @returns number Get protocol number by protocol date
     * @throws ApiError
     */
    public getTechProtocolNumber({
        protocolDate,
    }: {
        protocolDate?: string,
    }): Observable<number> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/tech/protocol-number',
            query: {
                'protocol-date': protocolDate,
            },
            errors: {
                400: `Bad request`,
                500: `Server error`,
            },
        });
    }

}
