/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DocumentTypeDto } from '../models/DocumentTypeDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DocumentTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get all document types
     * Returns all document types.
     * @returns DocumentTypeDto all document types
     * @throws ApiError
     */
    public getApiDocumentsDocTypes(): Observable<Array<DocumentTypeDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-types',
        });
    }

    /**
     * Create document type
     * Method for creating document type.
     * @returns DocumentTypeDto document type created
     * @throws ApiError
     */
    public postApiDocumentsDocTypes({
        requestBody,
    }: {
        /**
         * Document type object that needs to be created.
         */
        requestBody: DocumentTypeDto,
    }): Observable<DocumentTypeDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/documents/doc-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Get document type
     * Get document type by type.
     * @returns any document type found
     * @throws ApiError
     */
    public getApiDocumentsDocTypesGetByType({
        type,
    }: {
        /**
         * Document type. Cannot be empty.
         */
        type: string,
    }): Observable<{
        id: number;
        docType?: string;
        docName?: string;
        sizeLimit?: number;
        codeAKD?: string;
        akdFolderId?: number;
        codeDocsAcc?: string;
        includeFilename?: boolean;
        convertToTIFF?: boolean;
        documentGroupIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-types/get-by-type/{type}',
            path: {
                'type': type,
            },
            errors: {
                404: `document type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Get document type
     * Get document type by ID.
     * @returns any document type found
     * @throws ApiError
     */
    public getApiDocumentsDocTypes1({
        id,
    }: {
        /**
         * Document type ID. Cannot be empty.
         */
        id: number,
    }): Observable<{
        id: number;
        docType?: string;
        docName?: string;
        sizeLimit?: number;
        codeAKD?: string;
        akdFolderId?: number;
        codeDocsAcc?: string;
        includeFilename?: boolean;
        convertToTIFF?: boolean;
        documentGroupIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/documents/doc-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `document type not found`,
                500: `server error`,
            },
        });
    }

    /**
     * Update document type
     * Updates the document type with the given ID.
     * @returns any document type updated
     * @throws ApiError
     */
    public putApiDocumentsDocTypes({
        id,
        requestBody,
    }: {
        /**
         * Document type ID. Cannot be empty.
         */
        id: number,
        /**
         * Document type object that needs to be updated.
         */
        requestBody: DocumentTypeDto,
    }): Observable<{
        id: number;
        docType?: string;
        docName?: string;
        sizeLimit?: number;
        codeAKD?: string;
        akdFolderId?: number;
        codeDocsAcc?: string;
        includeFilename?: boolean;
        convertToTIFF?: boolean;
        documentGroupIds?: Array<number>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/documents/doc-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                500: `server error`,
            },
        });
    }

    /**
     * Delete document type
     * Deletes the document type with the given ID.
     * @returns void
     * @throws ApiError
     */
    public deleteApiDocumentsDocTypes({
        id,
    }: {
        /**
         * Document type ID.  Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/documents/doc-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Add role to document type
     * Add a document group role to the specified document type.
     * @returns void
     * @throws ApiError
     */
    public postApiDocumentsDocTypesDocGroups({
        groupId,
        id,
    }: {
        /**
         * Document group role ID. Cannot be empty.
         */
        groupId: number,
        /**
         * Document type ID. Cannot be empty.
         */
        id: number,
    }): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/documents/doc-types/{id}/doc-groups/{groupId}',
            path: {
                'groupId': groupId,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

    /**
     * Remove role from document type
     * Remove a document group role from the specified document type.
     * @returns any document type deleted
     * @throws ApiError
     */
    public deleteApiDocumentsDocTypesDocGroups({
        groupId,
        id,
    }: {
        /**
         * Document group role ID. Cannot be empty.
         */
        groupId: number,
        /**
         * Document type ID. Cannot be empty.
         */
        id: number,
    }): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/documents/doc-types/{id}/doc-groups/{groupId}',
            path: {
                'groupId': groupId,
                'id': id,
            },
            errors: {
                500: `server error`,
            },
        });
    }

}
