import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectDocumentaryOperationByAgreementId } from './documentary-operations.selectors';
import { selectAllDocumentaryOperations } from '../../+state/documentary-operations/documentary-operation.selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentaryOperationsFacadeService {
  private store = inject(Store);

  getDocumentaryOperationByAgreementId$ = (agreementId: string) =>
    this.store.select(selectDocumentaryOperationByAgreementId(agreementId));

  public getAllDocumentaryOperations$ = this.store.select(selectAllDocumentaryOperations);
}
