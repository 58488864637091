import { createSelector } from '@ngrx/store';
import * as fromBpRoles from './bp-roles.reducer';
import { selectBpRolesState } from '../+shell/reducers.index';

export const selectBpRolesIsLoading = createSelector(selectBpRolesState, fromBpRoles.selectIsLoading);

export const selectBpRolesEntities = createSelector(selectBpRolesState, fromBpRoles.selectEntities);

export const selectBpRolesById = (id: number) =>
  createSelector(selectBpRolesEntities, (bpRolesEntities) => bpRolesEntities[id]);

export const selectAllBpRoles = createSelector(selectBpRolesState, fromBpRoles.selectAll);
